import React, { useEffect, useState } from 'react';
import HeaderUser from './HeaderUser';
import FixedNav from '@components/FixedNav';
import Footer from './Footer';
import { CheckIconsProvider } from '../utils/lib'; // Context import
import * as PATH from '@routes/pathName';

const LayoutBasic = ({ ContentBody }) => {
  const [sectionId, setSectionId] = useState('');
  const [isActiveFixedNav, setIsActiveFixedNav] = useState(false);

  useEffect(() => {
    setSectionId(window.location.pathname.split('/')[1] + 'Section');
    if ([PATH.LOGIN, PATH.LOGIN_SOCIAL, PATH.FORGET_ACCOUNT, PATH.FORGET_EMAIL, PATH.FORGET_PASSWORD, PATH.NEW_PASSWORD, PATH.SIGNUP].includes(window.location.pathname)) setIsActiveFixedNav(false);
    else setIsActiveFixedNav(true);
  }, [ContentBody]);

  return (
    <>
      <CheckIconsProvider>
        <HeaderUser />
        <section style={{ margin: `52px 0 0` }} className={sectionId ? sectionId + ' userPage' : sectionId}>
          {ContentBody}
          {isActiveFixedNav && <FixedNav />}
        </section>
        <Footer />
      </CheckIconsProvider>
    </>
  );
};

export default LayoutBasic;
