import { Button, Form, Input, Layout, Select, message } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import { promptReviewTypeList, periodList, staffPick } from '@utils/constants';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import Table from '@components/Table';
import * as api from '@api/index';
import dayjs from 'dayjs';
import RangePicker from '@components/RangePicker';
import { useDebounce, makeSelectBoxList } from '@utils/lib';
import { useNavigate, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQuery, setPromptReviewList, setLocationKey, INIT_STATE_PROMPT_REVIEW_LIST, clearPromptReviewList } from '@stores/promptReviewList';
import { ADMIN_USER_DETAIL, ADMIN_PROMPT_REVIEW_DETAIL, PROMPT_REVIEW_HOME } from '@routes/pathName';
import RejectReason from '@pages/myPages/RejectReason';

const PromptReview = (props) => {
  const { key: locationKey } = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const promptReviewList = useSelector((s) => s.promptReviewList, shallowEqual);
  const [form] = Form.useForm();
  const promptListRef = useRef();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // 선택된 row index

  const aiModelList = useRef([]);
  const categoryList = useRef([]);
  const genreList = useRef([]);
  const [statList, setStatList] = useState([]);
  const [rejectRsnList, setRejectRsnList] = useState([]);
  const [rejectRsnModal, setRejectRsnModal] = useState(false); // 판매거절 모달
  const [selectedPrompt, setSelectedPrompt] = useState(null); // 선택된 프롬프트

  const today = dayjs(); // 오늘 날짜
  const oneWeekAgo = today.subtract(7, 'day'); // 일주일 전 날짜

  const defaultDate = useRef({
    st_date: oneWeekAgo.format('YYYY-MM-DD'),
    end_date: today.format('YYYY-MM-DD'),
  });

  // 판매거절 모달창 열기
  const openRejectSaleModal = (rowIndex, rejectedPrompt) => {
    setSelectedRowIndex(rowIndex);
    if (rejectedPrompt) setSelectedPrompt(rejectedPrompt);
    setRejectRsnModal(true);
  };

  // 판매거절 모달창 닫기
  const closeRejectSaleModal = () => {
    setSelectedPrompt();
    setRejectRsnModal(false);
  };

  const handleDate = ({ startDt, endDt }) => {
    const newQuery = { ...promptReviewList.query, startDt, endDt, offset: 0 };
    dispatch(setQuery(newQuery));
  };

  const columnLayout = [
    {
      dataField: 'cate_cd',
      headerText: '카테고리',
      width: '8%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = value;
        for (const categoryCode of categoryList.current) {
          if (categoryCode['cd'] == value) {
            retStr = categoryCode['cd_nm'];
            break;
          }
        }
        return retStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'ai_model_cd',
      headerText: 'AI모델',
      width: '8%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = value;
        for (const aiModelCode of aiModelList.current) {
          if (aiModelCode['cd'] == value) {
            retStr = aiModelCode['cd_nm'];
            break;
          }
        }
        return retStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'genre_cd',
      headerText: '장르',
      width: '8%',
      editable: false,
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = value;
        for (const genreCode of genreList.current) {
          if (genreCode['cd'] == value) {
            retStr = genreCode['cd_nm'];
            break;
          }
        }
        return retStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'mem_email',
      headerText: '회원ID',
      width: '14%',
      editable: false,
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          navigate(ADMIN_USER_DETAIL, { state: { mem_email: item?.mem_email, mem_key: item?.mem_key } });
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'prpt_title',
      headerText: '프롬프트명',
      width: '15%',
      editable: false,
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          navigate(ADMIN_PROMPT_REVIEW_DETAIL, {
            state: { prpt_id: item.prpt_id },
          });
        },
      },
    },
    { dataField: 'fir_dt', headerText: '생성일시', width: '10%', editable: false },
    { dataField: 'lst_dt', headerText: '최종상태일시', width: '10%', editable: false },
    {
      dataField: 'prpt_stat',
      headerText: '상태',
      width: '10%',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'value',
        valueField: 'label',
        list: statList,
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'reject_rsn',
      headerText: '거절사유',
      width: '10%',
      renderer: {
        type: 'ButtonRenderer',
        labelText: '상세보기',
        onClick: function (event) {
          openRejectSaleModal(event.rowIndex, event.item);
        },
        disabledFunction: function (rowIndex, columnIndex, value, item, dataField) {
          // 행 아이템의 name 이 Anna 라면 버튼 비활성화 처리
          if (['98'].includes(item?.prpt_stat)) return false;
          return true;
        },
      },
    },
    {
      dataField: 'music_org_link',
      headerText: '음원/동영상 링크',
      width: '17%',
      editable: false,
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
        // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
        jsCallback: function (rowIndex, columnIndex, value, item) {
          window.open(value, '_blank', 'noopenner, noreferrer');
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'staff_pick',
      headerText: '스탭 pick',
      editable: true,
      width: '10%',
      renderer: {
        type: 'DropDownListRenderer',
        showEditorBtnOver: true,
        keyField: 'value',
        valueField: 'label',
        list: staffPick,
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  const gridProps = {
    showRowCheckColumn: true,
    fillColumnSizeMode: true,
    editable: true,
    height: 630,
  };

  const getCommonCode = async () => {
    try {
      // AI모델
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      aiModelList.current = response1.data;
      // 카테고리
      const response2 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      categoryList.current = response2.data;
      // 장르
      const response3 = await api.fetchEqualCommonGroupCode({ grp_cd: 'GENRE' });
      genreList.current = response3.data;
      // 상태
      const response4 = await api.fetchEqualCommonGroupCode({ grp_cd: 'PRPT_STAT' });
      //작성완료, 임시저장 제외
      const madeSelectBoxStatList = makeSelectBoxList(response4.data.filter((item) => item.cd !== '00' && item.cd !== '01'), 'cd_nm', 'cd');
      setStatList(madeSelectBoxStatList);
      // 거절사유
      const response5 = await api.fetchEqualCommonGroupCode({ grp_cd: 'REJECT' });
      const madeSelectBoxRejectList = makeSelectBoxList(response5.data, 'cd_nm', 'cd');
      setRejectRsnList(madeSelectBoxRejectList);
    } catch (error) {
      console.error('공통코드 조회 error', error);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 그리드 이벤트 설정
  const setupGridEvents = ({ status, keyword_type, keyword_text, startDt, endDt, offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    promptListRef.current.bind([/*'vScrollChange', */'cellEditEnd'], (event) => {
      // 셀 수정완료시
      if (event.type === 'cellEditEnd') {
        // 프롬프트 상태가 판매거절일때
        if (event.item.prpt_stat === '98' && event.columnIdx === 7) {
          openRejectSaleModal(event.rowIndex);
        }
      }
    });
  };


  // 검색
  const handleSearchPromptReview = ({ status, keyword_type, keyword_text, period }) => {
    const newQuery = {
      ...promptReviewList.query, // 기존 검색 조건 유지
      status,
      keyword_type, // 새로운 검색 조건
      keyword_text,
      startDt: period?.startDt ? period?.startDt : promptReviewList.query.startDt ? promptReviewList.query.startDt : defaultDate?.current.st_date,
      endDt: period?.endDt ? period?.endDt : promptReviewList.query.endDt ? promptReviewList.query.endDt : defaultDate?.current.end_date,
      offset: 0, // 새 검색이므로 offset 초기화
      selector_1: '1'
    };
    handleDebounce(() => listPromptReview(newQuery));
  };

  const listPromptReview = async (query) => {
    try {
      const mergedQuery = { ...promptReviewList.query, selector_1: '1'};
      const { data } = await api.getPromptList(query);
      if (!data) return;

      dispatch(
          setPromptReviewList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
      );
      promptListRef.current.setGridData(data.items);
      dispatch(setQuery(mergedQuery));
      dispatch(setLocationKey(locationKey));
    } catch (error) {
      message.warning(error.message);
      console.log(error);
    }
  };

  const updatePromptReview = async () => {
    let saveItems = promptListRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }
    // 거절인데 사유없으면 return
    for (var i = 0; i < saveItems.length; i++) {
      var prptStat = saveItems[i].prpt_stat;
      var rejectRsnCd = saveItems[i].reject_rsn_cd;

      if ((prptStat == '98' && rejectRsnCd == '') || (prptStat == '98' && rejectRsnCd == null)) {
        alert('판매거절한 프롬프트의 거절사유를 선택하세요.');
        return;
      }
    }

    // 저장 - 상태, 거절사유, 가절사유상세, staffPick
    try {
      if (await window.confirm('저장하시겠습니까?')) {
        const response = await api.updatePromptReview(saveItems);
        if (response.data.returnStatus === 'success') {
          dispatch(clearPromptReviewList());
          message.success(response.data.returnMessage);
          listPromptReview(INIT_STATE_PROMPT_REVIEW_LIST.query);
        } else {
          message.error('저장에 실패했습니다.');
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 모달창 저장버튼 기능
  const saveRejectReason = (rejectReason) => {
    if (selectedRowIndex !== null) {
      const updatedData = {
        reject_rsn_cd: rejectReason?.reject_rsn_cd,
        reject_rsn: rejectReason?.reject_rsn,
      };

      promptListRef.current.updateRow(updatedData, selectedRowIndex);

      // 모달 닫기
      closeRejectSaleModal();
    } else {
      alert('선택된 행이 없습니다.');
    }
  };

  // 새로고침 이벤트 발생시
  const preventReload = () => {
    dispatch(clearPromptReviewList());
    navigate(PROMPT_REVIEW_HOME, { replace: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventReload);

    // 공통코드 조회
    getCommonCode();

    // 뒤로가기로 렌더링 됐을때
    if (locationKey === promptReviewList.locationKey) {
      promptListRef.current.setGridData(promptReviewList.items);
      promptListRef.current.setRowPosition(promptReviewList.yScrollPosition);
      setupGridEvents({
        keyword_type: promptReviewList.query.keyword_type,
        keyword_text: promptReviewList.query.keyword_text,
        status: promptReviewList.query.status,
        startDt: promptReviewList.query.startDt,
        endDt: promptReviewList.query.endDt,
        offset: promptReviewList.query.offset,
        limit: promptReviewList.query.limit,
        totalCount: promptReviewList.totalCount,
        currentCount: promptReviewList.currentCount,
      });
    }
    // 사이드 메뉴 선택으로 렌더링 됐을때
    else {
      const updatedQuery = {
        ...INIT_STATE_PROMPT_REVIEW_LIST.query,
        startDt: dayjs(defaultDate.current.st_date).format('YYYY-MM-DD'),
        endDt: dayjs(defaultDate.current.end_date).format('YYYY-MM-DD'),
      };

      listPromptReview(updatedQuery);
      form.setFieldsValue({
        keyword_type: INIT_STATE_PROMPT_REVIEW_LIST.query.keyword_type,
        keyword_text: INIT_STATE_PROMPT_REVIEW_LIST.query.keyword_text,
        startDt: defaultDate.current.startDt,
        endDt: defaultDate.current.endDt,
        status: INIT_STATE_PROMPT_REVIEW_LIST.query.status,
        period: '',
      });
    }

    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, []);

  return (
    <>
      <Layout>
        <Header className="l-divided">
          <Form className="l-flex gap" form={form} name="promptReviewList" layout="inline" onFinish={handleSearchPromptReview} initialValues={promptReviewList?.query}>
            <Form.Item name="status" style={{ width: '100px' }}>
              <Select options={statList} />
            </Form.Item>
            <Form.Item className="periodFilter" name="period">
              <RangePicker
                placeholderSelect="생성일시"
                optionsSelect={periodList}
                valueRangePicker={[promptReviewList?.query?.startDt ? dayjs(promptReviewList?.query?.startDt) : dayjs(defaultDate.current.st_date), promptReviewList?.query?.endDt ? dayjs(promptReviewList?.query?.endDt) : dayjs(defaultDate.current.end_date)]}
                onChange={handleDate}
              />
            </Form.Item>
            <Form.Item name="keyword_type" style={{ width: '130px' }}>
              <Select options={promptReviewTypeList} />
            </Form.Item>
            <Form.Item name="keyword_text">
              <Input placeholder="검색어를 입력해주세요." style={{ minWidth: '300px' }} allowClear />
            </Form.Item>
            <Button className="btn-searchIcon" type="primary" htmlType="submit">
              검색
            </Button>
          </Form>
        </Header>
        <Content className="l-content">
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '5px', gap: '10px' }}>
            <span>
              <strong>검색결과</strong>
            </span>
            <span>{`총 ${promptReviewList.totalCount}건`}</span>
            <div style={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
              <Button className="btn-pink" onClick={updatePromptReview}>
                저장
              </Button>
            </div>
          </div>
          <Table ref={promptListRef} columnLayout={columnLayout} customGridProps={gridProps} />
        </Content>
      </Layout>

      {/* 판매거절 모달 */}
      <RejectReason isOpen={rejectRsnModal} onClose={closeRejectSaleModal} onOk={saveRejectReason} promptInfo={selectedPrompt} selectOptions={rejectRsnList} />
    </>
  );
};

export default PromptReview;
