import React, {useCallback, useEffect, useRef, useState} from 'react';
import Loading from "@components/Loading";
import {useLocation} from "react-router-dom";
import { message, Layout, Form, Input, Button, Row, Col } from 'antd';
import Table from '@components/Table';
import { PlayCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import * as api from "@api/index";

const { Content } = Layout;

const BatchList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [batchStats, setBatchStats] = useState({ successCnt: 0, failCnt: 0 });
    const batchGridRef = useRef();

    const batchGridProps = {
        showRowCheckColumn: false,
        showRowNumColumn: false,
        showStateColumn: false,
        editable: false,
        selectionMode: 'single',
    };

    const batchColumnLayout = [
        { dataField: 'batch_seq', headerText: '배치 순번', width: '8%', visible: false },
        { dataField: 'batch_nm', headerText: '배치명', width: '10%' },
        { dataField: 'batch_desc', headerText: '배치 설명', width: '22%' },
        { dataField: 'run_seq', headerText: '실행된 순번', width: '5%' },
        { dataField: 'run_ret', headerText: '결과', width: '5%' },
        { dataField: 'pass_cnt', headerText: '성공 갯수', width: '5%' },
        { dataField: 'run_dt', headerText: '배치 실행 시간', width: '10%', formatString: 'yyyy-mm-dd HH:MM:ss', dataType: 'date' },
        { dataField: 'error_log', headerText: '배치 Log', width: '35%' },
        { dataField: 'batch_btn', headerText: '배치 수행', width: '6%', headerStyle: 'required-custom-header',
            renderer: {
                type: 'ButtonRenderer',
                labelText: '실행',
                onclick: function (rowIndex, columnIndex, value, item) {
                    handleRunBatch(item.batch_seq);
                },
            },
        },
    ];

    const getBatchList = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data } = await api.getBatchList();
            if (!data) return;

            if(batchGridRef.current) {
                batchGridRef.current.setGridData(data);
            }

            calculateBatchStats(data);
        } catch (error){
            message.warning(error.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const calculateBatchStats = (data) => {
        const stats = data.reduce(
            (acc, item) => {
                if (item.run_ret === "S") acc.successCnt++;
                if (item.run_ret === "F") acc.failCnt++;
                return acc;
            },
            { successCnt: 0, failCnt: 0 }
        );
        setBatchStats(stats);
    };

    const handleRunBatch = useCallback(async (batchSeq) => {
        try {
            if(await window.confirm('배치를 실행하시겠습니까?')){
                const { data } = await api.runBatch({batch_seq: batchSeq});
                if(!data) return;
                // 실행 완료 후 목록 새로고침
                await getBatchList();
            }
        } catch(error) {
            message.warning(error.message);
        }
    }, []);



    useEffect(() => {
        getBatchList();
    }, []);

    return (
        <Layout>
            <Loading isLoading={isLoading} />
            <Content className='l-content'>
                <Row style={{marginTop: '10px', alignItems: 'center'}}>
                    <Row style={{marginLeft: '5px'}}>실행결과 : </Row>
                    <span style={{marginLeft: '5px'}}>{`성공 ${batchStats.successCnt} 건,`}</span>
                    <span style={{marginLeft: '10px'}}>{`실패 ${batchStats.failCnt} 건`}</span>
                </Row>
                <Table
                    ref={batchGridRef}
                    columnLayout={batchColumnLayout}
                    customGridProps={batchGridProps}
                />
            </Content>
        </Layout>
    );
};

export default BatchList;