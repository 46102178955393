import React from 'react'
import {Modal} from 'antd';

const SalesTerms = (props) => {

  const { visible, onClose } = props;

  const handleOpen = () => {
    document.querySelector(".ant-modal-body .wrap").scrollTop = 0
  }

  return (
    <>
      <Modal
        className="termsModal"
        open={visible}
        onCancel={onClose}
        footer={null}
        afterOpenChange={handleOpen}
      >
        <h1>마켓플레이스 판매자 이용약관 2024. 12. 13 ver</h1>
        <div className="wrap">
          <div>
            <h3>제 1 조 (목적)</h3>
            <p>이 약관은 프롬프트뱅크(주)(이하 “회사”)가 운영하는 마켓플레이스에서 제공하는 서비스와 이를 이용하는 판매자자의 권리·의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다. 본 약관에서 특별히 규정되지 않은 사항은 “프롬프트뱅크 서비스 이용약관”과 “마켓플레이스 서비스 이용약관”을 따릅니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 2 조 (용어의 정의)</h3>
            <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
            <p><strong>1. 마켓플레이스:</strong> 회사가 서비스를 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 회원이 이용할 수 있도록 설정한 가상의 영업장(www.promptbank.com 등 회사가 운영하는 웹사이트 및 모바일 웹, 앱 등을 모두 포함)을 의미합니다.</p>
            <p><strong>2. 서비스:</strong> 판매자가 제공하는 게시물 및 용역 등의 판매를 돕기 위한 회사에서 제공하는 각종 디지털컨텐츠 및 제반 서비스를 말합니다.</p>
            <p><strong>3. 회원:</strong> 프롬프트뱅크가 제공하는 서비스 이용약관에 동의하고 서비스를 이용하는 자를 의미합니다.</p>
            <p><strong>4. 판매자:</strong> 회사가 제공하는 마켓플레이스를 통하여 게시물이나 용역 등을 판매할 목적으로 본 약관을 동의하고 인증받은 회원을 말합니다.</p>
            <p><strong>5. 구매자:</strong> 회사 또는 판매자가 판매하는 게시물 및 용역 등을 구입할 의사를 가지고 회사가 제공하는 서비스를 이용하는 회원을 말합니다. </p>
            <p><strong>6. 게시물:</strong> 회사가 제공하는 마켓플레이스 서비스를 이용하여 회사 또는 판매자가 판매하는 부호·문자·이미지·음성·음향·화상·동영상 등의 정보 형태의 글, 그림, 사진, 동영상 및 각종 파일과 링크 등의 디지털 컨텐츠 또는 소프트웨어를 의미합니다.</p>
            <p><strong>7. 용역 등:</strong> 회사가 제공하는 마켓플레이스 서비스를 이용하여 회사 또는 판매자가 판매하는 재화 또는 용역 일체를 말합니다.</p>
            <p><strong>8. 프로필:</strong> 판매자가 본인의 홍보를 목적으로 AI 경력·학력·자격 사항 및 업무 형태 등을 텍스트, 이미지, 문서 등으로 구성된 게시물 형태로 등록한 것을 의미합니다.</p>
            <p><strong>9. 할인쿠폰:</strong> 게시물, 용역 등을 구매할 때나 마켓플레이스에서 제공하는 서비스를 이용할 때 표시된 금액 또는 비율만큼 할인 받을 수 있는 쿠폰을 말합니다.</p>
            <p>10. 이 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 프롬프트뱅크 서비스 이용약관, 관계 법령 및 개별약관 등(이 약관에서 정의하는 바에 따름)에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 3 조 (약관의 공시 및 효력과 변경 등)</h3>
            <p>1. 회사는 이 약관의 내용을 판매자가 쉽게 알 수 있도록 웹사이트의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 판매자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다. 본 약관에 동의한 판매자 모두에게 그 효력이 발생합니다.</p>
            <p>2. 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p>
            <p>3. 회사가 이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 회사의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 판매자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.</p>
            <p>4. 판매자는 개정된 약관에 대해 거부할 권리가 있으며, 개정된 약관에 동의하지 않을 경우 이용계약 해지를 요청할 수 있으며, 개정된 약관의 효력 발생일 이후에도 서비스를 계속 이용할 경우 약관의 변경사항에 동의하는 것으로 간주합니다. 판매자가 개정약관에 동의하지 않을 경우 판매자는 이용계약을 해지할 수 있습니다.</p>
            <p>5. 회사는 이 약관 외에 별도의 이용약관 및 정책(이하 “개별약관등”)을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 개별약관 등이 우선하여 적용됩니다.</p>
            <p>6. 본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 운영정책, 규칙, 도움말, 홈페이지의 공지 또는 통지(이하 ‘세부지침’) 및 판매자와 회사 간 체결한 별도의 서면 계약(이하 ‘서면 계약’) 내용에 따릅니다. 별도의 서면 계약을 체결한 판매자자에 대해서도 본 약관 및 세부지침이 적용됩니다.</p>
            <p>7. 본 약관과 세부지침 또는 서면 계약의 내용이 상충될 경우 서면 계약, 세부지침, 본 약관의 순서로 우선하여 적용됩니다. 세부지침도 서비스 이용 계약의 일부를 구성합니다.</p>
            <p>8. 회사는 서비스 이용과 관련하여 범죄를 예방하고 사회윤리를 존중하는 것을 내용으로 하는 회사와 판매자가 준수해야 하는 윤리가이드를 서비스 내에 게시할 수 있으며, 해당 윤리가이드는 이 약관의 내용을 이룹니다. 판매자는 이 약관에 동의함으로써 윤리가이드의 준수에 동의한 것으로 봅니다.</p>
            <p>9. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 개별약관등 및 관계 법령 또는 상관례에 따릅니다.</p>
            <p>10. 회사가 저작권자 등 컨텐츠 제공자로부터 이용 또는 사용을 허락 받아 판매자의 이용에 제공하는 유료 서비스에 대해서는 컨텐츠 제공자의 국적 국가의 법 및 조약이 적용될 수 있습니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 4 조 (회사의 의무)</h3>
              <p>1. 회사는 관련 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위해 최선을 다하여 노력합니다.</p>
              <p>2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호법 및 관련 법령이 정하는 바에 따라 보안 시스템 등을 갖추고 회원의 개인정보를 보호하기 위해 노력하며, 회사의 귀책사유로 인한 회원의 개인정보 도용 및 유출 등에 대해 책임을 집니다.</p>
              <p>3. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 웹사이트(www.promptbank.com) 이외의 링크 사이트에 대해서는 회사의 개인정보처리방침이 적용되지 않습니다.</p>
              <p>4. 회사는 서비스 이용과 관련하여 발생하는 회원의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.</p>
              <p>5. 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 서비스 홈페이지, 이메일, 또는 고객문의 전용 메신저 등을 통하여 회원에게 처리과정 및 결과를 전달할 수 있습니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 5 조 (판매자의 의무)</h3>
            <p>1. 판매자는 관련 법령, 이 약관 및 회사의 운영정책, 고객센터 내 도움말, 서비스와 관련하여 회사가 공지한 주의사항 및 통지사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</p>
            <p>2. 판매자는 다음 각 호의 행위를 하여서는 안 됩니다.</p>
            <ul>
              <li> ○ 마켓플레이스 내 허위 내용 등록 및 타인의 정보를 도용하는 행위 </li>
              <li> ○ 회사와 기타 제3자의 저작권 등 지식재산권에 대한 침해</li>
              <li> ○ 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 </li>
              <li> ○ 회원이 회사가 제공하는 마켓플레이스 서비스를 이용하지 아니하고 구매자와 직접 거래하는 행위(이하 “직거래”) 또는 이를 유도하는 행위 </li>
              <li> ○ 판매 활동을 위해 인증한 판매자가 아닌 다른 판매자가 서비스를 제공하는 행위 </li>
              <li> ○ 회사의 사전 동의 없이 판매자의 권리∙의무를 타인에게 양도, 증여하거나 담보로 제공하는 행위 </li>
              <li> ○ 게시물 및 용역 등을 재판매 목적으로 구매하여 제3자에게 판매하는 행위 </li>
              <li> ○ 회사 또는 판매자의 판매 활동을 방해하거나 마켓플레이스를 이용하여 부당한 이득을 취하는 등 통상적인 전자상거래 관행에 부합하지 않는 거래상의 부정 행위 </li>
              <li> ○ 회사의 동의 없이 영리를 목적으로 마켓플레이스 서비스를 사용하는 행위 </li>
              <li> ○ 컨텐츠 판매 또는 용역의 제공을 가장한 현금 융통 등 『여신전문금융업법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』 및 기타 관계 법령에 위배되거나 비정상적인 결제 행위 </li>
              <li> ○ 회사가 제공하는 서비스 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나 회사의 정보처리시스템에 접근하는 행위 </li>
              <li> ○ 회사가 제공하는 서비스와 관련 없거나 비합리적인 요청, 공서양속에 반하는 행위 등으로 회사의 업무를 방해하는 행위 </li>
              <li> ○ 회원이 회사의 서비스를 이용하여 대한민국 및 기타 국가 또는 국제기구(UN, 미국, 유럽연합, 영국 등을 포함하며 이에 한정하지 아니함)가 시행 및 집행하는 제재조치, 수출입 통제 법령이나 규칙, 제한 등을 위반하는 행위 </li>
              <li> ○ 회원이 회사의 서비스를 이용하여 제공받은 상품, 소프트웨어, 기술 서비스 등을 수출입 제재 대상국가 혹은 제제 대상자 및 조직에 재수출 혹은 재판매하는 행위 </li>
              <li> ○ 기타 위 각 호에 준하여 회사 또는 제3자의 권리를 침해 또는 침해할 우려가 있거나, 전자상거래 질서를 위협하는 행위 및 불법적이거나 부당한 행위 </li>
            </ul>
            <p>3. 회사는 제2항을 위반한 회원에 대하여 계약 해제, 할인쿠폰 또는 크레딧 등의 환수, 회원 자격의 제한, 현금 환불 보류 등의 조치 및 민∙형사 상의 법적 조치 등을 취할 수 있습니다.</p>
            <p>4. 판매자는 회사가 서비스의 판매 가능 여부와 신뢰도 상승을 위해 요청한 판매자 정보/프로필/이미지/기타 필요한 사항 등의 등록 기준을 확인하고, 이와 관련한 회사의 요청사항이 있는 경우 협조해야 합니다.</p>
            <p>5. 판매자는 판매 게시물 및 용역 등의 설명, 가격 정보 및 옵션 등 서비스 상세 정보에 대해 사실만을  구체적으로 명시해야 하며, 예외적인 범위에 대해서는 구매자와 사전 합의해야 합니다.</p>
            <p>6. 판매자는 원칙적으로 서비스 상세 정보에 스스로 제공할 수 있는 서비스의 내용만을 명시해야 하며, 일부 또는 전부를 도급∙위임해야 하는 서비스에 대해서는 회사가 승인하는 범위 내에서 추가로 명시할 수 있습니다. 이 경우, 구매자와는 별도의 사전 합의를 진행해야 합니다.</p>
            <p>7.  판매자는 서비스 상세 정보에 직접 결제를 유도하는 문구나 구매자의 정당한 권리(청약 철회권 등)를 제한하거나, 기타 법령이나 약관에 위배되는 내용을 명시할 수 없습니다.</p>
            <p>8. 판매자는 구매자의 문의에 성실하게 응답해야 합니다.</p>
            <p>9. 판매자는 게시물, 용역 등을 판매 불가한 사유가 발생한 경우 회사에서 제공하는 기능을 사용하여 판매를 중단 설정하여야 하며, 거래가 진행 중인 경우에는 구매자에게 지체없이 그 사실을 알리고 필요한 조치를 취해야 합니다.</p>
            <p>10. 판매자는 계약 이행 과정에서 알게 된 구매자 등 다른 회원의 개인 정보를 법률·약관 또는 회사의 개인정보처리방침에서 정한 목적 외의 용도로 사용할 수 없습니다. 회사의 고의 또는 과실과 무관하게 특정 판매자가 처리·처리 중인 다른 회원 또는 제3자의 개인 정보가 침해된 경우, 회사는 그에 대하여 책임을 지지 않으며 판매자가 자신의 노력과 비용으로 회사를 면책시켜야 합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 6 조 (판매자에 대한 통지 및 공지)</h3>
            <p>1. 회사가 판매자에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 판매자가 제공한 이메일 주소, (휴대)전화번호, 공지사항 안내, 배너, 문자메시지, 알림톡, 고객센터 게시글 등 서비스 내 각종 통지 수단으로 할 수 있습니다.</p>
            <p>2. 회사는 판매자의 권리에 중대한 영향을 미치지 않는 사항에 대해서는 7일 이상 회사가 제공하는 서비스에 공지함으로써 제1항의 통지에 갈음할 수 있습니다.</p>
            <p>3. 회사가 판매자에게 통지해야 하는 경우 판매자가 등록한 이메일 주소 등을 이용하거나 서비스 내 알림 등을 통하여 통지하며, 소유자에게 통지한 경우 이는 판매자에게 통지한 것으로 봅니다. 판매자는 소유자 정보에 대한 관리 및 적절한 정보로의 변경 책임을 부담합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 7 조 (판매자 인증 및 평가)</h3>
            <p>1. 판매자는 회원가입 후 판매자 인증을 거쳐야 판매 활동을 시작할 수 있으며, 사업자 정보가 있어야 인증이 가능합니다.</p>
            <p>2. 판매자 인증은 회사가 정한 인증 양식에 따른 사항을 기입하고 필요 시 증빙 서류를 첨부하여 저장하면 회사가 이를 확인, 대조 후 승인합니다. 승인이 완료되면 판매자 인증이 완료됩니다. 단, 판매자가 작성한 정보가 증빙 서류와 불일치하는 경우에는 승인이 거절될 수 있습니다. </p>
            <p>3. 판매자가 인증 시 등록한 사항에 변경이 있는 경우에는 상당한 기간 내에 회사에 회원 정보를 수정하는 등의 방법으로 그 변경사항을 알려야 하며, 변경된 회원 정보를 회사에 알리지 않아 발생하는 손해에 대한 책임은 판매자에게 있습니다.</p>
            <p>4. 판매자는 판매자 인증 시 회사로부터 수익금을 수령할 계좌를 지정하여야 하며, 회사가 정하는 바에 따라 계좌를 변경할 수 있습니다. 판매자가 지정한 입금계좌의 예금주는 판매자와 동일인(개인 판매자의 경우 가입자 본인 명의, 사업자 판매자의 경우 대표자 혹은 사업장 명의) 임을 원칙으로 합니다.</p>
            <p>5. 회사는 평가제도를 운영하여 판매자의 등급을 결정하는데 사용할 수 있으며, 등급에 따라 판매자는 다른 혜택을 부여 받을 수 있습니다. 회사는 평가 방법, 등급 및 등급별 혜택 등의 내용을 관련 서비스 화면을 통해 공지합니다. </p>
          </div>
          <br/>

          <div>
            <h3>제 8 조 (회원 정보의 제공 및 관리)</h3>
            <p>1. 회원은 본 약관 등에 의해 회사에 정보를 제공하여야 하는 경우에는 진실되고 적법한 정보를 제공하여야 하며, 허위 또는 불법 정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.</p>
            <p>2. 회원의 계정 및 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다. 회사는 제3자가 회원의 계정 및 비밀번호 등을 무단으로 사용하는 것을 막기 위하여 비밀번호 입력 및 추가적인 본인 확인 절차를 거치도록 할 수 있습니다.</p>
            <p>3. 회원이 이용 신청 시 알려주신 내용에 변동이 있을 때에는 직접 수정하시거나 이메일, 고객센터를 통하여 회사에 알려 주시기 바랍니다.</p>
            <p>4. 본 조의 의무를 위반하여 발생하는 모든 책임은 회원에게 있습니다. 회원이 본 조의 의무를 위반하거나 세부지침 또는 회사의 안내를 따르지 않아 발생하는 불이익에 대하여 회사는 책임을 지지 않습니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 9 조 (회원 정보의 보호)</h3>
            <p>1. 회사는 본 약관에 벗어난 목적으로 서비스와 관련한 회원 정보에 접근하거나 처리하지 않습니다. 다만, 장애 처리, 회원의 정보보호 등 원활한 서비스 제공을 위하여 접근이 필요한 경우 회원 정보에 접근, 내용을 파악할 수 있습니다.</p>
            <p>2. 회원이 서비스 이용 중에 보관한 회원 정보는 회원의 소유로서 회원이 관리하여야 하며, 회사는 법령이 정하는 바에 따라 회원 정보를 보호합니다.</p>
            <p>3. 회사는 적절한 수준의 보안서비스를 제공하고 사고를 방지할 의무가 있으며 회원은 회원의 주의 의무 위반으로 인한 회원 정보의 도용 및 유출 등에 대해서 책임을 집니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 10 조 (회원 탈퇴 및 자격 상실 등)</h3>
            <p>1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 회사는 회원의 회원탈퇴 요청 즉시 회원탈퇴를 처리합니다. 회원탈퇴 시 회원자격이 상실되며, 회사가 제공하는 각종 할인쿠폰, 이벤트 혜택 등이 소멸됩니다. 단, 회원탈퇴 시 소진되지 않은 할인쿠폰, 크레딧 등이 있는 경우에 이에 대한 권리를 포기한 것으로 간주하여, 탈퇴와 동시에 전부 소멸됩니다. 다만, 회사의 이용 대금 후불결제 서비스 이용요금을 결제하지 않은 경우, 탈퇴가 제한됩니다.</p>
            <p>2. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원의 자격을 제한 또는 상실시킬 수 있습니다.</p>
            <ul>
              <li>○ 회원정보에 허위 내용을 입력하거나 타인의 정보를 도용한 경우</li>
              <li>○ 마켓플레이스을 이용하여 구입한 재화 등의 대금, 기타 마켓플레이스 이용과 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우</li>
              <li>○ 다른 사람의 마켓플레이스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
              <li>○ 서비스를 통해 회원이 처리하는 회원의 정보 및 게시물, 컨텐츠에 대해 제3자가 권리 침해를 주장하는 등(이를 포함하되 이에 한정 짓지 않음) 회원의 서비스 이용을 정지시킬 만한 법적 근거가 있는 경우</li>
              <li>○ 주소, 전자우편 등 회원정보를 잘못 입력하거나 변경하지 않는 등 회원의 귀책사유로 인하여 회사가 상당한 노력을 기울였음에도 불구하고 회원에게 통지 또는 연락을 할 수 없게 된 경우</li>
              <li>○ 이 약관 제 5 조 판매자의 의무를 위반하는 행위를 하는 경우</li>
              <li>○ 회사의 정상적인 서비스 운영을 방해한 경우</li>
              <li>○ 회원이 국가적 이익이나 공익에 반하는 목적을 위해 서비스를 이용하는 경우</li>
              <li>○ 그 밖에 회원이 회사가 정한 본 약관, 세부지침 또는 관련 법령을 위반하거나 위 각 호에 준하는 사유로 이용 제한 또는 정지가 필요한 경우</li>
            </ul>
            <p>3. 회사가 본 조 제2항에 따라 회원의 서비스 이용을 제한하거나 정지하는 경우, 회사에 귀책사유가 없을 시 회원은 이로 인한 손해배상을 회사에 청구할 수 없습니다.</p>
            <p>4. 회사가 본 조 제2항에 따라 회원의 서비스 이용을 제한하거나 정지하는 경우, 서면, 이메일, 전화, 기타 방법을 통하여 그 시정 또는 개선을 요구할 수 있고,  30일 이내에 귀책 당사자의 시정 또는 개선 조치가 없을 경우 서면, 이메일, 전화, 기타 방법을 통하여 통지한 후 서비스의 제공을 중지할 수 있습니다.</p>
            <p>5. 회사는 본 조에 따른 서비스 이용 제한 또는 정지 기간 중 발생한 이용요금을 회원에게 청구할 수 있습니다.</p>
            <p>6. 회사가 회원 자격을 상실시키는 경우에는 회원 등록을 말소하며, 이때 회사는 거래 안전을 위하여 해당 회원의 소유로 확인되는 아이디의 회원 등록을 일괄 말소할 수 있습니다. 이 경우 회사는 이를 제7조에 따라 회원에게 통지하고, 회원 등록 말소 전 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 11 조 (서비스의 제공 및 변경)</h3>
            <p>1. 회사는 다음 각 호와 같은 서비스를 제공합니다.</p>
            <ul>
              <li>○ 중개서비스: 회사가 판매자와 구매자 간에 게시물 및 용역 등을 거래할 수 있도록 제공하는 서비스 및 부가서비스 일체를 말합니다.</li>
              <li>○ 전자상거래 서비스(통신판매중개서비스 포함) 및 이에 수반 되는 기타 서비스</li>
              <li>○ 기타 회사가 정하는 서비스 또는 업무</li>
            </ul>
            <p>2. 회사가 제공하기로 회원과 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 회원에게 통지 가능한 방법으로 즉시 통지합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 12 조 (판매 게시물 및 용역 등의 등록)</h3>
            <p>1. 판매자가 마켓플레이스에 등록 또는 게시한 게시물, 용역 등에 대한 모든 책임은 판매자에게 있으며, 회사는 판매자의 게시물, 용역 등이 다음 각 항에 해당한다고 인정되는 경우 사전 통지 없이 해당 게시물, 용역 등에 대한 접근을 임시적으로 차단하는 조치를 할 수 있고, 이 경우에 회사는 판매자에게 지체없이 그 사유를 통지합니다. 게시물, 용역 등에 대한 임시 차단 조치에 대하여 판매자는 이의를 제기할 수 있습니다.</p>
            <ul>
              <li>○ 타인을 모욕, 비방, 비하하거나 타인의 명예를 훼손하는 경우</li>
              <li>○ 범죄 및 불법 행위에 악용될 수 있는 정보를 담고 있는 경우</li>
              <li>○ 타인의 저작권, 상표권 등 권리를 침해하는 경우</li>
              <li>○ 음란물 또는 일반인의 성적 굴욕감이나 수치심을 유발할 수 있는 내용을 담고 있는 경우(그러한 내용이 담긴 웹사이트, 앱 등으로 연결될 수 있는 게시물을 포함)</li>
              <li>○ 어린이와 청소년의 정신적, 신체적 건강을 해칠 우려가 있는 선정적인 내용을 담은 경우</li>
              <li>○ 특정인의 개인정보가 노출된 경우</li>
              <li>○ 특정 상품 등을 소개하여 판매하거나, 이를 구매, 사용하도록 권하거나 연락을 유도하는 등 직간접적으로 영리추구를 위한 내용을 담고 있는 경우</li>
              <li>○ 마켓플레이스의 이용 목적에 부합하지 않거나 해당 서비스의 취지와 무관한 내용의 경우</li>
              <li>○ 악성코드나 스파이웨어 등이 실행되어 다른 회원, 회사, 판매자 기타 제3자의 시스템 성능 저하, 개인정보 유출 등의 피해를 줄 수 있는 악의적인 코드를 담고 있는 경우</li>
              <li>○ 정당한 사유 없이 회사의 영업을 방해하는 내용의 경우</li>
              <li>○ 기타 이 약관 또는 법령에 위배되거나 공서양속에 반하는 내용의 경우</li>
            </ul>
            <p>2. 판매자는 판매 게시물 및 용역 등의 등록 시 구매자가 구매서비스에 대한 정보를 확인할 수 있도록 다음과 같은 사항을 정확하게 기재하여야 합니다.</p>
            <ol>
              <li>a. 게시물 또는 용역 등의 상세 정보</li>
              <li>b. 판매 가격</li>
              <li>c. 특별한 거래 조건이 있거나 추가되는 비용이 있는 경우 그에 관련된 사항</li>
            </ol>
            <p>3. 회사는 판매자의 판매 게시물 및 용역 등의 등록 요청이 있으면 이를 검토하여 승인하며, 판매자는 승인된 후에 판매할 수 있습니다.</p>
            <p>4. 판매자는 판매 게시물 및 용역 등의 등록이 승인된 이후에도, 승인 여부에 결정적인 영향을 미치지 않는 일부 내용에 한해 수정할 수 있습니다. 단, 수정되기 이전에 판매된 건에 대해서는 판매 시점의 정보가 적용됩니다.</p>
            <p>5. 판매자는 판매 게시물 및 용역 등이 등록이 승인된 이후 별도의 기한 없이 판매를 지속할 수 있습니다. 다만, 본 약관에서 정한 바에 따라 회원자격이 정지되거나 서비스의 이용 제한, 기타 필요한 조치가 취해지는 등 판매 활동이 제한될 수 있으며, 이 경우 회사는 판매 가능 여부를 재검토할 수 있습니다.</p>
            <p>6. 판매자가 탈퇴하는 경우, 탈퇴 전에 작성한 게시물, 용역 등(댓글 포함)은 삭제되지 않습니다. 회원 탈퇴 시 회원정보가 삭제되어 작성자 본인을 확인할 수 없게 되므로 게시물, 용역 등에 대한 편집 또는 삭제가 원천적으로 불가하며, 게시물, 용역 등의 삭제를 원하는 판매자는 반드시 회원 탈퇴 이전에 해당 내용을 삭제하여야 합니다.</p>
            <p>7. 판매자는 회사 또는 회사가 제휴한 타사에 판매자가 작성한 게시물, 용역 등을 상품 등의 연구개발, 판촉, 홍보 등의 서비스 제공을 위한 범위 내에서 공중송신∙복제∙배포∙공연∙대여∙전시∙2차적 저작물 작성∙서브라이선스를 할 수 있는 비배타적인 사용권을 부여하며, 상품 등의 연구개발, 판촉, 홍보 등의 서비스 제공을 위한 범위 내에서 회사가 수정 및 편집하여 이용할 수 있고 저작자 표시를 생략할 수 있음에 동의합니다. 이 경우 회사는 해당 게시물, 용역 등로부터 판매자의 개인정보가 식별되지 않도록 필요한 조치를 합니다. 게시물, 용역 등의 활용이 부적절하거나 동의한 범위 및 목적 외로 사용되는 경우 판매자는 회사에 고객센터 등으로 의견을 제기할 수 있으며 회사는 그에 따른 적절한 절차를 마련하여 운영합니다. 다만, 본 조항의 어떤 내용도 법령에서 허용되는 범위를 넘어서는 사용권을 부여하는 것은 아닙니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 13 조 (게시물, 용역 등의 지적 재산)</h3>
            <p>1. 회원은 마켓플레이스 서비스 내에 게시한 게시물, 용역 등의 지적 재산은 해당 게시물의 저작자에게 귀속됩니다.</p>
            <p>2. 회원은 타 회원의 게시물을 구매하거나 사용함으로써 게시물에 대한 소유권을 취득하지 않았음을 인정합니다.</p>
            <p>3. 회사는 마켓플레이스 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 정보, 결제수단 등을 이용할 수 있는 권리만을 부여하며, 회원은 저작자가 허용하지 않는 한 이를 양도, 판매, 담보제공 등의 방법으로 처분하여서는 아니됩니다.</p>
            <p>4. 회원은 마켓플레이스 서비스를 이용함에 있어서 제3자의 저작권, 상표 또는 기타 지적 재산권을 침해하지 않도록 할 책임이 있습니다.</p>
            <p>5. 회원이 마켓플레이스 서비스 내에 게시하는 게시물은 마켓플레이스 서비스, 관련 프로모션 등에 노출(해당 노출을 위해 필요한 범위 내에서의 일부 수정, 복제, 편집 포함)될 수 있고, 마켓플레이스 서비스를 위한 연구목적으로 활용될 수 있습니다. 이 경우 회사는 관련 법령을 준수하며, 회원은 언제든지 고객센터 또는 회사 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.</p>
            <p>6. 회사는 제5항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻어야 합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 14 조 (게시물, 용역 등의 관리)</h3>
            <p>1. 회사는 불법 여부 또는 AI 윤리 규정 위반 여부를 판단하기 위해 컨텐츠를 검토할 수 있으며 회사의 정책 또는 법규를 위반한다고 합리적으로 판단되는 컨텐츠는 삭제하거나 게시를 거부할 수 있습니다.</p>
            <p>2. 회원의 게시물, 용역 등에 정보통신망이용촉진및정보보호등에관한법률, 저작권법 등 관련법에 위반되는 내용이 포함된 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물, 용역 등의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야 합니다.</p>
            <p>3. 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물, 용역 등에 대해 임시조치 등을 취할 수 있습니다.</p>
            <p>4. 본 조에 따른 세부절차는 정보통신망이용촉진및정보보호등에관한법률, 저작권법이 규정한 범위 내에서 회사가 정한 게시중단요청서비스에 따릅니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 15 조 (금지행위 및 이용제한)</h3>
            <p>1. 회사는 판매자가 본 약관 및 회사의 서비스 운영을 위하여 정하는 정책에 따른 의무를 위반하거나 서비스의 정상적인 운영을 방해하거나 다음 각 호에 해당하는 경우, 서비스 판매를 중지하거나 경고, 사전 통보 후 이용정지 및 영구 이용정지 등으로 서비스 이용을 제한할 수 있고 민형사상의 책임을 물을 수 있습니다.</p>
            <ul>
              <li>○ 판매자에게 프롬프트뱅크 서비스 이용약관 제5조에서 정하고 있는 가입 제한 사유, 제14조에서 정하고 있는 이용제한 사유가 있음이 확인된 경우</li>
              <li>○ 회사가 제공하는 서비스 이용과 관련하여 판매자가 부담하는 채무를 기일에 지급하지 않는 경우</li>
              <li>○ 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
              <li>○ 서비스를 이용하여 법령 또는 본 약관 및 서비스 운영정책에서 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
              <li>○ 회원 간 직접 결제 등 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우</li>
              <li>○ 본인 또는 다른 회원의 아이디를 이용하여 내용, 조건 등이 실질적으로 동일한 용역 등을 중복하여 등록·판매 한 경우</li>
              <li>○ 판매자에게 파산·금치산·한정치산·회생·파산의 결정 또는 선고, 사망, 실종선고, 해산, 부도 등 정상적 서비스 이용을 불가능하게 하거나 곤란하게 하는 사유가 발생한 경우</li>
              <li>○ 제 12 조 (판매 게시물 및 용역 등의 등록)의 제 1 항에 해당하는 사유가 발생한 경우</li>
            </ul>
          </div>
          <br/>

          <div>
            <h3>제 16 조 (게시물 및 용역 등의 공급)</h3>
            <p>1. 회사는 구매자가 결제한 이후 웹사이트 내 기능 또는 메일(e-mail) 등의 알림 채널을 통하여 판매자에게 접수된 주문 정보를 통지합니다. 판매자는 회사가 발송하는 알림 및 판매 관리 서비스 화면을 통하여 주문 내역을 확인할 수 있습니다.</p>
            <p>2. 판매자가 게시물 등의 디지털 컨텐츠를 판매하는 경우, 1항에 따른 주문 접수와 동시에 등록한 컨텐츠가 발송됩니다. 판매자는 이를 인지하고 작업물을 항상 최신 상태로 유지해야 하며, 구매자에게 추가 안내가 필요한 경우에는 별도의 안내를 진행해야 합니다.</p>
            <p>3. 판매자가 용역 등을 판매하는 경우, 1항에 따른 주문 정보를 통지 받은 후 작업을 진행합니다. 정해진 기간 내에 작업이 불가하거나 구매자와 합의한 작업 및 업무 범위를 변경해야 하는 경우, 판매자는 즉시 구매자와 별도 합의를 진행해야 합니다.</p>
            <p>4. 판매자는 상세페이지에 명시한 작업 범위(구매자와 별도로 합의한 업무 범위가 있다면 구매자와 합의한 작업범위를 포함함)를 완료하지 않은 상태에서 구매자에게 작업물을 발송하거나 구매 확정을 요청해서는 안 됩니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 17 조 (거래 중 문제해결)</h3>
            <p>1. 판매자는 구매자가 작업 의뢰 시 수정·재진행 등에 관한 내용을 작성해야 하며, 작업물을 수령한 구매자가 7일 이내 수정을 요청한 경우 이에 따라 수정 작업을 진행해야 합니다. 수정·재진행은 일부 용역 등의 유형이나 일부 판매자 카테고리에 따라 제공하지 않을 수 있습니다.</p>
            <p>2. 판매자는 구매자와 협의 중 명시한 작업 범위(구매자와 별도로 합의한 업무 범위가 있다면 구매자와 합의한 작업범위를 포함함)를 모두 완료한 경우 수정·재진행 요청을 거절할 수 있으며, 명시된 무상 수정·재진행 범위를 벗어나는 등 추가적인 작업이 필요한 경우에는 구매자와의 합의 하에 추가 결제를 요청할 수 있습니다.</p>
            <p>3. 판매자는 구매 확정된 용역 등에 대해 구매자가 수정 요청하는 경우 구매자와 별도로 합의하여야 합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 18 조 (취소 및 환불)</h3>
            <p>1. 판매자가 게시물 등의 디지털 컨텐츠를 판매하는 경우, 프롬프트뱅크 마켓플레이스 서비스 이용약관 제 22 조 (청약철회 등)에 따라 취소 및 환불 여부가 결정됩니다.</p>
            <p>2. 판매자가 용역 등을 판매하는 경우, 거래 취소는 판매자와 구매자 모두가 상대방에게 요청할 수 있으며, 프롬프트뱅크 마켓플레이스 서비스 이용약관 제 22 조 (청약철회 등)과 당사자 간의 합의에 따라 결정됩니다.</p>
            <p>3. 판매자는 예상치 못한 사유로 정상적인 작업 진행이 어려운 경우 등 용역 등의 제공이 불가한 경우에는 지체없이 구매자에게 거래 취소 사유를 안내하고 조취를 취하여야 합니다.</p>
            <p>4. 판매자가 구매자로부터 거래 취소(청약철회)를 요청받은 경우, 청약철회 가능 여부를 검토하고 환불에 필요한 조치를 합니다. 다만 판매자는 다음 각 호의 어느 하나에 해당하면 구매자의 요청을 거부할 수 있습니다.</p>
            <ol>
              <li>○ 회원 탈퇴한 구매자가 환불을 요구할 경우</li>
              <li>○ 기타 정당한 환불 사유로 판명되지 않은 경우</li>
            </ol>
            <p>5. 판매자가 환불을 결정한 시점에 회사로부터 용역 등의 대금을 이미 지급받은 때에는 그 사실을 구매자에게 통지하고 환급을 위한 조치를 취하여야 합니다.</p>
            <p>6. 판매자가 구매자에게 용역 등을 발송한 경우 구매자는 수령일로부터 7일 이내에 직접 구매 확정할 수 있으며, 기한 내에 구매 확정, 수정 요청 등 구매자의 의사표시가 접수되지 않는 경우 회사는 자동으로 구매 확정 절차를 진행할 수 있습니다.</p>
            <p>7. 구매자가 구매 확정을 하거나 자동 구매 확정 절차가 진행되면 중개서비스가 종료되므로 거래 취소(청약 철회), 환불, 용역 등에 대한 수정과 판매 수수료 및 구매 수수료 반환 등의 절차는 원칙적으로 불가능하며, 판매자와 구매자가 회사에 대하여 이의를 제기할 수 없습니다. 이 경우 구매대금 환불 등의 모든 문제는 당사자 간 직접 해결해야 합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 19 조 (판매 수수료)</h3>
            <p>1. 회사는 회사가 제공하는 서비스에 대하여 판매 수수료를 부과할 수 있으며, 판매 수수료는 서비스의 종류와 카테고리별로 다르게 책정될 수 있습니다. 별도의 기준이 있는 경우, 회사는 본 약관 제 6 조에 규정한 방법 또는 기타의 방법으로 판매자에게 사전 고지합니다.</p>
            <p>2. 진행 중인 주문이 취소되는 경우 판매 수수료는 부과되지 않으며, 구매자가 구매 확정을 하거나 자동 구매 확정 절차가 진행되면 서비스가 종료되므로 판매 수수료 반환은 원칙적으로 불가합니다.</p>
            <p>3. 회사는 당월 구매 확정된 주문 건의 판매 수수료에 대하여 익월 전자세금계산서를 일괄 발행하며, 본 약관 제 6 조의 방법으로 판매자에게 통지합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 20 조 (수익금 및 정산)</h3>
            <p>1. 회사가 제공하는 결제 서비스로 게시물, 용역 등을 결제한 구매자가 본 약관 제 16 조에 따라 게시물, 용역 등을 수령하고 구매 확정하거나 자동 구매 확정이 진행된 경우, 회사는 판매 수수료를 공제한 금액을 판매자의 계정에 수익금으로 지급합니다.</p>
            <p>2. 1항의 절차가 진행되면 중개서비스가 종료되므로 판매 수수료 반환은 원칙적으로 불가하며, 이 경우 구매대금 환불 등의 모든 문제는 판매자와 구매자가 직접 해결해야 합니다.</p>
            <p>3. 판매자는 계정에 등록된 정산 계좌로 수익금을 받을 수 있으며, 수익금은 구매 확정일 기준으로 익월 15일 내 정산됩니다.</p>
            <p>4. 회사는 판매자의 귀책사유로 인해 발생한 비용 등의 채무가 있는 경우에는 해당 금액을 공제한 후 출금할 수 있습니다.</p>
            <p>5. 판매자는 회사에 대한 게시물, 용역 등의 대금 채권을 타인에게 양도할 수 없습니다. 다만, 판매자의 채권자가 제기한 신청에 의한 대금의 가압류, 압류 및 추심명령 등 법원의 결정이 있을 경우, 회사는 판매자와 채권자 간의 합의 또는 채무액 변제 등으로 위 결정이 해제될 때까지 수익금 출금을 보류하거나 제3채무자로서 정당하게 판매자의 채무를 변제할 수 있습니다.</p>
            <p>6. 회사는 아래 사유가 발생하는 경우 최대 120일까지 수익금의 출금을 중단할 수 있습니다. 이 경우 판매자가 거래 사실 증빙서류 등 관련 서류를 회사에 제공하는 때에는 회사가 이를 확인한 후 출금을 진행할 수 있습니다.</p>
            <ol>
              <li>○ 판매자가 서비스 등록 양식에 어긋난 등록이나 서비스 판매가 목적이 아닌 결제·거래 부정 행위 등의 다른 목적으로 허위 등록, 판매 가장등록, 신용카드 결제 시스템 또는 통신과금 시스템만을 이용하기 위한 등록을 한 경우</li>
              <li>○ 판매자가 매매 부적합한 용역 등을 판매한 것으로 확인되거나 부정 또는 부당한 방법으로 수익금을 취득한 경우, 혹은 이에 준하는 행위가 의심되는 경우</li>
              <li>○ 구매자의 불만이 다수 접수되는 등의 사유로 추가적인 환불 요청이 염려되는 경우</li>
              <li>○ 관련 법령의 규정에 의하거나 기타 합리적인 사유가 있는 경우(법원·수사기관·금융기관 등 권한 있는 기관의 요청이 있는 경우, 기타 위탁사를 통해 사실 확인이 필요한 경우 등)</li>
            </ol>
            <p>7. 전항에서 정한 기간을 넘어 수익금의 출금을 중단해야 하는 부득이한 사유가 있는 경우, 회사는 해당 사항을 본 약관 제 6 조의 방법으로 회원에게 통지하고 유보하거나 상계할 수 있습니다. </p>
            <p>8. 판매자가 지정한 입금계좌의 예금주가 판매자 인증 정보와 상이한 경우 출금이 불가능하며, 회사는 해당 조건에 맞는 계좌 관련 서류 증빙이 완료될 때까지 수익금의 출금을 보류할 수 있습니다.</p>
            <p>9. 판매자가 판매하고 있는 다수의 게시물, 용역 등 중 일부에 대하여 본 조에 따른 수익금 출금 보류 사유가 발생한 경우, 회사는 다른 회원의 피해 방지를 위해 필요한 경우 등 합리적인 사유가 있다면 회사는 해당 사유가 직접적으로 발생하지 않은 다른 용역 등의 전부 또는 일부에 대한 정산을 일정 기간 보류할 수 있습니다.</p>
            <p>10. 구매자가 신용카드로 결제한 경우, 회사는 정당한 이유가 있다면 여신전문금융업상 규정에 따른 신용카드 부정사용을 통한 허위거래 여부를 판단하기 위해 최고 60일까지 판매자에 대한 용역등의 대금 정산, 수익금 출금을 보류할 수 있습니다. 이 경우, 회사는 거래 사실 확인을 위한 증빙을 판매자에게 요구할 수 있으며, 사실 여부 확인 후 판매자에게 대금 정산, 수익금 출금을 진행할 수 있습니다.</p>
            <p>11. 회사는 수익금 지급과 관련한 수익금 내역, 출금 내역, 세금계산서 등의 정보를 관련 서비스 화면에서 안내합니다.</p>
            <p>12. 회사는 관련 법령 등에 따라 판매자의 분기별 매출 명세를 국세청으로 제출합니다. 판매자는 매출 신고가 누락되지 않도록 관리해야 하며, 회사는 관련 자료의 제공 외의 세무 처리에 대해 개입하거나 책임지지 않습니다.</p>
            <p>13. 현금영수증 발행 의무가 있는 판매자는 의뢰인의 요청사항 또는 국세청 지정 코드에 따라 현금영수증을 발행해야 합니다.</p>
            <p>14. 세금계산서 발행 의무가 있는 판매자는 구매자의 요청에 따라 세금계산서를 발행해야 합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 21 조 (결제대금예치서비스 이용)</h3>
            <p>1. “결제대금예치서비스”라 함은 매매계약이 체결되고 구매자 대금을 결제하는 경우에, 구매자의 결제대금 보호를 위하여 회사가 일정 기간 동안 결제대금을 예치하는 서비스를 말합니다.</p>
            <p>2. 회사는 구매자와 판매자 간에 이루어지는 게시물, 용역 등 매매의 안전성과 신뢰성을 높이고, 게시물, 용역 등을 인수하기 전에 대금을 결제해야 하는 구매자를 보호하기 위하여 결제대금예치서비스를 제공합니다.</p>
            <p>3. 회사는 결제대금예치서비스의 제공과 관련하여 판매자 또는 구매자를 대리하는 것이 아님은 물론, 게시물, 용역 등의 매매와 관련하여 판매자 또는 구매자의 의무를 대행하는 것도 아닙니다.</p>
            <p>4. 구매자는 결제대금예치서비스를 통하여 구매대금을 결제하는 과정에서 발생하는 송금수수료 등을 부담하여야 합니다.</p>
            <p>5. 구매자는 회사가 결제대금예치서비스를 제공하는 과정에서 발생하는 이자 등에 대하여 회사에게 그 반환을 청구할 수 없습니다.</p>
            <p>6. 결제대금예치서비스는 마켓플레이스 서비스 이용약관 제 18 조 (구매 확정) 에 정한 바에 따라 구매확정 된 경우에 종료됩니다.</p>
            <p>7. 구매자는 결제대금예치서비스가 종료된 이후에 해당 구매 건에 대하여 청약철회, 취소, 해제, 무효 등의 사유가 발생한 경우 판매자와 직접 청약철회, 취소, 해제 및 환불 등에 관한 절차를 진행해야 합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 22 조 (금지행위)</h3>
            <p>1. 판매자와 구매자가 회사가 제공하는 서비스를 이용하지 아니하고 직접 거래하는 행위(직접 결제)는 거래의 안전을 위하여 금지됩니다. 이 경우 직접 결제를 통하여 발생한 제반 문제에 대한 책임은 거래 당사자에게 있으며 회사는 이에 대해 어떠한 책임도 부담하지 아니 합니다.</p>
            <p>2. 물품 판매 또는 용역 제공을 가장하여 자금을 융통하는 것과 같이 여신전문금융업법 등 법령에 따라 금지된 방법으로 비정상적인 결제 행위는 금지됩니다.</p>
            <p>3. 실제 게시물, 용역 등의 제공이 없는 등 게시물, 용역 등의 실질적인 구매의사가 없는 구매행위와 회사가 제공하는 할인율 등을 이용하여 비정상적인 거래를 하는 것은 금지됩니다.</p>
            <p>4. 회사는 이용자가 본 조의 금지행위를 하는 경우에는 해당 이용자에게 부가적으로 제공한 혜택의 일부 또는 전부의 회수, 서비스의 이용제한, 이용계약 해지 등의 조치를 할 수 있습니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 23 조 (손해배상)</h3>
            <p>1. 회사는 회사의 고의, 과실로 인하여 판매자가 손해를 입게 될 경우 본 약관 및 관련 법령에 따라 판매자의 손해를 배상하겠습니다. 다만 회사는 회사의 고의, 과실이 없는 한 판매자 상호간 또는 판매자와 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다. 또한 회사는 회사의 고의, 과실 없이 발생된 아래와 같은 손해에 대해서는 책임을 부담하지 않습니다.</p>
            <ol>
              <li>○ 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해</li>
              <li>○ 판매자의 귀책사유로 서비스 이용에 장애가 발생한 경우</li>
              <li>○ 서비스에 접속 또는 이용과정에서 발생하는 개인적인 손해</li>
              <li>○ 제3자가 불법적으로 회사의 서버에 접속하거나 서버를 이용함으로써 발생하는 손해</li>
              <li>○ 제3자의 회사 서버에 대한 전송 또는 회사서버로부터의 전송을 방해함으로써 발생하는 손해</li>
              <li>○ 제3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해</li>
              <li>○ 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해, 명예훼손 등 제3자가 서비스를 이용하는 과정에서 발생된 손해</li>
              <li>○ 기타 회사의 고의 또는 과실이 없는 사유로 인해 발생한 손해</li>
            </ol>
            <p>2. 회사는 판매자가 서비스를 이용함으로써 기대되는 수익을 얻지 못하거나 서비스를 통해 얻은 정보를 이용하여 발생한 손해에 대해서는 책임을 부담하지 않습니다.</p>
            <p>3. 판매자가 본 약관의 규정 또는 관련 법령을 위반하여 회사에 손해가 발생한 경우 회사는 판매자에 대하여 손해배상을 청구할 수 있습니다. 이 경우 판매자는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 24 조 (책임제한)</h3>
            <p>1. 회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않으며, 회사의 귀책사유 없이 발생된 회원의 손해에 대하여는 책임을 부담하지 않습니다. 또한 회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다. 단, 회사 및 회사의 임직원, 대리인의 고의 또는 중대한 과실이 인정되는 경우에는 그러하지 아니합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 25 조 (정보의 제공)</h3>
            <p>1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보에 대하여 전자메일이나 서신우편 등의 방법으로 회원에게 정보를 제공할 수 있습니다. 단 광고성 정보에 해당하는 경우 회원으로부터 수신 동의를 받습니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 26 조 (광고게재)</h3>
            <p>1. 회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고 등을 게재할 수 있습니다.</p>
            <p>2. 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나 서비스에 연결될 수 있습니다.</p>
            <p>3. 제 2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우, 해당 영역에서 제공하는 서비스는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 회원의 손해에 대하여도 회사는 책임을 지지 않습니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 27 조 (개별서비스에 대한 이용약관)</h3>
            <p>1. 회사가 별도의 서비스를 제공하게 되거나, 기존 서비스 내에서 필요한 경우 이를 위한 약관 및 정책을 둘 수 있습니다. 이는 각 서비스의 개별 페이지에 유의사항의 형태로 구현될 수 있으며, 그 형태나 명칭을 불문하고 본 조에서 규정하는 ‘약관 및 정책’에 해당합니다. </p>
            <p>2. 회사가 전항에 따른 개별 약관 및 정책을 둘 경우 이용자가 최초로 해당 서비스를 이용할 때에 동의 절차를 거치도록 하거나 이를 이용자가 충분히 확인할 수 있도록 제시하며, 해당 약관 및 정책이 본 약관에 우선합니다.</p>
          </div>
          <br/>

          <div>
            <h3>제 28 조 (분쟁의 해결)</h3>
            <p>1. 본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다.</p>
            <p>2. 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위한 전담기구를 설치 및 운영합니다</p>
            <p>3. 회사는 회원으로부터 제출되는 불만사항 및 의견을 신속하게 처리하기 위해 노력합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보합니다.</p>
            <p>4. 회사와 회원 간에 발생한 분쟁과 관련하여 회원의 피해 구제 신청이 있는 경우에는 공정거래위원회 또는 시도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</p>
            <p>5. 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는 관련 소송의 관할은 “회사” 본점 소재지를 관할하는 법원으로 합니다.</p>
          </div>
          <br/>


          공고일자: 2024년 12월 13일
          시행일자: 2025년 1월 13일
        </div>


      </Modal>
    </>
  )
}

export default SalesTerms