import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clearTotalPayAmountList, setQuery, setTotalPayAmountList, setLocationKey, INIT_STATE_TOTAL_PAYAMOUNT_LIST } from '@stores/totalPayAmountList';
import { message, Layout, Row, Col, Segmented } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import Table from '@components/Table';
import ButtonExport from '@components/ButtonExportAUIGrid';
import RangePicker from '@components/RangePicker';
import * as api from '@api/index';
import { useDebounce, secondsCompactFormat, makeDataFormat, koKRFormat } from '@utils/lib';
import { ADMIN_PAY_LIST } from '@routes/pathName';

const { Content, Header } = Layout;

const TotalPayAmountList = ({ isActive }) => {
  const navigate = useNavigate();
  const { key: locationKey } = useLocation();
  const totalPayAmountListRef = useRef();
  const dispatch = useDispatch();
  const totalPayAmountList = useSelector((s) => s.totalPayAmountList, shallowEqual);
  const [activeTabKey, setActiveTabKey] = useState('년'); // 활성화된 tab
  const [gdsDivFormat, setGdsDivFormat] = useState({});

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 총 결제금액 목록 가져오기 그리드 설정
  const [totalPayAmountListGridProps, setTotalPayAmountListGridProps] = useState({
    showRowNumColumn: false, // 행번호 유무
    showRowCheckColumn: false,
    showStateColumn: false,
    editable: false,
    showFooter: true,
    footerRowCount: 3,
    footerHeight: 24,
    // 합계(소계) 설정
    groupingSummary: {
      // 합계 필드 설정
      dataFields: [],
      rows: [{ opertaion: 'SUM' }],
    },
    // 브랜치에 해당되는 행을 출력 여부
    showBranchOnGrouping: false,
    // 그룹핑 후 셀 병합 실행
    enableCellMerge: true,
    // enableCellMerge 할 때 실제로 rowspan 적용 시킬지 여부
    // 만약 false 설정하면 실제 병합은 하지 않고(rowspan 적용 시키지 않고) 최상단에 값만 출력 시킵니다.
    cellMergeRowSpan: true,
    // 그리드 ROW 스타일 함수 정의
    rowStyleFunction: function (rowIndex, item) {
      // 그룹핑으로 만들어진 합계 필드인지 여부
      if (item._$isGroupSumField) {
        // 그룹핑을 더 많은 필드로 하여 depth 가 많아진 경우는 그에 맞게 스타일을 정의하십시오.
        // 현재 3개의 스타일이 기본으로 정의됨.(AUIGrid_style.css)
        switch (
          item._$depth // 계층형의 depth 비교 연산
        ) {
          case 2:
            return 'aui-grid-row-depth3-style';
          case 3:
            return 'aui-grid-row-depth2-style';
          case 4:
            return 'aui-grid-row-depth1-style';
          default:
            return 'aui-grid-row-depth-default-style';
        }
      }
      return null;
    },
  });

  // 총 결제금액 목록 가져오기 그리드 칼럼 레이아웃
  const totalPayAmountListColumnLayout = useMemo(() => {
    // 년/월/일 탭 이동 또는 날짜 검색에 의해서 조회 데이터 변동시, ColumnLayout 변경
    const newColumnLayout = totalPayAmountListGridProps?.groupingSummary?.dataFields.map((fir_dt) => {
      return {
        // 컬럼명은 해당 날짜형식의 포멧으로 설정
        dataField: fir_dt,
        headerText: dayjs(fir_dt).format({ year: 'YYYY', month: 'YYYY.MM', day: 'MM.DD' }[totalPayAmountList.query.dateType]),
        labelFunction: (rowIndex, columnIndex, value) => {
          return koKRFormat(value?.gds_amt) || value;
        },
      };
    });

    return [
      {
        dataField: 'gds_div',
        headerText: { year: '년', month: '년.월', day: '월.일' }[totalPayAmountList.query.dateType],
        labelFunction: (rowIndex, columnIndex, value) => {
          return gdsDivFormat[value] || value;
        },
      },
      {
        dataField: 'charge_amt',
        headerText: '수수료 금액',
        formatString: '#,##0',
        visible: false,
      },
      ...newColumnLayout,
    ];
  }, [totalPayAmountList, gdsDivFormat, totalPayAmountListGridProps]);

  // 총 결제금액 목록 가져오기 푸터 레이아웃
  const totalPayAmountListFooterLayout = useMemo(() => {
    const firDtList = totalPayAmountListGridProps?.groupingSummary?.dataFields;

    // 합계 : FooterLayout의 첫번째 배열
    // 컬럼값은 해당 날짜의 모든 상품 가격의 합
    const newFooterLayoutFirst = firDtList.map((fir_dt) => {
      return {
        dataField: fir_dt,
        positionField: fir_dt,
        formatString: '#,##0',
        labelFunction: function (value, columnValues, footerValues) {
          const gdsAmtList = columnValues.map((columnValue) => columnValue.gds_amt);
          const sum = gdsAmtList.reduce((acc, cur) => {
            return acc + cur;
          }, 0);
          return sum;
        },
      };
    });

    // 총 합계 : FooterLayout의 두번째 배열
    // 컬럼값은 모든 날짜의 모든 상품 가격의 합
    // 마지막 컬럼에만 총 합계 표시하기
    const newFooterLayoutSecond = firDtList.map((fir_dt, index) => {
      if (index === firDtList.length - 1) {
        return {
          dataField: fir_dt,
          positionField: fir_dt,
          formatString: '#,##0',
          labelFunction: function (value, columnValues, footerValues) {
            const gdsAmtList = columnValues.map((columnValue) => columnValue.gds_amt);
            const sumColumnValues = gdsAmtList.reduce((acc, cur) => {
              return acc + cur;
            }, 0);
            // footerValues의 처음 인덱스와 마지막 인덱스의 값은 불필요하기에 제거
            const sliceFooterValues = footerValues.slice(1, footerValues.length - 1);
            const sumFooterValues = sliceFooterValues.reduce((acc, cur) => {
              return acc + cur;
            }, 0);
            return sumColumnValues + sumFooterValues;
          },
        };
      } else {
        return {
          dataField: fir_dt,
          labelFunction: function (value, columnValues, footerValues) {
            const gdsAmtList = columnValues.map((columnValue) => columnValue.gds_amt);
            const sum = gdsAmtList.reduce((acc, cur) => {
              return acc + cur;
            }, 0);
            return sum;
          },
        };
      }
    });

    // 총 수수료 : FooterLayout의 세번째 배열
    // 컬럼값은 모든 날짜의 모든 수수료의 합
    // 마지막 컬럼에만 총 수수료 표시하기
    const newFooterLayoutThird = firDtList.map((fir_dt, index) => {
      if (index === firDtList.length - 1) {
        return {
          dataField: fir_dt,
          positionField: fir_dt,
          formatString: '#,##0',
          labelFunction: function (value, columnValues, footerValues) {
            const chargeAmtList = columnValues.map((columnValue) => columnValue.charge_amt);
            const sumColumnValues = chargeAmtList.reduce((acc, cur) => {
              return acc + cur;
            }, 0);
            // footerValues의 처음 인덱스와 마지막 인덱스의 값은 불필요하기에 제거
            const sliceFooterValues = footerValues.slice(1, footerValues.length - 1);
            const sumFooterValues = sliceFooterValues.reduce((acc, cur) => {
              return acc + cur;
            }, 0);
            return sumColumnValues + sumFooterValues;
          },
        };
      } else {
        return {
          dataField: fir_dt,
          labelFunction: function (value, columnValues, footerValues) {
            const chargeAmtList = columnValues.map((columnValue) => columnValue.charge_amt);
            const sum = chargeAmtList.reduce((acc, cur) => {
              return acc + cur;
            }, 0);
            return sum;
          },
        };
      }
    });

    return [
      [
        {
          positionField: 'gds_div',
          style: 'my-footer-style',
          labelText: '합계',
        },
        ...newFooterLayoutFirst,
      ],
      [
        {
          positionField: 'gds_div',
          style: 'my-footer-style',
          labelText: '총 합계',
        },
        ...newFooterLayoutSecond,
      ],
      [
        {
          positionField: 'gds_div',
          style: 'my-footer-style',
          labelText: '총 수수료',
        },
        ...newFooterLayoutThird,
      ],
    ];
  }, [totalPayAmountListGridProps]);

  // 공통코드 상품구분 조회
  const makeGdsDivFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'GDS_DIV' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setGdsDivFormat(madeDataFormat);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 총 결제금액 목록 가져오기
  const listUserPay = useCallback(async (query) => {
    totalPayAmountListRef.current.showAjaxLoader();
    try {
      const { data } = await api.listPayTotalAmount(query);
      if (!data) return;

      // AUI-Grid 데이터 초기화
      const newData = [];

      // 합계 필드값 생성
      const firDtList = data.map((originItem) => originItem.fir_dt);
      const compactFirDtList = firDtList.filter((value, index) => firDtList.indexOf(value) === index);

      data.map((originItem) => {
        // AUI-Grid 데이터 파싱
        const addedData = newData.filter((addedItem) => {
          if (addedItem.gds_div === originItem.gds_div) {
            addedItem[originItem.fir_dt] = { gds_amt: originItem.gds_amt, charge_amt: originItem.charge_amt };
            return true;
          }
          return false;
        });
        if (!addedData.length && originItem.gds_div) {
          const newItem = { gds_div: originItem.gds_div };
          newItem[originItem.fir_dt] = { gds_amt: originItem.gds_amt, charge_amt: originItem.charge_amt };
          newData.push(newItem);
        }
        // 해당 날짜에 빠져있는 상품의 가격을 0으로 채우기
        newData.map((addedItem) => {
          compactFirDtList.map((fir_dt) => {
            if (!addedItem[fir_dt]) addedItem[fir_dt] = { gds_amt: 0, charge_amt: 0 };
          });
        });

        return originItem;
      });

      // redux 데이터 변경
      dispatch(setTotalPayAmountList({ items: newData }));

      // AUI-Grid props 변경
      setTotalPayAmountListGridProps({
        ...totalPayAmountListGridProps,
        groupingSummary: {
          ...totalPayAmountListGridProps.groupingSummary,
          dataFields: compactFirDtList,
        },
      });

      // AUI-Grid 데이터 변경
      totalPayAmountListRef.current.setGridData(newData);
      dispatch(setLocationKey(locationKey));
      totalPayAmountListRef.current.removeAjaxLoader();
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 날짜 필터링
  const handleDatePay = ({ startDt, endDt }) => {
    const newQuery = { ...totalPayAmountList.query, startDt, endDt };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listUserPay(newQuery));
  };

  // 탭 변경시
  const handleTab = (key) => {
    const newQuery = {
      년: {
        startDt: dayjs().startOf('year').format('YYYY-MM-DD'),
        endDt: dayjs().endOf('year').format('YYYY-MM-DD'),
        dateType: 'year',
      },
      월: {
        startDt: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDt: dayjs().endOf('month').format('YYYY-MM-DD'),
        dateType: 'month',
      },
      일: {
        startDt: dayjs().subtract(6, 'd').format('YYYY-MM-DD'),
        endDt: dayjs().format('YYYY-MM-DD'),
        dateType: 'day',
      },
    }[key];
    dispatch(setQuery(newQuery));
    setActiveTabKey(key);
    handleDebounce(() => listUserPay(newQuery));
  };

  // 새로고침 이벤트 발생시
  const preventReload = () => {
    dispatch(clearTotalPayAmountList());
    navigate(ADMIN_PAY_LIST, { replace: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventReload);

    if (isActive) {
      // 공통코드 조회
      makeGdsDivFormat();
    }

    // 뒤로가기로 렌더링 됐을때
    if (locationKey === totalPayAmountList.locationKey) {
      totalPayAmountListRef?.current?.resize('100%');
      totalPayAmountListRef.current.setGridData(totalPayAmountList.items);
    }
    // 사이드 메뉴 선택으로 렌더링 됐을때
    else {
      listUserPay(INIT_STATE_TOTAL_PAYAMOUNT_LIST.query);
    }

    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, [isActive]);

  return (
    <>
      <Header className='l-divided tab'>
          <Row className='l-flex gap w100' style={{alignItems: 'center'}}>
            <Segmented defaultValue="년" onChange={handleTab} options={['년', '월', '일']} />
            <RangePicker
              valueRangePicker={[totalPayAmountList?.query?.startDt ? dayjs(totalPayAmountList?.query?.startDt) : '', totalPayAmountList?.query?.endDt ? dayjs(totalPayAmountList?.query?.endDt) : '']}
              picker={{ 년: 'year', 월: 'month', 일: 'date' }[activeTabKey]}
              onChange={handleDatePay}
              isDisabledDate={true}
              maxYears={10}
              maxMonths={12}
              maxDays={31}
            />
            <Row style={{ color: '#1677ff'}}>{{ 년: '*기간: 최대 10년일 / 단위: 원', 월: '*기간: 최대 12개월 / 단위: 원', 일: '*기간: 최대 31일 / 단위: 원' }[activeTabKey]}</Row>
          </Row>
      </Header>
      <Content className='l-content'>
        <ButtonExport style={{marginLeft: 'auto'}} ref={totalPayAmountListRef} fileName={`${secondsCompactFormat(new Date())}_총결제금액조회`} text="엑셀 다운" />
        <Table ref={totalPayAmountListRef} columnLayout={totalPayAmountListColumnLayout} footerLayout={totalPayAmountListFooterLayout} customGridProps={totalPayAmountListGridProps} />
      </Content>
    </>
  );
};

export default TotalPayAmountList;
