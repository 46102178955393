import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const INIT_STATE_INQUIRYLIST = {
  query: {
    mem_key: 0,
    selector_1: '01', // 답변상태
    keyword_type: 'mem_nick', // 닉네임 or 이메일
    keyword_text: '', // 검색조건text
    startDt: dayjs().subtract(1, 'M').format('YYYY-MM-DD'),
    endDt: dayjs().format('YYYY-MM-DD'),
    offset: 0,
    limit: 20,
  },
  items: [],
  totalCount: 0,
  currentCount: 0,
  locationKey: '',
  yScrollPosition: 0,
};

export const inquiryListSlice = createSlice({
  name: 'inquiryList',
  initialState: INIT_STATE_INQUIRYLIST,
  reducers: {
    clearInquiryList: (state, action) => {
      return { ...state, ...action.payload }; // 상태를 통째로 교체
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setInquiryList: (state, action) => {
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.currentCount = action.payload.currentCount;
    },
    setLocationKey: (state, action) => {
      state.locationKey = action.payload;
    },
    setYScrollPosition: (state, action) => {
      state.yScrollPosition = action.payload;
    },
  },
});

export const { clearInquiryList, setQuery, setInquiryList, setLocationKey, setYScrollPosition } = inquiryListSlice.actions;

export default inquiryListSlice.reducer;
