import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Tabs, Button, message } from 'antd';
import Draggable from 'react-draggable';
import Table from '@components/Table';
import { secondsFormat, makeDataFormat } from '@utils/lib';
import MyTextareaEditor from '@static/AUIGrid/renderers/MyTextareaEditor';
import * as PATH from '@routes/pathName';
import * as api from '@api/index';

const ListBadReason = ({ isOpen, onClose, badUserList, badDiv = 'U' }) => {
  const navigate = useNavigate();
  const badUserListRef = useRef();
  const badPrptListRef = useRef();
  const badBoardListRef = useRef();
  const [activeKey, setActiveKey] = useState(badDiv);
  const [disabled, setDisabled] = useState(true);
  const [BadCdFormat, setBadCdFormat] = useState({});
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 신고 구분에 따른 상세페이지 이동
  const moveToDetailPage = (badRsn) => {
    if (!badRsn || !['U', 'P', 'B'].includes(badRsn?.bad_div)) message.warning('페이지 이동을 할 수 없습니다.');
    if (badRsn?.bad_div === 'U') moveToPage(PATH.ADMIN_USER_DETAIL, { mem_key: badRsn?.target_id });
    if (badRsn?.bad_div === 'P') moveToPage(PATH.ADMIN_PROMPT_REVIEW_DETAIL, { prpt_id: badRsn?.target_id });
    if (badRsn?.bad_div === 'B') moveToPage(PATH.BOARD_DETAIL, { doc_no: badRsn?.target_id, board_no: badRsn?.board_no, board_title: badRsn?.title });
  };

  // 공통코드 신고사유코드 조회
  const makeBadCdFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'BAD_RSN' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setBadCdFormat(madeDataFormat);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 그리드 설정
  const gridProps = {
    height: 350,
  };

  // 그리드 칼럼 레이아웃 정의
  const columnLayout = (tabKey) => {
    return [
      {
        dataField: 'bad_mem_nick',
        headerText: '신고자',
        width: '10%',
        editable: false,
        renderer: {
          type: 'LinkRenderer',
          baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
          // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
          jsCallback: function (rowIndex, columnIndex, value, item) {
            moveToPage(PATH.ADMIN_USER_DETAIL, { mem_key: item.bad_mem_key });
          },
        },
        filter: {
          showIcon: true,
          displayFormatValues: true,
        },
      },
      {
        dataField: 'own_mem_nick',
        headerText: '신고대상',
        width: '10%',
        editable: false,
        renderer: {
          type: 'LinkRenderer',
          baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
          // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
          jsCallback: function (rowIndex, columnIndex, value, item) {
            moveToPage(PATH.ADMIN_USER_DETAIL, { mem_key: item.own_mem_key });
          },
        },
        filter: {
          showIcon: true,
          displayFormatValues: true,
        },
      },
      {
        ...(tabKey === 'U' && {
          dataField: 'target_mem_nick',
          headerText: '닉네임',
        }),
        ...(tabKey === 'P' && {
          dataField: 'prpt_title',
          headerText: '프롬프트 제목',
        }),
        ...(tabKey === 'B' && {
          dataField: 'title',
          headerText: '글 제목',
        }),
        width: '18%',
        editable: false,
        renderer: {
          type: 'LinkRenderer',
          baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
          // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
          jsCallback: function (rowIndex, columnIndex, value, item) {
            moveToDetailPage(item);
          },
        },
        filter: {
          showIcon: true,
          displayFormatValues: true,
        },
      },
      {
        dataField: 'bad_cd',
        headerText: '사유코드',
        width: '22%',
        style: 'my-wrap-column left-custom-column',
        labelFunction: (rowIndex, columnIndex, value) => {
          return BadCdFormat[value] || value;
        },
        editRenderer: {
          type: 'CustomEditRenderer',
          jsClass: MyTextareaEditor,
          vPosition: 'top',
          fitWidth: true,
          extraProps: {
            confirm: '확 인',
            cancel: '취 소',
          },
        },
        filter: {
          showIcon: true,
          displayFormatValues: true,
        },
      },
      {
        dataField: 'contents',
        headerText: '사유내용',
        width: '28%',
        style: 'my-wrap-column left-custom-column',
        editRenderer: {
          type: 'CustomEditRenderer',
          jsClass: MyTextareaEditor,
          vPosition: 'top',
          fitWidth: true,
          extraProps: {
            confirm: '확 인',
            cancel: '취 소',
          },
        },
      },
      {
        dataField: 'fir_dt',
        headerText: '일시',
        dataType: 'date',
        dateInputFormat: 'yyyy-mm-dd', // 데이터의 날짜 형식
        formatString: 'yyyy.mm.dd', // 그리드에 보여줄 날짜 형식
        width: '12%',
        editable: false,
        labelFunction: (rowIndex, columnIndex, value) => {
          return secondsFormat(value);
        },
      },
    ];
  };

  const badUserListColumnLayout = columnLayout('U');
  const badPrptListColumnLayout = columnLayout('P');
  const badBoardListColumnLayout = columnLayout('B');

  const badRsnList = [
    {
      label: '사용자',
      key: 'U',
      children: <Table ref={badUserListRef} columnLayout={badUserListColumnLayout} customGridProps={gridProps} />,
      forceRender: true,
    },
    {
      label: '프롬프트',
      key: 'P',
      children: <Table ref={badPrptListRef} columnLayout={badPrptListColumnLayout} customGridProps={gridProps} />,
      forceRender: true,
    },
    {
      label: '게시글',
      key: 'B',
      children: <Table ref={badBoardListRef} columnLayout={badBoardListColumnLayout} customGridProps={gridProps} />,
      forceRender: true,
    },
  ];

  useEffect(() => {
    setActiveKey(badDiv);
    if (isOpen) {
      Promise.all([
        badUserListRef?.current?.setGridData(badUserList?.bad_user_list),
        badPrptListRef?.current?.setGridData(badUserList?.bad_prpt_list),
        badBoardListRef?.current?.setGridData(badUserList?.bad_board_list),
      ]);
      makeBadCdFormat();

      badUserListRef?.current?.resize();
      badPrptListRef?.current?.resize();
      badBoardListRef?.current?.resize();

    }
  }, [isOpen, badDiv]);

  const handleTab = (key) => {
    setActiveKey(key);
    if (key === 'U') badUserListRef?.current?.resize('100%');
    if (key === 'P') badPrptListRef?.current?.resize('100%');
    if (key === 'B') badBoardListRef?.current?.resize('100%');
  };

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          신고 리스트
        </div>
      }
      centered={true}
      width={'70%'}
      open={isOpen}
      closable={false}
      maskClosable={false}
      getContainer={() => document.getElementById('root')}
      footer={
        <Button type="primary" onClick={onClose}>
          확인
        </Button>
      }
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Tabs type="card" activeKey={activeKey} items={badRsnList} onChange={handleTab} />
    </Modal>
  );
};

export default ListBadReason;
