import React from 'react';
import Slider from 'react-slick';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import '@assets/css/populerPromptSlider.css';
// component
import CardPrompt from '@pages/marketplaces/CardPrompt';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrowRight flexColCenter" onClick={onClick}>
      <p>
        <RightOutlined />
      </p>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;

  return (
    <div className="arrowLeft flexColCenter" onClick={onClick}>
      <p>
        <LeftOutlined />
      </p>
    </div>
  );
};

const SliderCardPrompt = ({ title, titleImg, promptList = [], openPlayer, moveToPromptDetailPage, moveToUserDetailPage }) => {
  const settings = {
    className: 'center',
    centerMode: promptList.length > 6,
    infinite: promptList.length > 6,
    centerPadding: '0',
    slidesToShow: 6,
    speed: 500,
    nextArrow: promptList.length > 6 ? <NextArrow /> : null,
    prevArrow: promptList.length > 6 ? <PrevArrow /> : null,
    dots: true,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          centerPadding: '0',
          centerMode: false,
        },
      },
    ],
  };

  return (
    <>
      <article id="listWrapper" className="flexColCenter promptList ">
        <article id="otherWrapper" className="flexColCenter">
          <div className="profileTitle">
            <div className=" ">
              <img src={titleImg} />
              <p>{title}</p>
            </div>
          </div>
          <div className="slider-container rsWrapper" id="populerPromptSlider">
            {!Array.isArray(promptList) || promptList.length === 0 ? (
              <div className="noResult">
                <p>등록된 프롬프트가 없습니다.</p>
              </div>
            ) : (
              <Slider {...settings} style={{ marginBottom: '20px' }}>
                {promptList.map((item, index) => (
                  <CardPrompt
                    key={index}
                    prompt={item}
                    openPlayer={() => openPlayer(item)}
                    moveToPromptDetailPage={() => moveToPromptDetailPage(item)}
                    moveToUserDetailPage={() => moveToUserDetailPage(item)}
                  />
                ))}
              </Slider>
            )}
          </div>
        </article>
      </article>
    </>
  );
};

export default SliderCardPrompt;
