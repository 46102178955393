import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Descriptions, message, Row, Col, Image, Button, Card, Form, Input, Upload, Select, Modal} from 'antd';

import {CloseOutlined, PlusOutlined} from '@ant-design/icons';

import { IMAGES } from '@utils/image';
import Loading from "@components/Loading";
import TextArea from "antd/es/input/TextArea";
import {shallowEqual, useSelector} from "react-redux";
import aiIcon from "@assets/images/profile/ai_icon.png";
import * as api from "@api/index";
import {handleImageError, makeSelectBoxList} from "@utils/lib";
import useWidth from "@hooks/useWidth";

const GenreField = React.memo(({ genreList, selectedGenre, handleGenreSelect, handleGenreRemove }) => {
    return (
        <div className="flexRowBetween ai">
            <div className="title flexRowStart">
                <h4>주요장르</h4>
            </div>

            <div className="flexRowStart content">
                <Form.Item name="ai_fi_nm">
                    <Select placeholder="AI 분야 선택(중복)" options={genreList} onChange={handleGenreSelect} />
                </Form.Item>
            </div>
            <div className="flexRowStart tabBox">
                {selectedGenre.map((genre) => (
                    <div className="aiTab flexRowBetween">
                        <h1>{genre.label}</h1>
                            <p>
                                <CloseOutlined onClick={() => handleGenreRemove(genre.value)} />
                            </p>
                    </div>
                ))}
            </div>
        </div>
    )
})

const genreList = [
    { label: 'K-POP', value: '01' },
    { label: 'Hip Pop', value: '02' },
    { label: 'R & B', value: '03' },
    { label: '발라드', value: '04' },
    { label: 'Rock', value: '05' },
    { label: 'Pop', value: '06' },
]

const affiliationList = [
    { label: '중학교', value: 'middle' },
    { label: '고등학교', value: 'high' },
    { label: '대학교', value: 'graduate' },
    { label: '대학원', value: 'postgradutate' },
    { label: '일반', value: 'general' },
]
const ArtistEdit = ({ isOpen, onClose }) => {
    const auth = useSelector((s) => s.auth, shallowEqual);
    const width = useWidth();

    const [form] = Form.useForm();
    const currentYear = new Date().getFullYear(); // 현재 연도
    const [isEditMode, setIsEditMode] = useState(false);

    // 내림차순으로 연도 배열
    const yearOptions = [];
    for (let year = currentYear; year >= 2005; year--) {
        yearOptions.push(
            <Select.Option key={year} value={year}>
                {year}
            </Select.Option>
        );
    }

    const genreRef = useRef(null);
    const [selectedGenre, setSelectedGenre] = useState([]);

    const handleGenreSelect = (value) => {
        const selectedOption = genreList.find((genre) => genre.value === value);
        if (selectedOption && !selectedGenre.some((genre) => genre.value === value)) {
            setSelectedGenre([...selectedGenre, selectedOption]);
        }
    };

    const handleGenreRemove = (value) => {
        setSelectedGenre(selectedGenre.filter((genre) => genre.value !== value));
    };

    const [locationCodes, setLocationCodes] = useState([]);

    const fetchCommonCodes = useCallback(async () => {
        try {
            const locCodeList = await api.fetchCommonDetailCode({ grp_cd: 'LOC01' });
            setLocationCodes(makeSelectBoxList(locCodeList?.data, 'cd_desc', 'cd'));
        } catch (e) {
            message.warning('상세 코드 조회 중 오류가 발생했습니다.');
        }
    },[]);

    useEffect(() => {
        fetchCommonCodes();
        setIsEditMode(true);
    },[isOpen]);

    const [profileImageFile, setProfileImageFile] = useState(null);
    const [introImageFile, setIntroImageFile] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [introImageUrl, setIntroImageUrl] = useState(null);

    const { Dragger } = Upload;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const imageProfileError = useRef(false);
    const imageBannerError = useRef(false);
    const [hoverProfileState, setHoverProfileState] = useState(false);
    const [hoverChanelState, setHoverChanelState] = useState(false);
    const defaultProfileChk = useRef(false);
    const defaultIntroChk = useRef(false);

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    useEffect(() => {
        imageProfileError.current = false;
        imageBannerError.current = false;
    }, [profileImageUrl, introImageUrl]);

    const handleImageUpload = (file, type) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (type === 'profile') {
                imageProfileError.current = false;
                setProfileImageFile(file);
                setProfileImageUrl(reader.result);
                defaultProfileChk.current = false;
            } else if (type === 'intro') {
                imageBannerError.current = false;
                setIntroImageFile(file);
                setIntroImageUrl(reader.result);
                defaultIntroChk.current = false;
            }
        };
        reader.readAsDataURL(file);
    };

    const renderUpload = (name) => {
        const beforeUpload = (file) => {
            handleImageUpload(file, name === 'profile_image' ? 'profile' : 'intro');
            return false;
        };

        const handlePreview = (type) => {
            const url = type === 'p' ? profileImageUrl : introImageUrl;
            if (url) {
                setPreviewImage(url);
                setPreviewOpen(true);
            }
        };

        const handleRemove = () => {
            if (name === 'profile_image') {
                setProfileImageUrl(null);
                setProfileImageFile(null);
                defaultProfileChk.current = false;
            } else if (name === 'intro_image') {
                setIntroImageUrl(null);
                setIntroImageFile(null);
                defaultIntroChk.current = false;
            }
        };

        if (name === 'profile_image') {
            return (
                <div className="profileBox">
                    {previewImage && (
                        <Image
                            wrapperStyle={{ display: 'none' }}
                            preview={{
                                visible: previewOpen,
                                onVisibleChange: (visible) => setPreviewOpen(visible),
                                afterOpenChange: (visible) => !visible && setPreviewImage(''),
                            }}
                            src={previewImage}
                        />
                    )}
                    {isEditMode ? (
                        <>
                            <div className="plusBox flexColCenter">
                                <Form.Item name={name} valuePropName="fileList" getValueFromEvent={normFile} className="box">
                                    <Dragger accept=".jpg,.jpeg,.png" maxCount={1} listType="picture-card" showUploadList={false} beforeUpload={beforeUpload}>
                                        {profileImageUrl === null || imageProfileError.current || (profileImageUrl !== null && imageProfileError.current) ? (
                                            <>
                                                <div className="flexColCenter plus">
                                                    <PlusOutlined />
                                                </div>
                                                {width > 700 && (
                                                    <p>
                                                        이미지를 등록하거나
                                                        <br />
                                                        드래그 & 드롭해 주세요.
                                                    </p>
                                                )}
                                            </>
                                        ) : (
                                            <div
                                                className="flexColCenter imageContainer"
                                                onMouseEnter={(e) => {
                                                    setHoverProfileState(true);
                                                    const icons = e.currentTarget.querySelectorAll('.iconStyle');
                                                    icons.forEach((icon) => (icon.style.opacity = '1'));
                                                }}
                                                onMouseLeave={(e) => {
                                                    setHoverProfileState(false);
                                                    const icons = e.currentTarget.querySelectorAll('.iconStyle');
                                                    icons.forEach((icon) => (icon.style.opacity = '0'));
                                                }}
                                            >
                                                <img
                                                    src={IMAGES.FILE_EXPAN_ICON}
                                                    alt="expand icon"
                                                    className="iconStyle iconExpand"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePreview('p');
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '60%',
                                                        width: '20px',
                                                        height: '20px',
                                                        transform: 'translateY(-50%)',
                                                        pointerEvents: 'auto',
                                                        opacity: '0',
                                                        transition: 'opacity 0.3s ease-in-out',
                                                        zIndex: '10',
                                                    }}
                                                />

                                                <img
                                                    src={IMAGES.FILE_CHANGE_ICON}
                                                    alt="change icon"
                                                    className="iconStyle iconChange"
                                                    style={{
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '40%',
                                                        width: '20px',
                                                        height: '20px',
                                                        transform: 'translateY(-50%)',
                                                        pointerEvents: 'auto',
                                                        opacity: '0',
                                                        transition: 'opacity 0.3s ease-in-out',
                                                        zIndex: '10',
                                                    }}
                                                />

                                                <img
                                                    src={IMAGES.FILE_TRASH_ICON}
                                                    alt="trash icon"
                                                    className="iconStyle iconTrash"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleRemove();
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '20%',
                                                        width: '20px',
                                                        height: '20px',
                                                        transform: 'translateY(-50%)',
                                                        pointerEvents: 'auto',
                                                        opacity: '0',
                                                        transition: 'opacity 0.3s ease-in-out',
                                                        zIndex: '10',
                                                    }}
                                                />
                                                <img
                                                    src={profileImageUrl}
                                                    alt=''
                                                    style={{
                                                        borderRadius: '8px',
                                                        transition: 'filter 0.3s ease-in-out',
                                                        filter: hoverProfileState ? 'brightness(0.7)' : 'brightness(1)',
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Dragger>
                                </Form.Item>
                            </div>
                        </>
                    ) : (
                        <Image
                            src={profileImageUrl}
                            className="profilePreview"
                            alt="Profile Preview"
                            style={{
                                width: '140px',
                                height: '140px',
                                objectFit: 'cover',
                            }}
                            fallback={IMAGES.DEFAULT_PROFILE}
                            onError={(e) => handleImageError(e, imageProfileError, IMAGES.DEFAULT_PROFILE)}
                        />
                    )}
                </div>
            );
        } else {
            return (
                <div>
                    {isEditMode ? (
                        <>
                            <div className="flexColCenter plusBannerBox">
                                <Form.Item name={name} valuePropName="fileList" getValueFromEvent={normFile}>
                                    <Dragger accept=".jpg,.jpeg,.png" action="" maxCount={1} listType="picture-card" showUploadList={false} beforeUpload={beforeUpload}>
                                        {introImageUrl === null || imageBannerError.current || (introImageUrl !== null && imageBannerError.current) ? (
                                            <>
                                                <div className="flexColCenter plus">
                                                    <PlusOutlined />
                                                </div>
                                                {width > 700 && (
                                                    <>
                                                        <p>이미지를 등록하거나 드래그 & 드롭해 주세요.</p>
                                                        <p>* 권장 사이즈 1900 x 320 px</p>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <div
                                                className="flexColCenter imageContainer"
                                                style={{position: 'relative'}}
                                                onMouseEnter={(e) => {
                                                    setHoverChanelState(true);
                                                    const icons = e.currentTarget.querySelectorAll('.iconStyle');
                                                    icons.forEach((icon) => (icon.style.opacity = '1'));
                                                }}
                                                onMouseLeave={(e) => {
                                                    setHoverChanelState(false);
                                                    const icons = e.currentTarget.querySelectorAll('.iconStyle');
                                                    icons.forEach((icon) => (icon.style.opacity = '0'));
                                                }}
                                            >
                                                <img
                                                    src={introImageUrl}
                                                    alt=''
                                                    style={{
                                                        borderRadius: '2%',
                                                        transition: 'filter 0.3s ease-in-out',
                                                        filter: hoverChanelState ? 'brightness(0.7)' : 'brightness(1)',
                                                    }}
                                                />
                                                <img
                                                    className="iconStyle"
                                                    src={IMAGES.FILE_EXPAN_ICON}
                                                    alt=''
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handlePreview('i');
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '43%',
                                                        width: '20px',
                                                        height: '20px',
                                                        pointerEvents: 'auto',
                                                        transform: 'translate(-50%, -43%)',
                                                        opacity: '0',
                                                        transition: 'opacity 0.3s ease-in-out',
                                                        zIndex: '10',
                                                    }}
                                                />
                                                <img
                                                    className="iconStyle"
                                                    src={IMAGES.FILE_CHANGE_ICON}
                                                    alt=''
                                                    style={{
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        width: '20px',
                                                        height: '20px',
                                                        pointerEvents: 'auto',
                                                        transform: 'translate(-50%, -50%)',
                                                        opacity: '0',
                                                        transition: 'opacity 0.3s ease-in-out',
                                                        zIndex: '10',
                                                    }}
                                                />
                                                <img
                                                    className="iconStyle"
                                                    src={IMAGES.FILE_TRASH_ICON}
                                                    alt=''
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleRemove();
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '57%',
                                                        width: '20px',
                                                        height: '20px',
                                                        pointerEvents: 'auto',
                                                        transform: 'translate(-50%, -57%)',
                                                        opacity: '0',
                                                        transition: 'opacity 0.3s ease-in-out',
                                                        zIndex: '10',
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </Dragger>
                                </Form.Item>
                            </div>
                        </>
                    ) : (
                        <Image
                            src={introImageUrl}
                            alt="Intro Preview"
                            style={{
                                width: '100%',
                                maxHeight: '300px',
                                objectFit: 'cover',
                            }}
                            fallback={IMAGES.DEFAULT_BANNER}
                            onError={(e) => handleImageError(e, imageBannerError, IMAGES.DEFAULT_BANNER)}
                        />
                    )}
                </div>
            );
        }
    };

    return (
        <Modal
            className="p20"
            title="아티스트 등록"
            open={isOpen}
            onCancel={onClose}
            width={800}
            footer={
                [
                    /*<Button id="purpleBtn" key="submit" onClick={handleSubmit} /!*disabled={!agreed || !isPwCert}*!/>
                        다음
                    </Button>,
                    <Button id="logoutBtn" key="" onClick={onClose}>
                        취소
                    </Button>*/
                ]
            }
        >
            <div className="profileUpload">{renderUpload('profile_image')}<p>test1</p></div>
            <div className="flexColStart info">{renderUpload('intro_image')}<p>test2</p></div>

            <Form form={form} layout="vertical" requiredMark={true} initialValues={{  }}>
                <Form.Item name="mem_nick" label="아티스트명">
                    {auth.mem_nick}
                </Form.Item>
                <Form.Item name="loc_cd_1" label="지역" rules={[{ required: true, message: '지역을 선택해주세요.' }]}>
                    <Select placeholder="지역" options={locationCodes}></Select>
                </Form.Item>
                <Form.Item name="regionDetail" label="소속" rules={[{ required: true, message: '소속을 선택해주세요.' }]}>
                    <Select placeholder="소속" options={affiliationList}></Select>
                </Form.Item>
                <Form.Item label="학과" name="department">
                    <Input />
                </Form.Item>
                <Form.Item name="year" label="입학년도" rules={[{ required: true, message: "입학년도를 선택해주세요." }]}>
                    <Select
                        placeholder="입학년도를 선택하세요"
                    >
                        {yearOptions}
                    </Select>
                </Form.Item>
                <GenreField genreList={genreList} selectedGenre={selectedGenre} handleGenreSelect={handleGenreSelect} handleGenreRemove={handleGenreRemove} />
                <Form.Item name="intro" label="소개글" rules={[{ required: true, message: '소개글을 입력해주세요.' }]}>
                    <TextArea rows={4} placeholder="" />
                </Form.Item>
                <Form.Item name="reward" label="수상이력" rules={[{ required: true, message: '수상이력을 입력해주세요.' }]}>
                    <TextArea rows={4} placeholder="" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default ArtistEdit;