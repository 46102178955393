import React from 'react';
// icon
import markIcon from '@assets/images/common/yellow_mark_icon.png';

const ButtonDirectBuy = ({ onBuy }) => {
  return (
    <button id="purpleBtn" className="stroke" onClick={onBuy}>
      {/* <img src={markIcon} className="markIcon" /> */}

      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.624 5.313V5.17c0-2.077-1.602-3.795-3.624-3.795-2.023 0-3.625 1.718-3.625 3.795v.143H3.32a.813.813 0 0 0-.808.73L1.5 16.17a.813.813 0 0 0 .808.893H9.25v-1.625H3.206l.85-8.5h1.319v1.438a.812.812 0 1 0 1.625 0V6.937h4v1.438a.812.812 0 1 0 1.624 0V6.937h1.317l.26 2.588.022.225h1.634l-.027-.275-.344-3.431a.812.812 0 0 0-.807-.731h-2.055zm-.26 5.532-2.25 2.249a.811.811 0 1 0 1.15 1.149l.86-.861v3.43a.812.812 0 1 0 1.625 0v-3.43l.863.86a.813.813 0 1 0 1.149-1.148l-2.249-2.249a.812.812 0 0 0-1.149 0zM10.998 5.17v.144H7V5.17C7 3.948 7.92 3 9 3c1.082 0 2 .948 2 2.17z"
          fill="#fff"
          stroke="#fff"
          stroke-width=".5"
        />
      </svg>

      <p>바로구매</p>
    </button>
  );
};

export default ButtonDirectBuy;
