import React, {useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import ProfileHome from '@pages/profile/ProfileHome';
import ProfileDetail from '@pages/profile/ProfileDetail';
import PlanInfo from '@pages/profile/PlanInfo';
import NoticeSetting from '@pages/profile/NoticeSetting';
import Ranking from '@pages/profile/Ranking';
import Tabs from '@components/Tabs';
import { getLocalStorage, isAdmin } from '@utils/lib';
import ListPayment from "@pages/profile/ListPayment";
import useWidth from "@hooks/useWidth";

const ProfilePage = () => {
  const location = useLocation();
  const memKey = getLocalStorage('prptbk-token', 'mem_key');
  const memAuth = getLocalStorage('prptbk-token', 'mem_auth');
  const [activeKey, setActiveKey] = useState('home');
  const [isTabChanged, setIsTabChanged] = useState(true); // 탭 변경을 막기 위한 상태
  const width = useWidth();

  const tabs = [
    { id: 'home', label: '홈', component: <ProfileHome overrideData={location.state} /> },
    { id: 'profileDetail', label: width > 700 ? '내 프로필' : '프로필', component: <ProfileDetail overrideData={location.state} /> },
    { id: 'planInfo', label: width > 700 ? '플랜 정보' : '플랜', component: <PlanInfo overrideData={location.state} /> },
    { id: 'noticeSetting', label: width > 700 ? '알림 설정' : '알림', component: <NoticeSetting /> },
    { id: 'ranking', label: '랭킹', component: <Ranking overrideData={location.state} /> },
    { id: 'paymentHistory', label: width > 700 ? '결제내역' : '결제', component: <ListPayment /> },
  ];
  const filteredTabs = () => {
    // 내가 내 프로필을 볼때: 탭 전체
    if (location.state?.mem_key === memKey) return tabs;
    // 내가 관리자이고 다른 사람의 프로필을 볼때: '홈', '내 프로필', '랭킹'
    else if (isAdmin(memAuth)) return tabs.filter((tab) => ['home', 'profileDetail', 'ranking'].includes(tab.id));
    // 내 프로필도 아니고, 내가 관리자도 아닐때: '홈', '랭킹'
    else return tabs.filter((tab) => ['home', 'ranking'].includes(tab.id));
  };

  const handleTabClick = (key) => {
    setIsTabChanged(true);
    if (isTabChanged) {
      setActiveKey(key);
    }
  };

  useEffect(() => {
    if (location.state?.planInfo === 'true') {
      setActiveKey('planInfo');
    } else if (location.state?.profileDetail === 'true') {
      setActiveKey('profileDetail');
    } else if (location.state?.ranking === 'true') {
      setActiveKey('ranking');
    } else if (location.state?.plan === 'true') {
      setActiveKey('planInfo');
    } else {
      setActiveKey('home');
    }
  }, [location.state]);

  return <Tabs articleId="myPage" tabs={filteredTabs()} initialTab={activeKey} onTabClick={handleTabClick} />;
};

export default ProfilePage;
