import React from 'react'

const AvailableCouponButton = (props) => {
  const { status, count, disabled, openAvailableCouponModal } = props;

  return (
    <>
      <button className={status ? "btn-coupon" : "btn-coupon off"} disabled={disabled} onClick={() => openAvailableCouponModal()}>
        등록가능한 쿠폰 <span>{count}</span>
      </button>
    </>
  )
}

export default AvailableCouponButton