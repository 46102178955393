import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import Slider from 'react-slick';
import mainBanner from '@assets/images/common/mainBanner.png';
import whiteArrowRight from '@assets/images/common/white_arrow_right.png';
import dayjs from 'dayjs';
import * as api from '@api/index';
const Banner = memo(({ bannerCd, bannerTp }) => {
  /**
   * bannerCd = {left: "BANNERCD", right: "BANNERCD"}
   * or
   * bannerCd = "BANNERCD"
   *
   * bannerTp = "Top", "Bottom", "MiddleOne", "MiddleTwo"
   * */

  const toDate = dayjs().format('YYYYMMDD');
  const [bannerList, setBannerList] = useState({ one: [], left: [], right: [] });
  const draggingRef = useRef(false); // 드레깅 유무

  const settings = useCallback(
    (list) => ({
      dots: list?.length > 1,
      arrows: false,
      infinite: list?.length > 1,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: handleBeforeChange,
      afterChange: handleAfterChange,
    }),
    [],
  );

  const getBannerList = useCallback(
    async (cd, type) => {
      const params = { banner_cd: cd, to_date: toDate };
      const { data } = await api.getBannerByLoc(params);
      if (!data) return;
      console.log(data);
      setBannerList((prev) => ({ ...prev, [type]: data }));
    },
    [toDate],
  );

  const handleBeforeChange = useCallback(() => {
    draggingRef.current = true;
  }, []);
  const handleAfterChange = useCallback(() => {
    draggingRef.current = false;
  }, []);

  const moveToUrl = useCallback((item) => {
    if (!draggingRef.current) {
      if (item.banner_url) {
        // 배너 url이 있는 경우에만 페이지 이동
        window.open(item.banner_url, '_blank');
        updateBannerView(item.banner_seq);
      }
    }
  }, []);

  // 조회 수 업데이트
  const updateBannerView = useCallback((bannerSeq) => {
    api.updateBannerView({ banner_seq: bannerSeq });
  }, []);

  const onError = useCallback((e) => {
    e.currentTarget.src = mainBanner;
  }, []);

  useEffect(() => {
    if (bannerTp === 'MiddleTwo') {
      getBannerList(bannerCd.left, 'left');
      getBannerList(bannerCd.right, 'right');
    } else {
      getBannerList(bannerCd, 'one');
    }
  }, [bannerCd, bannerTp, getBannerList]);

  const BannerTopAndBottom = useCallback(
    () => (
      <div id={bannerTp === 'Top' ? 'mainSlider' : 'bannerSlider'} className={`slider-container ${bannerList.one.length > 0 ? '' : 'none'}`}>
        <Slider {...settings(bannerList.one)}>
          {bannerList.one.map((item, index) => (
            <div className="bannerBox" key={index} onClick={() => moveToUrl(item)}>
              <div className={(item.banner_url) ? "withCursor flexColCenter" : "flexColCenter"} >
                <div className="rsWrapper flexColCenter">
                  {/*{bannerTp === 'Top' && (*/}
                  {/*  <div id="whiteBorderBtn" onClick={() => moveToUrl(item)}>*/}
                  {/*    <p>바로가기</p>*/}
                  {/*    <img src={whiteArrowRight} alt="white_arrow_right" />*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
              </div>
              <img src={item.banner_img_path} alt="main_banner" onError={onError} />
            </div>
          ))}
        </Slider>
      </div>
    ),
    [bannerList.one, bannerTp, moveToUrl, onError, settings],
  );

  const BannerMiddleOne = useCallback(
    () => (
      <article id="subBanner2" className={`slider-container flexColCenter ${bannerList.one.length > 0 ? '' : 'none'}`}>
        <div className="rsWrapper">
          <Slider {...settings(bannerList.one)}>
            {bannerList.one.map((item, index) => (
              <div className={(item.banner_url) ? "withCursor" : ""} style={{ width: '100%' }} key={index} onClick={() => moveToUrl(item)}>
                <img src={item.banner_img_path} alt="subBanner" onError={onError} />
              </div>
            ))}
          </Slider>
        </div>
      </article>
    ),
    [bannerList.one, moveToUrl, onError, settings],
  );

  const BannerMiddleTwo = useCallback(
    () => (
      <article id="subBannerWrapper" className={`flexColCenter ${bannerList.left.length > 0 || bannerList.right.length ? '' : 'none'}`}>
        <div className="rsWrapper flexRowBetween">
          <div className={`subBanner ${bannerList.left.length > 0 ? '' : 'none'}`} >
            <Slider {...settings(bannerList.left)}>
              {bannerList.left.map((item, index) => (
                <div className={(item.banner_url) ? "withCursor" : ""} style={{ width: '100%'}} key={index} onClick={() => moveToUrl(item)} >
                    <img src={item.banner_img_path} alt="" onError={onError}/>
                </div>
              ))}
            </Slider>
          </div>
          <div className={`subBanner ${bannerList.right.length > 0 ? '' : 'none'}`} >
            <Slider {...settings(bannerList.right)}>
              {bannerList.right.map((item, index) => (
                <div className={(item.banner_url) ? "withCursor" : ""} style={{ width: '100%'}} key={index} onClick={() => moveToUrl(item)} >
                    <img src={item.banner_img_path} alt="" onError={onError}/>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </article>
    ),
    [bannerList.left, bannerList.right, moveToUrl, onError, settings],
  );

  if (bannerTp === 'Top' || bannerTp === 'Bottom') {
    return <BannerTopAndBottom />;
  }
  if (bannerTp === 'MiddleOne') {
    return <BannerMiddleOne />;
  }
  if (bannerTp === 'MiddleTwo') {
    return <BannerMiddleTwo />;
  }
  return null;
});

export default Banner;
