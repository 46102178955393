import React from 'react';
// lib
import { koKRFormat, getDefaultImg, handleImageError } from '@utils/lib';
// component
import Rate from '@components/Rate';
import IconRank from '@components/IconRank';
import { IMAGES } from '@utils/image';

const CardSquareImage1 = ({ data, isActive, moveToPromptDetailPage, moveToUserDetailPage }) => {
  // 카테고리 이름 가져오기
  const getCateNm = { CATE001: 'image', CATE002: 'video', CATE004: 'text' }[data?.cate_cd] || 'image';

  return (
    <div
      className="list flexColCenter"
      key={data?.prpt_id}
      onClick={(e) => {
        //e.stopPropagation();
        sessionStorage.setItem('slick-seq', data?.prpt_id);
      }}
    >
      <div className="flexColCenter">
        {/* <img src={getDefaultImg('image', data)} /> */}
        <div
          className="flexColBetween absoluteBox"
          onClick={() => {
            if (isActive) moveToPromptDetailPage();
          }}
        >
          <div className="thumbnail" style={{ backgroundImage: `url(${getDefaultImg(getCateNm, data)})` }}>
            <div className="flexRowBetween user">
              <div className="aiBox">{data?.ai_model_nm && <p>{data?.ai_model_nm}</p>}</div>

              <IconRank rankValue={data?.rnk} />
            </div>
            <p>{data?.prpt_title}</p>
          </div>

          <div className="flexColCenter title">
            <div
              className="greyBox flexRowBetween"
              onClick={(e) => {
                if (isActive) {
                  e.stopPropagation();
                  moveToUserDetailPage();
                }
              }}
            >
              <img src={data?.mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />

              <div className="flexColStart">
                <p>{data?.mem_nick}</p>
                <div className="flexRowBetween">
                  <div className="flexRowStart imgBox">
                    <img src={data?.icon_path} />
                    {/* <Rate isNotEmpty={true} value={data?.score_avg} /> */}
                  </div>

                  <p>{data?.sale_amt === 0 ? '무료' : `₩ ${koKRFormat(data?.sale_amt)}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardSquareImage1;
