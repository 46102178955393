import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Input, Layout, List, Row, Tabs, message } from 'antd';
import { Header } from 'antd/es/layout/layout';
// icon
import { CloseOutlined } from '@ant-design/icons';
import searchIcon from '@assets/images/common/search_icon.png';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';
import heartIcon from '@assets/images/common/heart_icon.png';
import userThum from '@assets/images/common/user_thumb.png';
import playIcon2 from '@assets/images/common/play_icon2.png';
// component
import CardItem from '@components/cardMarketPlace/CardItem';
import Filter from '@components/cardMarketPlace/Filter';
import CardPrompt from '@pages/marketplaces/CardPrompt';
// api
import * as api from '@api/index';
// constant
import { marketplaceSrot } from '@utils/constants';
// lib
import { formatNumber, getDefaultImg, koKRFormat, useDebounce, validateSession, getLocalStorage } from '@utils/lib';
// pathname
import * as PATH from '@routes/pathName';
// constants
import { AUTH_LOGIN, POPUP_MUSIC_PLAYER, POPUP_VIDEO_PLAYER } from '@utils/constants';

const MarketPromptList = () => {
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const { pathname } = useLocation();
  const [loading, setLoading] = useState({});
  const navigate = useNavigate();

  const [activeTabKey, setActiveTabKey] = useState(''); // 활성화된 tab
  const [promptList, setPromptList] = useState({
    query: {
      mem_key: memKey,
      keyword_tab: '', // 조회 tab (판매/구매/좋아요 목록)
      filter_text: '', // 프롬프트 제목/tag 검색
      filter_cate: '', // 프롬프트 카테고리
      filter_model: '', // 프롬프트 모델
      filter_stat: '', // 프롬프트 상태
      filter_genre: '', // 프롬프트 장르
      filter_sort: '', // 정렬
      offset: 0,
      limit: 20,
    },
    items: [],
  });

  // 공통코드 (filter 용)
  const [commonCodeList, setCommonCodeList] = useState([]);

  /** 이벤트 핸들러 */
  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 200),
    [],
  );

  // filter 변경 시
  const handleChangeFilter = (selectedItem, filterText) => {
    const newQuery = {
      ...promptList.query,
      filter_text: filterText,
      filter_cate: selectedItem.CATE == 'all' ? '' : selectedItem.CATE || '' /* 필터 존재 하지 않는 경우 'undifined' 방지 ''로 리턴 */,
      filter_model: selectedItem.MODEL == 'all' ? '' : selectedItem.MODEL || '',
      filter_stat: selectedItem.PRPT_STAT == 'all' ? '' : selectedItem.PRPT_STAT || '',
      filter_genre: selectedItem.GENRE == 'all' ? '' : selectedItem.GENRE || '',
      filter_sort: selectedItem.sort,
    };

    setPromptList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getMyPromptList(newQuery)); // 조회
  };

  // 공통코드 조회
  const getCommonCodes = useCallback(async () => {
    const pathSegments = pathname.split('/');
    const subTab = pathSegments[3];
    const params = subTab == 'purchaseList' ? [{ grp_cd: 'CATE' }, { grp_cd: 'MODEL' }] : [{ grp_cd: 'CATE' }, { grp_cd: 'MODEL' }, { grp_cd: 'PRPT_STAT' }]; /* , { grp_cd: 'GENRE' } */
    //const params = [{ grp_cd: 'CATE' }, { grp_cd: 'MODEL' }, { grp_cd: 'PRPT_STAT' }]; /*  */

    const { data } = await api.fetchCodesByGroupCodes(params);

    const transFormat = data.map((grpCode) => ({
      label: grpCode.grp_nm,
      value: grpCode.grp_cd,
      children: grpCode.detailData.map((item) => ({
        label: item?.cd_nm,
        value: item?.cd,
        parent: item?.parent_cd,
      })),
    }));

    transFormat.push(marketplaceSrot);
    setCommonCodeList(transFormat);
  }, []);

  // 프롬프트 리스트 조회
  const getMyPromptList = useCallback(async (query) => {
    try {
      const { data } = await api.getMyPromptList(query);
      setPromptList((prev) => ({ ...prev, items: data.items }));
    } catch (e) {
      // 에러 처리
    } finally {
    }
  }, []);

  // 상세로 이동
  const moveToDetail = async (item) => {
    if (item.prpt_stat == '00' || item.prpt_stat == '01') {
      // 임시저장, 작성완료일 경우 등록 페이지로 이동
      navigate(PATH.SALES_PROMPT_HOME, {
        state: { prpt_id: item.prpt_id },
      });
    } else {
      // 상태가 승인요청 이상일 경우 상세로 이동
      navigate(PATH.PROMPT_DETAIL, {
        state: { prpt_id: item.prpt_id },
      });
      updatePromptView(item.prpt_id, 'V'); // 조회 카운트 update
    }
  };

  const updatePromptView = (promptId, viewType) => {
    //  조회 타입 'D'/'P'/'V' (다운로드/플레이/조회)
    api.updatePromptView({ prpt_id: promptId, view_type: viewType });
  };

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 사용자 프로필 페이지 이동
  const moveToProfile = async (prompt) => {
    try {
      moveToPage(PATH.PROFILE_PAGE, { mem_email: prompt?.mem_email, mem_key: prompt?.mem_key });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (prompt) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: prompt?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: prompt?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 비디오/음악 플레이어 열기
  const onOpenPlayer = async (prompt) => {
    try {
      if (!prompt) return;
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        if (prompt.cate_cd === 'CATE002') window.open(`${PATH.VIDEO_PLAYER}?prpt_id=${encodeURI(btoa(prompt?.prpt_id))}`, POPUP_VIDEO_PLAYER, 'width=700,height=400');
        if (prompt.cate_cd === 'CATE003') {
          // 플레이리스트에 곡 추가
          const requestMusic = {
            mem_key: memKey,
            prpt_id: prompt?.prpt_id,
            prpt_title: prompt?.prpt_title,
            prpt_path: prompt?.music_org_link,
          };
          await api.addPlaylistMusic([requestMusic]);
          window.open(PATH.MUSIC_PLAYER, POPUP_MUSIC_PLAYER, 'width=375,height=700');
        }
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  useEffect(() => {
    getCommonCodes();
  }, []);

  useEffect(() => {
    const pathSegments = pathname.split('/');
    const subTab = pathSegments[3];
    setActiveTabKey(subTab);

    const newQuery = { ...promptList.query, keyword_tab: subTab };
    setPromptList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getMyPromptList(newQuery)); // 조회
  }, [pathname]);

  return (
    <>
      <Filter filterItem={commonCodeList} handleChangeFilter={handleChangeFilter} key={pathname} />
      <article id="listWrapper" className="flexColCenter promptList ">
        <div className="rsWrapper flexRowBetween">
          <div className="flexColCenter bottom newContents">
            <div className="listBox flexRowStart staffList">
              {!Array.isArray(promptList?.items) || promptList?.items.length === 0 ? (
                <div className="noResult">
                  <p>등록된 프롬프트가 없습니다.</p>
                </div>
              ) : (
                <>
                  {promptList?.items?.map((item, index) => (
                    <>
                      {['purchaseList', 'likeList'].includes(activeTabKey) ? (
                        // 구매목록, 좋아요 목록
                        <CardPrompt
                          key={index}
                          prompt={item}
                          openPlayer={() => onOpenPlayer(item)}
                          moveToPromptDetailPage={() => moveToDetailPage(item)}
                          moveToUserDetailPage={() => moveToProfile(item)}
                        />
                      ) : (
                        // 핀매목록
                        <div className="staffBox " key={item.prpt_id} id={['CATE002', 'CATE003'].includes(item?.cate_cd) ? 'playerBg' : ''}>
                          <div className="absoluteBox " onClick={() => moveToDetail(item)}>
                            <div>
                              <div className="thumbnail" style={{ backgroundImage: `url(${item?.thum_path ?? userThum})` }}>
                                <div className="user">{item?.ai_model_nm && <p>{item?.ai_model_nm}</p>}</div>
                              </div>
                            </div>

                            {['CATE002', 'CATE003'].includes(item?.cate_cd) && (
                              <>
                                <div className="hover-bg"></div>
                                <div className="play flexColCenter">
                                  <img
                                    src={playIcon2}
                                    alt="playIcon"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onOpenPlayer(item);
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            <div className="title">
                              <h2 className="">
                                <span> {item?.prpt_title}</span>
                                <p>{Number(item?.sale_amt) === 0 ? '무료' : `₩ ${koKRFormat(Number(item?.sale_amt))}`}</p>
                              </h2>
                              {activeTabKey === 'salesList' || activeTabKey === 'PromptDetail' ? (
                                <div
                                  className="infoCount"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // moveToUserDetailPage();
                                  }}
                                >
                                  <span className="good">{koKRFormat(Number(item?.like_cnt))}</span>
                                  <span className="sale">{koKRFormat(Number(item?.sales_cnt))}</span>
                                  {/* 판매중 : on / 승인 : ok /  판매요청 : request /  판매중지 : off / 판매거절 : rejection / 임시저장 : disable / 작성완료 : done /  */}
                                  {item?.prpt_stat_nm ? (
                                    <span
                                      className={`
                                          approval
                                          ${
                                            item?.prpt_stat === '00'
                                              ? 'done'
                                              : item?.prpt_stat === '01'
                                                ? 'disable'
                                                : item?.prpt_stat === '10'
                                                  ? ' request'
                                                  : item?.prpt_stat === '20'
                                                    ? 'ok'
                                                    : item?.prpt_stat === '30'
                                                      ? 'on'
                                                      : item?.prpt_stat === '98'
                                                        ? 'rejection'
                                                        : item?.prpt_stat === '99'
                                                          ? ' off'
                                                          : ''
                                          }
                                        `}
                                    >
                                      {item?.prpt_stat_nm}
                                    </span>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default MarketPromptList;
