import React from 'react';
import useWidth from "@hooks/useWidth";

const LoadingBar = ({ loading, progress }) => {
    const width = useWidth();

    return (
        <div className="container">
            {loading && (
                <div className="loading-container" style={{
                    position: 'fixed',
                    top: '53px',
                    left: 0,
                    width: '100%',
                    height: '4px',
                    backgroundColor: '#f0f0f0',
                    zIndex: 1000
                }}>
                    <div
                        className="loading-bar"
                        style={{
                            height: '100%',
                            width: `${progress}%`,
                            background:'linear-gradient(90deg, #13D5DF 0%, #F138FC 100%)',
                            transition: 'width 0.1s linear'
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default LoadingBar;