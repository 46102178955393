import React, { useEffect, useRef, useState } from 'react';
import Loading from '@components/Loading';
import { Button, Card, Form, Input, Layout, Select } from 'antd';
import { sortOption } from '@utils/constants';
import Table from '@components/Table';
import { Content } from 'antd/es/layout/layout';
import * as api from '@api/index';
import {IMAGES} from "@utils/image";
import * as PATH from "@routes/pathName";
import {useNavigate} from "react-router-dom";

const BlockedUser = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);
  const blockedUserListRef = useRef();
  const [form] = Form.useForm();
  const [sendData, setSendData] = useState({
    keyword_text: '',
  });

  const [totalCnt, setTotalCnt] = useState(0);
  const [selectedSort, setSelectedSort] = useState(null);

  const onChange = (name, value) => {
    setSendData({
      ...sendData,
      [name]: value,
    });
  };

  const handleProfileClick = (memKey) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_key: memKey,
      }
    });
  };

  const columnLayout = [
    { dataField: 'mem_key', headerText: '멤버 KEY', visible: false },
    { dataField: 'blk_mem_key', headerText: '차단 멤버 KEY', visible: false},
    { dataField: 'mem_id', headerText: '멤버 ID', visible: false },
    { dataField: 'mem_nick', headerText: '닉네임', width: '100%', editable: false,
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value) => {
        let rowData = blockedUserListRef.current.getGridData()[rowIndex];
        let memKey = rowData?.blk_mem_key;
        let memEmail = rowData?.mem_email;
        let mem_img_path = rowData?.mem_img_path;
        console.log(memEmail, memKey);
        return (
            `<div style="display: flex; align-items: center; gap: 8px; cursor: pointer;" 
             onclick="window.$agRendererTemplate.handleProfileClick('${encodeURIComponent(memKey)}')">
                <img src="${mem_img_path || IMAGES.DEFAULT_PROFILE}" 
                     alt="profile" 
                     style="width:20px; height:20px; border-radius:50%;"
                     onerror="this.onerror=null; this.src='${IMAGES.DEFAULT_PROFILE}';">
                <span>@${value}</span>
            </div>`
        );
      },
    },
  ];

  useEffect(() => {
    if (!window.$agRendererTemplate) {
      window.$agRendererTemplate = {};
    }
    window.$agRendererTemplate.handleProfileClick = handleProfileClick;

    if (blockedUserListRef.current) {
      blockedUserListRef.current.bind('cellClick', (event) => {
        const gridData = blockedUserListRef.current.getGridDataWithState('state');
        const selectedRowData = gridData[event.rowIndex];
        handleProfileClick(selectedRowData.mem_email, selectedRowData.mem_key);
      });
    }
    return () => {
      if (blockedUserListRef.current) {
        blockedUserListRef.current.unbind('cellClick');
      }
    };
  }, []);

  const blockedUserProps = {
    editable: false,
    showRowCheckColumn: true,
    showRowNumColumn: false,
    usePaging: true,
    showPageButtonCount: 5,
    showPageRowSelect: true,
    pageRowCount: 15,
    pageRowSelectValues: [15, 30, 50, 100],
    showHeader: false,
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter') {
      getBlockedList();
    }
  };

  const getBlockedList = async (sort) => {
    try {
      const response = await api.getBlockedList({
        keyword_text: sendData.keyword_text,
        sort: sort,
      });
      blockedUserListRef.current.setGridData(response.data);
      setTotalCnt(response.data.length);

    } catch (error) {
      console.error('조회 error', error);
    }
  };

  const deleteBlocked = async () => {
    var checkedItems = blockedUserListRef.current.getCheckedRowItems({});
    if (checkedItems.length < 1) {
      alert('차단 해제할 멤버를 선택해 주세요.');
      return;
    }
    const itemsToDelete = checkedItems.map((checkedItem) => checkedItem.item);

    try {
      setLoading((prev) => ({ ...prev, saveLoad: true }));

      const response = await api.deleteBlocked(itemsToDelete);
      alert(response.data.returnMessage);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => ({ ...prev, saveLoad: false }));
      getBlockedList();
    }
  };

  useEffect(() => {
    getBlockedList();
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="modalCont">
        <div className="inputArea">
            <span>총 {totalCnt}명</span>
            <Input
                placeholder="ID나 유저네임을 입력해 주세요."
                value={sendData.keyword_text}
                onChange={(e) => onChange('keyword_text', e.target.value)}
                onKeyUp={(e) => handleKeyUp(e)}
            />
            <button className="btn-searchIcon" onClick={getBlockedList}>
              검색
            </button>
            <button className="btn-unblock" onClick={deleteBlocked}>차단 해제</button>
            <Select options={sortOption} placeholder="정렬 선택" onChange={(value) => getBlockedList(value)} />
        </div>
        <Content style={{ borderTop: '2px solid #000'}}>
          <Table ref={blockedUserListRef} columnLayout={columnLayout} customGridProps={blockedUserProps} />
        </Content>
      </div>
    </>
  );
};
export default BlockedUser;
