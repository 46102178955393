import React from 'react';
// component
import ListTop10Music from '@pages/main/ListTop10Music';
import ListNewMusic from '@pages/main/ListNewMusic';
import ListGenreMusic from '@pages/main/ListGenreMusic';
import ListPopularMusic from '@pages/main/ListPopularMusic';
// path
import * as PATH from '@routes/pathName';
// const
import { POPUP_MUSIC_PLAYER } from '@utils/constants';

const ContentMusic = (props) => {
  // 음악 플레이어 열기
  const openMusicPlayer = () => {
    window.open(PATH.MUSIC_PLAYER, POPUP_MUSIC_PLAYER, 'width=375,height=700');
  };

  return (
    <>
      <ListTop10Music openMusicPlayer={openMusicPlayer} />
      <ListNewMusic openMusicPlayer={openMusicPlayer} />
      <ListGenreMusic openMusicPlayer={openMusicPlayer} />
      <ListPopularMusic openMusicPlayer={openMusicPlayer} />
    </>
  );
};

export default ContentMusic;
