import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loading from '@components/Loading';
import { Button, Input, Layout, Modal } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import * as api from '@api/index';
import Table from '@components/Table';
import { useDebounce } from '@utils/lib';
import BlockedUser from "@pages/profile/BlockedUser";
import useWidth from "@hooks/useWidth";
import { IMAGES } from "@utils/image"
import * as PATH from "@routes/pathName";
import {useNavigate} from "react-router-dom";

const Ranking = ({ overrideData }) => {
  const width = useWidth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState({});
  const [cateList, setCateList] = useState([]);
  const [selectedButton, setSelectedButton] = useState(overrideData?.cateCd || 'CATE001');
  const [searchKeyword, setSearchKeyword] = useState(''); // 검색어 상태 추가
  const rankingGridRef = useRef();
  const isLoading = Object.values(loading).some((value) => value);
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );
  const [isModalVisible, setModalVisible] = useState(false);

  const [userRankingList, setUserRankingList] = useState({
    query: {
      keyword_type: overrideData?.cateCd || 'CATE001',
      keyword_text: '',
      offset: 0,
      limit: 100,
      mem_key: overrideData?.mem_key,
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
    yScrollPosition: 0,
  });

  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));

      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'CATE' });
      setCateList(response1.data);
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));
    }
  };
  const rankingGridLayout = [
    { dataField: 'mem_key', headerText: '멤버 Key', visible: false },
    { dataField: 'mem_email', headerText: '멤버 email', visible: false },
    { dataField: 'mem_img_path', headerText: '게시판 썸네일', visible: false },
    { dataField: 'total_rnk', headerText: '랭킹', width: '20%', editable: false, headerStyle: { height: '0px' } ,
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
        return (
          `<p style="text-align: left; padding-left: 12px;">#${value}</p>`
        );
      },
    },
    { dataField: 'mem_nick', headerText: '닉네임', width: '80%', editable: false,
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value) => {
        let rowData = rankingGridRef.current.getGridData()[rowIndex];
        let memKey = rowData?.mem_key;
        let memEmail = rowData?.mem_email;
        let mem_img_path = rowData?.mem_img_path;
        return (
            `<div style="display: flex; align-items: center; gap: 8px; cursor: pointer;" 
             onclick="window.$agRendererTemplate.handleProfileClick('${encodeURIComponent(memEmail)}', '${encodeURIComponent(memKey)}')">
                <img src="${mem_img_path || IMAGES.DEFAULT_PROFILE}" 
                     alt="profile" 
                     style="width:20px; height:20px; border-radius:50%;"
                     onerror="this.onerror=null; this.src='${IMAGES.DEFAULT_PROFILE}';">
                <span>@${value}</span>
            </div>`
        );
      },
    },
  ];

  const rankingGridProps = {
    editable: false,
    showRowNumColumn: false, // 행번호 유무
    showStateColumn: false,
    enableSorting: true,
    showHeader: false,
  };

  // 랭킹 데이터 조회
  const fetchRankingData = async (query) => {
    try {
      setLoading((prev) => ({ ...prev, rankingLoad: true }));
      const { data } = await api.getUserRanking(query);
      // 두번째 이상 요청일 때 (스크롤 시 새로운 페이징 데이터 요청)
      if (query.offset && rankingGridRef.current.getGridData()) {
        rankingGridRef.current.appendData(data.items); // 기존 그리드에 다음 페이지 덧붙임
        setUserRankingList((prev) => ({
          ...prev,
          items: [rankingGridRef.current.getGridData()],
          totalCount: data.total,
          currentCount: rankingGridRef.current.getGridData().length,
        }));
      } else {
        rankingGridRef.current.setGridData(data.items); // 첫 요청 시
        setUserRankingList((prev) => ({
          ...prev,
          items: data.items,
          totalCount: data.total,
          currentCount: data.items.length,
        }));
        const targetIndex = data.items.findIndex(item => item.mem_key === query.mem_key);
        if (targetIndex !== -1) {
          rankingGridRef.current.setRowPosition(targetIndex);
        }
      }
    } catch (error) {
      console.error('랭킹 데이터 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, rankingLoad: false }));
    }
  };

  const handleButtonClick = (cd) => {
    const newQuery = { ...userRankingList.query, keyword_type: cd, keyword_text: searchKeyword, offset: 0 }; // 검색어 포함
    setSelectedButton(cd);
    setUserRankingList((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => fetchRankingData(newQuery));
  };

  const handleSearch = () => {
    handleButtonClick(selectedButton); // 현재 선택된 버튼의 타입으로 검색
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setModalVisible(false);
    }
  };

  useEffect(() => {
    getCommonCode();
    fetchRankingData(userRankingList.query); // 진입시 cate001로 랭킹조회
  }, []);


  const handleKeyPress  = async (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };
  useEffect(() => {
    if (searchKeyword === '') {
      handleSearch();
    }
  }, [searchKeyword]);

  const handleProfileClick = (memEmail, memKey) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_email: memEmail,
        mem_key: memKey,
      }
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
        // 스크롤이 끝까지 내려갔을 때 추가 데이터 요청
        setUserRankingList((prev) => {
          if (prev.currentCount >= prev.totalCount) return prev; // 데이터가 끝났으면 요청 X
          const newOffset = prev.query.offset + 100;
          const newQuery = { ...prev.query, offset: newOffset };
          fetchRankingData(newQuery);
          return { ...prev, query: newQuery };
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!window.$agRendererTemplate) {
      window.$agRendererTemplate = {};
    }
    window.$agRendererTemplate.handleProfileClick = handleProfileClick;

    if (rankingGridRef.current) {
      rankingGridRef.current.bind('cellClick', (event) => {
        const gridData = rankingGridRef.current.getGridDataWithState('state');
        const selectedRowData = gridData[event.rowIndex];
        handleProfileClick(selectedRowData.mem_email, selectedRowData.mem_key);
      });
    }
    return () => {
      if (rankingGridRef.current) {
        rankingGridRef.current.unbind('cellClick');
      }
    };
  }, []);

  return (
      <>
        <article id="myProfile">
          <div className="rankingArea">
            <Loading isLoading={isLoading} />
            <Layout style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Content style={{ width: '100%', padding: '0 0 32px' }}>
                <div className="rankingArea__header">
                  <div className="btnBox">
                    {cateList.map((item) => (
                        <button
                          id="pinkBtn2"
                          className={selectedButton === item.cd ? 'active' : ''}
                          key={item.cd}
                          onClick={() => handleButtonClick(item.cd)}
                          type="button"
                        >
                          {item.cd_nm}
                        </button>
                    ))}
                    </div>
                    <div className="inputBox">
                      <Input
                        value={searchKeyword} // 검색어 상태 바인딩
                        placeholder="ID나 유저네임을 입력해주세요."
                        onChange={(e) => setSearchKeyword(e.target.value)} // 검색어 상태 업데이트
                        onKeyDown={(e) => handleKeyPress(e)}
                      />
                      <button
                        className="btn-searchIcon"
                        type="button"
                        onClick={()=>handleSearch} // 검색 버튼 클릭 시 handleSearch 실행
                      >
                      </button>
                      <button
                        className="btn-reset"
                        onClick={() => setSearchKeyword('')}/>
                      <button
                        className="btn-block"
                        onClick={() => setModalVisible(true)}
                      >차단된 유저</button>
                    </div>
                </div>
                <Table
                    style={{ padding: '25px' }}
                    ref={rankingGridRef}
                    columnLayout={rankingGridLayout}
                    customGridProps={rankingGridProps}
                />
              </Content>
            </Layout>
        {/*차단 Modal*/}
        {isModalVisible && (
          <div className="modal" onClick={handleOverlayClick}>
            <div className="modalWrapper" onClick={(e) => e.stopPropagation()}>
              <div className="modalHeader">
                <h2>차단된 유저</h2>
                <button>
                  <img
                  src={IMAGES.CLOSE_ICON}
                  alt="닫기버튼"
                  className="close-icon"
                  onClick={() => setModalVisible(false)}
                />
                </button>
              </div>
              <BlockedUser />
            </div>
          </div>
          )}
          </div>
        </article>
    </>
  );
};
export default Ranking;