import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as api from '@api/index';
import closeIcon from '@assets/images/common/close_icon.png';

import {DatePicker, Form, Input, message, Select, Tooltip} from 'antd';
import Table from '@components/Table';

import dayjs from 'dayjs';
import { aiCertApr, aiCertSort, urlRegExp } from '@utils/constants';
import '/src/assets/css/AiModelCertModal.css';
import { getLocalStorage, makeSelectBoxList, useDebounce } from '@utils/lib';
import Loading from '@components/Loading';
import PhoneAuthModal from '@pages/profile/PhoneAuthModal';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '@stores/auth';
import {IMAGES} from "@utils/image";
import useWidth from "@hooks/useWidth";

const { RangePicker } = DatePicker;
const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const AiModelCertModal = ({ visible, onClose, aiModelCd }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const width = useWidth();

  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const aiCertGridRef = useRef(null);

  const phoneNumber = useRef('');
  const [isPhoneAuthModalVisible, setIsPhoneAuthModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [aiMembershipScreen, setAiMembershipScreen] = useState(null);
  const [delFileCaches, setDelFileCaches] = useState([]);
  const aiMembershipRef = useRef(null);

  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState('');
  const [tooltipVisable, setTooltipVisable] = useState(false);
  const tooltipRef = useRef(null);

  const today = dayjs();
  const oneWeekAgo = today.subtract(7, 'day');

  const [sendDate, setSendDate] = useState({
    st_date: oneWeekAgo.format('YYYY-MM-DD'),
    end_date: today.format('YYYY-MM-DD'),
  });

  const handleDate = ({ startDt, endDt }) => {
    setAiCertListState((prev) => ({
      ...prev,
      query: {
        ...prev.query,
        startDt: startDt,
        endDt: endDt,
        offset: 0,
      },
    }));

    setSendDate({
      st_date: startDt,
      end_date: endDt,
    });
  };

  const [aiCertListState, setAiCertListState] = useState({
    query: {
      keyword_type: '0',
      keyword_text: memKey,
      startDt: sendDate.st_date,
      endDt: sendDate.end_date,
      offset: 0,
      limit: 20,
      selector_3: '1',
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
    yScrollPosition: 0,
  });

  const [formData, setFormData] = useState({
    seq: '',
    ai_model_cd: aiModelCd || '',
    cert_start_dt: '',
    cert_end_dt: '',
    confirm_url: '',
  });

  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  const aiCertGridProps = {
    showRowCheckColumn: false,
    showRowNumColumn: false,
    showStateColumn: false,
    editable: false,
    height: width > 700 ? '10%' : window.innerHeight - 500,
  };

  const aiCertColumnLayout = [
    { dataField: 'seq', headerText: '순번', width: '8%', visible: false },
    { dataField: 'ai_model_nm', headerText: 'AI 서비스', editable: false, width: '12%' },
    { dataField: 'cert_start_dt', headerText: '시작일', formatString: 'yyyy-mm-dd', dataType: 'date', editable: false },
    { dataField: 'cert_end_dt', headerText: '종료일', formatString: 'yyyy-mm-dd', dataType: 'date', editable: false },
    {
      dataField: 'img_path',
      headerText: '스크린샷',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
        if (value) {
          const imgSrc = value.startsWith('blob:') ? value : value;
          return `<img src="${imgSrc}" alt="cert" style="height: 55px; width: 90%; border-radius: 5px; object-fit: cover; vertical-align: middle;">`;
        }
        return '<span>No image</span>';
      },
      editable: false,
    },
    { dataField: 'cert_stat', headerText: 'code', visible: false },
    {
      dataField: 'cert_stat_label',
      headerText: '인증상태',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value) => {
        let rowData = aiCertGridRef.current.getGridData()[rowIndex];
        return `<div class="certStat state${rowData?.cert_stat}" style="display: inline-block; height: 5%; line-height: 150%;" >
                    ${value}
            </div>`;
      },
    },
    { dataField: 'confirm_url', headerText: '본인 프로필 링크', editable: false },
  ];

  const setupGridEvents = useCallback(() => {
    if (aiCertGridRef.current && typeof aiCertGridRef.current.bind === 'function') {
      aiCertGridRef.current.bind('vScrollChange', (event) => {
        handleDebounce(() => setAiCertListState((prev) => ({ ...prev, yScrollPosition: event.position })));
        if (event.position === event.maxPosition && aiCertListState.currentCount < aiCertListState.totalCount) {
          handleScroll();
        }
      });
    }
  }, [handleDebounce, aiCertListState.currentCount, aiCertListState.totalCount]);

  const handleScroll = () => {
    const newQuery = { ...aiCertListState.query, offset: aiCertListState.query.offset + aiCertListState.query.limit };
    setAiCertListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getAiCertList(newQuery));
  };

  const getAiList = useCallback(async () => {
    try {
      // const [model] = await Promise.all([api.fetchCommonDetailCode({ grp_cd: 'MODEL' })]);
      // setCodes(makeSelectBoxList(model?.data, 'cd_desc', 'cd'));

      const [model] = await Promise.all([api.getAiCertCode()]);
      console.log(model?.data);

      setCodes(makeSelectBoxList(model?.data, 'ai_model_nm', 'ai_model_cd'));
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  const getAiCertList = useCallback(
    async (query) => {
      try {
        setIsLoading(true);
        const { data } = await api.getAiCertList(query);
        if (!data) return;

        const processedItems = data.items.map((item) => {
          const certStatLabel = aiCertApr.find((apr) => apr.value === item.cert_stat)?.label || item.cert_stat;

          return {
            ...item,
            cert_stat_label: certStatLabel,
            cert_stat: item.cert_stat,
          };
        });

        setAiCertListState((prev) => ({
          ...prev,
          items: processedItems /*data.items*/,
          totalCount: data.total,
          currentCount: data.items.length,
        }));

        if (aiCertGridRef.current) {
          aiCertGridRef.current.setGridData(processedItems);
        }
        setupGridEvents();
      } catch (error) {
        console.error('AI Cert List Fetch Error:', error);
        message.error(error.message || '데이터를 불러오는 데 실패했습니다.');
      } finally {
        setIsLoading(false);
      }
    },
    [setupGridEvents],
  );

  const handleSearchAiCert = (selector_3) => {
    form.setFieldsValue({ selector_3: selector_3 });
    const newQuery = { ...aiCertListState.query, selector_3, offset: 0 };
    setAiCertListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getAiCertList(newQuery));
  };
  const updateAiCert = async () => {
    try {
      if (!auth.mem_tel) {
        setIsPhoneAuthModalVisible(true);
        return;
      }

      const sendFormData = new FormData();
      const aiCertData = {
        ...formData, mem_key: auth?.mem_key
      };
      sendFormData.append(
        'updateAiCerList',
        new Blob([JSON.stringify([aiCertData])], {
          type: 'application/json',
        }),
      );
      if (aiMembershipScreen) {
        sendFormData.append('files', aiMembershipScreen);
      } else {
        message.error('AI 멤버십 스크린샷은 필수입니다. 이미지 파일을 선택하세요.');
        return;
      }
      const validationRules = [
        { field: 'ai_model_cd', message: 'AI 서비스를 선택해주세요.' },
        { field: 'cert_start_dt', message: '시작일이 비어있습니다.' },
        { field: 'cert_end_dt', message: '종료일이 비어있습니다.' },
        { field: 'confirm_url', message: '본인 프로필 링크가 비어있습니다.' },
      ];

      for (const rule of validationRules) {
        if (formData[rule.field] === '') {
          message.warning(rule.message);
          return;
        }
        const value = formData[rule.field];
        if (rule.field === "confirm_url" && !urlRegExp.test(value)) {
          alert("잘못된 URL 형식입니다. \nEx) https://suno.com/@master \n형식으로 입력해주세요.");
          return;
        }
      }

      const response = await api.updateAiCert(sendFormData);
      if (response.data.returnStatus === 'success') {
        // message.success(response.data.returnMessage);
        message.success('인증 요청이 완료되었습니다.');
        await getAiCertList(aiCertListState.query);
      } else {
        message.error(response.data.returnMessage || 'AI 인증 정보를 업데이트하는 데 실패했습니다.');
      }
    } catch (error) {
      console.error('updateAiCert 오류:', error);
      message.error(error?.response?.data?.message || 'AI 인증 정보를 업데이트하는 데 실패했습니다.');
    }
  };

  const handleAiMembershipClick = () => {
    aiMembershipRef.current?.click();
  };

  const handleAiMembershipChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      aiMembershipScreen?.status === 'done' && setDelFileCaches((prev) => [...prev, aiMembershipScreen.url]);
      setAiMembershipScreen(file);
    }
  };

  useEffect(() => {
    if (visible) {
      resetContents();
      getAiList();
      getAiCertList(aiCertListState.query);

      aiCertGridRef.current.bind('cellClick', (event) => {
        const gridData = aiCertGridRef.current.getGridDataWithState('state');
        const selectedRowData = gridData[event.rowIndex];
        if (selectedRowData.cert_stat !== '91') {
          setIsSaveBtnDisabled('none');
        } else {
          setIsSaveBtnDisabled('');
        }

        setFormData((prevFormData) => ({
          ...prevFormData,
          seq: selectedRowData.seq,
          ai_model_cd: aiModelCd || selectedRowData.ai_model_cd,
          cert_start_dt: selectedRowData.cert_start_dt,
          cert_end_dt: selectedRowData.cert_end_dt,
          confirm_url: selectedRowData.confirm_url,
        }));

        form.setFieldsValue({
          ai_model_cd: selectedRowData.ai_model_cd,
          cert_start_dt: selectedRowData.cert_start_dt,
          cert_end_dt: selectedRowData.cert_end_dt,
          confirm_url: selectedRowData.confirm_url,
        });

        if (selectedRowData.img_path) {
          fetch(selectedRowData.img_path)
            .then((response) => response.blob())
            .then((blob) => {
              const file = new File([blob], selectedRowData.img_file_nm, { type: blob.type });
              setAiMembershipScreen(file);
            })
            .catch((error) => console.error('이미지 로딩 오류:', error));
        }
      });
    }
  }, [visible]);

  const resetContents = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      seq: '',
      ai_model_cd: aiModelCd || '',
      cert_start_dt: '',
      cert_end_dt: '',
      confirm_url: '',
    }));
    setIsSaveBtnDisabled('');
    setAiMembershipScreen('');
  };

  const handlePhoneAuthentication = (authenticatedPhoneNumber) => {
    const phoneStr = String(authenticatedPhoneNumber || '');
    const cleanPhoneNumber = phoneStr.replace(/[^0-9]/g, '');
    setIsPhoneAuthModalVisible(false);
    localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify({ ...JSON.parse(localStorage.getItem(TOKEN_STORE_KEY) || {}), mem_tel: authenticatedPhoneNumber }));
    dispatch(setAuth({ mem_tel: cleanPhoneNumber }));
    api.updateMemberPhone({ mem_key: auth?.mem_key, mem_tel: cleanPhoneNumber });
  };

  useEffect(() => {
    if (visible) {
      const originalStyle = window.getComputedStyle(document.body).overflow;

      document.body.style.overflow = 'hidden';
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;

      return () => {
        document.body.style.overflow = originalStyle;
        document.body.style.paddingRight = '0px';
      };
    }
  }, [visible]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setTooltipVisable(false);
      }
    }

    if (tooltipVisable) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tooltipVisable]);

  if (!visible) return null;

  return (
    <Form form={form} layout="inline" initialValues={aiCertListState.query}>
      <div className="modal" onClick={onClose}>
        <div className="modalAi" onClick={(e) => e.stopPropagation()}>
          <div className="modalAi__headerArea">
            <p>AI 모델 상업적 사용 인증</p>
            <button className="closeIcon" type="button" onClick={onClose}>
              <img src={closeIcon} alt="close" />
            </button>
          </div>
          {/* 등록 영역 */}
          <div className="modalAi__wrap">
            <section className="modalAi__addArea">
              <div className="optBox">
                <div className="add">
                  <p ref={tooltipRef} style={{ display: "inline-flex", alignItems: "center" }}>
                    AI 멤버십 스크린샷
                    <Tooltip
                        title="해당 AI 서비스 멤버십 플랜명 및 기간이 보이도록 스크린샷을 촬영하세요."
                        open={tooltipVisable}
                    >
                      <img
                          src={IMAGES.QUESTION_ICON}
                          alt=""
                          style={{ cursor: "pointer", marginLeft: "8px", width:'16px' }}
                          onClick={() => setTooltipVisable(!tooltipVisable)}
                      />
                    </Tooltip>
                  </p>
                  <form>
                    <input type="file" ref={aiMembershipRef} onChange={handleAiMembershipChange} style={{ display: 'none' }} accept="image/*" />
                    <div style={{ cursor: 'pointer' }} onClick={handleAiMembershipClick}>
                      {aiMembershipScreen ? (
                        <img src={URL.createObjectURL(aiMembershipScreen)} alt="AI 멤버십 스크린 샷" style={{ width: '100%', height: '100%', margin: '0', objectFit: 'cover' }} />
                      ) : (
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            border: '1px solid #999',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            fontSize: '28px',
                            textAlign: 'center',
                            lineHeight: '1',
                            color: 'white',
                          }}
                        >
                          +
                        </span>
                      )}
                    </div>
                  </form>
                </div>
                <ul>
                  <li>
                    <p>AI 서비스</p>
                    <Select
                      style={{ width: '100%', height: '40px' }}
                      placeholder="AI 모델 선택"
                      options={codes}
                      value={formData.ai_model_cd || null}
                      onChange={(value) => setFormData({ ...formData, ai_model_cd: value })}
                    />
                  </li>
                  <li>
                    <p>AI 멤버십 기간</p>
                    <div className="datepicker">
                      <DatePicker
                          style={{height:'40px'}}
                          placeholder="시작일"
                          value={formData.cert_start_dt ? dayjs(formData.cert_start_dt) : null}
                          onChange={(date, dateString) =>
                              setFormData((prevFormData) => {
                                const newFormData = {
                                  ...prevFormData,
                                  cert_start_dt: dateString,
                                };

                                if (prevFormData.cert_end_dt &&
                                    dayjs(dateString).isAfter(dayjs(prevFormData.cert_end_dt))) {
                                  newFormData.cert_end_dt = dateString;
                                }
                                return newFormData;
                              })
                          }
                          format="YYYY-MM-DD"
                          allowClear={false}
                      />
                      <DatePicker
                          style={{height:'40px'}}
                          placeholder="종료일"
                          value={formData.cert_end_dt ? dayjs(formData.cert_end_dt) : null}
                          onChange={(date, dateString) =>
                              setFormData({
                                ...formData,
                                cert_end_dt: dateString,
                              })
                          }
                          disabledDate={(current) => {
                            return current && current < (formData.cert_start_dt ? dayjs(formData.cert_start_dt) : null);
                          }}
                          format="YYYY-MM-DD"
                          allowClear={false}
                      />
                    </div>
                  </li>
                  <li>
                    <p>본인 프로필 링크</p>
                    <Input
                      placeholder="Ex) https://suno.com/@master"
                      value={formData.confirm_url}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          confirm_url: e.target.value,
                        })
                      }
                    />
                  </li>
                </ul>
              </div>
              <div className="btnBox">
                <button className="btn-reset" type="button" onClick={resetContents}>
                  초기화
                </button>
                <button className="btn-pink" type="button" onClick={() => updateAiCert('s')} style={{ display: isSaveBtnDisabled }}>
                  등록
                </button>
              </div>
            </section>
            {/* 그리드 영역 */}
            <section className="modalAi__girdArea" style={{marginBottom: width < 700 ? '80px' : ''}}>
              <div className="searchBox">
                <DatePicker
                    style={{ height: '40px' }}
                    placeholder="시작일"
                    value={sendDate?.st_date ? dayjs(sendDate.st_date) : oneWeekAgo}
                    onChange={(date, dateString) => {
                      const adjustedEndDate = sendDate?.end_date &&
                      dayjs(dateString).isAfter(dayjs(sendDate.end_date))
                          ? dateString
                          : sendDate?.end_date || '';

                      handleDate({
                        startDt: dateString,
                        endDt: adjustedEndDate,
                      });
                    }}
                    format="YYYY-MM-DD"
                    allowClear={false}
                />
                <span>~</span>
                <DatePicker
                    style={{ height: '40px' }}
                    placeholder="종료일"
                    value={sendDate?.end_date ? dayjs(sendDate.end_date) : today}
                    onChange={(date, dateString) => {
                      const adjustedStartDate = sendDate?.st_date &&
                      dayjs(dateString).isBefore(dayjs(sendDate.st_date))
                          ? dateString
                          : sendDate?.st_date || '';

                      handleDate({
                        startDt: adjustedStartDate,
                        endDt: dateString,
                      });
                    }}
                    format="YYYY-MM-DD"
                    allowClear={false}
                />
                <button className="btn-searchIcon" type="button" onClick={() => getAiCertList(aiCertListState.query)} />
                <div className="select">
                  <Select options={aiCertSort} value={form.getFieldValue('selector_3')} onChange={(value) => handleSearchAiCert(value)} />
                </div>
              </div>
              <Loading isLoading={isLoading} />
              <Table ref={aiCertGridRef} columnLayout={aiCertColumnLayout} customGridProps={aiCertGridProps} />
              </section>
            </div>
        </div>
      </div>
      <PhoneAuthModal visible={isPhoneAuthModalVisible} onClose={() => setIsPhoneAuthModalVisible(false)} onAuthenticate={handlePhoneAuthentication} initialPhoneNumber={phoneNumber.current} />
    </Form>
  );
};
export default AiModelCertModal;
