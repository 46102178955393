import React, { useRef, useState, useEffect } from 'react';
import { Form, Modal, Input, Select, message } from 'antd';
import Draggable from 'react-draggable';
import { makeSelectBoxList } from '@utils/lib';
import * as api from '@api/index';

const LeaveReason = ({ isOpen, onClose, leavedUser }) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const [leaveRsnCdList, setLeaveRsnCdList] = useState([]);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  // 공통코드 자진탈퇴/강퇴 사유코드 조회
  const makeLeaveRsnCdFormat = async () => {
    try {
      const grpCd = leavedUser?.mem_stat === '91' ? 'WTR_RSN' : 'CNL_RSN';
      const response = await api.fetchCommonDetailCode({ grp_cd: grpCd });
      const madeSelectBoxList = makeSelectBoxList(response?.data, 'cd_nm', 'cd');
      setLeaveRsnCdList(madeSelectBoxList);
    } catch (error) {
      message.warning(error.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      // 공통코드 조회
      makeLeaveRsnCdFormat();

      form.setFieldsValue({
        ...(leavedUser?.mem_stat === '91' && {
          rsn_cd: leavedUser?.self_cnl_rsn_cd,
          rsn: leavedUser?.self_cnl_rsn,
        }),
        ...(leavedUser?.mem_stat === '99' && {
          rsn_cd: leavedUser?.cnl_rsn_cd,
          rsn: leavedUser?.cnl_rsn,
        }),
      });
    }
  }, [isOpen]);

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          탈퇴 사유
        </div>
      }
      centered={true}
      className="leaveReason"
      open={isOpen}
      closable={false}
      onOk={onClose}
      maskClosable={false}
      footer={
        <div className="flexRowCenter">
          <button id="pinkBtn" onClick={onClose}>
            닫기
          </button>
        </div>
      }
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Form form={form} name="leaveReason" layout="vertical" style={{ maxWidth: 500, margin: '30px auto 40px' }} autoComplete="off" scrollToFirstError>
        <Form.Item name="rsn_cd" label="탈퇴 사유 코드">
          <Select options={leaveRsnCdList} disabled />
        </Form.Item>
        <Form.Item name="rsn" label="탈퇴 사유 내용">
          <Input.TextArea autoSize disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LeaveReason;
