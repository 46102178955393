import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Form, Input, Button, message, List } from 'antd';
import { Comment } from '@ant-design/compatible';
import { MoreOutlined } from '@ant-design/icons';
import * as api from '@api/index';
import { formatEncodingDate, getLocalStorage, formatEncodingBoardDate } from '@utils/lib';
import '/src/assets/css/comment.css';
import * as PATH from '@routes/pathName';
import {useNavigate} from "react-router-dom";

const { TextArea } = Input;
const CommentItem = ({ comment, onReply, onDelete, onEdit, level = 1 }) => {
  const [replyVisible, setReplyVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [editContent, setEditContent] = useState(comment.reply_contents);
  const [moreVisible, setMoreVisible] = useState(false);
  const commentRef = useRef(null);
  const memKey = getLocalStorage('prptbk-token', 'mem_key');
  const memAuth = getLocalStorage('prptbk-token', 'mem_auth');
  const navigate = useNavigate();
  const commentPopRef = useRef(null);

  // 모달 외부를 클릭 했을 때 모달을 닫는 기능
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (commentPopRef.current && !commentPopRef.current.contains(event.target)) {
        setMoreVisible(false); // 외부 클릭시 모달 닫기
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleReply = () => {
    onReply(replyContent, null, comment.reply_seq);
    setReplyContent('');
    setReplyVisible(!replyContent);
  };

  const handleEdit = () => {
    onEdit(editContent, comment.reply_seq);
    setEditVisible(false);
  };

  const reCommentCard = {
    height: 'auto',
    backgroundColor: '#F9F9F9',
  };

  useEffect(() => {
    document.querySelectorAll('.anticon').forEach((icon) => {
      icon.removeAttribute('aria-label');
    });

    const elements = document.querySelectorAll(
      'section > div > div > div.ant-list.ant-list-split.box.css-dev-only-do-not-override-14i19y2 > div > div > ul > div > div > div.ant-comment-nested > div > div > div *',
    );
    elements.forEach((el) => {
      el.style.backgroundColor = 'rgba(0, 0, 0, 0) !important';
    });
  }, []);

  const handleProfileClick = (param) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_key: param,
      },
    });
  };

  return (
    <div className="repleBox" ref={commentRef}>
      <Comment
        style={level !== 1 ? reCommentCard : null}
        avatar={level === 1 ? <Avatar src={comment.writer_profile || 'default-avatar.png'} style={{cursor:comment.link_yn === 'N' && null }} onClick={()=>{comment.link_yn === 'N' ? null : handleProfileClick(comment.fir_id)}}/> : null}
        author={
          <div className="box">
            <div className="info">
              <span className="comment-name" style={{cursor:comment.link_yn === 'N' ? null : "pointer"}} onClick={()=>{comment.link_yn === 'N' ? null : handleProfileClick(comment.fir_id)}}>@{comment.writer_nm}</span>

              <span className="comment-date">
                {formatEncodingBoardDate(comment.lst_dt)} {comment.parent_writer_nm && <span className="fromTo">@{comment.parent_writer_nm} 님에게</span>}
              </span>
            </div>
            <div className="btnArea">
              <div className="answer btn-lineR" onClick={() => setReplyVisible(!replyVisible)}>
                답글
              </div>
              {(memAuth === 'R002' || memKey === parseInt(comment.fir_id, 10)) && (
                <>
                  <button className="i-more" onClick={() => setMoreVisible(!moreVisible)} aria-label=""></button>
                </>
              )}
              {moreVisible && (
                <div className="more-select" ref={commentPopRef}>
                  <ul>
                    <li>
                      <span
                        onClick={() => {
                          setEditVisible(true);
                          setMoreVisible(false);
                        }}
                      >
                        수정
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => {
                          onDelete(comment.reply_seq);
                          setMoreVisible(false);
                        }}
                      >
                        삭제
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        }
        content={
          editVisible ? (
            <div className="rereWrite">
              <Input.TextArea rows={1} onChange={(e) => setEditContent(e.target.value)} value={editContent} placeholder="답글을 수정해주세요." rootClassName="comment-input" />
              <Button className="comment-btn btn-gray2" htmlType="submit" onClick={handleEdit}>
                저장
              </Button>
            </div>
          ) : (
            <p>{comment.reply_contents}</p>
          )
        }
      >
        {replyVisible && (
          <div className="repleWrite">
            <Input.TextArea rootClassName="comment-input" rows={1} onChange={(e) => setReplyContent(e.target.value)} value={replyContent} placeholder="답글을 남겨주세요." />
            <Button className="comment-btn btn-gray2" htmlType="submit" onClick={handleReply}>
              저장
            </Button>
          </div>
        )}
        {comment.children && comment.children.length > 0 && (
          <div>
            {comment.children.map((reply) => (
              <CommentItem key={reply.reply_seq} comment={reply} onReply={onReply} onDelete={onDelete} onEdit={onEdit} level={level + 1} />
            ))}
          </div>
        )}
      </Comment>
    </div>
  );
};

const CommentSystem = ({ board_no, doc_no }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  const buildCommentTree = (data) => {
    const sortedData = [...data].sort((a, b) => b.reply_seq - a.reply_seq);
    const commentMap = new Map();
    sortedData.forEach((comment) => {
      commentMap.set(comment.reply_seq, {
        ...comment,
        children: [],
      });
    });

    const rootComments = [];
    sortedData.forEach((comment) => {
      const commentWithChildren = commentMap.get(comment.reply_seq);

      if (!comment.parent_reply_seq || comment.parent_reply_seq === 0) {
        rootComments.push(commentWithChildren);
      } else {
        const parentComment = commentMap.get(comment.parent_reply_seq);
        if (parentComment) {
          parentComment.children.push(commentWithChildren);
        }
      }
    });

    rootComments.forEach((comment) => {
      if (comment.children.length > 0) {
        comment.children.sort((a, b) => b.reply_seq - a.reply_seq);
      }
    });

    return rootComments;
  };

  const getComments = async () => {
    try {
      const params = {
        board_no,
        doc_no,
      };
      const { data } = await api.commentList({ params });

      if (data) {
        const commentTree = buildCommentTree(data);
        setComments(commentTree);
      }

      console.log(data)
    } catch (error) {
      message.error('댓글을 불러오는데 실패했습니다.');
    }
  };

  const updateComment = async (content, existingReplySeq = null, parentReplySeq = null) => {
    try {
      if (!content) return message.error("댓글 내용은 필수 값 입니다.");
      const newComment = {
        board_no,
        doc_no,
        reply_seq: existingReplySeq || null,
        parent_reply_seq: parentReplySeq,
        reply_contents: content,
        del_yn: 'N',
      };
      await api.updatedComment(newComment);
      message.success(existingReplySeq ? '댓글이 수정되었습니다.' : '댓글이 추가되었습니다.');
      setNewComment('');
      getComments();
    } catch (error) {
      message.error('댓글 처리에 실패했습니다.');
    }
  };

  const deleteComment = async (existingReplySeq = null) => {
    try {
      const newComment = {
        board_no,
        doc_no,
        reply_seq: existingReplySeq,
        del_yn: 'Y',
      };
      await api.updatedComment(newComment);
      message.success('댓글이 삭제되었습니다.');
      getComments();
    } catch (error) {
      message.error('댓글 삭제에 실패했습니다.');
    }
  };

  const countComments = (comments) => {
    let count = comments.length;

    comments.forEach((comment) => {
      if (comment.children && comment.children.length > 0) {
        count += countComments(comment.children);
      }
    });

    return count;
  };

  const totalCommentsCount = countComments(comments);

  useEffect(() => {
    getComments();
  }, [board_no, doc_no]);

  return (
    <div className="comment-system">
      <h2>댓글 남기기</h2>
      <Comment
        content={
          <>
            <Form.Item>
              <div className="write">
                <h3>댓글을 남겨주세요</h3>

                <div className="repleWriteArea">
                  <TextArea rows={1} onChange={(e) => setNewComment(e.target.value)} value={newComment} placeholder="게시글에 대한 댓글을 남겨주세요." />

                  <Button className="btn-gray2" htmlType="submit" onClick={() => updateComment(newComment)}>
                    {' '}
                    저장
                  </Button>
                </div>
              </div>
            </Form.Item>
          </>
        }
      />
      {comments.length > 0 && (
        <List
          className="box"
          dataSource={comments}
          /*  header={`${totalCommentsCount} ${totalCommentsCount > 1 ? 'replies' : 'reply'}`} */
          itemLayout="horizontal"
          renderItem={(comment) => <CommentItem comment={comment} onReply={updateComment} onDelete={deleteComment} onEdit={updateComment} level={1} />}
        />
      )}
    </div>
  );
};

export default CommentSystem;
