import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Layout, Row, Select } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Table from '@components/Table';
import * as api from '@api/index';
import { isEmpty } from '@utils/lib';
import Loading from '@components/Loading';
import GridHeader from '@components/GridHeader';
import MyTextareaEditor from '@static/AUIGrid/renderers/MyTextareaEditor';
import { getParamInfo, saveParamInfo } from '@api/index';

const AdminParameterHome = (props) => {
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);

  const [searchForm] = Form.useForm();
  const paramGridRef = useRef();

  /* 공통코드 */
  const aiModelList = useRef(); // AI 모델 목록

  const langList = [
    { label: '국문', value: 'ko' },
    { label: '영문', value: 'en' },
  ];
  const inTypeList = [
    { label: '입력형', value: 'input' },
    { label: '선택형', value: 'select' },
    { label: 'URL', value: 'url' },
  ];

  // 파라미터 관리 그리드 props
  const customGridProps = {
    fillColumnSizeMode: true, // 컬럼 사이즈 비율 계산 유무
    wordWrap: true,
    height: 700,
  };

  // 파라미터 관리 그리드 레이아웃
  const columnLayout = [
    { dataField: 'param_id', headerText: '파라미터 ID', width: '30%', visible: false },
    {
      dataField: 'ai_model_cd',
      headerText: 'AI모델',
      width: '50%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'cd',
        valueField: 'cd_nm',
        listFunction: () => {
          return aiModelList.current;
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'ko_nm',
      headerText: '파라미터명 국문',
      width: '50%',
      headerStyle: 'required-custom-header',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'en_nm',
      headerText: '파라미터명 영문',
      width: '50%',
      headerStyle: 'required-custom-header',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'in_tp',
      headerText: '입력 타입',
      width: '30%',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'value',
        valueField: 'label',
        list: inTypeList,
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    { dataField: 'in_txt', headerText: '프롬프트 표기', width: '80%' },
    {
      headerText: '입력',
      children: [
        {
          dataField: 'in_min',
          headerText: '최소',
          width: '30%',
          dataType: 'numeric',
          editRenderer: {
            type: 'InputEditRenderer',
            onlyNumeric: true, // 0~9만 입력가능
          },
          filter: {
            showIcon: true,
            displayFormatValues: true,
            type: 'numeric',
          },
        },
        {
          dataField: 'in_max',
          headerText: '최대',
          width: '30%',
          dataType: 'numeric',
          editRenderer: {
            type: 'InputEditRenderer',
            onlyNumeric: true, // 0~9만 입력가능
          },
          filter: {
            showIcon: true,
            displayFormatValues: true,
            type: 'numeric',
          },
        },
      ],
    },
    { dataField: 'in_tip', headerText: '툴팁', width: '60%' },
    {
      dataField: 'desc',
      headerText: '설명',
      width: '130%',
      style: 'my-wrap-column left-custom-column',
      editRenderer: {
        type: 'CustomEditRenderer',
        jsClass: MyTextareaEditor,
        vPosition: 'top',
        fitWidth: true,
        extraProps: {
          confirm: '확 인',
          cancel: '취 소',
        },
      },
    },
    {
      dataField: 'use_yn',
      headerText: '사용',
      width: '20%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N'],
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  // 그리드 행 추가
  const handleAddRow = () => {
    paramGridRef.current.addRow({});
  };

  // 파라미터 목록 조회
  const getParamInfo = async (searchData) => {
    const params = {
      ai_model_cd: searchData?.ai_model_cd,
      search_lang: searchData?.search_lang,
      search_text: searchData?.search_text,
    };

    if (params.search_lang && !params.search_text) {
      // 언어만 선택 시
      alert('검색어를 입력해주세요.');
      return;
    } else if (!params.search_lang && params.search_text) {
      // 검색어만 입력 시
      alert('언어를 선택해주세요.');
      return;
    }
    try {
      setLoading((prev) => ({ ...prev, paramLoad: true }));

      const response = await api.getParamInfo(params);
      paramGridRef.current.setGridData(response.data);
    } catch (e) {
      console.log('조회 error', e);
    } finally {
      setLoading((prev) => ({ ...prev, paramLoad: false }));
    }
  };

  // 파라미터 목록 저장
  const saveParamInfo = async () => {
    if (!(await window.confirm('저장하시겠습니까?'))) return false;
    const saveItems = paramGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);

    // validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }
    const isValid = paramGridRef.current.validateChangedGridData(['ai_model_cd', 'ko_nm', 'en_nm', 'use_yn'], '필수 필드는 반드시 값을 직접 입력해야 합니다.');
    if (!isValid) return;

    try {
      setLoading((prev) => ({ ...prev, paramSaveLoad: true }));
      const response = await api.saveParamInfo(saveItems);
      const responseStat = response.data.returnStatus; // 응답결과상태

      alert(response.data.returnMessage, responseStat == 'fail' ? 'error' : 'success');
      if (responseStat === 'success') {
        // 파라미터 목록 조회
        searchForm.submit();
      }
    } catch (e) {
      console.log('저장 error', e);
    } finally {
      setLoading((prev) => ({ ...prev, paramSaveLoad: false }));
    }
  };

  // 공통코드 조회
  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));

      // AI모델
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      aiModelList.current = response1.data;
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));
      getParamInfo(); // 파라미터 조회
    }
  };

  useEffect(() => {
    getCommonCode(); // 공통코드 조회
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout>
        <Content>
          <GridHeader className="l-layout">
            {{
              searchArea: (
                <Form className="l-flex gap" form={searchForm} layout="inline" onFinish={getParamInfo}>
                  <Form.Item name="ai_model_cd">
                    <Select options={aiModelList.current} fieldNames={{ label: 'cd_nm', value: 'cd' }} allowClear placeholder="AI모델" style={{ width: '150px' }} />
                  </Form.Item>
                  <Form.Item name="search_lang">
                    <Select options={langList} allowClear placeholder="언어" style={{ width: '80px' }} />
                  </Form.Item>
                  <Form.Item name="search_text">
                    <Input placeholder="검색어를 입력해 주세요" />
                  </Form.Item>
                  <Button className="btn-searchIcon" type="primary" htmlType="submit">
                    검색
                  </Button>
                </Form>
              ),
              buttonArea: (
                <>
                  <Button className="btn-add" onClick={handleAddRow}>
                    추가
                  </Button>
                  <Button className="btn-pink" type="primary" onClick={saveParamInfo}>
                    저장
                  </Button>
                </>
              ),
            }}
          </GridHeader>

          <Table ref={paramGridRef} columnLayout={columnLayout} customGridProps={customGridProps} />
        </Content>
      </Layout>
    </>
  );
};

export default AdminParameterHome;
