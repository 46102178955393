import React, { useEffect, useState } from 'react';
import { Tree, Layout, Switch, Input, Select, Space, Tooltip, Button } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import useWidth from '@hooks/useWidth';
import lockIcon from '../../assets/images/common/lock_icon.png';
import starIcon from '../../assets/images/common/star_icon.png';
import btnExclaIcon from '../../assets/images/common/btn-excla-icon.svg';

import { useNavigate } from 'react-router-dom';
import * as PATH from '@routes/pathName';
import { openDictionary, closeChildPopup } from '@utils/lib';

const { DirectoryTree } = Tree;
const { Content, Sider } = Layout;

const Step = (props) => {
  const auth = useSelector((s) => s.auth, shallowEqual);
  const navigate = useNavigate();

  const width = useWidth();

  const [inputValue, setInputValue] = useState(null);

  const [treeData, setTreeData] = useState([]);
  const [childNodes, setChildNodes] = useState([]); // 클릭한 노드의 자식들을 저장할 상태
  const [isExpandChecked, setIsExpandChecked] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [promptSeq, setPromptSeq] = useState();
  const {stepType, selectedBuilderInfo, stepUseLv, stepUseLvNm, langType} = props;

  const [selectedOption, setSelectedOption] = useState();
  const [selectedGroupImage, setSelectedGroupImage] = useState(starIcon);

  const [groupInfo, setGroupInfo] = useState({});

  const [selectedLibraryValue, setSelectedLibraryValue] = useState(null);

  const handleLibraryChange = (value) => {
    setSelectedLibraryValue(value);
  };
  const moveToPageWithActiveKey = (path) => {
    navigate(`${path}`, { state: { mem_email: auth.mem_email, mem_key: auth.mem_key, planInfo: 'true' } });
  };

  // 트리 데이터 생성 시 el_div가 '01'인 노드는 제외하고 부모 노드까지만 포함 (새로운 객체를 생성)
  const processTreeData = (node) => {
    // el_div가 '01'인 노드는 트리에 포함하지 않음
    if (node.el_div === '01') {
      return null;
    }

    // 새로운 객체 생성
    const newNode = {
      ...node,
      title: node.desc1 ? (
        <Tooltip title={node.desc1} placement="right">
          {node.ko_nm}
        </Tooltip>
      ) : (
        node.ko_nm
      ),
      key: node.parent_seq + '-' + node.el_id,
    };

    // 자식이 있는 경우만 트리에 포함되도록 필터링
    if (node.children && node.children.length > 0) {
      const filteredChildren = node.children
        .map((childNode) => processTreeData(childNode)) // 재귀적으로 자식 처리
        .filter((child) => child !== null); // el_div가 '01'인 노드는 필터링

      newNode.children = filteredChildren;
      newNode.isLeaf = filteredChildren.length === 0; // 자식이 없으면 리프 노드로 설정
    } else {
      newNode.isLeaf = true;
      newNode.children = [];
    }

    return newNode;
  };

  const collectAllKeys = (nodes) => {
    let keys = [];
    for (let node of nodes) {
      keys.push(node.key);
      if (node.children && node.children.length > 0) {
        keys = keys.concat(collectAllKeys(node.children));
      }
    }
    return keys;
  };

  // Switch의 상태에 따라 전체 트리를 확장하거나 축소
  const handleSwitchChange = (checked) => {
    if (checked) {
      const allKeys = collectAllKeys(treeData);
      setExpandedKeys(allKeys); // 전체 확장
      setIsExpandChecked(true);
    } else {
      setExpandedKeys([]); // 전체 축소
      setIsExpandChecked(false);
    }
  };

  useEffect(() => {
    setChildNodes([]);
    if (props.stepData) {
      const updatedStepData = (stepType === 'library' ? props.stepData[0].children : props.stepData).map((node) => processTreeData(node)).filter((node) => node !== null);
      setTreeData(updatedStepData);

      setExpandedKeys([]);
      setIsExpandChecked(false);
      setSelectedGroupImage(starIcon)
    }
  }, [props.stepData, selectedBuilderInfo]);

  // 특정 seq 값을 parent_seq로 가지고 있고, el_div가 '01'인 자식 객체 배열을 찾는 함수
  const findChildrenByParentSeqAndElDiv = (parentSeq, nodes) => {
    let result = [];

    for (let node of nodes) {
      // 자식 노드가 있는 경우, 먼저 재귀적으로 탐색
      if (node.children && node.children.length > 0) {
        result = result.concat(findChildrenByParentSeqAndElDiv(parentSeq, node.children));
      }

      // 노드의 parent_seq가 전달된 parentSeq와 일치하고, el_div가 '01'인 경우 결과에 추가
      if (node.parent_seq === parentSeq && node.el_div === '01') {
        result.push(node);
      }
    }

    return result;
  };

  const findTopLevelParent = (data, targetElId) => {
    for (const item of data) {
      // 현재 객체나 자식들 중에서 찾는다면 해당 최상위 객체 반환
      if (findInChildren(item, targetElId)) {
        return item;
      }
    }
    return null; // 찾는 데이터가 없으면 null 반환
  };

  const findInChildren = (node, targetElId) => {
    // 현재 객체가 targetElId를 가진다면 true 반환
    if (node.el_id === targetElId) {
      return true;
    }
    // children 배열을 순회하며 재귀적으로 탐색
    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        if (findInChildren(child, targetElId)) {
          return true;
        }
      }
    }
    return false;
  };

  // 트리에서 노드를 클릭했을 때 처리하는 함수
  const handleSelect = (selectedKey, { node }) => {
    setSelectedGroupImage(node.img_phy_path ? node.img_phy_path : starIcon);
    const rootNode = findTopLevelParent(treeData, node.el_id);

    const selectedItem = stepType === 'library' ? props.libraryOptionList[0] : props.optionList.find((item) => item.el_id === rootNode.el_id);

    setGroupInfo({
      groupKey: props.groupKey,
      groupSeq: treeData.findIndex((item) => item.el_id === rootNode.el_id),
      prefix: selectedItem?.prefix,
      suffix: selectedItem?.suffix,
      pre_sep: selectedItem?.pre_sep,
      suf_sep: selectedItem?.suf_sep,
      and_yn: selectedItem?.and_yn,
      loop_yn: selectedItem?.loop_yn,
    });
    setInputValue(null);
    setPromptSeq(rootNode.prpt_seq);
    setSelectedOption(rootNode);
    const children = findChildrenByParentSeqAndElDiv(node.seq, props.stepData);
    if (children.length > 0) {
      setChildNodes(children.map((item) => ({ ...item, clickYn: false })));
    } else {
      setChildNodes([]); // 자식이 없으면 빈 배열로 설정
    }
  };

  const handleClick = (item, index) => {
    setChildNodes((prevNodes) => prevNodes.map((node, i) => (i === index ? { ...node, clickYn: !node.clickYn } : node)));

    props.handleClickElements({
      ...groupInfo,
      el_id: item.el_id,
      ko_nm: item.ko_nm,
      en_nm: item.en_nm,
      promptSeq: stepType === 'library' ? selectedLibraryValue : promptSeq,
      parent_el_id: item.parent_el_id,
      type: stepType === 'library' ? 'library' : 'element',
      img_phy_path: item.img_phy_path,
    });
  };

  useEffect(() => {
    if(props.promptList){
      setSelectedLibraryValue(props.promptList[0].prpt_seq)
    }
  }, [props.promptList])

  const makeStepContent = () => {
    return (
      <>
        <Sider
          width={width < 700 ? `100%` : 200}
          style={{
            background: '#ffffff',
            height: `auto`,
          }}
        >
          <div className="btnArea">
            <Switch checked={isExpandChecked} style={{ marginBottom: '12px' }} checkedChildren="펼치기" unCheckedChildren="접기" onChange={handleSwitchChange} />
          </div>
          <DirectoryTree
            multiple
            treeData={treeData} // 트리 데이터
            onSelect={handleSelect} // 노드를 클릭할 때 동작
            expandedKeys={expandedKeys}
            onExpand={(keys) => setExpandedKeys(keys)}
            expandAction="doubleClick"
          />
        </Sider>

        <Layout
          style={{
            background: '#ffffff',
          }}
        >
          <Content>
            <div className="top flexRowBetween">
              <p>프롬프트</p>
              <div className="line"></div>
              <Button style={{ marinBottom: '15%' }} onClick={() => openDictionary(langType, null)}>
                프롬프트 사전
              </Button>
            </div>

            {stepType === 'library' ? (
              <>
                <Space style={{ display: 'flex', justifyContent: 'right' }}>
                  <span>라이브러리 입력창 선택</span>
                  <Select
                    className="w200"
                    options={props.promptList.map((item) => ({
                      value: item.prpt_seq,
                      label: item.ko_nm,
                    }))}
                    onChange={handleLibraryChange}
                    value={selectedLibraryValue}
                  />
                </Space>
              </>
            ) : null}

            <div className="flexRowStart listBox">
              {childNodes.length > 0 ? (
                <>
                  {childNodes.map((item, index) => (
                    <div
                      className="list "
                      id={item.clickYn && groupInfo.loop_yn === 'N' ? 'active' : ''}
                      key={index}
                      onClick={() => {
                        handleClick(item, index);
                      }}
                    >
                      {item.desc1 ? (
                        <div
                          className="btn-guide"
                          onClick={(event) => {
                            event.stopPropagation();
                            openDictionary(langType, item.el_id);
                          }}
                          style={{
                            backgroundImage: `url(${btnExclaIcon})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                          }}
                        />
                      ) : null}
                      <div className="thumBorder" style={{ display: item.clickYn && groupInfo.loop_yn === 'N' ? 'block' : 'none' }}></div>

                      {item.img_phy_path ? (
                        <>
                          <div
                            className="imgThum"
                            src={item.img_phy_path}
                            style={{
                              backgroundImage: `url("${item.img_phy_path.replace(/\\/g, '/')}")`,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                            }}
                          />
                        </>
                      ) : (
                        <div
                          style={{
                            width: '50px', // 이미지와 동일한 크기
                            height: '50px', // 이미지와 동일한 크기
                            backgroundColor: '#fff', // 하얀색 박스
                            border: '1px solid #ddd', // 선택적으로 테두리를 추가
                            borderRadius: '4px', // 선택적으로 모서리를 둥글게
                          }}
                        />
                      )}

                      <div className="absoluteBox flexColStart">
                        <span>{item.ko_nm}</span>
                      </div>
                    </div>
                  ))}
                  {stepUseLv && auth.plan_cd >= stepUseLv ? (
                    <div className="flexRowBetween promptInputBox">
                      <Input
                        className="input"
                        placeholder="프롬프트 직접입력"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onPressEnter={() => {
                          props.handleSubmitPromptText({
                            ...groupInfo,
                            groupSeq: 9999,
                            value: inputValue,
                            promptSeq: promptSeq,
                            type: 'step',
                            parentElId: selectedOption.el_id,
                          });
                          setInputValue(null);
                        }}
                      />
                      <button
                        id="darkGrey3"
                        onClick={() => {
                          props.handleSubmitPromptText({
                            ...groupInfo,
                            groupSeq: 9999,
                            value: inputValue,
                            promptSeq: promptSeq,
                            type: 'step',
                            parentElId: selectedOption.el_id,
                          });
                          setInputValue(null);
                        }}
                      >
                        <PlusOutlined />
                        &nbsp;입력
                      </button>
                    </div>
                  ) : null}
                </>
              ) : (
                // <p className="noResult">
                  <div
                    className="imgThum"
                    style={{
                      backgroundImage: `url("${selectedGroupImage?.replace(/\\/g, '/')}")`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  />
                // </p>
              )}
            </div>
          </Content>
        </Layout>
      </>
    );
  };

  useEffect(() => {
    setInputValue(null);
  }, [props.builderId]);

  useEffect(() => {
    window.addEventListener('unload', closeChildPopup);
  }, []);

  return (
    <>
      <Content
        style={{
          background: '#ffffff',
          width: '100%',
          height: '200px',
          overflow: 'hidden',
        }}
      >
        {props.stepImgPath ? (
          <>
            <div
              className="imgThum"
              src={props.stepImgPath}
              style={{
                backgroundImage: `url('${props.stepImgPath.replace(/\\/g, '/')}')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%', // 이미지와 동일한 크기
                height: '100%', // 이미지와 동일한 크기
              }}
            />
          </>
        ) : (
          <div
            className="imgThum"
            style={{
              width: '100%', // 이미지와 동일한 크기
              height: '100%', // 이미지와 동일한 크기
              borderRadius: '10px', // 선택적으로 모서리를 둥글게
            }}
          />
        )}
      </Content>
      <Layout
        style={{
          padding: width < 700 ? `0 0 24px` : `0 20px 24px`,
          background: '#ffffff',
        }}
      >
        {stepType === 'library' ? (
          makeStepContent()
        ) : stepUseLv && auth.plan_cd >= stepUseLv ? (
          makeStepContent()
        ) : (
          <div className="proMembership flexColCenter">
            <h1>
              해당 프롬프트는
              <br />
              <span>{stepUseLvNm && stepUseLvNm ? stepUseLvNm : null}</span> 멤버십일 경우
              <br />
              사용 가능합니다.
            </h1>

            <button className="btnBox flexColCenter" onClick={() => moveToPageWithActiveKey(PATH.PROFILE_PAGE)}>
              <div className="flexRowBetween">
                <div className="lock flexColCenter">
                  <img src={lockIcon} alt="" />
                </div>
                <div className="flexRowCenter">
                  <img src={starIcon} alt="" />
                  <p>
                    업그레이드
                    <br />
                    잠금해제
                  </p>
                </div>
              </div>
            </button>
          </div>
        )}
      </Layout>
    </>
  );
};

export default Step;
