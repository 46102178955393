import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Checkbox, Select, message, Result } from 'antd';
import ReactPlayer from 'react-player';
// lib
import { useDebounce } from '@utils/lib';
// api
import * as api from '@api/index';
// component
import Loading from '@components/Loading';

const PlayerVideo = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const prpt_id = JSON.parse(decodeURI(atob(urlParams.get('prpt_id'))));
  const [selectedVideoItem, setSelectedVideoItem] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const auth = useSelector((s) => s.auth, shallowEqual);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 프롬프트 상세정보 가져오기
  const selectVideo = useCallback(async () => {
    try {
      if (!auth?.mem_key) return;
      const { data } = await api.getSalesPrompt({
        params: { prpt_id: prpt_id },
      });
      if (!data) return;
      setSelectedVideoItem(data);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    handleDebounce(() => selectVideo());
  }, []);

  return (
    <div id="musicBox" className="flexColCenter">
      <div className="playBox flexColCenter" style={{ margin: 0 }}>
        {isLoading ? (
          <Loading isLoading={isLoading} />
        ) : selectedVideoItem?.music_org_link ? (
          <ReactPlayer url={selectedVideoItem?.music_org_link} />
        ) : (
          <Result status="warning" title="파일을 찾을 수 없습니다." />
        )}
      </div>
    </div>
  );
};

export default PlayerVideo;
