import React from "react";
import { Modal, Descriptions } from "antd";

const RefundModal = ({ visible, onClose, refundData }) => {
    if (!refundData) return null;
    return (
        <Modal
            title="환불 상세 정보"
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <Descriptions bordered column={1} size="middle">
                <Descriptions.Item label="상품명">{refundData.gds_nm}</Descriptions.Item>
                <Descriptions.Item label="환불 상태">{refundData.refund_stat_txt}</Descriptions.Item>
                <Descriptions.Item label="환불 사유">{refundData.refund_rsn_txt}</Descriptions.Item>
                <Descriptions.Item label="환불 요청일">{refundData.refund_req_dt}</Descriptions.Item>
                <Descriptions.Item label="환불일">{refundData.refund_pay_dt}</Descriptions.Item>
                <Descriptions.Item label="환불 금액">{refundData.refund_amt} 원</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default RefundModal;