import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Input, Button, Spin, message, Upload, Checkbox, Drawer, Rate, Modal, Tooltip } from 'antd';
import { MoreOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { setChatRooms, setCurrentRoom, addMessage, setMessages, clearMessages, setUserProfile } from '@stores/chat.js';
import * as api from '@api/index';
import { formatMessageDate, groupMessagesByDate, getLocalStorage, formatDate, formatBadgeIcon, truncateMessage, formatAlramDate, handleLinkClick, handleImageError } from '@utils/lib';
import FollowModal from '@components/FollowModal';

import { IMAGES } from '@utils/image';
import { badge_map } from '@utils/constants';
import { LoadingContainer, EmptyMessageContainer, DateDivider, DateLabel } from '@pages/chat/chatComponent';
import { triggerIconRefresh } from '@stores/icon';
import * as PATH from '@routes/pathName';
import { useNavigate } from 'react-router-dom';

const ChatBase = ({ tabId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(tabId);
  const chatRooms = useSelector((state) => state.chat.chatRooms) || [];
  const currentRoom = useSelector((state) => state.chat.currentRoom);
  const messages = useSelector((state) => {
    if (!currentRoom?.id) return [];
    const roomMessages = state.chat.messages[currentRoom.id];
    return roomMessages || [];
  }, shallowEqual);
  const userProfile = useSelector((state) => state.chat.userProfile) || [];
  const auth = useSelector((s) => s.auth, shallowEqual);

  const navigate = useNavigate();

  const [newMessage, setNewMessage] = useState('');
  const messageEndRef = useRef(null);
  const messageListRef = useRef(null);
  const prevMessagesLengthRef = useRef(0);

  const [selectedRoomForOptions, setSelectedRoomForOptions] = useState(null);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const selectedAlertsRef = useRef([]);
  const [selectedAlerts, setSelectedAlerts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [roomListLoading, setRoomListLoading] = useState(false);
  const [previousRooms, setPreviousRooms] = useState([]);
  const [previousRoom, setPreviousRoom] = useState(null);

  const [sendingMessage, setSendingMessage] = useState(false);

  const [isMessageVisable, setIsMessageVisable] = useState(false);
  const [mobileProfile, setMobileProfile] = useState(false);

  const [isRoomOptionsOpen, setIsRoomOptionsOpen] = useState(false); // More option 창

  const currentUserKey = getLocalStorage('prptbk-token', 'mem_key')?.toString();
  const [selectedAis, setSelectedAis] = useState([]);
  const wrapperRef = useRef(null);

  const [badgeList, setBadgeList] = useState([]);

  const scrollToBottom = useCallback((smooth = false) => {
    if (messageListRef.current) {
      const scrollOptions = {
        top: messageListRef.current.scrollHeight,
        behavior: smooth ? 'smooth' : 'auto',
      };
      messageListRef.current.scrollTo(scrollOptions);
    }
  }, []);

  // 채팅방 목록 조회
  const fetchRooms = useCallback(async () => {
    try {
      setRoomListLoading(true);
      const msgDiv = activeTab === 'normal' ? 'user' : 'system';
      const params = { mem_key: currentUserKey, msg_div: msgDiv };
      const response = await api.rooms({ params });
      const formattedRooms = response.data
        .filter((room) => msgDiv !== 'system' || room.del_yn !== '0')
        .map((room) => ({
          id: room.chat_id,
          name: room.chat_nm,
          unread: room.confirm_cnt || 0,
          del_yn: room.del_yn,
          lastMessage: room.contents || '새로운 채팅방',
          mem_key: room.mem_key,
          msg_seq: room.msg_seq || null,
          mem_img_path: room.mem_img_path,
          block_status: room.block_status,
          chat_div: room.chat_div,
          lst_id: room.lst_id,
          lst_dt: room.lst_dt,
        }));
      const hasRoomListChanged = JSON.stringify(previousRooms) !== JSON.stringify(formattedRooms);

      // console.log(formattedRooms);
      if (hasRoomListChanged) {
        dispatch(setChatRooms(formattedRooms));
        setPreviousRooms(formattedRooms);
      } else {
        handleTabChange(activeTab);
        const updatedRooms = formattedRooms.map((room) => {
          const updatedRoom = formattedRooms.find((r) => r.id === room.id);
          if (updatedRoom) {
            return {
              ...room,
              unread: updatedRoom.unread,
              lastMessage: updatedRoom.lastMessage,
              lst_dt: updatedRoom.lst_dt,
            };
          }
          return room;
        });
        dispatch(setChatRooms(updatedRooms));
      }
    } catch (error) {
      message.error('채팅방을 불러오는데 실패했습니다.');
    } finally {
      setRoomListLoading(false);
    }
  }, [activeTab, dispatch, previousRooms, currentUserKey]);

  // 채팅방 메시지 조회
  const fetchMessages = useCallback(async () => {
    if (!currentRoom) return;
    setLoading(true);
    try {
      const params = {
        chat_id: currentRoom.id,
        msg_div: activeTab === 'normal' ? 'user' : 'system',
        mem_key: currentUserKey,
      };
      const response = await api.messages({ params });
      const formattedMessages = response.data.map((msg) => ({
        id: msg.msg_seq,
        mem_img_path: msg.mem_img_path,
        sender: msg.sender,
        content: msg.contents,
        lst_dt: msg.lst_dt,
        isMine: msg.fr_mem_key === currentUserKey,
        isAdmin: msg.fr_mem_key === '0',
      }));
      dispatch(setMessages({ roomId: currentRoom.id, messages: formattedMessages }));
    } catch (error) {
      message.error('메시지를 불러오는데 실패했습니다.');
    } finally {
      requestAnimationFrame(() => {
        scrollToBottom(false);
      });
      setLoading(false);
    }
  }, [currentRoom, dispatch, scrollToBottom]);

  const [isFollowing, setIsFollowing] = useState(false);
  // 채팅방 프로필 조회
  const getProfile = async (room) => {
    setLoading(true);
    if (activeTab === 'system') return;
    try {
      const params = { mem_key: room.mem_key };
      const response = await api.userProfile({ params });
      dispatch(setUserProfile(response.data));
      const followerList = JSON.parse(response.data.follower_list);
      setIsFollowing(followerList != null ? checkFollowing(followerList) : false);

      if (response.data?.ai_fl_cd) {
        const ai_nm = response.data.ai_fl_nm.split('|');
        setSelectedAis(ai_nm);
      }
    } catch (error) {
      console.error('프로필 조회 에러:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    dispatch(setChatRooms(null));
    dispatch(clearMessages());
  };

  const handleRoomSelect = useCallback(
    async (room) => {
      if (isRoomOptionsOpen) return;

      const hasRoomChanged = JSON.stringify(previousRoom) !== JSON.stringify(room);

      if (hasRoomChanged) {
        try {
          setLoading(true);

          // 즉시 UI 업데이트
          dispatch(setCurrentRoom(room));
          setPreviousRoom(room);

          getProfile(room);
          await checkMessage(room); // 메시지 체크 비동기로 실행

          // 채팅방 목록 새로고침
          const response = await api.rooms({
            params: {
              mem_key: currentUserKey,
              msg_div: activeTab === 'normal' ? 'user' : 'system',
            },
          });

          const formattedRooms = response.data
            .filter((room) => activeTab !== 'system' || room.del_yn !== '0')
            .map((room) => ({
              id: room.chat_id,
              name: room.chat_nm,
              unread: room.confirm_cnt || 0,
              del_yn: room.del_yn,
              lastMessage: room.contents || '새로운 채팅방',
              mem_key: room.mem_key,
              msg_seq: room.msg_seq || null,
              mem_img_path: room.mem_img_path,
              block_status: room.block_status,
              chat_div: room.chat_div,
              lst_id: room.lst_id,
              lst_dt: room.lst_dt,
            }));

          dispatch(setChatRooms(formattedRooms));
        } catch (error) {
          console.error('Room selection failed:', error);
        } finally {
          setLoading(false);
        }
      } else {
        dispatch(setCurrentRoom(room));
      }

      setIsMessageVisable(true);
      setMobileProfile(false);
      dispatch(triggerIconRefresh(true));
    },
    [dispatch, previousRoom, isRoomOptionsOpen, activeTab, currentUserKey],
  );

  const handleRoomOptions = (e, room) => {
    e.stopPropagation();
    setIsRoomOptionsOpen(true);
    setSelectedRoomForOptions((prev) => (prev?.id === room.id ? null : room));
    setSelectedRooms([{ id: room.id, msg_seq: room.msg_seq }]);
  };
  const handleRoomBlock = (room) => {
    api
      .blockMember({
        mem_key: parseInt(currentUserKey, 10),
        blk_mem_key: room.mem_key,
      })
      .then((response) => {
        if (selectedRoomForOptions.block_status !== 'N') {
          message.success(`해당 사용자 차단을 해제했습니다.`);
        } else {
          message.success(`해당 사용자를 차단했습니다.`);
        }
        fetchRooms();
        setSelectedRoomForOptions(null);
      })
      .catch((error) => {
        console.error('Error blocking member:', error);
        message.error('차단에 실패했습니다.');
      });
    setIsRoomOptionsOpen(false);
  };

  const checkMessage = async (room) => {
    try {
      const msgDiv = activeTab === 'normal' ? 'user' : 'system';

      if (!room.id || !currentUserKey) {
        message.error('필수 정보가 누락되었습니다.');
        return;
      }
      const memKey = parseInt(currentUserKey, 10);
      const data = {
        chat_id: room.id,
        mem_key: memKey,
        msg_seq: room.msg_seq,
        msg_div: msgDiv,
      };
      await api.checkMessage(data);
    } catch (error) {
      console.error('Error details:', error.response?.data || error);
      message.error('메시지 확인을 실패했습니다.');
    }
  };

  const handleSendMessage = async (options = {}) => {
    if (!newMessage.trim() && Object.keys(options).length === 0) {
      message.error('메세지를 입력해주세요.');
      return;
    }

    if (sendingMessage) return;

    setSendingMessage(true);

    const { file } = options;

    if (!currentRoom) message.error('채팅방을 선택해주세요.');

    try {
      const formData = new FormData();
      if (file) {
        if (file.size / 1024 / 1024 >= 10) {
          message.error('파일은 10MB보다 작아야 합니다!');
          return;
        }
        formData.append('files', file);
      }
      const params = {
        chat_id: currentRoom.id,
        sender: `${currentUserKey}|${currentRoom.mem_key}`,
        msg_div: 'user',
        mem_key: currentUserKey,
      };
      formData.append('chatVO', new Blob([JSON.stringify(params)], { type: 'application/json' }));

      const response = await api.uploadMessage(formData);
      // console.log(response);
      if (file) console.log(`${file.name} 파일이 업로드되었습니다.`);

      if (newMessage?.trim()) {
        const response = await api.sendMessage({
          chat_id: currentRoom.id,
          sender: `${currentUserKey}|${currentRoom.mem_key}`,
          msg_div: 'user',
          contents: newMessage,
          mem_key: currentUserKey,
        });

        if (response.data.status === 200) {
          dispatch(
            addMessage({
              roomId: currentRoom.id,
              message: {
                id: Date.now(),
                sender: currentUserKey,
                content: newMessage,
                isMine: true,
                lst_dt: new Date().toISOString(),
              },
            }),
          );
          requestAnimationFrame(() => {
            scrollToBottom(true);
          });
        }
        setNewMessage('');
      }
      await fetchMessages();
      await fetchRooms();
      setIsMessageVisable(true);
      await handleRoomSelect(currentRoom);
    } catch (error) {
      console.error('Error sending message:', error);
      message.error('메시지 전송에 실패했습니다.');
    } finally {
      setSendingMessage(false);
    }
  };

  const handleLeaveRoom = useCallback(async () => {
    const msgDiv = activeTab === 'normal' ? 'user' : 'system';
    if (selectedRooms.length === 0 && currentRoom != null) {
      setSelectedRooms([{ id: currentRoom.id, msg_seq: currentRoom.msg_seq }]);
    }

    if (selectedRooms.length === 0 && currentRoom == null && activeTab === 'normal') {
      message.warning('채팅방을 선택해주세요');
      return;
    }

    if (selectedAlerts.length === 0 && selectedAlertsRef.current.length === 0 && activeTab !== 'normal') {
      message.warning('알림을 선택해주세요');
      return;
    }

    try {
      // 선택된 채팅방 또는 현재 채팅방 처리
      const roomsToLeave = selectedRooms.length === 0 ? selectedAlertsRef.current : selectedRooms;

      const deletionPromises = roomsToLeave.map(async (room) => {
        const data = {
          chat_id: room.id,
          msg_seq: room.msg_seq,
          msg_div: msgDiv,
          mem_key: parseInt(currentUserKey, 10),
        };
        // console.log('요청 데이터:', data);
        return await api.closeRoom(data);
      });

      await Promise.all(deletionPromises);

      setSelectedRooms([]);
      setSelectedAlerts([]);
      selectedAlertsRef.current = [];

      setSelectAll(false);

      if (currentRoom) {
        fetchMessages();
      }
      fetchRooms();
      dispatch(setCurrentRoom(null));
      setSelectedRoomForOptions(null);
      setIsMessageVisable(false);
      if (msgDiv === 'user') {
        message.success('채팅방을 나갔습니다.');
      } else {
        message.success('시스템 알림을 삭제했습니다.');
      }
    } catch (error) {
      console.error(error);
      if (msgDiv === 'user') {
        message.error('채팅방 나가기에 실패했습니다.');
      } else {
        message.error('시스템 알림 삭제를 실패했습니다.');
      }
    } finally {
      dispatch(triggerIconRefresh(true));
    }
  }, [selectedRooms, currentRoom, activeTab, dispatch]);

  const handleSelectAll = () => {
    if (chatRooms.length === 0) {
      setSelectAll(false);
      setSelectedRooms([]);
      setSelectedAlerts([]);
      selectedAlertsRef.current = [];
      return;
    }

    const newSelectAll = !selectAll;
    if (newSelectAll) {
      if (activeTab !== 'normal') {
        selectedAlertsRef.current = chatRooms.map((room) => ({
          id: room.id,
          msg_seq: room.msg_seq,
        }));
        setSelectedAlerts([...selectedAlertsRef.current]);
      } else {
        setSelectedRooms(
          chatRooms.map((room) => ({
            id: room.id,
            msg_seq: room.msg_seq || null,
          })),
        );
      }
    } else {
      setSelectedRooms([]);
      setSelectedAlerts([]);
      selectedAlertsRef.current = [];
    }
  };

  const handleCheckboxChange = (room) => {
    if (activeTab !== 'normal') {
      const prevSelectedAlert = selectedAlertsRef.current;
      const isAlreadySelected = prevSelectedAlert.some((selectedAlert) => selectedAlert.msg_seq === room.msg_seq);

      if (isAlreadySelected) {
        selectedAlertsRef.current = prevSelectedAlert.filter((selectedAlert) => selectedAlert.msg_seq !== room.msg_seq);
      } else {
        selectedAlertsRef.current = [...prevSelectedAlert, { id: room.id, msg_seq: room.msg_seq || null }];
      }
      // console.log(previousRooms.length)
    } else {
      setSelectedRooms((prevSelectedRooms) => {
        const isAlreadySelected = prevSelectedRooms.some((selectedRoom) => selectedRoom.id === room.id);

        if (isAlreadySelected) {
          return prevSelectedRooms.filter((selectedRoom) => selectedRoom.id !== room.id);
        } else {
          return [...prevSelectedRooms, { id: room.id, msg_seq: room.msg_seq || null }];
        }
      });
    }
    setSelectedAlerts([...selectedAlertsRef.current]);
  };

  const checkFollowing = (data) => {
    return data.some((item) => item.mem_key === parseInt(currentUserKey, 10));
  };

  const [followModalVisible, setFollowModalVisible] = useState(false);
  const [modalType, setModalType] = useState('follower');
  const [followData, setFollowData] = useState([]);

  const followerRef = useRef();
  const followingRef = useRef();

  const handleFollowClick = (type) => {
    const list = JSON.parse(userProfile?.[`${type}_list`] || '[]');
    if (list.length === 0) {
      message.warning(type === 'follower' ? '팔로워 한 사람이 없습니다.' : '팔로잉 한 사람이 없습니다.');
      return;
    }
    setModalType(type);
    setFollowData(list);
    setFollowModalVisible(true);
  };

  useEffect(() => {
    if (activeTab !== 'normal') {
      if (selectedAlertsRef.current.length === 0 && previousRooms?.length === 0) {
        setSelectAll(false);
      } else {
        setSelectAll(selectedAlertsRef.current.length === (previousRooms?.length ?? 0));
      }
    } else {
      if (selectedRooms.length === 0 && previousRooms?.length === 0) {
        setSelectAll(false);
      } else {
        setSelectAll(selectedRooms.length === (previousRooms?.length ?? 0));
      }
    }

    console.log(userProfile);
  }, [selectedRooms, previousRooms, selectedAlertsRef.current]);

  useEffect(() => {
    setSelectedAlerts([...selectedAlertsRef.current]);
  }, [selectedAlertsRef.current]);

  const [isBoxVisible, setIsBoxVisible] = useState(false);

  const toggleBoxVisibility = () => {
    setIsBoxVisible((prev) => !prev);
  };

  useEffect(() => {
    dispatch(setCurrentRoom(null));
    scrollToBottom();
  }, []);

  useEffect(() => {
    fetchRooms();
  }, [activeTab]);

  useEffect(() => {
    if (messages.length > prevMessagesLengthRef.current) {
      requestAnimationFrame(() => {
        scrollToBottom(); // smooth 스크롤 사용
      });
    }
    prevMessagesLengthRef.current = messages.length;
  }, [messages.length, scrollToBottom]);

  useEffect(() => {
    setActiveTab(tabId);
  }, [tabId]);

  useEffect(() => {
    if (!isRoomOptionsOpen) {
      if (currentRoom) {
        const initialize = async () => {
          if (messageListRef.current) {
            messageListRef.current.style.visibility = 'hidden';
          }
          await fetchMessages();
          scrollToBottom();
          if (messageListRef.current) {
            messageListRef.current.style.visibility = 'visible';
          }
        };
        initialize();
      } else {
        dispatch(clearMessages());
      }
    }
  }, [currentRoom]);

  useEffect(() => {
    if (messages.length > prevMessagesLengthRef.current) {
      requestAnimationFrame(() => {
        scrollToBottom();
      });
    }
    prevMessagesLengthRef.current = messages.length;
  }, [messages.length]);

  useEffect(() => {
    const handleClickOutside = () => {
      if (selectedRoomForOptions) {
        setSelectedRoomForOptions(null);
        setIsRoomOptionsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectedRoomForOptions]);

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        fetchRooms();
      },
      30 * 60 * 1000,
    ); // 30분 (? * 60 * 1000 ms)
    return () => clearInterval(intervalId);
  }, [fetchRooms]);

  const [isModalOpen, setIsModalOpen] = useState(false); // 모달 상태 관리
  const [modalImgSrc, setModalImgSrc] = useState(''); // 클릭된 이미지의 src

  const renderMessage = (msg) => {
    const handleImageClick = (imgSrc) => {
      setModalImgSrc(imgSrc);
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    if (msg.content.includes('/file/message')) {
      const imgSrc = msg.content;
      return (
        <>
          <img
            src={imgSrc}
            alt="message"
            style={{ height: '100%', maxWidth: '100%', cursor: 'pointer' }}
            onClick={() => handleImageClick(imgSrc)}
            onLoad={() => {
              scrollToBottom(false);
            }}
          />
          <Modal open={isModalOpen} footer={null} onCancel={handleCloseModal} centered>
            <img src={modalImgSrc} alt="modal" style={{ width: '100%' }} />
          </Modal>
        </>
      );
    }
    return msg.content;
  };

  const requestFollow = useCallback(
    async (follow_id) => {
      try {
        const { data } = await api.addFollower({ mem_key: follow_id, flw_mem_key: currentUserKey });

        if (data === 'success') {
          const messageText = isFollowing ? '팔로우가 취소 되었습니다.' : '팔로우를 시작하였습니다.';
          message.success(messageText);
        }
        await getProfile({ mem_key: follow_id });
      } catch (error) {
        message.warning(error.message);
      }
    },
    [currentUserKey, isFollowing],
  );

  // 뒤로 가기 제스처
  useEffect(() => {
    const handlePopState = () => {
      dispatch(setCurrentRoom(null));
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  // 순위 팝업 닫기
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !event.target.closest('img')) {
        setIsBoxVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMobileProfileClick = (e, room) => {
    if (window.innerWidth <= 700) {
      e.stopPropagation();
      getProfile(room);
      setIsMessageVisable(true);
      setMobileProfile(true);
    }
  };

  const handleProfileClick = (memKey) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_key: memKey,
      },
    });
  };

  // 선택한 메뉴로 이동
  const moveToPage = (num) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_email: userProfile?.mem_email,
        mem_key: userProfile?.mem_key,
        cateCd: (userProfile?.agg_cate || '').split(',')[num],
        ranking: 'true',
      },
    });
  };

  // 선택한 메뉴로 이동
  const moveToPlan = (num) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_email: auth?.mem_email,
        mem_key: auth?.mem_key,
        plan: 'true',
      },
    });
  };

  // 랭크 아이콘 치환
  const getRankItems = () => {
    if (!userProfile?.agg_rank) return [];

    const ranks = userProfile.agg_rank.split(',');
    const items = [
      { rank: ranks[0], icon: IMAGES.IMAGES_ICON, page: 0, label: 'image' },
      /*{ rank: ranks[1], icon: IMAGES.VIDEO_ICON, page: 1, label: 'video' },*/
      { rank: ranks[2], icon: IMAGES.MUSIC_ICON, page: 2, label: 'note' },
      { rank: ranks[3], icon: IMAGES.BOOK_ICON, page: 3, label: 'book' },
    ];

    return items.sort((a, b) => Number(a.rank) - Number(b.rank));
  };

  const sortedItems = getRankItems();
  const highestRankItem = sortedItems.length > 0 ? { ...sortedItems[0] } : null;

  // 마켓플레이스 뱃지 리스트 조회
  const listBadge = async () => {
    try {
      const { data } = await api.getBadgeInfo();
      setBadgeList(data);
    } catch (error) {
      console.error('마켓플레이스 뱃지 리스트 조회 error', error);
    }
  };

  // 사용자의 마켓플레이스 뱃지 가져오기
  const getMemberBadge = useCallback(
    (bdgCd) => {
      if (!bdgCd || !badgeList.length) return [];
      return badgeList.filter((badge) => bdgCd.includes(badge.bdg_cd));
    },
    [badgeList],
  );

  useEffect(() => {
    listBadge();
  }, []);

  return (
    <article id="message" className="flexColCenter">
      <div className="rsWrapper flexRowBetween">
        {/* 왼쪽 리스트 영역 */}
        <div className="list flexColCenter">
          <div className="flexRowBetween top">
            <Checkbox
              style={{
                fontSize: `14px`,
                fontWeight: `500`,
              }}
              checked={selectAll}
              onChange={handleSelectAll}
            >
              전체선택
            </Checkbox>
            {activeTab === 'system' ? <div style={{ fontSize: '115%' }}>알림목록</div> : null}
            <button
              className="logoutBtn"
              onClick={handleLeaveRoom}
              disabled={activeTab !== 'system' ? selectedRooms.length === 0 : selectedAlertsRef.current.length === 0}
              style={{ cursor: activeTab !== 'system' ? (selectedRooms.length > 0 ? 'pointer' : 'auto') : selectedAlertsRef.current.length === 0 ? 'auto' : 'pointer' }}
            >
              {' '}
              {/*  */}
              <>
                <img src={IMAGES.TRASH_ICON} />
                삭제
              </>
            </button>
          </div>

          {roomListLoading ? (
            <LoadingContainer>
              <Spin />
            </LoadingContainer>
          ) : (
            <>
              {chatRooms.length === 0 ? (
                <div className="flexRowBetween box">
                  <div
                    className="noResult"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '90%',
                      color: '#999',
                    }}
                  >
                    채팅방 목록이 없습니다.
                  </div>
                </div>
              ) : (
                chatRooms.map((room) => (
                  <div
                    className={`flexRowBetween box ${currentRoom?.id === room?.id ? 'active' : ''}`}
                    key={currentRoom?.msg_seq !== null ? `${room.id}_${room.msg_seq}` : room?.id} // msg_seq가 있을 때만 room.id와 msg_seq를 결합
                    id={`${currentRoom?.msg_seq !== null ? (currentRoom?.id === room?.id && currentRoom?.msg_seq === room?.msg_seq ? 'active' : '') : currentRoom?.id === room?.id ? 'active' : ''}`}
                    onClick={() => handleRoomSelect(room)}
                    style={{ backgroundColor: room?.unread > 0 ? '#fdf2ff' : null }}
                  >
                    <div className="flexRowStart check">
                      <Checkbox
                        checked={
                          activeTab !== 'normal' ? selectedAlerts.some((selectedAlert) => selectedAlert.msg_seq === room.msg_seq) : selectedRooms.some((selectedRoom) => selectedRoom.id === room.id)
                        }
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleCheckboxChange(room)}
                      />
                      <img
                        src={activeTab !== 'normal' ? IMAGES.LOGO_ICON : room?.mem_img_path || IMAGES.DEFAULT_PROFILE}
                        onClick={(e) => handleMobileProfileClick(e, room)}
                        onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)}
                      />
                    </div>
                    <div className="flexColStart messageD">
                      <h1>{room?.name}</h1>
                      <h2>{room?.lastMessage.includes('/file/message') ? '사진' : truncateMessage(room?.lastMessage, 18)}</h2>
                    </div>
                    <div className="flexColCenter info">
                      {room?.unread > 0 && <div className="count flexColCenter">{room?.unread}</div>}
                      <p>{formatMessageDate(room?.lst_dt)}</p>

                      {activeTab === 'normal' && (
                        <div className="flexRowCenter more">
                          {selectedRoomForOptions?.id === room?.id && (
                            <div className="absoluteBox flexColCenter">
                              <div className="flexColCenter" onClick={() => handleRoomBlock(selectedRoomForOptions)}>
                                {selectedRoomForOptions.block_status === 'N' ? '차단' : '차단해제'}
                              </div>
                              <div className="flexColCenter" onClick={() => handleLeaveRoom()}>
                                나가기
                              </div>
                            </div>
                          )}
                          <p>
                            <MoreOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRoomOptions(e, room);
                              }}
                              style={{
                                marginLeft: '4px',
                                cursor: 'pointer',
                              }}
                            />
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>

        <div className="center flexColCenter" style={currentRoom ? { display: 'block' } : { display: 'none' }}>
          <div className="title">
            <button
              className="btn-back-mes"
              onClick={(e) => {
                dispatch(setCurrentRoom(null));
              }}
            ></button>
            <div className="svgBox">
              <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '15px' }}>
                <g clipPath="url(#k4gdak41ea)">
                  <path
                    d="M9.582 9.059c.557.52.557 1.366 0 1.886L2.44 17.61c-.558.52-1.464.52-2.022 0a1.273 1.273 0 0 1 0-1.887L6.551 10 .423 4.277a1.273 1.273 0 0 1 0-1.887 1.504 1.504 0 0 1 2.022 0l7.141 6.665-.004.004z"
                    fill="#332E36"
                  />
                </g>
                <defs>
                  <clipPath id="k4gdak41ea">
                    <path fill="#fff" transform="translate(0 2)" d="M0 0h10v16H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <h1 onClick={handleMobileProfileClick}>{currentRoom ? currentRoom?.name : '채팅방 선택'}</h1>
          </div>

          <div
            className="message flexColCenter"
            ref={messageListRef}
            style={{
              visibility: loading && messages.length === 0 ? 'hidden' : 'visible',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {loading && messages.length === 0 ? (
              <LoadingContainer>
                <Spin />
              </LoadingContainer>
            ) : messages.length > 0 ? (
              activeTab === 'normal' ? (
                groupMessagesByDate(messages).map((item, index) => {
                  if (item.type === 'date') {
                    return (
                      <DateDivider key={`date-${index}`}>
                        <DateLabel>{item.date}</DateLabel>
                      </DateDivider>
                    );
                  }
                  const msg = item.data;
                  return (
                    <div className={`flexCol${msg.isMine ? 'End sender' : 'Start reciver'}`} key={`msg-${msg.id}`}>
                      {!msg.isMine ? (
                        <div className={'flexRowStart'}>
                          <div className="flexColCenter" onClick={handleMobileProfileClick}>
                            <img alt="user avatar" src={msg.mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />
                          </div>
                          <div className="flexColStart">{renderMessage(msg)}</div>
                        </div>
                      ) : (
                        <div className="flexColStart">{renderMessage(msg)}</div>
                      )}
                      <p style={{ marginLeft: !msg.isMine ? '50px' : null }}>
                        {new Date(msg.lst_dt).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className="flexColStart notice">
                  <div className="date flexRowStart">{formatAlramDate(currentRoom?.lst_dt)}</div>
                  <div className="box">
                    {/* 가운데 상세 메시지 영역 */}
                    <div className="icon">
                      <img src={IMAGES.MANAGER_ICON} alt="사용자아이콘" />
                    </div>
                    <div className="content">
                      <p>
                        {currentRoom?.lastMessage?.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}{' '}
                      </p>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <EmptyMessageContainer className="noResult">메시지가 없습니다.</EmptyMessageContainer>
            )}
          </div>

          {activeTab === 'normal' ? (
            <div className="flexRowBetween inputBox">
              <Upload name="image" customRequest={handleSendMessage} showUploadList={false} accept=".jpg,.jpeg,.png">
                <button id="pinkBtn" className="upload " disabled={userProfile?.mem_stat !== '02'}>
                  <img src={IMAGES.IMAGES_ICON2} alt="Upload Icon" />
                </button>
              </Upload>
              <Input
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={userProfile?.mem_stat !== '02' ? '탈퇴한 회원입니다.' : '메시지를 입력하세요'}
                onPressEnter={(e) => !sendingMessage && handleSendMessage()}
                disabled={userProfile?.mem_stat !== '02'}
              />
              <button className="send" onClick={() => !sendingMessage && handleSendMessage()} disabled={userProfile?.mem_stat !== '02'}>
                보내기
              </button>
            </div>
          ) : (
            <></>
          )}
          <div ref={messageEndRef} />
        </div>

        {activeTab === 'normal' && isMessageVisable && (
          /* 오른쪽 프로필 영역 */
          <div className={`profile flexColStart ${mobileProfile ? 'active' : ''}`}>
            <div className="title">
              <button
                className="btn-back-mes"
                onClick={() => {
                  setMobileProfile(false);
                  setIsMessageVisable(false);
                }}
              ></button>
              <h1>프로필</h1>
            </div>
            <div className="box flexColCenter">
              <div className="flexColCenter profileBox">
                <img
                  src={userProfile?.mem_stat !== '02' ? IMAGES.DEFAULT_PROFILE : userProfile?.mem_img_path || IMAGES.DEFAULT_PROFILE}
                  alt="Profile"
                  onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)}
                />
                <h1 style={{ cursor: userProfile?.mem_stat === '02' && 'pointer' }} onClick={() => handleProfileClick(userProfile?.mem_key)}>
                  {userProfile?.mem_stat !== '02' ? '탈퇴한 회원입니다.' : `${userProfile?.mem_nick}`}
                </h1>
                {userProfile?.mem_stat !== '02' ? null : (
                  <>
                    <h2>{userProfile?.fir_dt ? formatDate(userProfile?.fir_dt) : 'N/A'}</h2>
                    <div className="flexRowCenter">
                      <span className={`badge_s ${badge_map[userProfile?.plan_cd] || ''}`}>{userProfile?.plan_nm}</span>
                      <img src={IMAGES.MARK_ICON} style={{ cursor: 'pointer' }} onClick={moveToPlan} />
                    </div>
                  </>
                )}
              </div>
              {userProfile?.mem_stat !== '02' ? null : (
                <>
                  <div className="flexRowBetween followBox">
                    <div className="flexColCenter" onClick={() => handleFollowClick('following')} ref={followingRef}>
                      <h1>{userProfile?.following_cnt}</h1>
                      <p>팔로잉 수</p>
                    </div>
                    <div className="flexColCenter" onClick={() => handleFollowClick('follower')} ref={followerRef}>
                      <h1>{userProfile?.follower_cnt}</h1>
                      <p>팔로워 수</p>
                    </div>
                    <div className="flexColCenter">
                      <button className={`btn-follow${isFollowing ? ' ing' : ''}`} onClick={() => requestFollow(userProfile?.mem_key)}>
                        {isFollowing ? (
                          <>
                            {' '}
                            <MinusOutlined />
                            팔로잉{' '}
                          </>
                        ) : (
                          <>
                            <PlusOutlined />
                            팔로우{' '}
                          </>
                        )}
                      </button>
                    </div>
                    {/* 팔로워 수 확인 모달 */}
                    <FollowModal
                      visible={followModalVisible}
                      onClose={() => setFollowModalVisible(false)}
                      type={modalType}
                      data={followData}
                      anchorRef={modalType === 'follower' ? followerRef : followingRef}
                    />
                  </div>
                  <div className="infoBox">
                    {/* 뱃지 & sns */}
                    <div className="flexRowBetween">
                      <div className="snsArea">
                        {/*{userProfile?.web && <button className="btn-web" onClick={() => handleLinkClick(userProfile?.web, 'Web')}/>}*/}
                        {userProfile?.x_link && <button className="btn-x" onClick={() => handleLinkClick(userProfile?.x_link, 'X')} />}
                        {userProfile?.youtube_link && <button className="btn-youtube" onClick={() => handleLinkClick(userProfile?.youtube_link, 'Youtube')} />}
                        {userProfile?.insta_link && <button className="btn-insta" onClick={() => handleLinkClick(userProfile?.insta_link, 'Insta')} />}
                      </div>
                    </div>

                    {/* 랭킹 & 별점 */}
                    <div className="flexRowBetween">
                      <div className="rankingArea" onClick={toggleBoxVisibility}>
                        <img src={highestRankItem?.icon} alt={`${highestRankItem?.label} icon`} />
                        <p style={{ cursor: 'pointer' }}>#{highestRankItem?.rank}</p>
                        <img src={IMAGES.ARROWS_DOWN_ICON} alt="Toggle Visibility" style={{ width: '16px', cursor: 'pointer' }} />
                        {/* 클릭시 노출되는 UI */}
                        {isBoxVisible && (
                          <ul className="absoluteBox" ref={wrapperRef}>
                            {sortedItems.map((item, index) => (
                              <li style={{ cursor: 'pointer' }} onClick={() => moveToPage(item.page)}>
                                <img src={item.icon} alt={`${item.label} icon`} />
                                <p>#{item.rank || ''}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      <div className="major">
                        {getMemberBadge(userProfile?.bdg_cd).map((badge, index) => (
                          <Tooltip title={badge?.bdg_nm}>
                            <img key={index} src={badge?.icon_path} alt="icon" />
                          </Tooltip>
                        ))}
                        {/* {formatBadgeIcon(userProfile?.bdg_url)} */}
                      </div>
                      <div className="rateArea">
                        {/*
                      <h2>{userProfile?.score_avg || "0"}</h2>
                      <Rate tooltip={false} value={userProfile?.score_avg ? userProfile?.score_avg : 0} disabled={true} />
                      <p> (<span>{userProfile?.review_cnt}</span>) </p>
                    */}
                      </div>
                    </div>
                    {/* 전문 AI 분야 */}
                    <div className="aiArea">
                      <p>전문 AI 분야</p>
                      <div className="flexRowStart majorModel">{selectedAis.length ? selectedAis.map((ai) => <span>{ai}</span>) : <span>없음</span>}</div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {userProfile?.mem_stat !== '02' ? null : (
              <div className="info">
                <h3 style={{ marginTop: '2%', marginBottom: '3%' }}>{userProfile?.about_me || '페이지명이 없습니다.'}</h3>
                <h4>{userProfile?.about_chanel || '설명이 없습니다.'}</h4>
              </div>
            )}
          </div>
        )}
      </div>
    </article>
  );
};

export default ChatBase;
