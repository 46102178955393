import React from 'react';
import * as lib from '@utils/lib';

const CardSquareMusic1 = ({ data, moveToDetailPage }) => {
  const addDefaultImg = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        data.thum_path = '';
        e.currentTarget.src = lib.getDefaultImg('music', data);
  };
  return (
    <div className="music flexColCenter" key={data?.prpt_id} onClick={moveToDetailPage}>
      <div className="user">
        {data?.ai_model_nm && <p>{data?.ai_model_nm}</p>}
      </div>
      <img
        src={lib.getDefaultImg('music', data)}
        // onError={addDefaultImg}
      />
      <p>{data?.prpt_title}</p>
    </div>
  );
};

export default CardSquareMusic1;
