import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, Layout, Typography, Row, Col } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Table from '@components/Table';
import * as api from '@api/index';
import { isEmpty } from '@utils/lib';
import GridHeader from '@components/GridHeader';
import Loading from '@components/Loading';
import { file2Obj } from 'antd/es/upload/utils';

const AdminPlanHome = (props) => {
  const { Title } = Typography;
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);

  const planGridRef = useRef(); // 멤버십 그리드
  const badgeGridRef = useRef(); // 마켓플레이스 그리드
  const dataGridRef = useRef(); // 데이터 그리드
  const imgRef = useRef(); // icon 업로드용 input Ref

  const dataList = useRef([]); // 데이터 목록
  const bdgDivList = useRef([]); // 뱃지 구분 공통코드 목록

  const [recentGrid, setRecentGrid] = useState(null); // 가장 최근 그리드
  const [recentItem, setRecentItem] = useState(null); // 가장 최근 그리드의 item
  const [planFileCaches, setPlanFileCaches] = useState([]); // 멤버십 파일 저장 목록
  const [delPlanFileCaches, setDelPlanFileCaches] = useState([]); // 멤버십 파일 삭제 목록
  const [badgeFileCaches, setBadgeFileCaches] = useState([]); // 마켓플레이스 파일 저장 목록
  const [delBadgeFileCaches, setDelBadgeFileCaches] = useState([]); // 마켓플레이스 파일 삭제 목록

  // 새 카테고리 관리 그리드 칼럼 레이아웃 정의
  const planGridLayout = [
    { dataField: 'plan_cd', headerText: '멤버십 코드', width: '10%', visible: false },
    { dataField: 'plan_nm', headerText: '멤버십', width: '10%', headerStyle: 'required-custom-header' },
    {
      headerText: '뱃지',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'icon_path',
          headerText: '아이콘',
          width: '7%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'TemplateRenderer',
          },
          // dataField 로 정의된 필드 값이 HTML 이라면 labelFunction 으로 처리할 필요 없음.
          labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
            // HTML 템플릿 작성
            let template = '';
            if (value) {
              template += '<img src="' + value + '" alt="Example" style="height:25px">';
            } else if (item.icon_nm) {
              template += '<span>' + item.icon_nm + '</span>';
            }
            template += "<span onClick=\"imgDelete('PLAN'," + "'" + item.icon_path + "'," + rowIndex + ')" style="position: absolute; top: 0px; right: 5px;">X</span>';
            return template;
          },
          editable: false,
        },
        {
          dataField: 'icon_btn',
          headerText: '추가',
          width: '7%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'ButtonRenderer',
            labelText: '파일 선택',
            onclick: function (rowIndex, columnIndex, value, item) {
              setRecentGrid('PLAN'); // 클릭한 그리드 보관
              setRecentItem(item); // 클릭한 행의 그리드 아이템 보관
              imgRef?.current?.click();
            },
          },
          editable: false,
        },
        { dataField: 'icon_nm', headerText: '아이콘 명', width: '10%', visible: false },
      ],
    },
    {
      headerText: '결제 금액(₩)',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'month_amt',
          headerText: '월',
          width: '8%',
          headerStyle: 'required-custom-header',
          dataType: 'numeric',
          editRenderer: {
            type: 'InputEditRenderer',
            onlyNumeric: true, // 0~9만 입력가능
            autoThousandSeparator: true, // 천단위 구분자 삽입 여부
          },
        },
        {
          dataField: 'year_amt',
          headerText: '년(20%할인)',
          width: '8%',
          headerStyle: 'required-custom-header',
          dataType: 'numeric',
          editRenderer: {
            type: 'InputEditRenderer',
            onlyNumeric: true, // 0~9만 입력가능
            autoThousandSeparator: true, // 천단위 구분자 삽입 여부
          },
        },
      ],
    },
    {
      dataField: 'inst_yn',
      headerText: '강사',
      width: '8%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['O', 'X'],
      },
    },
    {
      headerText: '마켓플레이스',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'sale_tp',
          headerText: '판매',
          headerStyle: 'required-custom-header',
          width: '8%',
          renderer: {
            type: 'DropDownListRenderer',
            list: ['O', 'X'],
          },
        },
      ],
    },
    {
      headerText: '스페이스(월)',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'space_cnt',
          headerText: '기본 개수',
          width: '8%',
          headerStyle: 'required-custom-header',
          dataType: 'numeric',
          editRenderer: {
            type: 'InputEditRenderer',
            onlyNumeric: true, // 0~9만 입력가능
            autoThousandSeparator: true, // 천단위 구분자 삽입 여부
          },
        },
        {
          dataField: 'space_add_yn',
          headerText: '기능 추가(1개)',
          width: '8%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'DropDownListRenderer',
            list: ['O', 'X'],
          },
        },
        {
          dataField: 'space_amt',
          headerText: '기능 추가(원)',
          width: '8%',
          headerStyle: 'required-custom-header',
          dataType: 'numeric',
          editRenderer: {
            type: 'InputEditRenderer',
            onlyNumeric: true, // 0~9만 입력가능
            autoThousandSeparator: true, // 천단위 구분자 삽입 여부
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let resStr = value ? value.toLocaleString() + '원' : '';
            return resStr;
          },
        },
        {
          dataField: 'space_mem_cnt',
          headerText: '기본 멤버 수(명)',
          width: '8%',
          headerStyle: 'required-custom-header',
          dataType: 'numeric',
          editRenderer: {
            type: 'InputEditRenderer',
            onlyNumeric: true, // 0~9만 입력가능
            autoThousandSeparator: true, // 천단위 구분자 삽입 여부
          },
        },
        {
          dataField: 'space_mem_add_amt',
          headerText: '멤버 추가(1명)',
          width: '8%',
          headerStyle: 'required-custom-header',
          dataType: 'numeric',
          editRenderer: {
            type: 'InputEditRenderer',
            onlyNumeric: true, // 0~9만 입력가능
          },
          labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let resStr = value ? value.toLocaleString() + '원' : '';
            return resStr;
          },
        },
      ],
    },
    {
      dataField: 'bld_cnt',
      headerText: '빌더 사용(개)',
      width: '8%',
      headerStyle: 'required-custom-header',
      dataType: 'numeric',
      editRenderer: {
        type: 'InputEditRenderer',
        onlyNumeric: true, // 0~9만 입력가능
        allowNegative: true, // 음수 입력가능
        validator: function (oldValue, newValue, item, dataField) {
          let isValid = false;
          let numVal = Number(newValue);
          if (!isNaN(numVal) && numVal >= -1) {
            isValid = true;
          }
          return { validate: isValid, message: '-1 또는 0 이상의 숫자만 입력해주세요' };
        },
      },
      headerTooltip: {
        show: true,
        tooltipHtml: '개수 제한을 원하지 않으면 -1을 입력해주세요.',
      },
    },
    {
      dataField: 'data_cd',
      headerText: '기본 데이터',
      width: '8%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'cd',
        valueField: 'cd_nm',
        listFunction: () => {
          return dataList.current;
        },
      },
    },
    { dataField: 'data_size', headerText: '데이터 사이즈', width: '8%', visible: false },
    {
      headerText: '마이페이지',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'ai_model_reg_yn',
          headerText: '전문 AI모델 등록',
          width: '8%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'DropDownListRenderer',
            list: ['O', 'X'],
          },
        },
      ],
    },
    {
      headerText: '프롬프트 스쿨',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'sch_sale_tp',
          headerText: '판매',
          width: '8%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'DropDownListRenderer',
            list: ['O', 'X'],
          },
        },
      ],
    },
    {
      headerText: '채용',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'recrt_eng_yn',
          headerText: '엔지니어',
          width: '8%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'DropDownListRenderer',
            list: ['O', 'X'],
          },
        },
        {
          dataField: 'recrt_clnt_yn',
          headerText: '클라이언트',
          width: '8%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'DropDownListRenderer',
            list: ['O', 'X'],
          },
        },
      ],
    },
  ];

  // 멤버십 그리드 props
  const planGridProps = {
    height: '200',
  };

  // 마켓플레이스 뱃지 관리 그리드
  const badgeGridLayout = [
    { dataField: 'bdg_cd', headerText: '뱃지 코드', width: '30%', visible: false },
    {
      dataField: 'bdg_div',
      headerText: '구분',
      width: '30%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        keyField: 'cd',
        valueField: 'cd_nm',
        listFunction: () => {
          return bdgDivList.current;
        },
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'bdg_nm',
      headerText: '뱃지 명칭',
      width: '30%',
      headerStyle: 'required-custom-header',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      headerText: '뱃지',
      headerStyle: 'required-custom-header',
      children: [
        {
          dataField: 'icon_path',
          headerText: '아이콘',
          width: '10%',
          headerStyle: 'required-custom-header',
          renderer: {
            // HTML 템플릿 렌더러 사용
            type: 'TemplateRenderer',
          },
          // dataField 로 정의된 필드 값이 HTML 이라면 labelFunction 으로 처리할 필요 없음.
          labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
            // HTML 템플릿 작성
            let template = '';
            if (value) {
              template += '<img src="' + value + '" alt="Example" style="height:25px">';
            } else if (item.icon_nm) {
              template += '<span>' + item.icon_nm + '</span>';
            }
            template += "<span onClick=\"imgDelete('BADGE'," + "'" + item.icon_path + "'," + rowIndex + ')" style="position: absolute; top: 0px; right: 5px;">X</span>';
            return template;
          },
          editable: false,
        },

        {
          dataField: 'icon_btn',
          headerText: '추가',
          width: '10%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'ButtonRenderer',
            labelText: '파일 선택',
            onclick: function (rowIndex, columnIndex, value, item) {
              setRecentGrid('BADGE'); // 클릭한 그리드 보관
              setRecentItem(item); // 클릭한 행의 그리드 아이템 보관
              imgRef?.current?.click();
            },
          },
          editable: false,
        },
        { dataField: 'icon_nm', headerText: '아이템 명', width: '10%', visible: false },
      ],
    },
    {
      dataField: 'bdg_std',
      headerText: '기준',
      width: '30%',
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let retStr = '';
        const bdgDiv = item.bdg_div;
        for (const bdgDivCode of bdgDivList.current) {
          if (bdgDivCode['cd'] == bdgDiv) {
            retStr = bdgDivCode['attr1'];
            break;
          }
        }
        return retStr;
      },
      editable: false,
    },
    {
      dataField: 'bdg_cnt',
      headerText: '건수(유료)',
      width: '30%',
      headerStyle: 'required-custom-header',
      dataType: 'numeric',
      editRenderer: {
        type: 'InputEditRenderer',
        onlyNumeric: true, // 0~9만 입력가능
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let resStr = value ? value.toLocaleString() + '건' : '';
        return resStr;
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
  ];

  // 마켓플레이스 뱃지 관리 props
  const badgeGridProps = {
    fillColumnSizeMode: true, // 컬럼 사이즈 비율 계산 유무
    height: 300,
  };

  // 데이터 관리 그리드
  const dataGridLayout = [
    { dataField: 'grp_cd', headerText: '그룹코드', width: '30%', visible: false },
    { dataField: 'cd', headerText: '데이터코드', width: '30%', visible: false },
    {
      dataField: 'cd_nm',
      headerText: '데이터',
      width: '30%',
      headerStyle: 'required-custom-header',
      editRenderer: {
        type: 'InputEditRenderer',
        validator: function (oldValue, newValue, item, dataField) {
          const regex = /^\d+(GB|MB)$/;
          const isValid = regex.test(newValue);

          return { validate: isValid, message: 'MB 또는 GB 단위만 입력가능합니다.' };
        },
      },
    },
    {
      dataField: 'attr1',
      headerText: '추가 구매 데이터(월)',
      width: '40%',
      headerStyle: 'required-custom-header',
      dataType: 'numeric',
      editRenderer: {
        type: 'InputEditRenderer',
        onlyNumeric: true, // 0~9만 입력가능
        autoThousandSeparator: true, // 천단위 구분자 삽입 여부
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let resStr = value ? value.toLocaleString() + '원' : '';
        return resStr;
      },
    },
    {
      dataField: 'attr2',
      headerText: '사이즈(MB)',
      width: '30%',
      expFunction: function (rowIndex, columnIndex, item, dataField) {
        let result;
        if (isEmpty(item.cd_nm)) return 0;

        const num = item.cd_nm.match(/\d+/)[0];
        const txt = item.cd_nm.match(/[A-Za-z]+/)[0];
        if (txt == 'GB') {
          // 단위가 GB이면 MB로 변환
          result = Number(num) * 1024;
        } else {
          result = Number(num);
        }
        return result;
      },
      visible: false,
    },
    {
      dataField: 'use_yn',
      headerText: '사용',
      width: '10%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N'],
      },
    },
  ];
  // 데이터 관리 props
  const dataGridProps = {
    height: '200',
    fillColumnSizeMode: true, // 컬럼 사이즈 비율 계산 유무
  };

  // 추가
  const handleAddRow = (type) => {
    switch (type) {
      case 'membership':
        planGridRef.current.addRow({});
        break;
      case 'badge':
        badgeGridRef.current.addRow({});
        break;
      case 'data':
        dataGridRef.current.addRow({ grp_cd: 'DATA' });
        break;
    }
  };

  // 공통코드 조회
  const getCommonCode = async () => {
    try {
      setLoading((prev) => ({ ...prev, codeLoad: true }));
      // 뱃지구분
      const response1 = await api.fetchEqualCommonGroupCode({ grp_cd: 'BDG_DIV' });
      bdgDivList.current = response1.data;
    } catch (error) {
      console.error('공통코드 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, codeLoad: false }));
      getBadgeInfo(); // 뱃지 조회
      getDataInfo(); // 데이터 관리 조회
      getPlanInfo(); // 멤버십 플랜 조회
    }
  };

  // 멤버십 플랜 조회
  const getPlanInfo = async () => {
    try {
      setLoading((prev) => ({ ...prev, planLoad: true }));
      const response = await api.getPlanInfo();
      planGridRef.current.setGridData(response.data);
    } catch (error) {
      console.log('멤버십 조회에러', error);
    } finally {
      setLoading((prev) => ({ ...prev, planLoad: false }));
    }
  };

  // 멤버십 플랜 저장
  const savePlanInfo = async () => {
    if (!(await confirm('저장하시겠습니까?'))) return false;

    let saveItems = planGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);

    // validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return false;
    }
    const isValid = planGridRef.current.validateChangedGridData(
      [
        'plan_nm',
        'month_amt',
        'year_amt',
        'inst_yn',
        'sale_tp',
        'space_cnt',
        'space_add_yn',
        'space_amt',
        'space_mem_cnt',
        'space_mem_add_amt',
        'bld_cnt',
        'data_cd',
        'ai_model_reg_yn',
        'sch_sale_tp',
        'recrt_eng_yn',
        'recrt_clnt_yn',
      ],
      '필수 필드는 반드시 값을 직접 입력해야 합니다.',
    );
    if (!isValid) return false;

    const isIconValid = planGridRef.current.validateGridDataByFunc(
      ['icon_path'],
      function (item, dataField) {
        const iconPath = item[dataField];
        const iconNm = item['icon_nm'];

        if (isEmpty(iconPath) && isEmpty(iconNm)) {
          return false;
        }
      },
      '아이콘은 반드시 추가하셔야 합니다.',
      true,
    );
    if (!isIconValid) return false;

    try {
      setLoading((prev) => ({ ...prev, planSaveLoad: true }));

      const formData = new FormData();
      // 멤버십 files
      if (planFileCaches.length > 0) {
        planFileCaches.map((fileObj) => {
          formData.append('files', fileObj.file);
          formData.append('fileIds', fileObj.id);
        });
      }
      // 삭제 file path
      if (delPlanFileCaches.length > 0) {
        delPlanFileCaches.map((delfile) => {
          formData.append('delFiles', delfile);
        });
      }
      // 멤버십 그리드 data
      formData.append('savePlanList', new Blob([JSON.stringify(saveItems)], { type: 'application/json' }));

      const response = await api.savePlanInfo(formData);
      alert(response.data.returnMessage, response.dtaa.returnStatus == 'fail' ? 'error' : 'success');
    } catch (e) {
      console.log('멤버십 플랜 저장 error', e);
    } finally {
      // 파일 캐시 초기화
      setPlanFileCaches([]);
      setDelPlanFileCaches([]);

      setLoading((prev) => ({ ...prev, planSaveLoad: false }));
      getPlanInfo(); // 멤버십 플랜 조회
    }
  };

  // 마켓플레이스 뱃지 조회
  const getBadgeInfo = async () => {
    try {
      setLoading((prev) => ({ ...prev, badgeLoad: true }));
      const response = await api.getBadgeInfo();
      badgeGridRef.current.setGridData(response.data);
    } catch (e) {
      console.log('뱃지 조회 error', e);
    } finally {
      setLoading((prev) => ({ ...prev, badgeLoad: false }));
    }
  };

  // 마켓플레이스 뱃지 저장
  const saveBadgeInfo = async () => {
    if (!(await confirm('저장하시겠습니까?'))) return false;

    let saveItems = badgeGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);

    // validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return false;
    }
    const isValid = badgeGridRef.current.validateChangedGridData(['bdg_div', 'bdg_nm', 'bdg_cnt'], '필수 필드는 반드시 값을 직접 입력해야 합니다.');
    if (!isValid) return false;

    const isIconValid = badgeGridRef.current.validateGridDataByFunc(
      ['icon_path'],
      function (item, dataField) {
        const iconPath = item[dataField];
        const iconNm = item['icon_nm'];

        if (isEmpty(iconPath) && isEmpty(iconNm)) {
          return false;
        }
      },
      '아이콘은 반드시 추가하셔야 합니다.',
      true,
    );
    if (!isIconValid) return false;

    try {
      setLoading((prev) => ({ ...prev, badgeSaveLoad: true }));

      const formData = new FormData();
      // 마켓플레이스 뱃지 files
      if (badgeFileCaches.length > 0) {
        badgeFileCaches.map((fileObj) => {
          formData.append('files', fileObj.file);
          formData.append('fileIds', fileObj.id);
        });
      }
      // 삭제 file path
      if (delBadgeFileCaches.length > 0) {
        delBadgeFileCaches.map((delfile) => {
          formData.append('delFiles', delfile);
        });
      }
      // 마켓플레이스 그리드 data
      formData.append('saveBadgeList', new Blob([JSON.stringify(saveItems)], { type: 'application/json' }));

      const response = await api.saveBadgeInfo(formData);
      alert(response.data.returnMessage, response.data.returnStatus == 'fail' ? 'error' : 'success');
    } catch (e) {
      console.log('뱃지 저장 error', e);
    } finally {
      setLoading((prev) => ({ ...prev, badgeSaveLoad: false }));
      getBadgeInfo(); // 뱃지 조회
    }
  };

  // 데이터 관리 조회
  const getDataInfo = async () => {
    try {
      setLoading((prev) => ({ ...prev, dataLoad: true }));
      const response = await api.fetchCommonDetailCode({ grp_cd: 'DATA' });
      dataList.current = response.data.filter((item) => item.use_yn != 'N');
      dataGridRef.current.setGridData(response.data);
    } catch (error) {
      console.error('데이터관리 조회 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, dataLoad: false }));
    }
  };

  // 데이터 관리 저장
  const saveDataInfo = async () => {
    if (!(await confirm('저장하시겠습니까?'))) return false;
    const saveDataItems = dataGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);

    // validation
    if (saveDataItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return false;
    }
    const isValid = dataGridRef.current.validateChangedGridData(['cd_nm', 'attr1', 'use_yn'], '필수 필드는 반드시 값을 직접 입력해야 합니다.');
    if (!isValid) return false;

    try {
      setLoading((prev) => ({ ...prev, dataSaveLoad: true }));
      const params = {
        saveDetailData: saveDataItems,
      };
      const response = await api.saveCommonCode(params);
      alert(response.data.returnMessage, response.data.returnStatus == 'fail' ? 'error' : 'success');
    } catch (error) {
      console.log('데이터관리 저장 error', error);
    } finally {
      setLoading((prev) => ({ ...prev, dataSaveLoad: false }));
      getDataInfo();
    }
  };

  useEffect(() => {
    getCommonCode(); // 공통코드 조회

    /** AUI그리드 이벤트 */
    // 멤버십
    // 기본데이터 (data_cd) 변경시 데이터 사이즈 자동으로 set
    planGridRef.current.bind('cellEditEnd', function (event) {
      if (event.dataField == 'data_cd') {
        let newValue;
        for (const dataCode of dataList.current) {
          if (dataCode['cd'] == event.value) {
            newValue = dataCode['attr2'];
            break;
          }
        }
        planGridRef.current.setCellValue(event.rowIndex, 'data_size', newValue);
      }
    });
    // // 추가된 행만 수정가능
    // planGridRef.current.bind('cellEditBegin', (event) => {
    //   const gridData = planGridRef.current.getGridDataWithState('state');
    //   if (gridData[event.rowIndex].state !== 'added') {
    //     return false;
    //   }
    // });

    // // 마켓플레이스 뱃지
    // // 추가된 행만 수정가능
    // badgeGridRef.current.bind('cellEditBegin', (event) => {
    //   const gridData = badgeGridRef.current.getGridDataWithState('state');
    //   if (gridData[event.rowIndex].state !== 'added') {
    //     return false;
    //   }
    // });
  }, []);

  window.imgDelete = (gridId, path, rowIndex) => {
    if (gridId == 'PLAN') {
      setDelPlanFileCaches(Array.from(new Set([...delPlanFileCaches, path])));
      planGridRef.current.updateRow({ icon_path: null, icon_nm: null }, rowIndex);
    } else if (gridId == 'BADGE') {
      setDelBadgeFileCaches(Array.from(new Set([...delBadgeFileCaches, path])));
      badgeGridRef.current.updateRow({ icon_path: null, icon_nm: null }, rowIndex);
    }
  };

  const imgUpload = (event) => {
    let file = event.target.files[0];
    if (recentGrid == 'PLAN') {
      setPlanFileCaches((prevPlanFileCaches) => {
        // id가 같은 행 중복 제거
        const filterItem = prevPlanFileCaches.filter((item) => item.id !== recentItem._$uid);
        return [...filterItem, { id: recentItem._$uid, file: file }];
      });

      // 이전 파일 경로 삭제
      setDelPlanFileCaches(Array.from(new Set([...delPlanFileCaches, recentItem.icon_path])));

      planGridRef.current.updateRowsById({
        _$uid: recentItem._$uid,
        icon_path: null,
        icon_nm: file.name,
      });
    } else if (recentGrid == 'BADGE') {
      setBadgeFileCaches((prevBadgeFileCaches) => {
        // id가 같은 행 중복 제거
        const filterItem = prevBadgeFileCaches.filter((item) => item.id !== recentItem._$uid);
        return [...filterItem, { id: recentItem._$uid, file: file }];
      });

      // 이전 파일 경로 삭제
      setDelBadgeFileCaches(Array.from(new Set([...delBadgeFileCaches, recentItem.icon_path])));

      badgeGridRef.current.updateRowsById({
        _$uid: recentItem._$uid,
        icon_path: null,
        icon_nm: file.name,
      });
    }

    imgRef.current.value = ''; // 초기화
  };

  useEffect(() => {
    console.log(isLoading);
    console.log(loading);
  }, [isLoading]);

  return (
    <>
      <Loading isLoading={isLoading} timeout={0} />
      <Layout>
        <Content>
          <Row gutter={[0, 48]}>
            {/*이미지 업로드용 input*/}
            <input type="file" accept="image/*" ref={imgRef} onChange={imgUpload} style={{ display: 'none' }} />
            <Col span={24}>
              <GridHeader headerStyle={{ height: 'auto' }}>
                {{
                  searchArea: (
                    <Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                      멤버십
                    </Title>
                  ),
                  buttonArea: (
                    <>
                      <Button className="btn-add" onClick={() => handleAddRow('membership')}>
                        추가
                      </Button>
                      <Button className="btn-pink" type="primary" onClick={savePlanInfo}>
                        저장
                      </Button>
                    </>
                  ),
                }}
              </GridHeader>
              <Table ref={planGridRef} columnLayout={planGridLayout} customGridProps={planGridProps} />
            </Col>

            <Col span={24}>
              <GridHeader headerStyle={{ height: 'auto' }}>
                {{
                  searchArea: (
                    <Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                      마켓플레이스 뱃지
                    </Title>
                  ),
                  buttonArea: (
                    <>
                      <Button className="btn-add" onClick={() => handleAddRow('badge')}>
                        추가
                      </Button>
                      <Button className="btn-pink" type="primary" onClick={saveBadgeInfo}>
                        저장
                      </Button>
                    </>
                  ),
                }}
              </GridHeader>
              <Table ref={badgeGridRef} columnLayout={badgeGridLayout} customGridProps={badgeGridProps} />
            </Col>

            <Col span={24}>
              <GridHeader headerStyle={{ height: 'auto' }}>
                {{
                  searchArea: (
                    <Title level={5} style={{ margin: 0, display: 'flex', alignItems: 'center' }}>
                      데이터 관리
                    </Title>
                  ),
                  buttonArea: (
                    <>
                      <Button className="btn-add" onClick={() => handleAddRow('data')}>
                        추가
                      </Button>
                      <Button className="btn-pink" type="primary" onClick={saveDataInfo}>
                        저장
                      </Button>
                    </>
                  ),
                }}
              </GridHeader>
              <Table ref={dataGridRef} columnLayout={dataGridLayout} customGridProps={dataGridProps} />
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export default AdminPlanHome;
