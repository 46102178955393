import React, { forwardRef } from 'react';
import Slider from 'react-slick';
// component
import PrevArrow from '@components/slider/PrevArrow';
import NextArrow from '@components/slider/NextArrow';

// default config
const defaultConfig = {
  centerMode: true,
  infinite: true,
  centerPadding: '0',
  speed: 500,
  swipeToSlide: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  cssEase: "linear", // 부드럽게 이동
};

const NewSlider = forwardRef(({ config, Content }, ref) => {
  return (<Slider ref={ref} {...{ ...defaultConfig, ...(config && config) }}>{Content}</Slider>);
});

export default NewSlider;
