import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useWidth from '@hooks/useWidth';
import Loading from '@components/Loading';
import Table from '@components/Table';
import Banner from '@components/slider/Banner';
import { Button, Card, Form, Input, Layout, List, message, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import * as api from '@api/index';
import { useDebounce } from '@utils/lib';
import { IMAGES } from "@utils/image";
import { boardSearch } from '@utils/constants';
import { BOARD_DETAIL, BOARD_EDIT } from '@routes/pathName';
import { setQuery, setBoardList, setActiveSegKey, setIsLoading, setYScrollPosition, setBoardCheck, setBoardDetail } from '@stores/board';

const BoardList = () => {
    const width = useWidth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleDebounce = useCallback(
        useDebounce((func) => func(), 300),
        [],
    );

    const auth = useSelector((state) => state.auth);
    const boardListState = useSelector((s) => s.board, shallowEqual);

    const [mobileSearch, setMobileSearch] = useState(false);
    const [initialized, setInitialized] = useState(false);  // 데이터 셋팅 후 로드 상태관리

    const [form] = Form.useForm();
    const boardGridRef = useRef();
    const cardContainerRef = useRef(null);      // CARD DOM 요소 저장용
    const dataContainerRef = useRef([]);         // 데이터 저장용

    const boardGridProps = useMemo(
        () => ({
            editable: false,
            showRowCheckColumn: false,
            showRowNumColumn: false,
            showStateColumn: false,
            height: window.innerHeight,
        }),
        [],
    );

    const getBoardList = useCallback(async (query) => {
            try {
                dispatch(setIsLoading(true));
                const mergedQuery = { ...boardListState.query, ...query };
                const { data } = await api.getBoardList(mergedQuery);
                if (!data) return;

                const ensureArray = (data) => Array.isArray(data) ? data : [];      // 배열 체크
                const items = query.offset ?
                    (boardListState.activeSegKey === 'List' ?
                            [...ensureArray(boardGridRef.current?.getGridData()), ...ensureArray(data.items)] :
                            [...ensureArray(dataContainerRef.current), ...ensureArray(data.items)]
                    ) : ensureArray(data.items);

                const currentCount = items.length;
                dispatch(
                    setBoardList({
                        items,
                        totalCount: data.total,
                        currentCount
                    })
                );

                if (boardListState.activeSegKey === 'List') {
                    if (query.offset && boardGridRef.current) {
                        boardGridRef.current.appendData(data.items);
                    } else if (boardGridRef.current) {
                        boardGridRef.current.setGridData(data.items);
                    }
                } else {
                    dataContainerRef.current = items;
                }

                if (boardListState.activeSegKey === 'List') {
                    setupGridEvents({
                        keyword_type: boardListState.query.keyword_type,
                        keyword_text: query.keyword_text,
                        offset: query.offset,
                        limit: query.limit,
                        totalCount: data.total,
                        currentCount: boardGridRef.current.getGridData().length,
                    });
                }
            } catch (error) {
                if (error.name !== 'AbortError') {
                    console.error('Error fetching board list:', error);
                    message.warning(error.message);
                }
            } finally {
                dispatch(setIsLoading(false));
            }
        },
        [dispatch, boardListState.query, boardListState.activeSegKey, handleDebounce],
    );

    // 검색 조건 저장/로드 함수
    const saveSearchCondition = useCallback((condition) => {
        if (!boardListState?.board_chk?.board_no) return;
        /*if(condition.keyword_text === '') {
            localStorage.removeItem(`boardSearchCondition_${boardListState?.board_chk?.board_no}`);
        };*/
        localStorage.setItem(`boardSearchCondition_${boardListState?.board_chk?.board_no}`, JSON.stringify(condition));
    },[boardListState?.board_chk?.board_no]);

    const loadSearchCondition = useCallback(() => {
        if (!boardListState.board_chk.board_no) return { keyword_type: boardSearch[0].value };

        const savedCondition = localStorage.getItem(`boardSearchCondition_${boardListState.board_chk.board_no}`);
        if (savedCondition) {
            return JSON.parse(savedCondition);
        }
        return { keyword_type: boardSearch[0].value };
    }, [boardListState.board_chk.board_no]);

    const handleSearchBoard = useCallback(() => {
        form.validateFields().then((values) => {
            dispatch(setYScrollPosition(0));

            const searchValues = {
                keyword_type: values.keyword_type,
                keyword_text: values.keyword_text || ''
            };
            saveSearchCondition(searchValues);

            const newQuery = {
                ...boardListState.query,
                keyword_type: values.keyword_type,
                keyword_text: values.keyword_text || '',
                selector_2: '',
                offset: 0,
                limit: 20,
            };
            handleDebounce(() => getBoardList(newQuery));
        });
    }, [form, boardListState.query, getBoardList, handleDebounce, dispatch]);

    // 그리드 이벤트 설정
    const setupGridEvents = ({ keyword_type, keyword_text, offset, limit, totalCount, currentCount }) => {
        boardGridRef.current.bind('vScrollChange', (event) => {
            handleDebounce(() => dispatch(setYScrollPosition(offset)), 300);

            console.log(currentCount + ":" + totalCount);
            // 스크롤이 마지막에 도달하고 더 불러올 데이터가 있을 때
            if (event.position === event.maxPosition && currentCount < totalCount) {
                handleScroll({ keyword_type, keyword_text, offset, limit });
            }
        });
    };

    const boardColumnLayout = useMemo(
        () => [
            { dataField: 'board_no', headerText: '게시판 번호', width: '8%', visible: false },
            { dataField: 'doc_no', headerText: '글 번호', width: '8%', visible: false},
            { dataField: 'rownum', headerText: '<span class="gridLeft">No</span>', width: '8%' },
            { dataField: 'title', headerText: '제목', editable: false, width: '52%',
              renderer: { type: 'TemplateRenderer' },
                labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
                    const commentCount = item.comment_cnt || 0;
                    return `
                        <div class="group">
                            ${item.title}
                            ${commentCount > 0 && boardListState?.board_chk?.reply_yn === 'Y' ? 
                            `<div class="comment">
                                <span><img src="${IMAGES.COMMENT_ICON}" alt="comment icon" /> </span>
                                <span>${commentCount}</span>
                            </div>` : '' 
                            }
                        </div>                    
                    `;
                },
            },
            { dataField: 'mem_nick', headerText: '작성자', width: '12%', visible: boardListState?.board_chk?.write_lv !== 'PLAN999' },
            { dataField: 'fir_dt', headerText: '작성일', dataType: 'date',  width: '12%',
                labelFunction: function(rowIndex, columnIndex, value) {
                    const today = new Date();
                    const fullDate = today.toISOString().slice(2, 10).replace(/-/g, ''); // "YYMMDD"
                    const valueFullDate = value.slice(2, 4) + value.slice(5, 7) + value.slice(8, 10);
                    return fullDate === valueFullDate ? value.slice(11, 16) : value.slice(2, 10);
                }
            },
            { dataField: 'like_cnt', headerText: '좋아요', width: '8%', dataType: 'numeric' },
            { dataField: 'view_cnt', headerText: '<span class="gridRight">조회수</span>', width: '8%', dataType: 'numeric' },
        ],[],
    );

    // 조회수 증가 함수 메모이제이션
    const handleViewCountIncrease = useCallback(async (board_no, doc_no) => {
        try {
            await api.increaseViewCount({ board_no, doc_no });
        } catch (error) {
            message.error('조회수 증가 실패: ' + error.message);
        }
    }, []);

    const handleWriteClick = useCallback(() => {
        dispatch(setBoardDetail())
        navigate(BOARD_EDIT, {
            state: {
                board_no: boardListState.query.board_no,
                doc_no: boardListState.query.doc_no,
                board_title: boardListState.board_chk?.board_title,
                type: boardListState?.board_chk?.dsp_meth === '1' ? 'List' : 'Card',
                attach_yn: boardListState.board_chk?.attach_yn,
            },
        });
    }, [navigate, boardListState.query.board_no, boardListState.board_chk?.board_title, boardListState.query.doc_no, boardListState?.board_chk?.dsp_meth, boardListState.board_chk?.attach_yn]);

    const handleTitleClick = useCallback(
        async (item) => {
            await handleViewCountIncrease(item.board_no, item.doc_no);
            dispatch(setBoardCheck({ ...boardListState.board_chk, doc_no: item.doc_no }));
            dispatch(setBoardDetail(item));
            navigate(`${BOARD_DETAIL}`);
        },
        [navigate, boardListState.board_chk, dispatch, handleViewCountIncrease],
    );

    const handleScroll = ({ keyword_type, keyword_text, offset, limit }) => {
        const newQuery = { ...boardListState.query, keyword_type, keyword_text, offset: offset + limit };
        handleDebounce(() => getBoardList(newQuery));
    };

    useEffect(() => {
        let localLoad = loadSearchCondition();
        dispatch(
            setQuery({
                board_no: boardListState?.board_chk?.board_no,
                board_title: boardListState?.board_chk?.board_title,
                dsp_meth: boardListState?.board_chk?.dsp_meth,
                keyword_type: localLoad.keyword_type || boardSearch[0].value,
                keyword_text: localLoad.keyword_text || '',
                selector_2: '',
                like_mem_key: null,
                offset: 0,
                limit: 20,
            }),
        );
        dispatch(setActiveSegKey(boardListState?.board_chk?.dsp_meth === '1' ? 'List' : 'Card'));
        setInitialized(true);
    }, []);

    useEffect(() => {
        if(initialized && boardListState.query){
            getBoardList(boardListState.query);
        }
    }, [initialized, boardListState.query]);

    // 그리드 데이터 업데이트
    useEffect(() => {
        if (boardGridRef.current && boardListState.items?.length > 0 && !boardListState.isLoading) {
            boardGridRef.current.setGridData(boardListState.items);
        }
        if(boardListState.items?.length > 20 && boardListState?.activeSegKey === 'List') {
            boardGridRef.current.setRowPosition(boardListState.yScrollPosition);
        }
    }, []);

    useEffect(() => {
        // 게시글 제목 클릭 핸들러
        if (!window.$agRendererTemplate) {
            window.$agRendererTemplate = {};
        }
        window.$agRendererTemplate.handleTitleClick = handleTitleClick;

        if (boardListState.activeSegKey === 'List' && boardGridRef.current) {
            boardGridRef.current.bind('cellClick', (event) => {
                const gridData = boardGridRef.current.getGridDataWithState('state');
                const selectedRowData = gridData[event.rowIndex];
                handleTitleClick(selectedRowData);
            });
        }
        return () => {
            if (boardGridRef.current) {
                boardGridRef.current.unbind('cellClick');
            }
        };
    }, [boardListState.activeSegKey, handleTitleClick]);

    useEffect(() => {
        if (boardListState?.query?.keyword_type) {
            form.setFieldsValue({
                keyword_type: boardListState.query.keyword_type,
                keyword_text: boardListState.query.keyword_text || ''
            });
        }
    }, [boardListState?.query?.keyword_type, boardListState?.query?.keyword_text, form]);

    const renderSearch = () => {
        return (
            <>
                <Form form={form} onFinish={handleSearchBoard}>
                    <Form.Item name="keyword_type" className="select" initialValue={boardListState.query.keyword_type}>
                        <Select options={boardSearch} />
                    </Form.Item>
                    <Form.Item name="keyword_text" className="inputText">
                        <Input placeholder="검색어를 입력해주세요." />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">
                        검색
                    </Button>
                    {width < 700 && (
                        <>
                            <Button className="icon delete" htmlType="close" onClick={() => setMobileSearch(false)}>
                                삭제
                            </Button>{' '}
                        </>
                    )}
                    {/*버튼 클릭시, search 영역 display:none*/}
                    <Form.Item name="selector_2" style={{ display: 'none' }}>
                        <Select options={[{ value: '', label: '전체' }]} />
                    </Form.Item>
                </Form>
            </>
        );
    };

    // List 컴포넌트
    const ListComponent = useCallback(
        () => <Table ref={boardGridRef} columnLayout={boardColumnLayout} customGridProps={boardGridProps} onRowClick={handleTitleClick} />,
        [boardColumnLayout, boardGridProps, handleTitleClick],
    );

    // Card 컴포넌트
    const CardComponent = useCallback(() => {
        const scrollPositionRef = useRef(0);
        const handleCardScroll = () => {
            if (!cardContainerRef.current) return;
            const { scrollTop, clientHeight, scrollHeight } = cardContainerRef.current;
            scrollPositionRef.current = scrollTop;

            // 스크롤이 하단에서 50px 이내로 왔을 때 추가 데이터 로드
            if (scrollHeight - scrollTop - clientHeight < 50) {
                const currentDataLength = boardListState.items.length;
                if (currentDataLength < boardListState.totalCount) {
                    const newQuery = {
                        ...boardListState.query,
                        keyword_type: boardListState.query.keyword_type,
                        keyword_text: boardListState.query.keyword_text || '',
                        offset: currentDataLength,
                        limit: 20,
                    };

                    handleDebounce(() => {
                        dispatch(setIsLoading(true));
                        getBoardList(newQuery).finally(() => {
                            // 데이터 로드 후 저장된 스크롤 위치로 복원
                            if (cardContainerRef.current) {
                                cardContainerRef.current.scrollTop = scrollPositionRef.current;
                                setTimeout(() => {
                                    dispatch(setIsLoading(false));
                                }, 300);
                            }
                        });
                    });
                }
            }
        };

        // 데이터가 변경될 때 스크롤 위치 복원
        useEffect(() => {
            const container = cardContainerRef.current;
            if (container && scrollPositionRef.current > 0) {
                container.scrollTop = scrollPositionRef.current;
            }
        }, [boardListState.items]);

        useEffect(() => {
            const container = cardContainerRef.current;
            if (container) {
                container.addEventListener('scroll', handleCardScroll);
                return () => container.removeEventListener('scroll', handleCardScroll);
            }
        }, [boardListState.items.length, boardListState.totalCount, boardListState.query]);

        return (
            <div className="cardArea" ref={cardContainerRef} style={{ overflowY: 'auto' }}>
                <div className="listBox">
                    <List
                        grid={{ gutter: 16, column: 4 }}
                        dataSource={boardListState.items}
                        renderItem={(item) => (
                            <List.Item key={item.doc_no}>
                                <Card
                                    className="cardThum"
                                    onClick={() => { window.$agRendererTemplate.handleTitleClick(item); }}
                                    cover={
                                        <>
                                            <img src={item.thumnail || ""}
                                                 alt="썸네일"
                                                 style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                 onError={(e) => { e.target.style.display = 'none'; }}
                                            />{' '}
                                            <p>{item.title}</p>
                                        </>
                                    }
                                >
                                    <div className="ThumInfo">
                                        <p className="goodCount">{item.like_cnt}</p>
                                        <p className="viewCount">{item.view_cnt}</p>
                                        {boardListState.board_chk?.reply_yn === 'Y' && <p className="repleCount">{item.comment_cnt}</p>}
                                    </div>
                                </Card>
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        );
    }, [boardListState.items, boardListState.totalCount, getBoardList, boardListState.query, handleDebounce, dispatch, boardListState.board_chk]);

    useEffect(() => {
        if (boardListState.activeSegKey === 'List') {
            if (width > 700) {
                boardGridRef.current.showColumnByDataField('view_cnt');
                boardGridRef.current.setColumnPropByDataField('like_cnt', {headerText: '좋아요'})
                if(boardListState?.board_chk?.write_lv !== 'PLAN999'){
                    boardGridRef.current.setColumnSizeList([null, null, '5%', '60%', '10%', '5%', '5%', '5%'])
                }else{
                    boardGridRef.current.setColumnSizeList([null, null, '5%', '60%', null, '15%', '10%', '10%'])
                }
            } else {
                boardGridRef.current.hideColumnByDataField('view_cnt');
                boardGridRef.current.setColumnPropByDataField('like_cnt', {headerText: '<span class="gridRight">좋아요</span>'})
                if(boardListState?.board_chk?.write_lv !== 'PLAN999'){
                    boardGridRef.current.setColumnSizeList([null, null, '10%', '50%', '20%', '10%', '10%', null])
                }else{
                    boardGridRef.current.setColumnSizeList([null, null, '10%', '50%', null, '20%', '20%', null])
                }
            }
        }
    }, [width]);

    return (
        <>
            <Loading isLoading={boardListState.isLoading} />
            <article className="flexColCenter" id="infoWrapper">
                <Banner bannerCd="BANNER016" bannerTp="Top" />
                <div className="flexRowCenter title">
                    <div className="titleBox flexRowBetween">{boardListState.board_chk?.board_title || '게시판 제목이 없습니다.'}</div>
                    {/* 검색 */}
                    {width > 700 && <div className="searchBox">{renderSearch()}</div>}
                </div>
                <div className="boardWrapper rsWrapper flexRowStart">
                    <Layout>
                        <div className="searchBox flexRowBetween">
                            <span className="result">
                              {/*{localStorage.getItem(`boardSearchCondition_${boardListState.board_chk.board_no}`) !== null ?*/}
                                  <div className="searchCount">
                                      검색결과{' '}
                                      <span>
                                        <span>총 {boardListState.totalCount}</span> 개
                                      </span>
                                  </div>
                              {/*    : ''}*/}
                            </span>
                            <span className="btn">
                                <button className="btn-search" onClick={() => setMobileSearch(true)}></button>
                                {mobileSearch && (
                                    <div className="search" style={{ display: 'block' }}>
                                        {width < 700 && renderSearch()}
                                    </div>
                                )}
                                {(auth?.plan_cd !== '' && parseInt(boardListState?.board_chk?.write_lv.slice(-3)) <= parseInt(auth?.plan_cd.slice(-3))) || auth?.mem_auth === 'R002' ? (
                                    <Button className="btn-dark" onClick={handleWriteClick}>
                                        작성
                                    </Button>
                                ) : null}
                            </span>
                        </div>
                        <Content>{boardListState.activeSegKey === 'List' ? <ListComponent /> : <CardComponent />}</Content>
                    </Layout>
                </div>
                <Banner bannerCd="BANNER017" bannerTp="Bottom" />
            </article>
        </>
    );
};

export default React.memo(BoardList);
