import React from 'react';

const IconRank = ({ rankValue = 0 }) => {
  // 랭킹에 따라 클래스명 가져오기
  const getClassNameRank = (rank) => {
    if (rank === 1) return 'promptRank1';
    if (rank === 2) return 'promptRank2';
    if (rank === 3) return 'promptRank3';
    if (rank <= 100) return 'promptRank4';
    return '';
  };

  return (
    <>
      {rankValue > 0 && (
        <div className={`promptRankIcon ${getClassNameRank(rankValue)}`}>
          <span className="rank-value">{rankValue}</span>
        </div>
      )}
    </>
  );
};

export default IconRank;
