import { restApi, querystring } from './config';

// 공통 그룹코드 조회
export const fetchCommonGroupCode = (data) => {
  return restApi.post('/api/common/fetchCommonGroupCode', data);
};
export const fetchEqualCommonGroupCode = (data) => {
  return restApi.post('/api/common/fetchEqualCommonGroupCode', data);
};
export const fetchEqualCommGrpCodeByParent = (data) => {
  return restApi.post('/api/common/fetchEqualCommGrpCodeByParent', data);
};
export const fetchEqualAiModelByCate = (data) => {
  return restApi.post('/api/common/fetchEqualAiModelByCate', data);
};
// 공통 상세코드 조회
export const fetchCommonDetailCode = (data) => {
  return restApi.post('/api/common/fetchCommonDetailCode', data);
};
// 공통 상세코드 전체 조회
export const fetchCommonAllDetailCode = (data) => {
  return restApi.post('/api/common/fetchCommonAllDetailCode', data);
};
// 공통코드 저장
export const saveCommonCode = (data) => {
  return restApi.post('/api/common/saveCommonCode', data);
};
// 공통코드 다수 조회
export const fetchCodesByGroupCodes = (data) => {
  return restApi.post('/api/common/fetchCodesByGroupCodes', data);
};

// 좋아요 수
export const increaseLikeCount = async (data) => {
  return await restApi.post('/api/like/likeCnt', data);
};
// 좋아요 리스트
export const likeList = async (data) => {
  return await restApi.get('/api/like/likeList', data);
};
/** 알림 관리 */
// 알림 정보 조회
export const getAlarmInfo = () => {
  return restApi.get('/api/admin/alarm/getAlarmInfo');
};
// 알림 정보 저장
export const saveAlarmInfo = (data) => {
  return restApi.post('/api/admin/alarm/saveAlarmInfo', data);
};
// 채팅방 조회
export const rooms = (data) => {
  return restApi.get('/api/chat/rooms', data);
};
// 메세지 조회
export const messages = (data) => {
  return restApi.get('/api/chat/message', data);
};
// 메세지 입력
export const sendMessage = (data) => {
  return restApi.post('/api/chat/sendMessage', data);
};
// 메세지 확인
export const checkMessage = (data) => {
  return restApi.post('/api/chat/checkMessage', data, { headers: { 'Content-Type': 'application/json' } });
};
export const blockMember = (data) => {
  return restApi.post('/api/chat/blockMember', data, { headers: { 'Content-Type': 'application/json' } });
};
export const closeRoom = (data) => {
  return restApi.post('/api/chat/closeRoom', data, { headers: { 'Content-Type': 'application/json' } });
};
export const delMessage = (data) => {
  return restApi.post('/api/chat/delMessage', data, { headers: { 'Content-Type': 'application/json' } });
};

export const uploadMessage = (data) => {
  return restApi.post('/api/chat/uploadMessage', data);
};
export const userProfile = (data) => {
  return restApi.get('/api/userProfile', data);
};

/** 장바구니 */
// 장바구니 조회
export const getCartList = (data) => {
  return restApi.get('/api/cart/getCartList', querystring(data));
};
// 장바구니 삭제
export const deleteCart = (data) => {
  return restApi.post('/api/cart/deleteCart', data);
};
// 장바구니 결제
export const paymentCart = (data) => {
  return restApi.post('/api/payment/goodsPayment', data);
};
//결제 정보 삭제
export const deleteMemberPayment = (data) => {
  return restApi.post('/api/common/payment/deleteMemberPayment', data);
};
//선택된 장바구니 아이템 삭제
export const deleteSelectedCartItems = (data) => {
  return restApi.post('/api/cart/deleteSelectedCartItems', data);
};
//선택된 장바구니 아이템 삭제 (gds_key)
export const deleteCartItemByKey = (data) => {
  return restApi.post('/api/cart/deleteCartItemByKey', data);
};

//SMS전송
export const sendSMS = (data) => {
  return restApi.post('/api/sendSMS', data);
};

// 알림 전송
export const sendAlertContents = (data) => {
  return restApi.post('/api/alert/sendAlertContents', data);
};

/**결제 완료 후 알림 전송 */
export const sendPaymentCompleteAlert = (data) => {
  return restApi.post('/api/alert/sendPaymentCompleteAlert', data);
};


/* Batch List 가져오기 */
export const getBatchList = (data) => {
  return restApi.get('/api/admin/batchList', data);
};


/* Batch 실행 */
export const runBatch = (data) => {
  return restApi.post('/api/admin/runBatch', data);
};

/* session check */
export const sessionCheck = (data) => {
  return restApi.post('/api/sessionCheck', data);
};

/* session check */
export const logout = (data) => {
  return restApi.post('/api/logout');
};

/* header icon check */
export const iconCheck = (data) => {
  return restApi.get('/api/chat/iconCount', data);
}