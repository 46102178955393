import React, { useRef, useState, useEffect } from 'react';
import { Form, Modal, Input, Select } from 'antd';
import Draggable from 'react-draggable';
// icon
import { CloseOutlined } from '@ant-design/icons';
// lib
import { formatEncodingDate, handleImageError } from '@utils/lib';
import { IMAGES } from '@utils/image';

const ListReview = ({ isOpen, onClose, promptInfo, reviewList }) => {
  return (
    <Modal open={isOpen} footer={null} closable={false} width={`800px`}>
      <aticle id="reviewModal" className="flexColCenter">
        <div className="reviewTitle flexRowBetween">
          <h1>리뷰</h1>
          <p onClick={onClose}>
            <CloseOutlined />
          </p>
        </div>

        <div className="reviewBox flexRowStart">
          <h2>{promptInfo?.mem_nick}에 대한 리뷰</h2>
          {/* <h3>{Math.round(promptInfo?.score_avg * 100) / 100 || 0}</h3>
          <Rate value={promptInfo?.score_avg} />
          <p>({reviewList.length})</p> */}
        </div>

        <div className="listBox flexColCenter scroll">
          <div className="listScroll flexColCenter">
            {reviewList.map((review) => (
              <div className="list flexRowBetween" key={review?.seq}>
                <div className="userReview flexRowStart">
                  <img src={review?.score_mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />

                  <div className="flexColStart">
                    <div className="top flexRowBetween">
                      <p>{review?.score_mem_nick}</p>
                    </div>

                    <div className="rateBox flexRowStart">
                      {/* <Rate value={review?.score} />
                      <div className="line"></div> */}
                      <p>{formatEncodingDate(review?.fir_dt)}</p>
                    </div>

                    <p className="content">{review?.contents}</p>
                  </div>
                </div>
                {review?.re_cnt > 0 && (
                  <div className="answer flexColStart">
                    <div className="flexRowStart">
                      <h1>{review?.re_mem_nm}</h1>
                      <p>{formatEncodingDate(review?.rep_fir_dt)}</p>
                    </div>

                    <p>{review?.rep_contents}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </aticle>
    </Modal>
  );
};

export default ListReview;
