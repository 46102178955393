import React, { useEffect, useRef } from 'react';
import Table from '@components/Table';
import closeIcon from '@assets/images/common/close_icon.png';
import { useNavigate } from 'react-router-dom';
import { PROFILE_PAGE } from '@routes/pathName';
import { IMAGES } from '@utils/image';

const FollowModal = ({ visible, onClose, type, data, anchorRef }) => {
  const followGridRef = useRef();
  const modalRef = useRef(null);
  const navigate = useNavigate();

  const followGridProps = {
    showRowCheckColumn: false,
    showRowNumColumn: false,
    editable: false,
    selectionMode: 'single',
    autoGridHeight: true,
    // showHeader: false,
  };

  const followColumnLayout = [
    { dataField: 'mem_key', headerText: '멤버키', width: '30%', visible: false },
    {
      dataField: 'mem_img_path',
      headerText: '프로필',
      width: '100%',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value) => {
        let rowData = followGridRef.current.getGridData()[rowIndex];
        console.log(rowData)
        let memNick = rowData?.mem_nick;
        return (
            `<div>
                <img src="${value || IMAGES.DEFAULT_PROFILE}" 
                          alt="profile" 
                          style="width: 25px; height:25px; max-width:100%; border-radius:50%; vertical-align: middle; margin-right: 8px;" 
                          onerror="this.onerror=null; this.src='${IMAGES.DEFAULT_PROFILE}';">
                <span>${memNick}</span>
            </div>`
        )
      }
    },
    /*{ dataField: 'mem_nick', headerText: '닉네임', width: '75%' },*/
    /*{ dataField: 'mem_email', headerText: '이메일', width: '45%'  },*/
  ];

  if (!window.$agRendererTemplate) {
    window.$agRendererTemplate = {};
  }

  window.$agRendererTemplate.handleTitleClick = async (memEmail, memKey) => {
    navigate(`${PROFILE_PAGE}`, {
      state: {
        mem_email: memEmail,
        mem_key: memKey,
      },
    });
  };

  useEffect(() => {
    if (followGridRef.current && data) {
      followGridRef.current.setGridData(data);
    }
  }, [data]);

  useEffect(() => {
    if (followGridRef.current && visible) {
      followGridRef.current.unbind('cellClick');
      followGridRef.current.bind('cellClick', (event) => {
        const gridData = followGridRef.current.getGridDataWithState('state');
        const selectedRowData = gridData[event.rowIndex];
        if (selectedRowData) {
          window.$agRendererTemplate.handleTitleClick(selectedRowData.mem_email, selectedRowData.mem_key);
        }
        onClose();
      });
    }
  }, [visible, data]);

  // 모달 외부를 클릭 했을 때 모달을 닫는 기능
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // 외부 클릭시 모달 닫기
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    visible && (
      <div className="modalFol" style={{ display: visible ? 'block' : 'none' }} onClick={onClose} ref={modalRef}>
        <div
          className="modal-content"
          onClick={(e) => e.stopPropagation()} // 모달 안을 클릭해도 닫히지 않도록 처리
        >
          <div className="title">
            <h2>{type === 'follower' ? '팔로워 목록' : '팔로잉 목록'}</h2>
            <img src={closeIcon} alt="close" style={{ cursor: 'pointer', width: '16px', height: '16px' }} onClick={onClose} />
          </div>
          <div className="data">
            <Table
              ref={followGridRef}
              columnLayout={followColumnLayout}
              customGridProps={followGridProps}
              style={{ width: '100%', overflow: 'hidden' }} // 그리드가 모달 안에 맞게 표시
            />
          </div>
        </div>
      </div>
    )
  );
};

export default FollowModal;
