import { createSlice } from '@reduxjs/toolkit';

// 초기 상태 정의
export const INIT_STATE_BOARD_LIST = {
    query: {
        keyword_type: '1',
        keyword_text: '',
        board_no: null,
        selector_2: '',
        like_mem_key: null,
        offset: 0,
        limit: 20,
        board_title: '',
        dsp_meth: '',
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
    thumbnail: [],
    likedStatus: {},
    isLoading: false,
    activeSegKey: '',
    board_chk: {},
    board_detail: {},
    tabId: '',
    yScrollPosition: 0,
};

// Redux slice 생성
export const boardListSlice = createSlice({
    name: 'boardList',
    initialState: INIT_STATE_BOARD_LIST,
    reducers: {
        // 검색 조건 설정
        setQuery: (state, action) => {
            state.query = action.payload;
        },
        // 게시글 목록 설정
        setBoardList: (state, action) => {
            state.items = action.payload.items;
            state.totalCount = action.payload.totalCount;
            state.currentCount = action.payload.items.length;
        },
        // 좋아요 상태 설정
        setLikedStatus: (state, action) => {
            state.likedStatus = action.payload;
        },
        // 로딩 상태 설정
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        // 현재 활성화된 뷰 모드 (리스트/카드) 변경
        setActiveSegKey: (state, action) => {
            state.activeSegKey = action.payload;
        },
        // 게시판 목록 설정
        setBoardCheck: (state, action) => {
            state.board_chk = action.payload;
        },
        // 게시판 tab 설정
        setBoardDetail: (state, action) => {
            state.board_detail = action.payload;
        },
        // 게시판 tab 설정;
        setTabId: (state, action) => {
            state.tabId = action.payload;
        },
        // 스크롤 위치 저장
        setYScrollPosition: (state, action) => {
            state.yScrollPosition = action.payload;
        },
        // 좋아요 토글
        toggleLikeStatus: (state, action) => {
            const docNo = action.payload;
            state.likedStatus = {
                ...state.likedStatus,
                [docNo]: !state.likedStatus[docNo]
            };
        },
        // 상태 초기화
        clearBoardList: () => INIT_STATE_BOARD_LIST,
    },
});

// 액션 및 리듀서 내보내기
export const {
    setQuery,
    setBoardList,
    setLikedStatus,
    setIsLoading,
    setActiveSegKey,
    setBoardCheck,
    setBoardDetail,
    setTabId,
    setYScrollPosition,
    toggleLikeStatus,
    clearBoardList
} = boardListSlice.actions;

export default boardListSlice.reducer;
