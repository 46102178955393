import React, { useEffect, useRef } from 'react';

const ContentAdmin = ({ ContentBody }) => {
  const containerRef = useRef(null);

  const handleScroll = () => {
    const elements = document.querySelectorAll('.aui-grid-drop-list-item-wrapper');
    elements.forEach((element) => element.remove());
  };

  //AUI 그리드 내부의 select box가 전체 화면 스크롤 시 제거되지 않아 이벤트 추가
  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
      // 클린업 함수
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return <div ref={containerRef} className="s-container admin">{ContentBody}</div>;
};

export default ContentAdmin;
