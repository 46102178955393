import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Select, Input, Form, message, Button } from 'antd';
import * as api from "@api/index";
import { getLocalStorage, makeSelectBoxList } from "@utils/lib";

const ComplaintModal = ({ isOpen, onClose, params }) => {
    const memKey = getLocalStorage('prptbk-token', 'mem_key');
    const [badRsnList, setBadRsnList] = useState([]); // 신고하기 공통코드
    const [form] = Form.useForm();
    const [isButtonEnabled, setIsButtonEnabled] = useState(false); // 버튼 활성화 상태

    const fetchCommonCode = useCallback(async () => {
        try {
            const response = await api.fetchCommonDetailCode({ grp_cd: 'BAD_RSN' });
            setBadRsnList(makeSelectBoxList(response?.data, 'cd_desc', 'cd'));
        } catch (error) {
            message.warning('공통코드 조회 중 오류가 발생했습니다.');
        }
    }, []);

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            Modal.confirm({
                title: '신고하시겠습니까?',
                content: (
                    <>
                        해당 게시물을 신고하시겠습니까? <br />
                        신고 후에는 취소할 수 없습니다.
                    </>
                ),
                icon: null,
                maskClosable: true,
                style: { top: '20%' },
                footer: (
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px'}}>
                        <Button type="primary" onClick={async () => {
                            try {
                                const query = {
                                    target_id: params.target_id,
                                    bad_mem_key: memKey,
                                    own_mem_key: params.own_mem_key,
                                    bad_div: params.bad_div,
                                    bad_cd: values.bad_cd,
                                    contents: values.contents,
                                };

                                await api.addPromptBad(query);
                                message.success('신고가 접수되었습니다.');
                                onClose();
                                form.resetFields();
                                Modal.destroyAll(); // ✅ 모든 모달 닫기
                            } catch (error) {
                                message.error('신고 처리 중 오류가 발생했습니다.');
                            }
                        }}>
                            확인
                        </Button>
                        <Button onClick={() => Modal.destroyAll()} style={{marginBottom: '15%'}}>취소</Button>
                    </div>
                ),
            });
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const handleFormChange = () => {
        const values = form.getFieldsValue();
        const isValid = values.bad_cd && values.contents?.trim();
        setIsButtonEnabled(!!isValid);
    };

    useEffect(() => {
        fetchCommonCode();
    }, []);

    useEffect(() => {
        form.resetFields(['bad_cd', 'contents']);
        setIsButtonEnabled(false); // 모달 열릴 때 버튼 초기화
    }, [isOpen]);

    return (
        <Modal
            title="신고하기"
            open={isOpen}
            onCancel={onClose}
            footer={[
                <div className="flexWrapCenter" key="footer" style={{ gap: '10px' }}>
                    <Button
                        id="greyBtn"
                        className="btn-grad"
                        onClick={handleSubmit}
                        /*disabled={!isButtonEnabled}*/
                        style={{ background:"linear-gradient(90deg, #b041f3 0%, #ec15ff 100%)", borderRadius: "4px" }}
                    >
                        신고하기
                    </Button>
                    <button className="btn-white" onClick={onClose}>
                        취소
                    </button>
                </div>
            ]}
        >
            <article id="reviewModal" className="flexColCenter">
                <Form
                    noStyle
                    form={form}
                    name="badRsn"
                    layout="vertical"
                    autoComplete="off"
                    scrollToFirstError
                    style={{ width: '100%' }}
                    onValuesChange={handleFormChange}
                >
                    <Form.Item
                        name="bad_cd"
                        label="신고 사유 선택"
                        rules={[{ required: true, message: '신고 사유를 선택해주세요.' }]}
                    >
                        <Select placeholder="사유 선택" options={badRsnList} />
                    </Form.Item>
                    <Form.Item
                        name="contents"
                        label="신고 사유 상세"
                        rules={[{ required: true, message: '신고 사유 상세 내용을 입력해주세요.' }]}
                    >
                        <Input.TextArea rows={6} showCount maxLength={100} style={{height:'auto'}}/>
                    </Form.Item>
                </Form>
            </article>
        </Modal>
    );
};

export default ComplaintModal;