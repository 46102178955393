import React, { useCallback, useState, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { message, Skeleton } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
// icon
import cartIcon from '@assets/images/common/cart_icon.png';
import cartUpdateIcon from '@assets/images/common/cart_update_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';
// component
import Banner from '@components/slider/Banner';
import Filter from '@components/FilterSubMain';
import FilterMobile from '@components/FilterMobileSubMain';
import SubFilterSubMain from '@components/SubFilterSubMain';
import SearchSubMain from '@components/SearchSubMain';
import ButtonGroupSubMain from '@components/ButtonGroupSubMain';
import CardPrompt from '@pages/marketplaces/CardPrompt';
// lib
import {useDebounce, validateSession, getLocalStorage, useCheckIcons} from '@utils/lib';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';
// const
import { subMainSortList, AUTH_LOGIN } from '@utils/constants';
// redux
import { setQuery, setMarketplaceTextList, setLocationKey, setYScrollPosition, INIT_STATE_MARKETPLACE_TEXTLIST, clearMarketplaceTextList } from '@stores/marketplaceTextList';
import { triggerIconRefresh } from '@stores/icon';
const TextHome = (props) => {
  const { key: locationKey } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const [isActiveFilterMobile, setIsActiveFilterMobile] = useState(false);
  const [aiModelList, setAiModelList] = useState(); // AI 모델 리스트
  const [genreList, setGenreList] = useState(); // 장르 리스트
  const [subGenreList, setSubGenreList] = useState(); // 서브장르 리스트
  // 체크된 텍스트 리스트
  const [checkedTextList, setCheckedTextList] = useState([]);
  // 체크된 텍스트 리스트 총 갯수
  const [totalCheckedTextList, setTotalCheckedTextList] = useState(0);
  const dispatch = useDispatch();
  const marketplaceTextList = useSelector((s) => s.marketplaceTextList, shallowEqual);
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const [visible, setVisible] = useState();
  // 장바구니 삭제 관련 갱신 소스
  const {checkList, setCheckList} = useCheckIcons();
  useEffect(() => {
    const newFilterParams = { ...marketplaceTextList.query, offset: 0 };
    dispatch(setQuery(newFilterParams));
    handleDebounce(() => listText(newFilterParams));
    setCheckList(false);
  }, [checkList]);

  // PC용 필터 토글
  const filterToggle = useCallback(() => {
    setIsActiveFilter(!isActiveFilter);
  }, [isActiveFilter]);

  // 모바일용 필터 토글
  const filterToggleMobile = useCallback(() => {
    setIsActiveFilterMobile(!isActiveFilterMobile);
  }, [isActiveFilterMobile]);

  // 선택한 필터 변경
  const changeFilterParams = useCallback(
    (key, value) => {
      // key 값 유효성 체크
      if (!['filter_sort', 'filter_model', 'filter_genre', 'filter_sub_genre', 'filter_text', 'staff_pick'].includes(key)) return;

      // 선택한 필터 값 변경
      const newFilterParams = { ...marketplaceTextList.query, offset: 0 };
      newFilterParams[key] = value;
      // filter_genre 값이 바뀌면, filter_sub_genre 초기화
      if (key === 'filter_genre') newFilterParams['filter_sub_genre'] = 'all';
      dispatch(setQuery(newFilterParams));
      handleDebounce(() => listText(newFilterParams));
    },
    [marketplaceTextList.query],
  );

  // 툴팁 텍스트
  const getTooltipText = (text) => {
    const chk = text?.chk;
    return chk === 'owner' ? '회원님의 프롬프트 입니다.' : chk === 'cart' ? '이미 장바구니에 있습니다.' : chk === 'purch' ? '이미 구매한 프롬프트 입니다.' : null;
  };

  // 체크 여부
  const isChecked = (text) => {
    const existed = checkedTextList?.filter((checkedText) => checkedText?.prpt_id === text?.prpt_id);
    return existed.length > 0;
  };

  const isVisible = (image) => {
      return visible == image.prpt_id;
  }

  const handleMouseOver = (text)  => {
    const chk = text?.chk;
    if (chk != 'pass'){
        setVisible(text?.prpt_id);
        setTimeout(() => setVisible(''), 1500);
    }
  }

 /* const handleMouseLeave = (text)  => {
    setVisible('');
 } */

  // 체크 박스 한개 토글
  const onCheckOne = (checked, text) => {
    const chk = text?.chk;
    if (chk != 'pass'){
        setVisible(text?.prpt_id);
        setTimeout(() => setVisible(''), 1500);
        return false;
    }

    const existed = checkedTextList?.filter((checkedText) => checkedText?.prpt_id === text?.prpt_id);
    if (checked && !existed.length) {
      setCheckedTextList([...checkedTextList, text]);
      setTotalCheckedTextList(totalCheckedTextList + 1);
    }
    if (!checked && existed.length) {
      const removed = checkedTextList?.filter((checkedText) => checkedText?.prpt_id !== text?.prpt_id);
      setCheckedTextList(removed);
      setTotalCheckedTextList(totalCheckedTextList - 1);
    }
  };

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 사용자 프로필 페이지 이동
  const moveToProfile = async (text, pageY) => {
    try {
      // 해당 프롬프트의 스크롤 위치 저장
      dispatch(setYScrollPosition(pageY));
      moveToPage(PATH.PROFILE_PAGE, { mem_email: text?.mem_email, mem_key: text?.mem_key });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (text, pageY) => {
    try {
      // 해당 프롬프트의 스크롤 위치 저장
      dispatch(setYScrollPosition(pageY));
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: text?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: text?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 판매하기 페이지 이동
  const moveToSalePage = async (pageY) => {
    try {
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        // 해당 프롬프트의 스크롤 위치 저장
        dispatch(setYScrollPosition(pageY));
        moveToPage(PATH.SALES_PROMPT_HOME, { cate_cd: 'CATE004' });
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // AI 모델 리스트 조회
  const listAiModel = async () => {
    try {
      const { data } = await api.getAiModelByCate({ cate_cd: 'CATE004' });
      setAiModelList([{ ai_model_cd: 'all', ai_model_nm: '전체' }, ...data]);
    } catch (error) {
      console.error('AI 모델 리스트 조회 error', error);
    }
  };

  // 장르 리스트 조회
  const listGenre = async () => {
    try {
      const { data } = await api.fetchEqualCommGrpCodeByParent({ grp_cd: 'GENRE', parent_cd: 'CATE004' });
      setGenreList([{ cd: 'all', cd_nm: '전체' }, ...data]);
    } catch (error) {
      console.error('장르 리스트 조회 error', error);
    }
  };

  // 서브 장르 리스트 조회
  const listSubGenre = useCallback(async () => {
    try {
      if (marketplaceTextList.query?.filter_genre === 'all') return setSubGenreList();
      const { data } = await api.fetchEqualCommGrpCodeByParent({ grp_cd: 'SUB_GENRE', parent_cd: marketplaceTextList.query?.filter_genre });
      setSubGenreList([{ cd: 'all', cd_nm: '모두보기' }, ...data]);
    } catch (error) {
      console.error('서브 장르 리스트 조회 error', error);
    }
  }, [marketplaceTextList.query?.filter_genre]);

  // 장바구니 곡 추가
  const addCartText = useCallback(async (texts = []) => {
    try {
      if (!texts.length) return message.warning('텍스트를 선택해주세요.');
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        const requestTexts = texts?.map((text) => {
          return {
            mem_key: memKey,
            gds_key: text?.prpt_id,
            gds_nm: text?.prpt_title,
            gds_amt: text?.sale_amt,
            gds_url: text?.thum_path,
            gds_div: 'GDS003',
          };
        });
        const { data } = await api.addToCart(requestTexts);
        if (data?.returnStatus !== 'success') throw new Error(data.returnMessage);
        setCheckedTextList([]);
        setTotalCheckedTextList(0);

        message.success('장바구니에 저장되었습니다.');

        handleDebounce(() => listText(marketplaceTextList.query));
      }
    } catch (error) {
      message.warning(error.message);
    } finally {
      dispatch(triggerIconRefresh(true));
    }
  }, []);

  // 텍스트 리스트 가져오기
  const listText = useCallback(
    async (query) => {
      try {
        const mergedQuery = { ...marketplaceTextList.query, ...query, filter_stat: '30' };
        const { data } = await api.getAllPromptList(mergedQuery);
        if (!data) return;
        if (query.offset) {
          dispatch(
            setMarketplaceTextList({
              items: [...marketplaceTextList.items, ...data.items],
              totalCount: data.total,
              currentCount: marketplaceTextList.currentCount + data.items.length,
            }),
          );
        } else {
          dispatch(
            setMarketplaceTextList({
              items: data.items,
              totalCount: data.total,
              currentCount: data.items.length,
            }),
          );
        }
        setCheckedTextList([]);
        setTotalCheckedTextList(0);
        dispatch(setLocationKey(locationKey));
      } catch (error) {
        message.warning(error.message);
      }
    },
    [marketplaceTextList],
  );

  // 스크롤 페이징
  const handleScroll = () => {
    const newQuery = { ...marketplaceTextList.query, offset: marketplaceTextList.query.offset + marketplaceTextList.query.limit };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listText(newQuery));
  };

  // 새로고침 이벤트 발생시
  const preventReload = () => {
    dispatch(clearMarketplaceTextList());
    navigate(PATH.TEXT_HOME, { replace: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventReload);

    // 뒤로가기로 렌더링 됐을때
    if (locationKey === marketplaceTextList.locationKey) {
      window.scrollTo({ top: marketplaceTextList.yScrollPosition, behavior: 'smooth' });
    } else {
      const newQuery = { ...INIT_STATE_MARKETPLACE_TEXTLIST.query, ...location?.state };
      dispatch(setQuery(newQuery));
      handleDebounce(() => listText(newQuery));
    }

    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, []);

  useEffect(() => {
    listAiModel();
    listGenre();
  }, []);

  useEffect(() => {
    listSubGenre();
  }, [marketplaceTextList.query?.filter_genre]);

  return (
    <article id="otherWrapper" className="flexColCenter">
      <Banner bannerCd="BANNER013" bannerTp="Top" />

      <Filter isActive={isActiveFilter} toggle={filterToggle} filterParams={marketplaceTextList.query} setFilterParams={changeFilterParams} aiModelList={aiModelList} genreList={genreList} />

      <FilterMobile
        headerTitle="어떤 텍스트를 찾고 계신가요?"
        isActive={isActiveFilterMobile}
        toggle={filterToggleMobile}
        filterParams={marketplaceTextList.query}
        setFilterParams={changeFilterParams}
        aiModelList={aiModelList}
        genreList={genreList}
      />

      <div className="rsWrapper flexColCenter">
        <div className="flexRowBetween">
          <div className="content flexColCenter">
            <SearchSubMain
              defaultTitle="텍스트 리스트"
              placeholderSearch="텍스트를 검색해 주세요."
              subMainSortList={subMainSortList}
              aiModelList={aiModelList}
              genreList={genreList}
              subGenreList={subGenreList}
              marketplaceList={marketplaceTextList}
              setFilterParams={changeFilterParams}
              filterToggleMobile={filterToggleMobile}
            />

            <div className="searchBox flexRowBetween">
              <SubFilterSubMain sortList={subMainSortList} subGenreList={subGenreList} marketplaceList={marketplaceTextList} setFilterParams={changeFilterParams} />

              <ButtonGroupSubMain isSaleButton={validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: false })} onSale={(e) => moveToSalePage(e.clientY)} isCartButton={validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: false })} onCart={() => addCartText(checkedTextList)} />
            </div>

            <div id="listWrapper" className="flexColCenter promptList ">
              <div id="otherWrapper" className="flexColCenter">
                <div className="rsWrapper flexRowBetween paddingZero">
                  <div className="flexColCenter bottom newContents">
                    <InfiniteScroll
                      dataLength={marketplaceTextList.currentCount}
                      next={handleScroll}
                      hasMore={marketplaceTextList.currentCount < marketplaceTextList.totalCount}
                      style={{ width: '100%' }}
                      loader={
                        <>
                          <Skeleton active paragraph={{ rows: 4 }} />
                          <Skeleton active paragraph={{ rows: 4 }} />
                        </>
                      }
                    >
                      <div className="listBox flexRowStart staffList">
                        {marketplaceTextList.totalCount > 0 ? (
                          <>
                            {marketplaceTextList.items?.map((text, index) => (
                              <CardPrompt
                                key={index}
                                prompt={text}
                                moveToPromptDetailPage={(e) => moveToDetailPage(text, e.clientY)}
                                moveToUserDetailPage={(e) => moveToProfile(text, e.clientY)}
                                isActiveCheckbox={true}
                                tooltipText={getTooltipText(text)}
                                isDisabledCheckbox={text?.chk !== 'pass'}
                                isChecked={isChecked(text)}
                                visible={isVisible(text)}
                                onCheckOne={(e) => onCheckOne(e.target.checked, text)}
                                handleMouseOver={(e) => handleMouseOver(text)}
                                /* handleMouseLeave={(e) => handleMouseLeave(text)} */
                              />
                            ))}
                          </>
                        ) : (
                          <div className="noListData">
                            <h1>조회 결과가 없습니다.</h1>
                          </div>
                        )}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="mobileFixedBox" className="flexRowBetween">
        <div className="flexColCenter select">
          <p>
            <span>{totalCheckedTextList}</span>개 선택
          </p>
        </div>
        <div className="flexRowCenter">
          <img src={resetIcon} onClick={() => window.location.reload()} />
          <img src={cartUpdateIcon} onClick={() => moveToSalePage(window.scrollY)} />
          <img src={cartIcon} onClick={() => addCartText(checkedTextList)} />
        </div>
      </div>

      <Banner bannerCd="BANNER014" bannerTp="Bottom" />
    </article>
  );
};

export default TextHome;
