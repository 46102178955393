import React, {useEffect, useMemo, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { message } from 'antd';
import qs from 'qs';
import * as api from '@api/index';
import { setAuth, clearAuth } from '@stores/auth';
import * as PATH from '@routes/pathName';
import { isAdmin } from '@utils/lib';
import LoadingBar from "@components/LoadingBar";

const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const INIT_STATE = {
  provider: '',
  code: '',
};

const SocialLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useMemo(() => ({ ...INIT_STATE, ...qs.parse(search?.substring(1) || '') }), [search]);
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const [progress, setProgress] = useState(0); // 진행 상태 관리

  useEffect(() => {
    let progressInterval;

    const login = async () => {
      try {
        setLoading(true);
        setProgress(0);

        // 로딩 시간 지연 함수
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        progressInterval = setInterval(() => {
          setProgress((prev) => {
            if (prev < 95) {
              return prev + 1;
            }
            clearInterval(progressInterval);
            return prev;
          });
        }, 50);

        // 인위적인 지연 추가 (예: 2초)
        // await delay(2000);

        const { data } = await api.socialLogin(params);
        if (!data) navigate(PATH.LOGIN);
        // '1.가입대기' 상태이므로, 회원가입 화면으로 이동
        else if (data.mem_stat === '01') navigate(`${PATH.SIGNUP}?mem_email=${data.mem_email}&mem_key=${data.mem_key}&verified=${true}`);
        // '2.활성화' 또는 '3.탈퇴신청' 가입이 완료된 상태이므로, 로그인 처리 후, 메인 화면으로 이동
        else if (['02', '91'].includes(data.mem_stat)) {
          // 유저 프로필 가져오기
          const userProfile = await api.userProfile({ params: { mem_key: data.mem_key } });
          const parsedUserProfile = {
            ...userProfile?.data,
            follower_list: JSON.parse(userProfile?.data?.follower_list),
            following_list: JSON.parse(userProfile?.data?.following_list),
          };
          // 세션 정보 저장
          await Promise.all([localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(parsedUserProfile)), dispatch(setAuth(parsedUserProfile))]);
          /* if (isAdmin(data.mem_auth)) navigate(PATH.ADMIN_USER_LIST, { replace: true });
          else */
        }
      } catch (error) {
        message.warning(error.message);
        // 세션 정보 지우기
        localStorage.setItem(TOKEN_STORE_KEY, '{}');
        dispatch(clearAuth());
        navigate(PATH.LOGIN);
      } finally {
        clearInterval(progressInterval);
        setProgress(100);
        setTimeout(() => {
          setLoading(false);
          setProgress(0);
          navigate(PATH.MAIN, { replace: true });
        }, 500);

      }
    };
    if (params.provider && params.code) login();
    return () => clearInterval(progressInterval);

}, [params, params.provider, params.code]);

  return (
      <LoadingBar loading= {loading} progress={progress} />
  );

};

export default SocialLogin;
