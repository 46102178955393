import React, { useState, useCallback, useEffect } from 'react';
import { message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
// icon
import musicIcon3 from '@assets/images/common/music_icon3.png';
import pointerIcon from '@assets/images/common/pointer_icon.png';
import newIcon from '@assets/images/common/new_icon.png';
// component
import CardSquareImage3 from '@pages/main/CardSquareImage3';
// lib
import { useDebounce, validateSession } from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const ListStaffPickImage = ({ title, cateCd, filterCd, openPlayer, isHasMoreButton = true, labelMoreButton = '더보기' }) => {
  const navigate = useNavigate();
  const [promptList, setPromptList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // 타이틀 아이콘 정의
  const titleIcon = { staffPick: pointerIcon, new: newIcon }[filterCd] || musicIcon3;

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 더보기 클릭시
  const moveToSubMainPage = () => {
    if (!isHasMoreButton) return;

    let path;
    if (cateCd === 'CATE001') path = PATH.IMAGE_HOME;
    if (cateCd === 'CATE002') path = PATH.VIDEO_HOME;
    if (cateCd === 'CATE004') path = PATH.TEXT_HOME;

    moveToPage(path, { filter_sort: filterCd });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (prompt) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: prompt?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: prompt?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 프롬프트 리스트 가져오기
  const listPrompt = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        cate_cd: cateCd,
        keyword_tab: filterCd !== 'staffPick' && !!filterCd ? filterCd : '',
        staff_pick: filterCd === 'staffPick' ? 'Y' : '',
        filter_stat: '30',
        offset: 0,
        limit: 12,
      });
      if (!data) return;
      setPromptList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 비디오 플레이어 열기
  const onOpenVideoPlayer = async (video) => {
    try {
      if (!video) return;
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        // 해당 비디오 플레이수 카운트업
        await api.updatePromptView({ prpt_id: video?.prpt_id, view_type: 'P' });
        openPlayer(video);
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  useEffect(() => {
    handleDebounce(() => listPrompt());
  }, []);

  return (
    <div className="flexColCenter newContents">
      <div className="titleBox flexRowBetween">
        <div className="flexRowCenter title">
          <img src={titleIcon} alt="music_icon" />
          <p>{title}</p>
        </div>

        <div id="whiteBtn" className={`web ${isHasMoreButton ? '' : 'noMore'}`} onClick={() => moveToSubMainPage()}>
          {labelMoreButton}
        </div>
      </div>

      <div className="staffList flexRowStart">
        {isLoading ? (
          <>
            <Skeleton avatar active paragraph={{ rows: 4 }} />
            <Skeleton avatar active paragraph={{ rows: 4 }} />
            <Skeleton avatar active paragraph={{ rows: 4 }} />
          </>
        ) : (
          <>
            {promptList?.map((prompt) => (
              <CardSquareImage3
                data={prompt}
                openPlayer={onOpenVideoPlayer}
                moveToPromptDetailPage={() => moveToDetailPage(prompt)}
                moveToUserDetailPage={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: prompt?.mem_email, mem_key: prompt?.mem_key })}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ListStaffPickImage;
