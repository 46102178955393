import { createSlice } from '@reduxjs/toolkit';

export const INIT_STATE_USERLIST = {
  query: {
    keyword_type: 'mem_nick',
    keyword_text: '',
    keyword_plan: 'PLAN001',
    keyword_stat: '02',
    offset: 0,
    limit: 20,
  },
  items: [],
  totalCount: 0,
  currentCount: 0,
  locationKey: '',
  yScrollPosition: 0,
};

export const userListSlice = createSlice({
  name: 'userList',
  initialState: INIT_STATE_USERLIST,
  reducers: {
    clearUserList: () => INIT_STATE_USERLIST,
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setUserList: (state, action) => {
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.currentCount = action.payload.currentCount;
    },
    setLocationKey: (state, action) => {
      state.locationKey = action.payload;
    },
    setYScrollPosition: (state, action) => {
      state.yScrollPosition = action.payload;
    },
  },
});

export const { clearUserList, setQuery, setUserList, setLocationKey, setYScrollPosition } = userListSlice.actions;

export default userListSlice.reducer;
