import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// icon
import arrowUp from '@assets/images/common/i-upTop.svg';
import whiteArrowUp from '@assets/images/common/white_arrow_up.png';
import whiteCloseIcon from '@assets/images/common/white_close_icon.png';
import whiteMusicIcon from '@assets/images/common/white_music_icon.png';
import whiteImageIcon from '@assets/images/common/white_image_icon.png';
import whiteVideoIcon from '@assets/images/common/white_video_icon.png';
import whiteTextIcon from '@assets/images/common/white_text_icon.png';
import whiteMenuIcon from '@assets/images/common/white_menu_icon.png';
// path
import * as PATH from '@routes/pathName';

const FixedNav = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [menuFlag, setMenuFlag] = useState(false);

  const menuToggle = useCallback(() => {
    if (pathname === '/') setMenuFlag(!menuFlag);
  }, [menuFlag, pathname]);

  // 선택한 메뉴로 이동
  const moveToPage = (path) => {
    if (pathname === '/') document.getElementById(path)?.scrollIntoView({ behavior: 'smooth' });
    else navigate(path);
  };

  useEffect(() => {
    if (pathname !== '/') setMenuFlag(false);
  }, [pathname]);

  return (
    <section className="flexColEnd" id="section">
      {pathname === '/' ? (
        <>
          <article className={`up flexRowCenter ${menuFlag ? 'active' : ''}`} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            <div className="flexColCenter">
              <img src={menuFlag ? whiteArrowUp : arrowUp} alt="arrow_up" />
            </div>
          </article>

          <article className="relativeMenu flexColEnd" id={menuFlag ? 'active' : 'nothing'}>
            <div className="menu flexRowCenter" onClick={() => menuFlag && moveToPage(PATH.MUSIC_HOME)}>
              <p>뮤직</p>
              <div className="flexColCenter">
                <img src={whiteMusicIcon} alt="icon" />
              </div>
            </div>

            <div className="menu flexRowCenter" onClick={() => menuFlag && moveToPage(PATH.IMAGE_HOME)}>
              <p>이미지</p>
              <div className="flexColCenter">
                <img src={whiteImageIcon} alt="icon" />
              </div>
            </div>

            {/* <div className="menu flexRowCenter" onClick={() => menuFlag && moveToPage(PATH.VIDEO_HOME)}>
              <p>비디오</p>
              <div className="flexColCenter">
                <img src={whiteVideoIcon} alt="icon" />
              </div>
            </div> */}

            <div className="menu flexRowCenter" onClick={() => menuFlag && moveToPage(PATH.TEXT_HOME)}>
              <p>텍스트</p>
              <div className="flexColCenter">
                <img src={whiteTextIcon} alt="icon" />
              </div>
            </div>
          </article>

          <article className={`closeBox flexColEnd ${menuFlag ? 'active' : ''}`}>
            <div className="menuBox flexColCenter" onClick={menuToggle}>
              <img src={whiteCloseIcon} alt="close_icon" className="activeIcon" id={menuFlag ? 'activeIcon' : 'nothing'} />
              <img src={whiteMenuIcon} alt="menu_icon" id={menuFlag ? 'noneVisible' : 'nothing'} />
            </div>
          </article>
        </>
      ) : (
        <article className="up flexRowCenter active" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          <div className="flexColCenter">
            <img src={whiteArrowUp} alt="arrow_up" />
          </div>
        </article>
      )}
    </section>
  );
};

export default FixedNav;
