import React, { useRef, useEffect, useState } from 'react'
import { Modal, message } from 'antd';
import Table from '@components/Table';

const PaymentCoupon = (props) => {

  const {visible, onClose, userOwnCoupons, planCode, handleSelectedCoupon} = props;
  const [showGrid, setShowGrid] = useState(false);

  const couponTableRef = useRef();

  const couponGridProps = {
    height : 400,
    showStateColumn: false,
    enableSorting : false,
    showRowNumColumn : true,
    selectionMode: 'singleRow',
    showRowCheckColumn: true,
    rowCheckToRadio: true,
  }

  const couponColumnLayout = [
    { dataField: 'cpn_tp_nm', headerText: '발급형태', width : "10%", editable : false},
    { dataField: 'cpn_nm', headerText: '쿠폰명', width : "40%", editable : false },
    { dataField: 'd_cnt', headerText: '혜택 기간', width : "40%", editable : false,
     labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
             let html = '';
             html += '적용일로 부터 '+item.d_cnt + ' 일간';
             return html;
           },
     },
    /* { dataField: 'issue_dt_str', headerText: '발급일', width : "15%", editable : false}, */
    /* { dataField: 'use_start_dt_str', headerText: '등록 기간', width : "25%", editable : false ,
      renderer: {
        type: 'TemplateRenderer',
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let html = '';
        html += '<span>'+item.use_start_dt_str + ' ~ ' + item.use_end_dt_str+'</span>';
        return html;
      },

    }, */
    { dataField: 'cpn_stat_nm', headerText: '상태', width : "10%", editable : false },
  ]

  const handleSelect = async () => {
    const data = couponTableRef.current.getCheckedRowItems();
    if(data.length === 0){
      message.error('사용할 쿠폰을 선택해주세요.');
      return false;
    }else{
      if(await window.confirm('선택한 쿠폰을 적용하시겠습니까?')){
        handleSelectedCoupon(data[0].item);
      }
    }
  }

  useEffect(() => {
    if(visible){
      function changeShowGrid(){
        setShowGrid(true)
        function dataset(){
          const couponDivision = planCode === 'PLAN002' ? 'BASIC' : 'PRO';
          const gridData = userOwnCoupons.filter((item) => item.cpn_div === couponDivision)
          couponTableRef.current.setGridData(gridData)
        }
        setTimeout(dataset, 10)
      }
      setTimeout(changeShowGrid, 10)
    }
  }, [visible])

  return (
    <Modal
      open={visible}
      title="쿠폰정보"
      width={`800px`}
      footer={null}
      onCancel={() => {
        onClose();
      }}
    >
      <div>
        <div className="modalReceipt">
          <section className="l-section">
            <div className="l-section__content">
              {showGrid && <Table ref={couponTableRef} columnLayout={couponColumnLayout} customGridProps={couponGridProps}/>}
            </div>
          </section>
        </div>
        <div className='flexWrapCenter' style={{ gap: '10px' }}>
          <button className='btn-grad' onClick={handleSelect}>선택</button>
          <button className='btn-white' onClick={onClose}>취소</button>
        </div>
      </div>
    </Modal>
  )
}

export default PaymentCoupon