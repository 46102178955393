import {Button, Form, message, Avatar, Tooltip, Skeleton} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as api from '@api/index';
import { useNavigate } from 'react-router-dom';
import CommentSystem from './Comment';
import { HeartOutlined, HeartFilled, StarFilled } from '@ant-design/icons';
import {formatEncodingBoardDate, formatNumber} from '@utils/lib';
import '@assets/css/board.css';
import ComplaintModal from '@components/ComplaintModal';
import { IMAGES } from '@utils/image';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import * as PATH from '@routes/pathName';
import {badge_map} from "@utils/constants";
import {setBoardDetail} from "@stores/board";

const BoardDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();  const [form] = Form.useForm();

  const auth = useSelector((state) => state.auth);
  const boardState = useSelector((s) => s.board, shallowEqual);

  const formRef = useRef(form);
  const [isLoading, setIsLoading] = useState(false);
  const [lstId, setLstId] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [writerProfile, setWriterProfile] = useState({});
  const [badgeList, setBadgeList] = useState([]);           // 뱃지 리스트
  const [selectedAis, setSelectedAis] = useState([]);
  const [fileGrpSeq, setFileGrpSeq] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isComplaintModal, setIsComplaintModal] = useState(false);
  const [complaintUpdated, setComplaintUpdated] = useState(false);
  const complaintParams = useRef({});


  const [boardListState, setBoardListState] = useState({
    query: {
      keyword_type: 0,
      keyword_text: boardState?.board_detail?.doc_no,
      board_no: boardState?.board_detail?.board_no || null,
      like_mem_key: auth?.mem_key || null,
      selector_2: '',
    },
    items: [],
  });

  const getBoardList = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.getBoardList(query);
      if (!data) {
        setIsLoading(false);
        return;
      }
      dispatch(setBoardDetail(data.items[0]));

      const boardDetail = data.items?.[0];
      if (data.items) {
        formRef.current.setFieldsValue({
          title: boardState?.board_detail?.title,
          contents: replaceImageSrcs(boardState?.board_detail?.contents, boardState?.board_detail?.totalFileList),
        });
        setLstId(boardDetail.lst_id);
      }
      setBoardListState((prev) => ({
        ...prev,
        items: data.items,
      }));

      getProfile(boardDetail.fir_id);
      setFileGrpSeq(boardDetail.file_grp_seq);
      complaintParams.current = {
        target_id: Number(boardState?.board_detail?.doc_no),
        own_mem_key: boardState?.board_detail?.fir_id,
        bad_div: 'B',
      };
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getLikes = async () => {
    try {
      if (auth?.mem_key) {
        const params = {
          target_id: Number(boardState?.board_detail?.doc_no),
          like_mem_key: auth?.mem_key,
        };

        const { data } = await api.likeList({ params });
        if (data.length > 0) {
          setIsLiked(true);
        }
      }
    } catch (error) {
      message.error('좋아요를 불러오는데 실패했습니다.');
    }
  };

  const replaceImageSrcs = (content, files) => {
    const contentImgs = files?.filter((file) => file.attr1 === 'c') || [];
    let updatedContent = content;
    contentImgs.forEach((img) => {
      const imgRegex = new RegExp(`src="[^"]*${img.file_org_nm}"`, 'g');
      updatedContent = updatedContent.replace(imgRegex, `src="${img.file_dwn_path}"`);
    });
    return updatedContent;
  };

  const downloadFile = async (filePath, fileName) => {
    try {
      const response = await fetch(filePath);
      if (!response.ok) throw new Error('파일 다운로드에 실패하였습니다.');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      message.error('파일 다운로드에 실패하였습니다.');
    }
  };

  const toggleLike = async () => {
    const data = {
      target_id: Number(boardListState.query.keyword_text),
      like_mem_key: auth?.mem_key,
      own_mem_key: lstId,
      like_div: 'B',
    };
    try {
      await api.increaseLikeCount(data);
      setIsLiked((prev) => !prev);
      message.success(isLiked ? '좋아요가 취소되었습니다.' : '좋아요를 눌렀습니다.');
    } catch (error) {
      message.error('좋아요 처리 중 오류가 발생했습니다.');
    } finally {
      getBoardList(boardListState.query);
      getLikes();
    }
  };

  const getProfile = async (room) => {
    const params = { mem_key: room };
    const response = await api.userProfile({ params });
    setWriterProfile(response.data);

    if (response.data?.ai_fl_cd) {
      const ai_nm = response.data.ai_fl_nm.split('|');
      setSelectedAis(ai_nm);
    }
  };
  const renderStars = (score) => {
    const totalStars = 5;
    const validScore = typeof score === 'number' && score >= 0 && score <= 5 ? score : 0;
    const filledStars = Math.floor(validScore);
    const halfStar = validScore % 1 !== 0;

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {[...Array(filledStars)].map((_, index) => (
          <StarFilled key={`filled-${index}`} style={{ color: '#FFA514' }} />
        ))}

        {halfStar && (
          <StarFilled
            style={{
              color: '#FFA514',
              fontSize: '18px',
              clipPath: 'inset(0 50% 0 0)',
            }}
          />
        )}
        {[...Array(totalStars - filledStars - (halfStar ? 1 : 0))].map((_, index) => (
          <StarFilled key={`gray-${index}`} />
        ))}
      </div>
    );
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const delComplain = async () => {
    try {
      /*const query = {
        target_id: complaintParams.current.target_id,
        bad_mem_key: auth?.mem_key, // 신고자
        own_mem_key: complaintParams.current.own_mem_key, // 신고대상
        bad_div: complaintParams.current.bad_div,
      };
      // console.log('최종 제출 데이터:', query);
      await api.addPromptBad(query);
      setComplaintUpdated((prev) => !prev);
      message.success('해당 회원의 신고가 취소 처리되었습니다.');*/
      message.error('신고 접수된 글 입니다.')
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const handleReWriteClick = () => {
    navigate(`${PATH.BOARD_EDIT}`, {
      state: {
        board_no: boardState?.board_detail?.board_no,
        doc_no: boardState?.board_detail?.doc_no,
        board_title: boardState?.board_detail?.title,
        type: boardState?.board_chk?.dsp_meth === '1' ? 'List' : 'Card',
        attach_yn: boardState?.board_chk?.attach_yn,
      },
    });
  };

  const handleProfileClick = (param) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_key: param,
      },
    });
  };

  const handleDel = async () => {
    try {
      const formData = new FormData();
      const updateBoard = [{
        board_no: boardState?.board_detail?.board_no,
        doc_no: boardState?.board_detail?.doc_no,
        title: boardState?.board_detail?.board_title,
        state: 'del',
        file_grp_seq: fileGrpSeq,
      }]

      formData.append('updateBoard', new Blob([JSON.stringify(updateBoard)], { type: 'application/json' }));
      await api.updatedBoard(formData);
      message.success('게시글이 삭제되었습니다.');
    } catch(error) {
      message.error('게시글이 삭제에 실패하였습니다.');
      console.log("게시글 삭제 오류" + error);
    } finally {
      navigate(-1);
    }
  }

  // 랭크 아이콘 치환
  const getRankItems = () => {
    if (!writerProfile?.agg_rank) return [];

    const ranks = writerProfile.agg_rank.split(',');
    const items = [
      { rank: ranks[0], icon: IMAGES.IMAGES_ICON, page: 0, label: 'image' },
      /*{ rank: ranks[1], icon: IMAGES.VIDEO_ICON, page: 1, label: 'video' },*/
      { rank: ranks[2], icon: IMAGES.MUSIC_ICON, page: 2, label: 'image' },
      { rank: ranks[3], icon: IMAGES.BOOK_ICON, page: 3, label: 'text' }
    ];

    return items.sort((a, b) => Number(a.rank) - Number(b.rank));
  };

  const sortedItems = getRankItems();
  const highestRankItem = sortedItems.length > 0 ? {...sortedItems[0]} : null;

  const moveToPage = (num) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_email: writerProfile?.mem_email,
        mem_key: writerProfile?.mem_key,
        cateCd: (writerProfile?.agg_cate || '').split(',')[num],
        ranking: "true",
      }
    });
  };

  useEffect(() => {
    getBoardList(boardListState.query);
    getLikes();
    listBadge();
  }, [isComplaintModal, complaintUpdated]);

  const listBadge = async () => {
    try {
      const { data } = await api.getBadgeInfo();
      setBadgeList(data);
    } catch (error) {
      console.error('마켓플레이스 뱃지 리스트 조회 error', error);
    }
  };

  const getMemberBadge = useCallback(
      (bdgCd) => {
        if (!bdgCd || !badgeList.length) return [];
        return badgeList.filter((badge) => bdgCd.includes(badge.bdg_cd));
      },
      [badgeList],
  );

  return (
    <>
      <section id="boardDetail" className="rsWrapper">
        <Form form={form} layout="vertical">
          <div className="titleBox">
            <h1 className="cate">{isLoading ? <Skeleton.Input size="small" active /> : boardState?.board_detail?.board_cate}</h1>
            <div className="titleArea">
              <h2>{isLoading ? <Skeleton.Input active /> : form.getFieldValue("title")}</h2>
              {writerProfile?.mem_auth === 'R001' && auth?.mem_key && auth?.mem_key !== parseInt(boardState?.board_detail?.fir_id, 10) && (
                <button
                  className={`icon siren ${boardState?.board_detail?.prpt_bad === 'Y' ? 'on' : ''}`}
                  onClick={() => (boardState?.board_detail?.prpt_bad === 'Y' ? delComplain() : setIsComplaintModal(true))}
                >
                  신고
                </button>
              )}
            </div>
            <div className="infoArea">
              <span className="date">{isLoading ? <Skeleton.Input size="small" active /> : formatEncodingBoardDate(boardState?.board_detail?.fir_dt)}</span>
              <span className="view">
                조회수 <b>{isLoading ? <Skeleton.Input size="small" style={{ marginLeft: 10 }} active /> : boardState?.board_detail?.view_cnt}</b>
              </span>
              {writerProfile?.mem_key !== 0 && (
                <div className="like-button-container">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      auth?.mem_key ? toggleLike() : message.warning('로그인 후 좋아요 체크가 가능합니다.');
                    }}
                    icon={isLiked ? <HeartFilled style={{ color: '#ec15ff' }} /> : <HeartOutlined style={{ color: '#000' }} />}
                    type="text"
                  />
                  {boardState?.board_detail?.like_cnt}
                </div>
              )}
            </div>
          </div>
          <div className="userBox">
            <div className="userIcon">
              {isLoading ? (
                  <Skeleton.Avatar size={48} active />
              ) : (
                  <Avatar src={writerProfile?.mem_img_path || IMAGES.DEFAULT_PROFILE} size={48} icon={<img src={IMAGES.DEFAULT_PROFILE} alt="default profile" />} />
              )}
            </div>
            <div className="userInfo">
              <div className="name">
                {/* {pro/basic/beginner 로 class 아래에 적용} */}
                {isLoading ? <Skeleton.Input size="small" active /> : <span className={`badge_s ${badge_map[writerProfile.plan_cd] || ''}`}>{writerProfile?.plan_nm}</span>}
                {isLoading ? (
                    <Skeleton.Input size="small" active />
                ) : (
                    <p
                        className="user-nickname"
                        style={{ cursor: writerProfile?.mem_auth === 'R001' ? 'pointer' : 'default' }}
                        onClick={() => writerProfile?.mem_auth === 'R001' && handleProfileClick(writerProfile?.mem_key)}
                    >
                      {writerProfile?.mem_nick}
                    </p>
                )}

              </div>
              {isLoading ? <Skeleton.Input size="small" style={{ width: 60 }} active /> :
              <div className="info-container">
                {writerProfile?.mem_auth === 'R001' && (
                  <div className="dropdown-container rankingArea">
                    <div className="dropdown-button" onClick={toggleDropdown}>
                      <div className="dropdown-item">
                        <div className={`ranking_s ${highestRankItem?.label}`}>#{writerProfile?.agg_rank ? formatNumber(writerProfile?.agg_rank.split(',')[0]) : '0'}</div>
                      </div>
                    </div>
                    {isOpen && (
                      <div className="dropdown-content">
                        {sortedItems.map((item, index) => (
                          <div className={`ranking_s ${item?.label}`} onClick={moveToPage}>#{item.rank}</div>
                            ))
                        }
                      </div>
                    )}
                  </div>
                )}
                {writerProfile?.mem_auth === 'R001' && (
                  <div className="major">
                    {getMemberBadge(writerProfile?.bdg_cd).map((badge, index) => (
                        <Tooltip title={badge?.bdg_nm}>
                          <img key={index} src={badge?.icon_path} alt="icon" />
                        </Tooltip>
                    ))}
                  </div>
                )}
                {/*<div className="star-rating">{renderStars(writerProfile?.score_avg)}</div>*/}
              </div>
              }
            </div>
            {writerProfile?.mem_auth === 'R001' && selectedAis.length > 0 && (
              <div className="aiArea">
                <p>전문 AI 분야</p>
                <div className="majorModel">
                  {selectedAis.map((ai, index) => (
                      <span key={index}>{ai}</span>
                  ))}
                </div>
              </div>
            )}
          </div>
          {/*<div className="document-container"></div>
          <Form.Item label="">
            <div className="file-attachment">
              <span className="file-title">첨부파일</span>
              {boardListState.items?.[0]?.totalFileList?.filter((file) => file.attr1 === 'a').length ? (
                <ul className="file-list">
                  {boardListState.items[0].totalFileList
                    .filter((file) => file.attr1 === 'a')
                    .map((file) => (
                      <li key={file.file_seq} className="file-item">
                        <Button onClick={() => downloadFile(file.file_dwn_path, file.file_org_nm)}>{file.file_org_nm}</Button>
                      </li>
                    ))}
                </ul>
              ) : (
                <div className="noResult">첨부된 파일이 없습니다</div>
              )}
            </div>
          </Form.Item>*/}
          <div className="contentBox">
            {isLoading ? <Skeleton active paragraph={{ rows: 5, width: '100%'}} style={{ height: '300px' }} /> : <div className="content" dangerouslySetInnerHTML={{ __html: form.getFieldValue('contents') }} />}
            <div className="content-btn">
              <button className="btn-back" onClick={handleGoBack}>뒤로가기</button>
            </div>
          </div>
          <div className="buttonBox">
            {!isLoading && (auth?.mem_auth === 'R002' || auth?.mem_key === parseInt(boardState?.board_detail?.fir_id, 10)) && (
              <>
                <Button className="btn-dark" onClick={handleReWriteClick}>
                  수정
                </Button>
                <Button className="btn-red" onClick={handleDel} danger>
                  삭제
                </Button>
              </>
            )}
          </div>
        </Form>
        {boardState?.board_chk?.reply_yn !== 'N' && <CommentSystem board_no={boardState?.board_detail?.board_no} doc_no={boardState?.board_detail?.doc_no} />}
      </section>
      <ComplaintModal isOpen={isComplaintModal} onClose={() => setIsComplaintModal(false)} params={complaintParams.current} />
    </>
  );
};

export default BoardDetail;
