import React from 'react';
import {  Tooltip } from 'antd';

const ButtonGroupSubMain = ({
  isShareButton = false,
  onShare,
  isListenButton = false,
  onListen,
  isDisabledListenButton,
  isKeepButton = false,
  onKeep,
  isSaleButton = false,
  onSale,
  isCartButton = false,
  onCart,
}) => {
  return (
    <div className="flexRowCenter buttonGroup">
      {isShareButton && (
        <button id="pinkBtn" className="icon sale" onClick={onShare}>
          <p>음악등록</p>
        </button>
      )}

      {isListenButton && (
        <>
        {isDisabledListenButton ? (
            <Tooltip title="음악을 선택해서 플레이어에 추가하세요." overlayClassName="widthAuto" visible={isDisabledListenButton ? undefined : false}>
            <button id="whiteBtn" className="icon listen" onClick={onListen} disabled={isDisabledListenButton}>
              <p>듣기</p>
            </button>
            </Tooltip>
        ) : (
            <button id="whiteBtn" className="icon listen" onClick={onListen} disabled={isDisabledListenButton}>
                <p>듣기</p>
            </button>
        )}
        </>
      )}

      {isKeepButton && (
        <button id="whiteBtn" className="icon keep" onClick={onKeep}>
          <p>보관함</p>
        </button>
      )}
      {isSaleButton && (
        <button id="pinkBtn" className="icon sale" onClick={onSale}>
          <p>판매하기</p>
        </button>
      )}

      {isCartButton && (
        <button id="whiteBtn" className="icon put" onClick={onCart}>
          <p>장바구니</p>
        </button>
      )}
    </div>
  );
};

export default ButtonGroupSubMain;
