import React from 'react';
import { Drawer, Input } from 'antd';
// icon
import { CloseOutlined } from '@ant-design/icons';
import searchIcon from '@assets/images/common/search_icon.png';
import whiteCheckIcon from '@assets/images/common/white_check_icon.png';

const FilterMobileSubMain = ({ headerTitle, isActive, toggle, filterParams, setFilterParams, aiModelList, genreList }) => {
  return (
    <Drawer open={isActive} width={`100%`} closable={false}>
      <div id="mobileFilter" className="flexColCenter">
        <div className="flexColEnd">
          <p onClick={toggle}>
            <CloseOutlined />
          </p>
        </div>

        <div className="content flexColStart">
          <h1>필터</h1>

          <div className="listBox flexColStart ">
            <h3>AI 모델</h3>

            <div className="flexRowStart">
              {aiModelList?.map((aiModel, index) => (
                <div
                  key={index}
                  className="list flexRowCenter"
                  id={aiModel?.ai_model_cd === filterParams?.filter_model ? 'active' : ''}
                  onClick={() => setFilterParams('filter_model', aiModel?.ai_model_cd)}
                >
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>
                  <p>{aiModel?.ai_model_nm}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="listBox flexColStart ">
            <h3>카테고리</h3>

            <div className="flexRowStart">
              {genreList?.map((genre, index) => (
                <div key={index} className="list flexRowCenter" id={genre?.cd === filterParams?.filter_genre ? 'active' : ''} onClick={() => setFilterParams('filter_genre', genre?.cd)}>
                  <div className="round flexColCenter">
                    <img src={whiteCheckIcon} />
                  </div>
                  <p>{genre?.cd_nm}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="listBox flexColStart">
            <h3>{headerTitle}</h3>
            <div className="searchBox flexRowBetween">
              <Input placeholder="검색어를 입력해주세요." value={filterParams?.filter_text} onChange={(e) => setFilterParams('filter_text', e.target.value)} />
              <img src={searchIcon} />
            </div>
          </div>
        </div>

        <div className="btnBox flexRowBetween">
          <button id="pinkBtn" onClick={toggle}>
            검색
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default FilterMobileSubMain;
