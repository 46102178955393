/* eslint-disable no-restricted-globals */
import React, { useCallback, useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
// 나머지 코드...
// component
import * as api from '@api/index';
import Loading from '@components/Loading';
import {BOARD_HOME, COMMUNITY_HOME} from '@routes/pathName';
import {setBoardCheck, setYScrollPosition} from '@stores/board';
import { message } from 'antd';
import { IMAGES } from '@utils/image';

const CommunityHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [communityList, setCommunityList] = useState([]);
  const [boardList, setBoardList] = useState([]);

  const getIntegratedBoardList = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.getIntegratedBoardList(query);

      if (data?.items?.length) {
        const userPlanLevel = parseInt((auth?.plan_cd || "PLAN000").slice(-3), 10);

        const { boardList, communityList } = data.items.reduce((acc, item) => {
          const boardLevel = parseInt((item.use_lv).slice(-3), 10);

          if (boardLevel <= userPlanLevel && item.use_yn === 'Y' && item.menu_yn === 'Y') {
            if (item.board_cate === '1') acc.boardList.push(item);
            if (item.board_cate === '2') acc.communityList.push(item);
          }
          return acc;
        }, { boardList: [], communityList: [] });

        setBoardList(boardList);
        if (auth?.mem_key) {
          setCommunityList(communityList);
        }
      } else {
        message.warning('데이터를 불러오는 데 실패했습니다.');
      }
    } catch (error) {
      message.warning(error.message || '데이터 로드 중 오류가 발생했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, [auth?.mem_key, auth?.plan_cd]);

  // 커뮤니티와 게시판에 표시할 항목
  const displayedCommunityList = location.pathname.includes("/community") ? communityList : communityList.slice(0, 4);
  const displayedBoardList = location.pathname.includes("/community") ? boardList : boardList.slice(0, 4);

  const moveCommunity= () => {
    if (!location.pathname.includes("/community")) {
      navigate(`${COMMUNITY_HOME}`);
    }
  };

  const handleItemClick = (value) => {
    dispatch(setBoardCheck(value));
    navigate(`${BOARD_HOME}`);
  };

  const clearAllSearchConditions = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('boardSearchCondition_')) {
        localStorage.removeItem(key);
      }
    });
  };

  useEffect(() => {
    getIntegratedBoardList();
  }, []);

  useEffect(() => {
    // URL 경로에 /boards가 포함되지 않으면 검색 조건 초기화
    if (!location.pathname.includes("/boards")) {
      dispatch(setYScrollPosition(0));
      clearAllSearchConditions();
    }
  }, [location]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <article id="infoWrapper" className="flexColCenter">
        <h1>
          <span>프롬프트 아이디어 공유 공간</span>
        </h1>
        <div className="rsWrapper flexColCenter">
          <div className="box">
            <div className="titleBox">
              <div className="flexRowCenter title">
                <img src={IMAGES.BOARD_ICON} alt="board_icon" />
                <p>게시판</p>
              </div>
              {!location.pathname.includes("/community") && (
                  <div id="whiteBtn" className="web" onClick={moveCommunity}>더보기</div>
              )}
            </div>
            <div className="listBox">
              {displayedBoardList.map((com, i) => (
                  <div className="list flexColCenter" key={i} onClick={() => handleItemClick(com)}>
                    <div className="card absoluteBox">
                      <p>{com.board_title || 'Unknown Community'}</p>
                    </div>
                    <div className="image-wrapper">
                      <img src={com.board_img_path || ''} alt="" onError={(e) => { e.target.style.display = 'none' }} />
                    </div>
                  </div>
              ))}
            </div>
          </div>

          {auth?.mem_key && (
            <div className="box">
              <div className="titleBox">
                <div className="flexRowCenter title">
                  <img src={IMAGES.COMMUNITY_ICON} alt="community_icon" />
                  <p>커뮤니티</p>
                </div>
                {!location.pathname.includes("/community") && (
                    <div id="whiteBtn" className="web" onClick={moveCommunity}>더보기</div>
                )}
              </div>
              <div className="listBox">
                {displayedCommunityList.map((com, i) => (
                    <div className="list flexColCenter" key={i} onClick={() => handleItemClick(com)}>
                      <div className="card absoluteBox">
                        <p>{com.board_title || 'Unknown Community'}</p>
                      </div>
                      <div className="image-wrapper">
                        <img src={com.board_img_path || ''} alt="" onError={(e) => { e.target.style.display = 'none' }} />
                      </div>
                    </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </article>
    </>
  );
};

export default CommunityHome;
