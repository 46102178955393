import React from 'react';
import { Divider } from 'antd';

const DividerList = ({ text, style, dashed = false }) => {
  return (
    <Divider dashed={dashed} orientation="center">
      {text}
    </Divider>
  );
};

export default DividerList;
