import { Button, Col, Form, Input, Layout, Row } from 'antd';
import React, { useEffect, useRef } from 'react';
import Table from '@components/Table';
import { Content } from 'antd/es/layout/layout';
import { isEmpty } from '../../../utils/lib';
import * as api from '@api/index';
import GridHeader from '@components/GridHeader';

const CommonCodes = (props) => {
  const [form] = Form.useForm();
  const groupTableRef = useRef();
  const detailTableRef = useRef();

  //그룹 코드 그리드 설정
  const groupGridProps = {
    showRowCheckColumn: true,
  };

  //그룹 코드 그리드 컬럼
  const groupColumnLayout = [
    {
      dataField: 'grp_cd',
      headerText: '그룹코드',
      width: '12%',
      editRenderer: {
        type: 'InputEditRenderer',
        maxlength: 10,
        regExp: '^[a-zA-Z0-9_]*$',
      },
    },
    {
      dataField: 'grp_nm',
      headerText: '그룹코드명',
      width: '20%',
      editRenderer: {
        type: 'InputEditRenderer',
        maxlength: 50,
      },
    },
    {
      dataField: 'grp_cate',
      headerText: '그룹카테고리',
      width: '15%',
      editRenderer: {
        type: 'InputEditRenderer',
        maxlength: 10,
        regExp: '^[a-zA-Z0-9]*$',
      },
    },
    {
      dataField: 'grp_parent_cd',
      headerText: '그룹상위코드',
      width: '12%',
      editRenderer: {
        type: 'InputEditRenderer',
        maxlength: 10,
        regExp: '^[a-zA-Z0-9]*$',
      },
    },
    {
      dataField: 'grp_desc',
      headerText: '그룹설명',
      width: '20%',
      editRenderer: {
        type: 'InputEditRenderer',
        maxlength: 50,
      },
    },
    {
      dataField: 'dsp_seq',
      headerText: '표시순번',
      width: '10%',
      editRenderer: {
        type: 'InputEditRenderer',
        maxlength: 3,
        regExp: '^[0-9]*$',
      },
    },
    {
      dataField: 'use_yn',
      headerText: '사용여부',
      width: '*',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N'],
      },
    },
  ];

  //상세 코드 그리드 설정
  const detailGridProps = {
    showRowCheckColumn: true,
  };

  //상세 코드 그리드 컬럼
  const detailColumnLayout = [
    { dataField: 'grp_cd', headerText: '그룹코드', visible: false },
    { dataField: 'cd', headerText: '상세코드', width: '15%', editRenderer: { type: 'InputEditRenderer', maxlength: 10, regExp: '^[a-zA-Z0-9_]*$' } },
    { dataField: 'cd_nm', headerText: '상세코드명', width: '20%', editRenderer: { type: 'InputEditRenderer', maxlength: 50 } },
    {
      dataField: 'parent_cd',
      headerText: '상위코드',
      width: '15%',
      editRenderer: { type: 'InputEditRenderer', maxlength: 10, regExp: '^[a-zA-Z0-9]*$' },
    },
    { dataField: 'cd_desc', headerText: '설명', width: '25%', editRenderer: { type: 'InputEditRenderer', maxlength: 50 } },
    { dataField: 'dsp_seq', headerText: '표시순번', width: '10%', editRenderer: { type: 'InputEditRenderer', maxlength: 3, regExp: '^[0-9]*$' } },
    {
      dataField: 'use_yn',
      headerText: '사용여부',
      width: '*',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N'],
      },
    },
  ];

  //그룹 코드 조회
  const handleFetchCommonGroupCode = async (values) => {
    const params = {
      grp_cd: values.grp_cd,
      grp_nm: values.grp_nm,
    };

    const response = await api.fetchCommonGroupCode(params);
    groupTableRef.current.setGridData(response.data);
    detailTableRef.current.clearGridData();
  };

  //상세 코드 조회
  const handleFetchCommonDetailCode = async (value) => {
    const params = {
      grp_cd: value,
    };

    const response = await api.fetchCommonAllDetailCode(params);
    detailTableRef.current.setGridData(response.data);
  };

  //그룹 코드 추가
  const handleAddGroupCode = () => {
    groupTableRef.current.addRow({});
    detailTableRef.current.clearGridData();
  };

  //그룹 코드 삭제
  const handleDeleteGroupCode = (items) => {
    const gridData = groupTableRef.current.getGridData();
    const checkedItems = groupTableRef.current.getCheckedRowItems();
    if (gridData.length === 0) {
      alert('삭제할 그룹코드가 없습니다.');
      return;
    } else if (checkedItems.length === 0) {
      alert('선택된 그룹코드가 없습니다.');
      return;
    } else {
      window.confirm('삭제하시겠습니까?', 'delete').then(async (result) => {
        if (result) groupTableRef.current.removeCheckedRows();
        else return;
      });
    }
  };

  //상세 코드 추가
  const handleAddDetailCode = () => {
    const index = groupTableRef.current.getSelectedIndex();
    const items = groupTableRef.current.getGridDataWithState('state');
    const selectedItem = items[index[0]];
    if (index[0] === -1) {
      alert('그룹코드를 선택해주세요.');
      return;
    } else if (['added'].includes(selectedItem.state)) {
      alert('추가된 그룹코드는 저장 후에 상세코드를 등록할 수 있습니다.');
      return;
    } else {
      detailTableRef.current.addRow({
        grp_cd: selectedItem.grp_cd,
      });
    }
  };

  //상세 코드 삭제
  const handleDeleteDetailCode = () => {
    const gridData = detailTableRef.current.getGridData();
    const checkedItems = detailTableRef.current.getCheckedRowItems();
    if (gridData.length === 0) {
      alert('삭제할 상세코드가 없습니다.');
      return;
    } else if (checkedItems.length === 0) {
      alert('선택된 상세코드가 없습니다.');
      return;
    } else {
      window.confirm('삭제하시겠습니까?', 'delete').then(async (result) => {
        if (result) detailTableRef.current.removeCheckedRows();
        else return;
      });
    }
  };

  //저장
  const handleSave = async () => {
    const groupGridData = groupTableRef.current.getGridDataWithState('state');
    const detailGridData = detailTableRef.current.getGridDataWithState('state');
    let saveGroupData = [];
    let saveDetailData = [];
    for (const data of groupGridData) {
      if (data.state) {
        if (data.state === 'added' || data.state === 'edited') {
          if (isEmpty(data.grp_cd) || isEmpty(data.grp_nm) || isEmpty(data.grp_cate) || isEmpty(data.grp_desc) || isEmpty(data.dsp_seq) || isEmpty(data.use_yn)) {
            alert('그룹코드\n그룹코드명\n그룹카테고리\n그룹설명\n표시순번\n사용여부는 필수값입니다.');
            return;
          }
        }
        saveGroupData.push(data);
      }
    }
    for (const data of detailGridData) {
      if (data.state) {
        if (data.state === 'added' || data.state === 'edited') {
          if (isEmpty(data.cd) || isEmpty(data.cd_nm) || isEmpty(data.cd_desc) || isEmpty(data.dsp_seq) || isEmpty(data.use_yn)) {
            alert('상세코드\n상세코드명\n설명\n표시순번\n사용여부는 필수값입니다.');
            return;
          }
        }
        saveDetailData.push(data);
      }
    }

    if (saveGroupData.length === 0 && saveDetailData.length === 0) {
      alert('저장할 데이터가 없습니다.');
      return;
    }

    window.confirm('저장하시겠습니까?').then(async (result) => {
      if (result) {
        const params = {
          saveGroupData: saveGroupData,
          saveDetailData: saveDetailData,
        };

        const response = await api.saveCommonCode(params);
        alert(response.data.returnMessage);
        if (response.data.returnStatus === 'success') {
          form.submit();
        }
      } else return;
    });
  };

  useEffect(() => {
    //그룹 코드 셀 클릭
    groupTableRef.current.bind('cellClick', (event) => {
      const gridData = groupTableRef.current.getGridDataWithState('state');
      if (gridData[event.rowIndex].state !== 'added' && event.item.grp_cd) {
        handleFetchCommonDetailCode(event.item.grp_cd);
      } else {
        detailTableRef.current.clearGridData();
      }
    });

    //그룹 코드 수정 시작
    groupTableRef.current.bind('cellEditBegin', (event) => {
      const gridData = groupTableRef.current.getGridDataWithState('state');
      if (event.dataField === 'grp_cd') {
        if (gridData[event.rowIndex].state !== 'added') {
          return false;
        }
      }
    });

    //상세 코드 수정 시작
    detailTableRef.current.bind('cellEditBegin', (event) => {
      const gridData = detailTableRef.current.getGridDataWithState('state');
      if (event.dataField === 'cd') {
        if (gridData[event.rowIndex].state !== 'added') {
          return false;
        }
      }
    });
  }, []);

  return (
    <Layout>
      <Content>
        <Row className='l-flex gap' style={{flexWrap: 'nowrap'}}>
          <Col className='l-content' style={{flex: 1, minWidth:0}}>
            <Form className='l-flex gap' form={form} layout="inline" onFinish={handleFetchCommonGroupCode}>
              <Form.Item name="grp_cd">
                <Input placeholder="공통코드" />
              </Form.Item>
              <Form.Item name="grp_nm">
                <Input placeholder="공통코드명" />
              </Form.Item>
              <Button className='btn-searchIcon' type="primary" htmlType="submit">
                검색
              </Button>
            </Form>
            <Table ref={groupTableRef} columnLayout={groupColumnLayout} customGridProps={groupGridProps}></Table>
          </Col>
          <Col className='l-content' style={{flex: 1, minWidth:0}}>
            <Row className='l-flex gap' style={{marginLeft: 'auto'}}>
              <Button className='btn-add' onClick={handleAddGroupCode}>그룹코드추가</Button>
              <Button className='btn-red' onClick={handleDeleteGroupCode} danger>
                그룹코드삭제
              </Button>
              <Button className='btn-add' onClick={handleAddDetailCode}>상세코드추가</Button>
              <Button className='btn-red' onClick={handleDeleteDetailCode} danger>
                상세코드삭제
              </Button>
              <Button className='btn-pink' type="primary" onClick={handleSave}>
                저장
              </Button>
            </Row>
            <Table ref={detailTableRef} columnLayout={detailColumnLayout} customGridProps={detailGridProps}></Table>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default CommonCodes;
