import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Skeleton, Dropdown, Tooltip } from 'antd';
// icon
import followIcon from '@assets/images/common/user_icon2.png';
import { RightOutlined } from '@ant-design/icons';
import boostIcon from '@assets/images/common/boost_icon.png';
import useIcon from '@assets/images/common/use_icon.png';
import downArrow from '@assets/images/profile/down_arrow_icon_white.png';
import musicIcon from '@assets/images/common/music_icon.png';
import imageIcon from '@assets/images/common/image_icon.png';
import videoIcon from '@assets/images/common/video_icon.png';
import textIcon from '@assets/images/common/text_icon.png';
import whiteMusicIcon from '@assets/images/common/white_music_icon.png';
import whiteImageIcon from '@assets/images/common/white_image_icon.png';
import whiteVideoIcon from '@assets/images/common/white_video_icon.png';
import whiteTextIcon from '@assets/images/common/white_text_icon.png';
// component
import CardSquareImage2 from '@pages/main/CardSquareImage2';
import Rate from '@components/Rate';
import IconRank from '@components/IconRank';
// lib
import { koKRFormat, validateSession, getLocalStorage, getDefaultImg, handleImageError } from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';
// path
import * as PATH from '@routes/pathName';
// api
import * as api from '@api/index';
import { IMAGES } from '@utils/image';

const DetailImage = ({ selectedPrompt }) => {
  const navigate = useNavigate();
  const [imageList, setImageList] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [badgeList, setBadgeList] = useState([]);
  const [aiModelList, setAiModelList] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const [cateRankList, setCateRankList] = useState([]);
  const [followingList, setFollowingList] = useState([]);
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const memEmail = getLocalStorage('prptbk-token', 'mem_email') || '';
  // 카테고리 이름 가져오기
  const getCateNm = { CATE001: 'image', CATE002: 'video', CATE004: 'text' }[selectedPrompt?.cate_cd] || 'image';

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (image) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: image?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: image?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 팔로우 상태 확인
  const checkFollowing = useCallback(() => {
    const result = followingList?.filter((following) => following?.mem_key === selectedPrompt?.mem_key);
    setIsFollowing(result.length > 0);
  }, [followingList, selectedPrompt]);

  // 해당 사용자가 만든 이미지 리스트 가져오기
  const listImage = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({
        mem_key: selectedPrompt?.mem_key,
        cate_cd: selectedPrompt?.cate_cd,
        keyword_tab: 'new',
        filter_stat: '30',
        offset: 0,
        limit: 4,
      });
      if (!data) return;
      setImageList(data.items);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [selectedPrompt]);

  // 마켓플레이스 뱃지 리스트 조회
  const listBadge = async () => {
    try {
      const { data } = await api.getBadgeInfo();
      setBadgeList(data);
    } catch (error) {
      console.error('마켓플레이스 뱃지 리스트 조회 error', error);
    }
  };

  // 프롬프트 소유자의 마켓플레이스 뱃지 가져오기
  const getMemberBadge = useCallback(
    (bdgCd) => {
      if (!bdgCd || !badgeList.length) return [];
      return badgeList.filter((badge) => bdgCd.includes(badge.bdg_cd));
    },
    [badgeList],
  );

  // AI 모델 리스트 조회
  const listAiModel = async () => {
    try {
      const { data } = await api.fetchEqualCommonGroupCode({ grp_cd: 'MODEL' });
      setAiModelList(data);
    } catch (error) {
      console.error('AI 모델 리스트 조회 error', error);
    }
  };

  // 프롬프트 소유자의 전문 AI 분야 가져오기
  const getMemberAiModel = useCallback(
    (aiFlCd) => {
      if (!aiFlCd || !aiModelList.length) return;
      return aiModelList.filter((aiModel) => aiFlCd.includes(aiModel.cd));
    },
    [aiModelList],
  );

  // 팔로우 요청
  const requestFollow = useCallback(
    async (prevIsFollowing) => {
      try {
        // 로그인 체크
        if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true }) != true) return false;

        await api.addFollower({ mem_key: selectedPrompt?.mem_key, flw_mem_key: memKey });
        await getMyFollowingList();
        if (prevIsFollowing) message.success('팔로우가 취소 되었습니다.');
        else message.success('팔로우를 시작하였습니다.');
      } catch (error) {
        message.warning(error.message);
      }
    },
    [selectedPrompt],
  );

  // 프롬프트 소유자 프로필 조회
  const getUserProfile = useCallback(async () => {
    try {
      const { data } = await api.userProfile({ params: { mem_key: selectedPrompt?.mem_key } });
      setUserProfile(data);
      const newCateRankList = data?.agg_cate?.split(',')?.map((cateCd, index) => {
        return {
          agg_cate: cateCd,
          agg_rank: data?.agg_rank?.split(',')[index],
        };
      });
      const filteredList = newCateRankList.filter(item => item.agg_cate !== "CATE002");
      const sortedCateRankList = filteredList?.sort((a, b) => a?.agg_rank - b?.agg_rank);
      setCateRankList(sortedCateRankList || []);
    } catch (error) {
      console.error('프롬프트 소유자 프로필 조회 error', error);
    }
  }, [selectedPrompt]);

  // 내 팔로우 리스트 조회
  const getMyFollowingList = useCallback(async () => {
    try {
      const { data } = await api.listFollower({ flw_mem_key: memKey });
      if (!data) return;
      setFollowingList(data.items);
    } catch (error) {
      console.error('내 팔로우 리스트 조회 error', error);
    }
  }, []);

  // 카테고리별 이미지 가져오기
  const getCateImage = (cateCd, active = false) => {
    return {
      CATE001: active ? whiteImageIcon : imageIcon,
      CATE002: active ? whiteVideoIcon : videoIcon,
      CATE003: active ? whiteMusicIcon : musicIcon,
      CATE004: active ? whiteTextIcon : textIcon,
    }[cateCd];
  };

  useEffect(() => {
    if (selectedPrompt) {
      getUserProfile();
      checkFollowing();
      listImage();
    }
  }, [selectedPrompt, followingList]);

  useEffect(() => {
    getMyFollowingList();
    listBadge();
    listAiModel();
  }, []);

  return (
    <div className="rankBox flexColCenter">
      {isLoading ? (
        <Skeleton avatar active paragraph={{ rows: 4 }} style={{ margin: '50px 0' }} />
      ) : (
        <div className="rsWrapper flexRowBetween">
          <div className="flexColCenter thumbnail" style={{ backgroundImage: `url(${getDefaultImg(getCateNm, selectedPrompt)})` }}>
            <div className="absoluteBox flexColBetween">
              <div className="flexRowBetween">
                <div className="user">
                  <img src={selectedPrompt?.icon_path} alt="thumb" />
                  <p>{selectedPrompt?.ai_model_nm}</p>
                </div>

                <IconRank rankValue={selectedPrompt?.rnk} />
              </div>

              <h1 onClick={() => moveToDetailPage(selectedPrompt)}>{selectedPrompt?.prpt_title}</h1>

              <div className="flexColEnd priceBox">
                <p>{selectedPrompt?.sale_amt === 0 ? '무료' : `₩ ${koKRFormat(selectedPrompt?.sale_amt)}`}</p>
              </div>
            </div>
            {/* <img src={selectedPrompt?.thum_path} /> */}
          </div>

          <div className="infoBox flexColBetween">
            <div className="flexColStart userBox">
              <div className="flexRowStart">
                <img className="profileImg" src={userProfile?.mem_img_path || IMAGES.DEFAULT_PROFILE} alt="user" onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />

                <div className="flexColStart infoProfile">
                  <h1 onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: userProfile?.mem_email, mem_key: selectedPrompt?.mem_key })}>{userProfile?.mem_nick}</h1>

                  <div className="flexRowStart follow">
                    <div className="appraisal">
                      {/* 랭킹 */}
                      <div className="classRank">
                        <Dropdown
                          trigger="click"
                          getPopupContainer={() => document.getElementsByClassName('classRank')[0]}
                          menu={{
                            items: cateRankList?.map((rank, index) => {
                              return {
                                key: index,
                                label: (
                                  <div className="flexRowStart">
                                    <img src={getCateImage(rank?.agg_cate)} alt="cate icon" />
                                    <p>#{koKRFormat(rank?.agg_rank) || 'N/A'}</p>
                                  </div>
                                ),
                                onClick: () => moveToPage(PATH.PROFILE_PAGE, { mem_email: userProfile?.mem_email, mem_key: userProfile?.mem_key, ranking: 'true', cateCd: rank?.agg_cate }),
                              };
                            }),
                          }}
                        >
                          <div className="flexRowStart">
                            <img src={getCateImage(cateRankList[0]?.agg_cate, true) || ''} alt="cate icon" />
                            <p>#{koKRFormat(cateRankList[0]?.agg_rank) || 'N/A'}</p>
                            <img src={downArrow} alt="down arrow" />
                          </div>
                        </Dropdown>
                      </div>
                      {/* <div className="star">
                        <Rate isNotEmpty={true} value={selectedPrompt?.score_avg} />
                      </div> */}

                      {/* 뱃지 */}
                      <div className="classBadge">
                        <div className="major">
                          {getMemberBadge(userProfile?.bdg_cd).map((badge, index) => (
                            <Tooltip title={badge?.bdg_nm} overlayClassName="widthAuto">
                              <img key={index} src={badge?.icon_path} alt="icon" />
                            </Tooltip>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flexRowStart">
                {/* 전문 AI 분야 */}
                <div className="majorModel">
                  <span className="title">전문 AI분야</span>
                  {userProfile?.ai_fl_cd ? (
                    <>
                      {getMemberAiModel(userProfile?.ai_fl_cd).map((aiModel, index) => (
                        <span key={index} className="">
                          {aiModel?.cd_nm}
                        </span>
                      ))}
                    </>
                  ) : (
                    <>
                      {userProfile?.mem_key === memKey ? (
                        <span className="me" onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: memEmail, mem_key: memKey, profileDetail: 'true' })}>
                          전문 AI 분야를 설정하세요
                        </span>
                      ) : (
                        <span>없음</span>
                      )}
                    </>
                  )}
                </div>
              </div>

              {userProfile?.mem_key !== memKey && (
                <div className="flexRowStart btnBox" style={{ gap: '16px' }}>
                  {/* <div id="whiteBorderBtn">
                    <img src={boostIcon} />
                    후원하기
                  </div> */}
                  {/* <div id="whiteBorderBtn">
                    <img src={useIcon} />
                    사용의뢰
                  </div> */}
                  <div id={'whiteBorderBtn'} className={isFollowing ? 'active' : ''} onClick={() => requestFollow(isFollowing)}>
                    <img src={followIcon} />
                    {isFollowing ? '팔로잉' : '팔로우'}
                  </div>
                </div>
              )}
            </div>

            <div className="flexColStart contentsBox">
              <p onClick={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: userProfile?.mem_email, mem_key: selectedPrompt?.mem_key })}>
                {userProfile?.mem_nick} <span>님의 다른 컨텐츠</span>
                <RightOutlined />
              </p>

              <div className="contentsList">
                {imageList?.map((image) => (
                  <CardSquareImage2 data={image} moveToDetailPage={() => moveToDetailPage(image)} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailImage;
