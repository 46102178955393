import React from 'react'
import { Modal } from 'antd';
import Ticket from './Ticket';

const AvailableCoupons = (props) => {
  const { visible, onClose, availableCoupons, registerCoupon, registAllCoupon } = props;

  return (
    <Modal
      open={visible}
      title="쿠폰"
      onCancel={() => {
        onClose();
      }}
      footer={[
        <div className='flexWrapCenter' style={{ gap: '10px' }}>
          <button className='btn-grad' onClick={() => registAllCoupon()}>전체등록</button>
          <button className='btn-white' onClick={() => onClose()}>취소</button>
        </div>
      ]}

    >
      <div className="modalContent">
        <div className="ticketList">
          {/* 티켓을 사용하면 상태 'off' */}
          {availableCoupons && availableCoupons.map((item) => {
            return (
              <Ticket 
                status={item.status}
                cpn_cd={item.cpn_cd}
                cpn_nm={item.cpn_nm}
                use_start_dt={item.use_start_dt_str}
                use_end_dt={item.use_end_dt_str}
                d_cnt={item.d_cnt}
                registerCoupon={registerCoupon}
              />
            )
          })}
          {/* <Ticket status='off' /> */}
        </div>
        <div className="notice">
          <p>유의사항</p>
          <p>
            - ID 당 1회 사용 가능하며, 타 이벤트 쿠폰 할인과 중복되지 않습니다. <br />
            - 쿠폰 혜택은 혜택 유효기한까지만 유지됩니다. <br />
            - 등록하지 않은 쿠폰은 소진되거나 삭제할 수 있습니다. <br />
          </p>
        </div>
      </div>      
    </Modal>
  )
}

export default AvailableCoupons