import React from 'react';

const ButtonCopy = ({ onCopy }) => {
  return (
    <button id="pinkBtn" className="fill" onClick={onCopy}>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.25 2.25C2.25 2.05109 2.32902 1.86032 2.46967 1.71967C2.61032 1.57902 2.80109 1.5 3 1.5H12C12.1989 1.5 12.3897 1.57902 12.5303 1.71967C12.671 1.86032 12.75 2.05109 12.75 2.25C12.75 2.44891 12.671 2.63968 12.5303 2.78033C12.3897 2.92098 12.1989 3 12 3H3.75V12C3.75 12.1989 3.67098 12.3897 3.53033 12.5303C3.38968 12.671 3.19891 12.75 3 12.75C2.80109 12.75 2.61032 12.671 2.46967 12.5303C2.32902 12.3897 2.25 12.1989 2.25 12V2.25ZM5.25 5.25C5.25 5.05109 5.32902 4.86032 5.46967 4.71967C5.61032 4.57902 5.80109 4.5 6 4.5H15C15.1989 4.5 15.3897 4.57902 15.5303 4.71967C15.671 4.86032 15.75 5.05109 15.75 5.25V14.25C15.75 14.8467 15.5129 15.419 15.091 15.841C14.669 16.2629 14.0967 16.5 13.5 16.5H7.5C6.90326 16.5 6.33097 16.2629 5.90901 15.841C5.48705 15.419 5.25 14.8467 5.25 14.25V5.25Z"
          fill="white"
        />
      </svg>
      &nbsp; 복사
    </button>
  );
};

export default ButtonCopy;
