import React  from 'react'
import { Modal } from 'antd';

const SignUpAgreeTerms = (props) => {

  const { visible, onClose, agreeCode} = props;

  const handleOpen = () => {
    document.querySelector(".signUpTerm1, .signUpTerm2, .signUpTerm3").scrollTop = 0
  }

  return (
    <Modal
      className="termsModal"
      open={visible}
      onCancel={onClose}
      footer={null}
      afterOpenChange={handleOpen}
    >
      {agreeCode === 'AGREE002' ? ( // 서비스 이용약관
        <>
          <h2>프롬프트뱅크 서비스 이용약관</h2>
          <div className="wrap signUpTerm1">
            <div>
              <h4>제 1 조 (목적)</h4>
              <p>이 약관은 프롬프트뱅크(주)(이하 “회사”)가 제공하는 서비스 및 관련 제반 서비스(이하 “서비스”)의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다. 본 약관은 이용자가 회사가 제공하는 스마트폰, 태블릿 기기용 앱 등을 이용하는 경우에도 그 성질에 반하지 않는 한 준용됩니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 2 조 (용어의 정의)</h4>
              <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
              <p>1. 서비스: 회사가 웹사이트(www.promptbank.com), 모바일 어플리케이션, Web/PC버전 등 소프트웨어를 통해 제공하는 서비스 및 관련 제반 서비스를 의미합니다.</p>
              <p>2. 회원: 본 약관에 동의하고 서비스를 이용하는 자를 의미합니다.</p>
              <p>3. 회원 정보: 서비스를 이용하기 위해 회사가 정한 필수 내지 선택 입력 정보로서 프롬프트뱅크 웹사이트 또는 어플리케이션을 통해 정보 확인, 변경 처리 등을 관리할 수 있는 회원과 관련된 정보 항목을 의미합니다.</p>
              <p>4. 사용 계정: 회원의 식별 및 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 조건의 문자와 숫자의 조합을 의미합니다.</p>
              <p>5. 비밀번호: 회원이 통신상의 비밀보호를 위해 회원 자신이 정한 문자, 숫자 또는 부호의 조합을 의미합니다.</p>
              <p>6. 유료 서비스:  회사가 제공하는 서비스 중 유료로 제공하는 각종 서비스를 의미합니다.</p>
              <p>7. 멤버십 서비스: 회사가 무료 또는 유료로 제공하는 구독 형태의 서비스로  유료 구독 서비스의 경우, 계약기간동안 매월 또는 매년 단위로 이용 요금을 청구할 수 있습니다.</p>
              <p>8. 부가 서비스: 회사 제공하는 기본 구독 형태인 멤버십 서비스와 별도로 제공하는 무료 또는 유료 서비스를 의미합니다.</p>
              <p>9. 할인쿠폰: 회사에서 제공하는 서비스를 이용할 때 표시된 금액 또는 비율만큼 할인 받을 수 있는 쿠폰을 말합니다.</p>
              <p>10. 게시물 혹은 컨텐츠: 회원이 서비스를 이용함에 있어 서비스 상에 게시한 부호ㆍ문자ㆍ음성ㆍ음향ㆍ화상ㆍ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.</p>
              <p>11. 결제: 회원이 유료서비스를 이용하기 위하여 비용을 회사에 지급하는 것을 의미합니다.</p>
              <p>12. 월간 계약: 회원이 서비스 이용기간을 1개월 단위로 약정하고, 회원이 해지 의사를 표명하지 않을 경우 매월 자동 갱신되는 계약을 의미합니다.</p>
              <p>13. 리셀링 파트너: 회사로부터 서비스에 대한 판매 권한을 위임 받아 수행하고, 회원이 프롬프트뱅크에 서비스 파트너 또는 제3자의 솔루션, 서비스 등을 결합⋅연동하는 업무를 대행할 수 있도록 회사로부터 허락을 받은 리퍼럴 또는 리셀러 파트너를 의미합니다.</p>
              <p>14. 이 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 프롬프트뱅크 서비스 이용약관, 관계 법령 및 개별약관 등(이 약관에서 정의하는 바에 따름)에서 정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 3 조 (약관의 공시 및 효력과 변경 등)</h4>
              <p>1. 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 웹사이트의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다. 본 약관에 동의한 회원 모두에게 그 효력이 발생합니다.</p>
              <p>2. 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p>
              <p>3. 회사가 이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 회사의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.</p>
              <p>4. 고객은 개정된 약관에 대해 거부할 권리가 있으며, 개정된 약관에 동의하지 않을 경우 이용계약 해지를 요청할 수 있으며, 개정된 약관의 효력 발생일 이후에도 서비스를 계속 이용할 경우 약관의 변경사항에 동의하는 것으로 간주합니다. 회원이 개정약관에 동의하지 않을 경우 회원은 이용계약을 해지할 수 있습니다.</p>
              <p>5. 회사는 이 약관 외에 별도의 이용약관 및 정책(이하 “개별약관등”)을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우에는 개별약관등이 우선하여 적용됩니다.</p>
              <p>6. 본 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 운영정책, 규칙, 도움말, 홈페이지의 공지 또는 통지(이하 ‘세부지침’) 및 회원과 회사 간 체결한 별도의 서면 계약(이하 ‘서면 계약’) 내용에 따릅니다. 별도의 서면 계약을 체결한 회원에 대해서도 본 약관 및 세부지침이 적용됩니다.</p>
              <p>7. 본 약관과 세부지침 또는 서면 계약의 내용이 상충될 경우 서면 계약, 세부지침, 본 약관의 순서로 우선하여 적용됩니다. 세부지침도 서비스 이용 계약의 일부를 구성합니다.</p>
              <p>8. 회사는 서비스 이용과 관련하여 범죄를 예방하고 사회윤리를 존중하는 것을 내용으로 하는 회사와 회원이 준수해야 하는 윤리가이드를 서비스 내에 게시할 수 있으며, 해당 윤리가이드는 이 약관의 내용을 이룹니다. 회원은 이 약관에 동의함으로써 윤리가이드의 준수에 동의한 것으로 봅니다.</p>
              <p>9. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 개별약관등 및 관계 법령 또는 상관례에 따릅니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 4 조 (회사의 의무)</h4>
              <p>1. 회사는 관련 법령과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위해 최선을 다하여 노력합니다.</p>
              <p>2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보보호법 및 관련 법령이 정하는 바에 따라 보안 시스템 등을 갖추고 회원의 개인정보를 보호하기 위해 노력하며, 회사의 귀책사유로 인한 회원의 개인정보 도용 및 유출 등에 대해 책임을 집니다.</p>
              <p>3. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 웹사이트(www.promptbank.com) 이외의 링크 사이트에 대해서는 회사의 개인정보처리방침이 적용되지 않습니다.</p>
              <p>4. 회사는 서비스 이용과 관련하여 발생하는 회원의 불만 또는 피해구제요청을 적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비합니다.</p>
              <p>5. 회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 합니다. 회원이 제기한 의견이나 불만사항에 대해서는 서비스 홈페이지, 이메일, 또는 고객문의 전용 메신저 등을 통하여 회원에게 처리과정 및 결과를 전달할 수 있습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 5 조 (회원의 의무)</h4>
              <p>1. 회원은 관련 법령, 이 약관 및 회사의 운영정책, 고객센터 내 도움말, 서비스와 관련하여 회사가 공지한 주의사항 및 통지사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</p>
              <p>2. 회원은 아래 각 호의 행위를 해서는 안 됩니다.</p>
              <p>● 서비스의 신청 또는 변경 시 허위내용의 기재</p>
              <p>● 타인의 정보 또는 결제수단의 도용</p>
              <p>● 회사에 게시된 정보의 변경 또는 또는 서비스에 장애를 주는 행위</p>
              <p>● 다른 회원의 개인정보 및 계정정보를 무단으로 수집∙저장∙게시∙유포하는 행위</p>
              <p>● 리버스엔지니어링, 디컴파일, 디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또는 모방 기타 변형하는 행위</p>
              <p>● 해킹, 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를 이용하여 회사의 서버에 부하를 일으켜 회사의 정상적인 서비스를 방해하는 행위</p>
              <p>● 본인 아닌 제3자에게 계정을 대여, 양도하는 등 접속권한을 부여하는 행위</p>
              <p>● 회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</p>
              <p>● 도박 등 사행행위를 하거나 유도하는 행위, 음란⋅저속한 정보를 입력⋅교류⋅게재하거나 음란 사이트를 연결(링크)하는 행위, 수치심⋅혐오감 또는 공포심을 일으키는 말⋅소리⋅글⋅그림⋅사진 또는 영상을 타인에게 전송 또는 유포하는 행위 등 서비스를 불건전하게 이용하는 행위</p>
              <p>● 게시판을 도배할 목적에서 동일 또는 유사한 게시글 또는 의미가 없는 게시글을 반복적으로 개시하는 행위</p>
              <p>● 회사의 동의 없이 영리, 영업, 광고, 홍보, 정치활동, 선거운동 등 본래의 용도 이외의 용도로 서비스를 이용하는 행위</p>
              <p>● 회사의 서비스를 이용하여 얻은 정보를 무단으로 복제∙유통∙조장하거나 상업적으로 이용하는 행위, 알려지거나 알려지지 않은 버그를 악용하여 서비스를 이용하는 행위</p>
              <p>● 타인을 기망하여 이득을 취하는 행위, 회사의 서비스의 이용과 관련하여 타인에게 피해를 입히는 행위</p>
              <p>● 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
              <p>● 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
              <p>● 기타 불법적이거나 부당한 행위</p>
              <p>3. 회원은 관계법령, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.</p>
              <p>4. 회원은 본인의 사용 계정, 비밀번호, 결제수단정보 등에 대한 관리책임을 부담하며, 회원의 관리소홀로 인하여 발생한 문제에 대하여 회사는 책임을 지지 않습니다.</p>
              <p>5. 회원은 유료서비스를 신청하기 전에 반드시 회사가 서비스 홈페이지를 통해 제공하는 상품의 이용조건과 거래조건, 이용약관을 확인한 후 신청하여야 합니다. 이를 확인하지 않고 유료서비스를 신청하여 발생한 모든 손실, 손해에 대한 책임은 회원이 부담합니다.</p>
              <p>6. 회사는 제2항을 위반한 회원에 대하여 계약 해제, 할인쿠폰의 환수, 회원 자격의 제한, 현금 환불 보류 등의 조치 및 민∙형사 상의 법적 조치 등을 취할 수 있습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 6 조 (회원에 대한 통지 및 공지)</h4>
              <p>1. 회사가 회원에 대한 통지를 하는 경우, 회원이 회사와 미리 약정하여 지정한 방법에 따라 서비스 내 알림림, 전자우편, SMS, 전화, 팩스 등으로 할 수 있습니다. 회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 웹사이트 공지사항에 게시함으로써 개별 통지에 갈음할 수 있습니다. 단, 회원에게 불리한 변경 사항을 알리는 경우에는 제2항의 방법으로 통지하거나 효력 발생일 30일 이전에 이를 공지합니다. </p>
              <p>2. 회사가 회원에게 통지해야 하는 경우 회원이 등록한 이메일 주소 등을 이용하거나 서비스 내 알림 등을 통하여 통지하며, 소유자에게 통지한 경우 이는 회원에게 통지한 것으로 봅니다. 회원은 소유자 정보에 대한 관리 및 적절한 정보로의 변경 책임을 부담합니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 7 조 (회원 정보의 제공 및 관리)</h4>
              <p>1. 회원은 본 약관 등에 의해 회사에 정보를 제공하여야 하는 경우에는 진실되고 적법한 정보를 제공하여야 하며, 허위 또는 불법 정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.</p>
              <p>2. 회원의 계정 및 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다. 회사는 제3자가 회원의 계정 및 비밀번호 등을 무단으로 사용하는 것을 막기 위하여 비밀번호 입력 및 추가적인 본인 확인 절차를 거치도록 할 수 있습니다.</p>
              <p>3. 회원이 이용 신청 시 알려주신 내용에 변동이 있을 때에는 직접 수정하시거나 이메일, 고객센터를 통하여 회사에 알려 주시기 바랍니다.</p>
              <p>4. 본 조의 의무를 위반하여 발생하는 모든 책임은 회원에게 있습니다. 회원이 본 조의 의무를 위반하거나 세부지침 또는 회사의 안내를 따르지 않아 발생하는 불이익에 대하여 회사는 책임을 지지 않습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 8 조 (회원 정보의 보호)</h4>
              <p>1. 회사는 본 약관에 벗어난 목적으로 서비스와 관련한 회원 정보에 접근하거나 처리하지 않습니다. 다만, 장애 처리, 회원의 정보보호 등 원활한 서비스 제공을 위하여 접근이 필요한 경우 회원 정보에 접근, 내용을 파악할 수 있습니다.</p>
              <p>2. 회원이 서비스 이용 중에 보관한 회원 정보는 회원의 소유로서 회원이 관리하여야 하며, 회사는 법령이 정하는 바에 따라 회원 정보를 보호합니다.</p>
              <p>3. 회사는 적절한 수준의 보안서비스를 제공하고 사고를 방지할 의무가 있으며 회원은 회원의 주의 의무 위반으로 인한 회원 정보의 도용 및 유출 등에 대해서 책임을 집니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 9 조 (회원 탈퇴 및 자격 상실 등)</h4>
              <p>1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며, 회사는 회원의 회원탈퇴 요청 즉시 회원탈퇴를 처리합니다. 회원탈퇴 시 회원자격이 상실되며, 회사가 제공하는 각종 할인쿠폰, 이벤트 혜택 등이 소멸됩니다. 단, 회원탈퇴 시 소진되지 않은 할인쿠폰, 크레딧 등이 있는 경우에 이에 대한 권리를 포기한 것으로 간주하여, 탈퇴와 동시에 전부 소멸됩니다. 다만, 회사의 이용 대금 후불결제 서비스 이용요금을 결제하지 않은 경우, 탈퇴가 제한됩니다.</p>
              <p>2. 다음 각 호의 경우, 회사는 사전 통지 없이 회원의 서비스 이용을 제한하거나 정지할 수 있습니다.</p>
              <p>● 회원정보에 허위 내용을 입력하거나 타인의 정보를 도용한 경우</p>
              <p>● 회원이 서비스 이용 계약에 명시된 기간 내 회사의 서비스 이용대금 및 기타 회사의 서비스 이용에 관련하여 회원이 부담하는 채무를 기일에 이행하지 않는 경우</p>
              <p>● 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</p>
              <p>● 서비스를 통해 회원이 처리하는 회원의 정보 및 게시물, 컨텐츠에 대해 제3자가 권리 침해를 주장하는 등(이를 포함하되 이에 한정 짓지 않음) 회원의 서비스 이용을 정지시킬 만한 법적 근거가 있는 경우</p>
              <p>● 주소, 전자우편 등 회원정보를 잘못 입력하거나 변경하지 않는 등 회원의 귀책사유로 인하여 회사가 상당한 노력을 기울였음에도 불구하고 회원에게 통지 또는 연락을 할 수 없게 된 경우</p>
              <p>● 이 약관 제 5 조 회원의 의무를 위반하는 행위를 하는 경우</p>
              <p>● 회사의 정상적인 서비스 운영을 방해한 경우</p>
              <p>● 회원이 국가적 이익이나 공익에 반하는 목적을 위해 서비스를 이용하는 경우</p>
              <p>● 그 밖에 회원이 회사가 정한 본 약관, 세부지침 또는 관련 법령을 위반하거나 위 각 호에 준하는 사유로 이용 제한 또는 정지가 필요한 경우</p>
              <p>3. 회사가 본 조 제2항에 따라 회원의 서비스 이용을 제한하거나 정지하는 경우, 회사에 귀책사유가 없을 시 회원은 이로 인한 손해배상을 회사에 청구할 수 없습니다.</p>
              <p>4. 회사가 본 조 제2항에 따라 회원의 서비스 이용을 제한하거나 정지하는 경우, 서면, 이메일, 전화, 기타 방법을 통하여 그 시정 또는 개선을 요구할 수 있고,  30일 이내에 귀책 당사자의 시정 또는 개선 조치가 없을 경우 서면, 이메일, 전화, 기타 방법을 통하여 통지한 후 서비스의 제공을 중지할 수 있습니다.</p>
              <p>5. 회사는 본 조에 따른 서비스 이용 제한 또는 정지 기간 중 발생한 이용요금을 회원에게 청구할 수 있습니다.</p>
              <p>6. 회사가 회원 자격을 상실시키는 경우에는 회원 등록을 말소하며, 이때 회사는 거래 안전을 위하여 해당 회원의 소유로 확인되는 아이디의 회원 등록을 일괄 말소할 수 있습니다. 이 경우 회사는 이를 제7조에 따라 회원에게 통지하고, 회원 등록 말소 전 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 10 조 (멤버십 서비스)</h4>
              <p>1. 회사는 회원에게 회사의 멤버십 서비스의 전부 또는 일부를 유료로 제공할 수 있으며, 계약기간 동안 매월 또는 매년 단위로 이용 요금을 청구할 수 있습니다.</p>
              <p>2. 회원은 서비스 이용 요금을 선불로 지불해야 하며, 서비스 이용 요금은 프롬프트뱅크 웹사이트에 등록되어 있는 멤버십 플랜 (비기너, 베이직, 프로) 에 따르며, 멤버십 서비스의 플랜 명칭은 변경될 수 있습니다.</p>
              <p>3. 멤버십 플랜에 따라 접근할 수 있는 권한과 서비스의 범위가 다르며, 그에 따른 금액도 다릅니다. 하위 멤버십 플랜에서 상위 멤버십 플랜으로 다음과 같이 구성되어 있습니다.</p>
              <p>○ 순서 (하위 {'>'} 상위): 비기너(무료) {'>'} 베이직(유료) {'>'} 프로(유료)</p>
              <p>4. 회원은 유료 멤버십 서비스를 신청할 때 월간 계약으로 진행되며, 다음 각 호의 사항을 유의하여야 합니다.</p>
              <p>● 월간 계약</p>
              <p>○ 월간 계약 대금을 결제하는 날짜가 계약일 기준으로, 익월 같은 날짜에 계약 종료 및 갱신됩니다. 해당 월에 동일 날짜가 없는 경우 말일에 계약 종료 및 갱신됩니다.</p>
              <p>■ 월간 계약 시작일 예시: 24년 3월 14일</p>
              <p>■ 월간 계약 종료일&갱신일 예시: 24년 4월 14일</p>
              <p>5. 회원은 멤버십 플랜과 계약기간을 변경할 수 있으며, 다음 각 호의 사항을 유의하여야 합니다.</p>
              <p>○ 멤버십 플랜에 따른 디지털 컨텐츠의 공개 범위가 상이하므로, 회원은 유료 멤버십 플랜을 이용 중인 경우 계약기간 동안 하위 유료 멤버십 또는 무료 멤버십 플랜으로 전환할 수 없습니다. 단, 계약기간 종료 이후에는 전환할 수 있습니다.</p>
              <p>○ 회원은 현재 유료 멤버십 계약 조건일 경우, 다음 각 호의 사항을 유의하여야 합니다.</p>
              <p>○ 월간 계약</p>
              <p>■ 상위 유료 멤버십 플랜으로 변경</p>
              <p>회원은 현재 유료 멤버십 플랜을 상위 유료 멤버십 플랜으로 전환할 수 있습니다. 상위 멤버십 플랜으로 전환할 경우, 상위 멤버십 플랜 월간 계약금액과 기존 유료 멤버십 플랜의 사용한 일자에 대해 일할 계산하여 각각 청구되며, 초기 지불한 기존 유료 멤버십 플랜 계약금액은 환불됩니다.</p>
              <p>■ 상위 유료 멤버십 플랜으로 변경 시 계약 기간 변경</p>
              <p>상위 유료 멤버십 플랜으로 변경하는 일자로 계약 시작일이 갱신됩니다.</p>
              <p>6. 회원은 계약기간 만료일까지 조건을 변경하지 않은 경우 서비스 이용 계약은 자동 갱신으로 처리됩니다. </p>
              <p>7. 멤버십 서비스 이용 중에 계약 해지를 요청할 경우, 계약이 완료되는 시점에 자동 갱신을 취소할 수 있습니다.</p>
              <p>8. 멤버십 플랜 상품 변경에 관한 구체적인 내용은 프롬프트뱅크 웹사이트에서 정한 내용을 따릅니다.</p>
              <p>9. 회원은 유료서비스 결제와 관련하여 회원이 입력한 정보가 정확한지 여부를 확인해야 하며, 그 정보와 관련하여 발생한 책임과 불이익을 전적으로 부담합니다. 회원은 결제 정보 입력 시 (또는 회사가 지정한 결제 대행업체) 해당 거래에 필요한 결제 금액 전체를 청구할 수 있도록 허가한 것으로 간주합니다.</p>
              <p>10. 회원은 유료서비스에 대해서 세금을 포함하여 지불해야 하고 세금은 회원이 계약하는 회사의 주소지 국가 세법에 따라서 지정됩니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 11 조 (부가 서비스)</h4>
              <p>1. 회사는 회원에게 회사의 부가 서비스의 전부 또는 일부를 유료로 제공할 수 있으며, 부가 서비스의 성격에 따라 계약기간 동안 매월 단위 또는 일회성 이용 요금을 청구할 수 있습니다. </p>
              <p>2. 회원은 서비스 이용 요금을 선불로 지불해야 하며, 서비스 이용 요금은 프롬프트뱅크 웹사이트에 등록되어 있는 부가 서비스에 따릅니다.</p>
              <p>3. 계약기간이 존재하는 부가 서비스 플랜의 경우, 결제 시점에 데이터 공간 확보가 확정되고 부가 서비스 플랜에 따라 데이터 공간 확보 범위가 상이하므로 회원은 유료 부가 서비스 플랜을 이용 중인 경우 계약기간 동안 하위 유료 부가 서비스로 전환할 수 없습니다. 단, 계약기간 종료 이후에는 전환할 수 있습니다.</p>
              <p>4. 회원은 일회성이 아닌 계약기간이 존재하는 유료 부가 서비스를 신청할 때, 월간 계약을 지원하는 서비스가 있을 경우, 다음 각 호의 사항을 유의하여야 합니다.</p>
              <p>● 월간 계약</p>
              <p>○ 월간 계약 대금을 결제하는 날짜가 계약일 기준으로, 익월 같은 날짜에 계약 종료 및 갱신됩니다. 해당 월에 동일 날짜가 없는 경우 말일에 계약 종료 및 갱신됩니다.</p>
              <p>■ 월간 계약 시작일: 24년 3월 14일</p>
              <p>■ 월간 계약 종료일&갱신일: 24년 4월 14일</p>
              <p>○ 상위 유료 부가 서비스 플랜으로 변경</p>
              <p>회원은 현재 유료 부가 서비스 플랜을 상위 유료 부가 서비스 플랜으로 전환할 수 있습니다. 상위 유료 부가 서비스 플랜으로 전환할 경우, 상위 유료 부가 서비스 플랜 월간 계약금액과 기존 유료 부가 서비스 플랜의 사용한 일자에 대해 일할 계산하여 각각 청구되며, 초기 지불한 기존 유료 부가 서비스 플랜 계약금액은 환불됩니다.</p>
              <p>5. 회원은 계약기간 만료일까지 조건을 변경하지 않은 경우 서비스 이용 계약은 자동 갱신으로 처리됩니다. </p>
              <p>6. 부가 서비스 이용 중에 계약 해지를 요청할 경우, 계약이 완료되는 시점에 자동 갱신을 취소할 수 있습니다.</p>
              <p>7. 부가 서비스 상품 변경에 관한 구체적인 내용은 프롬프트뱅크 웹사이트에서 정한 내용을 따릅니다.</p>
              <p>8. 회원은 유료서비스 결제와 관련하여 회원이 입력한 정보가 정확한지 여부를 확인해야 하며, 그 정보와 관련하여 발생한 책임과 불이익을 전적으로 부담합니다. 회원은 결제 정보 입력 시 (또는 회사가 지정한 결제 대행업체) 해당 거래에 필요한 결제 금액 전체를 청구할 수 있도록 허가한 것으로 간주합니다.</p>
              <p>9. 회원은 유료서비스에 대해서 세금을 포함하여 지불해야 하고 세금은 회원이 계약하는 회사의 주소지 국가 세법에 따라서 지정됩니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 12 조 (지적 재산권 등의 귀속 등)</h4>
              <p>1. 회원은 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사에 지식재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.</p>
              <p>2. 회사가 회원이 게시물을 게재할 수 있는 서비스를 제공하는 경우, 해당 게시물의 저작권은 이를 게시한 이용자에게 귀속됩니다.</p>
              <p>3. 회사는 서비스 특성상 회원이 서비스가 제공하는 기능을 사용하여, 서비스상 작성된 결과물이나 결과물 작성과정에 노출된 각종 정보가 타인의 저작권과 기타 지적재산권을 침해하지 않음을 보증하지 않습니다. 회원이 서비스가 제공하는 기능을 사용하여, 서비스상 작성된 결과물의 사용으로 인한 책임은 회원에게 있습니다.</p>
              <p>4. 회사는 회원이 서비스 내에 게시한 게시글 및 회원이 서비스가 제공하는 기능을 사용하여, 서비스상 작성된 결과물을 서비스 내 노출, 서비스 홍보를 위한 활용, 서비스 운영, 개선 및 새로운 서비스 개발을 위한 연구 목적을 위하여 저장, 복제, 수정, 공중 송신, 전시, 배포, 2차적 저작물 작성의 방식으로 이용할 수 있습니다.</p>
              <p>5. 회사는 서비스 이용 약관 및 개인정보처리방침에 설명된 경우를 제외하고는 회원의 정보, 파일 및 데이터를 다른 사람과 공유하지 않습니다.</p>
              <p>6. 회원은 자신이 서비스 이용과정에서 입력하는 글, 이미지 기타 자료가 제3자의 지적재산권 기타 권리를 침해하지 않는다는 점을 보증합니다. 회원이 이를 위반하여 제3자와 회원 또는 회사 사이에 소송, 이의제기, 권리주장 기타 일체의 분쟁이 발생하는 경우, 회원은 회사를 면책하고 해당 분쟁을 해결하여야 하며, 이로 인해 회사가 손해를 입은 경우 해당 손해를 배상하여야 합니다.</p>
              <p>7. 이 조는 회사가 서비스를 운영하는 동안 유효하며, 회원 탈퇴 후에도 지속적으로 적용됩니다.</p>
              <p>8. 회사는 저작권법 제103조가 적용되는 경우, 이에 따라 복제 및 전송을 중단할 수 있습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 13 조 (서비스 가입의 제한)</h4>
              <p>1. 회사는 원칙적으로 신청자에게 서비스 가입을 승낙합니다. 다만, 회사는 아래 각 호의 경우에는 그 사유가 해소될 때까지 승낙을 유보하거나 승낙하지 않을 수 있습니다.</p>
              <p>● 가입 시 필요한 정보를 입력하지 않거나 허위의 정보를 입력한 경우</p>
              <p>● 만 14세 미만의 자가 이용신청을 하는 경우</p>
              <p>● 회사가 본 약관 또는 세부지침에 의해 회원의 계약을 해지하였던 경우</p>
              <p>● 회사로부터 이용 제한 등의 조치를 받은 회원이 그 조치기간에 서비스 이용 계약을 임의로 해지하고 재이용을 신청하는 경우</p>
              <p>● 서비스 관련 설비에 여유가 없는 경우</p>
              <p>● 서비스 제공을 위한 기술적인 부분에 문제가 있다고 판단되는 경우</p>
              <p>● 기술상 또는 업무상 지장이 있는 경우</p>
              <p>● 기타 회사 사정상 필요하다고 인정되는 경우</p>
              <p>● 다른 사람의 명의를 사용하여 신청한 경우</p>
              <p>● 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우</p>
              <p>● 서비스 이용권한을 대여 또는 재판매한 경우</p>
              <p>● 회사 및 타 회원 또는 그 직원이나 관계자로 오인될 우려가 있는 경우</p>
              <p>● 기타 회사가 정한 이용신청 요건이 미비한 경우</p>
              <p>● 기타 회사가 재정적, 기술적으로 필요하다고 인정하는 경우</p>
              <p>● 기타 관련 법령에 위배되거나 세부지침 등 회사가 정한 기준에 반하는 경우</p>
              <p>2. 만약, 회원이 위 조건에 위반하여 서비스에 가입한 것으로 판명된 때에는 회사는 즉시 회원의 서비스 이용을 제한하거나 계약을 해지하는 등 적절한 제한 조치를 할 수 있습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 14 조 (서비스의 이용)</h4>
              <p>1. 회원은 이용 중인 플랜이나 소유자의 설정에 따라서 서비스 내 추가적인 기능들을 사용할 수 있으며, 이에 대한 자세한 내용은 프롬프트뱅크 웹사이트에서 확인할 수 있습니다.</p>
              <p>2. 회사는 회원 별 서비스 이용 가능 기기의 종류, 기기 대 수 등을 설정 또는 변경할 수 있습니다.</p>
              <p>3. 회원은 필요에 따라 알맞은 플랜을 선택하여야 하며, 회사는 회원의 비정상적인 API 확장 및 봇 제작, 계정 돌려쓰기 등 악의적 사용을 금지합니다.</p>
              <p>4. 비정상적으로 많은 사용량과 계정 로그인 세부 정보를 다른 사용자와 공유하는 것으로 합리적으로 의심되는 활동이 적발되는 경우 사전 통보 없이 계정이 차단 혹은 영구 삭제가 될 수 있습니다. 이에 관련하여 어떠한 환불도 이루어지지 않습니다.</p>
              <p>5. 이 약관에 명시되지 않은 서비스 이용에 관한 사항은 회사가 정해 웹사이트 내에 게시하거나 또는 별도로 공지하는 내용에 따릅니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 15 조 (서비스의 중단)</h4>
              <p>1. 회사는 아래 각 호의 경우 서비스 이용의 전부 또는 일부를 중단할 수 있습니다.</p>
              <p>● 서비스용 설비의 유지·보수, 업그레이드, 서비스 진단 등을 위한 정기/임시 점검 또는 사전에 공지/통지한 후 서비스를 중단한 경우</p>
              <p>● 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 어려운 경우</p>
              <p>● 정전, 제반 설비의 장애 등으로 정상적인 서비스 이용에 지장이 있는 경우</p>
              <p>● 정부의 명령/규제 등 회사의 제반 사정으로 서비스를 유지할 수 없는 경우</p>
              <p>● 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</p>
              <p>● 전기통신 서비스 제공업체(전기통신사업법에 명시)가 통신 서비스를 중단한 경우</p>
              <p>2. 서비스의 전부 또는 일부가 중단되는 경우 회원에게 공지 또는 통지하겠습니다. 다만, 예측할 수 없거나 통제할 수 없는 사유(회사의 과실이 없는 디스크 내지 서버 장애, 시스템 다운 등)로 인해 사전 공지 또는 통지가 불가능한 경우에는 사후에 할 수 있습니다.</p>
              <p>3. 회사는 영업양도∙분할∙합병 등에 따른 영업의 폐지, 컨텐츠 제공의 계약만료, 당해 서비스의 현저한 수익 악화 등 경영상의 중대한 사유로 인해 서비스를 지속하기 어려운 경우에는 서비스를 중단할 수 있습니다. 이 경우 중단일자 30일 이전까지 중단일자 및 사유를 웹사이트, 앱 초기화면 또는 그 연결화면 (앱을 제공할 경우), 회사 홈페이지 등 기타 서비스 제공 화면을 통해 공지하고 회원에게 통지합니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 16 조 (회원의 게시물)</h4>
              <p>1. 회원이 서비스에 등록 또는 게시한 게시물 등에 대한 모든 책임은 회원에게 있으며, 회사는 회원의 게시물이 다음 각 항에 해당한다고 인정되는 경우 사전 통지 없이 해당 게시물에 대한 접근을 임시적으로 차단하는 조치를 할 수 있고, 이 경우에 회사는 회원에게 지체없이 그 사유를 통지합니다. 게시물에 대한 임시 차단 조치에 대하여 회원은 이의를 제기할 수 있습니다.</p>
              <p>● 타인을 모욕, 비방, 비하하거나 타인의 명예를 훼손하는 경우</p>
              <p>● 범죄 및 불법 행위에 악용될 수 있는 정보를 담고 있는 경우</p>
              <p>● 타인의 저작권, 상표권 등 권리를 침해하는 경우</p>
              <p>● 음란물 또는 일반인의 성적 굴욕감이나 수치심을 유발할 수 있는 내용을 담고 있는 경우(그러한 내용이 담긴 웹사이트, 앱 등으로 연결될 수 있는 게시물을 포함)</p>
              <p>● 어린이와 청소년의 정신적, 신체적 건강을 해칠 우려가 있는 선정적인 내용을 담은 경우</p>
              <p>● 특정인의 개인정보가 노출된 경우</p>
              <p>● 특정 상품 등을 소개하여 판매하거나, 이를 구매, 사용하도록 권하거나 연락을 유도하는 등 직간접적으로 영리추구를 위한 내용을 담고 있는 경우</p>
              <p>● 서비스의 이용 목적에 부합하지 않거나 해당 서비스의 취지와 무관한 내용의 경우</p>
              <p>● 악성코드나 스파이웨어 등이 실행되어 다른 회원, 회사, 판매자 기타 제3자의 시스템 성능 저하, 개인정보 유출 등의 피해를 줄 수 있는 악의적인 코드를 담고 있는 경우</p>
              <p>● 정당한 사유 없이 회사의 영업을 방해하는 내용의 경우</p>
              <p>● 기타 이 약관 또는 법령에 위배되거나 공서양속에 반하는 내용의 경우</p>
              <p>2. 회원이 탈퇴하는 경우, 탈퇴 전에 작성한 게시물(댓글 포함)은 삭제되지 않습니다. 회원 탈퇴 시 회원정보가 삭제되어 작성자 본인을 확인할 수 없게 되므로 게시물에 대한 편집 또는 삭제가 원천적으로 불가하며, 게시물의 삭제를 원하는 회원은 반드시 회원 탈퇴 이전에 해당 내용을 삭제하여야 합니다.</p>
              <p>3. 회원은 회사 또는 회사가 제휴한 타사에 회원이 작성한 게시물을 상품 등의 연구개발, 판촉, 홍보 등의 서비스 제공을 위한 범위 내에서 공중송신∙복제∙배포∙공연∙대여∙전시∙2차적 저작물 작성∙서브라이선스를 할 수 있는 비배타적인 사용권을 부여하며, 상품 등의 연구개발, 판촉, 홍보 등의 서비스 제공을 위한 범위 내에서 회사 또는 회사와 제휴한 타사가 수정 및 편집하여 이용할 수 있고 저작자 표시를 생략할 수 있음에 동의합니다.  이 경우 회사는 해당 게시물로부터 회원의 개인정보가 식별되지 않도록 필요한 조치를 합니다. 게시물의 활용이 부적절하거나 동의한 범위 및 목적 외로 사용되는 경우 회원은 회사에 고객센터 등으로 의견을 제기할 수 있으며 회사는 그에 따른 적절한 절차를 마련하여 운영합니다. 다만, 본 조항의 어떤 내용도 법령에서 허용되는 범위를 넘어서는 사용권을 부여하는 것은 아닙니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 17 조 (게시물의 관리)</h4>
              <p>1. 회사는 불법 여부 또는 AI 윤리 규정 위반 여부를 판단하기 위해 컨텐츠를 검토할 수 있으며 회사의 정책 또는 법규를 위반한다고 합리적으로 판단되는 컨텐츠는 삭제하거나 게시를 거부할 수 있습니다.</p>
              <p>2. 회원의 게시물에 정보통신망이용촉진및정보보호등에관한법률, 저작권법 등 관련법에 위반되는 내용이 포함된 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야 합니다.</p>
              <p>3. 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 게시물에 대해 임시조치 등을 취할 수 있습니다.</p>
              <p>4. 본 조에 따른 세부절차는 정보통신망이용촉진및정보보호등에관한법률, 저작권법이 규정한 범위 내에서 회사가 정한 게시중단요청서비스에 따릅니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 18 조 (구매신청 및 개인정보 제공 동의 등)</h4>
              <p>1. 회원은 서비스 상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 회사는 회원이 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.</p>
              <p>○ 회사가 제공하는 해당 서비스의 검색 및 선택</p>
              <p>○ 필요에 따라 구매자의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력</p>
              <p>○ 약관내용, 청약철회권이 제한되는 서비스 등의 비용부담과 관련한 내용에 대한 확인</p>
              <p>○ 이 약관에 동의하고 위 제3호의 사항을 확인하거나 거부하는 표시(예. 마우스 클릭)</p>
              <p>○ 서비스의 구매신청 및 이에 관한 확인 또는 회사의 확인에 대한 동의</p>
              <p>○ 결제방법의 선택</p>
              <p>2. 회사가 제3자에게 회원의 개인정보를 제공할 필요가 있는 경우 실제 구매신청 시 회원의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이때 회사는 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용목적 및 보유·이용기간 등을 회원에게 명시하여야 합니다.</p>
              <p>3. 회사가 제3자에게 회원의 개인정보 처리(개인정보의 수집, 생성, 연계, 연동, 기록, 저장, 보유, 가공, 편집, 검색, 출력, 정정(訂正), 복구, 이용, 제공, 공개, 파기(破棄), 그 밖에 이와 유사한 행위를 포함) 업무를 위탁할 필요가 있는 경우 실제 구매신청 시 회원의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이때 회사는 개인정보 처리위탁을 받는 자, 개인정보 처리위탁을 하는 업무의 내용 등을 회원에게 명시하여야 합니다. 다만, 『정보통신망이용촉진 및 정보보호 등에 관한 법률』에 달리 정함이 있는 경우에는 그에 따릅니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 19 조 (계약의 성립)</h4>
              <p>1. 서비스 이용계약은 이용신청자가 회원가입 안내에서 이 약관의 내용에 대하여 동의하고 등록절차(회사의 소정 양식의 가입 신청서 작성)를 통해 서비스 이용 신청을 하고, 회사가 그 신청에 대해서 승낙함으로써 체결됩니다.</p>
              <p>2. 회원은 회원가입 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 그 변경사항을 알려야 합니다.</p>
              <p>3. 제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</p>
              <p>4. 프롬프트뱅크는 가입신청자의 신청에 대하여 프롬프트뱅크 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니하거나, 이용자의 귀책사유로 인하여 승인이 불가능한 경우, 실명이 아니거나 타인의 명의를 이용한 경우, 기타 이 약관의 제반 사항을 위반한 경우 등에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다. 회원가입신청의 승낙을 하지 아니하거나 유보한 경우, 회사는 원칙적으로 이를 가입신청자에게 알립니다.</p>
              <p>5. 프롬프트뱅크는 회원에 대하여 청소년보호법, 영화및비디오물의진흥에관한법률 등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있고, 성인인증 절차를 거치도록 할 수 있습니다.</p>
              <p>6. 회사가 제공하는 서비스 상품은 기본 구독 상품인 멤버십 서비스 상품과 그외 부가 서비스 상품으로 구분되며, 상세한 사항은 프롬프트뱅크 웹사이트를 통해 확인할 수 있습니다. 회원의 상품 선택에 따라 회사가 회원에게 제공하는 서비스의 상세 내용 및 제공 수준이 결정됩니다.</p>
              <p>7. 리셀링 파트너를 통해 유료서비스 이용에 대한 계약을 체결한 회원에 대해서도 본 약관을 포함한 세부지침이 적용됩니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 20 조 (지급방법)</h4>
              <p>1. 서비스 구매에 대한 대금지급 방법은 다음 각 호의 방법 중 가용한 방법으로 할 수 있습니다.</p>
              <p>● 폰뱅킹, 인터넷뱅킹 등의 각종 계좌이체</p>
              <p>● 선불카드, 직불카드, 신용카드 등의 각종 카드 결제</p>
              <p>● 온라인 무통장입금</p>
              <p>● 전자화폐에 의한 결제</p>
              <p>● 수령 시 대금지급</p>
              <p>● 할인쿠폰, 크레딧 등에 의한 결제</p>
              <p>● 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제</p>
              <p>● 기타 전자적 지급 방법에 의한 대금 지급 등</p>
              <p>2. 회원이 구매 대금의 결제와 관련하여 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 회원이 부담합니다.</p>
              <p>3. 회사는 상품 등의 대금에 회원의 지급방법에 대한 수수료를 추가하여 징수할 수 없습니다. 다만, 회사는 상품대금 후불결제서비스 이용시 고지된 연체료 등 지연손해금을 회원에게 청구할 수 있습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 21 조 (할인쿠폰)</h4>
              <p>1. 할인쿠폰은 회사가 무상으로 발행하는 쿠폰으로 발행대상, 발행경로, 사용대상 등에 따라 구분될 수 있으며, 할인쿠폰의 세부구분, 할인금액(할인율), 사용방법, 사용기한 및 제한에 대한 사항은 할인쿠폰 또는 서비스 화면에 표시됩니다. 할인쿠폰의 종류 및 내용과 발급 여부는 회사의 서비스 정책에 따라 변경될 수 있습니다.</p>
              <p>2. 할인쿠폰은 현금으로 출금될 수 없으며, 할인쿠폰에 표시된 유효기간이 만료되거나 회원자격이 상실되면 소멸합니다.</p>
              <p>3. 할인쿠폰은 회사에서 별도로 명시한 경우를 제외하고는 타인에게 양도할 수 없으며, 부정한 목적이나 용도로 사용할 수 없습니다. 이를 위반한 경우 회사는 할인쿠폰을 소멸시키거나 회원자격을 제한 또는 상실시킬 수 있습니다.</p>
              <p>4. 회원이 부정한 방법으로 할인쿠폰, 크레딧 등을 획득한 사실이 확인될 경우, 회사는 이를 회수하고, 회원자격 상실(아이디 삭제) 및 민∙형사 상의 법적 조치 등을 취할 수 있습니다.</p>
            </div>
            <br/>
            
            <div>
              <h4>제 22 조 (수신확인통지·구매신청 변경 및 취소)</h4>
              <p>1. 회사는 회원의 구매신청이 있는 경우 회원에게 수신확인통지를 합니다.</p>
              <p>2. 수신확인통지를 받은 회원은 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후, 즉시 구매신청 변경 및 취소를 요청할 수 있고, 회사는 회원의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만, 회원이 이미 지불한 대금에 대한 서비스 이용을 시작한 경우에는 제 24 조에 따라 청약철회를 처리합니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 23 조 (환급)</h4>
              <p>1. 회사는 회원이 구매한 서비스의 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 회원에게 통지하고 사전에 서비스 등의 대금을 받은 경우에는 대금을 받은 날로부터 3영업일 이내에 회원이 결제한 수단으로 거래 대금을 환급하거나 환급에 필요한 조치를 취합니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 24 조 (청약철회 등)</h4>
              <p>1. 회원은 「전자상거래 등에서의 소비자보호에 관한 법률」 등 관련 법령에 따라 유료서비스 계약 시작일로부터 7일 이내에 서비스를 이용 (컨텐츠 또는 자료의 다운로드 포함) 하지 않은 경우 청약의 철회를 할 수 있습니다.</p>
              <p>2. 단, 회원은 게시물, 용역 등을 제공받은 경우 등 같은 법 제17조제2항에 따라 다음 각 호에 해당하는 경우에는 거래 취소 및 환불을 할 수 없습니다.</p>
              <p>● 게시물, 용역 또는 『문화산업진흥 기본법』 제2조 제5호의 디지털컨텐츠의 제공이 개시된 경우(다만, 가분적 게시물, 용역 또는 가분적 디지털컨텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는 제외함)</p>
              <p>● 회원에게 책임 있는 사유로  게시물, 용역 등이 멸실 또는 훼손된 경우(다만,  게시물, 용역 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외함)</p>
              <p>● 회원의 사용 또는 일부 소비에 의하여 게시물, 용역 등의 가치가 현저히 감소한 경우</p>
              <p>● 시간의 경과에 의하여 재판매가 곤란할 정도로 게시물, 용역 등의 가치가 현저히 감소한 경우</p>
              <p>● 같은 성능을 지닌 게시물, 용역 등으로 복제가 가능한 경우 그 원본인 게시물,용역 등의 포장을 훼손한 경우</p>
              <p>● 회사가  게시물, 용역 등의 청약철회 등의 제한에 관해 사전에 고지한 경우</p>
              <p>3. 생성형 AI는 특성 상 동일한 프롬프트라도 정확히 똑같은 결과물을 보장해 주지 않습니다. 마켓플레이스 서비스를 이용하여 구매한 프롬프트 결과값의 적법성, 독창성, 배타성, 신뢰도, 정확성, 진실성, 활용 가능성, 특정 목적에의 적합성을 보장하지 아니하며, 프롬프트 결과값의 불만으로 인한 청약철회는 제한됩니다.</p>
              <p>4. 회원은 유료서비스 계약을 체결한 날로부터 7일 이후 회원이 서비스 계약의 해지를 원하는 경우에는 회사측에 문의 절차를 통해서 이를 통지해야 하며, 회사가 해지 과정을 진행합니다.</p>
              <p>5. 회원이 유료서비스를 월간 계약으로 이용하고 있고, 이를 계약 해지하는 경우 회사는 연체 금액 및 당월 이용 금액을 청구합니다. </p>
              <p>6. 회원은 제1항 및 제2항의 규정에도 불구하고 게시물, 용역 등의 내용이 표시∙광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 상품 등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다. </p>
            </div>
            <br/>

            <div>
              <h4>제 25 조 (청약철회 등의 효과)</h4>
              <p>1. 청약철회 등을 한 경우 회원은 제공받은 게시물, 서비스 등 일체를 반환하여야 하며, 다운로드한 것이 있는 경우 이를 삭제하여야 합니다. </p>
              <p>2. 회사는 청약철회가 완료되면 3영업일 이내에 이미 지급받은 게시물, 서비스 등의 대금을 환급합니다. 이 경우 회사가 회원에게 상품 등의 대금 환급을 지연한 때에는 그 지연기간에 대하여 『전자상거래 등에서의 소비자보호에 관한 법률 시행령』 제21조의3에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다. 단, 회사의 귀책사유 없이 금융기관의 전산오류 내지는 마비 등 외부적 사정에 의하여 지연된 경우 회사는 그 책임을 부담하지 않습니다. </p>
              <p>3. 회사는 위 대금을 환급함에 있어서 회원이 신용카드 또는 전자화폐 등의 결제수단으로 상품 등의 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 게시물, 용역 등의 대금의 청구를 정지하거나 결제를 취소하도록 요청합니다.</p>
              <p>4. 제3항에도 불구하고 회사는 회원이 결제한 수단으로 환급할 수 없을 때에는 환급에 필요한 다른 조치를 취할 수 있습니다.</p>
              <p>5. 회사는 이미 게시물, 용역 등이 일부 사용 또는 일부 소비된 경우에는 일부 소비에 의하여 회원이 얻은 이익 또는 그 게시물, 용역 등의 공급에 소요된 비용에 상당하는 금액을 회원에게 청구할 수 있습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 26 조 (회원의 손해배상)</h4>
              <p>1. 회원은 다음과 같은 사유로 분쟁이 발생하는 경우 회사 및 회사의 임직원, 대리인, 파트너 등을 면책시키고 방어하며, 이들이 피해를 입지 않도록 하는데 동의합니다.</p>
              <p>● 회원이 본 약관, 세부지침, 관련 법령을 위반하는 경우</p>
              <p>● 회사가 본 약관, 세부지침, 관련 법령의 위반으로 의심되는 사항을 조사하거나 위반이 발생한 것으로 판단하여 취하는 조치</p>
              <p>2. 회원이 회사에 대하여 손해를 배상하게 되는 경우 손해배상의 범위에는 회사의 법률 비용, 경비 및 기타 손해를 포함하되 이에 한정되지 않습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 27 조 (회사의 손해배상)</h4>
              <p>1. 회사는 유료 서비스을 이용하는 회원에 한하여 본 조에 따라 보상을 진행합니다.</p>
              <p>● 회사는 서비스의 월별 가동 시간 비율[해당 월의 총 시간(분)에서 해당 월에 발생한 중단시간(분)을 뺀 후 해당 월의 총 시간(분)으로 나눈 값]을 99.9%로 유지하기 위해 노력합니다.</p>
              <p>● 서비스 중단은 회원이 서비스 접속이 불가능한 상태를 의미하며, 중단시간은 해당 월 동안 중단이 발생한 시간의 총합을 의미합니다. 단, 보상 산정 기준이 되는 중단 시간은 15일을 초과할 수 없습니다.</p>
              <p>● 회사가 월별 가동 시간 비율을 99.9% 이상으로 제공하지 못하였을 경우, 회원은 아래에 명시된 기준에 따라 회사의 멤버십 서비스를 이용할 수 있는 할인쿠폰을 청구할 수 있습니다. 회사는 회원과 합의하여 확정한 할인쿠폰을 합의한 날의 익월에 회원에게 제공합니다.</p>
              <p>● 할인쿠폰 산정 시 기준이 되는 이용요금은 회원이 서비스에 대하여 회사에 납부 완료한 금액을 기준으로 합니다. 즉, 이용요금에 할인을 받은 경우에는 할인된 가격을 기준으로 할인쿠폰을 산정하며, 회원이 해당 월의 이용요금을 미납한 경우에는 할인쿠폰이 지급되지 않습니다.</p>
              <p>● 보상의 지급은 회원이 서비스 중단이 발생한 시점부터 14일 이내에 청구사유, 청구금액 및 산출근거를 기재하여 고객문의 창구를 통해 제출해야 하며, 이를 이행하지 않은 경우 회원은 위와 같은 보상을 받을 권리를 상실하게 됩니다.</p>
    
              <p>월별 가동 시간 비율 할인쿠폰</p>
              <p>99.0%이상 ~99.9%미만 해당 월 이용요금의10%</p>
              <p>95.0%이상 ~99.0%미만 해당 월 이용요금의25%</p>
              <p>95.0%미만 해당 월 이용요금의50%</p>
    
              <p>2. 다음 각 호의 경우, 본 조의 보상 대상에서 제외됩니다.</p>
              <p>● 사전 공지를 통해 예정된 정기 점검의 경우</p>
              <p>● 회원이 소유 또는 관리하는 장비의 장애 (회사의 주요 통제범위에 속하지 않는 문제)로 인해 장애가 발생한 경우</p>
              <p>● 회원의 허가되지 않은 행동이나 필요한 조치의 불이행 (필수 구성 또는 필수 프로그램의 미설치, 임의삭제 등)으로 인해 또는 회원의 계정이나 장비를 사용하여 회사 네트워크에 접속하는 회원의 직원, 대리인 계약자, 공급업체 등 기타 모든 회원 측 사람으로 인해 장애가 발생하는 경우</p>
              <p>● 회원이 회사의 권고 또는 정책을 따르지 않거나 고객이 시스템에 대한 적절한 보안 관리를 소홀히 하여 침해나 장애가 발생한 경우</p>
              <p>● 회원의 오 입력 (존재하지 않는 파일에 대한 엑세스 요청 등), 약관 등 회사가 명시한 유의사항을 위반하거나 이용한도를 초과하여 서비스를 이용함에 따라 장애가 발생하는 경우</p>
              <p>● 회사가 관련 법령에 따른 보호조치를 취하였음에도 외부로부터의 불법적 침해로 인해 서비스 장애가 발생한 경우</p>
              <p>● 회원이 무료 서비스를 이용하는 경우(무료 서비스 플랜에 유료 옵션 상품을 이용하는 경우 포함) 또는 유료 서비스 플랜을 무료로 이용하는 동안에 장애가 발생하는 경우</p>
              <p>● 회사가 본 약관이나 별도 계약에 따라 서비스 이용 제한, 정지, 해지를 한 경우</p>
              <p>● 회원이 이용요금을 연체하고 있거나 베타서비스, 시험사용, 테스트용, 평가판 등을 이용하는 동안에 장애가 발생하는 경우</p>
              <p>● 그 밖에 위 각 호에 준하는 사유가 있는 경우</p>
            </div>
            <br/>

            <div>
              <h4>제 28 조 (책임제한)</h4>
              <p>1. 회사는 법령상 허용되는 한도 내에서 서비스와 관련하여 본 약관에 명시되지 않은 어떠한 구체적인 사항에 대한 약정이나 보증을 하지 않으며, 회사의 귀책사유 없이 발생된 회원의 손해에 대하여는 책임을 부담하지 않습니다. 또한 회사는 법률상 허용되는 한도 내에서 간접 손해, 특별 손해, 결과적 손해, 징계적 손해, 및 징벌적 손해에 대한 책임을 부담하지 않습니다.</p>
              <p>2. 계약 또는 불법행위, 기타에서 비롯되는 모든 손해, 손실 및 소송 등의 사유에 대하여 회사가 책임지는 최대 누적 배상금은 책임을 발생시키는 사건의 발생일 직전 6개월 내 서비스 이용을 위해 회원이 회사에 납부한 총 합계 금액을 초과하지 않습니다.</p>
              <p>3. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</p>
              <p>4. 회사는 회원의 귀책사유로 인한 서비스 이용 장애 또는 분쟁에 대하여 책임을 부담하지 않습니다.</p>
              <p>5. 회사는 무료서비스 (유료 플랜을 무료로 사용하는 경우, 무료 플랜에 유료 옵션을 사용하는 경우 포함), 테스트, 시범운영, 체험판 서비스에 대해 보상 또는 배상 책임을 부담하지 않습니다.</p>
              <p>6. 생성형 AI는 특성 상 동일한 프롬프트라도 정확히 똑같은 결과물을 보장해 주지 않습니다. 회사가 제공하는 서비스 및 마켓플레이스 서비스를 이용하여 생성한 프롬프트 결과값의 적법성, 독창성, 배타성, 신뢰도, 정확성, 진실성, 활용 가능성, 특정 목적에의 적합성을 보장하지 아니합니다. 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를 통하여 얻은 결과물로 인한 손해에 관하여 책임을 지지 않습니다.</p>
              <p>7. AI 와 프롬프트 결과값의 이용과 관련하여 발생하는 모든 문제에 대한 책임은 회원에게 있으며, 회사는 이에 대하여 일체의 책임을 지지 아니합니다.</p>
              <p>8. 회사는 서비스를 매개로 제3자가 제공하거나 회원이 작성하는 등의 방법으로 서비스에 게재⋅연계된 서비스, 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 이로 인해 발생한 서비스 이용 장애 또는 분쟁에 대해 회사는 어떠한 책임도 부담하지 않습니다.</p>
              <p>9. 리셀링 파트너를 통해 서비스 이용 계약을 체결한 경우, 회사는 리셀링 파트너와 회원 간에 발생한 분쟁에 대해 책임을 부담하지 않습니다.</p>
              <p>10. 회원이 서비스를 불법행위에 이용하거나 본 약관을 위반한 경우 이에 대한 모든 책임은 해당 회원이 부담하며, 회사는 이에 대한 일체의 책임을 부담하지 않습니다. 만약 이로 인하여 회사가 제3자로부터 손해배상 청구 등 각종 이의제기를 받는 경우, 당해 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 책임을 부담하게 되는 경우에는 회사는 책임을 부담한 부분에 대하여 해당 회원에게 구상을 청구할 수 있습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 29 조 (정보의 제공)</h4>
              <p>1. 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보에 대하여 전자메일이나 서신우편 등의 방법으로 회원에게 정보를 제공할 수 있습니다. 단 광고성 정보에 해당하는 경우 회원으로부터 수신 동의를 받습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 30 조 (광고게재)</h4>
              <p>1. 회사는 서비스의 운용과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고 등을 게재할 수 있습니다.</p>
              <p>2. 회사가 제공하는 서비스 중의 배너 또는 링크 등을 통해 타인이 제공하는 광고나 서비스에 연결될 수 있습니다.</p>
              <p>3. 제 2항에 따라 타인이 제공하는 광고나 서비스에 연결될 경우, 해당 영역에서 제공하는 서비스는 회사의 서비스 영역이 아니므로 회사가 신뢰성, 안정성 등을 보장하지 않으며, 그로 인한 회원의 손해에 대하여도 회사는 책임을 지지 않습니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 31 조 (개별서비스에 대한 이용약관)</h4>
              <p>1. 회사가 별도의 서비스를 제공하게 되거나, 기존 서비스 내에서 필요한 경우 이를 위한 약관 및 정책을 둘 수 있습니다. 이는 각 서비스의 개별 페이지에 유의사항의 형태로 구현될 수 있으며, 그 형태나 명칭을 불문하고 본 조에서 규정하는 ‘약관 및 정책’에 해당합니다. </p>
              <p>2. 회사가 전항에 따른 개별 약관 및 정책을 둘 경우 이용자가 최초로 해당 서비스를 이용할 때에 동의 절차를 거치도록 하거나 이를 이용자가 충분히 확인할 수 있도록 제시하며, 해당 약관 및 정책이 본 약관에 우선합니다.</p>
            </div>
            <br/>

            <div>
              <h4>제 32 조 (연결몰과 피연결몰의 관계)</h4>
              <p>1. 상위 서비스와 하위 서비스가 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상, 각종 광고 등이 포함됨) 방식 등으로 연결된 경우, 전자를 연결 서비스(웹 사이트)이라고 하고 후자를 피연결 서비스(웹사이트)이라고 합니다.</p>
              <p>2. 연결 서비스는 피연결 서비스가 독자적으로 제공하는 게시물, 용역 등과 관련하여 이용자와 발생하는 거래에 대한 보증 책임을 지지 않음을 초기화면 또는 연결되는 시점의 팝업화면을 통해 명시한 경우, 해당 거래에 대한 보증 책임을 부담하지 않습니다.</p>
              <p>3. 회사의 공식 사이트 이외의 피연결 서비스에서는 회사의 본 약관과 개인정보처리방침이 적용되지 않습니다. 피연결 서비스의 서비스 이용 및 용역 등 구매에 관하여는 해당 피연결 서비스의 약관 및 개인정보처리방침을 확인하여야 하며, 그렇지 않음으로 인하여 발생한 책임은 이용자에게 있고 회사는 이에 대하여 책임을 부담하지 않습니다. </p>
            </div>
            <br/>

            <div>
              <h4>제 33 조 (분쟁의 해결)</h4>
              <p>1. 본 약관 또는 서비스는 대한민국법령에 의하여 규정되고 이행됩니다.</p>
              <p>2. 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위한 전담기구를 설치 및 운영합니다</p>
              <p>3. 회사는 회원으로부터 제출되는 불만사항 및 의견을 신속하게 처리하기 위해 노력합니다. 다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보합니다.</p>
              <p>4. 회사와 회원 간에 발생한 분쟁과 관련하여 회원의 피해 구제 신청이 있는 경우에는 공정거래위원회 또는 시도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.</p>
              <p>5. 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는 관련 소송의 관할은 “회사” 본점 소재지를 관할하는 법원으로 합니다.</p>
            </div>
            <br/>

            공고일자: 2024년 11월 30일
            시행일자: 2024년 12월 30일
          </div>
        </>
      ) : agreeCode === 'AGREE003' ? ( //개인정보 수집 및 이용 동의
        <>
          <h2>개인정보 수집 및 이용동의</h2>
          <div className="wrap signUpTerm2">
            
            <h4>주식회사 프롬프트뱅크(이하 “회사”)는 다음의 목적을 위하여 최소한의 개인정보를 수집하여 처리합니다. 처리하고 있는 개인정보는 다음 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. 이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 해당 항목에 처리 시 수집하는 최소한의 개인정보인 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회사가 제공하는 해당 서비스를 사용하실 수 없습니다. 자세한 사항은 주식회사 프롬프트뱅크 개인정보처리방침에서 확인할 수 있습니다.</h4>
            <br/>

            <div>
              <h4>1.회원 가입 및 관리</h4>
              <p>① 개인정보 수집 및 이용 목적: 회원 가입 및 관리</p>
              <p>② 수집 항목:  </p>
              <p>●	필수: 이메일 주소, 비밀번호, DI</p>
              <p>●	선택: 유저네임, 휴대폰번호, 주소, 소속, 아이디, 프로필사진, 소셜미디어 주소, 생년월일</p>
              <p>③ 보유 및 이용기간: 회원 탈퇴시까지</p>
              <p>이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의</p>
              <p>개인정보인 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회사가 제공하는 서비스를 사용하실 수 없습니다.</p>
            </div>
            <br/>

            <div>
              <h4>2.소셜 로그인</h4>
              <p>① 개인정보 수집 및 이용 목적: 회원 가입 및 관리</p>
              <p>② 수집 항목:  </p>
              <p>●	필수: </p>
              <p>○	카카오 계정: 프로필 정보, 닉네임, 이메일 주소, 휴대폰번호, 계정 고유식별자, 비밀번호, DI</p>
              <p>○	구글:  필명, 로그인 정보 식별 값, 프로필 사진, 아이디 ,비밀번호</p>
              <p>○	네이버 계정: 이메일, 별명, 이름(선택), 프로필 정보(선택), 성별(선택), 생년월일(선택), 연령대(선택), 계정 고유식별자, 비밀번호, DI, CI</p>
              <p>③ 보유 및 이용기간: 회원 탈퇴시까지</p>
              <p>이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의</p>
              <p>개인정보인 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회사가 제공하는 서비스를 사용하실 수 없습니다.</p>
            </div>
            <br/>

            <div>
              <h4>3.본인 인증</h4>
              <p>① 개인정보 수집 및 이용 목적: 본인 인증 및 미성년자의 식별</p>
              <p>② 수집 항목:  </p>
              <p>●	필수: 휴대폰 번호, 이름, 생년월일, 성별, 이동통신사 및 내/외국인 여부, CI</p>
              <p>③ 보유 및 이용기간: 회원 탈퇴시까지</p>
              <p>이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 본인 인증 시 수집하는 최소한의</p>
              <p>개인정보인 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회사가 제공하는 서비스를 사용하실 수 없습니다.</p>
            </div>
            <br/>

            <div>
              <h4>4.결제 및 환불 서비스</h4>
              <p>① 개인정보 수집 및 이용 목적: 결제 및 환불 서비스</p>
              <p>② 수집 항목:  </p>
              <p>●	필수: 이름, 생년월일, 결제수단에 따른 구매자 정보</p>
              <p>○	신용카드: 카드번호, 카드 유효기간, 카드 비밀번호, 카드 CVC, 생년월일</p>
              <p>○	가상계좌: 은행, 가상계좌번호, 입금자명</p>
              <p>○	계좌이체	: 은행, 출금계좌번호, 비밀번호, 주민등록번호(또는 사업자등록번호), 예금주</p>
              <p>○	휴대폰: 통신사, 휴대폰번호, 주민등록번호 앞 7자리(생년월일, 성별)</p>
              <p>○	상품권: 상품권 발행사 아이디, 비밀번호’ 또는 상품권 번호</p>
              <p>○	현금영수증 발행: ‘주민등록번호, 휴대폰번호, 현금영수증번호, 기타 카드번호’ 중 현금영수증 발급정보</p>
              <p>③ 보유 및 이용기간: 회원 탈퇴시까지</p>
              <p>이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 결제 시 수집하는 최소한의</p>
              <p>개인정보인 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회사가 제공하는 서비스를 사용하실 수 없습니다.</p>
            </div>
            <br/>

            <div>
              <h4>5.판매자 등록</h4>
              <p>① 개인정보 수집 및 이용 목적: 상품 판매를 위한 판매자 등록 및 관리, 세금계산서 발행</p>
              <p>② 수집 항목:  </p>
              <p>●	필수: 사업자정보, 이메일 주소, 휴대폰번호, 이름, 생년월일, 성별, 이동통신사 및 내/외국인 여부, DI, CI</p>
              <p>③ 보유 및 이용기간: 회원 탈퇴시까지</p>
              <p>이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 판매자 등록 시 수집하는 최소한의</p>
              <p>개인정보인 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회사가 제공하는 서비스를 사용하실 수 없습니다.</p>
            </div>
            <br/>

            <div>
              <h4>6.판매자 정산 계좌 등록</h4>
              <p>① 개인정보 수집 및 이용 목적: 판매자 계좌 등록을 통한 판매 대금 정산 및 환불 서비스 제공</p>
              <p>② 수집 항목:  </p>
              <p>●	필수: 예금주, 은행명, 계좌번호, 사업자등록번호(사업자), 주민등록번호 앞 7자리(개인)</p>
              <p>③ 보유 및 이용기간: 회원 탈퇴시까지</p>
              <p>이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 판매자 정산 계좌 등록 시 수집하는 최소한의</p>
              <p>개인정보인 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회사가 제공하는 서비스를 사용하실 수 없습니다.</p>
            </div>
            <br/>

            <div>
              <h4>7.문의 등록</h4>
              <p>① 개인정보 수집 및 이용 목적: 문의 처리</p>
              <p>② 수집 항목:  </p>
              <p>●	필수: 이메일 주소</p>
              <p>③ 보유 및 이용기간: 문의 답변 완료 후 3년.</p>
              <p>이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 문의 등록 시 수집하는 최소한의</p>
              <p>개인정보인 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회사가 제공하는 서비스를 사용하실 수 없습니다.</p>
            </div>
            <br/>

            <div>
              <h4>8.자동수집항목</h4>
              <p>① 개인정보 수집 및 이용 목적: 서비스 품질향상 및 부정이용 방지</p>
              <p>② 수집 항목:  </p>
              <p>●	필수: 서비스 이용기록, 불량 이용 기록, 결제 기록, 접속로그, 쿠키, 방문 일시, 접속IP 정보, MAC주소, 기기정보, OS(Android/iOS) 및 버전, 브라우저 종류</p>
              <p>③ 보유 및 이용기간: 회원 탈퇴시까지</p>
              <p>이용자는 개인정보 수집 및 이용 동의를 거부할 권리가 있습니다. 자동 수집항목으로 수집하는 최소한의</p>
              <p>개인정보인 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회사가 제공하는 서비스를 사용하실 수 없습니다.</p>
            </div>
            <br/>

            <h4>개인정보 보유 및 이용 기간 안내</h4>
            <p>회사는 원칙적으로 이용자의 개인정보를 회원탈퇴 시 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.</p>
            <br/>

            <h4>내부 정책에 의한 정보 보유 사유</h4>
            <br/>
            <table className='termsTable'>
              <thead>
                <tr>
                  <td>보관 정보</td>
                  <td>보관 목적</td>
                  <td>보유기간</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>부정이용 기록</td>
                  <td>부정 이용 방지</td>
                  <td>탈퇴 후 1년</td>
                </tr>
                <tr>
                  <td>문의 내용</td>
                  <td>고객 상담 처리</td>
                  <td>3년</td>
                </tr>
                <tr>
                  <td>저작권 침해 및 유해게시물 신고 구비서류</td>
                  <td>신고 사항 처리</td>
                  <td>3개월</td>
                </tr>
                <tr>
                  <td>산업안전보건법 위반행위 기록</td>
                  <td>고객의 폭언 등으로 인한 건강장해 예방조치 및 제재</td>
                  <td>3년</td>
                </tr>
                <tr>
                  <td>중복 결제 취소 처리 구비서류</td>
                  <td>세무조사 등 규제기관의 요청 대응</td>
                  <td>마지막 거래일로부터 6년 3개월</td>
                </tr>
              </tbody>
            </table>

            <br/>

            <h4>관련법령에 의한 정보 보유</h4>
            <p>상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 개인정보를 보관합니다. </p>

            <br/>

            <table className='termsTable'>
              <thead>
                <tr>
                  <td>보관 정보</td>
                  <td>보관 목적</td>
                  <td>보유기간</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>계약 또는 청약철회 등에 관한 기록</td>
                  <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>대금 결제 및 재화 등의 공급에 대한 기록</td>
                  <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
                  <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                  <td>3년</td>
                </tr>
                <tr>
                  <td>표시/광고에 관한 기록</td>
                  <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                  <td>6개월</td>
                </tr>
                <tr>
                  <td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
                  <td>국세기본법,법인세법,부가가치세법</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>전자금융 거래에 관한 기록</td>
                  <td>전자금융거래법</td>
                  <td>5년</td>
                </tr>
                <tr>
                  <td>저작권 신고에 관한 기록</td>
                  <td>저작권법</td>
                  <td>1년</td>
                </tr>
                <tr>
                  <td>서비스 방문기록</td>
                  <td>통신비밀보호법</td>
                  <td>3개월</td>
                </tr>
              </tbody>
            </table>
                
            <br/>

            <h4>개인정보처리 위탁에 관한 동의</h4>
            <p>회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다. 회사는 위탁계약 체결 시 개인정보 보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 개인정보 처리방침을 통하여 공개하도록 하겠습니다.</p>

            <br/>

            <table className='termsTable'>
              <thead>
                <tr>
                  <td>위탁받는 자(수탁자)</td>
                  <td>업무 내용</td>
                  <td>위탁 기간</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>(주)인피니소프트</td>
                  <td>결제 서비스</td>
                  <td>회원 탈퇴 또는 위탁업무 종료시까지</td>
                </tr>
                <tr>
                  <td>(주)가비아</td>
                  <td>정보시스템 운영</td>
                  <td>회원 탈퇴 또는 위탁업무 종료시까지</td>
                </tr>
                <tr>
                  <td>(주)세종네트웍스</td>
                  <td>SMS 발송</td>
                  <td>회원 탈퇴 또는 위탁업무 종료시까지</td>
                </tr>
                <tr>
                  <td>(주)인피니소프트</td>
                  <td>계좌 예금주 조회</td>
                  <td>회원 탈퇴 또는 위탁업무 종료시까지</td>
                </tr>
                <tr>
                  <td>공공데이터포털</td>
                  <td>사업자 조회</td>
                  <td>회원 탈퇴 또는 위탁업무 종료시까지</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ) : ( // 이벤트 및 광고성 정보 수신 동의
        <>
          <h2>광고성 정보 수신 동의 안내</h2>
          <div className="wrap signUpTerm3">
            <h4>프롬프트뱅크에서는 수집 및 이용에 동의한 개인정보를 활용하여, 다양한 전자적 전송매체(SMS/MMS/e-mail 등)를 통해, 프롬프트뱅크 서비스(또는 제 3 자 제공 서비스)에 대한 개인 맞춤형 광고 및 정보를 전송할 수 있습니다.</h4>
            <br/>
            <p>수집 목적: 이벤트, 혜택, 마케팅 및 특정 맞춤 서비스 제공</p>
            <p>수집 항목:  이메일 주소, 휴대폰 번호, 성별, 연령, 직종, 가입경로, 사용용도</p>
            <p>보유기간: 회원 탈퇴 시 까지</p>
            <br/>
            <h4>이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 광고성 정보 제공을 위한 개인정보 수집 및 이용 동의를 거부하실 경우, 광고성 정보 수신이 어려울 수 있습니다. 본 수신동의를 철회하고자 할 경우 고객센터(홈페이지 하단)로 문의하거나 서비스 홈페이지(www.promptbank.com)의 ‘마이페이지{'>'}설정{'>'}알림 관리’에서 수신거부 할 수 있습니다.</h4>
          </div>
        </>
      )}
    </Modal>
  )
}

export default SignUpAgreeTerms