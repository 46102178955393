import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import * as api from '@api/index';

const PhoneAuthModal = ({ visible, onClose, onAuthenticate, initialPhoneNumber = ''}) => {
    const [form] = Form.useForm();
    const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
    const [authNum, setAuthNum] = useState(null);
    const [timer, setTimer] = useState(180); // 3분 타이머
    const [timerActive, setTimerActive] = useState(false);
    const [isAuthNumVerified, setIsAuthNumVerified] = useState(false);

    useEffect(() => {
        let countdown;

        if (timerActive && timer > 0) {
            countdown = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0 && timerActive) {
            message.error('인증 시간이 초과되었습니다.');
            setTimerActive(false);
        }

        return () => clearInterval(countdown);
    }, [timer, timerActive]);

    const formatPhoneNumber = (value) => {
        const cleanedValue = value.replace(/[^0-9]/g, '');
        let formattedValue = '';

        if (cleanedValue.length <= 3) {
            formattedValue = cleanedValue;
        } else if (cleanedValue.length <= 7) {
            formattedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3)}`;
        } else if (cleanedValue.length <= 11) {
            formattedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3, 7)}-${cleanedValue.slice(7)}`;
        } else {
            formattedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3, 7)}-${cleanedValue.slice(7, 11)}`;
        }

        return {
            cleanedValue,
            formattedValue
        };
    };

    const handlePhoneChange = (e) => {
        const { cleanedValue, formattedValue } = formatPhoneNumber(e.target.value);
        setPhoneNumber(formattedValue);
        form.setFieldsValue({ mem_tel: cleanedValue });
    };

    const handleSendAuthNum = async () => {
        try {
            await form.validateFields(['mem_tel']);
            const phoneToSend = form.getFieldValue('mem_tel');

            const params = { phone: phoneToSend };
            const response = await api.sendSMS(params);

            if (response.status === 200) {
                message.success('인증번호가 발송되었습니다.');
                setAuthNum(response.data);
                setTimerActive(true);
                setTimer(180);
                setIsAuthNumVerified(false);
            } else {
                message.error(response.data);
            }
        } catch (error) {
            message.error('문자발송에 실패했습니다.');
        }
    };

    const handleVerifyAuthNum = async () => {
        try {
            await form.validateFields(['auth_num']);
            const inputAuthNum = form.getFieldValue('auth_num');
            if (Number(inputAuthNum) === authNum) {
                message.success('인증되었습니다.');
                setIsAuthNumVerified(true);

                const authenticatedPhoneNumber = form.getFieldValue('mem_tel');
                console.log('Modal에서 전달하는 번호:', authenticatedPhoneNumber);

                // 상태 초기화
                setPhoneNumber('');
                setAuthNum(null);
                setTimerActive(false);
                setTimer(180);
                form.resetFields();

                onAuthenticate(authenticatedPhoneNumber);
            } else {
                message.error('정확한 인증번호를 입력해주세요.');
            }
        } catch (error) {
            message.error('인증번호를 정확히 입력해주세요.');
        }
    };

    const handleClose = () => {
        form.resetFields();
        setAuthNum(null);
        setTimerActive(false);
        setTimer(180);
        setIsAuthNumVerified(false);
        onClose();
    };

    return (
        <Modal
            title="전화번호 인증"
            open={visible}
            onCancel={handleClose}
            footer={[
                /*
                <Button key="cancel" onClick={handleClose}>
                    취소
                </Button>,
                <Button
                    key="authenticate"
                    type="primary"
                    disabled={!isAuthNumVerified}
                    onClick={handleClose}
                >
                    확인
                </Button>*/
            ]}
            style={{marginTop:'13%'}}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="mem_tel"
                    label="전화번호"
                    rules={[
                        { required: true, message: '전화번호를 입력해주세요.' },
                        {
                            pattern: /^(01[016789]-?\d{3,4}-?\d{4}|02-?\d{3,4}-?\d{4}|0[3-9]{1}[0-9]{1}-?\d{3,4}-?\d{4})$/,
                            message: '올바른 전화번호 형식이 아닙니다.',
                        },
                    ]}
                >
                    <Input
                        value={phoneNumber}
                        onChange={handlePhoneChange}
                        placeholder='"-"를 제외한 숫자만 입력해 주세요.'
                        maxLength={13}
                        addonAfter={
                            <Button
                                type="link"
                                onClick={handleSendAuthNum}
                                disabled={timerActive}
                            >
                                인증요청
                            </Button>
                        }
                    />
                </Form.Item>

                {timerActive && (
                    <Form.Item
                        name="auth_num"
                        label="인증번호"
                        rules={[
                            { required: true, message: '인증번호를 입력해주세요.' },
                            {
                                pattern: /^\d{6}$/,
                                message: '올바른 인증번호 형식이 아닙니다.',
                            },
                        ]}
                    >
                        <Input
                            placeholder="인증번호를 입력해주세요."
                            maxLength={6}
                            disabled={isAuthNumVerified}
                            addonAfter={
                                <div>
                                    {timerActive && (
                                        <span style={{ color: 'red' }}>
                      {`${Math.floor(timer / 60)}:${(timer % 60).toString().padStart(2, '0')}`}
                    </span>
                                    )}
                                    <Button
                                        type="link"
                                        onClick={handleVerifyAuthNum}
                                        disabled={isAuthNumVerified}
                                    >
                                        인증
                                    </Button>
                                </div>
                            }
                        />
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};

export default PhoneAuthModal;