// 공통
import logo from '@assets/images/chat/icon_logo.png';
import closeIcon from '@assets/images/common/close_icon.png';
import resetIcon from '@assets/images/common/reset_icon2.png';
import deleteIcon from '@assets/images/common/icon_delete_confirm.svg';

import defaultProfile from '@assets/images/common/defaultProfileSVG.svg';
import questionIcon from '@assets/images/common/help_other_icon.svg';

// 신고
import activeSirenIcon from '@assets/images/common/active_siren_icon.png';
import sirenIcon from '@assets/images/common/siren_icon.png';

// 채팅
import trashIcon from '@assets/images/chat/trash_black_icon.png';
import imageIcon2 from '@assets/images/chat/image_icon2.png';
import arrowDown from '@assets/images/chat/arrow_down_icon.png';
import markIcon from '@assets/images/chat/mark_icon.png';
import manageIcon from '@assets/images/chat/icon_manage.png';

// 프로필
import noteIcon from '@assets/images/profile/note_icon.png';
import downArrow from '@assets/images/profile/down_arrow_icon_white.png';
import bookIcon from '@assets/images/profile/book_icon2.png';
import mobileFilterIcon from '@assets/images/profile/mobile_filter_icon.png';
import promptIcon from '@assets/images/profile/prompt_myHomg.png';
import earthIcon from '@assets/images/profile/webW.svg';
import youtubeIcon from '@assets/images/profile/youtubeW.svg';
import XIcon from '@assets/images/profile/xW.svg';
import instaIcon from '@assets/images/profile/instarW.svg';
import searchIcon from '@assets/images/profile/search_icon2.png';
import starIcon from '@assets/images/profile/star_icon.png';
import partyIcon from '@assets/images/profile/party_icon.png';
import newIcon from '@assets/images/profile/new_icon2.png';
import guideIcon from '@assets/images/profile/guide_icon.png';

// 랭킹 아이콘
import musicIcon from '@assets/images/common/music_icon.png';
import imageIcon from '@assets/images/common/image_icon.png';
import videoIcon from '@assets/images/common/video_icon.png';
import textIcon from '@assets/images/common/text_icon.png';
import whiteMusicIcon from '@assets/images/common/white_music_icon.png';
import whiteImageIcon from '@assets/images/common/white_image_icon.png';
import whiteVideoIcon from '@assets/images/common/white_video_icon.png';
import whiteTextIcon from '@assets/images/common/white_text_icon.png';

import defaultBanner from '@assets/images/common/defaultBanner.svg';

// 첨부파일 hover 아이콘
import fileExpanIcon from '@assets/images/common/i-expansion.svg';
import fileChangeIcon from '@assets/images/common/i-change.svg';
import TrashIcon from '@assets/images/common/i-trash.svg';

// 1:1 문의
import uploadIcon from '@assets/images/common/icon_upload.png';
import titleIcon from '@assets/images/common/icon_inquiryTit0.png';
import listIcon1 from '@assets/images/common/icon_inquiryTit1.png';
import listIcon2 from '@assets/images/common/icon_inquiryTit2.png';
import pictureIcon from '@assets/images/common/i-rankImage.svg';
import inquiryVideoIcon from '@assets/images/common/i-rankVideo.svg';
import inquiryTextIcon from '@assets/images/common/i-rankText.svg';

// 커뮤니티
import iconCommunity from '@assets/images/common/i-community.svg';
import iconBoard from '@assets/images/common/i-book.svg';
import albumThumb from '@assets/images/common/album_thumb.png';
import commentIcon from '@assets/images/common/commentIcon.svg';

export const IMAGES = {
  // 공통
  LOGO_ICON: logo,
  CLOSE_ICON: closeIcon,
  RESET_ICON: resetIcon,
  FILE_EXPAN_ICON: fileExpanIcon,
  FILE_CHANGE_ICON: fileChangeIcon,
  FILE_TRASH_ICON: TrashIcon,
  QUESTION_ICON: questionIcon,
  DELETE_CONFIRM_ICON: deleteIcon,

  // Default
  DEFAULT_PROFILE: defaultProfile,
  DEFAULT_BANNER: defaultBanner,

  // 신고
  ACTIVE_SIREN_ICON: activeSirenIcon,
  SIREN_ICON: sirenIcon,

  // 채팅
  TRASH_ICON: trashIcon,
  IMAGES_ICON2: imageIcon2,
  ARROWS_DOWN_ICON: arrowDown,
  MARK_ICON: markIcon,
  MANAGER_ICON: manageIcon,

  // 프로필
  NOTE_ICON: noteIcon,
  DOWN_WHITE_ICON: downArrow,
  BOOK_ICON: bookIcon,
  MOBILE_FILTER_ICON: mobileFilterIcon,
  PROMPT_ICON: promptIcon,
  EARTH_ICON: earthIcon,
  YOUTUBE_ICON: youtubeIcon,
  X_ICON: XIcon,
  INSTA_ICON: instaIcon,
  SEARCH_ICON: searchIcon,
  STAR_ICON: starIcon,
  PARTY_ICON: partyIcon,
  NEW_ICON: newIcon,
  GUIDE_ICON: guideIcon,

  // 랭킹 아이콘
  MUSIC_ICON: musicIcon,
  IMAGES_ICON: imageIcon,
  VIDEO_ICON: videoIcon,
  TEXT_ICON: textIcon,
  WHITE_MUSIC_ICON: whiteMusicIcon,
  WHITE_IMAGE_ICON: whiteImageIcon,
  WHITE_VIDEO_ICON: whiteVideoIcon,
  WHITE_TEXT_ICON: whiteTextIcon,

  // 1:1 문의
  UPLOAD_ICON: uploadIcon,
  TITLE_ICON: titleIcon,
  LIST_ICON1: listIcon1,
  LIST_ICON2: listIcon2,
  INQUIRY_PICTURE_ICON: pictureIcon,
  INQUIRY_VIDEO_ICON: inquiryVideoIcon,
  INQUIRY_TEXT_ICON: inquiryTextIcon,

  // 커뮤니티
  COMMUNITY_ICON: iconCommunity,
  BOARD_ICON: iconBoard,
  CARD_THUMB_ICON: albumThumb,
  COMMENT_ICON: commentIcon,
};