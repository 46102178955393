import React, { useEffect, useState, useMemo } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
// icon
import logo from '@assets/images/common/logo.png';
// api
import * as api from '@api/index';
// path
import { LOGIN } from '@routes/pathName';
// lib
import { encryptRSA } from '@utils/lib';

const INIT_STATE_NEW_PASSWORD_INFO = {
  mem_pwd: '',
  mem_pwd_confirm: '',
};

const regexPwd = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/;

const NewPassword = ({ email: propsEmail, verified: propsVerified, changePw: propChangePw }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const params = useMemo(() => qs.parse(search?.substring(1) || ''), [search]);

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isUpdatedPassword, setIsUpdatedPassword] = useState(false);

  // 비밀번호 변경
  const handleUpdatePassword = async (formData) => {
    try {
      if (!isEmailVerified || !email) {
        message.warning(<div><p>이메일이 유효하지 않습니다.</p><br /><p>이메일 인증을 다시 진행해주세요.</p></div>);
        return;
      }
      setIsLoading(true);
      await api.updatePassword({
        mem_email: email,
        mem_pwd: encryptRSA(formData.mem_pwd),
        mem_pwd_confirm: encryptRSA(formData.mem_pwd_confirm),
      });
      setIsUpdatedPassword(true);
      message.success('비밀번호 변경이 완료되었습니다.');
      setTimeout(() => {
        navigate(LOGIN, { replace: true });
      }, 1500);
    } catch (error) {
      setIsUpdatedPassword(false);
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // URL 파라미터 확인
    const urlVerified = params?.verified === 'true';
    const urlEmail = params?.email;

    // props 확인
    const isPropsValid = propsVerified && propsEmail;

    // URL 파라미터가 있으면 우선 사용, 없으면 props 사용
    if (urlVerified && urlEmail) {
      setEmail(urlEmail);
      setIsEmailVerified(true);
    } else if (isPropsValid) {
      setEmail(propsEmail);
      setIsEmailVerified(true);
    }
  }, [params, propsEmail, propsVerified]);

  return (
      <section className="flexColCenter">
        <article id="findPw" className="flexColCenter">
          {!propChangePw ? (
              <>
                <div className="flexColCenter logo">
                  <img src={logo} alt="logo" />
                </div>

                <h1>비밀번호 재설정</h1>
              </>
            ) : null
          }

          <Form form={form} name="newPassword" initialValues={INIT_STATE_NEW_PASSWORD_INFO} onFinish={handleUpdatePassword} autoComplete="off" scrollToFirstError style={{ width: '100%' }}>
            <Form.Item
                name="mem_pwd"
                style={{ marginBottom: '16px' }}
                rules={[
                  { required: true, message: '비밀번호를 입력해주세요!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || regexPwd.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('비밀번호는 8~12자리/영문, 숫자, 특수기호 조합이어야 합니다!'));
                    },
                  }),
                ]}
            >
              {propChangePw && <span className="checkLabel">신규 비밀번호</span>}
              <Input.Password className="input" placeholder="비밀번호 입력(8~12자리 영문, 숫자, 특수기호 포함)" disabled={isUpdatedPassword || !isEmailVerified} />
            </Form.Item>

            <Form.Item
                name="mem_pwd_confirm"
                style={{ marginBottom: '16px' }}
                dependencies={['mem_pwd']}
                rules={[
                  { required: true, message: '비밀번호를 입력해주세요!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('mem_pwd') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('비밀번호가 일치하지 않습니다!'));
                    },
                  }),
                ]}
            >
              {propChangePw && <span className="checkLabel">비밀번호 확인</span>}
              <Input.Password className="input" placeholder="비밀번호 재확인" disabled={isUpdatedPassword || !isEmailVerified} />
            </Form.Item>

            <Form.Item>
              <Button id="purpleBtn" htmlType="submit" loading={isLoading}>
                비밀번호 변경
              </Button>
            </Form.Item>
          </Form>
        </article>
      </section>
  );
};

export default NewPassword;