import React, { useEffect, useState, useCallback, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setQuery, setCompanyPayList, setLocationKey, setYScrollPosition, INIT_STATE_COMPANY_PAYLIST, clearCompanyPayList } from '@stores/companyPayList';
import { message, Layout, Form, Input, Button, Select, Row, Col } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import Table from '@components/Table';
import Loading from '@components/Loading';
import ButtonExport from '@components/ButtonExportAUIGrid';
import RangePicker from '@components/RangePicker';
import iconDate from '@static/AUIGrid/images/calendar-icon.png';
import * as api from '@api/index';
import { useDebounce, secondsCompactFormat } from '@utils/lib';
import { periodList, bankList } from '@utils/constants';
import { ADMIN_PAY_LIST } from '@routes/pathName';

const { Content, Header } = Layout;

const CompanyPayList = ({ isActive }) => {
  const navigate = useNavigate();
  const { key: locationKey } = useLocation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const companyPayListRef = useRef();
  const dispatch = useDispatch();
  const companyPayList = useSelector((s) => s.companyPayList, shallowEqual);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 업체 광고관리 목록 가져오기 그리드 설정
  const companyPayListGridProps = {
    showRowCheckColumn: true,
    editable: true,
    selectionMode: 'multipleCells',
  };

  // 업체 광고관리 목록 가져오기 그리드 칼럼 레이아웃 정의
  const companyPayListColumnLayout = [
    {
      dataField: 'owner',
      headerText: '회사명',
      width: '14%',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'bank_cd',
      headerText: '은행명',
      width: '12%',
      renderer: {
        type: 'DropDownListRenderer',
        list: bankList, // key-value Object 로 구성된 리스트
        keyField: 'value', // value 에 해당되는 필드명
        valueField: 'label', // key 에 해당되는 필드명
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'account_no',
      headerText: '계좌번호',
      width: '18%',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'cust_nm',
      headerText: '거래처명',
      width: '14%',
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'gds_amt',
      headerText: '금액',
      width: '10%',
      dataType: 'numeric',
      editRenderer: {
        type: 'InputEditRenderer',
        onlyNumeric: true, // 0~9만 입력가능
        autoThousandSeparator: true, // 천단위 구분자 삽입 여부
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
        type: 'numeric',
      },
    },
    {
      dataField: 'tran_his',
      headerText: '거래내역',
      width: '18%',
    },
    {
      dataField: 'fir_dt',
      headerText: '거래일시',
      dataType: 'date',
      //   dateInputFormat: 'yyyy-mm-dd', // 데이터의 날짜 형식
      formatString: 'yyyy-mm-dd HH:MM:ss', // 그리드에 보여줄 날짜 형식
      width: '14%',
      renderer: {
        type: 'IconRenderer',
        iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
        iconHeight: 16,
        iconPosition: 'aisleRight',
        iconTableRef: {
          // icon 값 참조할 테이블 레퍼런스
          default: iconDate, // default
        },
        onClick: () => {
          // 달력 아이콘 클릭하면 실제로 달력을 띄움.
          // 즉, 수정으로 진입함.
          companyPayListRef.current.openInputer();
        },
      },
      editRenderer: {
        type: 'CalendarRenderer',
        defaultFormat: 'yyyy-mm-dd HH:MM:ss', // 달력 선택 시 데이터에 적용되는 날짜 형식
        onlyCalendar: false, // 사용자 입력 불가, 즉 달력으로만 날짜입력 (기본값 : true)
        showPlaceholder: true, // 플레이스홀더 표시
        // placeholder: '____/__/__ __:__:__', // 플레이스홀더 표시 값 지정
        showEditorBtnOver: true, // 마우스 오버 시 에디터버턴 출력 여부
        showExtraDays: true, // 지난 달, 다음 달 여분의 날짜(days) 출력
        showTimePicker: true, // 시간 선택 출력 여부
        showTimeSecond: true, // 시간의 초까지 출력 여부
        showConfirmBtn: true, // 확인 버튼 출력 여부
      },
    },
  ];

  // 그리드 이벤트 설정
  const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
    // 그리드 이벤트 바인딩
    companyPayListRef.current.bind(['vScrollChange'], (event) => {
      // 스크롤 변경시
      if (event.type === 'vScrollChange') {
        handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
        // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
        if (event.position === event.maxPosition && currentCount < totalCount) {
          handleScroll({ offset, limit });
        }
      }
    });
  };

  // 업체 광고관리 행 추가
  const handleAddRowCompanyPay = () => {
    companyPayListRef.current.addRow({}, 'last');
  };

  // 업체 광고관리 행 삭제
  const handleRemoveRowCompanyPay = async () => {
    const checkedItems = companyPayListRef.current.getCheckedRowItems();
    if(checkedItems.length === 0){
      alert('선택된 데이터가 없습니다.');
      return;
    }else{
      if(await window.confirm('선택한 데이터를 삭제하시겠습니까?')){
        companyPayListRef.current.removeCheckedRows({});
      }
    }
  };

  // 업체 광고관리 저장
  const handleSaveCompanyPay = async () => {
    let saveItems = companyPayListRef.current.getGridDataWithState('state').filter((item) => {
      if (item.state !== null && item.state !== undefined) {
        if (item.state === 'added') {
          item.pay_no = secondsCompactFormat(new Date());
          item.inout_tp = 'IN';
          item.inout_div = '02';
          item.pay_meth = '30';
          item.gds_seq = 0;
          item.gds_div = 'GDS006';
          item.fir_dt = dayjs(item.fir_dt).unix();
          return item;
        } else if (item.state === 'edited') {
          item.fir_dt = dayjs(item.fir_dt).unix();
          return item;
        } else return item;
      }
    });

    // validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }
    let isValid = companyPayListRef.current.validateChangedGridData(['owner', 'bank_cd', 'account_no', 'cust_nm', 'fir_dt'], '회사명, 은행명, 계좌번호, 거래처명, 거래일시 는 필수값입니다.');
    if (!isValid) return;

    try {
      if(await window.confirm('저장하시겠습니까?')){
        await api.savePay(saveItems);
        message.success('저장이 완료되었습니다.');
        listCompanyPay(INIT_STATE_COMPANY_PAYLIST.query);
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 업체 광고관리 목록 가져오기
  const listCompanyPay = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.listPay({ ...query, inout_div: '02' });
      if (!data) return;
      // 두번째 이상 요청일때
      if (query.offset && companyPayListRef.current.getGridData()) {
        dispatch(
          setCompanyPayList({
            items: [...companyPayListRef.current.getGridData(), ...data.items],
            totalCount: data.total,
            currentCount: companyPayListRef.current.getGridData().length + data.items.length,
          }),
        );
        companyPayListRef.current.appendData(data.items);
      }
      // 첫번째 요청일때
      else {
        dispatch(
          setCompanyPayList({
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }),
        );
        companyPayListRef.current.setGridData(data.items);
      }
      dispatch(setLocationKey(locationKey));
      // 그리드 이벤트 재설정
      setupGridEvents({
        offset: query.offset,
        limit: query.limit,
        totalCount: data.total,
        currentCount: companyPayListRef.current.getGridData().length,
      });
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 검색
  const handleSearchPay = ({ keyword_type, keyword_text }) => {
    const newQuery = { ...companyPayList.query, keyword_type, keyword_text, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listCompanyPay(newQuery));
  };

  // 날짜 필터링
  const handleDatePay = ({ startDt, endDt }) => {
    const newQuery = { ...companyPayList.query, startDt, endDt, offset: 0 };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listCompanyPay(newQuery));
  };

  // 스크롤 페이징
  const handleScroll = ({ offset, limit }) => {
    const newQuery = { ...companyPayList.query, offset: offset + limit };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listCompanyPay(newQuery));
  };

  // 새로고침 이벤트 발생시
  const preventReload = () => {
    dispatch(clearCompanyPayList());
    navigate(ADMIN_PAY_LIST, { replace: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventReload);

    // 뒤로가기로 렌더링 됐을때
    if (locationKey === companyPayList.locationKey) {
      companyPayListRef?.current?.resize('100%');
      companyPayListRef.current.setGridData(companyPayList.items);
      companyPayListRef.current.setRowPosition(companyPayList.yScrollPosition);
      setupGridEvents({
        offset: companyPayList.query.offset,
        limit: companyPayList.query.limit,
        totalCount: companyPayList.totalCount,
        currentCount: companyPayList.currentCount,
      });
    }
    // 사이드 메뉴 선택으로 렌더링 됐을때
    else {
      listCompanyPay(INIT_STATE_COMPANY_PAYLIST.query);
    }

    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, [isActive]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Header className='l-divided tab'>
        <Form className='l-flex gap'  form={form} name="companyPayList" layout="inline" initialValues={companyPayList?.query} onFinish={handleSearchPay} style={{padding: '0'}}>
          <RangePicker
            styleSelect={{ minWidth: '80px' }}
            placeholderSelect="거래일시"
            optionsSelect={periodList}
            valueRangePicker={[companyPayList?.query?.startDt ? dayjs(companyPayList?.query?.startDt) : '', companyPayList?.query?.endDt ? dayjs(companyPayList?.query?.endDt) : '']}
            onChange={handleDatePay}
          />
          <Form.Item name="keyword_type">
            <Select options={[{ label: '회사명', value: 'owner' }]} />
          </Form.Item>
          <Form.Item name="keyword_text">
            <Input placeholder="검색어를 입력해주세요." allowClear style={{ minWidth: '300px' }} />
          </Form.Item>
          <Button className='btn-searchIcon' type="primary" htmlType="submit">
            검색
          </Button>
        </Form>
      </Header>
      <Content className='l-content'>
        <Row className='l-flex betCenter'>
          <Row>검색결과
            <Row style={{ marginLeft: '10px' }}>{`총 ${companyPayList.totalCount}건`}</Row>
          </Row>
          <Row className='l-flex gap'>
            <ButtonExport ref={companyPayListRef} fileName={`${secondsCompactFormat(new Date())}_결제내역조회`} text="엑셀 다운" />
            <Button className='btn-add' onClick={handleAddRowCompanyPay}>추가</Button>
            <Button className='btn-red' htmlType="submit" onClick={handleRemoveRowCompanyPay} danger>
              삭제
            </Button>
            <Button className='btn-pink' type="primary" htmlType="submit" onClick={handleSaveCompanyPay}>
              저장
            </Button>
          </Row>
        </Row>
        <Table ref={companyPayListRef} columnLayout={companyPayListColumnLayout} customGridProps={companyPayListGridProps} />
      </Content>
    </>
  );
};

export default CompanyPayList;
