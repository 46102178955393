import React, { useState, useCallback, useEffect, useRef } from 'react';
import { message, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
// icon
import musicIcon3 from '@assets/images/common/music_icon3.png';
import waveBgImg from '@assets/images/common/wave.png';
// component
import NewSlider from '@components/slider/NewSlider';
import CardCircleMusic from '@pages/main/CardCircleMusic';
// lib
import { useDebounce, validateSession, getLocalStorage } from '@utils/lib';
// const
import { AUTH_LOGIN } from '@utils/constants';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const ListNewMusic = ({ openMusicPlayer }) => {
  const navigate = useNavigate();
  const [musicList, setMusicList] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const sliderRef = useRef(null);
  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (music) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: music?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 최신 음악 리스트 가져오기
  const listMusic = useCallback(async () => {
    try {
      const { data } = await api.getAllPromptList({ cate_cd: 'CATE003', keyword_tab: 'new', filter_stat: '30' });
      if (!data) return;
      setMusicList(data.items);
      setSelectedMusic(data.items[0]);
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 음악 플레이어 열기
  const onOpenMusicPlayer = async (musics = []) => {
    try {
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        // 플레이리스트에 곡 추가
        const requestMusics = musics?.map((music) => {
          return {
            mem_key: memKey,
            prpt_id: music?.prpt_id,
            prpt_title: music?.prpt_title,
            prpt_path: music?.music_org_link,
          };
        });
        await api.addPlaylistMusic(requestMusics);
        openMusicPlayer();
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  useEffect(() => {
    handleDebounce(() => listMusic());
  }, []);

  return (
    <article id="newWrapper" className="flexColCenter">
      <div className="rsWrapper flexColCenter">
        {!isLoading && <img src={waveBgImg} className="bgImg" />}
        <div className="flexRowBetween">
          <div className="flexRowCenter titleBox">
            <img src={musicIcon3} alt="musicIcon" />
            <h1>최신곡</h1>
          </div>

          <div id="whiteBtn" className="web" onClick={() => moveToPage(PATH.MUSIC_HOME, { filter_sort: 'new' })}>
            더보기
          </div>
        </div>

        <div className="flexColCenter slider">
          <div className="slider-container" id="newSlider">
            {isLoading ? (
              <Skeleton active paragraph={{ rows: 4 }} />
            ) : (
              <NewSlider ref={sliderRef}
                config={{
                  slidesToShow: 5,
                  focusOnSelect: true,
                  responsive: [
                    {
                      breakpoint: 1100,
                      settings: {
                        slidesToShow: 3,
                      },
                    },
                  ],
                  /* afterChange: (index) => {
                    setSelectedMusic(musicList[index]);
                  }, */
                  afterChange: (index) => {
                    let prpt_id = sessionStorage.getItem('slick-seq');
                    let idx = musicList.findIndex((item) => item.prpt_id === Number(prpt_id) );

                    if (idx !== -1 && sliderRef.current) {
                        setTimeout(() => {
                            if (index !== idx) sliderRef.current?.slickGoTo(idx);
                                setSelectedMusic({ ...musicList[idx], rnk: idx + 1 });
                        }, 100); // 약간의 딜레이 추가
                    } else {
                        setSelectedMusic({ ...musicList[index], rnk: index + 1 });
                    }
                    sessionStorage.removeItem('slick-seq');
                  }
                }}
                Content={musicList?.map((music) => (
                  <CardCircleMusic
                    data={music}
                    isActive={music?.prpt_id === selectedMusic?.prpt_id}
                    openMusicPlayer={onOpenMusicPlayer}
                    moveToPromptDetailPage={() => moveToDetailPage(music)}
                    moveToUserDetailPage={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: music?.mem_email, mem_key: music?.mem_key })}
                  />
                ))}
              />
            )}
          </div>
        </div>
      </div>
    </article>
  );
};

export default ListNewMusic;
