import React, { useState } from 'react';
import FooterTerms from './FooterTerms';
import SignUpAgreeTerms from './SignUpAgreeTerms';
import { useNavigate } from 'react-router-dom';
import * as PATH from '@routes/pathName';
import { getLocalStorage } from '@utils/lib';

const BasicFooter = (props) => {
  const navigate = useNavigate();
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const memEmail = getLocalStorage('prptbk-token', 'mem_email') || '';

  const [isTermsModal, setIsTermsModal] = useState(false);
  const [termsCode, setTermsCode] = useState(null);

  const [isTermsModal2, setIsTermsModal2] = useState(false);


  const handleOpenTermsModal = (code) => {
    setIsTermsModal(true);
    setTermsCode(code)
  }

  const handleCloseTermsModal = (code) => {
    setIsTermsModal(false);
  }

  const handleMoveToInquiry = () => {
    if(memKey){
      navigate(PATH.INQUIRY_HOME, { state: { mem_email: memEmail, mem_key: memKey } });
    }else{
      navigate(PATH.LOGIN)
    }
  }



  return (
    <>
      <footer id="footer" className="flexRowCenter">
        <article className="flexColCenter">
          {/* <img src={logo} alt="logo" />
          <p>© 2024. JMCORPORation INC. All rights reserved.</p> */}
          <ul className="flexRowCenter footMenu">
            <li>
              <button onClick={() => {
                setIsTermsModal2(true);
                setTermsCode('AGREE002');
              }}>
                <p>이용약관</p>
              </button>
            </li>
            <li>
              <button onClick={() => handleOpenTermsModal('2')}>
                <p>개인정보처리방침</p>
              </button>
            </li>
            <li>
              <button onClick={handleMoveToInquiry}>
                <p>1:1문의</p>
              </button>
            </li>
          </ul>

          <ul className="flexWrapCenter">
            <li className="logo">주식회사 프롬프트뱅크</li>
            <li>대표 박준명</li>
            <li>사업자등록번호 688-87-03564</li>
            <li> 서울특별시 성동구 아차산로13길 30, 8층 에프02호(성수동 2가)</li>
            <li>통신판매업신고번호 추후 발급 시 등록 예정(3월 말~4월 초 예상)</li>
            <li>회사 대표번호 1688-8103</li>
            <li>개인정보보호책임자 박동일</li>
            <li>
              이메일 <a href="mailto:help@promptbank.com">help@promptbank.com</a>
            </li>
            <li>
              호스팅 제공자 (주)가비아
            </li>
            <li className="copy">© 2024. PROMPTBANK CO., LTD. All rights reserved. Designed by MSLK</li>
          </ul>
        </article>
      </footer>
      <FooterTerms visible={isTermsModal} onClose={handleCloseTermsModal} code={termsCode}/>
      <SignUpAgreeTerms visible={isTermsModal2} onClose={() => setIsTermsModal2(false)} agreeCode={termsCode}/>
    </>
  );
};

export default BasicFooter;
