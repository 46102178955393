import React from 'react';
import { Input } from 'antd';
// icon
import searchIcon from '@assets/images/common/search_icon.png';
import mobileFilterIcon from '@assets/images/common/mobile_filter_icon.png';

const SearchSubMain = ({ defaultTitle, placeholderSearch, subMainSortList, aiModelList, genreList, subGenreList, marketplaceList, setFilterParams, filterToggleMobile }) => {
  // 필터 타이틀 가져오기
  const getFilterTitle = (params) => {
    const labelSort = subMainSortList?.filter((sort) => sort?.value === params?.filter_sort) || [];
    const labelAiModel = aiModelList?.filter((aiModel) => aiModel?.ai_model_cd === params?.filter_model) || [];
    const labelGenre = genreList?.filter((genre) => genre?.cd === params?.filter_genre) || [];
    const labelSubGenre = subGenreList?.filter((subGenre) => subGenre?.cd === params?.filter_sub_genre) || [];

    const genre = labelGenre[0]?.cd_nm?.replaceAll("전체", "") || "";
    const aiModel = labelAiModel[0]?.ai_model_nm?.replaceAll("전체", "") || "";
    const sort = labelSort[0]?.label?.replaceAll("전체", "") || "";

    return { genre, aiModel, sort };
  };
    const { genre, aiModel, sort } = getFilterTitle(marketplaceList.query);

  return (
    <div className="titleBox flexRowBetween">
        <h1>{genre} {aiModel && <span>{aiModel}</span>} {sort} {defaultTitle}</h1>

      <div className="search flexRowBetween">
        <img src={searchIcon} />
        <Input placeholder={placeholderSearch} value={marketplaceList.query?.filter_text} onChange={(e) => setFilterParams('filter_text', e.target.value)} allowClear />
      </div>

      <img src={mobileFilterIcon} onClick={filterToggleMobile} />
    </div>
  );
};

export default SearchSubMain;
