import React, { useEffect, useState, useCallback, useRef } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {message, Layout, Form, Input, Button, Row, Modal, Select} from 'antd';
import Table from '@components/Table';
import Loading from '@components/Loading';
import * as api from '@api/index';
import {useDebounce} from '@utils/lib';
import {aiCertApr, periodList} from "@utils/constants";
import RangePicker from "@components/RangePicker";
import dayjs from "dayjs";
import {ADMIN_USER_DETAIL} from "@routes/pathName";

const { Content, Header } = Layout;

const AiCertList = () =>{
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [searchSelectedValue, setSearchSelectedValue] = useState('01');

  const today = dayjs(); // 오늘 날짜
  const oneWeekAgo = today.subtract(7, 'day'); // 일주일 전 날짜

  const [sendDate, setSendDate] = useState({
    st_date: oneWeekAgo.format('YYYY-MM-DD'),
    end_date: today.format('YYYY-MM-DD'),
  });
  const [aiCertListState, setAiCertListState] = useState({
    query: {
      keyword_text: '',
      startDt: sendDate.st_date,
      endDt: sendDate.end_date,
      offset: 0,
      limit: 20,
      selector_1: '01',
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
  });

  const handleDate = ({ startDt, endDt }) => {
    setSendDate({
      st_date: startDt,
      end_date: endDt,
    });
  };

  window.handleImageClick = (imagePath) => {
    setModalImage(imagePath);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setAiCertListState((prevState) => ({
      ...prevState,
      query: {
        ...prevState.query,
        startDt: sendDate.st_date,
        endDt: sendDate.end_date,
      },
    }));
  }, [sendDate]);

  const aiCertGridRef = useRef();
  const handleDebounce = useCallback(
      useDebounce((func) => func(), 300),
      [],
  );

  const aiCertGridProps = {
    showRowCheckColumn: true,
  };

  const aiCertColumnLayout = [
    { dataField: 'seq', headerText: '순번', width: '8%', visible: false },
    { dataField: 'mem_key', headerText: '멤버 식별키', width: '8%', editable: false, visible: false },
    {
      dataField: 'mem_email', headerText: 'Email', width: '14%', editable: false,
      renderer: {
        type: 'LinkRenderer',
        baseUrl: 'javascript',
        jsCallback: function (rowIndex) {
          const rowData = aiCertGridRef.current.getGridData()[rowIndex];
          let memKey = rowData?.mem_key;
          navigate(ADMIN_USER_DETAIL, {state: {mem_key: memKey}});
        },
      },
    },
    { dataField: 'ai_model_nm', headerText: 'AI 모델명', width: '13%', editable: false,
     filter: {
        showIcon: true,
        displayFormatValues: true,
     },
    },
    { dataField: 'img_path', headerText: '이미지', width: '21%',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
        let template = '';
        if (value) {
          const imgSrc = value.startsWith('blob:') ? value : `${value}?t=${new Date().getTime()}`;
          template += `<img src="${imgSrc}" alt="Banner" style="height:25px; max-width:100%;" onclick="handleImageClick('${imgSrc}');">`;
        } else {
          template += '<span>No image</span>';
        }
        return template;
      },
      editable: false,
    },
    {
      headerText: '인증 기간',
      editable :false,
      children: [
        {
          dataField: 'cert_start_dt',
          headerText: '시작일',
          formatString: 'yyyy-mm-dd',
          dataType: 'date',
          width: '10%',
          editable: false,
        },
        {
          dataField: 'cert_end_dt',
          headerText: '종료일',
          formatString: 'yyyy-mm-dd',
          dataType: 'date',
          width: '10%',
          editable: false,
        },
      ],
    },
    { dataField: 'cert_stat', headerText: '인증상태', width: '12%', renderer: { type: 'DropDownListRenderer', list: aiCertApr, keyField: 'value', valueField: 'label' },
        filter: {
            showIcon: true,
            displayFormatValues: true,
        },
    },
    { dataField: 'confirm_url', headerText: '인증 URL', width: '20%', editable: false,
        renderer: {
                type: 'LinkRenderer',
                baseUrl: 'javascript', // 자바스크립 함수 호출로 사용하고자 하는 경우에 baseUrl 에 "javascript" 로 설정
                // baseUrl 에 javascript 로 설정한 경우, 링크 클릭 시 callback 호출됨.
                jsCallback: function (rowIndex, columnIndex, value, item) {
                  window.open(value);
                },
        },
    },
  ]

  const setupGridEvents = useCallback(() => {
      aiCertGridRef.current.bind('cellEditEnd', (event) => {
        if (event.dataField === "cert_stat") {
            if (aiCertGridRef.current.isEditedByRowIndex(event.rowIndex)) {
                aiCertGridRef.current.addCheckedRowsByIds([event.item._$uid]); // 체크박스 체크
            } else {
                aiCertGridRef.current.addUncheckedRowsByIds([event.item._$uid]); // 체크박스 해제
            }
        }
      });
  }, []);

  const getAiCertList = useCallback(
      async (query) => {
        try {
          console.log(query);
          setIsLoading(true);
          const {data} = await api.getAiCertList(query);
          if (!data) return;

          setAiCertListState((prev) => ({
            ...prev,
            items: data.items,
            totalCount: data.total,
            currentCount: data.items.length,
          }));

          if (aiCertGridRef.current) {
            aiCertGridRef.current.setGridData(data.items);
          }
          setupGridEvents();
        } catch (error) {
          message.warning(error.message);
        } finally {
          setIsLoading(false);
        }
      },
      [setupGridEvents],
  );

  const handleSearchAiCert = ({ keyword_text }) => {
    const newQuery = { ...aiCertListState.query, keyword_text, offset: 0 };
    setAiCertListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getAiCertList(newQuery));
  };

  const updateAiCert = async (type) => {
    let saveItems = aiCertGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);
    if (type === 's') {
      if (saveItems.length < 1) {
        alert('저장할 데이터가 없습니다.');
        return false;
      }
    }
    try {
      setIsLoading(true);
      if(await window.confirm('저장하시겠습니까?')){
        const formData = new FormData();
        formData.append('updateAiCerList', new Blob([JSON.stringify(saveItems)], { type: 'application/json' }));
  
        const response = await api.updateAiCert(formData);
        alert(response.data.returnMessage, response.data.returnStatus == 'fail' ? 'error' : 'success');
      }
    } catch (e) {
      console.log('인증상태 갱신 error', e);
    } finally {
      setIsLoading(false);
      getAiCertList(aiCertListState.query);
    }
  }

  useEffect(() => {
    getAiCertList(aiCertListState.query);
  }, []);


  const handleChangeSearchSelect = (value) => {
    setSearchSelectedValue(value);
    const newQuery = { ...aiCertListState.query, selector_1: value };
    setAiCertListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getAiCertList(newQuery));
  };


  const [selectedValue, setSelectedValue] = useState(undefined);

  const handleChangeSelect = (value) => {
    setSelectedValue(value);
  };

  const handleInputState = () => {
    const checkedItems = aiCertGridRef.current.getCheckedRowItems();
    if(checkedItems.length === 0){
      message.error('데이터를 변경할 행을 선택해주세요.');
      return false;
    }
    console.log(checkedItems);
    console.log(selectedValue);
    if (!aiCertGridRef) {
      message.error('값을 선택하고 입력하세요.');
      return;
    }

    for (let i = 0; i < checkedItems.length; i++) {
      aiCertGridRef.current.setCellValue(checkedItems[i].rowIndex, 'cert_stat', selectedValue);
    }
  };

  return (
      <>
        <Loading isLoading={isLoading} />
        <Layout>
          <Header className='l-divided'>
            <Form form={form} layout="inline" initialValues={aiCertListState.query} onFinish={handleSearchAiCert} className='l-flex gap'>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <RangePicker
                    styleSelect={{minWidth: '80px', marginRight: '10px'}}
                    placeholderSelect="생성일시"
                    optionsSelect={periodList}
                    valueRangePicker={[sendDate?.st_date ? dayjs(sendDate?.st_date) : oneWeekAgo, sendDate?.end_date ? dayjs(sendDate?.end_date) : today]}
                    onChange={handleDate}
                />
              </div>
              <Form.Item name="keyword_text">
                <Input placeholder="유저네임 또는 이메일을 입력해주세요." style={{minWidth: '300px'}}/>
              </Form.Item>
              <Form.Item name="selector_1">
                <Select
                    onChange={handleChangeSearchSelect}
                    className="w-full p-2"
                    style={{ width: 150 }}
                >
                  {aiCertApr.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Button className="btn-searchIcon" type="primary" htmlType="submit">
                검색
              </Button>
            </Form>
          </Header>
          <Content className='l-content'>
            <Row className='l-flex betCenter'>
              <div className='l-flex'>
                검색결과 <Row style={{marginLeft: '10px'}}>{`총 ${aiCertListState.totalCount}건`}</Row>
              </div>
              <div className="aiSubmit">
                <Select
                    value={selectedValue}
                    onChange={handleChangeSelect}
                    placeholder="선택하세요"
                    className="w-full p-2"
                    style={{width: 150}}
                >
                  {aiCertApr.map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                  ))}
                </Select>
                <Button type="primary" htmlType="submit" onClick={handleInputState}>
                  입력
                </Button>
                <Button className="btn-pink" type="primary" htmlType="submit" onClick={() => updateAiCert('s')}>
                  저장
                </Button>
                {/*<Button style={{ marginLeft: '3px' }} htmlType="submit" onClick={() => updateAiCertState('d')} danger>삭제</Button>*/}
              </div>
            </Row>
            <Table ref={aiCertGridRef} columnLayout={aiCertColumnLayout} customGridProps={aiCertGridProps} />
          </Content>
        </Layout>

        <Modal
            visible={isModalVisible}
            footer={null}
            onCancel={handleModalCancel}
            width="50%"
            height="50%"
            style={{ textAlign: 'center' }}
        >
          <img src={modalImage} alt="Enlarged Image" style={{ width: '100%' }} />
        </Modal>
      </>
  );
};

export default AiCertList;