import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import { Button, Form, Image, Input, message, Select, Upload, Modal } from 'antd';
import { CloseOutlined, PlusOutlined, EyeOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import Loading from '@components/Loading';
import * as api from '@api/index';
import { getLocalStorage, handleImageError, makeDataFormat, makeSelectBoxList, phoneFormat } from '@utils/lib';
import AiModelCertModal from '@components/AiModelCertModal';
import PhoneAuthModal from '@pages/profile/PhoneAuthModal';
import NewPassword from '@pages/logins/NewPassword';
import SellerRegist from '@components/SellerRegist';
import AccountDelModal from '@components/AccountDelModal';
import PasswordCheck from '@components/slider/PasswordCheck';
import useWidth from '@hooks/useWidth';

import borderLine from '@assets/images/common/border_line.png';
import profileIcon from '@assets/images/profile/profile_icon.png';
import myInfoIcon from '@assets/images/profile/myInfo_icon.png';
import aiIcon from '@assets/images/profile/ai_icon.png';
import secessionIcon from '@assets/images/profile/logout_icon.png';
import dayjs from 'dayjs';
import { IMAGES } from '@utils/image';
import TextArea from 'antd/es/input/TextArea';
import { setAuth } from '@stores/auth';
import TermsAndConditions from '@components/payment/Terms';
import ArtistEdit from "@pages/artist/edit";

const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const memKey = getLocalStorage('prptbk-token', 'mem_key');

const CONSTANTS = {
  INIT_STATE_USER_DETAIL_SEARCH: {
    mem_email: '',
    mem_key: memKey,
  },
  INIT_STATE_EDIT_INFO: {
    mem_email: '',
    mem_nick: '',
    mem_tel: '',
    web: '',
    twitter_link: '',
    youtube_link: '',
    about_me: '',
    loc_cd_1: '',
    loc_cd_2: '',
    group: '',
    mem_div: '',
    plan_nm: '',
    mem_stat: '',
    fir_dt: '',
    cnl_dt: '',
    login_dt: '',
    cnl_rsn_cd: '',
    cnl_rsn: '',
    desc: '',
    ai_fl_cd: '',
    ai_fl_nm: '',
  },
};

const useCommonCodes = () => {
  const [codes, setCodes] = useState({
    memStatFormat: {},
    memDivFormat: {},
    cnlRsnCdFormat: {},
    loc1List: [],
    loc2List: [],
    aiFormat: {},
    aiList: [],
  });

  const fetchCommonCodes = useCallback(async () => {
    try {
      const [memStat, memDiv, cnlRsn, loc1, model] = await Promise.all([
        api.fetchCommonDetailCode({ grp_cd: 'MEM_STAT' }),
        api.fetchCommonDetailCode({ grp_cd: 'MEM_DIV' }),
        api.fetchCommonDetailCode({ grp_cd: 'CNL_RSN' }),
        api.fetchCommonDetailCode({ grp_cd: 'LOC01' }),
        api.fetchCommonDetailCode({ grp_cd: 'MODEL' }),
      ]);

      setCodes({
        memStatFormat: makeDataFormat(memStat?.data, 'cd_nm', 'cd'),
        memDivFormat: makeDataFormat(memDiv?.data, 'cd_nm', 'cd'),
        cnlRsnCdFormat: makeDataFormat(cnlRsn?.data, 'cd_nm', 'cd'),
        loc1List: makeSelectBoxList(loc1?.data, 'cd_desc', 'cd'),
        aiFormat: makeDataFormat(model?.data, 'cd_desc', 'cd'),
        aiList: makeSelectBoxList(model?.data, 'cd_desc', 'cd'),
      });
    } catch (error) {
      message.warning('공통코드 조회 중 오류가 발생했습니다.');
    }
  }, []);

  const fetchLoc2Format = useCallback(async (value) => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'LOC02', parent_cd: value });
      setCodes((prev) => ({
        ...prev,
        loc2List: makeSelectBoxList(response?.data, 'cd_desc', 'cd'),
      }));
    } catch (error) {
      message.warning('지역 상세 코드 조회 중 오류가 발생했습니다.');
    }
  }, []);

  return { codes, fetchCommonCodes, fetchLoc2Format };
};

const AiField = React.memo(({ isEditMode, aiList, selectedAis, handleAiSelect, handleAiRemove }) => {
  const width = useWidth();

  return (
    <div className="flexRowBetween ai">
      <div className="title flexRowStart">
        <img src={aiIcon} />
        <h1>전문 AI분야</h1>
      </div>

      <div className="flexRowStart content">
        {isEditMode && (
          <div className="flexColStart box">
            <p style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
              AI 분야 선택 <span className="comment">* 중복 선택 가능</span>
            </p>
          </div>
        )}
        {isEditMode && (
          <Form.Item name="ai_fi_nm">
            <Select placeholder="AI 분야 선택(중복)" options={aiList} onChange={handleAiSelect} />
          </Form.Item>
        )}

        <div className="flexRowStart tabBox">
          {selectedAis.map((ai) => (
            <div className="aiTab flexRowBetween">
              <h1>{ai.label}</h1>
              {isEditMode && (
                <p>
                  <CloseOutlined onClick={() => handleAiRemove(ai.value)} />
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

const Profile = ({ overrideData = {} }) => {
  const width = useWidth();
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const { search } = useLocation();
  const params = useMemo(
    () => ({
      ...CONSTANTS.INIT_STATE_USER_DETAIL_SEARCH,
      ...qs.parse(search?.substring(1) || ''),
      ...overrideData,
    }),
    [search],
  );
  const { codes, fetchCommonCodes, fetchLoc2Format } = useCommonCodes();

  const [isLoading, setIsLoading] = useState(false);
  const [userDetailData, setUserDetailData] = useState(null);
  const [aiFormat, setAiFormat] = useState({});
  const phoneNumber = useRef('');
  const [selectedAis, setSelectedAis] = useState([]);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [introImageFile, setIntroImageFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [introImageUrl, setIntroImageUrl] = useState('');

  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisiblePW, setIsModalVisiblePW] = useState(false);
  const [isPhoneAuthModalVisible, setIsPhoneAuthModalVisible] = useState(false);
  const [isModalVisibleSeller, setIsModalVisibleSeller] = useState(false);
  const [isPwModalVisible, setIsPwModalVisible] = useState(false);

  const [isSellerInfo, setIsSellerInfo] = useState([]);
  const [isArtistEditModalVisible, setIsArtistEditModalVisible] = useState(false);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const selectUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.userProfile({ params });
      setUserDetailData(response.data);
      setProfileImageUrl(getImageUrl(response.data?.mem_img_path));
      setIntroImageUrl(getImageUrl(response.data?.ch_img_path));
      defaultProfileChk.current = response.data?.mem_img_path?.length > 0;
      defaultIntroChk.current = response.data?.ch_img_path?.length > 0;

      if (response.data?.ai_fl_cd && codes.aiList.length > 0) {
        const aiCodes = response.data.ai_fl_cd.split('|');
        const initialSelectedAis = aiCodes
          .map((code) => {
            return codes.aiList.find((item) => item.value === code);
          })
          .filter(Boolean);
        setSelectedAis(initialSelectedAis);
      }

      form.setFieldsValue({
        ...response.data,
        mem_nick: response.data?.mem_nick?.replace(/^@/, ''),
        loc_nm_1: response.data?.loc_cd_1,
        loc_nm_2: response.data?.loc_cd_2,
      });
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return '';
    return `${imagePath}`;
  };

  const renderField = (name, value, inputProps = {}) => {
    const getMaxLength = () => {
      if (name === 'about_me') return 15;
      if (name === 'mem_nick') return 7;
      return undefined;
    };

    const maxLength = getMaxLength();

    const getValidationRules = () => {
      if (name === 'mem_nick') {
        return [{min: 2, max: 8, message: '최소 2자이상 최대 8자까지 입력하세요'}];
      }
      return [];
    };

    if (isEditMode) {
      return (
        <Form.Item name={name} style={{ margin: 0, width: '100%' }} rules={getValidationRules()}>
          {name === 'about_chanel' ? <TextArea {...inputProps} /> : <Input {...inputProps} maxLength={maxLength} />}
        </Form.Item>
      );
    }
    return (
      <span className={['about_chanel', 'about_me'].includes(name) ? name : undefined}>
        {['mem_nick', 'insta_link', 'x_link'].includes(name) && '@'}
        {value}
      </span>
    );
  };

  const renderSelect = (name, value, options, selectProps = {}) => {
    if (isEditMode) {
      return (
        <Form.Item name={name}>
          <Select
            options={options}
            {...selectProps}
            {...(name === 'loc_nm_1' && {
              onChange: (value) => {
                fetchLoc2Format(value);
                form.setFieldsValue({ loc_nm_2: undefined });
              },
            })}
            value={name === 'loc_nm_2' ? userDetailData?.loc_nm_2 : value}
          />
        </Form.Item>
      );
    }
    return <span>{value}</span>;
  };

  const { Dragger } = Upload;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const imageProfileError = useRef(false);
  const imageBannerError = useRef(false);
  const [hoverProfileState, setHoverProfileState] = useState(false);
  const [hoverChanelState, setHoverChanelState] = useState(false);
  const defaultProfileChk = useRef(false);
  const defaultIntroChk = useRef(false);

  useEffect(() => {
    imageProfileError.current = false;
  }, [profileImageUrl]);

  useEffect(() => {
    imageBannerError.current = false;
  }, [introImageUrl]);

  const renderUpload = (name) => {
    const beforeUpload = (file) => {
      if (name === 'profile_image') {
        imageProfileError.current = false;
        setProfileImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfileImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
        defaultProfileChk.current = false;
      } else if (name === 'intro_image') {
        imageBannerError.current = false;
        setIntroImageFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setIntroImageUrl(reader.result);
        };
        reader.readAsDataURL(file);
        defaultIntroChk.current = false;
      }
      return false;
    };

    const handlePreview = (type) => {
      const url = type === 'p' ? profileImageUrl : introImageUrl;
      if (url) {
        setPreviewImage(url);
        setPreviewOpen(true);
      }
    };

    const handleRemove = () => {
      if (name === 'profile_image') {
        setProfileImageUrl(null);
        setProfileImageFile(null);
        defaultProfileChk.current = false;
      } else if (name === 'intro_image') {
        setIntroImageUrl(null);
        setIntroImageFile(null);
        defaultIntroChk.current = false;
      }
    };

    if (name === 'profile_image') {
      return (
        <div className="profileBox">
          {previewImage && (
            <Image
              wrapperStyle={{ display: 'none' }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )}
          {isEditMode ? (
            <>
              <div className="plusBox flexColCenter">
                <Form.Item name={name} valuePropName="fileList" getValueFromEvent={normFile} className="box">
                  <Dragger accept=".jpg,.jpeg,.png" maxCount={1} listType="picture-card" showUploadList={false} beforeUpload={beforeUpload}>
                    {profileImageUrl === null || imageProfileError.current || (profileImageUrl !== null && imageProfileError.current) ? (
                      <>
                        <div className="flexColCenter plus">
                          <PlusOutlined />
                        </div>
                        {width > 700 && (
                          <p>
                            이미지를 등록하거나
                            <br />
                            드래그 & 드롭해 주세요.
                          </p>
                        )}
                      </>
                    ) : (
                      <div
                        className="flexColCenter imageContainer"
                        onMouseEnter={(e) => {
                          setHoverProfileState(true);
                          const icons = e.currentTarget.querySelectorAll('.iconStyle');
                          icons.forEach((icon) => (icon.style.opacity = '1'));
                        }}
                        onMouseLeave={(e) => {
                          setHoverProfileState(false);
                          const icons = e.currentTarget.querySelectorAll('.iconStyle');
                          icons.forEach((icon) => (icon.style.opacity = '0'));
                        }}
                      >
                        <img
                          src={IMAGES.FILE_EXPAN_ICON}
                          alt="expand icon"
                          className="iconStyle iconExpand"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreview('p');
                          }}
                        />

                        <img
                          src={IMAGES.FILE_CHANGE_ICON}
                          alt="change icon"
                          className="iconStyle iconChange"
                        />

                        <img
                          src={IMAGES.FILE_TRASH_ICON}
                          alt="trash icon"
                          className="iconStyle iconTrash"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemove();
                          }}
                        />
                        <img
                          src={profileImageUrl}
                          alt=''
                          id="profileImage"
                          style={{ filter: hoverProfileState ? 'brightness(0.7)' : 'brightness(1)' }}
                        />
                      </div>
                    )}
                  </Dragger>
                </Form.Item>
              </div>
            </>
          ) : (
            <Image
              src={profileImageUrl}
              className="profilePreview"
              alt="Profile Preview"
              fallback={IMAGES.DEFAULT_PROFILE}
              onError={(e) => handleImageError(e, imageProfileError, IMAGES.DEFAULT_PROFILE)}
            />
          )}
        </div>
      );
    } else {
      return (
        <div>
          {isEditMode ? (
            <>
              <div className="flexColCenter plusBannerBox">
                <Form.Item name={name} valuePropName="fileList" getValueFromEvent={normFile}>
                  <Dragger accept=".jpg,.jpeg,.png" action="" maxCount={1} listType="picture-card" showUploadList={false} beforeUpload={beforeUpload}>
                    {introImageUrl === null || imageBannerError.current || (introImageUrl !== null && imageBannerError.current) ? (
                      <>
                        <div className="flexColCenter plus">
                          <PlusOutlined />
                        </div>
                        {width > 700 && (
                          <>
                            <p>이미지를 등록하거나 드래그 & 드롭해 주세요.</p>
                            <p>* 권장 사이즈 1900 x 320 px</p>
                          </>
                        )}
                      </>
                    ) : (
                      <div
                        className="flexColCenter imageContainer"
                        style={{position: 'relative'}}
                        onMouseEnter={(e) => {
                          setHoverChanelState(true);
                          const icons = e.currentTarget.querySelectorAll('.iconStyle');
                          icons.forEach((icon) => (icon.style.opacity = '1'));
                        }}
                        onMouseLeave={(e) => {
                          setHoverChanelState(false);
                          const icons = e.currentTarget.querySelectorAll('.iconStyle');
                          icons.forEach((icon) => (icon.style.opacity = '0'));
                        }}
                      >
                        <img
                          src={introImageUrl}
                          alt=''
                          style={{
                            borderRadius: '2%',
                            transition: 'filter 0.3s ease-in-out',
                            filter: hoverChanelState ? 'brightness(0.7)' : 'brightness(1)',
                          }}
                        />
                        <img
                          className="iconStyle iconExpand"
                          src={IMAGES.FILE_EXPAN_ICON}
                          alt=''
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreview('i');
                          }}
                        />
                        <img
                          className="iconStyle iconChange"
                          src={IMAGES.FILE_CHANGE_ICON}
                          alt=''
                        />
                        <img
                          className="iconStyle iconTrash"
                          src={IMAGES.FILE_TRASH_ICON}
                          alt=''
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemove();
                          }}
                        />
                      </div>
                    )}
                  </Dragger>
                </Form.Item>
              </div>
            </>
          ) : (
            <Image
              src={introImageUrl}
              alt="Intro Preview"
              style={{
                width: '100%',
                maxHeight: '300px',
                objectFit: 'cover',
              }}
              fallback={IMAGES.DEFAULT_BANNER}
              onError={(e) => handleImageError(e, imageBannerError, IMAGES.DEFAULT_BANNER)}
            />
          )}
        </div>
      );
    }
  };

  const renderAiFl = () => <AiField isEditMode={isEditMode} aiList={codes.aiList} selectedAis={selectedAis} handleAiSelect={handleAiSelect} handleAiRemove={handleAiRemove} />;

  const handleAiSelect = (value) => {
    const selectedOption = codes.aiList.find((ai) => ai.value === value);
    if (selectedOption && !selectedAis.some((ai) => ai.value === value)) {
      setSelectedAis([...selectedAis, selectedOption]);
    }
  };

  const handleAiRemove = (value) => {
    setSelectedAis(selectedAis.filter((ai) => ai.value !== value));
  };

  const handleCancel = () => {
    setIsEditMode(false);
    selectUser();
    setProfileImageUrl(getImageUrl(userDetailData?.mem_img_path));
    setIntroImageUrl(getImageUrl(userDetailData?.ch_img_path));
    setProfileImageFile(null);
    setIntroImageFile(null);
    setIsPwModalVisible(false);
    form.setFieldsValue(userDetailData);
  };

  const handleSave = useCallback(async () => {
    try {
      const values = await form.validateFields();

      if (!hasChanges()) {
        message.info('변경된 내용이 없습니다.');
        return;
      }

      const newParams = {
        ...values,
        mem_nick: values.mem_nick,
        mem_key: parseInt(overrideData?.memKey, 10),
        mem_tel: phoneNumber.current.replace(/[^0-9]/g, ''),
        loc_cd_1: values.loc_nm_1,
        loc_cd_2: values.loc_nm_2,
        ai_fl_cd: selectedAis.map((ai) => ai.value).join('|'),
        mem_img_chk: defaultProfileChk.current ? 'Y' : 'N',
        ch_img_chk: defaultIntroChk.current ? 'Y' : 'N',
      };
      const formData = new FormData();
      if (profileImageFile) {
        formData.append('files', profileImageFile);
        newParams.mem_img_path = profileImageFile.name;
      }
      if (introImageFile) {
        formData.append('files', introImageFile);
        newParams.ch_img_path = introImageFile.name;
      }

      formData.append('memberVO', new Blob([JSON.stringify(newParams)], { type: 'application/json' }));
      const response = await api.updateProfile(formData);

      if (response.status === 200) {
        // 유저정보 변경후, 프로필 정보 가져오기
        const userProfile = await api.userProfile({ params });
        const parsedUserProfile = {
          ...userProfile?.data,
          follower_list: JSON.parse(userProfile?.data?.follower_list),
          following_list: JSON.parse(userProfile?.data?.following_list),
        };
        // 세션 정보 저장
        await Promise.all([localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(parsedUserProfile)), dispatch(setAuth(parsedUserProfile))]);
        message.success('저장되었습니다.');
        handleCancel();
      }
    } catch (error) {
      message.error('저장 중 오류가 발생했습니다.');
      console.error('Save error:', error);
    }
  }, [form, selectedAis, profileImageFile, introImageFile]);

  const hasChanges = () => {
    const currentValues = form.getFieldsValue();
    const hasFormChanges = Object.keys(currentValues).some((key) => {
      if (key === 'profile_image' || key === 'intro_image') return false;
      return currentValues[key] !== userDetailData?.[key];
    });

    const hasProfileImageChange = profileImageFile !== null;
    const hasIntroImageChange = introImageFile !== null;
    const originalAiCodes = userDetailData?.ai_fl_cd ? userDetailData.ai_fl_cd.split('|') : [];
    const currentAiCodes = selectedAis.map((ai) => ai.value);
    const hasAiChanges = JSON.stringify(originalAiCodes.sort()) !== JSON.stringify(currentAiCodes.sort());

    return hasFormChanges || hasProfileImageChange || hasIntroImageChange || hasAiChanges;
  };

  const handlePhoneAuthentication = (authenticatedPhoneNumber) => {
    const phoneStr = String(authenticatedPhoneNumber || '');
    const cleanPhoneNumber = phoneStr.replace(/[^0-9]/g, '');
    const formattedNumber = phoneFormat(cleanPhoneNumber);
    phoneNumber.current = formattedNumber;

    setIsEditMode(true);
    setIsPhoneAuthModalVisible(false);
  };

  const handlePwCheckAuth = (checkModal) => {
    setIsEditMode(checkModal);
    setIsPwModalVisible(!checkModal);
  };

  const sellerInfo = useCallback(async () => {
    try {
      const { data } = await api.listSeller({ mem_key: memKey });
      if (!data) return;

      setIsSellerInfo(data.items[0]);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      try {
        await fetchCommonCodes();
        await selectUser();
        await getAiCertList();
      } catch (error) {
        message.error('데이터 로딩 중 오류가 발생했습니다.');
      } finally {
        setIsLoading(false);
      }
    };

    sellerInfo();

    initializeData();
  }, [fetchCommonCodes, selectUser]);

  useEffect(() => {
    if (userDetailData?.mem_tel) {
      phoneNumber.current = phoneFormat(userDetailData.mem_tel);
    }
  }, [userDetailData]);

  useEffect(() => {
    const loc_cd_1 = form.getFieldValue('loc_nm_1');
    const loc_cd_2 = userDetailData?.loc_cd_2;

    if (loc_cd_1 && loc_cd_2) {
      fetchLoc2Format(loc_cd_1).then(() => {
        form.setFieldsValue({
          loc_nm_2: loc_cd_2,
        });
      });
    }
  }, [userDetailData?.loc_cd_2]);

  useEffect(() => {
    if (userDetailData?.ai_fl_cd && codes.aiList.length > 0) {
      const aiCodes = userDetailData.ai_fl_cd.split('|');
      const initialSelectedAis = aiCodes.map((code) => codes.aiList.find((item) => item.value === code)).filter(Boolean);
      setSelectedAis(initialSelectedAis);
    }
  }, [userDetailData?.ai_fl_cd, codes.aiList]);

  useEffect(() => {
    const section = document.querySelector('#findPw');
    const title = document.querySelector('#findPw h1');

    if (isModalVisiblePW) {
      if (section) {
        section.style.display = isModalVisiblePW ? 'flex' : 'none';
      }

      if (title) {
        title.style.display = isModalVisiblePW ? 'block' : 'none';
      }
    }
  }, [isModalVisiblePW]);

  const [isAccountDelMoal, setIsAccountDelMoal] = useState(false);
  const today = dayjs();
  const oneWeekAgo = today.subtract(7, 'day');

  const aiCertListRef = useRef(null);

  const getAiCertList = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.getAiCertList({
        keyword_type: '0',
        keyword_text: params.mem_key,
        startDt: oneWeekAgo.format('YYYY-MM-DD'),
        endDt: today.format('YYYY-MM-DD'),
        selector_3: '1',
      });

      aiCertListRef.current = data?.items?.filter((item) => item.cert_stat === '02') || [];
    } catch (error) {
      console.error('AI Cert List Fetch Error:', error);
      message.error(error.message || '데이터를 불러오는 데 실패했습니다.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    sellerInfo();
  }, [isModalVisibleSeller])

  return (
    <>
      <article id="myProfile" className="flexColCenter">
        <Loading isLoading={isLoading} />
        <div className="rsWrapper flexColCenter">
          <Form
            form={form}
            layout="vertical"
          >
            <div className="flexRowCenter titleBox">
              <div
                className="line"
                style={{
                  backgroundImage: `url(${borderLine})`,
                }}
              ></div>
              <div className="flexRowCenter title">
                <img src={profileIcon} />
                <h1>기본정보</h1>
              </div>
              <div
                className="line"
                style={{
                  backgroundImage: `url(${borderLine})`,
                }}
              ></div>
            </div>

            <div className="flexRowBetween userInfo">
              <div className="title flexRowStart">
                <img src={profileIcon} />
                <h1>회원정보</h1>
              </div>

              <div className="flexColCenter content">
                <div className="flexRowBetween box">
                  <div className="flexColStart info">
                    <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>유저네임</h1>
                    <div className="flexRowBetween rowBox">
                      {renderField('mem_nick', userDetailData?.mem_nick?.replace(/@/g, ''), { disabled: false })}
                      {/*{isEditMode ? (*/}
                          {/*<Button id="darkGrey" className="mobileBtn" onClick={() => setIsArtistEditModalVisible(true)}>
                            아티스트 등록
                          </Button>*/}
                      {/*): null}*/}
                    </div>
                  </div>

                  <div className="flexColStart info">
                    <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>이메일</h1>
                    {renderField('mem_email', userDetailData?.mem_email, { disabled: true })}
                  </div>
                </div>

                <div className="flexRowBetween box">
                  <div className="flexColStart info">
                    <div className="flexRowBetween">
                      <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
                        연락처&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {width < 700 && isEditMode && <span className="comment">* &nbsp;연락처를 변경 할 경우 새로 인증이 필요합니다.</span>}
                      </h1>

                      {width > 700 && isEditMode && <p className="comment">* &nbsp;연락처를 변경 할 경우 새로 인증이 필요합니다.</p>}
                    </div>
                    {isEditMode ? (
                      <div className="flexRowBetween rowBox">
                        <Input
                          value={phoneNumber.current}
                          className="mobileInput"
                          placeholder='"-"를 제외한 숫자만 입력해 주세요.'
                          maxLength={13}
                          disabled={true}
                          rules={[
                            { required: true, message: '전화번호를 입력해주세요.' },
                            {
                              pattern: /^(01[016789]-?\d{3,4}-?\d{4}|02-?\d{3,4}-?\d{4}|0[3-9]{1}[0-9]{1}-?\d{3,4}-?\d{4})$/,
                              message: '올바른 전화번호 형식이 아닙니다.',
                            },
                          ]}
                        />
                        <Button id="darkGrey" className="mobileBtn" onClick={() => setIsPhoneAuthModalVisible(true)}>
                          인증요청
                        </Button>
                      </div>
                    ) : (
                      <p>{renderField('mem_tel', phoneFormat(userDetailData?.mem_tel), { disabled: true })}</p>
                    )}
                  </div>

                  <div className="flexColStart info">
                    <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>위치</h1>

                    {isEditMode ? (
                      <div className="flexRowBetween rowBox">
                        {renderSelect('loc_nm_1', userDetailData?.loc_nm_1, codes.loc1List, { placeholder: '도, 시' })}
                        {renderSelect('loc_nm_2', userDetailData?.loc_nm_2, codes.loc2List, { placeholder: '구' })}
                      </div>
                    ) : (
                      <div className="flexRowBetween rowBox">
                        <p>{renderField('loc_nm_1', userDetailData?.loc_nm_1, { disabled: true })}</p>
                        <p>{renderField('loc_nm_2', userDetailData?.loc_nm_2, { disabled: true })}</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flexRowBetween box" id={isEditMode ? 'update' : 'nothing'}>
                  {isEditMode && (
                    <div className="flexColStart info">
                      <div className="flexRowBetween">
                        <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
                          비밀번호 변경&nbsp;
                          {width < 700 && isEditMode && <span className="comment">* 8~12자리 영문, 숫자, 특수기호 포함</span>}
                        </h1>
                        {width > 700 && isEditMode && <p className="comment">* 8~12자리 영문, 숫자, 특수기호 포함</p>}
                      </div>
                      <div className="flexRowBetween rowBox">
                        <Button id="darkGrey" onClick={() => setIsModalVisiblePW(true)}>
                          변경
                        </Button>
                      </div>
                    </div>
                  )}
                  <div className="flexColStart info">
                    <h1 style={{ margin: width < 700 ? `0 0 5px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>소속</h1>
                    {renderField('group', userDetailData?.group, { placeholder: '소속' })}
                  </div>
                </div>
                {/*{width < 700 && isEditMode && (
                  <div className="box flexColStart">
                    <div className="flexColStart info">
                      <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>계정삭제</h1>
                      <Button id="logoutBtn" onClick={() => setIsAccountDelMoal(true)}>
                        탈퇴요청
                      </Button>
                    </div>
                  </div>
                )}*/}
              </div>
            </div>

            <div className="flexRowBetween myInfo">
              <div className="title flexRowStart">
                <img src={myInfoIcon} />
                <h1>내 소개</h1>
              </div>
              <div className="flexColCenter content">
                <div className="flexRowBetween box">
                  <div className={isEditMode ? 'flexColStart thumbUpdate' : 'thumb'}>
                    <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>내 프로필 이미지</h1>
                    <div className="profileUpload">{renderUpload('profile_image', userDetailData?.profile_image)}</div>
                    {isEditMode && (
                      <p className="comment" style={{ fontSize: '11px' }}>
                        * 권장 사이즈 300 x 300px
                      </p>
                    )}
                  </div>
                  <div className={isEditMode ? 'flexColCenter infoBoxUpdate' : 'flexColCenter infoBox'}>
                    {/*<div className="flexRowBetween box2">
                      <div className="flexColStart info">
                        <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>웹 사이트</h1>
                        {renderField('web', userDetailData?.web, { placeholder: '웹사이트를 입력해주세요.' })}
                      </div>

                      <div className="flexColStart info">
                        <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>Youtube</h1>
                        {renderField('youtube_link', userDetailData?.youtube_link, { placeholder: '유튜브를 입력해주세요.' })}
                      </div>
                    </div>*/}
                    <div className="flexRowBetween box2">
                      <div className="flexColStart info">
                        <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>인스타그램</h1>
                        {renderField('insta_link', userDetailData?.insta_link, { placeholder: '인스타그램 아이디를 입력해주세요.' })}
                      </div>
                    </div>
                    <div className="flexRowBetween box2">
                      <div className="flexColStart info">
                        <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>X</h1>
                        {renderField('x_link', userDetailData?.x_link, { placeholder: 'X 아이디를 입력해주세요.' })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flexColStart info">
                  <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
                    소개 이미지&nbsp;
                    {width < 700 && isEditMode && <span className="comment">* 이미지 권장 사이즈(1900px X 320px, 최대 300KB)</span>}
                  </h1>
                  {renderUpload('intro_image', userDetailData?.intro_image)}
                </div>

                <div className="flexColStart info">
                  <div className="flexRowBetween">
                    <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>
                      페이지 명 &nbsp;
                      {width < 700 && isEditMode && <span className="comment">* 최대 15자로 입력하세요.</span>}
                    </h1>

                    {isEditMode && width > 700 && <p className="comment">* 최대 15자로 입력하세요.</p>}
                  </div>
                  {renderField('about_me', userDetailData?.about_me, { placeholder: '페이지명을 입력해주세요.' })}

                  <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}>설명 &nbsp;</h1>
                  {renderField('about_chanel', userDetailData?.about_chanel, { placeholder: '설명을 입력해주세요' })}
                </div>
              </div>
            </div>

            {renderAiFl('ai_fi_nm', aiFormat[userDetailData?.ai_fi_cd], codes.aiList, { placeholder: 'AI 분야 선택(중복)' })}

            <div className="flexRowBetween aiInfo ">
              <div className="title flexRowStart">
                <img src={profileIcon} />
                <h1>AI 정보</h1>
                {width > 800 ? (
                  <div className="flexColStart">
                    <button type='button' id="darkGrey" onClick={() => setIsModalVisible(true)}>
                      <PlusOutlined />
                      &nbsp; 정보 항목 추가
                    </button>
                  </div>
                ) : (
                  <button type='button' id="darkGrey" onClick={() => setIsModalVisible(true)}>
                    <PlusOutlined />
                    &nbsp; 정보 항목 추가
                  </button>
                )}
                <AiModelCertModal visible={isModalVisible} onClose={() => setIsModalVisible(false)} />
              </div>

              <div className="flexColStart content">
                <p>
                  AI 모델 이용정보&nbsp;&nbsp;
                  <span className="comment">* 인증 확인된 정보만 표시됩니다.</span>
                </p>

                <div className="flexRowStart tabBox">
                  {aiCertListRef && aiCertListRef.current && aiCertListRef.current.length > 0
                    ? aiCertListRef.current
                        .filter((ai) => ai.cert_stat === '02')
                        .map((ai) => (
                          <div className="tab flexColCenter" key={ai.seq}>
                            <h1>{ai.ai_model_nm}</h1>
                          </div>
                        ))
                    : null}
                </div>
              </div>
            </div>

            <div className="flexRowBetween sellerInfo">
              <div className="title flexRowStart">
                <img src={profileIcon} />
                <h1>판매자 정보</h1>
                {isEditMode && (
                  <div className="flexColStart">
                    <button type='button' id="darkGrey" onClick={() => setIsModalVisibleSeller(true)}>
                      {!isSellerInfo ? '등록' : '재등록'}
                    </button>
                  </div>
                )}
              </div>

              <div className="flexColStart content">
                <div className="flexColStart info">
                  {isSellerInfo && <h1 style={{ margin: width < 700 ? `0 0 10px` : isEditMode ? `0 0 9px` : `0 0 12px` }}> 대표자명 : {isSellerInfo?.represent_nm}</h1>}
                  <p>
                    {isSellerInfo?.company_nm} {isSellerInfo?.bank_nm} {isSellerInfo?.account}
                  </p>
                </div>
              </div>
            </div>

            { isEditMode && (
              <div className="flexRowBetween secession">
                <div className="title flexRowStart">
                  <button type='button' id="logoutBtn" onClick={() => setIsAccountDelMoal(true)}>
                    <img src={secessionIcon} /> 탈퇴 요청
                  </button>
                </div>
              </div>
            )}

            <div className="flexRowCenter btnBox" id={isEditMode ? 'update' : 'nothing'}>
              {!isEditMode ? (
                <button type='button' id="darkGrey" onClick={() => setIsPwModalVisible(true)}>
                  수정
                </button>
              ) : (
                <>
                  <button id="purpleBtn" type="submit" onClick={handleSave}>
                    저장
                  </button>
                  <button type='button' id="logoutBtn" onClick={handleCancel}>
                    취소
                  </button>
                </>
              )}
            </div>

            {/*비밀번호 변경 Modal*/}
            <Modal
              title="비밀번호 변경"
              open={isModalVisiblePW}
              onCancel={() => setIsModalVisiblePW(false)}
              footer={null} // 모달 하단 버튼 제거
              centered // 모달 중앙 정렬
              destroyOnClose // 모달 닫을 때 내부 상태 초기화
            >
              <NewPassword email={userDetailData?.mem_email} verified={true} changePw={true} />
            </Modal>

            {/*전화번호 인증 Modal*/}
            <PhoneAuthModal visible={isPhoneAuthModalVisible} onClose={() => setIsPhoneAuthModalVisible(false)} onAuthenticate={handlePhoneAuthentication} initialPhoneNumber={phoneNumber.current} />

            {/*판매자 등록 Modal*/}
            <SellerRegist visible={isModalVisibleSeller} onClose={() => setIsModalVisibleSeller(false)} />

            {/*비밀번호 확인 Modal*/}
            <PasswordCheck isOpen={isPwModalVisible} onClose={handleCancel} onAuthenticate={handlePwCheckAuth} />

            <AccountDelModal isOpen={isAccountDelMoal} onClose={() => setIsAccountDelMoal(false)} />

            <ArtistEdit isOpen={isArtistEditModalVisible} onClose={() => setIsArtistEditModalVisible(false)} />
          </Form>
        </div>
      </article>
    </>
  );
};

export default Profile;
