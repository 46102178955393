import React, { useRef, useState, useEffect } from 'react';
import { Form, Modal, Input, Select } from 'antd';
import Draggable from 'react-draggable';

const RequestBad = ({ isOpen, onClose, onOk, selectOptions }) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    if (isOpen) form.resetFields();
  }, [isOpen]);

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          신고사유
        </div>
      }
      centered={true}
      open={isOpen}
      closable={false}
      footer={null}
      maskClosable={false}
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Form
        form={form}
        name="requestBad"
        layout="vertical"
        initialValues={{ bad_cd: 'BAD_RSN001', bad_cd_label: '비방이나 욕설', contents: '' }}
        autoComplete="off"
        scrollToFirstError
      >
        <Form.Item name="bad_cd" label="신고 사유 선택" rules={[{ required: true, message: '신고 사유를 선택해주세요.' }]}>
          <Select
              options={selectOptions}
              onChange={(value, option) => {
                const selectedLabel = option?.label || '';
                form.setFieldsValue({
                  bad_cd: value,
                  bad_cd_label: selectedLabel
                });
              }}
          />
        </Form.Item>
        <Form.Item name="bad_cd_label" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="contents" label="신고 사유 상세">
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
      <div className="flexWrapCenter" style={{ gap: '10px' }}>
        <button className="btn-grad"   onClick={() => {
          form.validateFields()
              .then(values => {
                onOk(values);
              })
              .catch(errorInfo => {
                console.log('Validation Failed:', errorInfo);
              });
        }}>
          신고하기
        </button>
        <button className="btn-white" onClick={onClose}>
          취소
        </button>
      </div>
    </Modal>
  );
};

export default RequestBad;
