import React from 'react'
import { Form, Input, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const DefaultForm = (props) => {

  const { 
    form, 
    imgFiles, 
    mp3Files, 
    mp4Files, 
    handleImageFileChange, 
    handleMp3FileChange, 
    handleMp4FileChange, 
    handlePreview, 
    beforeUpload, 
    changeElementKoName, 
    changeElementEnName 
  } = props;

  const { TextArea } = Input;

  return (
    <Form
      form={form} 
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 22 }}
      layout="horizontal"
    >
      <Form.Item label="국문" name="ko_nm" rules={[{ required: true, message: '필수 입력 항목' }, { pattern: /^[^a-zA-Z]*$/, message: '한글만 입력할 수 있습니다.', },]}>
        <Input placeholder="국문" onChange={changeElementKoName} maxLength={100}/>
      </Form.Item>
      <Form.Item label="영문" name="en_nm" rules={[{ required: true, message: '필수 입력 항목' }, { pattern: /^[^가-힣ㄱ-ㅎㅏ-ㅣ]*$/, message: '영어만 입력할 수 있습니다.', }]}>
        <Input placeholder="영문" onChange={changeElementEnName} maxLength={100}/>
      </Form.Item>
      <Form.Item label="이미지" name="img_phy_path">
        <Upload fileList={imgFiles} onChange={(e) => handleImageFileChange(e)} beforeUpload={(file) => beforeUpload(file, "image")} maxCount={1} onPreview={(file) => handlePreview(file, "image")}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Form.Item>
      <Form.Item label="MP3" name="mp3_phy_path">
        <Upload fileList={mp3Files} onChange={(e) => handleMp3FileChange(e)} beforeUpload={(file) => beforeUpload(file, "mp3")} maxCount={1} onPreview={(file) => handlePreview(file, "audio")}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Form.Item>
      <Form.Item label="MP4" name="mp4_phy_path">
        <Upload fileList={mp4Files} onChange={(e) => handleMp4FileChange(e)} beforeUpload={(file) => beforeUpload(file, "mp4")} maxCount={1} onPreview={(file) => handlePreview(file, "video")}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Form.Item>
      <Form.Item label="설명" name="desc1">
        <TextArea autoSize={{ minRows: 2, maxRows: 4 }} placeholder="설명" maxLength={500}/>
      </Form.Item>
      <Form.Item label="Key1" required={true} className="form-half-element-label">
        <Form.Item 
          name='attr1' 
          rules={[{ required: true, message: '필수 입력 항목' }, { pattern: /^[^a-zA-Z]*$/, message: '한글만 입력할 수 있습니다.', }]} 
          className="form-half-element form-half-element-left"
        >
          <Input placeholder="국문" maxLength={50}/>
        </Form.Item>
        <Form.Item 
          name='key1' 
          rules={[{ required: true, message: '필수 입력 항목' }, { pattern: /^[^가-힣ㄱ-ㅎㅏ-ㅣ]*$/, message: '영어만 입력할 수 있습니다.', }]} 
          className="form-half-element"
        >
          <Input placeholder="영문" maxLength={100}/>
        </Form.Item>
      </Form.Item>
      <Form.Item label="Key2" required={true} className="form-half-element-label">
        <Form.Item 
          name='attr2' 
          rules={[{ required: true, message: '필수 입력 항목' }, { pattern: /^[^a-zA-Z]*$/, message: '한글만 입력할 수 있습니다.', }]} 
          className="form-half-element form-half-element-left"
        >
          <Input placeholder="국문" maxLength={50}/>
        </Form.Item>
        <Form.Item 
          name='key2' 
          rules={[{ required: true, message: '필수 입력 항목' }, { pattern: /^[^가-힣ㄱ-ㅎㅏ-ㅣ]*$/, message: '영어만 입력할 수 있습니다.', }]} 
          className="form-half-element"
        >
          <Input placeholder="영문" maxLength={100}/>
        </Form.Item>
      </Form.Item>
      <Form.Item label="Key3" className="form-half-element-label">
        <Form.Item name='attr3' className="form-half-element form-half-element-left">
          <Input placeholder="국문" maxLength={50}/>
        </Form.Item>
        <Form.Item name='key3' className="form-half-element">
          <Input placeholder="영문" maxLength={100}/>
        </Form.Item>
      </Form.Item>
    </Form>
  )
}

export default DefaultForm