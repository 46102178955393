import React, { useRef, useState, useEffect } from 'react';
import { Form, Modal, Input, Select, message } from 'antd';
import Draggable from 'react-draggable';
// lib
import { getLocalStorage, isAdmin } from '@utils/lib';

const RejectReason = ({ isOpen, onClose, onOk, promptInfo, selectOptions }) => {
  const memAuth = getLocalStorage('prptbk-token', 'mem_auth') || '';
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    if (isOpen) {
      if (promptInfo) form.setFieldsValue({ reject_rsn_cd: promptInfo?.reject_rsn_cd, reject_rsn: promptInfo?.reject_rsn });
      else form.setFieldsValue({ reject_rsn_cd: 'REJECT001', reject_rsn: '' });
    }
  }, [isOpen]);

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          판매거절 사유
        </div>
      }
      centered={true}
      open={isOpen}
      closable={false}
      maskClosable={false}
      footer={null}
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Form form={form} name="requestRejectReason" layout="vertical" style={{ maxWidth: 500, margin: '30px auto 40px' }} autoComplete="off" scrollToFirstError>
        <Form.Item name="reject_rsn_cd" label="판매거절 사유 코드" rules={[{ required: true, message: '판매거절 사유를 선택해주세요.' }]}>
          <Select options={selectOptions} disabled={!isAdmin(memAuth)} />
        </Form.Item>
        <Form.Item name="reject_rsn" label="판매거절 사유 내용">
          <Input.TextArea rows={6} disabled={!isAdmin(memAuth)} />
        </Form.Item>
      </Form>
      {isAdmin(memAuth) ? (
        <div className="flexWrapCenter" style={{ gap: '10px' }}>
          <button className="btn-grad" onClick={() => onOk({ reject_rsn_cd: form.getFieldValue('reject_rsn_cd'), reject_rsn: form.getFieldValue('reject_rsn') })}>
            {promptInfo ? '수정' : '등록'}
          </button>
          <button className="btn-white" onClick={onClose}>
            취소
          </button>
        </div>
      ) : (
        <div className="flexWrapCenter">
          <button className="btn-grad" onClick={onClose}>
            확인
          </button>
        </div>
      )}
    </Modal>
  );
};

export default RejectReason;
