import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import playIcon2 from '@assets/images/common/play_icon2.png';
// lib
import { getDefaultImg, handleImageError, koKRFormat } from '@utils/lib';
import '@assets/css/populerPromptSlider.css';
import { IMAGES } from '@utils/image';

const CardPrompt = ({ prompt, openPlayer, moveToPromptDetailPage, moveToUserDetailPage, isActiveCheckbox, tooltipText, isDisabledCheckbox, isChecked, onCheckOne, visible, handleMouseOver }) => { /* , handleMouseLeave */
  // 카테고리 이름 가져오기
  const getCateNm = (prompt) => {
    return { CATE001: 'image', CATE002: 'video', CATE004: 'text' }[prompt?.cate_cd] || 'image';
  };

  return (
    <div className="staffBox " key={prompt?.prpt_id} id={['CATE002', 'CATE003'].includes(prompt?.cate_cd) ? 'playerBg' : ''}>
      <div className="absoluteBox " onClick={moveToPromptDetailPage}>
        <div>
          <div className="thumbnail" style={{ backgroundImage: `url(${getDefaultImg(getCateNm(prompt), prompt)})` }}>
            <div className="user">
              {isActiveCheckbox && (
                <div onClick={(e) => e.stopPropagation()}>
                  <Tooltip title={tooltipText} overlayClassName="widthAuto" visible={visible}>
                    <Checkbox className="check" checked={isChecked} onMouseOver={handleMouseOver}  onChange={onCheckOne} className={isDisabledCheckbox ? "custom-checkbox" : null}/> {/* onMouseLeave={handleMouseLeave} */}
                  </Tooltip>
                </div>
              )}
              {prompt?.ai_model_nm && <p>{prompt?.ai_model_nm}</p>}
            </div>

            <h2 className="">{prompt?.prpt_title}</h2>
          </div>
        </div>

        {['CATE002', 'CATE003'].includes(prompt?.cate_cd) && (
          <>
            <div className="hover-bg"></div>
            <div className="play flexColCenter">
              <img
                src={playIcon2}
                alt="playIcon"
                onClick={(e) => {
                  e.stopPropagation();
                  openPlayer();
                }}
              />
            </div>
          </>
        )}

        <div className="title">
          <div
            className="greyBox flexRowStart"
            onClick={(e) => {
              e.stopPropagation();
              moveToUserDetailPage(e);
            }}
          >
            <img src={prompt?.mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />

            <div className="flexColStart">
              <div className="flexRowBetween">
                <div className="imgBox flexRowCenter">
                  <h1>{prompt?.mem_nick}</h1>
                </div>

                <p>{Number(prompt?.sale_amt) === 0 ? '무료' : `₩ ${koKRFormat(prompt?.sale_amt)}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardPrompt;
