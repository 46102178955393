import React, { useEffect, useRef, useState } from 'react';
import Loading from '@components/Loading';
import { Button, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Table from '@components/Table';
import * as api from '@api/index';

const NoticeSetting = () => {
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);

  const userAlertListRef = useRef();

  const columnLayout = [
    { dataField: 'alrt_contents', headerText: '<span class="gridLeft">알림 내용</span>', width: '55%' },
    {
      dataField: 'email_yn',
      headerText: '이메일',
      width: '15%',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) { // HTML 템플릿 작성
        const checked = value === 'Y' ? 'checked' : '';
        return `<input type="checkbox" ${checked} onchange="updateCheck(this, ${rowIndex}, ${columnIndex})" ${value === 'X' ? 'disabled' : ''}>`;
      }
    },
    {
      dataField: 'sms_yn',
      headerText: '문자',
      width: '15%',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) { // HTML 템플릿 작성
        const checked = value === 'Y' ? 'checked' : '';
        return `<input type="checkbox" ${checked} onchange="updateCheck(this, ${rowIndex}, ${columnIndex})" ${value === 'X' ? 'disabled' : ''}>`;
      }
    },
    {
      dataField: 'alrt_yn',
      headerText: '<span class="gridRight">메시지</span>',
      width: '15%',
      renderer: { type: 'TemplateRenderer' },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) { // HTML 템플릿 작성
        const checked = value === 'Y' ? 'checked' : '';
        return `<input type="checkbox" ${checked} onchange="updateCheck(this, ${rowIndex}, ${columnIndex})" ${value === 'X' ? 'disabled' : ''}>`;
      }
    },
  ];

  window.updateCheck = (checkbox, rowIndex, columnIndex) => {
    const newValue = checkbox.checked ? 'Y' : 'N';
    userAlertListRef.current.setCellValue(rowIndex, columnIndex, newValue);
  };

  const userAlertProps = {
    selectionMode: 'singleRow',
    editable: false,
    showRowCheckColumn: false,
    showRowNumColumn: false,
    autoGridHeight: true,
  };

  const getAlertSetting = async () => {
    try {
      const response = await api.getAlertSetting();
      userAlertListRef.current.setGridData(response.data);
    } catch (error) {
      console.error('조회 error', error);
    }
  };

  //
  const alrtSettingSave = async () => {
    console.log(userAlertListRef.current.getGridData());
    if (!(await window.confirm('저장하시겠습니까?'))) return false;
    const saveItems = userAlertListRef.current.getEditedRowItems({});

    try {
      setLoading((prev) => ({ ...prev, saveLoad: true }));

      const response = await api.updAlrtSetting(saveItems);
      alert(response.data.returnMessage);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => ({ ...prev, saveLoad: false }));
      getAlertSetting();
    }
  };

  useEffect(() => {
    getAlertSetting();
  }, []);

  return (
    <>
      <article id="myProfile">
      <div className="notice-area">
      <Loading isLoading={isLoading} timeout={0} />
      <Layout style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Content style={{width: '100%'}}>
          <Table ref={userAlertListRef} columnLayout={columnLayout} customGridProps={userAlertProps} rowNumColNone={true}/>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
            <Button onClick={alrtSettingSave}>저장</Button>
          </div>
        </Content>
      </Layout>
      </div>
        </article>
    </>
  );
};
export default NoticeSetting;
