import React, { useState, useEffect } from 'react'
import { Modal, message } from 'antd';
import * as lib from '@utils/lib';

const ReceiptModal = (props) => {
  const {visible, onClose, amount, isCoupon, openPaymentCouponModal, paymentAmount , setPaymentAmount, handleCouponPayment, setIsAutomaticPayment} = props;

  const [isDiscountVisible, setIsDiscountVisible] = useState(false);
  const [selectedCheckedMethod, setSelectedCheckedMethod] = useState('CARD');

  const handlePaymentChange = (event) => {
    setSelectedCheckedMethod(event.target.value);
  }

  const handleConfirmPayment = async () => {
    if(selectedCheckedMethod){
      if(selectedCheckedMethod === 'COUPON'){
        if(paymentAmount !== 0){
          message.error('쿠폰을 선택해주세요.');
          return false;
        }else{
          if(await window.confirm('선택한 쿠폰으로 결제하시겠습니까?')){
            handleCouponPayment(selectedCheckedMethod);
          }
        }
      }else{
        setIsAutomaticPayment()
      }
    }else{
      message.error('결제수단을 선택해주세요.');
      return false;
    }
  }

  useEffect(() => {
    setSelectedCheckedMethod('CARD');
  }, [visible])

  useEffect(() => {
    if(selectedCheckedMethod === 'COUPON'){
      openPaymentCouponModal();
    }else{
      setIsDiscountVisible(false);
      setPaymentAmount(amount)
    }
  }, [selectedCheckedMethod])

  useEffect(() => {
    if(paymentAmount === 0){
      setIsDiscountVisible(true)
    }else{
      setIsDiscountVisible(false)
    }
  }, [paymentAmount])

  return (
    <Modal
      open={visible}
      title="결제"
      width={`auto`}
      style={{maxWidth:"1000px"}}
      footer={null}
      onCancel={() => {
        onClose();
      }}
    >
      <div>
        <div className="modalReceipt">
          <div className="optList">
            <label className="opt">
              <input type="radio" name="payment" value="CARD" onChange={handlePaymentChange} checked={selectedCheckedMethod === 'CARD'}/>신용카드
            </label>
            {isCoupon &&
              <label className="opt">
                <input type="radio" name="payment" value="COUPON" onChange={handlePaymentChange} checked={selectedCheckedMethod === 'COUPON'}/>쿠폰사용
              </label>
            }
          </div>
          <div className="receipt">
            <div className="receipt__content">
              <h5>결제금액</h5>
              <p>
                상품금액
                <b><span>{lib.formatNumber(amount)}</span>원</b>
              </p>
              {isDiscountVisible ? (
                <p>
                  할인금액(쿠폰할인)
                  <b class="red">-<span>{lib.formatNumber(amount)}</span>원</b>
                </p>
              ) : null}
            </div>
            <div className="receipt__total">
              총 결제금액
              <b><span>{lib.formatNumber(paymentAmount)}</span>원</b>
            </div>
          </div>
        </div>
        <div className='flexWrapCenter' style={{ gap: '10px' }}>
          <button className='btn-grad' onClick={handleConfirmPayment}>결제하기</button>
          <button className='btn-white' onClick={onClose}>취소</button>
        </div>
      </div>
    </Modal>
  );
}

export default ReceiptModal