import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Form, Input, message, Upload, Image} from 'antd';
import qs from 'qs';
import Editor from '../../components/Editor';
import Loading from "@components/Loading";
import * as api from '@api/index';
import {v4 as uuidv4} from 'uuid';
import { BOARD_HOME } from "@routes/pathName";
import { IMAGES } from '@utils/image';
import {PlusOutlined} from "@ant-design/icons";

const BoardEdit = () => {
    const uploadSize = process.env.REACT_APP_UPLOAD_SIZE;
    const navigate = useNavigate();
    const location = useLocation();
    const { board_no, doc_no, board_title, type, attach_yn } = location.state || {};

    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();
    const formRef = useRef(form);
    const [fileGrpSeq, setFileGrpSeq] = useState(null);
    const editorRef = useRef(null);
    const [editorContent, setEditorContent] = useState('');
    const [thumbnail, setThumbnail] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [delFileCaches, setDelFileCaches] = useState([]);
    const [existFileList, setExistFileList] = useState([]);
    const [previewImage, setPreviewImage] = useState('');
    const [previewOpen, setPreviewOpen] = useState(false);

    const [boardListState, setBoardListState] = useState({
        query: {
            keyword_type: 0,
            keyword_text: doc_no || '',
            board_no: board_no || localStorage.getItem('board_no'),
            selector_2: '',
        }
    });

    const getBoardList = useCallback(async (query) => {
        try {
            setIsLoading(true);
            const { data } = await api.getBoardList(query);
            if (!data) return setIsLoading(false);
            setBoardListState(prev => ({
                ...prev,
                items: data.items,
                totalCount: data.total,
                currentCount: data.items.length,
            }));
            if (!data.items.length) return;
            // console.log(data.items[0])

            const boardDetail = data.items[0];
            formRef.current.setFieldsValue({ title: boardDetail.title });
            setFileGrpSeq(boardDetail.file_grp_seq);
            const allFiles = boardDetail.totalFileList || [];
            setExistFileList(allFiles);

            // console.log(allFiles)
            const thumbnailFile = allFiles.find(file => file.attr1 === 's');
            const attachments = allFiles.filter(file => file.attr1 === 'a');
            const contentImgs = allFiles.filter(file => file.attr1 === 'c');

            // 썸네일 처리
            if (thumbnailFile) {
                const blob = await (await fetch(thumbnailFile.file_dwn_path)).blob();
                const file = new File([blob], thumbnailFile.file_nm, { type: blob.type });
                file.url = thumbnailFile.file_dwn_path;
                file.status = 'done';
                setThumbnail([file]);
                console.log(file);
            }

            // 첨부파일 처리
            if (attachments.length > 0) {
                const formattedAttachments = attachments.map(file => ({
                    uid: file.file_seq || file.uid || `${file.file_nm}-${file.file_seq}`,
                    name: file.file_org_nm,
                    status: 'done',
                    url: file.file_dwn_path,
                    originFileObj: file
                }));
                setFileList(formattedAttachments);
            }

            // SunEditor Content Images 처리
            let updatedContents = boardDetail.contents;
            contentImgs.forEach(img => {
                updatedContents = updatedContents
                    .replace(new RegExp(`src="[^"]*${img.file_org_nm}"`, 'g'), `src="${img.file_dwn_path}"`)
                    .replace(new RegExp(`<img[^>]*src="[^"]*${img.file_dwn_path}"[^>]*>`, 'g'), match =>
                        match.replace(/alt="[^"]*"/, `alt="${img.file_seq}"`)
                    );
            });
            setEditorContent(updatedContents);
        } catch (error) {
            console.error('게시글 데이터 불러오기 실패:', error);
            message.error('게시글 데이터를 불러오는데 실패했습니다.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleFileChange = ({ fileList: newFileList }) => {
        const filteredNewFiles = newFileList.filter(file => file.status !== 'done');

        if (filteredNewFiles.some(file => file.size > 10 * 1024 * 1024)) {
            message.error('각 파일의 크기는 10MB를 초과할 수 없습니다.');
            return;
        }

        setFileList(newFileList);
    };

    const handleFileRemove = (file) => {
        setFileList(prevFileList => prevFileList.filter(item => item.uid !== file.uid));

        if (file.url) {
            setDelFileCaches(prev => [...prev, file.url]);
        }
    };

    const handleThumbnailChange = ({ file }) => {
        if (file.size > uploadSize * 1024 * 1024) {
            message.error('각 파일의 크기는 ' + uploadSize + 'MB를 초과할 수 없습니다.');
            return;
        }

        if (thumbnail.length > 0 && thumbnail[0]?.url) {
            if (thumbnail[0]?.status === 'done') {
                setDelFileCaches(prev => [...prev, thumbnail[0].url]);
            }
        }

        setThumbnail([file]);
        const reader = new FileReader();
        reader.readAsDataURL(file);
    };

    const handleSubmit = async (values) => {
        try {
            const { content, files: editorFiles } = await editorRef.current.getProcessedContent();

            let fileIndex = 0
            const basePath = `/file/board/${new Date().getFullYear()}/${String(new Date().getMonth() + 1).padStart(2, '0')}/`;
            const fileNames = editorFiles.map(file => file.name);
            const formData = new FormData();

            let updatedContent = content;
            if (!updatedContent?.replace(/<p><br><\/p>/g, "").trim()) {
                alert("내용은 필수 값 입니다.");
                return;
            }

            let updatedDelFileCaches = [...delFileCaches];
            // console.log("del file" + updatedDelFileCaches);

            const checkFile = existFileList.filter(file => file.attr1 === 'c');
            checkFile.forEach(file => {
                const { file_phy_path, file_org_nm, file_nm } = file;
                // basePath를 서버의 파일 저장 경로로 수정
                const basePath = file_phy_path.replace(file_nm, '');

                // 1a. file의 file_phy_path와 img의 src 값이 일치하지 않으면 updatedDelFileCaches에 추가
                const imgSrcChkRegex = new RegExp(`<img[^>]+src="${file_phy_path}"[^>]*>`, 'g');
                if (!content.match(imgSrcChkRegex)) {
                    updatedDelFileCaches.push(file.file_dwn_path);
                }

                // 1b. alt 값이 비어있지 않은 이미지의 src 경로 변경
                const imgSrcRegex = new RegExp(`(<img[^>]+src=["'])(${file_phy_path})(["'][^>]*alt=["'][^"']+["'][^>]*>)`, 'g');
                updatedContent = updatedContent.replace(imgSrcRegex, `$1${basePath}${file_org_nm}$3`);
            });

            // 2. alt=""인 이미지의 src 값 변경
            const imgRegex = /<img.*?src="(.*?)".*?alt="".*?>/g;
            updatedContent = updatedContent.replace(imgRegex, (match, src) => {
                if (src.startsWith('data:image')) {
                    return match;
                }
                const newPath = `${basePath}${fileNames[fileIndex++]}`;
                return match.replace(src, newPath);
            });

            const updateBoard = [{
                board_no: board_no,
                doc_no: doc_no || null,
                title: values.title,
                contents: updatedContent,
                _$uid: uuidv4(),
                state: (values === 'd') ? 'del' : '',
                file_grp_seq: fileGrpSeq
            }];

            formData.append('updateBoard', new Blob([JSON.stringify(updateBoard)], { type: 'application/json' }));
            if (type !== "List" && !thumbnail) {
                message.error('썸네일은 필수입니다. 썸네일을 선택하세요.');
                return;
            }
            if (type !== "List"){
                // 썸네일 추가
                thumbnail ? (formData.append('files', thumbnail[0]), formData.append('attr1', 's')) : null;
            }
            if(attach_yn === 'Y'){
                // 첨부 파일 추가
                fileList?.filter(file => file.status !== 'done').forEach((file, index) => {
                    formData.append(`files`, file.originFileObj || file);
                    formData.append(`attr1`, 'a');
                });
                // SunEditor 파일 추가
                editorFiles?.forEach((file, index) => {
                    // console.log(`Adding file ${index}:`, file);
                    formData.append(`files`, file);
                    formData.append(`attr1`, 'c');
                });
            }
            if(updatedDelFileCaches.length > 0){
                updatedDelFileCaches.forEach((delFile) => {
                    formData.append('delfiles', delFile);
                });
            } else {
                // console.log('No files to delete.');
            }

            const response = await api.updatedBoard(formData);
            // console.log('Response:', response.data);
            if(values === 'd'){
                message.success('게시글이 삭제되었습니다.');
            } else {
                message.success('게시글이 성공적으로 저장되었습니다.');
            }
            const queryString = qs.stringify({ board_no: board_no, board_title: board_title });
            navigate(`${BOARD_HOME}?${queryString}`);
        } catch (e) {
            console.error('게시글 저장 실패:', e);
            if (e.response) {
                console.error('Error response:', e.response.data);
                if(values === 'd'){
                    message.error(`게시글 삭제를 실패했습니다.: ${e.response.data.message || e.message}`);
                } else {
                    message.error(`게시글 저장에 실패했습니다.: ${e.response.data.message || e.message}`);
                }
            } else {
                message.error('게시글 저장에 실패했습니다. 다시 시도해주세요.');
            }
        }
    };
    useEffect(() => {
        if (doc_no) {
            getBoardList(boardListState.query);
        }
    }, [doc_no]);


    const ThumbnailRenderer = () => {
        const beforeUpload = (file) => {
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
                alert('대표이미지는 이미지만 업로드 가능합니다.');
                return Upload.LIST_IGNORE;
            }
            return false;
        };

        const handlePreview = (e, url) => {
            e.stopPropagation();
            e.preventDefault();
            if(url){
                setPreviewImage(url);
                setPreviewOpen(true);
            }
        };

        const handleFileRemove = (e, file) => {
            e.stopPropagation();
            setThumbnail([]);
            setPreviewImage('');
            setDelFileCaches(prev => [...prev, file.url]);
        };

        return (
            <div>
                {/* 이미지 미리보기 */}
                {previewImage && (
                    <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                            visible: previewOpen,
                            onVisibleChange: (visible) => setPreviewOpen(visible),
                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                        }}
                        src={previewImage}
                    />
                )}
                {/* 대표이미지 업로더 */}
                <Upload.Dragger
                    accept="image/png, image/jpeg, image/jpg, image/webp"
                    fileList={thumbnail}
                    className="flexColCenter plusBox"
                    onChange={(file) => handleThumbnailChange(file)}
                    beforeUpload={beforeUpload}
                    showUploadList={false} // 기본 리스트 표시 비활성화
                >
                    {thumbnail?.length === 0 ? (
                        <div className="flexColCenter">
                            <div className="plus flexColCenter">
                                <PlusOutlined />
                            </div>
                            <p style={{ fontSize: '11px' }}>
                                대표 이미지를 등록하거나 드래그 & 드롭해주세요.
                                <br />
                                (16:9 사이즈 권장)
                            </p>
                        </div>
                    ) : (
                        <div className="flexColCenter">
                            {thumbnail.map((file) => (
                                <div className="flexColCenter imageContainer">
                                    {/* 미리보기 아이콘 */}
                                    <img
                                        src={IMAGES.FILE_EXPAN_ICON}
                                        alt="expand icon"
                                        className="expandIcon"
                                        onClick={(e) => handlePreview(e, URL.createObjectURL(file))}
                                    />
                                    {/* 변경 아이콘 */}
                                    <img
                                        src={IMAGES.FILE_CHANGE_ICON} alt="change icon" className="changeIcon" />
                                    {/* 삭제 아이콘 */}
                                    <img
                                        src={IMAGES.FILE_TRASH_ICON}
                                        alt="trash icon"
                                        className="trashIcon"
                                        onClick={(e) => handleFileRemove(e, file)}
                                    />
                                    {/* 업로드된 이미지 */}
                                    <img src={URL.createObjectURL(file)} alt="" className="uploadthum"/>
                                </div>
                            ))}
                        </div>
                    )}
                </Upload.Dragger>
            </div>
        );
    }

    return (
        <>
            <Loading isLoading={isLoading} />
            <section id="boardDetail" className="rsWrapper">
                <div className="l-form">
                    <h3 className="l-form__title">
                        <img src={IMAGES.LIST_ICON1} alt="아이콘" />
                        게시내용
                    </h3>
                    <Form form={form} layout="vertical" onFinish={handleSubmit} className="l-form__list" >
                        <div className="title-container">
                            <Form.Item name="title" label="제목" rules={[{ required: true, message: '제목을 입력하세요.' }]}>
                                <Input placeholder="제목을 입력하세요." />
                            </Form.Item>
                        </div>
                        {/*<div className="file-attachment">
                                    {attach_yn !== 'N' && ( // attach_yn이 N이 아닐 때만 렌더링
                                        <Form.Item>
                                            <span className="file-title">첨부파일</span>
                                            <Upload
                                                multiple
                                                fileList={fileList}
                                                onChange={handleFileChange}
                                                beforeUpload={() => false}
                                                showUploadList={false}
                                            >
                                                <Button icon={<UploadOutlined />} style={{ marginTop: '10px' }}>파일 선택</Button>
                                            </Upload>
                                            <div className="file-list" style={{
                                                marginTop: '5px',
                                                height: '170px',
                                                overflowY: 'auto',
                                                border: '1px solid #d9d9d9',
                                                padding: '10px',
                                                borderRadius: '4px',
                                                overflow: 'hidden',
                                                cursor: 'pointer',
                                            }}>
                                                {fileList.length === 0 ? (
                                                    <div
                                                        onClick={() => {
                                                            document.querySelector('input[type="file"]').click();
                                                        }}
                                                        style={{
                                                            color: '#999',
                                                            textAlign: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: '100%',
                                                            width: '100%',
                                                        }}>
                                                        파일 선택
                                                    </div>
                                                ) : (
                                                    <Upload
                                                        multiple
                                                        fileList={fileList}
                                                        onChange={handleFileChange}
                                                        beforeUpload={() => false}
                                                        itemRender={(originNode, file) => (
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center'
                                                            }}>
                                                                <span>{file.name}</span>
                                                                <DeleteOutlined onClick={() => handleFileRemove(file)} style={{ color: 'red' }} />
                                                            </div>
                                                        )}
                                                    />
                                                )}
                                            </div>
                                        </Form.Item>
                                    )}
                                </div>*/}
                        <div className="content-container view">
                            <Form.Item label="내용" required>
                                <Editor ref={editorRef} content={editorContent} setContent={setEditorContent} attach_yn={attach_yn} />
                            </Form.Item>
                        </div>
                        {type === "Card" && ( // attach_yn이 N이 아닐 때만 렌더링
                            <Form.Item label="썸네일" required style={{ height: '250px' }}>
                                <ThumbnailRenderer />
                            </Form.Item>
                        )}

                        <Form.Item className="btnArea">
                            <div className="button-group">
                                <Button className="btn-dark" htmlType="submit">
                                    {doc_no ? '저장' : '등록'}
                                </Button>
                                {doc_no &&
                                    <Button className="btn-red" onClick={() => handleSubmit('d')} style={{ marginLeft: '10px' }} danger>
                                        삭제
                                    </Button>
                                }
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </section>

        </>
    );
};

export default BoardEdit;