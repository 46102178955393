import React, { useState } from 'react';
import { Form, Input, Button, message, Modal } from 'antd';
// icon
import logo from '@assets/images/common/logo.png';
// api
import * as api from '@api/index';
import { useNavigate } from 'react-router-dom';

const INIT_STATE_FORGET_PASSWORD_INFO = {
  mem_email: '',
};

const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;

const ForgetPassword = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // 비밀번호 찾기
  const handleForgetEmail = async (formData) => {
    try {
      setIsLoading(true);
      const { data: selectedMember } = await api.forgetPassword(formData);
      if (selectedMember.returnStatus === 'success') {
        const verifedEmail = await api.sendVerificationEmailForNewPassword({ mem_email: selectedMember.mem_email });
        if (verifedEmail.status === 200) {
          Modal.success({content: (<div><p>인증 이메일이 발송되었습니다.</p><br /><p>이메일을 확인해주세요.</p></div>), onOk: () => {navigate('/')}, okText: '확인'})
          //
        } else {
          message.warning(`인증 이메일 발송 중 오류가 발생했 습니다: ${verifedEmail.data}`);
        }
      } else {
        message.warning(selectedMember.returnMessage);
      }
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="flexColCenter">
      <article id="findPw" className="flexColCenter">
        <div className="flexColCenter logo">
          <img src={logo} alt="logo" />
        </div>

        <h1>비밀번호 찾기</h1>

        <Form form={form} name="forgetPassword" initialValues={INIT_STATE_FORGET_PASSWORD_INFO} onFinish={handleForgetEmail} autoComplete="off" scrollToFirstError style={{ width: '100%' }}>
          <Form.Item
            name="mem_email"
            rules={[
              { required: true, message: '이메일을 입력해주세요!' },
              () => ({
                validator(_, value) {
                  if (!value || regexEmail.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('이메일 형식이 올바르지 않습니다!'));
                },
              }),
            ]}
          >
            <Input className="input" placeholder="이메일 주소를 입력해주세요." allowClear />
          </Form.Item>

          <p style={{ fontFamily: 'Pretendard', fontWeight: 400, textAlign: 'center' }}>∙ 가입하신 이메일 주소로 비밀번호 변경 메일을 발송해 드립니다.</p>

          <Form.Item>
            <Button id="purpleBtn" htmlType="submit" loading={isLoading}>
              이메일 발송
            </Button>
          </Form.Item>
        </Form>
      </article>
    </section>
  );
};

export default ForgetPassword;
