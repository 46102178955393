import React from 'react'
import { Form, Select, Checkbox, Input, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const AdditionalForm = (props) => {

  const { 
    builderForm, 
    categoryList, 
    lyricsChecked, 
    setLyricsChecked, 
    aiModelList, 
    libraryList, 
    handleChangedLibrary, 
    planList, 
    changedPromptCount, 
    lyricsList, 
    stepZeroFiles, 
    handleStepZeroImageFileChange, 
    beforeUpload, 
    handlePreview
  } = props;

  const { Option } = Select;

  return (
    <Form 
      form={builderForm}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 20 }}
      layout="horizontal"
    >
      <Form.Item label="카테고리" required={true} className="form-half-element-label">
        <Form.Item name='cate_cd' className="form-half-element form-half-element-left" rules={[{ required: true, message: '필수 입력 항목' }]}>
          <Select options={categoryList}></Select>
        </Form.Item>
        <Form.Item name='lyrics_yn' className="form-half-element">
          <Checkbox checked={lyricsChecked} onChange={(e) => setLyricsChecked(e.target.checked)}>가사빌더</Checkbox>
        </Form.Item>
      </Form.Item>
      <Form.Item label="AI모델" name="ai_model_cd" rules={[{ required: true, message: '필수 입력 항목' }]}>
        <Select mode="multiple" options={aiModelList}></Select>
      </Form.Item>
      <Form.Item label="라이브러리" name="lib_el_id" /*rules={[{ required: true, message: '필수 입력 항목' }]}*/>
        <Select options={libraryList} onChange={handleChangedLibrary}></Select>
      </Form.Item>
      <Form.Item label="사용여부" required={true} className="form-half-element-label">
        <Form.Item className="form-half-element form-half-element-left" name="use_yn" rules={[{ required: true, message: '필수 입력 항목' }]}>
          <Select options={[{label : "Y", value : "Y"}, {label : "N", value : "N"}]}></Select>
        </Form.Item>
        <Form.Item name='use_lv' className="form-half-element" rules={[{ required: true, message: '필수 입력 항목' }]}>
          <Select options={planList}></Select>
        </Form.Item>
      </Form.Item>
      <Form.Item label="파라미터 적용" name="param_use_yn" rules={[{ required: true, message: '필수 입력 항목' }]}>
        <Select options={[{label : "Y", value : "Y"}, {label : "N", value : "N"}]}></Select>
      </Form.Item>
      <Form.Item label="프롬프트 개수" required={true} className="form-half-element-label">
        <Form.Item name='prpt_cnt' className="form-half-element form-half-element-left" rules={[{ required: true, message: '필수 입력 항목' }]}>
          <Select onChange={changedPromptCount}>
          {Array.from({ length: 3 }, (_, i) => (
            <Option key={i + 1} value={i + 1}>
              {i + 1}
            </Option>
          ))}
          </Select>
        </Form.Item>
        <Form.Item name='lyrics_el_id' className="form-half-element">
          <Select options={lyricsList}></Select>
        </Form.Item>
      </Form.Item>
      <Form.Item label="Step Zero명" name="step_zero_nm">
        <Input placeholder="Step Zero명" maxLength={10}/>
      </Form.Item>
      <Form.Item label="Step Zero 입력방식" name="step_zero_type">
        <Select
            options={[
              {value: 'TEXTAREA', label: 'TextArea'},
              {value: 'INPUT', label: 'Input'},
            ]}
            defaultValue={'INPUT'}
        />
      </Form.Item>
      <Form.Item label="Step Zero 이미지" name="step_zero_phy_path">
        <Upload fileList={stepZeroFiles} onChange={(e) => handleStepZeroImageFileChange(e)} beforeUpload={(file) => beforeUpload(file, "image")} maxCount={1} onPreview={(file) => handlePreview(file, "image")}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Form.Item>
    </Form>
  )
}

export default AdditionalForm