import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { Button, Col, Form, message, Row } from 'antd';
import Loading from '@components/Loading';
import * as api from '@api/index';
import { ArrowDownOutlined, BookOutlined, DownOutlined, PlayCircleOutlined, UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import {dayFormat, formatBadgeIcon, formatEncodingBoardDate, formatNumber} from '@utils/lib';
import { ADMIN_INQUIRY_DETAIL, INQUIRY_EDIT } from '@routes/pathName';
import TextArea from 'antd/es/input/TextArea';
import '@assets/css/inquiry.css';
import * as PATH from "@routes/pathName";
import { IMAGES } from '@utils/image';
import {badge_map} from "@utils/constants";

const InquiryDetail = () => {
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보
  const { state } = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(1000);
  const [fileList, setFileList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [writerProfile, setWriterProfile] = useState({});
  const [inquiryState, setInquiryState] = useState({
    query: {
      mem_key: state?.mem_key || 5,
      inqr_seq: state?.inqr_seq,
    },
    item: {},
  });
  const [selectedAis, setSelectedAis] = useState([]);

  const answerRef = useRef(null);
  const wrapperRef = useRef(null);

  /** api */
  // 문의내역 조회
  const getMemInquiry = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.getMemInquiry(query);
      setInquiryState((prev) => ({ ...prev, item: data.item }));
      if (data.fileList) {
        setFileList(data.fileList.filter((file) => file.attr1 != 'c'));
      }
      answerRef.current = data.item.answer;
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  // 작성자 프로필 조회
  const getProfile = async (key) => {
    const params = { mem_key: key };
    const { data } = await api.userProfile({ params });
    setWriterProfile(data);

    if (data?.ai_fl_cd) {
      const ai_nm = data.ai_fl_nm.split('|');
      setSelectedAis(ai_nm);
    }
  };

  // 답변 저장 - 관리자용
  const saveAnswer = async (value) => {
    const answer = answerForm.getFieldValue('answer');
    const params = {
      mem_key: state?.mem_key,
      answer: answer,
      answer_mem_key: auth?.mem_key, // 답변 작성자
      inqr_seq: state.inqr_seq,
      inqr_stat: value === "ts" ? "02" : "03", // 02 임시저장, 03 답변완료
    };

    try {
      setIsLoading(true);
      setTime(0);
      const { data } = await api.saveAnswer(params);
      // console.log(data);
    } catch (e) {
      message.error('답변작성에 실패했습니다.');
    } finally {
      message.success(value === "ts" ? "답변 임시저장에 성공하였습니다." :  "답변 작성이 완료 되었습니다.")
      setIsLoading(false);
      setTime(1000);
      getMemInquiry(inquiryState.query);
    }
  };

  /** event 핸들러 */
  const downloadFile = async (filePath, fileName) => {
    try {
      const response = await fetch(filePath);
      if (!response.ok) throw new Error('File download failed');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleReWriteClick = () => {
    navigate(INQUIRY_EDIT, { state: { inqr_seq: inquiryState.item.inqr_seq, mem_key: inquiryState.query.mem_key } });
  };

  const [answerForm] = Form.useForm();
  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getMemInquiry(inquiryState.query);
    getProfile(inquiryState.query.mem_key);
  }, []);


  // 랭크 아이콘 치환
  const getRankItems = () => {
    if (!writerProfile?.agg_rank) return [];

    const ranks = writerProfile.agg_rank.split(',');
    const items = [
      { rank: ranks[0], icon: IMAGES.IMAGES_ICON, page: 0, label: 'image' },
      /*{ rank: ranks[1], icon: IMAGES.VIDEO_ICON, page: 1, label: 'video' },*/
      { rank: ranks[2], icon: IMAGES.MUSIC_ICON, page: 2, label: 'note' },
      { rank: ranks[3], icon: IMAGES.BOOK_ICON, page: 3, label: 'book' }
    ];

    return items.sort((a, b) => Number(a.rank) - Number(b.rank));
  };

  const sortedItems = getRankItems();
  const highestRankItem = sortedItems.length > 0 ? {...sortedItems[0]} : null;


  // 순위 팝업 닫기
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !event.target.closest('img')) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const AnswerArea = () => {
    if (inquiryState.item.inqr_stat == '03') {
      // 답변완료 상태일 때 답변 창만 보임 - 추가 문의/답글 제한
      return (
          <>
            <div className="buttonBox" style={{ marginBottom: '2%' }}>
              <button className="btn-back" onClick={handleGoBack}>뒤로가기</button>
            </div>

            <div className="answerBox">
              <ul className="info">
                <li className="name">{inquiryState.item.answer_mem_nick}</li>
                <li className="date">{formatEncodingBoardDate(inquiryState.item.fir_dt)}</li>
              </ul>
              <p className="cont"> {inquiryState.item.answer}</p>
            </div>

          </>
      );
    } else {
      // 미답변 상태
      if (state.flag == 'A') {

        // 관리자인 경우
        return (
            <Form form={answerForm} className="answerCreate flexRowBetween">
              <div className="create flexColStart">
                <p>답변하기</p>
              <div className="flexRowBetween">
                <TextArea className="input" placeholder="답변을 등록하세요"
                          defaultValue={answerRef.current}  // defaultValue로 설정
                          onChange={(e) => answerForm.setFieldsValue({ answer: e.target.value })} />
                <Button id="greyBtn" htmlType="submit" onClick={()=>saveAnswer('ts')}>
                  임시저장
                </Button>
                <Button id="greyBtn" htmlType="submit" onClick={()=>saveAnswer('fs')}>
                  답변완료
                </Button>
              </div>
            </div>
          </Form>
        );
      }
      if (state?.mem_key == auth?.mem_key) {
        // 본인 작성인 경우
        return (
          <div className="buttonBox">
            <button class="btn-back" onClick={handleGoBack}>뒤로가기</button>
            <button className="btn-edit" onClick={handleReWriteClick}>수정하기</button>
          </div>
        );
      }
    }
  };

  return (
      <>
        <Loading isLoading={isLoading} timeout={time} />
      <article id="inquiryDetail">
        <div className="titleBox">
          <span className="cate">{inquiryState.item.inqr_div_nm}</span>
          <h1 className="title">{inquiryState.item.inqr_title}</h1>
          <p className="date">{formatEncodingBoardDate(inquiryState.item.fir_dt)}</p>
        </div>

        <div className="userBox">
          <div className="userIcon">
            <img src={writerProfile.mem_img_path || IMAGES.DEFAULT_PROFILE} alt="image" />
          </div>

          <div className="userInfo">
            <div className="userInfo-top">
              <span className={`badge ${badge_map[writerProfile.plan_cd] || ''}`}>
                {writerProfile.plan_nm}
              </span>
              <p className="name">{writerProfile.mem_nick}</p>
            </div>

            <div className="userInfo-bottom" >
              <div className="ranking" style={{gap: '5px'}}  onClick={toggleDropdown}>
                <img
                    src={highestRankItem?.icon}
                    alt={`${highestRankItem?.label} icon`}
                    style={{cursor:'pointer', width:'18%'}}
                />
                <p style={{cursor: "pointer"}} >
                  #{highestRankItem?.rank}
                </p>
              </div>
              {/* 드롭다운 */}
              {isOpen && (
                <ul className="profileToggle" ref={wrapperRef}>
                  {sortedItems.map((item, index) => (
                      <li className="flexRowStart"
                          style={{cursor: "pointer"}}
                          onClick={() => moveToPage(item.page)}
                      >
                        <img src={item.icon} alt={`${item.label} icon`} style={{width:'18%'}}/>
                        <p>#{item.rank || ''}</p>
                      </li>
                  ))}
                </ul>
              )}
              {/*<div className="profileStar">
                {[...Array(5)].map((_, index) => (
                  <img key={index} src={starIcon} alt="star_icon" className={index < writerProfile.score_avg ? 'filledStar' : 'emptyStar'} />
                ))}
              </div>*/}
              <div className="major">
                  {formatBadgeIcon(writerProfile?.bdg_url)}
              </div>
            </div>
          </div>
          <div className="aiArea">
            <p>전문 AI 분야</p>
            <div className="majorModel">
              {selectedAis.length ? (
                  selectedAis.map((ai) => <span>{ai}</span>)
              ) : (
                  <span>없음</span>
              )}
            </div>
          </div>
        </div>

        <div className="fileBox">
          <p>첨부파일</p>
          <ul>
            {fileList && fileList.length > 0 ? (
              fileList.map((file) => (
                <li key={file.file_seq} onClick={() => downloadFile(file.file_dwn_path, file.file_org_nm)}>
                  <ArrowDownOutlined /> {file.file_org_nm}
                </li>
              ))
            ) : (
              <li> 첨부된 파일이 없습니다. </li>
            )}
          </ul>
        </div>

        <Row>
          <div
            style={{
              minHeight: '300px',
              maxHeight: '800px',
              width: '100%',
              overflowY: 'auto',
              padding: '20px 10px',
            }}
            dangerouslySetInnerHTML={{ __html: inquiryState.item.contents }}
          />
        </Row>
        <AnswerArea />
      </article>
    </>
  );
};

export default InquiryDetail;
