import styled from "styled-components";
export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

export const EmptyMessageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: #999;
`;
export const DateDivider = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    &::before, &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid #e8e8e8;
        margin: 0 10px;
    }
`;
export const DateLabel = styled.span`
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 12px;
    color: #666;
`;