import React from 'react';
// component
import CardPrompt from '@pages/marketplaces/CardPrompt';

const ListCardPrompt = ({ title, titleImg, promptList = [], openPlayer, moveToPromptDetailPage, moveToUserDetailPage }) => {
  return (
    <>
      <article id="listWrapper" className="flexColCenter promptList ">
        <article id="otherWrapper" className="flexColCenter">
          <div className="profileTitle">
            <div className=" ">
              <img src={titleImg} />
              <p>{title}</p>
            </div>
          </div>
          <div className="rsWrapper flexRowBetween">
            <div className="flexColCenter bottom newContents">
              <div className="listBox flexRowStart staffList">
                {!Array.isArray(promptList) || promptList.length === 0 ? (
                  <div className="noResult">
                    <p>등록된 프롬프트가 없습니다.</p>
                  </div>
                ) : (
                  <>
                    {promptList?.map((item, index) => (
                      <CardPrompt
                        key={index}
                        prompt={item}
                        openPlayer={() => openPlayer(item)}
                        moveToPromptDetailPage={() => moveToPromptDetailPage(item)}
                        moveToUserDetailPage={() => moveToUserDetailPage(item)}
                      />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </article>
      </article>
    </>
  );
};

export default ListCardPrompt;
