import React, { useRef, useState, useEffect } from 'react';
import { Form, Modal, Input, Select, Drawer } from 'antd';
import Draggable from 'react-draggable';
// icon
import { CloseOutlined } from '@ant-design/icons';
// lib
import { formatEncodingDate, getLocalStorage } from '@utils/lib';

const ListReviewMobile = ({ isOpen, onClose, promptInfo, reviewList, onReply, onSave, onChangeReply, reviewFlag }) => {
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';

  return (
    <Drawer open={isOpen} footer={null} closable={false} width={`100%`} bodyStyle={{ background: `#eff8f9` }}>
      <aticle id="reviewModal" className="flexColCenter">
        <div className="reviewTitle flexColEnd">
          <p onClick={onClose}>
            <CloseOutlined />
          </p>
        </div>

        <div className="reviewBox flexRowStart">
          <h1>리뷰</h1>
          <h2>{promptInfo?.mem_nick}에 대한 리뷰</h2>
          {/* <h3>{Math.round(promptInfo?.score_avg * 100) / 100 || 0}</h3>
          <Rate />
          <p>
            (<span>{reviewList.length}</span>)
          </p> */}
        </div>

        <div className="listBox flexColCenter scroll">
          <div className="listScroll flexColCenter">
            {reviewList.map((review) => (
              <div className="list flexRowBetween" key={review?.seq}>
                <div className="userReview flexRowStart">
                  <div className="flexColStart">
                    <div className="top flexRowBetween">
                      <div className="flexRowCenter">
                        <img src={review?.score_mem_img_path} />
                        <p>{review?.score_mem_nick}</p>
                        {/* <Rate className="mobileRate" style={{ fontSize: `10px` }} value={review?.score} /> */}
                      </div>

                      <p>{formatEncodingDate(review?.fir_dt)}</p>
                    </div>

                    <div className="rateBox flexRowStart">
                      {/* <Rate value={review?.score} />
                      <div className="line"></div> */}
                      <p>{formatEncodingDate(review?.fir_dt)}</p>
                    </div>

                    <p className="content">{review?.contents}</p>
                  </div>
                </div>
                {review?.re_cnt === 0 && promptInfo?.mem_key === memKey && (
                  <div className="mobileBtn flexColEnd">
                    <button id="darkGreyBorder" className="active" onClick={() => onReply(review?.seq)}>
                      답글
                    </button>
                  </div>
                )}
                {review?.re_cnt > 0 ? (
                  <div className="answer flexColStart">
                    <div className="flexRowBetween">
                      <h1>{review?.re_mem_nm}</h1>
                      <p>{formatEncodingDate(review?.rep_fir_dt)}</p>
                    </div>
                    <p>{review?.rep_contents}</p>
                  </div>
                ) : reviewFlag[review?.seq] ? (
                  <div className="answer flexRowBetween">
                    <Input.TextArea className="mobileInput" placeholder="답글을 남겨주세요." onChange={(e) => onChangeReply(review?.seq, e.target.value)} />
                    <button id="greyBtn" onClick={() => onSave(review)}>
                      저장
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
        </div>
      </aticle>
    </Drawer>
  );
};

export default ListReviewMobile;
