import React, { useRef, useState, useEffect } from 'react';
import { Form, Modal, Input, Select, message } from 'antd';
import Draggable from 'react-draggable';
import { msTimestampFormat, makeSelectBoxList } from '@utils/lib';
import * as api from '@api/index';

const INIT_STATE_WITHDRAW_REASON_INFO = {
  cnl_rsn_cd: 'CNL_RSN001',
  cnl_rsn: '',
};

const WithdrawReason = ({ isOpen, onClose, onSave }) => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const [cnlRsnCdList, setCnlRsnCdList] = useState([]);

  const handleOk = (e) => {
    onSave({
      ...form.getFieldsValue(),
      mem_stat: '99',
      cnl_dt: parseInt(msTimestampFormat(new Date())),
    });
    onClose();
  };

  const handleCancel = (e) => {
    onClose();
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  // 공통코드 탈퇴사유코드 조회
  const makeCnlRsnCdFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'CNL_RSN' });
      const madeSelectBoxList = makeSelectBoxList(response?.data, 'cd_nm', 'cd');
      setCnlRsnCdList(madeSelectBoxList);
    } catch (error) {
      message.warning(error.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      // 공통코드 조회
      makeCnlRsnCdFormat();

      form.resetFields();
    }
  }, [isOpen]);

  return (
    <Modal
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          탈퇴 사유
        </div>
      }
      centered={true}
      open={isOpen}
      closable={false}
      footer={null}
      maskClosable={false}
      modalRender={(modal) => (
        <Draggable disabled={disabled} bounds={bounds} nodeRef={draggleRef} onStart={(event, uiData) => onStart(event, uiData)}>
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <Form
        form={form}
        name="withdrawReason"
        layout="vertical"
        style={{ maxWidth: 500, margin: '30px auto 40px' }}
        initialValues={INIT_STATE_WITHDRAW_REASON_INFO}
        autoComplete="off"
        scrollToFirstError
      >
        <Form.Item name="cnl_rsn_cd" label="탈퇴 사유 코드" rules={[{ required: true, message: '탈퇴 사유 코드를 선택해주세요.' }]}>
          <Select options={cnlRsnCdList} />
        </Form.Item>
        <Form.Item name="cnl_rsn" label="탈퇴 사유 내용">
          <Input.TextArea rows={6} showCount maxLength={100} />
        </Form.Item>
      </Form>
      <div className="flexWrapCenter" style={{ gap: '10px' }}>
        <button className="btn-grad" onClick={handleOk}>
          저장
        </button>
        <button className="btn-white" onClick={handleCancel}>
          취소
        </button>
      </div>
    </Modal>
  );
};

export default WithdrawReason;
