import React from 'react';
import * as lib from '@utils/lib';
// lib
import { koKRFormat } from '@utils/lib';

const CardSquareImage2 = ({ data, moveToDetailPage }) => {
  const addDefaultImg = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    data.thum_path = '';
    e.currentTarget.src = lib.getDefaultImg('image', data);
  };
  return (
    <div className="contents flexColCenter" key={data?.prpt_id} onClick={moveToDetailPage}>
      <div className="absoluteBox flexColBetween">
        <div className="user">
          <img src={data?.icon_path} />
          <h1>{data?.ai_model_nm}</h1>
        </div>

        <div className="flexColEnd price">{data?.sale_amt === 0 ? '무료' : `₩ ${koKRFormat(data?.sale_amt)}`}</div>
      </div>
      <img
        src={lib.getDefaultImg('image', data)}
        // onError={addDefaultImg}
      />
    </div>
  );
};

export default CardSquareImage2;
