import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateSession } from '@utils/lib';
import { AUTH_LOGIN } from '@utils/constants';
import { message } from 'antd';
import * as PATH from '@routes/pathName';

const SessionChecker = () => {
  const location = useLocation();

  // 세션 검사가 필요한 경로 리스트
  const protectedRoutes = ["/myMarketplaces", "/prompt/create", "/inquiry", "/chat"];
    const navigate = useNavigate();
    useEffect(() => {
        const isProtected = protectedRoutes.some(item => (location.pathname).includes(item));
        if (isProtected) {
            if (!validateSession({ authType: AUTH_LOGIN })) {
                message.warning('로그인 후 이용가능합니다');
                navigate(PATH.LOGIN);
            }
        }

      }, [location.pathname, navigate]);

      return null;
    };

export default SessionChecker;
