import { createSlice } from '@reduxjs/toolkit';

export const INIT_STATE_MARKETPLACE_MUSICLIST = {
  query: {
    cate_cd: 'CATE003',
    keyword_tab: '',
    filter_sort: 'popular',
    filter_model: 'all',
    filter_genre: 'all',
    filter_sub_genre: 'all',
    filter_text: '',
    filter_stat: '',
    staff_pick: 'all',
    offset: 0,
    limit: 60,
  },
  items: [],
  totalCount: 0,
  currentCount: 0,
  locationKey: '',
  yScrollPosition: 0,
};

export const marketplaceMusicListSlice = createSlice({
  name: 'marketplaceMusicList',
  initialState: INIT_STATE_MARKETPLACE_MUSICLIST,
  reducers: {
    clearMarketplaceMusicList: () => INIT_STATE_MARKETPLACE_MUSICLIST,
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setMarketplaceMusicList: (state, action) => {
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.currentCount = action.payload.currentCount;
    },
    setLocationKey: (state, action) => {
      state.locationKey = action.payload;
    },
    setYScrollPosition: (state, action) => {
      state.yScrollPosition = action.payload;
    },
  },
});

export const { clearMarketplaceMusicList, setQuery, setMarketplaceMusicList, setLocationKey, setYScrollPosition } = marketplaceMusicListSlice.actions;

export default marketplaceMusicListSlice.reducer;
