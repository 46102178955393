import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loading from '@components/Loading';
import {DatePicker, Form, message, Select} from "antd";
import {Content} from "antd/es/layout/layout";
import Table from "@components/Table";
import {makeDataFormat, dayFormatYearMonth, useDebounce} from "@utils/lib";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import RefundModal from "@components/RefundModal";
import {setQuery} from "@stores/paymentList";
import {setUserPayList, setYScrollPosition} from "@stores/userPayList";
import * as api from "@api/index";

const ListPayment = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const paymentHistoryGridRef = useRef(null);
    const [selectedList, setSelectedList] = useState([]);
    const [selectedRefundData, setSelectedRefundData] = useState(null);
    const selectedGdsCd = useRef('');
    const selectedInout = useRef('');
    const handleDebounce = useCallback(
        useDebounce((func) => func(), 300),
        [],
    );
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedRefund, setSelectedRefund] = useState(null);

    const today = dayjs();
    const oneWeekAgo = today.subtract(7, 'day');

    const [sendDate, setSendDate] = useState({
        st_date: oneWeekAgo.format('YYYY-MM-DD'),
        end_date: today.format('YYYY-MM-DD'),
    });

    const [userPaymentHistoryList, setUserPaymentHistoryList] = useState({
        query: {
            gds_div: '',
            inout_tp: '',
            mem_key: auth?.mem_key,
            offset: 0,
            limit: 20,
            startDt: sendDate.st_date,
            endDt: sendDate.end_date,
        },
        items: [],
        totalCount: 0,
        currentCount: 0,
        yScrollPosition: 0,
    });

    useEffect(() => {
        if (!window.$agRendererTemplate) {
            window.$agRendererTemplate = {};
        }
        window.$agRendererTemplate.handleShowModal = handleShowModal;
    }, [])
    const handleShowModal = (data) => {
        const decodedData = JSON.parse(decodeURIComponent(data));
        setSelectedRefundData(decodedData);
        setModalVisible(true);
    }
    const handleDate = ({ startDt, endDt }) => {
        if(startDt >= endDt) {
            return message.error("시작일이 종료일보다 클 수 없습니다.");
        }
        setUserPaymentHistoryList((prev) => ({
            ...prev,
            query: {
                ...prev.query,
                startDt: startDt,
                endDt: endDt,
                offset: 0,
            },
        }));

        setSendDate({
            st_date: startDt,
            end_date: endDt,
        });
    };

    const paymentHistoryGridProps = {
        editable: false,
        showRowNumColumn: false,
        showStateColumn: false,
        enableSorting: true,
    };

    const paymentHistoryGridLayout = [
        { dataField: 'gds_div_txt', headerText: '<span class="gridLeft">구분</span>', width: '15%' },
        { dataField: 'inout_tp', headerText: '판매 / 구매', width: '18%',
            labelFunction: (rowIndex, columnIndex, value) => {
                return value === 'IN' ? "판매" : "구매";
            },
        },
        { dataField: 'gds_nm', headerText: '상품명' },
        { dataField: 'pay_meth', headerText: '결제 수단', width: '10%', visible: false,
            labelFunction: (rowIndex, columnIndex, value) => {
                if(value === '10'){
                    return "Card";
                } else if(value === '40') {
                    return "Coupon";
                } else {
                    return "없음";
                }
            },
        },
        { dataField: 'pay_amt', headerText: '금액', width: '20%', dataType: 'numeric',
            editRenderer: {
                autoThousandSeparator: true, // 천단위 구분자 삽입 여부
            },
        },
        { dataField: 'fir_dt', headerText: '<span class="gridRight">일시</span>', width: '20%',
            dataType: 'date',
            dateInputFormat: 'yyyy-mm-dd',
            formatString: 'yyyy.mm.dd',
            labelFunction: (rowIndex, columnIndex, value) => {
                return dayFormatYearMonth(value);
            },
        },
        {
            dataField: 'refund_seq',
            headerText: '환불',
            width: '10%',
            headerStyle: 'required-custom-header',
            renderer: { type: 'TemplateRenderer' },
            visible: false,
            labelFunction: (rowIndex) => {
                let rowData = paymentHistoryGridRef.current.getGridData()[rowIndex];
                if (rowData.refund_req_dt !== null) {
                    const encodedRowData = encodeURIComponent(JSON.stringify(rowData));
                    return (
                        `<div onclick="window.$agRendererTemplate.handleShowModal('${encodedRowData}')">환불 확인</div>`
                    );
                } else {
                    return '';
                }
            },
        },
        {dataField: "refund_stat_txt", headerText: '환불 상태', width: '10%', visible: false},
        {dataField: "refund_rsn_txt", headerText: '환불 사유', width: '10%', visible: false},
        {dataField: "refund_req_dt", headerText: '환불 요청일', width: '10%', visible: false},
        {dataField: "refund_pay_dt", headerText: '환불일', width: '10%', visible: false},
        {dataField: "refund_amt", headerText: '환불 금액', width: '10%', visible: false},
    ];
    // 결제내역 목록 가져오기
    const listUserPay = useCallback(async (query) => {
        try {
            setIsLoading(true);
            const { data } = await api.listPay({ ...query, inout_div: '01' });
            if (!data) return;
            // 두번째 이상 요청일때
            if (query.offset && paymentHistoryGridRef.current.getGridData()) {
                dispatch(
                    setUserPayList({
                        items: [...paymentHistoryGridRef.current.getGridData(), ...data.items],
                        totalCount: data.total,
                        currentCount: paymentHistoryGridRef.current.getGridData().length + data.items.length,
                    }),
                );
                paymentHistoryGridRef.current.appendData(data.items);
            }
            // 첫번째 요청일때
            else {
                dispatch(
                    setUserPayList({
                        items: data.items,
                        totalCount: data.total,
                        currentCount: data.items.length,
                    }),
                );
                paymentHistoryGridRef.current.setGridData(data.items);
            }
            // 그리드 이벤트 재설정
            setupGridEvents({
                offset: query.offset,
                limit: query.limit,
                totalCount: data.total,
                currentCount: paymentHistoryGridRef.current.getGridData().length,
            });
        } catch (error) {
            message.warning(error.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    // 스크롤 페이징
    const handleScroll = ({ offset, limit }) => {
        const newQuery = { ...userPaymentHistoryList.query, gds_div: selectedGdsCd.current, inout_tp: selectedInout.current, offset: offset + limit };
        dispatch(setQuery(newQuery));
        handleDebounce(() => listUserPay(newQuery));
    };

    // 그리드 이벤트 설정
    const setupGridEvents = ({ offset, limit, totalCount, currentCount }) => {
        // 그리드 이벤트 바인딩
        paymentHistoryGridRef.current.bind(['vScrollChange'], (event) => {
            // 스크롤 변경시
            if (event.type === 'vScrollChange') {
                handleDebounce(() => dispatch(setYScrollPosition(event.position)), 300);
                // 스크롤 위치가 마지막과 일치하고, 현재 갯수가 총 갯수 보다 작을때 요청
                if (event.position === event.maxPosition && currentCount < totalCount) {
                    handleScroll({ offset, limit });
                }
            }
        });
    };

    // 검색
    const handleSearchPay = () => {
        const newQuery = { ...userPaymentHistoryList.query, gds_div: selectedGdsCd.current, inout_tp: selectedInout.current, offset: 0 };
        dispatch(setQuery(newQuery));
        handleDebounce(() => listUserPay(newQuery));
    };

    const showModal = (refund) => {
        setSelectedRefund(refund);
        setModalVisible(true);
    };

    const gdsCode = async () => {
        try {
            const response = await api.fetchCommonDetailCode({ grp_cd: 'GDS_DIV' });
            const selectList = makeDataFormat(response?.data, 'cd_nm', 'cd');
            const optionsList = Object.entries(selectList).map(([value, label]) => ({
                label,
                value
            }));

            setSelectedList(optionsList);
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        gdsCode();
        listUserPay(userPaymentHistoryList.query);
    },[])

    return (
        <>
            <Loading isLoading={isLoading} />
            <Content className="payDetail">
                <div className="searchArea">
                    <Select
                        options={selectedList}
                        style={{ width: 100 }}
                        placeholder="구분"
                        onChange={(value) => selectedGdsCd.current = value === null || value === undefined ? '' : value}
                        allowClear={true}
                    />
                    <div className="dateBox">
                        <DatePicker
                            style={{ height: '40px' }}
                            placeholder="시작일"
                            value={sendDate?.st_date ? dayjs(sendDate.st_date) : oneWeekAgo}
                            onChange={(date, dateString) => {
                                handleDate({
                                    startDt: dateString,
                                    endDt: sendDate?.end_date || '',
                                });
                            }}
                            format="YYYY-MM-DD"
                            allowClear={false}
                        />
                        <span style={{padding: '0 5px'}}>~</span>
                        <DatePicker
                            style={{ height: '40px' }}
                            placeholder="종료일"
                            value={sendDate?.end_date ? dayjs(sendDate.end_date) : today}
                            onChange={(date, dateString) => {
                                handleDate({
                                    startDt: sendDate?.st_date || '',
                                    endDt: dateString,
                                });
                            }}
                            format="YYYY-MM-DD"
                            allowClear={false}
                        />
                    </div>
                    <button
                        className="btn-searchIcon"
                        style={{width:'40px', height: '40px', borderRadius: '4px'}}
                        type="button"
                        onClick={handleSearchPay}
                    >
                    </button>
                </div>
                <Table
                    style={{ padding: '25px' }}
                    ref={paymentHistoryGridRef}
                    columnLayout={paymentHistoryGridLayout}
                    customGridProps={paymentHistoryGridProps}
                />
            </Content>
            <RefundModal visible={modalVisible} onClose={() => setModalVisible(false)} refundData={selectedRefundData} />
        </>
    );
};
export default ListPayment;