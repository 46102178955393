import React, { useCallback, useEffect, useState, useRef } from 'react';
import * as api from '@api/index';
import * as PATH from '@routes/pathName';
import { useLocation, useNavigate } from 'react-router-dom';
import {message, Radio, Rate, Tooltip} from 'antd';
import '@assets/css/profile.css';
import { IMAGES } from '@utils/image';

import useWidth from '@hooks/useWidth';
import { profileHomeType, badge_map, AUTH_LOGIN, POPUP_VIDEO_PLAYER, POPUP_MUSIC_PLAYER } from '@utils/constants';
import { getLocalStorage, formatBadgeIcon, handleLinkClick, formatDate, handleImageError, koKRFormat, validateSession } from '@utils/lib';

import FollowModal from '@components/FollowModal';
import ComplaintModal from '@components/ComplaintModal';
import { BOARD_HOME } from '@routes/pathName';
import SliderCardPrompt from '@pages/marketplaces/SliderCardPrompt';
import ListCardPrompt from '@pages/marketplaces/ListCardPrompt';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {setBoardCheck} from "@stores/board";

const ProfileHome = ({ overrideData = {} }) => {
  const width = useWidth();
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const location = useLocation();
  const [userProfile, setUserProfile] = useState({});
  const sendRanking = useRef();
  const [selectedAis, setSelectedAis] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isComplaintModal, setIsComplaintModal] = useState(false);
  const auth = useSelector((s) => s.auth, shallowEqual);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const getProfile = async () => {
    try {
      setVisible(false);
      const params = { mem_key: overrideData?.mem_key ?? memKey };

      if (!params.mem_key) {
        message.warning('로그인 후 이용가능합니다');
        navigate(PATH.LOGIN);
        return false;
      }

      const response = await api.userProfile({ params });

      if (response?.data) {
        if (response.data.mem_stat != '02') {
          alert('작성자가 이미 회원이 아닙니다.\n프로필을 조회할 수 없습니다.');
          navigate(-1);
        }
        setUserProfile(response.data);
        setVisible(true);
        sendRanking.current = response.data;
        let followerList = null;
        if (memKey != null && response.data.follower_list) {
          followerList = JSON.parse(response.data.follower_list);
        }

        setIsFollowing(followerList ? checkFollowing(followerList) : false);

        if (response.data?.ai_fl_cd) {
          const ai_nm = response.data.ai_fl_nm.split('|');
          setSelectedAis(ai_nm);
        }

        await recentPrompt();
        await popularPrompt();
      } else {
        console.error('No data response');
        setUserProfile(null);
        setVisible(false);
        setIsFollowing(false);
      }
    } catch (error) {
      console.error('Error getProfile:', error);
      setUserProfile(null);
      setVisible(false);
      setIsFollowing(false);
    }
  };

  const [followModalVisible, setFollowModalVisible] = useState(false);
  const [modalType, setModalType] = useState('follower');
  const [followData, setFollowData] = useState([]);
  const selectedType = useRef(`${overrideData?.cate_cd || profileHomeType[0]?.value}`);
  const [filterText, setFilterText] = useState(''); // 입력값 관리용 state
  const followerRef = useRef();
  const followingRef = useRef();

  const handleFollowerClick = () => {
    const followerList = JSON.parse(userProfile?.follower_list || '[]');
    if (followerList.length === 0) {
      message.warning('팔로워 한 사람이 없습니다.');
      return;
    }
    setModalType('follower');
    setFollowData(followerList);
    setFollowModalVisible(true);
  };

  const handleFollowingClick = () => {
    const followingList = JSON.parse(userProfile?.following_list || '[]');
    if (followingList.length === 0) {
      message.warning('팔로잉 한 사람이 없습니다.');
      return;
    }
    setModalType('following');
    setFollowData(followingList);
    setFollowModalVisible(true);
  };

  const handleChange = (event) => {
    selectedType.current = event.target.value;
    popularPrompt();
    recentPrompt();
  };

  const [popularList, setPopularList] = useState([]);
  const [recentList, setRecentList] = useState([]);

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setFilterText(e.target.value);
      popularPrompt();
      recentPrompt();
    }
  };

  const popularPrompt = useCallback(async () => {
    try {
      const { data } = await api.getProfilePromptList({
        cate_cd: selectedType.current,
        mem_key: overrideData?.mem_key || memKey,
        filter_sort: 'popular',
        offset: 0,
        limit: 12,
        filter_text: filterText || '',
      });
      if (!data) return;
      setPopularList(data.items);
    } catch (error) {
      message.warning(error.message);
    }
  }, [selectedType, filterText]);

  const handleInputChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleSearchClick = () => {
    recentPrompt();
    popularPrompt();
  };

  const recentPrompt = useCallback(async () => {
    try {
      const { data } = await api.getProfilePromptList({
        cate_cd: selectedType.current,
        mem_key: overrideData?.mem_key || memKey,
        filter_sort: 'new',
        offset: 0,
        limit: 12,
        filter_text: filterText || '',
      });
      if (!data) return;

      setRecentList(data.items);
    } catch (error) {
      message.warning(error.message);
    }
  }, [selectedType, filterText]);

  const [isVisible, setIsVisible] = useState(false);
  const wrapperRef = useRef(null);

  const toggleVisibility = () => {
    setIsVisible((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !event.target.closest('img')) {
        setIsVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isFollowing, setIsFollowing] = useState(false);

  const checkFollowing = (data) => {
    return data.some((item) => item.mem_key === parseInt(memKey, 10));
  };

  const requestFollow = useCallback(async () => {
    try {
      const { data } = await api.addFollower({ mem_key: overrideData?.mem_key || mem_key, flw_mem_key: memKey });

      if (data === 'success') {
        const messageText = isFollowing ? '팔로우가 취소 되었습니다.' : '팔로우를 시작하였습니다.';
        message.success(messageText);
      }
      await getProfile({ mem_key: overrideData?.mem_key || mem_key });
    } catch (error) {
      message.warning(error.message);
    }
  }, [memKey, isFollowing]);

  const handleProfileClick = () => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_key: memKey,
        profileDetail: 'true',
      },
    });
  };

  // 선택한 메뉴로 이동
  const moveToPage = (num) => {
    navigate(`${PATH.PROFILE_PAGE}`, {
      state: {
        mem_email: sendRanking.current.mem_email,
        mem_key: sendRanking.current.mem_key,
        cateCd: (sendRanking.current.agg_cate || '').split(',')[num],
        ranking: 'true',
      },
    });
  };

  const movePromptGuide = () => {
    dispatch(setBoardCheck({
      board_no: 0,
      board_title: '프롬프트 가이드',
      dsp_meth: 2,
      attach_yn: "Y",
      reply_yn: "N",
      write_lv: "PLAN999",
    }));
    navigate(`${BOARD_HOME}`);
  };

  // 랭크 아이콘 white/black 치환
  const getRankItems = () => {
    if (!userProfile?.agg_rank) return { sortedItems: [], highestRankItem: null };

    const ranks = userProfile.agg_rank.split(',');
    const items = [
      { rank: ranks[0], icon: IMAGES.IMAGES_ICON, page: 0, label: 'image' },
      /*{ rank: ranks[1], icon: IMAGES.VIDEO_ICON, page: 1, label: 'video' },*/
      { rank: ranks[2], icon: IMAGES.MUSIC_ICON, page: 2, label: 'note' },
      { rank: ranks[3], icon: IMAGES.BOOK_ICON, page: 3, label: 'book' },
    ];

    const sortedItems = items.sort((a, b) => Number(a.rank) - Number(b.rank));
    let highestRankItem = null;
    if (sortedItems.length > 0) {
      const iconMapping = {
        [IMAGES.IMAGES_ICON]: IMAGES.WHITE_IMAGE_ICON,
        [IMAGES.MUSIC_ICON]: IMAGES.WHITE_MUSIC_ICON,
        [IMAGES.BOOK_ICON]: IMAGES.WHITE_TEXT_ICON,
      };

      highestRankItem = {
        ...sortedItems[0],
        icon: iconMapping[sortedItems[0].icon] || sortedItems[0].icon
      };
    }

    return { sortedItems, highestRankItem };
  };

  const { sortedItems, highestRankItem } = getRankItems();

  useEffect(() => {
    getProfile();
    getRankItems();
    listBadge();
  }, []);

  // 비디오/음악 플레이어 열기
  const onOpenPlayer = async (prompt) => {
    try {
      if (!prompt) return;
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        if (prompt.cate_cd === 'CATE002') window.open(`${PATH.VIDEO_PLAYER}?prpt_id=${encodeURI(btoa(prompt?.prpt_id))}`, POPUP_VIDEO_PLAYER, 'width=700,height=400');
        if (prompt.cate_cd === 'CATE003') window.open(PATH.MUSIC_PLAYER, POPUP_MUSIC_PLAYER, 'width=375,height=700');
      }
    } catch (error) {
      message.warning(error.message);
    }
  };
  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (prompt) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: prompt?.prpt_id, view_type: 'V' });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate(PATH.PROMPT_DETAIL, { state: { prpt_id: prompt?.prpt_id } });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 유저 프로필 상단으로
  const moveToUserPage = async () => {
    try {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (error) {
      message.warning(error.message);
    }
  };
  const [badgeList, setBadgeList] = useState([]);

  const getMemberBadge = useCallback(
      (bdgCd) => {
        if (!bdgCd || !badgeList.length) return [];
        return badgeList.filter((badge) => bdgCd.includes(badge.bdg_cd));
      },
      [badgeList],
  );

  const listBadge = async () => {
    try {
      const { data } = await api.getBadgeInfo();
      setBadgeList(data);
    } catch (error) {
      console.error('마켓플레이스 뱃지 리스트 조회 error', error);
    }
  };

  return (
    <>
      <article className="flexColCenter" id="myHome">
        {visible ? (
          <div className="top flexColCenter">
            <img src={userProfile?.ch_img_path || IMAGES.DEFAULT_BANNER} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_BANNER)} />
            <div className="rsWrapper flexRowStart">
              {width < 700 && (
                <div className="flexColStart title">
                  <h1>{userProfile?.about_me}</h1>
                  <h2>{userProfile?.about_chanel}</h2>
                </div>
              )}

              <div className="profile flexColcenter">
                <div className="flexRowBetween topProfile">
                  <div className="thumb flexColCenter">
                    <img src={userProfile?.mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />
                  </div>

                  {width < 700 && <h1>{`${userProfile?.mem_nick?.trim() ? userProfile?.mem_nick : userProfile?.mem_email?.split('@')[0]}` || ''}</h1>}
                  <div className="flexColStart">
                    <div className="flexRowBetween gradeBox">
                      <div className="flexRowCenter">
                        <div className={`badge_s ${badge_map[userProfile?.plan_cd] || ''}`}>{userProfile?.plan_nm}</div>
                        {/* {formatBadgeIcon(userProfile?.bdg_url)} */}
                      </div>
                      {width < 700 ? (
                        <h2>{userProfile?.fir_dt ? formatDate(userProfile?.fir_dt) : ''}</h2>
                      ) : (
                        <div className="snsArea">
                          {/*
                        {userProfile?.web && <img src={IMAGES.EARTH_ICON} onClick={() => handleLinkClick(userProfile?.web, 'Web')} style={{ cursor: 'pointer' }} />}
*/}
                          {userProfile?.x_link && <img src={IMAGES.X_ICON} onClick={() => handleLinkClick(userProfile?.x_link, 'X')} style={{ cursor: 'pointer' }} />}
                          {/*
                        {userProfile?.youtube_link && <img src={IMAGES.YOUTUBE_ICON} onClick={() => handleLinkClick(userProfile?.youtube_link, 'Youtube')} style={{ cursor: 'pointer' }} />}
*/}
                          {userProfile?.insta_link && <img src={IMAGES.INSTA_ICON} onClick={() => handleLinkClick(userProfile?.insta_link, 'Insta')} style={{ cursor: 'pointer' }} />}
                        </div>
                      )}
                    </div>

                    {width < 700 ? (
                      <div className="flexColCenter mobile">
                        {/*<div className="flexRowCenter">
                        <img src={IMAGES.STAR_ICON} />
                        <p>{userProfile?.score_avg}</p>
                        <h3>({userProfile?.review_cnt})</h3>
                      </div>*/}

                        <div className="flexRowCenter">
                          {userProfile?.x_link && <img src={IMAGES.X_ICON} onClick={() => handleLinkClick(userProfile?.x_link, 'X')} style={{ cursor: 'pointer' }} />}
                          {userProfile?.insta_link && <img src={IMAGES.INSTA_ICON} onClick={() => handleLinkClick(userProfile?.insta_link, 'Insta')} style={{ cursor: 'pointer' }} />}
                        </div>
                      </div>
                    ) : (
                      <>
                        <h1>{`${userProfile?.mem_nick?.trim() ? userProfile.mem_nick : userProfile?.mem_email?.split('@')[0]}`}</h1>
                        <h2>{userProfile?.fir_dt ? formatDate(userProfile?.fir_dt) : 'N/A'}</h2>
                      </>
                    )}
                  </div>
                </div>

                {/* //  팔로잉 팔로우 */}
                <ul className="followBox" style={{ position: 'relative' }}>
                  <li className="" onClick={handleFollowingClick} style={{ cursor: 'pointer' }}>
                    <p ref={followingRef}>팔로잉</p>
                    <span>{userProfile?.following_cnt}</span>
                  </li>
                  <li className="" onClick={handleFollowerClick} style={{ cursor: 'pointer' }}>
                    <p ref={followerRef}>팔로워</p>
                    <span>{userProfile?.follower_cnt}</span>
                  </li>
                  {/* 팔로잉 팔로우 목록 모달 */}
                  <FollowModal
                    visible={followModalVisible}
                    onClose={() => setFollowModalVisible(false)}
                    type={modalType}
                    data={followData}
                    anchorRef={modalType === 'follower' ? followerRef : followingRef}
                  />
                  {/* 모바일때 드러나는 순위 */}
                  {width < 700 && (
                    <li className="flexColCenter mobile" onClick={toggleVisibility}>
                      <p className="flexRowCenter">
                        순위
                        <img src={highestRankItem?.icon} alt={`${highestRankItem?.label} icon`} />
                        <img src={IMAGES.DOWN_WHITE_ICON} alt="down arrow" style={{ cursor: 'pointer' }} />
                      </p>
                      <span>#{highestRankItem?.rank}</span>
                      {isVisible && (
                        <div className="absoluteBox flexColCenter" ref={wrapperRef}>
                          {sortedItems.map((item, index) => (
                            <div key={index} className="flexRowStart" style={{ cursor: 'pointer' }} onClick={() => moveToPage(item.page)}>
                              <img src={item.icon} alt={`${item.label} icon`} />
                              <p>#{item.rank || 'N/A'}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </li>
                  )}
                </ul>
                <div className="btnArea" style={{ display: overrideData?.mem_key ? 'block' : 'none' }}>
                  {memKey !== overrideData?.mem_key && auth.mem_key !== '' && (
                    <div className="followBtn">
                      {/* <button className="btnPink2 donation">후원하기</button> */}
                      {/* <button className="btnPink2 request">사용의뢰</button> */}
                      <button className={isFollowing ? 'following' : ''} onClick={requestFollow}>
                        {isFollowing ? <> 팔로잉 </> : <> 팔로우 </>}
                      </button>
                    </div>
                  )}
                </div>
                <div className="bottom">
                {width > 700 && (
                    <div className="flexRowCenter" style={{ cursor: 'pointer' }} onClick={toggleVisibility}>
                      <img src={highestRankItem?.icon} alt={`${highestRankItem?.label} icon`} />
                      <p style={{ cursor: 'pointer' }}>#{highestRankItem?.rank}</p>
                      <img src={IMAGES.DOWN_WHITE_ICON} alt="down arrow" style={{ cursor: 'pointer' }} />
                      {isVisible && (
                        <div className="absoluteBox flexColCenter" ref={wrapperRef}>
                          {sortedItems.map((item, index) => (
                            <div key={index} className="flexRowStart" style={{ cursor: 'pointer' }} onClick={() => moveToPage(item.page)}>
                              <img src={item.icon} alt={`${item.label} icon`} />
                              <p>#{item.rank || 'N/A'}</p>
                            </div>
                          ))}
                        </div>
                  )}
                </div>
                
// <div className="flexRowCenter">
                
// <div>{userProfile?.score_avg}</div>
// <Rate value={userProfile?.score_avg} disabled={true} />
// <h2>({userProfile?.review_cnt})</h2>
                
// </div>
                )}
                {/* 분야별 배지*/}
                {/* badgeMusicBronze =>asset/iamge/common/bad_musicBronze.svg */}
                <div className="major">
                  {getMemberBadge(userProfile?.bdg_cd).map((badge, index) => (
                      <Tooltip title={badge?.bdg_nm}>
                        <img key={index} src={badge?.icon_path} alt="icon" />
                      </Tooltip>
                  ))}
                </div>
              </div>
                {/* ai 전문분야*/}
                <div className="majorModel">
                  <span className="title">전문 AI분야</span>
                  {selectedAis.length ? (
                    selectedAis.map((ai) => <span className="">{ai}</span>)
                  ) : memKey === userProfile?.mem_key ? (
                    <span onClick={handleProfileClick} style={{ cursor: 'pointer' }}>
                      전문 AI 분야를 설정하세요
                    </span>
                  ) : (
                    <span>없음</span>
                  )}
                </div>
              </div>

              {width > 700 && (
                <div className="flexColStart title">
                  <h1>{userProfile?.about_me}</h1>
                  <h2>{userProfile?.about_chanel}</h2>
                </div>
              )}
            </div>
          </div>
        ) : null}
        <div className="flexColCenter filter">
          <div className="rsWrapper flexRowStart">
            <div className="listBox flexRowStart">
              <div className="flexRowStart title">
                <img src={IMAGES.MOBILE_FILTER_ICON} />
                <h2>타입</h2>
              </div>
              <Radio.Group value={selectedType.current} onChange={handleChange}>
                {profileHomeType.map((type) => (
                  <Radio
                    key={type.id}
                    style={{
                      width: width < 700 ? `auto` : `140px`,
                      fontSize: `14px`,
                    }}
                    value={type.value}
                  >
                    {type.label}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
        </div>

        <div className="flexColCenter searchBox">
          <div className="rsWrapper flexRowCenter">
            {width < 700 && (
              <div className="flexRowCenter search">
                <img src={IMAGES.SEARCH_ICON} onClick={handleSearchClick} />
                <input
                  placeholder={`${userProfile?.mem_nick?.trim() ? userProfile.mem_nick : userProfile?.mem_email?.split('@')[0]}님의 컨텐츠를 검색해 보세요.`}
                  value={filterText}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyPress(e)}
                />
              </div>
            )}
            {width > 700 && (
            <div className="text flexColCenter" onClick={() => navigate('/prompt/create')} style={{ display: location.state?.mem_key === memKey ? '' : 'none', cursor: 'pointer' }}>
              <img src={IMAGES.PROMPT_ICON} />
              <p>프롬프트 빌더</p>
            </div>
            )}
            {width > 700 && (
              <div className="flexRowCenter search">
                <img src={IMAGES.SEARCH_ICON} onClick={handleSearchClick} />
                <input
                  placeholder={`${userProfile?.mem_nick?.trim() ? userProfile.mem_nick : userProfile?.mem_email?.split('@')[0]}님의 컨텐츠를 검색해 보세요.`}
                  value={filterText}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyPress(e)}
                />
              </div>
            )}

            <div className="text flexColCenter" style={{ display: location.state?.mem_key === memKey ? '' : 'none', cursor: 'pointer' }} onClick={movePromptGuide}>
              <img src={IMAGES.GUIDE_ICON} />
              <p>프롬프트 가이드</p>
            </div>
          </div>
        </div>
        <div className="promptThumList forceWidth">
          <SliderCardPrompt
            title="인기 컨텐츠"
            titleImg={IMAGES.PARTY_ICON}
            promptList={popularList}
            openPlayer={onOpenPlayer}
            moveToPromptDetailPage={moveToDetailPage}
            moveToUserDetailPage={moveToUserPage}
          />
          <ListCardPrompt
            title="최신 컨텐츠"
            titleImg={IMAGES.NEW_ICON}
            promptList={recentList}
            openPlayer={onOpenPlayer}
            moveToPromptDetailPage={moveToDetailPage}
            moveToUserDetailPage={moveToUserPage}
          />
        </div>

        <ComplaintModal isOpen={isComplaintModal} onClose={() => setIsComplaintModal(false)} />
      </article>
    </>
  );
};

export default ProfileHome;
