import { restApi, querystring } from './config';

// 쿠폰 조회
export const getCouponList = (data) => {
  return restApi.get('/api/coupon/couponSearch', querystring(data));
};
// 쿠폰 저장
export const couponSave = (data) => {
  return restApi.post('/api/coupon/couponSave', data);
};
// 쿠폰상세조회
export const getCpnDtlSearch = (data) => {
  return restApi.get('/api/coupon/cpnDtlSearch', querystring(data));
};
//사용자별 쿠폰 목록 조회
export const getUserCoupons = (data) => {
  return restApi.post('/api/coupon/getUserCoupons', data);
}
//쿠폰 등록
export const registerCoupon = (data) => {
  return restApi.post('/api/coupon/registerCoupon', data);
}
//쿠폰 전체 등록
export const registerAllCoupon = (data) => {
  return restApi.post('/api/coupon/registerAllCoupon', data);
}
//오프라인 쿠폰 등록
export const registerOfflineCoupon = (data) => {
  return restApi.post('/api/coupon/registerOfflineCoupon', data);
}
//쿠폰 사용 처리
export const usedCompleteCoupon = (data) => {
  return restApi.post('/api/coupon/usedCompleteCoupon', data);
}