import { Button, Col, Form, Input, Layout, Row, Typography, Select, Upload, Checkbox, Tabs, Modal, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Table from '@components/Table';
import { Content } from 'antd/es/layout/layout';
import * as api from '@api/index';
import {useLocation} from 'react-router-dom';
import GridHeader from '@components/GridHeader';
import { UploadOutlined } from '@ant-design/icons';
import * as constants from '@utils/constants';
import MyTextareaEditor from '@static/AUIGrid/renderers/MyTextareaEditor';

import "../../../assets/css/style.css";
import DefaultForm from './DefaultForm';
import AdditionalForm from './AdditionalForm';

const AdminElementsManagementHome = (props) => {

  //url로 요소 구분
  const location = useLocation();
  const division = location.state.division || {};
  const divisionCode = division === "builder" ? "04" : 
                       division === "category" ? "05" : 
                       division === "group" ? "02" : 
                       division === "library" ? "03" : "01";

  const divisionName = division === "builder" ? "빌더" : 
                       division === "category" ? "카테고리" : 
                       division === "group" ? "그룹" : 
                       division === "library" ? "라이브러리" : "";

  const divisionSub = division === "builder" ? "그룹이" : 
                      division === "category" ? "빌더가" : 
                      division === "group" ? "요소가" : 
                      division === "library" ? "그룹이" : "";

  //요소 ID
  const [ elId, setElId] = useState(0);

  //기존 요소 검색 여부
  const searchExistingElements = useRef("N");

  //JSX HTML element
  const { Title } = Typography;
  const { TextArea } = Input;
  const { Option } = Select;

  //우측 새 요소 등록 폼
  const [form] = Form.useForm();
  const [builderForm] = Form.useForm();
  const [searchForm] = Form.useForm();

  //그리드
  const newTableRef = useRef();
  const currentTableRef = useRef();
  const promptTableRef = useRef();
  const optionTableRef = useRef();
  const stepTableRef = useRef();
  const stepZeroTableRef = useRef();
  const libraryTableRef = useRef();

  //최하위 요소 구분
  const [lowest, setLowest] = useState(false);
  //카테고리 목록
  const [categoryList, setCategoryList] = useState([]);
  //AI모델 목록
  const [aiModelList, setAiModelList] = useState([]);
  //구독플랜 목록
  const [planList, setPlanList] = useState([]);
  //가사빌더 목록
  const [lyricsList, setLyricsList] = useState([]);
  //라이브러리 목록
  const [libraryList, setLibraryList] = useState([]);

  //Step 그리드 그룹 목록
  const stepGridList = useRef([]);
  const stepSelectedList = useRef([]);
  const stepUnselectedList = useRef([]);
  //STEP그리드 파일 목록
  let stepGridFiles = useRef([]);
  //STEP그리드 구독 플랜 목록
  const stepGridPlanList = useRef([]);

  // 기본정보 이미지, 수정 여부
  const [ imgFiles, setImgFiles ] = useState([]);
  const [ imgFileUpdated, setImgFileUpdated ] = useState(false);
  const [ mp3Files, setMp3Files ] = useState([]);
  const [ mp3FileUpdated, setMp3FileUpdated ] = useState(false);
  const [ mp4Files, setMp4Files ] = useState([]);
  const [ mp4FileUpdated, setMp4FileUpdated ] = useState(false);
  const [ stepZeroFiles, setStepZeroFiles ] = useState([]);
  const [ stepZeroFileUpdated, setStepZeroFileUpdated ] = useState(false);

  //이미지, mp3, mp4 미리보기
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewType, setPreviewType] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  //빌더 Tab active Key
  const [activeKey, setActiveKey] = useState('1');

  const [lyricsChecked, setLyricsChecked] = useState(false);

  // 기본정보 국문,영문 변경 시 새 요소관리 최상위 row의 데이터 변경
  const changeElementKoName =(e) => {
    newTableRef.current.updateRow({ko_nm : e.target.value}, 0);
  }
  const changeElementEnName =(e) => {
    newTableRef.current.updateRow({en_nm : e.target.value}, 0);
  }

  // upload validation
  const beforeUpload = (file, flag) => {
    const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();

    if(flag === "image" && !extensionsValidation(constants.allowedExtensionsImage, fileExtension)){
      return Upload.LIST_IGNORE;
    }else if(flag === "mp3" && !extensionsValidation(constants.allowedExtensionsMp3, fileExtension)){
      return Upload.LIST_IGNORE;
    }else if(flag === "mp4" && !extensionsValidation(constants.allowedExtensionsMp4, fileExtension)){
      return Upload.LIST_IGNORE;
    }
    return false;
  }

  //확장자 validation
  const extensionsValidation = (checkExtensions, fileExtension) => {
    if (!checkExtensions.includes(fileExtension)) {
      message.error(checkExtensions+"만 업로드 가능합니다.");
      return false;
    }
    return true;
  }

  //프롬프트 개수 변경
  const changedPromptCount = (e) => {
    promptTableRef.current.clearGridData();
    for(let i = 0 ; i < e ; i++){
      promptTableRef.current.addRow({})
    }
  }

  //검색버튼 클릭
  const handleSearch = async (values) => {
    // if(!values.searchText?.length || values.searchText?.length < 2){
    //   alert("검색어는 2자리 이상 입력해주세요.");
    //   return false;
    // }
    //카테고리는 빌더만 조회. 그 외에는 그룹 이하만 조회
    const params = {
      "searchText" : values.searchText,
      "searchType" : values.searchType,
      "el_div" : divisionCode === "05" ? "04" : "01"
    };
    currentTableRef.current.showAjaxLoader();
    let response, data;
    if(lowest || divisionCode === "05"){
      response = await api.fetchExistingElements(params);
      data = response.data;
    }else{
      response = await api.fetchElementsList(params);
      data = JSON.parse(response.data.treeData);
    }
    
    currentTableRef.current.removeAjaxLoader();
    if(data){
      currentTableRef.current.setGridData(data);
    }else{
      currentTableRef.current.clearGridData();
    }
  }

  //저장버튼 클릭
  const handleSave = async () => {
    const elementInfo = form.getFieldsValue()
    const gridData = newTableRef.current.getGridData();

    if(gridData.length === 1){
      message.error("추가된 " + divisionSub + " 없습니다");
      return false;
    }
    try{
      await form.validateFields();
      if(divisionCode === "04"){
        await builderForm.validateFields();                                                                                                        
      }
    }catch(errorInfo){
      message.error("필수 입력 항목을 입력해주세요.");
      return false;
    }

    if(divisionCode === "04"){
      if(!promptTableRef.current.validateGridData(['ko_nm', 'en_nm'],'필수 필드는 반드시 값을 직접 입력해야 합니다.',)){
        message.error("프롬프트 그리드의 필수 필드를 모두 입력해주세요.");
        return false;
      }
      if(!optionTableRef.current.validateGridData(['prpt_seq'],'필수 필드는 반드시 값을 직접 입력해야 합니다.' )){
        message.error('구성요소 그리드의 국문명, 영문영, 프롬프트 위치를 모두 입력해주세요.');
        return false;
      }
      if(!stepTableRef.current.validateGridData(['step_seq', 'step_nm', 'use_lv'],'필수 필드는 반드시 값을 직접 입력해야 합니다.',)){
        message.error("STEP 그리드의 필수 필드를 모두 입력해주세요.");
        return false;
      };
      if(!stepZeroTableRef.current.validateGridData(['title', 'prpt_seq'],'필수 필드는 반드시 값을 직접 입력해야 합니다.',)){
        message.error("STEP ZERO 그리드의 필수 필드를 모두 입력해주세요.");
        return false;
      };
      // if(stepUnselectedList.current.length !== 0){
      //   message.error("STEP 그리드의 그룹을 모두 선택해주세요.");
      //   return false;
      // }

    }
    const builderInfo = builderForm.getFieldsValue();
    if(builderInfo.prpt_cnt === 3 && builderInfo.lyrics_el_id){
      message.error('가사빌더를 사용할 경우 프롬프트 개수는 \n2개까지만 가능합니다.');
      return false;
    }
    if(builderInfo.step_zero_type === 'TEXTAREA' && stepZeroTableRef.current.getRowCount() > 1){
      message.error('StepZero 입력방식이 TextArea일 경우에는 StepZero를 1개만 입력가능합니다.');
      return false;
    }
    
    if(await window.confirm("저장하시겠습니까?")){
      const formData = new FormData();
      formData.append("el_id", elId);
      Object.keys(elementInfo).forEach(key => {
        const element = elementInfo[key];
        if(element !== undefined && element !== null){
          formData.append(key, element !== undefined ? element : '');
        }
      })

      if(imgFileUpdated === true){
        if(imgFiles?.length > 0){
          formData.set("img_phy_path", imgFiles[0]);
        }else{
          formData.set("img_phy_path", 'EMPTY');
          formData.set("img_file_nm", 'EMPTY');
        }
      }else{
        if(imgFiles?.length > 0){
          formData.set("img_phy_path", imgFiles[0].url);
          formData.set("img_file_nm", imgFiles[0].name);
        }else{
          formData.set("img_phy_path", 'EMPTY');
          formData.set("img_file_nm", 'EMPTY');
        }
      }

      if(mp3FileUpdated === true){
        if(mp3Files?.length > 0){
          formData.set("mp3_phy_path", mp3Files[0]);
        }else{
          formData.set("mp3_phy_path", 'EMPTY');
          formData.set("mp3_file_nm", 'EMPTY');
        }
      }else{
        if(mp3Files?.length > 0){
          formData.set("mp3_phy_path", mp3Files[0].url);
          formData.set("mp3_file_nm", mp3Files[0].name);
        }else{
          formData.set("mp3_phy_path", 'EMPTY');
          formData.set("mp3_file_nm", 'EMPTY');
        }
      }

      if(mp4FileUpdated === true){
        if(mp4Files?.length > 0){
          formData.set("mp4_phy_path", mp4Files[0]);
        }else{
          formData.set("mp4_phy_path", 'EMPTY');
          formData.set("mp4_file_nm", 'EMPTY');
        }
      }else{
        if(mp4Files?.length > 0){
          formData.set("mp4_phy_path", mp4Files[0].url);
          formData.set("mp4_file_nm", mp4Files[0].name);
        }else{
          formData.set("mp4_phy_path", 'EMPTY');
          formData.set("mp4_file_nm", 'EMPTY');
        }
      }

      if(divisionCode === "04"){

        if(stepZeroFileUpdated === true){
          if(stepZeroFiles?.length > 0){
            formData.set("step_zero_phy_path", stepZeroFiles[0]);
          }else{
            formData.set("step_zero_phy_path", 'EMPTY');
            formData.set("step_zero_img_nm", 'EMPTY');
          }
        }else{
          if(stepZeroFiles?.length > 0){
            formData.set("step_zero_phy_path", stepZeroFiles[0].url);
            formData.set("step_zero_img_nm", stepZeroFiles[0].name);
          }else{
            formData.set("step_zero_phy_path", 'EMPTY');
            formData.set("step_zero_img_nm", 'EMPTY');
          }
        }

        const builderInfo = builderForm.getFieldsValue();
        builderInfo.lyrics_yn = lyricsChecked ? 'Y' : 'N';
        Object.keys(builderInfo).forEach(key => {
          const element = builderInfo[key];
          if(element !== undefined){
            formData.append(key, element !== undefined ? element : '');
          }
        })

        formData.append('promptGridData', JSON.stringify(promptTableRef.current.getGridData()));
        formData.append('optionGridData', JSON.stringify(optionTableRef.current.getGridData()));
        formData.append('libraryGridData', JSON.stringify(libraryTableRef.current.getGridData()));
        formData.append('stepGridData', JSON.stringify(stepTableRef.current.getGridData()));
        formData.append('stepGroupList', JSON.stringify(stepSelectedList.current));
        if(stepGridFiles.current.length > 0){
          for(let i = 0 ; i < stepGridFiles.current.length ; i++){
            if(stepGridFiles.current[i]){
              formData.append('stepGridFiles', stepGridFiles.current[i]);
            }else{
              formData.append('stepGridFiles', new Blob());
            }
          }
        }
        formData.append('stepZeroGridData', JSON.stringify(stepZeroTableRef.current.getGridData()));
      }

      formData.append('treeData', JSON.stringify(gridData[0]));
      formData.append("el_div", divisionCode);

      const response = await api.saveElementInfo(formData);
      if(response.data.returnStatus === "success"){
        message.success(response.data.returnText);
        setElId(response.data.el_id);
        fetchElementInformation(response.data.el_id);
        makeLyricsList();
      }else if(response.data.returnStatus === "nameValidation"){
        message.error(divisionName+response.data.returnText);
      }else{
        message.info(response.data.returnText);
      }

    }
  }

  //라이브러리 조회
  const makeLibraryList = async () => {
    const response = await api.fetchElementList({elementSearchType : "lib"});
    const array = [];
    array.push({label : null, value : null});
    if(response.data.length > 0){
      const newArray = response.data.map((data) => {return {label : data.ko_nm, value : data.el_id}})
      array.push(...newArray);
    }
    setLibraryList(array);
  }

  //가사빌더 조회
  const makeLyricsList = async () => {
    const response = await api.fetchLyricsList({builderSearchType : "lyrics_yn"});
    const array = [{label: '', value: ''}];
    if(response.data.length > 0){
      const newArray = response.data.map((data) => {return {label : data.ko_nm, value : data.bld_id}})
      array.push(...newArray);
    }
    setLyricsList(array);
  }

  //공통코드 카테고리 조회
  const makeCategoryList = async () => {
    const response = await api.fetchCommonDetailCode({grp_cd : "CATE"});
    const array = [];
    if(response.data.length > 0){
      const newArray = response.data.map((data) => {return {label : data.cd_nm, value : data.cd}})
      array.push(...newArray);
    }
    setCategoryList(array);
  }

  //공통코드 AI 조회
  const makeAiModelList = async () => {
    const response = await api.fetchCommonDetailCode({grp_cd : "MODEL"});
    const array = [];
    if(response.data.length > 0){
      const newArray = response.data.map((data) => {return {label : data.cd_nm, value : data.cd}})
      array.push(...newArray);
    }
    setAiModelList(array);
  }

  //구독플랜 목록 조회
  const makePlanList = async () => {
    const response = await api.getPlanInfo();
    stepGridPlanList.current = response.data;
    const array = [];
    if(response.data.length > 0){
      const newArray = response.data.map((data) => {return {label : data.plan_nm, value : data.plan_cd}})
      array.push(...newArray);
    }
    setPlanList(array);
  }

  //새 빌더 생성 시 new그리드 변경될 경우 Step 그리드 데이터 생성
  const makeStepGridData = async () => {
    const gridData = newTableRef.current.getGridData();
    if(gridData[0].children && gridData[0].children.length > 0){
      stepGridList.current = await gridData[0].children.map((item) => {return {"el_id" : item.el_id, "ko_nm" : item.ko_nm, "en_nm" : item.en_nm}})
      stepSelectedList.current = await stepSelectedList.current.filter((item) => stepGridList.current.some((stepItem) => Number(stepItem.el_id) === Number(item.el_id)));
      stepUnselectedList.current = await stepGridList.current;
      stepTableRef.current.update();
    }
  }

  //새 빌더 생성 시 new그리드 변경될 경우 구성요소 그리드 데이터 생성
  const makeOptionGridData = () => {
    const gridData = newTableRef.current.getGridData();
    optionTableRef.current.clearGridData();
    optionTableRef.current.setGridData(gridData[0].children.map((item) => {
      return {
        "el_id" : item.el_id, 
        "ko_nm" : item.ko_nm, 
        "en_nm" : item.en_nm,
        "seq" : item.seq,
        "prpt_seq": 1,
        "loop_yn": "Y", 
        "and_yn": 'N'
      }
    }));
  }

  //STEP 그리드 행추가
  const addStep = () => {
    const gridData = stepTableRef.current.getGridData();
    if(stepGridList.current.length > 0 && gridData.length < stepGridList.current.length){
      stepTableRef.current.addRow({});
    }else{
      message.info("새 빌더에 그룹을 추가해주세요.");
      return false;
    }
  }

  //STEP 그리드 행삭제
  const deleteStep = async () => {
    const checkedItems = stepTableRef.current.getCheckedRowItems();
    if(checkedItems.length === 0){
      message.info("삭제할 STEP을 선택해주세요.");
      return false;
    }
    if(await window.confirm("선택한 STEP을 삭제하시겠습니까?", 'delete')){
      for(const item of checkedItems){
        const rowIndex = item.rowIndex;
        const selectedRowData = stepSelectedList.current.filter((item) => item.rowIndex === rowIndex);
        stepSelectedList.current = stepSelectedList.current.filter((item) => item.rowIndex !== rowIndex);
        if(selectedRowData && selectedRowData.length > 0){
          selectedRowData.forEach((data) => {
            stepUnselectedList.current.push(data)
          })
        }
        if(stepSelectedList && stepSelectedList.current.length > 0){
          stepSelectedList.current = stepSelectedList.current.map((item) => {
            if(item.rowIndex > rowIndex){
              item.rowIndex = item.rowIndex -1
              return item;
            }else{
              return item;
            }
          })
        }

        if(stepGridFiles.current.length > 0){
          stepGridFiles.current = stepGridFiles.current.filter((item, index) => index !== rowIndex);
        }
      }
      stepTableRef.current.removeCheckedRows();
    }
  }

  //STEP ZERO 그리드 행 추가
  const addStepZero = () => {
    stepZeroTableRef.current.addRow({});
  }

  //STEP ZERO 그리드 행 삭제
  const deleteStepZero = async () => {
    const checkedItems = stepZeroTableRef.current.getCheckedRowItems();
    if(checkedItems.length === 0){
      message.info("삭제할 STEP ZERO를 선택해주세요.");
      return false;
    }
    if(await window.confirm("선택한 STEP을 삭제하시겠습니까?", 'delete')){
      stepZeroTableRef.current.removeCheckedRows();
    }
  }

  //새 요소 그리드 행 삭제
  const deleteNewGrid = async () => {
    const checkedData = newTableRef.current.getCheckedRowItems();
    if(checkedData.length === 0){
      message.info("선택된 데이터가 없습니다.");
      return false;
    }
    for(let i = 0 ; i < checkedData.length ; i++){
      if(checkedData[i].rowIndex === 0){
        message.info("최상위 요소는 삭제할 수 없습니다.");
        return false;
      }
    }
    if(await window.confirm("선택한 데이터를 삭제하시겠습니까?", 'delete')){
      newTableRef.current.removeCheckedRows();
      if(divisionCode === "04"){
        makeOptionGridData();
        makeStepGridData();
      }
    }
  }

  //STEP 그리드의 그룹 삭제
  window.deleteStepElement = async function(el_id, rowIndex){
    if(await window.confirm("선택한 그룹을 삭제하시겠습니까?", 'delete')){
      stepSelectedList.current = stepSelectedList.current.filter((item) => !(Number(item.rowIndex) === rowIndex && Number(item.el_id) === el_id));
      stepTableRef.current.update();
    }
  }

  //STEP 그리드 파일 선택
  window.stepGridFileButtonClick = async function(rowIndex){
    const input = document.createElement("input");
    input.type = "file";
    input.style.display = "none";
    input.onchange = (e) => {
      const file = e.target.files[0];
      if(file && !beforeUpload(file, "image")){
        if(file.size > 20480000){
          message.info("20MB를 초과할 수 없습니다.");
          return false;
        }
        stepGridFiles.current[rowIndex] = file;
        stepTableRef.current.updateRow({help_img_nm : file.name}, rowIndex);
      }
      document.body.removeChild(input);
    }
    document.body.appendChild(input);
    input.click();
  }

  //새 요소 등록 세팅
  const setNewElement = async () => {
    await clearAllElements();

    newTableRef.current.setGridData([{"ko_nm":""}]);
    newTableRef.current.updateRow({el_div : divisionCode}, 0);
    newTableRef.current.updateRow({el_div_name : divisionName}, 0);
  }

  //모든 데이터 삭제
  const clearAllElements = () => {
    setElId(0);
    searchExistingElements.current = 'N';
    newTableRef.current.clearGridData();

    form.resetFields();

    setImgFiles([]);
    setMp3Files([]);
    setMp4Files([]);
    setImgFileUpdated(false);
    setMp3FileUpdated(false);
    setMp4FileUpdated(false);
    setStepZeroFiles([])
    setStepZeroFileUpdated(false);

    if(divisionCode === "04"){
      builderForm.resetFields();
      promptTableRef.current.clearGridData();
      optionTableRef.current.clearGridData();
      stepSelectedList.current = [];
      stepZeroTableRef.current.clearGridData();
      libraryTableRef.current.clearGridData();
      stepTableRef.current.clearGridData();
    }
  }

  //현재 요소 레벨의 데이터 조회
  const handleSearchNewGrid = async (values) => {
    await clearAllElements();
    const params = {
      "searchText" : values.searchText,
      "searchType" : "ko",
      "el_div" : divisionCode
    };

    newTableRef.current.showAjaxLoader();
    const response = await api.fetchExistingElements(params);
    searchExistingElements.current = "Y";
    if(response.data.length > 0){
      newTableRef.current.setGridData(response.data);
    }else{
      newTableRef.current.clearGridData();
    }

    newTableRef.current.removeAjaxLoader();
  }

  //선택된 요소 정보 조회
  const fetchElementInformation = async (el_id) => {
    await clearAllElements();
    newTableRef.current.showAjaxLoader();
    const params = {
      "el_id" : el_id
    };
    setElId(el_id);
    const response = await api.fetchElementInformation(params);
    const data = response.data;
    const treeData = JSON.parse(response.data.treeData)
    newTableRef.current.setGridData(treeData);
    form.setFieldsValue(data);

    if(data.img_file_nm){
      setImgFiles([{uid : "0", name : data.img_file_nm, status : "done", url : data.img_phy_path}])
    }
    if(data.mp3_file_nm){
      setMp3Files([{uid : "0", name : data.mp3_file_nm, status : "done", url : data.mp3_phy_path}])
    }
    if(data.mp4_file_nm){
      setMp4Files([{uid : "0", name : data.mp4_file_nm, status : "done", url : data.mp4_phy_path}])
    }

    if(divisionCode === "04"){
      builderForm.setFieldsValue({
        ...response.data,
        ai_model_cd : response.data.ai_model_cd?.split(","), 
        lyrics_el_id : response.data.lyrics_el_id === 0 ? null : response.data.lyrics_el_id,
      })

      if(data.step_zero_img_nm){
        setStepZeroFiles([{uid : "0", name : data.step_zero_img_nm, status : "done", url : data.step_zero_phy_path}])
      }

      setLyricsChecked(response.data.lyrics_yn === 'Y' ? true : false)

      promptTableRef.current.setGridData(response.data.prompts);
      await optionTableRef.current.setGridData(response.data.options);

      await libraryTableRef.current.setGridData(response.data.libraryOptions);
      const groupData = response.data.steps.reduce((acc, record) => {
        const { step_seq } = record;
        if (!acc[step_seq]) acc[step_seq] = [];
        acc[step_seq].push(record);
        return acc;
      }, {})

      const stepGridData = [];
      // eslint-disable-next-line array-callback-return
      Object.keys(groupData).map((value, index) => {
        stepGridData.push({
          bld_id : groupData[value][0].bld_id, 
          step_nm : groupData[value][0].step_nm, 
          step_seq : groupData[value][0].step_seq, 
          use_lv : groupData[value][0].use_lv, 
          help_img_nm : groupData[value][0].help_img_nm, 
          help_path : groupData[value][0].help_path
        })
        if(groupData[value].length > 0){
          for(let i = 0 ; i < groupData[value].length ; i++){
            stepSelectedList.current.push({rowIndex : index, el_id : groupData[value][i].el_id, ko_nm : groupData[value][i].ko_nm})
          }
        }
      })
      stepTableRef.current.setGridData(stepGridData);
      stepTableRef.current.update();

      const stepZeroGridData = response.data.stepZeros.map((item) => {
        return {
          bld_id : item.bld_id, 
          seq : item.seq, 
          title : item.title, 
          prpt_seq : item.prpt_seq === 0 ? '' : item.prpt_seq, 
          step_zero_prefix : item.step_zero_prefix,
          step_zero_suffix : item.step_zero_suffix,
          step_zero_placeholder : item.step_zero_placeholder,
        }
      });

      stepZeroTableRef.current.setGridData(stepZeroGridData);

      makeStepGridData();

      setTimeout(() => {
        setActiveKey('2');
        setTimeout(() => {
          setActiveKey('1');
        }, 100);
      }, 100);
    }
    newTableRef.current.removeAjaxLoader();
  }

  // 미리보기 팝업 열기
  const handlePreview = async (file, flag) => {
    setPreviewUrl(file.url); // 이미지 URL 설정
    setPreviewType(flag); //미리보기 종류
    setPreviewVisible(true); // 팝업 보이기
  };

  // 미리보기 팝업 닫기
  const handlePreviewCancel = () => {
    setPreviewVisible(false);
    setPreviewType('');
    setPreviewUrl('');
  }

  //새 그리드 설정
  const newGridProps = {
    height : 700,
    editable : false,
    selectionMode: "singleRow",
    // 그리드 데이터와 무관하게 무조건적인 트리 그리드로 출력.
    forceTreeView: true,
    // 일반 데이터를 트리로 표현할지 여부
    treeColumnIndex: 1,
    // 드래깅 행 이동 가능 여부 (기본값 : false)
    enableDrag: true,
    // 셀에서 바로  드래깅 해 이동 가능 여부 (기본값 : false) - enableDrag=true 설정이 선행 
    enableDragByCellDrag: true,
    // 드랍 가능 여부 (기본값 : true)
    // false 설정했다는 의미는 이 그리드는 드래깅만 가능하고, 드랍은 절대 받지 않는다는 뜻임.
    enableDrop: true,
    // 드랍을 받아줄 그리드가 다른 그리드에도 있는지 여부 (기본값 : false)
    // 즉, 드리드 간의 행 이동인지 여부
    dropToOthers: false,
    showStateColumn: false,
    showRowNumColumn : false,
    showHeader : false,
    showRowCheckColumn : true,
    softRemoveRowMode : false,
    enableFilter : false,
    treeLevelIndent: 8,
  };

  //새 그리드 컬럼
  const newColumnLayout = [
    { dataField: 'el_id', headerText: '구성요소ID', visible: false },
    { dataField: 'ko_nm', headerText: '한글명', width : "40%" },
    { dataField: 'en_nm', headerText: '영어명', width : "40%" },
    { dataField: 'el_div', headerText: '구성요소구분', visible : false },
    { dataField: 'el_div_name', headerText: '구성요소구분', width : "20%" },
    { dataField: 'mp3_phy_path', headerText: 'MP3물리경로', visible: false },
    { dataField: 'img_phy_path', headerText: '이미지물리경로', visible: false },
    { dataField: 'desc', headerText: '설명', visible: false },
    { dataField: 'key1', headerText: '식별키1', visible: false },
    { dataField: 'key2', headerText: '식별키2', visible: false },
    { dataField: 'attr1', headerText: 'ATTR1', visible: false },
    { dataField: 'attr2', headerText: 'ATTR2', visible: false },
    { dataField: 'attr3', headerText: 'ATTR3', visible: false },
    { dataField: 'seq', headerText: 'seq', visible: false },
  ];

  //현재 그리드 설정
  const currentGridProps = {
    height : 700,
    editable : false,
    selectionMode: "singleRow",
    // 그리드 데이터와 무관하게 무조건적인 트리 그리드로 출력.
    forceTreeView: true,
    // 일반 데이터를 트리로 표현할지 여부
    treeColumnIndex: 1,
    // 드래깅 행 이동 가능 여부 (기본값 : false)
    enableDrag: true,
    // 셀에서 바로  드래깅 해 이동 가능 여부 (기본값 : false) - enableDrag=true 설정이 선행 
    enableDragByCellDrag: true,
    // 드랍 가능 여부 (기본값 : true)
    // false 설정했다는 의미는 이 그리드는 드래깅만 가능하고, 드랍은 절대 받지 않는다는 뜻임.
    enableDrop: false,
    // 드랍을 받아줄 그리드가 다른 그리드에도 있는지 여부 (기본값 : false)
    // 즉, 드리드 간의 행 이동인지 여부
    dropToOthers: true,
    showStateColumn: false,
    showRowNumColumn : false,
    showHeader : false,
    treeLevelIndent: 8,
  };

  //현재 그리드 컬럼
  const currentColumnLayout = [
    { dataField: 'el_id', headerText: '구성요소ID', visible: false },
    { dataField: 'ko_nm', headerText: '한글명', width : "40%" },
    { dataField: 'en_nm', headerText: '영어명', width : "40%" },
    { dataField: 'el_div', headerText: '구성요소구분', visible : false },
    { dataField: 'el_div_name', headerText: '구성요소구분', width : "20%" },
    { dataField: 'mp3_phy_path', headerText: 'MP3물리경로', visible: false },
    { dataField: 'img_phy_path', headerText: '이미지물리경로', visible: false },
    { dataField: 'desc', headerText: '설명', visible: false },
    { dataField: 'key1', headerText: '식별키1', visible: false },
    { dataField: 'key2', headerText: '식별키2', visible: false },
    { dataField: 'attr1', headerText: 'ATTR1', visible: false },
    { dataField: 'attr2', headerText: 'ATTR2', visible: false },
    { dataField: 'attr3', headerText: 'ATTR3', visible: false },
  ];

  const promptGridProps = {
    height : 200,
    showStateColumn: false,
    wordWrap: true,
    enableSorting : false,
  }

  const promptColumnLayout = [
    { dataField: 'bld_id', visible : false},
    { dataField: 'prpt_seq', visible : false},
    { dataField: 'ko_nm', headerText: '한글명', width : "25%", editRenderer : {type : "InputEditRenderer",maxlength : 50}},
    { dataField: 'en_nm', headerText: '영어명', width : "25%", editRenderer : {type : "InputEditRenderer",maxlength : 50}},
    { dataField: 'desc', headerText: '설명', width : "40%", style: 'my-wrap-column',
      editRenderer: {
        type: "CustomEditRenderer",
        jsClass: MyTextareaEditor,
        vPosition: "top",
        fitWidth: true,
        extraProps: {
          confirm: "확 인(Ctrl+Enter)",
          cancel: "취 소(Esc)"
        }
      } 
    },
    { dataField: 'max_txt', headerText: '최대문자', width : "10%", dataType : "numeric", formatString: "#,###", editRenderer : {type : "InputEditRenderer",maxlength : 10,regExp : /^\d+$/} },
  ]

  const optionGridProps = {
    height : 200,
    showStateColumn: false,
    enableSorting : false,
    autoGridHeight : true,
    showRowCheckColumn : true,
  }

  const optionColumnLayout = [
    { dataField: 'bld_id', visible : false},
    { dataField: 'el_id', visible : false},
    { dataField: 'ko_nm', headerText: '국문명', width : "10%", editable : false},
    { dataField: 'en_nm', headerText: '영문명', width : "10%", editable : false },
    { dataField: 'prefix', headerText: '접두사', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 10}},
    { dataField: 'suffix', headerText: '접미사', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 10} },
    { dataField: 'pre_sep', headerText: '반복 접두사', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 10} },
    { dataField: 'suf_sep', headerText: '반복 접미사', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 10} },
    { dataField: 'and_yn', headerText: 'And 치환', width : "10%", 
      renderer : {
        type : "DropDownListRenderer",
        list : ["Y","N"]
      }
    },
    { dataField: 'prpt_seq', headerText: '프롬프트 위치', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 2,regExp : /^\d+$/} },
    { dataField: 'loc_idx', headerText: '위치 순서', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 2,regExp : /^\d+$/} },
    { dataField: 'loop_yn', headerText: '반복여부', width : "10%",
      renderer : {
        type : "DropDownListRenderer",
        list : ["Y","N"]
      }
    },
  ]

  const libraryGridProps = {
    height : 200,
    showStateColumn: false,
    enableSorting : false
  }

  const libraryColumnLayout = [
    { dataField: 'bld_id', visible : false},
    { dataField: 'el_id', visible : false},
    { dataField: 'ko_nm', headerText: '국문명', width : "25%", editable : false},
    { dataField: 'en_nm', headerText: '영문명', width : "25%", editable : false },
    { dataField: 'prefix', headerText: '접두사', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 10}},
    { dataField: 'suffix', headerText: '접미사', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 10} },
    { dataField: 'pre_sep', headerText: '반복 접두사', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 10} },
    { dataField: 'suf_sep', headerText: '반복 접미사', width : "10%", editRenderer : {type : "InputEditRenderer",maxlength : 10} },
    { dataField: 'and_yn', headerText: 'And 치환', width : "10%", 
      renderer : {
        type : "DropDownListRenderer",
        list : ["Y","N"]
      }
    },
  ]

  const stepGridProps = {
    height : 200,
    showStateColumn: false,
    showRowNumColumn : false,
    showRowCheckColumn : true,
    softRemoveRowMode : false,
    enableSorting : false
  }

  const stepColumnLayout = [
    { dataField: 'bld_id', visible : false},
    { dataField: 'el_id', visible : false},
    { dataField: 'step_seq', headerText: 'STEP', width : "5%", 
      renderer : {
        type : "DropDownListRenderer",
        listFunction : function(rowIndex, columnIndex, item, dataField){
          return Array.from({length : stepGridList.current.length}, (_, index) => index + 1)
        }
      }
    },
    { dataField: 'step_nm', headerText: '스텝명', width : "10%"},
    { dataField: 'group', headerText: '그룹 선택', width : "15%"
      , renderer : {
        type : "DropDownListRenderer",
        valueField : "ko_nm",
        keyField : "el_id",
        listFunction : function(rowIndex, columnIndex, item, dataField){
          return stepUnselectedList.current;
        }
      }
    },
    { dataField: 'selected_group', headerText: '선택그룹', width : "40%", editable : false,
      renderer : {
        type : "TemplateRenderer"
      },
      labelFunction : function(rowIndex, columnIndex, value, headerText, item){
        let html = "";
        if(stepSelectedList.current.length > 0){
          html += "<div style='text-align : left; display: flex'>";
          for(const selectedItem of stepSelectedList.current){
            if(rowIndex === selectedItem.rowIndex){
              html += "<button style='border : 1px solid ; border-radius : 5px ; padding : 10px' onClick='deleteStepElement("+selectedItem.el_id+","+selectedItem.rowIndex+")'>"
              html +=   selectedItem.ko_nm;
              html += "</button>"
            }
          }
          html += "</div>";
        }
        return html;
      }
    },
    { dataField: 'help_select', headerText: '도움말 등록', width : "5%" , editable : false,
      renderer : {
        type : "TemplateRenderer"
      },
      labelFunction : function(rowIndex, columnIndex, value, headerText, item){
        let html = "";
        html += '<button style="border : 1px solid ; border-radius : 5px ; padding : 10px" onClick="stepGridFileButtonClick('+rowIndex+')">파일선택</button>';
        return html;
      }
    },
    { dataField: 'help_path', headerText: '파일경로', width : "15%", editable : false, visible : false },
    { dataField: 'help_img_nm', headerText: '파일명', width : "15%", editable : false },
    { dataField: 'use_lv', headerText: '구독 플랜', width : "10%" 
      , renderer : {
        type : "DropDownListRenderer",
        valueField : "plan_nm",
        keyField : "plan_cd",
        listFunction : function(rowIndex, columnIndex, item, dataField){
          return stepGridPlanList.current;
        }
      }
    },
  ]

  const stepZeroGridProps = {
    height : 200,
    showStateColumn: false,
    showRowCheckColumn : true,
    softRemoveRowMode : false,
    enableSorting : false
  }

  const stepZeroColumnLayout = [
    { dataField: 'bld_id', visible : false},
    { dataField: 'seq', visible : false},
    { dataField: 'title', headerText: '타이틀명', width : "50%"},
    { dataField: 'step_zero_prefix', headerText: '접두사', width : "10%"},
    { dataField: 'step_zero_suffix', headerText: '접미사', width : "10%"},
    { dataField: 'step_zero_placeholder', headerText: '플레이스홀더', width : "20%"},
    { dataField: 'prpt_seq', headerText: '프롬프트 순서', width : "10%"
      , renderer : {
        type : "DropDownListRenderer",
        listFunction : function(rowIndex, columnIndex, item, dataField){
          const gridData = promptTableRef.current.getGridData();
          return Array.from({length : gridData.length}, (_, index) => index + 1)
        }
      }
    },
  ]

  const handleImageFileChange = (e) => {
    setImgFileUpdated(true);
    if(e.file.status && e.file.status === "removed"){
      setImgFiles([]);
    }else{
      setImgFiles([e.file]);
    }
  }

  const handleMp3FileChange = (e) => {
    setMp3FileUpdated(true);
    if(e.file.status && e.file.status === "removed"){
      setMp3Files([]);
    }else{
      setMp3Files([e.file]);
    }
  }

  const handleMp4FileChange = (e) => {
    setMp4FileUpdated(true);
    if(e.file.status && e.file.status === "removed"){
      setMp4Files([]);
    }else{
      setMp4Files([e.file]);
    }
  }

  const handleStepZeroImageFileChange = (e) => {
    setStepZeroFileUpdated(true);
    if(e.file.status && e.file.status === "removed"){
      setStepZeroFiles([]);
    }else{
      setStepZeroFiles([e.file]);
    }
  }

  const handleChangedLibrary = async (libraryId) => {
    libraryTableRef.current.clearGridData();
    if(!libraryId){
      return false;
    }
    const params = {
      'el_id' : libraryId
    };

    const response = await api.fetchElementsList(params);
    const treeData = JSON.parse(response.data.treeData);

    libraryTableRef.current.clearGridData();
    libraryTableRef.current.setGridData({
      "el_id" : treeData[0].el_id, 
      "ko_nm" : treeData[0].ko_nm, 
      "en_nm" : treeData[0].en_nm,
      "seq" : treeData[0].seq,
      "prpt_seq": 1,
      "loop_yn": "Y", 
      "and_yn": 'N'
    })
  }

  const updateNewGrid = () => {
    const gridData = newTableRef.current.getGridData();
    gridData.forEach((data, index) => {
      newTableRef.current.updateRow({seq : index}, index);
    })
  }

  const additionalForm = () => {
    return (
      <AdditionalForm
        builderForm={builderForm}
        categoryList={categoryList}
        lyricsChecked={lyricsChecked}
        setLyricsChecked={setLyricsChecked}
        aiModelList={aiModelList}
        libraryList={libraryList}
        handleChangedLibrary={handleChangedLibrary}
        planList={planList}
        changedPromptCount={changedPromptCount}
        lyricsList={lyricsList}
        stepZeroFiles={stepZeroFiles}
        handleStepZeroImageFileChange={handleStepZeroImageFileChange}
        beforeUpload={beforeUpload}
        handlePreview={handlePreview}
      />
    )
  }

  const defaultForm = () => {
    return (
      <DefaultForm
        form={form}
        imgFiles={imgFiles}
        mp3Files={mp3Files}
        mp4Files={mp4Files}
        handleImageFileChange={handleImageFileChange}
        handleMp3FileChange={handleMp3FileChange}
        handleMp4FileChange={handleMp4FileChange}
        handlePreview={handlePreview}
        beforeUpload={beforeUpload}
        changeElementKoName={changeElementKoName}
        changeElementEnName={changeElementEnName}
      />
    )
  }

  const tabsItems = [
    {
      key: '1',
      label: '기본 정보',
      children: defaultForm(),
    },
    {
      key: '2',
      label: '빌더 특성',
      children: additionalForm(),
    },
  ];

  useEffect(() => {
    clearAllElements();
    makeCategoryList();
    makeAiModelList();
    makePlanList();
    makeLyricsList();
    makeLibraryList();

    newTableRef.current.setGridData([{"ko_nm":""}]);
    currentTableRef.current.clearGridData();

    newTableRef.current.updateRow({el_div : divisionCode}, 0);
    newTableRef.current.updateRow({el_div_name : divisionName}, 0);

    newTableRef.current?.bind("cellDoubleClick", async function(event){
      if(searchExistingElements.current === "Y"){
        const gridData = newTableRef.current.getGridData();
        fetchElementInformation(gridData[event.rowIndex].el_id);
      }
    })

    newTableRef.current?.bind("dropEnd", async function(event){
      const gridData = newTableRef.current.getGridData();
      if(divisionCode !== "05" && gridData[event.toRowIndex-1].el_div === "01" && event.items[0].el_div !== '01'){
        newTableRef.current.indentTreeDepth(newTableRef.current);
      }
      await updateNewGrid();
      if(divisionCode === "04"){
        makeStepGridData();
        makeOptionGridData();
      }
    })

    newTableRef.current.bind("dragBegin", function(e){
      if(searchExistingElements.current === "Y"){
        return false;
      }
    })

    currentTableRef.current.bind("dropEnd", async function(event){
      await updateNewGrid();
      if(divisionCode !== "05"){
        newTableRef.current.indentTreeDepth(newTableRef.current);
      }else{
        if(newTableRef.current.getGridData().length-1 === event.toRowIndex){
          newTableRef.current.indentTreeDepth(newTableRef.current);
        }
      }
      if(divisionCode === "04"){
        makeStepGridData();
        makeOptionGridData();
      }
    })

    currentTableRef.current.bind("dragBegin", function(e){
      if(searchExistingElements.current === "Y"){
        return false;
      }
    })

    newTableRef.current.bind("dropEndBefore", function(event){
      const gridData = newTableRef.current.getGridData();
      if(event.toRowIndex === 0){
        message.error("최상위 "+divisionName+"로는 이동할 수 없습니다.");
        return false;
      }

      if(gridData[event.toRowIndex-1].el_div === "01"){
        if(event.items[0].el_div !== '01'){
          message.error("최하위 요소의 하위로 이동할 수 없습니다.");
          return false;
        }
      }

      const children = gridData[event.toRowIndex-1].children;
      if(children && children.length > 0){
        for(let i = 0 ; i < children.length ; i++){
          if(children[i].el_id === event.items[0].el_id){
            message.error("한 그룹에 같은 요소가 들어갈 수 없습니다.");
            return false;
          }
        }
      }
    })

    // 드랍 종료 전 이벤트 바인딩 - 여기서 행 이동, 복사를 결정합니다.
    currentTableRef.current.bind("dropEndBefore", function(event){
      if(event.toRowIndex === 0){
        message.error("최상위 "+divisionName+"로는 이동할 수 없습니다.");
        return false;
      }

      const gridData = newTableRef.current.getGridData();
      if(gridData[event.toRowIndex-1].el_div === "01"){
        message.error("최하위 요소의 하위로 이동할 수 없습니다.");
        return false;
      }
      const children = gridData[event.toRowIndex-1].children;
      if(children && children.length > 0){
        for(let i = 0 ; i < children.length ; i++){
          if(children[i].el_id === event.items[0].el_id){
            message.error("한 그룹에 같은 요소가 들어갈 수 없습니다.");
            return false;
          }
        }
      }
			// 이벤트의 isMoveMode 속성을 false 설정하면 행 복사를 합니다.
			event.isMoveMode = false;
    })

    if(divisionCode === "04"){
      stepTableRef.current.bind("cellEditEnd", function(e){
        if(e.dataField === "group" && stepGridList.current.length > 0 && e.value){
          // 조건 체크: stepSelectedList.current에 동일한 rowIndex와 el_id가 있는지 확인
          const isDuplicate = stepSelectedList.current.some(
            (item) => item.rowIndex === e.rowIndex && Number(item.el_id) === Number(e.value)
          );

          // 중복된 데이터가 있으면 이후 코드 실행을 중단
          if (isDuplicate) {
            message.error('중복된 그룹을 넣을 수 없습니다.');
            return; // 조건 충족 시 조기 종료
          }
          const foundItem = stepGridList.current.find((item) => item.el_id === Number(e.value));
          stepSelectedList.current.push({rowIndex : e.rowIndex, el_id : e.item.group, ko_nm : foundItem.ko_nm}); 
          stepTableRef.current.update();
          stepTableRef.current.updateRow({group : ""}, e.rowIndex);
        }
      })
  
      stepTableRef.current.bind("cellEditBegin", function(e){
        if(e.dataField === "group" && stepUnselectedList.current.length === 0){
          return false;
        }
      })

      stepTableRef.current.bind("cellEditEndBefore", function(e){
        if(e.dataField === "step_seq"){
          const gridData = stepTableRef.current.getGridData();
          for(let i = 0 ; i < gridData.length ; i++){
            if(gridData[i].step_seq === Number(e.value)){
              message.error("동일한 STEP을 여러개 지정할 수 없습니다.");
              return e.oldValue === undefined ? "" : e.oldValue;
            }
          }
        }
      })

      stepTableRef.current.bind("cellClick", function(e){
        if(e.dataField === "help_img_nm"){
          if(e.item.help_img_nm){
            handlePreview({url : e.item.help_path}, "image")
          }
        }
      })

      promptTableRef.current.bind("cellEditEnd", function(e){
        if(e.dataField === 'ko_nm' || 'en_nm'){
          const koReg = /[가-힣ㄱ-ㅎㅏ-ㅣ]/g;
          const enReg = /[a-zA-Z]/g;
          let originalValue = e.value;
          let filteredValue = originalValue;
          if(e.dataField === 'ko_nm'){
            filteredValue = originalValue.replace(enReg, "");
            if (filteredValue !== originalValue) {
              message.error("한글만 입력 가능합니다.");
              promptTableRef.current.updateRow({ko_nm: filteredValue}, e.rowIndex);
            }
          }else if(e.dataField === 'en_nm'){
            filteredValue = originalValue.replace(koReg, "");
            if (filteredValue !== originalValue) {
              message.error("영어만 입력 가능합니다.");
              promptTableRef.current.updateRow({en_nm: filteredValue}, e.rowIndex);
            }
          }
        }
      })
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divisionCode, divisionName, form]);

  const [selectedValue, setSelectedValue] = useState(undefined);
  const inputRef = useRef(); // Input DOM 요소를 참조하기 위한 useRef 생성

  const targetFields = ['prefix', 'suffix', 'pre_sep', 'suf_sep'];

  const selectOptions = optionColumnLayout
      .filter(column => targetFields.includes(column.dataField))
      .map(column => ({
        label: column.headerText,
        value: column.dataField
      }));

  const handleChangeSelect = (value) => {
    setSelectedValue(value);
  };

  const handleSubmit = () => {
    const checkedItems = optionTableRef.current.getCheckedRowItems();
    if(checkedItems.length === 0){
      message.error('데이터를 변경할 행을 선택해주세요.');
      return false;
    }
    if (!selectedValue || !inputRef.current.input.value) {
      message.error('모든 값을 선택하고 입력하세요.');
      return;
    }

    for (let i = 0; i < checkedItems.length; i++) {
      optionTableRef.current.setCellValue(checkedItems[i].rowIndex, selectedValue, inputRef.current.input.value);
    }
  };


  return (
    <>
      <Layout>
        <Content>
          <Row gutter={16} style={{gap: '10px'}}>
            <Col span={8} className='l-content'>
              <GridHeader>
                {{
                  searchArea : (
                    <div className='l-flex col w100'>
                      <Title level={5}>
                          <span>새 {divisionName}관리</span>
                      </Title>
                      <div className='l-flex betCenter'>
                        <Form layout="inline" onFinish={handleSearchNewGrid} >
                          <Form.Item name="searchText">
                            <Input placeholder="검색어" maxLength={100}/>
                          </Form.Item>
                          <Button type="primary" htmlType="submit" className='btn-searchIcon' style={{marginLeft: '10px'}}>검색</Button>
                        </Form>
                        <div className='l-flex gap' style={{marginLeft: '10px'}}>
                          <Button className='btn-add' onClick={setNewElement}>새 {divisionName}</Button>
                          <Button className='btn-red' onClick={deleteNewGrid} danger>
                            삭제
                          </Button>
                        </div>
                      </div>
                    </div>
                  ),
                }}
              </GridHeader>
              <Table ref={newTableRef} columnLayout={newColumnLayout} customGridProps={newGridProps}></Table>
            </Col>
            <Col span={8} className='l-content'>
            <GridHeader>
                {{
                  searchArea : (
                    <div className='l-flex col w100'> 
                      <Title level={5}>
                        현재 {divisionName}
                      </Title>
                      <Form form={searchForm} layout="inline" onFinish={handleSearch} initialValues={{ searchType: 'ko' }} className='l-flex gap'>
                        <Form.Item name="searchType">
                          <Select
                            style={{ width: 120 }}
                            options={[
                              { value: 'ko', label: '국문' },
                              { value: 'en', label: '영문' },
                              { value: 'attr', label: '조회 키 국문' },
                              { value: 'key', label: '조회 키 영문' },
                            ]}
                          />
                        </Form.Item>
                        <Form.Item name="searchText">
                          <Input placeholder="검색어" maxLength={100}/>
                        </Form.Item>
                        {divisionCode !== "05" ? (
                          <Form.Item name="lowest">
                            <Checkbox checked={lowest} onChange={(e) => setLowest(e.target.checked)}>최하위</Checkbox>
                          </Form.Item>
                        ) : null}
                        <Button type="primary" htmlType="submit" className='btn-searchIcon'>
                          검색
                        </Button>
                      </Form>
                    </div>
                  )
                }}
              </GridHeader>
              <Table ref={currentTableRef} columnLayout={currentColumnLayout} customGridProps={currentGridProps}></Table>
            </Col>
            <Col span={8} className='l-content'>
              <GridHeader>
                {{
                  searchArea : (
                    <Title level={5}>정보 : 새 {divisionName}</Title>
                  )
                }}
              </GridHeader>
              {divisionCode === "04" ? (
                <Tabs activeKey={activeKey} items={tabsItems} destroyInactiveTabPane={false} onChange={(key) => setActiveKey(key)}/>
              ) : (
                defaultForm()
              )}
            </Col>
          </Row>
          {divisionCode === "04" ? (
            <Col className='l-content' style={{marginTop: '10px'}}>
              <GridHeader>
                {{
                  searchArea : (
                    <Title level={5}>프롬프트 프리뷰창</Title>
                  ),
                }}
              </GridHeader>
              <Table ref={promptTableRef} columnLayout={promptColumnLayout} customGridProps={promptGridProps}></Table>
            </Col>
          ) : null}
          {divisionCode === "04" ? (
              <Col className='l-content' style={{marginTop: '10px'}}>
                <GridHeader>
                  {{
                    searchArea: (
                        <Title level={5}>STEP구성그룹</Title>
                    ),
                    inputArea : (
                        <>
                          <Select
                              value={selectedValue}
                              onChange={handleChangeSelect}
                              placeholder="선택하세요"
                              className="w-full p-2"
                              style={{width: 150}}
                          >
                            {selectOptions.map(option => (
                                <Option key={option.value} value={option.value}>
                                  {option.label}
                                </Option>
                            ))}
                          </Select>
                          <Input
                              style={{ width: 300 }}
                              ref={inputRef}
                          />
                          <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                            입력
                          </Button>
                        </>
                    ),
                  }}
                </GridHeader>


                <Table ref={optionTableRef} columnLayout={optionColumnLayout} customGridProps={optionGridProps}></Table>
              </Col>
          ) : null}
          {divisionCode === "04" ? (
              <Col className='l-content' style={{marginTop: '10px'}}>
                <GridHeader>
                  {{
                    searchArea: (
                        <Title level={5}>라이브러리 구성요소</Title>
                    ),
                  }}
                </GridHeader>
                <Table ref={libraryTableRef} columnLayout={libraryColumnLayout} customGridProps={libraryGridProps}></Table>
            </Col>
          ) : null}
          {divisionCode === "04" ? (
            <Col className='l-content' style={{marginTop: '10px'}}>
              <GridHeader>
                {{
                  searchArea : (
                    <Title level={5}>STEP</Title>
                  ),
                  buttonArea : (
                    <>
                      <Button className='btn-add' type="primary" onClick={addStep}>Step 추가</Button>
                      <Button className='btn-red' onClick={deleteStep} danger>Step 삭제</Button>
                    </>
                  )
                }}
              </GridHeader>
              <Table ref={stepTableRef} columnLayout={stepColumnLayout} customGridProps={stepGridProps}></Table>
            </Col>
            ) : null}
            {divisionCode === "04" ? (
              <Col className='l-content' style={{marginTop: '10px'}}>
                <GridHeader>
                  {{
                    searchArea : (
                      <Title level={5}>STEP Zero</Title>
                    ),
                    buttonArea : (
                      <>
                        <Button className='btn-add' type="primary" onClick={addStepZero}>Step Zero 추가</Button>
                        <Button className='btn-red' onClick={deleteStepZero} danger>Step Zero 삭제</Button>
                      </>
                    )
                  }}
                </GridHeader>
                <Table ref={stepZeroTableRef} columnLayout={stepZeroColumnLayout} customGridProps={stepZeroGridProps}></Table>
              </Col>
            ) : null}

          <div className='l-flex' style={{justifyContent: 'end', marginTop: '20px'}}>
            <Button className='btn-pink' type="primary" onClick={handleSave}>
              저장
            </Button>
          </div>
        </Content>
      </Layout>
      <input type="file" id="stepGridFile" style={{display : "none"}}/>
      <Modal
        open={previewVisible}
        footer={null}
        onCancel={handlePreviewCancel}
      >
        {previewType.startsWith("image") && (
          <img
            alt="Preview"
            style={{ width: '100%' }}
            src={previewUrl}
          />
        )}
        {previewType.startsWith("audio") && (
          <audio controls style={{ width: '100%' }}>
            <source src={previewUrl} type="audio/mpeg" />
            Your browser does not support the audio tag.
          </audio>
        )}
        {previewType.startsWith("video") && (
          <video controls style={{ width: '100%' }}>
            <source src={previewUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </Modal>
    </>
  );
};

export default AdminElementsManagementHome;
