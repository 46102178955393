import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Checkbox, message, Skeleton, Tooltip, Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
// icon
import saveIcon from '@assets/images/common/save_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
import heartIcon from '@assets/images/common/heart_icon.png';
import activeHeartIcon from '@assets/images/common/active_heart_icon.png';
import activeHeadsetIcon from '@assets/images/common/active_headset_icon.png';
import activeSaveIcon from '@assets/images/common/active_save_icon.png';
import activeCartIcon from '@assets/images/common/active_cart_icon.png';
import downloadIcon from '@assets/images/common/download_icon.png';
import activeDownloadIcon from '@assets/images/common/active_download_icon.png';
import userThum from '@assets/images/common/user_thumb.png';
import cartUpdateIcon from '@assets/images/common/cart_update_icon.png';
import headsetIcon from '@assets/images/common/i-playLine.svg';
import resetIcon from '@assets/images/common/reset_icon2.png';
// component
import Banner from '@components/slider/Banner';
import Rate from '@components/Rate';
import Filter from '@components/FilterSubMain';
import FilterMobile from '@components/FilterMobileSubMain';
import SubFilterSubMain from '@components/SubFilterSubMain';
import SearchSubMain from '@components/SearchSubMain';
import ButtonGroupSubMain from '@components/ButtonGroupSubMain';
import CardSquareMusic2 from '@pages/main/CardSquareMusic2';
import MusicPlayer from '@components/PlayerMusic';
// path
import * as PATH from '@routes/pathName';
// lib
import { useDebounce, validateSession, getDefaultImg, getLocalStorage, handleImageError } from '@utils/lib';
// const
import { AUTH_LOGIN, subMainSortList, POPUP_MUSIC_PLAYER } from '@utils/constants';
// api
import * as api from '@api/index';
// redux
import { setQuery, setMarketplaceMusicList, setLocationKey, setYScrollPosition, INIT_STATE_MARKETPLACE_MUSICLIST, clearMarketplaceMusicList } from '@stores/marketplaceMusicList';
// Images
import { IMAGES } from '@utils/image';
// hook
import useWidth from '@hooks/useWidth';

const MusicHome = (props) => {
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const { key: locationKey } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isActiveFilter, setIsActiveFilter] = useState(true);
  const [isActiveFilterMobile, setIsActiveFilterMobile] = useState(false);
  const [aiModelList, setAiModelList] = useState(); // AI 모델 리스트
  const [genreList, setGenreList] = useState(); // 장르 리스트
  const [subGenreList, setSubGenreList] = useState(); // 서브장르 리스트
  // 보관함 목록 데이터: prpt_id만 있음
  const [savedMusicPrptIds, setsavedMusicPrptIds] = useState([]);
  // 좋아요 목록 데이터: prpt_id만 있음
  const [likedMusicPrptIds, setlikedMusicPrptIds] = useState([]);
  // 장바구니 목록 데이터: prpt_id만 있음
  const [cartMusicPrptIds, setcartMusicPrptIds] = useState([]);
  // 장바구니 목록 데이터
  const [cartMusicList, setcartMusicList] = useState([]);
  // 체크된 음악 리스트
  const [checkedMusicList, setCheckedMusicList] = useState([]);
  // 체크된 음악 리스트 총 갯수
  const [totalCheckedMusicList, setTotalCheckedMusicList] = useState(0);
  const dispatch = useDispatch();
  const marketplaceMusicList = useSelector((s) => s.marketplaceMusicList, shallowEqual);
  // 음악 플레이어 모달창
  const [isActiveMusicPlayer, setIsActiveMusicPlayer] = useState(false);
  const width = useWidth();

  // 음악 플레이어 모달창 열기
  const openModalMusicPlayer = () => {
    setIsActiveMusicPlayer(true);
  };

  // 음악 플레이어 모달창 닫기
  const closeModalMusicPlayer = () => {
    setIsActiveMusicPlayer(false);
  };

  // PC용 필터 토글
  const filterToggle = useCallback(() => {
    setIsActiveFilter(!isActiveFilter);
  }, [isActiveFilter]);

  // 모바일용 필터 토글
  const filterToggleMobile = useCallback(() => {
    setIsActiveFilterMobile(!isActiveFilterMobile);
  }, [isActiveFilterMobile]);

  // 선택한 필터 변경
  const changeFilterParams = useCallback(
    (key, value) => {
      // key 값 유효성 체크
      if (!['filter_sort', 'filter_model', 'filter_genre', 'filter_sub_genre', 'filter_text', 'staff_pick'].includes(key)) return;

      // 선택한 필터 값 변경
      const newFilterParams = { ...marketplaceMusicList.query, offset: 0 };
      newFilterParams[key] = value;
      // filter_genre 값이 바뀌면, filter_sub_genre 초기화
      if (key === 'filter_genre') newFilterParams['filter_sub_genre'] = 'all';
      dispatch(setQuery(newFilterParams));
      handleDebounce(() => listMusic(newFilterParams));
    },
    [marketplaceMusicList.query],
  );

  // 체크 여부
  const isChecked = (music) => {
    const existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.prpt_id === music?.prpt_id);
    return existed.length > 0;
  };

  // 체크 박스 한개 토글
  const onCheckOne = (checked, music) => {
    const existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.prpt_id === music?.prpt_id);
    if (checked && !existed.length) {
      setCheckedMusicList([...checkedMusicList, music]);
      setTotalCheckedMusicList(totalCheckedMusicList + 1);
    }
    if (!checked && existed.length) {
      const removed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.prpt_id !== music?.prpt_id);
      setCheckedMusicList(removed);
      setTotalCheckedMusicList(totalCheckedMusicList - 1);
    }
  };

  // 체크 박스 전체 토글
  const onCheckAll = (checked) => {
    if (checked) {
      setCheckedMusicList(marketplaceMusicList?.items);
      setTotalCheckedMusicList(marketplaceMusicList?.currentCount);
    } else {
      setCheckedMusicList([]);
      setTotalCheckedMusicList(0);
    }
  };

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (music, pageY) => {
    try {
      // 해당 프롬프트의 스크롤 위치 저장
      dispatch(setYScrollPosition(pageY));
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: music?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 판매하기 페이지 이동
  const moveToSalePage = async (pageY) => {
    try {
      // 해당 프롬프트의 스크롤 위치 저장
      dispatch(setYScrollPosition(pageY));
      moveToPage(PATH.SALES_PROMPT_HOME, { cate_cd: 'CATE003' });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // AI 모델 리스트 조회
  const listAiModel = async () => {
    try {
      const { data } = await api.getAiModelByCate({ cate_cd: 'CATE003' });
      setAiModelList([{ ai_model_cd: 'all', ai_model_nm: '전체' }, ...data]);
    } catch (error) {
      console.error('AI 모델 리스트 조회 error', error);
    }
  };

  // 장르 리스트 조회
  const listGenre = async () => {
    try {
      const { data } = await api.fetchEqualCommGrpCodeByParent({ grp_cd: 'GENRE', parent_cd: 'CATE003' });
      setGenreList([{ cd: 'all', cd_nm: '전체' }, ...data]);
    } catch (error) {
      console.error('장르 리스트 조회 error', error);
    }
  };

  // 서브 장르 리스트 조회
  const listSubGenre = useCallback(async () => {
    try {
      if (marketplaceMusicList.query?.filter_genre === 'all') return setSubGenreList();
      const { data } = await api.fetchEqualCommGrpCodeByParent({ grp_cd: 'SUB_GENRE', parent_cd: marketplaceMusicList.query?.filter_genre });
      setSubGenreList([{ cd: 'all', cd_nm: '모두보기' }, ...data]);
    } catch (error) {
      console.error('서브 장르 리스트 조회 error', error);
    }
  }, [marketplaceMusicList.query?.filter_genre]);

  // 보관함 목록 가져오기
  const listSavedMusic = useCallback(async () => {
    try {
      if (!memKey) return;
      const { data } = await api.listMyMusic({ mem_key: memKey });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setsavedMusicPrptIds(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 추가
  const addMyMusic = useCallback(async (musics = []) => {
    try {
      if (!musics.length) return message.warning('곡을 선택해주세요.');
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        const requestMusics = musics?.map((music) => {
          return {
            mem_key: memKey,
            prpt_id: music?.prpt_id,
            prpt_title: music?.prpt_title,
            prpt_path: music?.music_org_link,
          };
        });
        const { data } = await api.addMyMusic(requestMusics);
        if (!data) return;
        await listSavedMusic();
        message.success('보관함에 저장되었습니다.');
      }
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 보관함 곡 삭제
  const deleteMyMusic = useCallback(async (musics = []) => {
    try {
      if (!musics.length) return message.warning('곡을 선택해주세요.');
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        const requestMusics = musics?.map((music) => {
          return {
            mem_key: memKey,
            prpt_id: music?.prpt_id,
          };
        });
        const { data } = await api.deleteMyMusic(requestMusics);
        if (!data) return;
        await listSavedMusic();
        message.success('보관함에서 삭제되었습니다.');
      }
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 목록 가져오기
  const listLikedMusic = useCallback(async () => {
    try {
      if (!memKey) return;
      const { data } = await api.getMyPromptList({ mem_key: memKey, keyword_tab: 'likeList', filter_cate: ['CATE003'] });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setlikedMusicPrptIds(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 곡 추가
  const addLikeMusic = useCallback(async (music) => {
    try {
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        const { data } = await api.increaseLikeCount({
          target_id: music?.prpt_id,
          like_mem_key: memKey,
          own_mem_key: music?.mem_key,
          like_div: 'P',
          fir_id: memKey,
        });
        if (!data) return;
        await listLikedMusic();
      }
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 좋아요 곡 삭제
  const deleteLikeMusic = useCallback(async (music) => {
    try {
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        const { data } = await api.increaseLikeCount({
          target_id: music?.prpt_id,
          like_mem_key: memKey,
          own_mem_key: music?.mem_key,
          like_div: 'P',
        });
        if (!data) return;
        await listLikedMusic();
      }
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 장바구니 목록 가져오기
  const listCartMusic = useCallback(async () => {
    try {
      if (!memKey) return;
      const { data } = await api.getCartList({ mem_key: memKey, gds_div: 'GDS003' });
      if (!data) return;
      const ids = data.map((music) => music.gds_key);
      setcartMusicPrptIds(ids);
      setcartMusicList(data);
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 장바구니 곡 추가
  const addCartMusic = useCallback(async (musics = []) => {
    try {
      if (!musics.length) return message.warning('곡을 선택해주세요.');
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        const requestMusics = musics?.map((music) => {
          return {
            mem_key: memKey,
            gds_key: music?.prpt_id,
            gds_nm: music?.prpt_title,
            gds_amt: music?.sale_amt,
            gds_url: music?.thum_path,
            gds_div: 'GDS003',
          };
        });
        const { data } = await api.addToCart(requestMusics);
        if (data?.returnStatus !== 'success') throw new Error(data.returnMessage);
        await listCartMusic();
        message.success('장바구니에 저장되었습니다.');
      }
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  // 장바구니 곡 삭제
  const deleteCartMusic = useCallback(
    async (music) => {
      try {
        if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
          // 음악 프롬프트 아이디로 매핑되는 장바구니 프롬프트 찾기
          const findCartList = cartMusicList?.filter((cart) => cart.gds_key === JSON.stringify(music.prpt_id));
          if (!findCartList.length) return;
          const { data } = await api.deleteCart({ mem_key: memKey, gds_seq: findCartList[0].gds_seq });
          if (!data || data.returnStatus !== 'success') return;
          await listCartMusic();
          message.success('장바구니에서 삭제되었습니다.');
        }
      } catch (error) {
        message.warning(error.message);
      }
    },
    [cartMusicList],
  );

  // 음악 리스트 가져오기
  const listMusic = useCallback(
    async (query) => {
      try {
        const mergedQuery = { ...marketplaceMusicList.query, ...query, filter_stat: '30' };
        const { data } = await api.getAllPromptList(mergedQuery);
        if (!data) return;
        if (query.offset) {
          dispatch(
            setMarketplaceMusicList({
              items: [...marketplaceMusicList.items, ...data.items],
              totalCount: data.total,
              currentCount: marketplaceMusicList.currentCount + data.items.length,
            }),
          );
        } else {
          dispatch(
            setMarketplaceMusicList({
              items: data.items,
              totalCount: data.total,
              currentCount: data.items.length,
            }),
          );
        }
        setCheckedMusicList([]);
        setTotalCheckedMusicList(0);
        dispatch(setLocationKey(locationKey));
      } catch (error) {
        message.warning(error.message);
      }
    },
    [marketplaceMusicList],
  );

  // 음악 플레이어 열기
  const openMusicPlayer = async (musics = []) => {
    try {
      if (!musics.length) return message.warning('곡을 선택해주세요.');
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
        // 플레이리스트에 곡 추가
        const requestMusics = musics?.map((music) => {
          return {
            mem_key: memKey,
            prpt_id: music?.prpt_id,
            prpt_title: music?.prpt_title,
            prpt_path: music?.music_org_link,
          };
        });
        await api.addPlaylistMusic(requestMusics);
        setCheckedMusicList([]);
        setTotalCheckedMusicList(0);
        if (width < 900) openModalMusicPlayer();
        else window.open(PATH.MUSIC_PLAYER, POPUP_MUSIC_PLAYER, 'width=375,height=720,popup=yes');
      }
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 스크롤 페이징
  const handleScroll = () => {
    const newQuery = { ...marketplaceMusicList.query, offset: marketplaceMusicList.query.offset + marketplaceMusicList.query.limit };
    dispatch(setQuery(newQuery));
    handleDebounce(() => listMusic(newQuery));
  };

  // 새로고침 이벤트 발생시
  const preventReload = () => {
    dispatch(clearMarketplaceMusicList());
    navigate(PATH.MUSIC_HOME, { replace: true });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', preventReload);

    // 뒤로가기로 렌더링 됐을때
    if (locationKey === marketplaceMusicList.locationKey) {
      window.scrollTo({ top: marketplaceMusicList.yScrollPosition, behavior: 'smooth' });
    } else {
      const newQuery = { ...INIT_STATE_MARKETPLACE_MUSICLIST.query, ...location?.state };
      dispatch(setQuery(newQuery));
      handleDebounce(() => listMusic(newQuery));
    }

    return () => {
      window.removeEventListener('beforeunload', preventReload);
    };
  }, []);

  useEffect(() => {
    listSavedMusic();
    listLikedMusic();
    listCartMusic();
    listAiModel();
    listGenre();
  }, []);

  useEffect(() => {
    listSubGenre();
  }, [marketplaceMusicList.query?.filter_genre]);

  return (
    <>
      <article id="musicWrapper" className="flexColCenter">
        <Banner bannerCd="BANNER007" bannerTp="Top" />

        <Filter
          isActive={isActiveFilter}
          toggle={filterToggle}
          filterParams={marketplaceMusicList.query}
          setFilterParams={changeFilterParams}
          sortList={subMainSortList}
          aiModelList={aiModelList}
          genreList={genreList}
        />

        <FilterMobile
          headerTitle="어떤 음악을 찾고 계신가요?"
          isActive={isActiveFilterMobile}
          toggle={filterToggleMobile}
          filterParams={marketplaceMusicList.query}
          setFilterParams={changeFilterParams}
          sortList={subMainSortList}
          aiModelList={aiModelList}
          genreList={genreList}
        />

        <div className="rsWrapper flexColCenter">
          <div className="flexRowBetween">
            <div className="content flexColCenter">
              <SearchSubMain
                defaultTitle="음악 리스트"
                placeholderSearch="음악을 검색해 주세요."
                subMainSortList={subMainSortList}
                aiModelList={aiModelList}
                genreList={genreList}
                subGenreList={subGenreList}
                marketplaceList={marketplaceMusicList}
                setFilterParams={changeFilterParams}
                filterToggleMobile={filterToggleMobile}
              />

              <div className="searchBox flexRowBetween">
                <SubFilterSubMain sortList={subMainSortList} subGenreList={subGenreList} marketplaceList={marketplaceMusicList} setFilterParams={changeFilterParams} />

                <ButtonGroupSubMain
                  isShareButton={validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: false })}
                  onShare={(e) => moveToSalePage(e.clientY)}
                  isListenButton={validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: false })}
                  onListen={() => openMusicPlayer(checkedMusicList)}
                  isDisabledListenButton={totalCheckedMusicList === 0}
                  // isKeepButton={true}
                  // onKeep={() => addMyMusic(checkedMusicList)}
                  // isCartButton={true}
                  // onCart={() => addCartMusic(checkedMusicList)}
                />
              </div>

              <InfiniteScroll
                dataLength={marketplaceMusicList.currentCount}
                next={handleScroll}
                hasMore={marketplaceMusicList.currentCount < marketplaceMusicList.totalCount}
                style={{ width: '100%' }}
                loader={
                  <>
                    <Skeleton active paragraph={{ rows: 4 }} />
                    <Skeleton active paragraph={{ rows: 4 }} />
                  </>
                }
              >
                <div className="listBox flexColCenter">
                  <div className="list tableHeader flexRowBetween">
                    <div onClick={(e) => e.stopPropagation()}>
                      <Checkbox className="check" checked={marketplaceMusicList?.currentCount === totalCheckedMusicList && totalCheckedMusicList > 0} onChange={(e) => onCheckAll(e.target.checked)} />
                    </div>
                    <div className="HeaderIndex">번호</div>
                    <div className="HeaderThumb"></div>
                    <div className="HeaderTitleAndUser">
                      <div className="HeaderTitle">제목</div>
                      <div className="HeaderUser" style={{ textAlign: 'center' }}>
                        엔지니어
                      </div>
                    </div>
                    <div className="HeaderLike">좋아요</div>
                    <div className="HeaderPlayCount">플레이수</div>
                    {/* <div className="HeaderReview">평점</div> */}
                    <div className="HeaderListen">듣기</div>
                  </div>
                  {marketplaceMusicList.totalCount > 0 ? (
                    <>
                      {marketplaceMusicList.items?.map((music, index) => (
                        <div key={index} className="list flexRowBetween" id={isChecked(music) ? 'active' : ''} onClick={() => onCheckOne(isChecked(music) ? false : true, music)}>
                          <div onClick={(e) => e.stopPropagation()}>
                            <Checkbox className="check" checked={isChecked(music)} onChange={(e) => onCheckOne(e.target.checked, music)} />
                          </div>
                          <div className="rankBox">
                            <h1>{index + 1}</h1>
                          </div>

                          <Tooltip
                            placement="right"
                            overlayClassName="prompt-detail-prev"
                            title={
                              <article id="engineWrapper" className="flexColCenter">
                                <div className="rsWrapper flexColCenter">
                                  <div className="flexRowStart listBox">
                                    <CardSquareMusic2
                                      data={music}
                                      openMusicPlayer={openMusicPlayer}
                                      moveToPromptDetailPage={(e) => moveToDetailPage(music, e.clientY)}
                                      moveToUserDetailPage={() => moveToPage(PATH.PROFILE_PAGE, { mem_email: music?.mem_email, mem_key: music?.mem_key })}
                                    />
                                  </div>
                                </div>
                              </article>
                            }
                          >
                            <img
                              className="thumb"
                              src={getDefaultImg('music', music)}
                              // onError={({ currentTarget }) => {
                              //   currentTarget.onError = null;
                              //   music.thum_path = '';
                              //   currentTarget.src = getDefaultImg('music', music);
                              // }}
                            />
                          </Tooltip>
                          {/* 제목 */}
                          <div className="flexRowCenter titleBox">
                            <div className="flexColStart title">
                              {music?.ai_model_nm && (
                                <div className="flexRowStart ">
                                  <p>{music?.ai_model_nm}</p>
                                </div>
                              )}
                              <h1
                                onClick={(e) => {
                                  e.stopPropagation();
                                  moveToDetailPage(music, e.clientY);
                                }}
                              >
                                {music?.prpt_title}
                              </h1>
                            </div>
                            {/* 엔지니어 */}
                            <div className="flexRowStart userBox">
                              <img src={music?.mem_img_path || IMAGES.DEFAULT_PROFILE} onError={(e) => handleImageError(e, null, IMAGES.DEFAULT_PROFILE)} />
                              <p
                                onClick={(e) => {
                                  e.stopPropagation();
                                  moveToPage(PATH.PROFILE_PAGE, { mem_email: music?.mem_email, mem_key: music?.mem_key });
                                }}
                              >
                                {music?.mem_nick}
                              </p>
                            </div>
                          </div>
                          {/* 좋아요 */}
                          <div
                            className="heart flexColCenter btn"
                            id={likedMusicPrptIds?.includes(music?.prpt_id) ? 'active' : ''}
                            onClick={(e) => {
                              e.stopPropagation();
                              likedMusicPrptIds?.includes(music?.prpt_id) ? deleteLikeMusic(music) : addLikeMusic(music);
                            }}
                          >
                            <img src={activeHeartIcon} className="activeIcon" />
                            <img src={heartIcon} />
                          </div>
                          {/* 조회수 */}
                          <div className="views flexRowEnd">
                            <p className="i-playHit">{music?.play_cnt}</p>
                          </div>
                          {/* 별점 */}
                          {/* <div className="flexRowCenter star">
                            <Rate value={music?.score_avg} />
                          </div> */}

                          {/* 듣기*/}
                          <div
                            className="flexColCenter btn play mobile mobile"
                            onClick={(e) => {
                              e.stopPropagation();
                              openMusicPlayer([music]);
                            }}
                          >
                            <img src={headsetIcon} />
                            <img src={activeHeadsetIcon} className="activeIcon" />
                          </div>

                          {/* 보관 */}
                          {/* <div
                            className="flexColCenter btn"
                            id={savedMusicPrptIds?.includes(music?.prpt_id) ? 'active' : ''}
                            onClick={(e) => {
                              e.stopPropagation();
                              savedMusicPrptIds?.includes(music?.prpt_id) ? deleteMyMusic([music]) : addMyMusic([music]);
                            }}
                          >
                            <img src={activeSaveIcon} className="activeIcon" />
                            <img src={saveIcon} />
                          </div> */}

                          {/* 장바구니 */}
                          {/* <div
                            className="flexColCenter btn"
                            id={cartMusicPrptIds?.includes(JSON.stringify(music?.prpt_id)) ? 'active' : ''}
                            onClick={(e) => {
                              e.stopPropagation();
                              cartMusicPrptIds?.includes(JSON.stringify(music?.prpt_id)) ? deleteCartMusic(music) : addCartMusic([music]);
                            }}
                          >
                            <img src={activeCartIcon} className="activeIcon" />
                            <img src={cartIcon} />
                          </div> */}

                          {/* <div className="flexColCenter btn" id={downloadedMusicList?.includes(music?.prpt_id) ? 'active' : ''}>
                            <img src={downloadIcon} />
                            <img src={activeDownloadIcon} className="activeIcon" />
                          </div> */}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="noListData">
                      <h1>조회 결과가 없습니다.</h1>
                    </div>
                  )}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>

        <div id="mobileFixedBox" className="flexRowBetween">
          <div className="flexColCenter select">
            <p>
              <span>{totalCheckedMusicList}</span>곡 선택
            </p>
          </div>
          <div className="flexRowCenter">
            <img src={resetIcon} onClick={() => window.location.reload()} />
            <img src={cartUpdateIcon} onClick={() => moveToSalePage(window.scrollY)} />
            <img src={headsetIcon} onClick={() => openMusicPlayer(checkedMusicList)} />
            {/* <img src={saveIcon} onClick={() => addMyMusic(checkedMusicList)} />
            <img src={cartIcon} onClick={() => addCartMusic(checkedMusicList)} /> */}
          </div>
        </div>

        <Banner bannerCd="BANNER008" bannerTp="Bottom" />
      </article>
      <Modal className="musicPlayerModal" open={isActiveMusicPlayer} footer={null} maskClosable={false} onCancel={closeModalMusicPlayer} getContainer={() => document.getElementById('root')}>
        <MusicPlayer isMobile={width < 900} />
      </Modal>
    </>
  );
};

export default MusicHome;
