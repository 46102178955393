import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Tabs from '@components/Tabs';
import { getLocalStorage } from '@utils/lib';
import ChatBase from '@pages/chat/chatBase';
import { message } from 'antd';
import { setCurrentRoom, clearMessages } from '@stores/chat.js';
import { useLocation, useNavigate } from 'react-router-dom';
import useWidth from '@hooks/useWidth';

const ChatInterface = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionChk = getLocalStorage('prptbk-token', 'mem_key');
  const width = useWidth();

  // 초기 탭
  const getInitialTab = useCallback(() => {
    const routeState = location.state?.selectedTab;
    if (routeState) {
      return routeState;
    }

    const storedTab = localStorage.getItem('selectedChatTab');
    if (storedTab) {
      return storedTab;
    }
    return 'userChat';
  }, [location.state?.selectedTab]);

  // 상태 설정
  const [initialTab, setInitialTab] = useState(getInitialTab);
  const [isTabReady, setIsTabReady] = useState(true);

  const tabs = [
    { id: 'userChat', label: '메시지', component: <ChatBase tabId="normal" /> },
    { id: 'systemChat', label: '알림', component: <ChatBase tabId="system" /> },
  ];

  const handleTabClick = (clickedTabId) => {
    if (!sessionChk) {
      message.error('로그인을 해주세요.');
      return false;
    }
    localStorage.setItem('selectedChatTab', clickedTabId);
    setInitialTab(clickedTabId);
    dispatch(setCurrentRoom(null));
    dispatch(clearMessages());

    navigate(location.pathname, {
      state: { selectedTab: clickedTabId },
      replace: true,
    });
    return true;
  };

  useEffect(() => {
    const newInitialTab = getInitialTab();

    if (newInitialTab !== initialTab) {
      setIsTabReady(false); // 준비 전 렌더링 중단
      setInitialTab(newInitialTab);
    }

    // location.state 정리
    if (location.state?.selectedTab) {
      navigate(location.pathname, { replace: true });
    }
  }, [location.state?.selectedTab, getInitialTab]);

  useEffect(() => {
    setIsTabReady(true);
  }, [initialTab]);

  useEffect(() => {
    if (location.state?.tabId === 'normal') {
      handleTabClick('userChat');
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state?.tabId]);

  return <>{isTabReady && <Tabs articleId="myMessage" tabs={tabs} onTabClick={(tabId) => handleTabClick(tabId)} initialTab={initialTab} />}</>;
};

export default ChatInterface;
