// store/iconSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shouldRefresh: false
}

export const iconSlice = createSlice({
    name: 'icon',
    initialState,
    reducers: {
        triggerIconRefresh: (state, action) => {
            state.shouldRefresh = action.payload;
        }
    }
});

export const { triggerIconRefresh } = iconSlice.actions;
export default iconSlice.reducer;