import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Layout, Row, Select, Typography } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import Table from '@components/Table';
import * as api from '@api/index';
import Loading from '@components/Loading';
import GridHeader from '@components/GridHeader';

const AdminAlarmHome = () => {
  const { Title } = Typography;
  const [loading, setLoading] = useState({});
  const isLoading = Object.values(loading).some((value) => value);

  const alarmGridRef = useRef();

  // 알림 관리 그리드 props
  const customGridProps = {
    fillColumnSizeMode: true, // 컬럼 사이즈 비율 계산 유무
    wordWrap: true,
  };

  // 알림 관리 그리드 레이아웃
  const columnLayout = [
    { dataField: 'alrt_cd', headerText: '알림코드', width: '20%', visible: false },
    {
      dataField: 'cate_nm',
      headerText: '카테고리',
      width: '20%',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    { dataField: 'alrt_div_cd', headerText: '알림구분코드', width: '20%', visible: false },
    {
      dataField: 'alrt_div_nm',
      headerText: '구분',
      width: '20%',
      editable: false,
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    { dataField: 'alrt_contents', headerText: '내용', width: '60%', style: 'left-custom-column', editable: false },
    {
      dataField: 'alrt_yn',
      headerText: '시스템 알림',
      width: '10%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N', 'X'],
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'email_yn',
      headerText: '이메일',
      width: '10%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N', 'X'],
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
    {
      dataField: 'sms_yn',
      headerText: 'SMS',
      width: '10%',
      headerStyle: 'required-custom-header',
      renderer: {
        type: 'DropDownListRenderer',
        list: ['Y', 'N', 'X'],
      },
      filter: {
        showIcon: true,
        displayFormatValues: true,
      },
    },
  ];

  // 알림 정보 조회
  const getAlarmInfo = async () => {
    try {
      setLoading((prev) => ({ ...prev, alarmLoad: true }));
      const response = await api.getAlarmInfo();
      alarmGridRef.current.setGridData(response.data);
    } catch (e) {
      console.log('조회 error', e);
      alert(e.message, 'error');
    } finally {
      setLoading((prev) => ({ ...prev, alarmLoad: false }));
    }
  };

  // 알림 정보 저장
  const saveAlarmInfo = async () => {
    if (!(await confirm('저장하시겠습니까?'))) return false;
    const saveItems = alarmGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);

    // validation
    if (saveItems.length < 1) {
      alert('저장할 데이터가 없습니다.');
      return;
    }
    const isValid = alarmGridRef.current.validateChangedGridData(['alrt_yn', 'email_yn', 'sms_yn'], '필수 필드는 반드시 값을 직접 입력해야 합니다.');
    if (!isValid) return;

    try {
      setLoading((prev) => ({ ...prev, alarmSaveLoad: true }));
      const response = await api.saveAlarmInfo(saveItems);
      alert(response.data.returnMessage, response.data.returnStatus == 'fail' ? 'error' : 'success');
    } catch (e) {
      console.log('저장 error', e);
      alert(e.message, 'error');
    } finally {
      setLoading((prev) => ({ ...prev, alarmSaveLoad: false }));
      getAlarmInfo(); // 알림 정보 조회
    }
  };

  useEffect(() => {
    getAlarmInfo(); // 알림 정보 조회
  }, []);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout>
        <Content className='l-content'>
          <GridHeader>
            {{
              searchArea: <Title level={5}>알림 항목</Title>,
              buttonArea: (
                <>
                  <Button className='btn-pink' type="primary" onClick={saveAlarmInfo}>
                    저장
                  </Button>
                </>
              ),
            }}
          </GridHeader>

          <Table ref={alarmGridRef} columnLayout={columnLayout} customGridProps={customGridProps} />
        </Content>
      </Layout>
    </>
  );
};

export default AdminAlarmHome;
