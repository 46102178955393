import React from 'react';
// icon
import starIcon from '@assets/images/common/star_icon.png';
import userIcon3 from '@assets/images/common/user_icon_3.png';
// lib
import { koKRFormat } from '@utils/lib';
import cartThumbnail from '@assets/images/common/cart_thumbanil.png';
import closeIcon from '@assets/images/common/icon_close.svg';
import logo from '@assets/images/common/logo.png';

const CartCardSquare = ({ cart, deleteCart, onClick, selected }) => {
  return (
    <div
      className="cart flexColCenter"
      style={{
        ...(selected && {
          padding: ' 4px  ',
          background: ' #ec15ff',
          // backgroundOrigin: ' borderBox',
          // backgroundClip: 'contentBox, borderBox',
          // borderImageSlice: '1',
        }),
        color: ' #fff;',
        padding: '4px',
      }}
      onClick={() => onClick(cart)}
    >
      <div className="thumbnailBox">
        <img src={cart.thum_path || cartThumbnail} alt="thumbnail" />

        <div className="flexColBetween">
          <div className="top flexRowBetween ">
            <div className="flexRowCenter">
              <img src={cart.icon_path || logo} alt="image" />
              <h1>{cart.ai_model_nm}</h1>
            </div>

            <div className="close flexColCenter" onClick={(e) => deleteCart(e, cart.gds_seq)}>
              <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#4fbeijeiua)">
                  <path
                    d="M9.43 9.095a.237.237 0 0 1-.335.336L5 5.335.905 9.431a.237.237 0 1 1-.336-.336L4.665 5 .569.905A.237.237 0 0 1 .905.569L5 4.665 9.095.569a.237.237 0 0 1 .336.336L5.335 5l4.096 4.095z"
                    fill="#000"
                    stroke="#000"
                  />
                </g>
                <defs>
                  <clipPath id="4fbeijeiua">
                    <path fill="#fff" transform="translate(.5 .5)" d="M0 0h9v9H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          <h1>{cart.gds_nm}</h1>
        </div>
      </div>
      <div className="bottom flexRowBetween">
        <img src={cart.mem_img_path || userIcon3} alt="image" />
        <div className="flexColStart">
          <h1>{cart.seller_nick}</h1>

          <div className="priceBox flexRowBetween">
            {/* <div className="flexRowCenter">
              {[...Array(cart?.score_avg)].map((_, index) => (
                <img key={index} src={starIcon} alt="star_icon" />
              ))}
            </div> */}

            <p>{`₩ ${cart.gds_amt !== 0 ? koKRFormat(cart.gds_amt) : '무료'}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartCardSquare;
