import React from 'react';
import Tabs from '@components/Tabs';
import CommunityHome from '@pages/boards/Home';
import TotalBoardList from '@pages/boards/TotalBoardList';
import { getLocalStorage } from '@utils/lib';
import { message } from 'antd';
// lib
import { validateSession, isAdmin } from '@utils/lib';
// const
import { AUTH_LOGIN, AUTH_BEGINNER } from '@utils/constants';
import Banner from '@components/slider/Banner';

const CommunityPage = () => {
  const tabs = [
    { id: 'boardHome', label: '홈', component: <CommunityHome /> },
/*
    { id: 'boardSearch', label: '통합 검색', component: <TotalBoardList /> },
*/
  ];
  const sessionChk = getLocalStorage('prptbk-token', 'mem_key');

  const handleTabClick = (tabId) => {
    if (tabId === 'boardSearch') {
      if (validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true }) != true) {
        return false;
      }

      /* message.error('로그인을 해주세요.');
      return false; */
    }
    return true;
  };

  return (
    <>
      <Banner bannerCd="BANNER016" bannerTp="Top" />
      <Tabs articleId="depth2" tabs={tabs} onTabClick={handleTabClick} />
      <Banner bannerCd="BANNER017" bannerTp="Bottom" />
    </>
  );
};

export default CommunityPage;
