import React, { useCallback, useEffect, useState } from 'react';
import {Modal, Select, Input, Checkbox, Form, message, Button, Space} from 'antd';
import PasswordCheck from '@components/slider/PasswordCheck';
import * as api from '@api/index';
import { getLocalStorage, makeSelectBoxList } from '@utils/lib';
import { clearAuth } from '@stores/auth';
import * as PATH from '@routes/pathName';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

const AccountDelModal = ({ isOpen, onClose }) => {
  const memEmail = getLocalStorage('prptbk-token', 'mem_email');

  const [form] = Form.useForm();
  const [cnl_rsn, setCnlRsn] = useState([]);
  const [isPwCert, setIsPwCert] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [isPwVisible, setIsPwVisible] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    setIsPwCert(false);
    setAgreed(false);
    form.resetFields(['reason', 'reasonDetail']);
  }, [isOpen]);

  const handleCancel = () => {
    setIsPwVisible(false);
  };

  const handlePwCheckAuth = (checkModal) => {
    setIsPwVisible(!checkModal);
    setIsPwCert(checkModal);
  };

  const handleSubmit = async () => {
    try {
      if (!agreed || !isPwCert) {
        message.error("탈퇴 양식을 작성해주세요.");
        return false;
      };

      const values = form.getFieldsValue();

      if (!values.reason) {
        message.error("프롬프트 뱅크를 떠나는 이유를 선택해주세요.");
        return false;
      }

      if (!values.reasonDetail || values.reasonDetail.trim() === "") {
        message.error("상세 사유를 입력해주세요.");
        return false;
      }
      await form.validateFields();

      const params = {
        mem_email: memEmail,
        self_cnl_rsn_cd: values.reason,
        self_cnl_rsn: values.reasonDetail,
        self_cnl_rsn_areed: agreed ? 'Y' : 'N',
      };
      /*
      console.log('=== 회원 탈퇴 제출 데이터 ===');
      console.log('선택된 탈퇴 사유:', values.reason);
      console.log('상세 사유:', values.reasonDetail);
      console.log('약관 동의 여부:', agreed);
      console.log('비밀번호 인증 여부:', isPwCert);
      console.log('최종 제출 데이터:', params);
      */

      api.updateMemberLeave(params);

      message.success('회원 탈퇴 요청이 처리되었습니다.');
      onClose();
      form.resetFields();
      setAgreed(false);
      await logout();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const fetchCommonCode = useCallback(async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'WTR_RSN' });
      setCnlRsn(makeSelectBoxList(response?.data, 'cd_desc', 'cd'));
    } catch (error) {
      message.warning('공통코드 조회 중 오류가 발생했습니다.');
    }
  }, []);

  const logout = async () => {
    try {
      const { data } = await api.logout();
      console.log(data);
    } catch (error) {
      console.log(error.message);
    }
    navigate(PATH.LOGIN);
  };

  useEffect(() => {
    fetchCommonCode();
  }, []);

  return (
    <Modal
      className="p20"
      title="회원 탈퇴"
      open={isOpen}
      onCancel={onClose}
      width={800}
      footer={[
        <Button id="purpleBtn" key="submit" onClick={handleSubmit} /*disabled={!agreed || !isPwCert}*/>
          회원 탈퇴 요청합니다.
        </Button>,
        <Button id="logoutBtn" key="" onClick={onClose}>
          취소
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" requiredMark={true}>
        <div className="inputArea">
          <Form.Item name="reason" label="프롬프트 뱅크를 떠나는 이유를 알려주세요." rules={[{ required: true, message: '탈퇴 사유를 선택해주세요.' }]}>
            <Select placeholder="사유 선택" options={cnl_rsn}></Select>
          </Form.Item>

          <Form.Item name="reasonDetail" label="상세 사유" rules={[{ required: true, message: '상세 사유를 입력해주세요.' }]}>
            <TextArea rows={4} placeholder="탈퇴 사유를 자세히 적어주세요." />
          </Form.Item>

          <Form.Item
            name="password"
            label="비밀번호 확인"
            rules={[
              {
                required: true,
                validator: async (_, value) => {
                  if (!isPwCert) {
                    throw new Error('비밀번호를 인증해주세요.');
                  }
                },
              },
            ]}
          >
            <PasswordCheck isOpen={isPwVisible} onClose={handleCancel} onAuthenticate={handlePwCheckAuth} />
            <Button className="btn-dark" onClick={() => setIsPwVisible(true)} disabled={isPwCert}>
              {isPwCert ? '확인 완료' : '비밀번호 확인'}
            </Button>
          </Form.Item>
        </div>
        <div className="agreeArea">
          <h2>안내사항</h2>
          <div className="cautionBlue bg-gray-50 p-4 rounded-lg text-sm space-y-2 mb-4" id="intro_info">
            <h3>* 현재 사용중인 계정 정보는 회원 탈퇴 후 복구가 불가능합니다.</h3>
            <div className="box">
              <p>• 현재 사용중인 계정 정보는 회원 탈퇴 후 복구가 불가능합니다.</p>
              <p>• 진행 중인 거래건이 있거나 패널티 조치 중인 경우 탈퇴처리가 불가능합니다.</p>
              <p>• 탈퇴 후 회원님의 정보는 전자상거래 소비자 보호법에 의거한 주식회사 프롬프트뱅크의 개인정보처리방침에 따라 관리됩니다.</p>
              <p>• 현재 보유 중인 쿠폰 및 혜택은 모두 자동 소멸되며, 탈퇴 후 재가입하더라도 이미 소멸되었기 때문에 양도되지 않습니다.</p>
              <p>• 구매 후기 및 댓글은 탈퇴 시 자동 삭제되지 않습니다. 탈퇴 후에는 계정 정보가 삭제되어 본인 확인이 불가하므로, 탈퇴 신청 전에 삭제하시길 바랍니다.</p>
              <p>• 판매 수익금이 있을 경우, 반드시 정산이 완료된 후 탈퇴하시길 바랍니다. 미정산 거래내역이 있음에도 탈퇴할 경우 모든 책임은 회원에게 있습니다.</p>
            </div>
          </div>
        </div>

        <div className="termCenter">
          <Form.Item name="agreed">
            <Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)}>
              안내 사항을 모두 확인하였으며, 이에 동의합니다.
            </Checkbox>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default AccountDelModal;
