import React, { useEffect, useState, useRef } from 'react';
import { Tree, Layout, Input, Modal, message } from 'antd';
import * as api from '@api/index';
import useWidth from '@hooks/useWidth';

const { DirectoryTree } = Tree;
const { Content, Sider } = Layout;

const LyricsStep = (props) => {
  const createBuilderInfo = props.createBuilderInfo;
  const lyricsOptions = props.lyricsOptions;
  const lyricsLibraryOptions = props.lyricsLibraryOptions;
  const lyricsBuilderPrompt = props.lyricsBuilderPrompt;
  const maxTextLength = lyricsBuilderPrompt[0].max_txt;

  const width = useWidth();
  const { TextArea } = Input;

  const [treeData, setTreeData] = useState([]);
  const [childNodes, setChildNodes] = useState([]); // 클릭한 노드의 자식 노드
  const [selectedLyricsText, setSelectedLyricsText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [soundEffectList, setSoundEffectList] = useState([]);
  const [selectedLyricsNode, setSelectedLyricsNode] = useState(null);
  const [selectedSoundEffectList, setSelectedSoundEffectList] = useState([]);
  const [lyricsText, setLyricsText] = useState(null);
  const selectedStepInfo = useRef({});
  const addCount = useRef(1);
  const [selectedLyricsGroupInfo, setSelectedLyricsGroupInfo] = useState(null);

  const [selectedButton, setSelectedButton] = useState(null);

  const lastClickTime = useRef(0);

  const lyricsTreeData = (node) => {
    // 새로운 객체 생성
    const newNode = {
      ...node,
      title: node.ko_nm,
      key: node.parent_seq + '-' + node.el_id,
    };

    if (node.children && node.children.length > 0) {
      const filteredChildren = node.children.map((childNode) => lyricsTreeData(childNode)); // 재귀적으로 자식 처리

      newNode.children = filteredChildren;
      newNode.isLeaf = filteredChildren.length === 0; // 자식이 없으면 리프 노드로 설정
    } else {
      newNode.isLeaf = true;
      newNode.children = [];
    }

    return newNode;
  };

  useEffect(() => {
    const updatedTreeData = props.lyricsOptions.map((node) => ({ ...node, key: node.el_id, title: node.ko_nm }));
    setTreeData(updatedTreeData);
    setSelectedLyricsText('');
    setSelectedSoundEffectList([]);
    setLyricsText(null);
  }, [props.stepData]);

  const handleSelect = (_, { node }) => {
    handleClearSoundEffectList();
    const children = props.stepData.find((parent) => parent.el_id === node.key)?.children || [];
    setChildNodes(children);
    setSelectedLyricsText('');
    selectedStepInfo.current = lyricsOptions.find((item) => item.el_id === node.key);
  };

  const handleChildClick = (ko_nm, en_nm, el_id) => {
    setSelectedButton(el_id);
    handleClearSoundEffectList();
    if (createBuilderInfo.langType === 'KO') {
      setSelectedLyricsText(ko_nm);
    } else {
      setSelectedLyricsText(en_nm);
    }
  };

  const createText = (lyricsText) => {
    let soundEffectsText = '';

    if (selectedSoundEffectList.length > 0) {
      let groupItems = [];
      let currentParentElId = null;

      const processGroup = (effects, option) => {
        if (effects.length === 0) {
          return false;
        }
        const options = lyricsLibraryOptions[0] || {};
        const { prefix = '', pre_sep = '', suf_sep = '', suffix = '' } = {
          prefix: options.prefix ?? '',
          pre_sep: options.pre_sep ?? '',
          suf_sep: options.suf_sep ?? '',
          suffix: options.suffix ?? ''
        };

        soundEffectsText += prefix;
        soundEffectsText += (effects.map((effect) => {
          return pre_sep + (createBuilderInfo.langType === 'KO' ? effect.ko_nm : effect.en_nm) + suf_sep;
        })).join(' ');
        soundEffectsText += suffix;
      };

      selectedSoundEffectList.forEach((item, index) => {
        const nextItem = selectedSoundEffectList[index + 1];
        const parentElId = item.parent_el_id;

        if (currentParentElId !== parentElId) {
          if (currentParentElId !== null) {
            processGroup(groupItems, item);
          }
          currentParentElId = parentElId;
          groupItems = [];
        }

        groupItems.push(item);

        if (!nextItem || nextItem.parent_el_id !== parentElId) {
          processGroup(groupItems, item);
          groupItems = [];
        }
      });
    }

    let texts = `${selectedStepInfo.current.prefix ? selectedStepInfo.current.prefix : ''}`;
    texts += `${selectedStepInfo.current.pre_sep ? selectedStepInfo.current.pre_sep : ''}`;
    texts += `${selectedLyricsText ? selectedLyricsText : ''}`;
    texts += `${selectedStepInfo.current.suf_sep ? selectedStepInfo.current.suf_sep : ''}`;
    texts += `${selectedStepInfo.current.suffix ? selectedStepInfo.current.suffix : ''}`;
    texts += `${soundEffectsText ? soundEffectsText : ''}`;
    texts += `${lyricsText}`;
    return texts.replaceAll(/\\n/g, '\n');
  };

  const onSubmit = () => {
    if (selectedLyricsText === '') {
      message.error('타이틀을 선택해주세요.');
      return false;
    }
    if (!lyricsText) {
      message.error('가사를 입력해주세요.');
      return false;
    }

    const index = props.lyricsOptions.findIndex((item) => item.el_id === selectedStepInfo.current.el_id);

    const params = {
      text: createText(lyricsText),
      addCount: addCount.current,
      type: index === 0 ? 'first' : index === props.stepData.length - 1 ? 'last' : 'middle',
    };
    props.handleSubmitLyrics(params); // 가사 입력 전달
    setLyricsText(null);
    setSelectedSoundEffectList([]);
    addCount.current++;
  };

  const openModal = async () => {
    setIsModalOpen(true);
    const params = {
      el_id: props.lyricsId,
    };
    const response = await api.fetchLibararyElementsByLyricsBuilderId(params);

    const updatedLyricsList = JSON.parse(response.data.treeData)
      .map((node) => lyricsTreeData(node))
      .filter((node) => node !== null);
    setSoundEffectList(updatedLyricsList);
  };

  const handleDeleteSoundEffect = (soundEffect) => {
    setSelectedSoundEffectList((prev) => prev.filter((item) => !(item.el_id === soundEffect.el_id && item.parent_seq === soundEffect.parent_seq)));
  };

  const handleClearSoundEffectList = () => {
    setSelectedSoundEffectList([]);
  };

  const findTopLevelParent = (data, targetElId) => {
    for (const item of data) {
      // 현재 객체나 자식들 중에서 찾는다면 해당 최상위 객체 반환
      if (findInChildren(item, targetElId)) {
        return item;
      }
    }
    return null; // 찾는 데이터가 없으면 null 반환
  };

  const findInChildren = (node, targetElId) => {
    // 현재 객체가 targetElId를 가진다면 true 반환
    if (node.el_id === targetElId) {
      return true;
    }
    // children 배열을 순회하며 재귀적으로 탐색
    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        if (findInChildren(child, targetElId)) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSelectLyricsTree = (selectedKey, { node }) => {
    setSelectedLyricsNode(node);
    if (node.parent_el_id !== 0) {
      const rootNode = findTopLevelParent(soundEffectList[0].children, node.el_id);
      const selectedItem = soundEffectList[0].children.find((item) => item.el_id === rootNode.el_id);
      setSelectedLyricsGroupInfo({
        parent_el_id: selectedItem.el_id,
        prefix: selectedItem.prefix,
        suffix: selectedItem.suffix,
        pre_sep: selectedItem.pre_sep,
        suf_sep: selectedItem.suf_sep,
        ko_nm: node.ko_nm,
        en_nm: node.en_nm,
        el_id: node.el_id,
      });
    }

    const currentTime = Date.now();
    const timeDiff = currentTime - lastClickTime.current;

    if (timeDiff < 300 && selectedLyricsNode.el_div === '01') {
      const checkItem = selectedSoundEffectList.find((item) => item.el_id === selectedLyricsNode.el_id);
      if (checkItem) {
        message.error('이미 등록된 SoundEffect입니다.');
        return false;
      }
      setSelectedSoundEffectList((prev) => [...prev, selectedLyricsGroupInfo]);
    }

    lastClickTime.current = currentTime;
  };

  const renderSelectedTreeNodes = (nodeData) => {
    const isSelected = selectedSoundEffectList.some((item) => item.el_id === nodeData.el_id);
    return (
      <span
        style={{
          backgroundColor: isSelected ? '#e74c3c' : 'transparent',
          color: isSelected ? 'white' : 'inherit',
          padding: '2px 8px',
          borderRadius: '4px',
        }}
      >
        {nodeData.title}
      </span>
    );
  };

  return (
    <Layout
      style={{
        background: '#ffffff',
      }}
    >
      <Sider
        width={width < 700 ? `100%` : 200}
        style={{
          background: '#ffffff',
        }}
      >
        <DirectoryTree treeData={treeData} onSelect={handleSelect} />
      </Sider>
      <Layout
        style={{
          padding: width < 700 ? `20px 0 0` : '0 ',
        }}
      >
        <Content
          style={{
            padding: 0,
            margin: 0,
            minHeight: 280,
          }}
        >
          {childNodes.length > 0 ? (
            <div className="tabBox ">
              {childNodes.map((item) => (
                <button key={item.el_id} id="blueBtn" onClick={() => handleChildClick(item.ko_nm, item.en_nm, item.el_id)} className={selectedButton === item.el_id ? 'selectedButton' : ''}>
                  {createBuilderInfo.langType === 'KO' ? item.ko_nm : item.en_nm}
                </button>
              ))}
            </div>
          ) : null}

          <div className="inputBox flexColStart">
            <div className="flexRowBetween">
              <h1>
                {selectedLyricsText ? (
                  <p>
                    {selectedStepInfo.current.pre_sep}
                    {selectedLyricsText}
                    {selectedStepInfo.current.suf_sep}
                  </p>
                ) : null}
              </h1>

              <button id="whiteBtn" onClick={openModal}>
                Sound Effect 추가 +
              </button>
            </div>
            {/* 선택 이팩트 목록 */}
            <div className="selectList">
              {selectedSoundEffectList.map((item) => (
                <button className="btn-pinkGray" key={`${item.el_id}-${item.parent_seq}`}>
                  {createBuilderInfo.langType === 'KO' ? item?.ko_nm : item?.en_nm}
                  <span className="btn-close" onClick={() => handleDeleteSoundEffect(item)}></span>
                </button>
              ))}
            </div>

            <div className="textAreaBox flexColEnd">
              <TextArea
                className="textArea"
                style={{ resize: `none`, height: `200px` }}
                placeholder="가사 텍스트를 입력하세요."
                value={lyricsText}
                onChange={(e) => {
                  if (e.target.value.length > maxTextLength) {
                    message.error('가사는 ' + maxTextLength + '자까지 입력 가능합니다.');
                    return false;
                  } else {
                    setLyricsText(e.target.value);
                  }
                }}
              />
              <button id="darkGrey3" onClick={onSubmit}>
                입력
              </button>
            </div>
          </div>
        </Content>
      </Layout>
      <Modal className="p20" open={isModalOpen} footer={null} closable={false} width={width < 700 ? `100%` : `500px`}>
        <div>
          <div className="top flexRowBetween">
            <h1 className="title">Sound Effect</h1>

            <div className="svg" onClick={() => setIsModalOpen(false)}>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#m0y82tzh7a)">
                  <path
                    d="M23.815 23.421a.632.632 0 1 1-.894.894L12 13.394 1.079 24.314a.632.632 0 0 1-.894-.893L11.106 12.5.186 1.579a.632.632 0 0 1 .893-.894L12 11.606 22.921.686a.63.63 0 1 1 .894.893L12.894 12.5l10.92 10.921z"
                    fill="#000"
                  />
                </g>
                <defs>
                  <clipPath id="m0y82tzh7a">
                    <path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="soundEffectModal flexColCenter">
            <div className="scrollBox scroll flexColCenter">
              <div className="flexColCenter box">
                <Tree
                  defaultExpandAll
                  treeData={soundEffectList}
                  showIcon={width < 700 ? false : true}
                  onSelect={handleSelectLyricsTree}
                  style={{ height: `auto` }}
                  titleRender={renderSelectedTreeNodes}
                  expandAction="doubleClick"
                />
              </div>
            </div>

            <div className="btnBox flexRowCenter">
              <button className="btn-line" id="logoutBtn" onClick={() => setIsModalOpen(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default LyricsStep;
