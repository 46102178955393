import { createSlice } from '@reduxjs/toolkit';

export const INIT_STATE_PROMPT_REVIEW_LIST = {
  query: {
    keyword_type: 'mem_email',
    keyword_text: '',
    status: '10',
    startDt: '',
    endDt: '',
    offset: 0,
    limit: 20,
  },
  items: [],
  totalCount: 0,
  currentCount: 0,
  locationKey: '',
  yScrollPosition: 0,
};

export const promptReviewListSlice = createSlice({
  name: 'promptReviewList',
  initialState: INIT_STATE_PROMPT_REVIEW_LIST,
  reducers: {
    clearPromptReviewList: () => INIT_STATE_PROMPT_REVIEW_LIST,
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setPromptReviewList: (state, action) => {
      state.items = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.currentCount = action.payload.currentCount;
    },
    setLocationKey: (state, action) => {
      state.locationKey = action.payload;
    },
    setYScrollPosition: (state, action) => {
      state.yScrollPosition = action.payload;
    },
  },
});

export const { clearPromptReviewList, setQuery, setPromptReviewList, setLocationKey, setYScrollPosition } = promptReviewListSlice.actions;

export default promptReviewListSlice.reducer;
