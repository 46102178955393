import React from 'react';

const SubFilterSubMain = ({ sortList, subGenreList, marketplaceList, setFilterParams }) => {
  return (
    <div className="flexRowCenter" id="subFilter">
      <div className="tabBox flexRowCenter">
        {sortList?.map((sort, index) => (
          <div key={index} className="tab flexColCenter" id={marketplaceList.query?.filter_sort === sort?.value ? 'active' : ''} onClick={() => setFilterParams('filter_sort', sort?.value)}>
            {sort?.label}
          </div>
        ))}
      </div>

      {marketplaceList.query?.filter_genre !== 'all' && (
        <div className="flexRowCenter">
          {subGenreList?.map((subGenre, index) => (
            <div key={index} className={marketplaceList.query?.filter_sub_genre === subGenre?.cd ? 'active' : ''} id="pinkBtn2" onClick={() => setFilterParams('filter_sub_genre', subGenre?.cd)}>
              {subGenre?.cd_nm}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubFilterSubMain;
