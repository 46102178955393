import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Checkbox, Select, message, Affix, Skeleton, Dropdown } from 'antd';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { useNavigate, useLocation } from 'react-router-dom';
// icon
import { MoreOutlined } from '@ant-design/icons';
import saveIcon from '@assets/images/common/save_icon.png';
import cartIcon from '@assets/images/common/cart_icon.png';
import heartIcon from '@assets/images/common/heart_icon.png';
import activeHeartIcon from '@assets/images/common/active_heart_icon.png';
import activeSaveIcon from '@assets/images/common/active_save_icon.png';
import activeCartIcon from '@assets/images/common/active_cart_icon.png';
import downloadIcon from '@assets/images/common/download_icon.png';
import activeDownloadIcon from '@assets/images/common/active_download_icon.png';
import trashIcon from '@assets/images/common/prompt_trash_icon.png';
import activeTrashIcon from '@assets/images/common/active_propmt_trash_icon.png';
import randomIcon from '@assets/images/common/random_icon.svg';
import randomOffIcon from '@assets/images/common/random_off_icon.svg';
import prevArrowIcon from '@assets/images/common/prev_arrow_icon.png';
import playIcon3 from '@assets/images/common/play_icon3.png';
import playingIcon from '@assets/images/common/playing_icon.svg';
import nextArrowIcon from '@assets/images/common/next_arrow_icon.png';
import repeatIcon from '@assets/images/common/repeat_icon.svg';
import repeatOffIcon from '@assets/images/common/repeat_off_icon.svg';
import greyArrowIcon from '@assets/images/common/grey_arrow_right.png';
import activeHeadsetIcon from '@assets/images/common/active_headset_icon.png';
// component
import ReceiptModal from '@components/payment/ReceiptModal';
import LyricsInfoModal from '@components/LyricsInfo';
// lib
import { useDebounce, getLocalStorage, makePaymentUUID, validateSession, isAdmin, isPaidUser } from '@utils/lib';
// const
import { AUTH_LOGIN, innopayResultCode, AUTH_BASIC } from '@utils/constants';
// api
import * as api from '@api/index';
// path
import * as PATH from '@routes/pathName';

const tabList = [
  { label: '플레이리스트', value: 1 },
  { label: '마이뮤직', value: 2 },
];

const sortList = [
  { label: '최신순', value: 1, sort_key: 'fir_dt', sort_value: 'desc' },
  { label: '오래된순', value: 2, sort_key: 'fir_dt', sort_value: 'asc' },
];

const INIT_STATE_SORT = { label: '최신순', value: 1, sort_key: 'fir_dt', sort_value: 'desc' };

const innopayMid = process.env.REACT_APP_INNOPAY_MID;
const innopayLicenseKey = process.env.REACT_APP_INNOPAY_LICENSE_KEY;

const PlayerMusic = ({ isMobile }) => {
  // 순수 audio 태그를 컨트롤하기 위해 useRef 사용
  const playerRef = useRef();
  // 플레이리스트/마이뮤직 리스트
  const [musicList, setMusicList] = useState([]);
  // 플레이리스트/마이뮤직 리스트 총 갯수
  const [totalMusicList, setTotalMusicList] = useState(0);
  // 보관함 리스트: prpt_id만 있음
  const [savedMusicList, setSavedMusicList] = useState([]);
  // 좋아요 리스트: prpt_id만 있음
  const [likedMusicList, setLikedMusicList] = useState([]);
  // 체크된 음악 리스트
  const [checkedMusicList, setCheckedMusicList] = useState([]);
  // 체크된 음악 리스트 총 갯수
  const [totalCheckedMusicList, setTotalCheckedMusicList] = useState(0);
  // 현재 재생되는 음악의 item
  const [selectedMusicItem, setSelectedMusicItem] = useState();
  // 현재 재생되는 음악의 index
  const [selectedMusicIndex, setSelectedMusicIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  // 반복 여부
  const [isLoop, setIsLoop] = useState(true);
  // 셔플 여부
  const [isShuffle, setIsShuffle] = useState(true);
  const [sortParams, setSortParams] = useState(INIT_STATE_SORT);
  // 결제 모달창
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  // 결제금액
  const [receiptAmount, setReceiptAmount] = useState(null);
  // 결제정보
  const payInfo = useRef({});
  // 상품정보
  const gdsInfo = useRef({});
  const navigate = useNavigate();
  const location = useLocation();
  // 세션정보
  const memKey = getLocalStorage('prptbk-token', 'mem_key') || '';
  const memEmail = getLocalStorage('prptbk-token', 'mem_email') || '';
  const memNick = getLocalStorage('prptbk-token', 'mem_nick') || '';
  const memTel = getLocalStorage('prptbk-token', 'mem_tel') || '';
  const memAuth = getLocalStorage('prptbk-token', 'mem_auth') || '';
  const planCd = getLocalStorage('prptbk-token', 'plan_cd') || '';
  // 가사정보 모달창
  const [isLyricsInfoModalVisible, setIsLyricsInfoModalVisible] = useState(false);
  // 선택된 가사정보
  const [selectedLyricsInfo, setSelectedLyricsInfo] = useState();
  // 첫번째 API 호출 여부
  const [isFirstCall, setIsFirstCall] = useState(true);

  // 디바운싱 핸들러
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  // 선택한 메뉴로 이동
  const moveToPage = (path, state) => {
    navigate(path, state && { state: state });
  };

  // 프롬프트 상세페이지 이동
  const moveToDetailPage = async (music) => {
    try {
      // 해당 프롬프트 조회수 카운트업
      await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'V' });
      moveToPage(PATH.PROMPT_DETAIL, { prpt_id: music?.prpt_id });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 다운로드
  const onDownload = async (music) => {
    try {
      // 유료회원 이상만 다운로드 가능 (관리자 제외)
      if (!isAdmin(memAuth) && !validateSession({ authType: AUTH_BASIC, isCallbackConfirm: true })) {
        return;
      }

      if (!music?.file_dwn_path) return message.warning('파일이 존재하지 않습니다.');

      const response = await fetch(music?.file_dwn_path);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = music?.file_org_nm; // 다운로드될 파일 이름
      link.click();
      // 해당 프롬프트 다운로드수 카운트업
      await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'D' });
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 가사정보 모달창 열기
  const openLyricsInfoModal = (music) => {
    setSelectedLyricsInfo(music);
    setIsLyricsInfoModalVisible(true);
  };

  // 가사정보 모달창 닫기
  const closeLyricsInfoModal = () => {
    setIsLyricsInfoModalVisible(false);
  };

  // 결제 모달창 닫기
  const closeReceiptModal = () => {
    setIsReceiptModalVisible(false);
  };

  // 결제 모달창 열기
  const openReceiptModal = async (music) => {
    gdsInfo.current = {
      mem_key: memKey,
      gds_div: 'GDS003', //프롬프트
      gds_key: music?.prpt_id,
      gds_nm: music?.prpt_title,
      gds_amt: music?.sale_amt,
      gds_qty: 1,
    };

    payInfo.current = {
      mem_key: memKey,
      gds_nm: music?.prpt_title,
      inout_tp: 'OUT',
      inout_div: '01',
      pay_amt: music?.sale_amt,
      pay_meth: '',
      gdsList: [gdsInfo?.current],
      pay_no: makePaymentUUID(memKey),
    };

    setReceiptAmount(music?.sale_amt);

    if (music?.sale_amt === 0) {
      if (await window.confirm('구매하시겠습니까?')) {
        savePayInfo();
      }
    } else {
      setIsReceiptModalVisible(true);
    }
  };

  // 바로구매
  const handleOpenPayment = async (paymentType) => {
    // 로그인 체크
    if (!validateSession({ authType: AUTH_LOGIN, isCallbackConfirm: true })) {
      return;
    }

    payInfo.current = {
      ...payInfo.current,
      pay_meth: paymentType,
    };

    if (memTel) {
      window.innopay_result = (data) => {
        if (data.ResultCode === '3001') {
          savePayInfo();
        } else {
          const resultMessage = innopayResultCode.find((item) => item.key === data.ResultCode);
          message.error('에러가 발생했습니다.\n에러사유 : ' + resultMessage);
          return;
        }
      };

      if (window.innopay && window.innopay.goPay) {
        window.innopay.goPay({
          PayMethod: paymentType, // 결제수단
          MID: innopayMid, // 가맹점 MID
          MerchantKey: innopayLicenseKey,
          Amt: payInfo.current.pay_amt + '', // 결제금액
          GoodsName: payInfo.current.gds_nm, // 상품명
          BuyerName: memNick, // 고객닉네임
          BuyerTel: memTel, // 고객 전화번호
          BuyerEmail: memEmail, // 고객 이메일
          ResultYN: 'N', // 결제 결과창 출력 여부
          Moid: '' + payInfo.current.pay_no, // 주문번호
        });
      } else {
        api.deleteMemberPayment(payInfo.current);
        console.error('이노페이 SDK가 로드되지 않았습니다.');
      }
    } else {
      if (await window.confirm(`내 프로필에서 전화번호 등록 후 결제가 가능합니다.\n전화번호를 등록하시겠습니까?`)) {
        moveToPage(PATH.PROFILE_PAGE, { mem_email: memEmail, mem_key: memKey, profileDetail: 'true' });
      }
    }
  };

  // 구매 저장
  const savePayInfo = async () => {
    try {
      const response = await api.insertPaymentMstForPaySeq(payInfo?.current);
      if (response.status === 200) {
        try {
          await api.insertSaleInfo(response.data); // 판매자 거래정보 프로시저
          api.deleteCartItemByKey([gdsInfo?.current]); // 장바구니 삭제
          api.sendPaymentCompleteAlert({ mem_key: memKey, pay_no: payInfo?.current.pay_no, pay_type: 'CART' }); // 결제 완료 알림
          setIsReceiptModalVisible(false);
          message.success('결제가 완료되었습니다.');
          await listMusic();
        } catch (e) {
          message.error('결제정보 업데이트 도중 에러가 발생했습니다.');
        }
      } else {
        message.error('결제정보 업데이트 도중 에러가 발생했습니다.');
      }
    } catch (e) {
      console.error(e);
    }
  };

  // 반복 버튼 토글
  const toggleLoop = () => {
    setIsLoop(!isLoop);
  };

  // 셔플 버튼 토글
  const toggleShuffle = () => {
    setIsShuffle(!isShuffle);
  };

  // 해당 곡 재생여부
  const isPlayingMusic = useCallback(
    (music, index) => {
      if (selectedTab === 1) return selectedMusicItem?.play_list_seq === music?.play_list_seq && selectedMusicItem?.prpt_id === music?.prpt_id;
      if (selectedTab === 2) return !selectedMusicItem?.play_list_seq && selectedMusicIndex === index && selectedMusicItem?.prpt_id === music?.prpt_id;
      return false;
    },
    [selectedTab, selectedMusicItem, selectedMusicIndex],
  );

  // 정지
  const onPause = () => {
    playerRef.current.audio.current.pause();
    playerRef.current.audio.current.src = null;
  };

  // 재생 시작
  const onPlayMusic = useCallback(async () => {
    if (!selectedMusicItem || !selectedMusicItem?.file_dwn_path) onPause();
  }, [selectedMusicItem]);

  // 이전곡 재생
  const onPrevious = useCallback(async () => {
    // 이전 index 추출
    let prevIndex;
    if (selectedMusicIndex === 0) prevIndex = musicList.length - 1;
    else prevIndex = selectedMusicIndex - 1;
    // 데이터 유효하지 않으면 플레이어 일시정지
    if (!musicList[prevIndex] || !musicList[prevIndex]?.file_dwn_path) onPause();
    // 데이터 유효하면 플레이수 카운트업
    else await api.updatePromptView({ prpt_id: musicList[prevIndex]?.prpt_id, view_type: 'P' });
    playerRef.current.audio.current.currentTime = 0;
    setSelectedMusicItem(musicList[prevIndex]);
    setSelectedMusicIndex(prevIndex);
  }, [selectedMusicIndex, musicList]);

  // 다음곡 재생
  const onNext = useCallback(async () => {
    // 다음 index 추출
    let nextIndex;
    if (selectedMusicIndex === musicList.length - 1) nextIndex = 0;
    else nextIndex = selectedMusicIndex + 1;
    // 데이터 유효하지 않으면 플레이어 일시정지
    if (!musicList[nextIndex] || !musicList[nextIndex]?.file_dwn_path) onPause();
    // 데이터 유효하면 플레이수 카운트업
    else await api.updatePromptView({ prpt_id: musicList[nextIndex]?.prpt_id, view_type: 'P' });
    playerRef.current.audio.current.currentTime = 0;
    setSelectedMusicItem(musicList[nextIndex]);
    setSelectedMusicIndex(nextIndex);
  }, [selectedMusicIndex, musicList]);

  // 랜덤곡 재생
  const onShuffle = useCallback(async () => {
    const randomIndex = Math.floor(Math.random() * totalMusicList);
    // 데이터 유효하지 않으면 플레이어 일시정지
    if (!musicList[randomIndex] || !musicList[randomIndex]?.file_dwn_path) onPause();
    // 데이터 유효하면 플레이수 카운트업
    else await api.updatePromptView({ prpt_id: musicList[randomIndex]?.prpt_id, view_type: 'P' });
    playerRef.current.audio.current.currentTime = 0;
    setSelectedMusicItem(musicList[randomIndex]);
    setSelectedMusicIndex(randomIndex);
  }, [totalMusicList, musicList]);

  // 곡 종료시
  const onEnd = useCallback(() => {
    if (isLoop && isShuffle) {
      onShuffle();
    }
    if (isLoop && !isShuffle) {
      onNext();
    }
    if (!isLoop) playerRef.current.audio.current.pause();
  }, [isLoop, isShuffle, onNext, onShuffle]);

  // 리스트에서 곡 선택 재생
  const onSelectMusic = async (music, index) => {
    // 데이터 유효하지 않으면 플레이어 일시정지
    if (!music || !music?.file_dwn_path) onPause();
    // 데이터 유효하면 플레이수 카운트업
    else await api.updatePromptView({ prpt_id: music?.prpt_id, view_type: 'P' });
    playerRef.current.audio.current.currentTime = 0;
    setSelectedMusicItem(music);
    setSelectedMusicIndex(index);
  };

  // 체크 여부
  const isChecked = (music) => {
    let existed = [];
    if (selectedTab === 1) existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.play_list_seq === music?.play_list_seq);
    if (selectedTab === 2) existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.prpt_id === music?.prpt_id);
    return existed.length > 0;
  };

  // 체크 박스 한개 토글
  const onCheckOne = (checked, music) => {
    let existed = [];
    if (selectedTab === 1) existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.play_list_seq === music?.play_list_seq);
    if (selectedTab === 2) existed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.prpt_id === music?.prpt_id);
    if (checked && !existed.length) {
      setCheckedMusicList([...checkedMusicList, music]);
      setTotalCheckedMusicList(totalCheckedMusicList + 1);
    }
    if (!checked && existed.length) {
      let removed = [];
      if (selectedTab === 1) removed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.play_list_seq !== music?.play_list_seq);
      if (selectedTab === 2) removed = checkedMusicList?.filter((checkedMusic) => checkedMusic?.prpt_id !== music?.prpt_id);
      setCheckedMusicList(removed);
      setTotalCheckedMusicList(totalCheckedMusicList - 1);
    }
  };

  // 체크 박스 전체 토글
  const onCheckAll = (checked) => {
    if (checked) {
      setCheckedMusicList(musicList);
      setTotalCheckedMusicList(totalMusicList);
    } else {
      setCheckedMusicList([]);
      setTotalCheckedMusicList(0);
    }
  };

  // 탭 변경시
  const changeTab = async (index) => {
    setSelectedTab(index);
    setSortParams(INIT_STATE_SORT);
  };

  // 정렬 변경시
  const changeSortParams = (value) => {
    const selectedSort = sortList.filter((sort) => sort.value === value);
    if (!selectedSort.length) return;
    setSortParams(selectedSort[0]);
  };

  // 플레이리스트/마이뮤직 가져오기
  const listMusic = useCallback(
    async (isInit) => {
      try {
        // 세션없을때 리턴
        if (!memKey) return;
        setIsLoading(true);
        let data = {};
        // 플레이리스트 조회
        if (selectedTab === 1) {
          const response = await api.listPlaylistMusic({ mem_key: memKey, sort_key: sortParams?.sort_key, sort_value: sortParams?.sort_value });
          data = response.data;
        }
        // 마이뮤직 조회
        if (selectedTab === 2) {
          const response = await api.listMyMusic({ mem_key: memKey, sort_key: sortParams?.sort_key, sort_value: sortParams?.sort_value });
          data = response.data;
        }
        setMusicList(data?.items);
        setTotalMusicList(data?.total);
        setCheckedMusicList([]);
        setTotalCheckedMusicList(0);
        // 플레이어를 처음 열었을때 or 강제로 초기화해야 하는 경우
        if (isFirstCall || isInit) {
          // 데이터 유효하지 않으면 플레이어 일시정지
          if (!data || !data?.items.length || !data?.items[0]?.file_dwn_path) onPause();
          // 데이터 유효하면 플레이수 카운트업
          else await api.updatePromptView({ prpt_id: data?.items[0]?.prpt_id, view_type: 'P' });
          setSelectedMusicItem(data?.items[0]);
          setSelectedMusicIndex(0);
          setIsFirstCall(false);
        }
      } catch (error) {
        message.warning(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [memKey, selectedTab, isFirstCall, sortParams?.sort_key, sortParams?.sort_value],
  );

  // 플레이리스트 곡 삭제
  const deletePlaylistMusic = useCallback(
    async (musics = []) => {
      try {
        if (!musics.length) return message.warning('곡을 선택해주세요.');
        if (!memKey) return;
        const { data } = await api.deletePlaylistMusic(musics);
        if (!data || data !== 'success') return;
        // 삭제하려는 곡이 재생중인지 확인, 재생중이었다면 곡 리스트 조회 후 초기화시킴
        const playingMusics = musics?.filter((music) => music?.play_list_seq === selectedMusicItem?.play_list_seq);
        const isInit = playingMusics.length > 0;
        await listMusic(isInit);
        message.success('플레이리스트에서 삭제되었습니다.');
      } catch (error) {
        message.warning(error.message);
      }
    },
    [listMusic, memKey, selectedMusicItem],
  );

  // 보관함 목록 가져오기
  const listSavedMusic = useCallback(async () => {
    try {
      if (!memKey) return;
      const { data } = await api.listMyMusic({ mem_key: memKey });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setSavedMusicList(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, [memKey]);

  // 보관함 곡 추가
  const addMyMusic = useCallback(
    async (tab, isInitChecked, musics = []) => {
      try {
        if (!musics.length) return message.warning('곡을 선택해주세요.');
        if (!memKey) return;
        const requestParams = musics?.map((music) => {
          return {
            mem_key: memKey,
            prpt_id: music?.prpt_id,
            prpt_title: music?.prpt_title,
            prpt_path: music?.music_org_link,
          };
        });
        const { data } = await api.addMyMusic(requestParams);
        if (!data || data !== 'success') return;
        await listSavedMusic();
        if (tab === 1) {
          if (isInitChecked) {
            setCheckedMusicList([]);
            setTotalCheckedMusicList(0);
          }
        }
        if (tab === 2) await listMusic();
        message.success('마이뮤직에 저장되었습니다.');
      } catch (error) {
        message.warning(error.message);
      }
    },
    [listMusic, listSavedMusic, memKey],
  );

  // 보관함 곡 삭제
  const deleteMyMusic = useCallback(
    async (tab, musics = []) => {
      try {
        if (!musics.length) return message.warning('곡을 선택해주세요.');
        if (!memKey) return;
        const requestParams = musics?.map((music) => {
          return {
            mem_key: memKey,
            prpt_id: music?.prpt_id,
          };
        });
        const { data } = await api.deleteMyMusic(requestParams);
        if (!data || data !== 'success') return;
        await listSavedMusic();
        if (tab === 2) {
          // 삭제하려는 곡이 재생중인지 확인, 재생중이었다면 곡 리스트 조회 후 초기화시킴
          const playingMusics = musics?.filter((music) => !music?.play_list_seq && music?.prpt_id === selectedMusicItem?.prpt_id);
          const isInit = playingMusics.length > 0;
          await listMusic(isInit);
        }
        message.success('마이뮤직에서 삭제되었습니다.');
      } catch (error) {
        message.warning(error.message);
      }
    },
    [listMusic, listSavedMusic, memKey, selectedMusicItem],
  );

  // 좋아요 목록 가져오기
  const listLikedMusic = useCallback(async () => {
    try {
      if (!memKey) return;
      const { data } = await api.getMyPromptList({ mem_key: memKey, keyword_tab: 'likeList', filter_cate: ['CATE003'] });
      if (!data) return;
      const ids = data.items.map((music) => music.prpt_id);
      setLikedMusicList(ids);
    } catch (error) {
      message.warning(error.message);
    }
  }, [memKey]);

  // 좋아요 곡 추가
  const addLikeMusic = useCallback(
    async (music) => {
      try {
        if (!memKey) return;
        const { data } = await api.increaseLikeCount({
          target_id: music?.prpt_id,
          like_mem_key: memKey,
          own_mem_key: music?.mem_key,
          like_div: 'P',
          fir_id: memKey,
        });
        if (!data) return;
        await listLikedMusic();
        message.success('좋아요 등록되었습니다.');
      } catch (error) {
        message.warning(error.message);
      }
    },
    [listLikedMusic, memKey],
  );

  // 좋아요 곡 삭제
  const deleteLikeMusic = useCallback(
    async (music) => {
      try {
        if (!memKey) return;
        const { data } = await api.increaseLikeCount({
          target_id: music?.prpt_id,
          like_mem_key: memKey,
          own_mem_key: music?.mem_key,
          like_div: 'P',
        });
        if (!data) return;
        await listLikedMusic();
        message.success('좋아요 삭제되었습니다.');
      } catch (error) {
        message.warning(error.message);
      }
    },
    [listLikedMusic, memKey],
  );

  // 장바구니 곡 추가
  const addCartMusic = useCallback(async (musics = []) => {
    try {
      if (!musics.length) return message.warning('곡을 선택해주세요.');
      if (!memKey) return;
      const requestParams = musics?.map((music) => {
        return {
          mem_key: memKey,
          gds_key: music?.prpt_id,
          gds_nm: music?.prpt_title,
          gds_amt: music?.sale_amt,
          gds_url: music?.thum_path,
          gds_div: 'GDS003',
        };
      });
      const { data } = await api.addToCart(requestParams);
      if (data?.returnStatus !== 'success') throw new Error(data.returnMessage);
      setCheckedMusicList([]);
      setTotalCheckedMusicList(0);
      message.success('장바구니에 저장되었습니다.');
    } catch (error) {
      message.warning(error.message);
    }
  }, []);

  useEffect(() => {
    // 이노페이 SDK 스크립트 로드
    const script = document.createElement('script');
    script.src = 'https://pg.innopay.co.kr/ipay/js/innopay-2.0.js';
    script.charset = 'utf-8';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // 컴포넌트 언마운트 시 스크립트 제거
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    handleDebounce(() => listMusic());
  }, [selectedTab, sortParams]);

  useEffect(() => {
    listSavedMusic();
    listLikedMusic();
  }, []);

  return (
    <div id="musicBox" className="flexColCenter player">
      <div className="flexColCenter album">
        <div className="absoluteBox flexColCenter">
          <div className="flexColCenter">
            <div className="flexColCenter borderBox">
              <img src={selectedMusicItem?.mem_img_path} />
            </div>
          </div>
        </div>
        <img src={selectedMusicItem?.thum_path} />
      </div>

      <Affix className="affixBox" target={() => (isMobile ? document.getElementsByClassName('ant-modal-wrap')[0] : window)}>
        <div className="playBox flexColCenter">
          <p>{selectedMusicItem?.mem_nick}</p>

          <div className="flexRowBetween title">
            <div
              className="flexRow"
              id={savedMusicList.includes(selectedMusicItem?.prpt_id) ? 'active' : ''}
              onClick={() => (savedMusicList.includes(selectedMusicItem?.prpt_id) ? deleteMyMusic(selectedTab, [selectedMusicItem]) : addMyMusic(selectedTab, false, [selectedMusicItem]))}
            >
              <img src={activeSaveIcon} className="activeIcon" />
              <img src={saveIcon} />
            </div>

            <h1>{selectedMusicItem?.prpt_title}</h1>

            <div
              className="flexRow"
              id={likedMusicList.includes(selectedMusicItem?.prpt_id) ? 'active' : ''}
              onClick={() => (likedMusicList.includes(selectedMusicItem?.prpt_id) ? deleteLikeMusic(selectedMusicItem) : addLikeMusic(selectedMusicItem))}
            >
              <img src={activeHeartIcon} className="activeIcon" />
              <img src={heartIcon} />
            </div>
          </div>

          <div className="flexColStart">
            <AudioPlayer
              ref={playerRef}
              src={selectedMusicItem?.file_dwn_path}
              autoPlay={selectedMusicItem && selectedMusicItem?.file_dwn_path}
              showSkipControls={true}
              showJumpControls={false}
              customIcons={{
                pause: <img src={playingIcon} />,
                play: <img src={playIcon3} />,
                previous: <img src={prevArrowIcon} />,
                next: <img src={nextArrowIcon} />,
              }}
              customProgressBarSection={[RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_TIME, <div className="separator">/</div>, RHAP_UI.DURATION]}
              customControlsSection={[
                <div className="rhap_shuffle-controls">
                  <button className="rhap_button-shuffle" onClick={toggleShuffle}>
                    <img src={isShuffle ? randomIcon : randomOffIcon} />
                  </button>
                </div>,
                RHAP_UI.MAIN_CONTROLS,
                <div className="rhap_shuffle-controls">
                  <button className="rhap_button-shuffle" onClick={toggleLoop}>
                    <img src={isLoop ? repeatIcon : repeatOffIcon} />
                  </button>
                </div>,
              ]}
              onClickPrevious={onPrevious}
              onClickNext={onNext}
              onPlay={onPlayMusic}
              onEnded={onEnd}
            />
          </div>
        </div>

        <div className="flexRowCenter tabBox">
          {tabList?.map((tab) => (
            <div key={tab?.value} className="tab flexColCenter" id={tab?.value === selectedTab ? 'active' : ''} onClick={() => changeTab(tab?.value)}>
              {tab?.label}
            </div>
          ))}
        </div>

        <div className="flexRowBetween top">
          <div className="flexRowCenter">
            <Checkbox checked={totalMusicList !== 0 && totalMusicList === totalCheckedMusicList} className="check" onClick={(e) => onCheckAll(e.target.checked)} />

            <p>
              {`${totalMusicList}곡 / `}
              <span>{`${totalCheckedMusicList}곡`}</span>
            </p>
          </div>

          <div className="antSelect flexColCenter">
            <Select defaultValue={1} value={sortParams?.value} options={sortList} onChange={changeSortParams} />
          </div>
        </div>
      </Affix>

      <div className="flexColCenter listBox playlist">
        {isLoading ? (
          <>
            <Skeleton avatar active />
            <Skeleton avatar active />
          </>
        ) : (
          <div className="flexColCenter">
            {musicList.length ? (
              musicList?.map((music, index) => (
                <div key={index} className="list flexRowBetween" id={isPlayingMusic(music, index) ? 'active' : ''}>
                  <Checkbox checked={isChecked(music)} className="check" onChange={(e) => onCheckOne(e.target.checked, music)} />

                  <img src={music?.thum_path} className="thumbnail" />

                  <div className="flexColStart titleBox">
                    <div className="flexColStart user">
                      <p className="">{music?.ai_model_nm}</p>
                      <h1>{music?.prpt_title}</h1>
                    </div>

                    <div className="flexRowStart">
                      <img src={music?.mem_img_path} />
                      <p>{music?.mem_nick}</p>
                    </div>
                  </div>

                  <div className="playIcon flexColCenter" id={isPlayingMusic(music, index) ? 'active' : ''} onClick={() => onSelectMusic(music, index)}>
                    <img src={greyArrowIcon} />
                    <img src={activeHeadsetIcon} className="activeIcon" />
                  </div>

                  <Dropdown
                    overlayClassName="spread-button"
                    placement="bottomRight"
                    menu={{
                      items: [
                        {
                          key: 1,
                          disabled: !isAdmin(memAuth) && !isPaidUser(planCd),
                          onClick: () => openLyricsInfoModal(music),
                          label: <div className="tab flexColCenter">가사</div>,
                        },
                        {
                          key: 2,
                          disabled: !isAdmin(memAuth) && !isPaidUser(planCd),
                          onClick: () => onDownload(music),
                          label: <div className="tab flexColCenter">다운로드</div>,
                        },
                        // {
                        //   key: 3,
                        //   label: (
                        //     <div className="tab flexColCenter" onClick={() => openReceiptModal(music)}>
                        //       사용권 구매
                        //     </div>
                        //   ),
                        // },
                      ],
                    }}
                  >
                    <p>
                      <MoreOutlined />
                    </p>
                  </Dropdown>
                </div>
              ))
            ) : (
              <div className="list-nothing">등록된 음악이 없습니다.</div>
            )}
          </div>
        )}
      </div>

      <Affix className="affixBox" offsetBottom={0} target={() => (isMobile ? document.getElementsByClassName('ant-modal-wrap')[0] : window)}>
        <div className="flexRowBetween bottom">
          <div
            className="flexColCenter"
            onClick={() => {
              if (selectedTab === 1) addMyMusic(1, true, checkedMusicList);
              if (selectedTab === 2) addMyMusic(2, false, checkedMusicList);
            }}
          >
            <img src={saveIcon} />
            <img src={activeSaveIcon} className="activeIcon" />
          </div>
          {/* <div className="flexColCenter" onClick={() => addCartMusic(checkedMusicList)}>
            <img src={cartIcon} />
            <img src={activeCartIcon} className="activeIcon" />
          </div> */}
          {/* <div className="flexColCenter">
            <img src={downloadIcon} />
            <img src={activeDownloadIcon} className="activeIcon" />
          </div> */}
          <div
            className="flexColCenter"
            onClick={() => {
              if (selectedTab === 1) deletePlaylistMusic(checkedMusicList);
              if (selectedTab === 2) deleteMyMusic(2, checkedMusicList);
            }}
          >
            <img src={trashIcon} />
            <img src={activeTrashIcon} className="activeIcon" />
          </div>
        </div>
      </Affix>

      <LyricsInfoModal isOpen={isLyricsInfoModalVisible} onClose={closeLyricsInfoModal} data={selectedLyricsInfo} />
      {/* <ReceiptModal visible={isReceiptModalVisible} amount={receiptAmount} onClose={closeReceiptModal} handleOpenPayment={handleOpenPayment} isCoupon={false} paymentAmount={receiptAmount} /> */}
    </div>
  );
};

export default PlayerMusic;
