import { restApi, querystring } from './config';

// 거래 내역 전체 조회
export const listPayHistory = (data) => {
  return restApi.get('/api/payHistory', querystring(data));
};
// 결제내역 전체 조회
export const listPay = (data) => {
  return restApi.get('/api/pay', querystring(data));
};
// 총 결제금액 조회
export const listPayTotalAmount = (data) => {
  return restApi.get('/api/pay/totalAmount', querystring(data));
};
// 결제내역 저장 (생성 / 수정 / 삭제)
export const savePay = (data) => {
  return restApi.post('/api/pay', data);
};
//수수료 관리 목록 조회
export const fetchChargeList = (data) => {
  return restApi.post('/api/chargeManagement/fetchChargeList', data);
};
//수수료 관리 목록 저장
export const saveCharges = (data) => {
  return restApi.post('/api/chargeManagement/saveCharges', data);
};
/**지급관리 */
export const listPayment = (data) => {
  return restApi.post('/api/paymentManagement/listPayment', data);
};
/**정산 요청 후 상태 업데이트 */
export const updatePaymentDetail = (data) => {
  return restApi.post('/api/paymentManagement/updatePaymentDetail', data);
};
/**환불관리 - 멤버십 조회 */
export const listRefund = (data) => {
  return restApi.post('/api/refundManagement/listRefund', data);
};
/**토스페이먼츠 판매자등록 */
export const makeSeller = (data) => {
  return restApi.post('/api/pay/makeSeller', data);
}
/**이노페이 결제 처리 */
export const completePayment = (data) => {
  return restApi.post('/api/pay/payment/complete', data);
}
/**결제 마스터 데이터 생성 */
export const insertPaymentMst = (data) => {
  return restApi.post('/api/pay/insertPaymentMst', data);
}
/**결제 마스터 테이블 업데이트 */
export const insertPaymentMstForPaySeq = (data) => {
  return restApi.post('/api/pay/insertPaymentMstForPaySeq', data);
}
/**사용자간 거래 결제 후 프로시저 실행 */
export const insertSaleInfo = (data) => {
  return restApi.post('/api/pay/insertSaleInfo', data);
}
