import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { message, Layout, Form, Input, Button, Row } from 'antd';
import Table from '@components/Table';
import Loading from '@components/Loading';
import * as api from '@api/index';
import { makeDataFormat, useDebounce } from '@utils/lib';
import { bannerApr, periodList } from '@utils/constants';
import iconDate from '/src/static/AUIGrid/images/calendar-icon.png';
import ButtonExport from '@components/ButtonExportAUIGrid';
import RangePicker from '@components/RangePicker';
import dayjs from 'dayjs';

const { Content, Header } = Layout;

const Banner = () => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const imgRef = useRef();
  const [recentItem, setRecentItem] = useState(null);
  const [fileCaches, setFileCaches] = useState([]);
  const [delFileCaches, setDelFileCaches] = useState([]);
  const [bannerLocation, setBannerLocation] = useState([]);
  const [bannerAttr, setBannerAttr] = useState([]);

  const today = dayjs(); // 오늘 날짜
  const oneWeekAgo = today.subtract(7, 'day'); // 일주일 전 날짜

  const [sendDate, setSendDate] = useState({
    st_date: oneWeekAgo.format('YYYY-MM-DD'),
    end_date: today.format('YYYY-MM-DD'),
  });

  const [bannerListState, setBannerListState] = useState({
    query: {
      keyword_text: '',
      startDt: sendDate.st_date,
      endDt: sendDate.end_date,
      offset: 0,
      limit: 20,
    },
    items: [],
    totalCount: 0,
    currentCount: 0,
    yScrollPosition: 0,
  });

  const handleDate = ({ startDt, endDt }) => {
    setSendDate({
      st_date: startDt,
      end_date: endDt,
    });
  };

  useEffect(() => {
    setBannerListState((prevState) => ({
      ...prevState,
      query: {
        ...prevState.query,
        startDt: sendDate.st_date,
        endDt: sendDate.end_date,
      },
    }));
  }, [sendDate]);

  const bannerGridRef = useRef();
  const handleDebounce = useCallback(
    useDebounce((func) => func(), 300),
    [],
  );

  const bannerGridProps = {
    showRowCheckColumn: true,
    showRowNumColumn: false,
  };

  // 이미지 업로드 처리 함수
  const imgUpload = (event) => {
    let file = event.target.files[0];
    const tempUrl = URL.createObjectURL(file);
    setFileCaches((prevFileCaches) => {
      const filteredItems = prevFileCaches.filter((item) => item.id !== recentItem._$uid);
      return [...filteredItems, { id: recentItem._$uid, file, tempUrl }];
    });

    setDelFileCaches(Array.from(new Set([...delFileCaches, recentItem.banner_img_path])));

    bannerGridRef.current.updateRowsById({
      _$uid: recentItem._$uid,
      banner_img_path: tempUrl,
      banner_img_nm: file.name,
    });
    imgRef.current.value = ''; // Reset
  };

  // 이미지 삭제 함수
  window.imgDelete = (imgPath, rowIndex) => {
    setDelFileCaches(Array.from(new Set([...delFileCaches, imgPath])));
    if (imgPath.startsWith('blob:')) {
      setFileCaches((prevFileCaches) => prevFileCaches.filter((item) => item.tempUrl !== imgPath));
      URL.revokeObjectURL(imgPath); // Free up memory
    }
    bannerGridRef.current.updateRows({ banner_img_path: null, banner_img_nm: null }, rowIndex);
    if (typeof window !== 'undefined' && window !== null && window.postMessage) {
      window.postMessage({ type: 'delete_image', path: imgPath }, '*');
    } else {
      console.error('window.postMessage is not available');
    }
  };

  const bannerLoc = async () => {
    try {
      const response = await api.fetchEqualCommonGroupCode({ grp_cd: 'BANNER' });
      const bannerLocList = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setBannerLocation(bannerLocList);
      const bannerAttrList = makeDataFormat(response?.data, 'attr3', 'cd');
      setBannerAttr(bannerAttrList);
    } catch (error) {
      message.warning(error.message);
    }
  };

  const bannerColumnLayout = [
    { dataField: 'banner_seq', headerText: '배너seq', width: '8%', visible: false },
    {
      dataField: 'loc_cd',
      headerText: '위치',
      width: '16%',
      renderer: {
        type: 'DropDownListRenderer',
        list: Object.entries(bannerLocation).map(([key, value]) => ({ cd: key, cd_nm: value })),
        keyField: 'cd',
        valueField: 'cd_nm',
      },
    },
    { dataField: 'dsp_idx', headerText: '순서', width: '3%' },
    { dataField: 'banner_nm', headerText: '광고명', width: '13%' },
    {
      headerText: '기간',
      children: [
        {
          dataField: 'banner_start_dt',
          headerText: '시작일',
          formatString: 'yyyy-mm-dd',
          dataType: 'date',
          width: '7%',
          renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
            onClick: () => {
              // 달력 아이콘 클릭하면 실제로 달력을 띄움.
              // 즉, 수정으로 진입함.
              bannerGridRef.current.openInputer();
            },
          },
          editRenderer: {
            type: 'CalendarRenderer',
            showEditorBtn: false, // 기본 에디터버턴 사용 안함.
            onlyCalendar: true, // 달력으로만 수정 가능
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            monthTitleString: 'mmm',
            formatMonthString: 'mmm yyyy',
            formatYearString: 'yyyy',
            showExtraDays: true, // 지난 달, 다음 달 여분의 날짜(days) 출력 안함
            showTodayBtn: true, // 오늘 날짜 선택 버턴 출력
            todayText: 'Today', // 오늘 날짜 버턴 텍스트
            onChange: (value, item) => handleGridDateChange('banner_start_dt', value, item), // 날짜 변경 시 호출
          },
        },

        {
          dataField: 'banner_end_dt',
          headerText: '종료일',
          formatString: 'yyyy-mm-dd',
          dataType: 'date',
          width: '7%',
          renderer: {
            type: 'IconRenderer',
            iconWidth: 16, // icon 사이즈, 지정하지 않으면 rowHeight에 맞게 기본값 적용됨
            iconHeight: 16,
            iconPosition: 'aisleRight',
            iconTableRef: {
              // icon 값 참조할 테이블 레퍼런스
              default: iconDate, // default
            },
            onClick: () => {
              // 달력 아이콘 클릭하면 실제로 달력을 띄움.
              // 즉, 수정으로 진입함.
              bannerGridRef.current.openInputer();
            },
          },
          editRenderer: {
            type: 'CalendarRenderer',
            showEditorBtn: false, // 기본 에디터버턴 사용 안함.
            onlyCalendar: true, // 달력으로만 수정 가능
            titles: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            monthTitleString: 'mmm',
            formatMonthString: 'mmm yyyy',
            formatYearString: 'yyyy',
            showExtraDays: true, // 지난 달, 다음 달 여분의 날짜(days) 출력 안함
            showTodayBtn: true, // 오늘 날짜 선택 버턴 출력
            todayText: 'Today', // 오늘 날짜 버턴 텍스트
            onChange: (value, item) => handleGridDateChange('banner_end_dt', value, item), // 날짜 변경 시 호출
          },
        },
      ],
    },
    { dataField: 'banner_amt', headerText: '배너 금액', width: '8%', dataType: 'numeric' },
    {
      dataField: 'banner_stat',
      headerText: '결제',
      width: '6%',
      renderer: { type: 'DropDownListRenderer', list: bannerApr, keyField: 'value', valueField: 'label' },
    },
    {
      headerStyle: 'required-custom-header',
      headerText: '배너 썸네일',
      children: [
        {
          dataField: 'banner_img_path',
          headerText: '배너 이미지',
          width: '18%',
          headerStyle: 'required-custom-header',
          renderer: { type: 'TemplateRenderer' },
          labelFunction: (rowIndex, columnIndex, value, headerText, item) => {
            let template = '';
            let rowData = bannerGridRef.current.getGridData()[rowIndex];
            const bannerValue = bannerAttr[rowData.loc_cd] || null;
            if (value) {
              const imgSrc = value.startsWith('blob:') ? value : `${value}?t=${new Date().getTime()}`;
              template += `<img src="${imgSrc}" alt="Banner" style="height:25px; max-width:100%;">`;
              template += '<span onClick="imgDelete(\'' + item.banner_img_path + "'," + rowIndex + ')" style="position: absolute; top: 0px; right: 5px; cursor: pointer;">X</span>';
            } else {
              if(bannerValue === "1"){
                template += '<span> 배너 권장 크기 (740px x 280px) </span>';
              } else{
                template += '<span> 배너 권장 크기 (1520px x 300px) </span>';
              }
            }
            return template;
          },
          editable: false,
        },
        {
          dataField: 'banner_add_btn',
          headerText: '추가',
          width: '5%',
          headerStyle: 'required-custom-header',
          renderer: {
            type: 'ButtonRenderer',
            labelText: '파일 선택',
            onclick: function (rowIndex, columnIndex, value, item) {
              setRecentItem(item);
              imgRef?.current?.click();
            },
          },
          editable: false,
        },
      ],
    },
    { dataField: 'banner_img_nm', headerText: '배너 이미지명', width: '10%', visible: false },
    { dataField: 'banner_url', headerText: '배너 url', width: '13%' },
    { dataField: 'view_cnt', headerText: '클릭수', width: '4%', editable: false },
  ];

  const setupGridEvents = useCallback(() => {
    if (bannerGridRef.current) {
      bannerGridRef.current.bind('vScrollChange', (event) => {
        handleDebounce(() => setBannerListState((prev) => ({ ...prev, yScrollPosition: event.position })));
        if (event.position === event.maxPosition && bannerListState.currentCount < bannerListState.totalCount) {
          handleScroll();
        }
      });
    }
  }, [handleDebounce, bannerListState.currentCount, bannerListState.totalCount, bannerLocation]);

  const handleScroll = () => {
    const newQuery = { ...bannerListState.query, offset: bannerListState.query.offset + bannerListState.query.limit };
    setBannerListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getBannerList(newQuery));
  };

  const getBannerList = useCallback(
    async (query) => {
      try {
        setIsLoading(true);
        const { data } = await api.getBannerList(query);
        if (!data) return;

        setBannerListState((prev) => ({
          ...prev,
          items: data.items,
          totalCount: data.total,
          currentCount: data.items.length,
        }));

        if (bannerGridRef.current) {
          bannerGridRef.current.setGridData(data.items);
        }
        setupGridEvents();
      } catch (error) {
        message.warning(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [setupGridEvents],
  );

  const handleSearchBanner = ({ keyword_text }) => {
    const newQuery = { ...bannerListState.query, keyword_text, offset: 0 };
    setBannerListState((prev) => ({ ...prev, query: newQuery }));
    handleDebounce(() => getBannerList(newQuery));
  };

  const handleAddRow = () => {
    bannerGridRef.current.addRow({}, 'first');
  };

  // 추가, 수정, 삭제
  const updatedBanner = async (type) => {
    let saveItems = bannerGridRef.current.getGridDataWithState('state').filter((item) => item.state !== null && item.state !== undefined);
    let despSeqItems = bannerGridRef.current.getGridData();
    let delItems = bannerGridRef.current.getCheckedRowItems().map(({ item }) => {
      return { ...item, state: 'del' };
    });

    // const duplicateDspSeqs = validDspSeqDup(despSeqItems);
    // if (duplicateDspSeqs.length > 0) {
    //   alert(`중복된 표시순이 있습니다: ${duplicateDspSeqs.join(', ')}`);
    //   return false;
    // }

    if (type === 's') {
      if (saveItems.length < 1) {
        alert('저장할 데이터가 없습니다.');
        return false;
      }
      console.log(saveItems);

      const isValid = bannerGridRef.current.validateChangedGridData(
        ['page_cd', 'loc_cd', 'dsp_idx', 'banner_nm', 'banner_start_dt', 'banner_end_dt', 'banner_amt', 'banner_stat', 'banner_img_path'],
        '비어있는 필드가 있습니다.',
      );

      if (!isValid) return false;

      try {
        setIsLoading(true);
        const formData = new FormData();
        if (fileCaches.length > 0) {
          fileCaches.map((fileObj) => {
            formData.append('files', fileObj.file);
            formData.append('fileIds', fileObj.id);
          });
        }
        if (delFileCaches.length > 0) {
          delFileCaches.map((delFile) => {
            formData.append('delfiles', delFile);
          });
        }
        formData.append('updateBannerList', new Blob([JSON.stringify(saveItems)], { type: 'application/json' }));
        if (await window.confirm('저장하시겠습니까?')) {
          const response = await api.updatedBanner(formData);
          alert(response.data.returnMessage, response.data.returnStatus === 'fail' ? 'error' : 'success');
        }
      } catch (e) {
        console.log('광고 저장 error', e);
      } finally {
        setIsLoading(false);
        getBannerList(bannerListState.query);
      }
    } else if (type === 'd') {
      if (delItems.length < 1) {
        alert('삭제할 데이터가 없습니다.');
        return false;
      }

      if (delItems.length > 0) {
        try {
          setIsLoading(true);
          const formData = new FormData();
          formData.append('updateBannerList', new Blob([JSON.stringify(delItems)], { type: 'application/json' }));
          if (await window.confirm('선택한 데이터를 삭제하시겠습니까?')) {
            await api.updatedBanner(formData);
            alert(response.data.returnMessage, response.data.returnStatus === 'fail' ? 'error' : 'success');
          }
        } catch (e) {
          console.log('광고 삭제 error', e);
        } finally {
          setIsLoading(false);
          getBannerList(bannerListState.query);
        }
      } else {
        alert('삭제할 항목이 없습니다.');
      }
    }
  };

  // 중복 체크
  const validDspSeqDup = (data) => {
    const countMap = new Map();
    const duplicates = new Set();

    data.forEach((item) => {
      const dspSeq = item.dsp_idx;
      countMap.set(dspSeq, (countMap.get(dspSeq) || 0) + 1);
    });

    countMap.forEach((count, dspSeq) => {
      if (count > 1) {
        duplicates.add(dspSeq);
      }
    });

    return Array.from(duplicates); // 중복된 값 반환
  };

  const handleGridDateChange = (field, value, item) => {
    const newItems = bannerGridRef.current.getGridData();
    const updatedItem = newItems.find((item) => item.banner_seq === item.banner_seq);

    if (updatedItem) {
      updatedItem[field] = value;

      const startDate = new Date(updatedItem.banner_start_dt);
      const endDate = new Date(updatedItem.banner_end_dt);

      if (isNaN(startDate) || isNaN(endDate)) {
        alert('유효하지 않은 날짜 형식입니다.');
        return;
      }
      if (!validateDates(startDate, endDate)) {
        return;
      }
      bannerGridRef.current.setGridData(newItems);
    }
  };

  const validateDates = (startDate, endDate) => {
    if (startDate > endDate) {
      alert('시작일이 종료일보다 클 수 없습니다.');
      return false;
    }
    return true;
  };
  useEffect(() => {
    bannerLoc();
  }, []);

  useEffect(() => {
    getBannerList(bannerListState.query);
  }, [location.pathname]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Layout>
        <Header className="l-divided">
          <Form className="l-flex gap" form={form} layout="inline" initialValues={bannerListState.query} onFinish={handleSearchBanner}>
            <RangePicker
              styleSelect={{ minWidth: '80px' }}
              placeholderSelect="생성일시"
              optionsSelect={periodList}
              valueRangePicker={[sendDate?.st_date ? dayjs(sendDate?.st_date) : oneWeekAgo, sendDate?.end_date ? dayjs(sendDate?.end_date) : today]}
              onChange={handleDate}
            />
            <input type="file" accept="image/*" ref={imgRef} onChange={imgUpload} style={{ display: 'none' }} />
            <Form.Item name="keyword_text">
              <Input placeholder="광고 명을 입력해주세요." style={{ minWidth: '300px' }} />
            </Form.Item>
            <Button className="btn-searchIcon" type="primary" htmlType="submit">
              검색
            </Button>
          </Form>
        </Header>
        <Content className="l-content">
          <Row className="l-flex betCenter">
            <Row>
              검색결과 <Row style={{ marginLeft: '10px' }}>{`총 ${bannerListState.totalCount}건`}</Row>
            </Row>
            <Row className="l-flex gap">
              <ButtonExport ref={bannerGridRef} fileName="광고 관리" text="엑셀다운" />
              <Button className="btn-add" onClick={handleAddRow}>
                추가
              </Button>
              <Button className="btn-red" htmlType="submit" onClick={() => updatedBanner('d')} danger>
                삭제
              </Button>
              <Button className="btn-pink" type="primary" htmlType="submit" onClick={() => updatedBanner('s')}>
                저장
              </Button>
            </Row>
          </Row>
          <Table ref={bannerGridRef} columnLayout={bannerColumnLayout} customGridProps={bannerGridProps} />
        </Content>
      </Layout>
    </>
  );
};

export default Banner;
