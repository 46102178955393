import Page404 from '@pages/Page404';
import Main from '@pages/main';
import MusicHome from '@pages/musics';
import ImageHome from '@pages/images';
import VideoHome from '@pages/videos';
import TextHome from '@pages/texts';
import CommunityHome from '@pages/communities';
import MyPageHome from '@pages/myPages';
import Login from '@pages/logins';
import SocialLogin from '@pages/logins/SocialLogin';
import AdminHome from '@pages/admins';
import AdminUserHome from '@pages/admins/users';
import AdminUserEdit from '@pages/admins/users/Edit';
import CommonCodes from '@pages/admins/commonCodes';
import AdminAiModelHome from '@pages/admins/models';
import AdminElementsManagementHome from '@pages/admins/elementsManagement';
import AdminParameterHome from '@pages/admins/parameters';
import AdminPlanHome from '@pages/admins/membershipPlans';
import AdminRankHome from '@pages/admins/rankings';
import BaseDataHome from '@pages/admins/baseData';
import EventCoupon from '@pages/admins/eventCoupon';
import PromptReview from '@pages/admins/promptReview';
import IntegratedBoard from '@pages/admins/integratedBoard';
import Banner from '@pages/admins/banner';
import AdminPayList from '@pages/admins/pays';
import AdminAlarmHome from '@pages/admins/alarm';
import AdminChargeManagementHome from '@pages/admins/chargeManangement';
import BoardList from '@pages/boards';
import BoardEdit from '@pages/boards/Edit';
import BoardDetail from '@pages/boards/Detail';
import AdminPaymentManagementHome from '@pages/admins/paymentManagement';
import BusinessRegist from '@pages/myPages/businessRegist';
import AdminRefundHome from '@pages/admins/refund';
import ForgetAccount from '@pages/logins/ForgetAccount';
import NewPassword from '@pages/logins/NewPassword';
import ChatLayout from '@pages/chat';
import PromptDetail from '@pages/myPages/PromptDetail';
import ProfilePage from '@pages/profile';
import ProfileDetail from '@pages/myPages/ProfileDetail';
import InquiryPage from '@pages/inquiry';
import PromptPage from '@pages/prompt';
import Signup from '@pages/logins/Signup';
import ForgetPassword from '@pages/logins/ForgetPassword';
import ForgetEmail from '@pages/logins/ForgetEmail';
import MusicPlayer from '@components/PlayerMusic';
import SellerList from '@pages/admins/sellerList';
import AiCertList from '@pages/admins/aiCertManagement';
import AdminInquiryHome from '@pages/admins/inquiry';
import InquiryDetail from '@pages/inquiry/Detail';
import VideoPlayer from '@components/PlayerVideo';
import {
  MAIN,
  LOGIN,
  LOGIN_SOCIAL,
  MUSIC_HOME,
  IMAGE_HOME,
  VIDEO_HOME,
  TEXT_HOME,
  COMMUNITY_HOME,
  MYPAGE_HOME,
  ADMIN_HOME,
  ADMIN_USER_LIST,
  ADMIN_USER_DETAIL,
  ADMIN_USER_EDIT,
  COMMON_CODE_HOME,
  BASE_DATA_HOME,
  ADMIN_MODEL_HOME,
  SIGNUP,
  ADMIN_BUILDER_HOME,
  ADMIN_CATEGORY_HOME,
  ADMIN_LIBRARY_HOME,
  ADMIN_GROUP_HOME,
  ADMIN_PARAM_HOME,
  ADMIN_PLAN_HOME,
  EVENT_COUPON_HOME,
  INTEGRATED_BOARD_HOME,
  ADMIN_RANKING_HOME,
  PROMPT_REVIEW_HOME,
  BANNER_HOME,
  BOARD_HOME,
  BOARD_EDIT,
  BOARD_DETAIL,
  ADMIN_PAY_LIST,
  ADMIN_ALARM_HOME,
  ADMIN_CHARGE_MANAGEMENT_HOME,
  ADMIN_PAYMENT_MANAGEMENT_HOME,
  BUSINESS_REGIST,
  PROMPT_DETAIL,
  FORGET_ACCOUNT,
  NEW_PASSWORD,
  ADMIN_REFUND_HOME,
  CHAT_HOME,
  PROFILE_PAGE,
  MYPAGE_PROFILE,
  INQUIRY_HOME,
  PROMPT_HOME,
  FORGET_PASSWORD,
  FORGET_EMAIL,
  MUSIC_PLAYER,
  SELLER_LIST,
  ADMIN_INQUIRY_HOME,
  ADMIN_INQUIRY_DETAIL,
  ADMIN_PROMPT_REVIEW_DETAIL,
  AI_CERT,
  MY_MARKET_HOME,
  VIDEO_PLAYER,
  DICTIONARY_POPUP,
  BATCH_LIST,
} from './pathName';
import MyMarketplacesPage from '@pages/marketplaces';
import Dictionary from "@components/Dictionary";
import BatchList from "@pages/admins/batch";

const routes = [
  {
    name: '404 페이지',
    path: '*',
    Component: <Page404 />,
    isNotLayout: true,
  },
  {
    name: '메인페이지',
    path: MAIN,
    Component: <Main />,
  },

  // 로그인
  {
    name: '로그인',
    path: LOGIN,
    Component: <Login />,
  },
  {
    name: '소셜 로그인',
    path: LOGIN_SOCIAL,
    Component: <SocialLogin />,
  },
  {
    name: '아이디/비밀번호 찾기',
    path: FORGET_ACCOUNT,
    Component: <ForgetAccount />,
  },
  {
    name: '아이디 찾기',
    path: FORGET_EMAIL,
    Component: <ForgetEmail />,
  },
  {
    name: '비밀번호 찾기',
    path: FORGET_PASSWORD,
    Component: <ForgetPassword />,
  },
  {
    name: '비밀번호 변경',
    path: NEW_PASSWORD,
    Component: <NewPassword />,
  },

  // 유저 관리
  {
    name: '회원가입',
    path: SIGNUP,
    Component: <Signup />,
  },
  // 내 프로필
  {
    name: '내 프로필',
    path: MYPAGE_PROFILE,
    Component: <ProfileDetail />,
  },
  // 프롬프트 생성
  {
    name: '프롬프트 생성',
    path: PROMPT_HOME,
    Component: <PromptPage />,
  },
  // 프롬프트 상세
  {
    name: '프롬프트 상세',
    path: PROMPT_DETAIL,
    Component: <PromptDetail />,
  },
  {
    name: '내 마켓플레이스',
    path: MY_MARKET_HOME,
    Component: <MyMarketplacesPage />,
  },

  // 음악
  {
    name: '음악 홈',
    path: MUSIC_HOME,
    Component: <MusicHome />,
  },
  {
    name: '음악 플레이어',
    path: MUSIC_PLAYER,
    Component: <MusicPlayer />,
    isNotLayout: true,
  },

  // 이미지
  {
    name: '이미지 홈',
    path: IMAGE_HOME,
    Component: <ImageHome />,
  },

  // 영상
  {
    name: '영상 홈',
    path: VIDEO_HOME,
    Component: <VideoHome />,
  },
  {
    name: '영상 플레이어',
    path: VIDEO_PLAYER,
    Component: <VideoPlayer />,
    isNotLayout: true,
  },

  // 텍스트
  {
    name: '텍스트 홈',
    path: TEXT_HOME,
    Component: <TextHome />,
  },

  // 커뮤니티
  {
    name: '커뮤니티 홈',
    path: COMMUNITY_HOME,
    Component: <CommunityHome />,
  },

  /** 마이페이지 */
  {
    name: '마이페이지 홈',
    path: MYPAGE_HOME,
    Component: <MyPageHome />,
  },

  // 판매 프롬프트
  {
    name: '사업자 번호 등록',
    path: BUSINESS_REGIST,
    Component: <BusinessRegist />,
  },
  {
    name: '1:1 문의',
    path: INQUIRY_HOME,
    Component: <InquiryPage />,
  },
  {
    name: '프롬프트 사전',
    path: DICTIONARY_POPUP,
    isNotLayout: true,
    Component: <Dictionary />,
  },
];

// Admin
const routesAdmin = [
  {
    name: 'Admin 홈',
    path: ADMIN_HOME,
    Component: <AdminHome />,
  },
  {
    name: 'Admin 회원관리 홈',
    path: ADMIN_USER_LIST,
    Component: <AdminUserHome />,
  },
  {
    name: 'Admin 회원관리 상세페이지',
    path: ADMIN_USER_DETAIL,
    Component: <ProfilePage />,
  },
  {
    name: 'Admin 회원관리 편집페이지',
    path: ADMIN_USER_EDIT,
    Component: <AdminUserEdit />,
  },
  {
    name: 'Admin AI 모델 관리 홈',
    path: ADMIN_MODEL_HOME,
    Component: <AdminAiModelHome />,
  },
  {
    name: 'Admin 빌더 관리 홈',
    path: ADMIN_BUILDER_HOME,
    Component: <AdminElementsManagementHome />,
  },
  {
    name: 'Admin 카테고리 관리 홈',
    path: ADMIN_CATEGORY_HOME,
    Component: <AdminElementsManagementHome />,
  },
  {
    name: 'Admin 라이브러리 관리 홈',
    path: ADMIN_LIBRARY_HOME,
    Component: <AdminElementsManagementHome />,
  },
  {
    name: 'Admin 그룹 관리 홈',
    path: ADMIN_GROUP_HOME,
    Component: <AdminElementsManagementHome />,
  },
  {
    name: '공통코드 홈',
    path: COMMON_CODE_HOME,
    Component: <CommonCodes />,
  },
  {
    name: '기초 데이터 관리',
    path: BASE_DATA_HOME,
    Component: <BaseDataHome />,
  },
  {
    name: 'Admin 파라미터 관리 홈',
    path: ADMIN_PARAM_HOME,
    Component: <AdminParameterHome />,
  },
  {
    name: 'Admin 랭킹 관리 홈',
    path: ADMIN_RANKING_HOME,
    Component: <AdminRankHome />,
  },
  {
    name: 'Admin 멤버십 플랜관리 홈',
    path: ADMIN_PLAN_HOME,
    Component: <AdminPlanHome />,
  },
  {
    name: '쿠폰',
    path: EVENT_COUPON_HOME,
    Component: <EventCoupon />,
  },
  {
    name: '게시판 관리',
    path: INTEGRATED_BOARD_HOME,
    Component: <IntegratedBoard />,
  },
  {
    name: '프롬프트 검토',
    path: PROMPT_REVIEW_HOME,
    Component: <PromptReview />,
  },
  {
    name: 'Admin 프롬프트 검토 디테일',
    path: ADMIN_PROMPT_REVIEW_DETAIL,
    Component: <PromptDetail />,
  },
  {
    name: '광고 관리',
    path: BANNER_HOME,
    Component: <Banner />,
  },
  {
    name: '결제 관리 목록페이지',
    path: ADMIN_PAY_LIST,
    Component: <AdminPayList />,
  },
  {
    name: 'Admin 알림 관리',
    path: ADMIN_ALARM_HOME,
    Component: <AdminAlarmHome />,
  },
  {
    name: 'Admin 수수료 관리',
    path: ADMIN_CHARGE_MANAGEMENT_HOME,
    Component: <AdminChargeManagementHome />,
  },
  {
    name: '커뮤니티',
    path: BOARD_HOME,
    Component: <BoardList />,
  },
  {
    name: '커뮤니티 작성',
    path: BOARD_EDIT,
    Component: <BoardEdit />,
  },
  {
    name: '커뮤니티 상세',
    path: BOARD_DETAIL,
    Component: <BoardDetail />,
  },
  {
    name: 'Admin 지급 관리',
    path: ADMIN_PAYMENT_MANAGEMENT_HOME,
    Component: <AdminPaymentManagementHome />,
  },
  {
    name: 'Admin 환불 관리',
    path: ADMIN_REFUND_HOME,
    Component: <AdminRefundHome />,
  },
  {
    name: 'Admin 고객문의 관리',
    path: ADMIN_INQUIRY_HOME,
    Component: <AdminInquiryHome />,
  },
  {
    name: 'Admin 고객문의 관리 상세',
    path: ADMIN_INQUIRY_DETAIL,
    Component: <InquiryDetail />,
  },
  {
    name: '채팅',
    path: CHAT_HOME,
    Component: <ChatLayout />,
  },
  {
    name: '프로필',
    path: PROFILE_PAGE,
    Component: <ProfilePage />,
  },
  {
    name: '판매자 목록',
    path: SELLER_LIST,
    Component: <SellerList />,
  },
  {
    name: 'AI 인증 관리',
    path: AI_CERT,
    Component: <AiCertList />,
  },
  {
    name: '배치 & 스케줄러',
    path: BATCH_LIST,
    Component: <BatchList />,
  },
];

export const routesList = [...routes, ...routesAdmin];
