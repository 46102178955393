import React, { useState, useEffect, useRef } from 'react';
import { message, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import axios from 'axios';
import * as api from '@api/index';
import * as lib from '@utils/lib';
import PhoneAuthModal from '@pages/profile/PhoneAuthModal';
import ReceiptModal from '../../components/payment/ReceiptModal';
import AvailableCouponButton from '../../components/payment/AvailableCouponButton';
import AvailableCoupons from '../../components/payment/AvailableCoupons';
import PaymentCoupon from '../../components/payment/PaymentCoupon';
import Table from '@components/Table';
import RegisterForAutomaticPayment from '@components/payment/RegisterForAutomaticPayment';

const innopayMid = process.env.REACT_APP_INNOPAY_MID;
const TOKEN_STORE_KEY = process.env.REACT_APP_TOKEN_STORE_KEY;

const PlanInfo = (overrideData) => {
  const memKey = lib.getLocalStorage(TOKEN_STORE_KEY, 'mem_key') || '';
  const memTel = lib.getLocalStorage(TOKEN_STORE_KEY, 'mem_tel') || '';
  const memNick = lib.getLocalStorage(TOKEN_STORE_KEY, 'mem_nick') || '';
  const memEmail = lib.getLocalStorage(TOKEN_STORE_KEY, 'mem_email') || '';

  const [memberPlanInfo, setMemberPlanInfo] = useState({});
  const [membershipPlanList, setMembershipPlanList] = useState([]);
  const payMembershipInfo = useRef({});

  const [isPhoneAuthModalVisible, setIsPhoneAuthModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  //연간 결제 모달
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  //자동 결제 모달
  const [isAutomaticPayment, setIsAutomaticPayment] = useState(false);
  const [receiptAmount, setReceiptAmount] = useState(null);
  const [paymentMembership, setPaymentMembership] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);

  const isAvailableCoupons = useRef(false);
  const isCountAvailableCoupons = useRef(null);
  const [userOwnCoupons, setUserOwnCoupons] = useState(null);
  const [availableCoupons, setAvailableCoupons] = useState(null);
  const [isAvailableCouponModal, setIsAvailableCouponModal] = useState(false);
  const [offlineCouponCode, setOfflineCouponCode] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const [isPaymentCouponModal, setIsPaymentCouponModal] = useState(false);

  const isDowngrade = useRef(false);

  const tableRef = useRef();

  useEffect(() => {
    if (overrideData && overrideData.plan) {
      window.scrollIntoView(0, document.body.scrollHeight);
    }
  }, [overrideData]);

  const gridProps = {
    height: 150,
    showStateColumn: false,
    enableSorting: false,
    autoGridHeight: true,
    showRowNumColumn: false
  };

  const columnLayout = [
    { dataField: 'cpn_tp_nm', headerText: '발급형태', width: '10%', editable: false, visible: false },
    { dataField: 'rownum', headerText: '<span class="gridLeft">No</span>', width: '5%', editable: false },
    { dataField: 'cpn_nm', headerText: '쿠폰명', width: '35%', editable: false },
    /* { dataField: 'issue_dt_str', headerText: '쿠폰 등록일', width: '25%', editable: false }, */
    {
      dataField: 'use_start_dt_str',
      headerText: '등록 기간',
      width: '25%',
      editable: false,
      renderer: {
        type: 'TemplateRenderer',
      },
      labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
        let html = '';
        html += '<span>' + item.use_start_dt_str + ' ~ ' + item.use_end_dt_str + '</span>';
        return html;
      },
    },
    { dataField: 'd_cnt', headerText: '혜택 기간', width: '25%', editable: false ,
        labelFunction: function (rowIndex, columnIndex, value, headerText, item) {
            let html = '';
            html += '적용일로 부터 ' + item.d_cnt +  ' 일';
            return html;
          },},
    { dataField: 'cpn_stat_nm', headerText: '<span class="gridRight">상태</span>', width: '10%', editable: false },
  ];

  //멤버십 기간 한달 뒤 계산
  const getFutureDate = () => {
    const baseDate = new Date();
    const futureDate = new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, baseDate.getDate());
    // 하루 전으로 조정
    futureDate.setDate(futureDate.getDate() - 1);
    return formatDate(futureDate);
  };

  const formatDate = (date) => {
    // 날짜를 "YYYYMMDD" 형식으로 변환
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  };

  //날짜 증가
  function addDaysToDate(daysToAdd) {
    const baseDate = new Date();

    // 일수 추가
    baseDate.setDate(baseDate.getDate() + daysToAdd);

    // 결과를 YYYYMMDD 형식으로 변환
    const resultYear = baseDate.getFullYear();
    const resultMonth = String(baseDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const resultDay = String(baseDate.getDate()).padStart(2, '0');

    return `${resultYear}${resultMonth}${resultDay}`;
  }

  const calculateRefund = (subscriptionAmount, startDate) => {
    // 시작일과 환불 요청일 설정
    const start = new Date(startDate.substring(0,4) + '-' + startDate.substring(4,6) + '-' + startDate.substring(6,8)); // 예: "2025-03-01"
    const refundDate = new Date(); // 예: "2025-03-15"
  
    // 해당 월의 총 일수 계산
    const year = start.getFullYear();
    const month = start.getMonth(); // 0부터 시작 (3월이면 2)
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate(); // 해당 월의 마지막 날
  
    // 사용한 일수 계산
    const usedDays = Math.floor((refundDate - start) / (1000 * 60 * 60 * 24)) + 1;
    
    // 일일 요금 계산
    const dailyRate = subscriptionAmount / totalDaysInMonth;
    // 사용 금액 계산
    const usedAmount = Math.round(dailyRate * usedDays); // 반올림 처리
    
    return usedAmount;
  }

  // 사용자 정보 조회, 멤버십 데이터 세션 업데이트
  const fetchPlanInformation = () => {
    api
      .fetchPlanInformation()
      .then((response) => {
        let sessionToken = JSON.parse(localStorage.getItem(TOKEN_STORE_KEY)) || {};
        sessionToken.plan_cd = response.data.plan_cd;
        sessionToken.plan_nm = response.data.plan_nm;
        sessionToken.plan_start_dt = response.data.plan_start_dt;
        sessionToken.plan_end_dt = response.data.plan_end_dt;
        localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(sessionToken));
        
        setMemberPlanInfo(response.data);
        setMembershipPlanList(response.data.membershipPlanList);

        isDowngrade.current = false;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //결제 금액 계산
  const calculationAmount = async (selectedMembership) => {
    const beforeMembership = membershipPlanList.filter((item) => item.plan_cd === memberPlanInfo.plan_cd)[0];

    const nextPayment = async() => {
      const membership = {...selectedMembership}
      //세션 체크. 세션 살아있는 상태일 때만 멤버십 업그레이드 가능
      const requestData = { param: "" };
      await api.sessionCheck(requestData);

      membership.plan_start_dt = formatDate(new Date());
      membership.plan_end_dt = getFutureDate();
      membership.plan_nm = selectedMembership.plan_nm;
      
      openReceiptModal(membership);
    }

    if(memberPlanInfo.plan_cd === 'PLAN002' && memberPlanInfo.coupon_yn === 'N'){
      const refundAmount = beforeMembership.month_amt - calculateRefund(beforeMembership.month_amt, memberPlanInfo.plan_start_dt);
      if(await window.confirm('남은 이용일 수만큼의 금액을 결제 취소하고\n프로 멤버십으로 결제합니다.\n결제를 진행하시겠습니까?\n결제 취소 금액 : ' + lib.koKRFormat(refundAmount) + '원\n결제금액 : ' + lib.koKRFormat(selectedMembership.month_amt) + '원')){
    
          payMembershipInfo.current.refund_yn = 'Y';
          payMembershipInfo.current.refund_amt = refundAmount;
          nextPayment();
          return false;
        }
    }else{
      nextPayment();
    }
  };

  const closeReceiptModal = () => {
    setIsReceiptModalVisible(false);
  };

  const openReceiptModal = (membership) => {
    setPaymentMembership(membership);
    setIsReceiptModalVisible(true);
    setReceiptAmount(membership.month_amt);
    setPaymentAmount(membership.month_amt);
  };

  const handlePhoneAuthentication = (authenticatedPhoneNumber) => {
    const phoneStr = String(authenticatedPhoneNumber || '');
    const cleanPhoneNumber = phoneStr.replace(/[^0-9]/g, '');

    setPhoneNumber(lib.phoneFormat(cleanPhoneNumber));
    setIsPhoneAuthModalVisible(false);
    let sessionToken = JSON.parse(localStorage.getItem(TOKEN_STORE_KEY)) || {};
    sessionToken.mem_tel = authenticatedPhoneNumber;
    localStorage.setItem(TOKEN_STORE_KEY, JSON.stringify(sessionToken));
    api.updateMemberPhone({ mem_key: memKey, mem_tel: memTel });
  };

  //결제 취소 - 베이직 -> 프로 업그레이드 시 기존 베이직 플랜의 잔여일수의 금액을 환불 처리
  const handleCancelPayments = async (param) => {
    const params = {
      mid:innopayMid,
      tid:memberPlanInfo.tid,
      svcCd: '01',
      partialCancelCode: '1',
      cancelAmt:payMembershipInfo.current.refund_amt,
      cancelMsg:"결제취소",
      cancelPwd:"123456",
      currency:"KRW"
    }

    axios.post('https://api.innopay.co.kr/api/cancelApi', params)
    .then((response) => {
      if(response.data.resultCode === '2001'){
        handleDeleteBillKey(param)
      }else{
        alert(response.data.resultMsg)
        return false;
      }
    })
  }

  // 다운그레이드 시 빌키 삭제 or 베이직 -> 프로 업그레이드 시 기존 빌키 삭제 후 신규 업그레이드
  const handleDeleteBillKey = async (param) => {
    const params = {
      mid: innopayMid,
      billKey: memberPlanInfo.billing_key,
      userId: memKey
    }

    axios.post('https://api.innopay.co.kr/api/delAutoCardBill', params)
    .then((response) => {
      if(response.data.resultCode === '0000'){
        api.deleteMemberBillingKey({refund_amt : payMembershipInfo.current.refund_amt, tid: response.data.pgTid})
        .then((response) => {
          if(response.status === 200){
            if(isDowngrade.current){
              isDowngrade.current = false;
              alert('자동결제가 취소되었습니다.');
              fetchPlanInformation();
            }else{   
              autoPayments(param);
            }
          }
        });
      }else{
        alert(response.data.resultMsg);
        return false;
      }
    })
  }

  //월결제 등록. 베이직 -> 프로 업그레이드 시 차액 결제 취소 후 업그레이드
  const handleRegisterAutoPayment = async (params) => {
    await setPaymentMembershipInfo(10)
    params = {...params, moid: payMembershipInfo.current.uuid}
    
    if(payMembershipInfo.current.refund_yn === 'Y'){
      handleCancelPayments(params)
    }else{
      autoPayments(params)
    }
    
  }

  //빌링킹 생성 후 월결제 등록
  const autoPayments = (params) => {
    axios.post('https://api.innopay.co.kr/api/regAutoCardBill', params)
    .then((response) => {
      if(response.data.resultCode === '0000'){
        payMembershipInfo.current.billing_key = response.data.billKey;
        payMembershipInfo.current.tid = response.data.tid;
        const paymentParams = {
          mid: innopayMid,
          moid: params.moid,
          amt: payMembershipInfo.current.pay_amt,
          billKey: response.data.billKey,
          buyerName: params.buyerName,
          goodsName: payMembershipInfo.current.gds_nm,
          userId: memKey,
          buyerEmail: memEmail,
        }

        axios.post('https://api.innopay.co.kr/api/payAutoCardBill', paymentParams)
        .then((paymentResponse) => {
          if(paymentResponse.data.resultCode === '0000'){
            payMembershipInfo.current = {
              ...payMembershipInfo.current,
              pay_no: payMembershipInfo.current.uuid,
              tid: paymentResponse.data.tid
            };
            alert('결제가 완료되었습니다.');
            closeAutomaticPaymentModal();
            closeReceiptModal();
            updateMembershipAfterPayment(params.moid);
          }else{
            alert(paymentResponse.data.resultMsg);
            handleDeleteBillKey();
            return false;
          }
        })
      }else{
        alert(response.data.resultMsg);
        return false;
      }
    });
  }

  //결제 정보 저장, uuid 생성
  const setPaymentMembershipInfo = (paymentType) => {
    const membership = paymentMembership;
    payMembershipInfo.current = {
      ...payMembershipInfo.current,
      uuid: lib.makePaymentUUID(memKey),
      pay_seq: membership.pay_seq,
      plan_cd: membership.plan_cd,
      plan_nm: membership.plan_nm,
      plan_start_dt: membership.plan_start_dt,
      plan_end_dt: membership.plan_end_dt,
      mem_key: memKey,
      gds_nm: membership.plan_nm + '플랜',
      inout_tp: 'OUT',
      inout_div: '01',
      pay_amt: membership.month_amt,
      pay_meth: paymentType,
      gdsList: [
        {
          gds_div: 'GDS001',
          gds_nm: membership.plan_nm + '플랜',
          gds_amt: paymentType === 40 ? 0 : membership.month_amt,
          gds_qty: 1,
          gds_key: membership.plan_cd,
          start_dt: membership.plan_start_dt,
          end_dt: membership.plan_end_dt,
        },
      ],
    };
  };

  //사용자 쿠폰, 등록 가능 쿠폰 조회
  const getUserCoupons = async () => {
    const response = await api.getUserCoupons({ mem_key: memKey });
    tableRef.current.setGridData(response.data.userCoupons);
    setUserOwnCoupons(response.data.userCoupons);
    isAvailableCoupons.current = response.data.availableCoupons.length > 0 ? true : false;
    isCountAvailableCoupons.current = response.data.availableCoupons.length;
    setAvailableCoupons(response.data.availableCoupons);
  };

  //등록 가능 쿠폰 모달 닫기
  const closeAvailableCouponModal = () => {
    setIsAvailableCouponModal(false);
    getUserCoupons();
  };

  //등록 가능 쿠폰 모달 열기
  const openAvailableCouponModal = () => {
    setIsAvailableCouponModal(true);
  };

  //등록 가능한 쿠폰 단일 등록
  const registerCoupon = async (cpn_cd) => {
    if (await window.confirm('선택한 쿠폰을 등록하시겠습니까?')) {
      api
        .registerCoupon({ cpn_cd: cpn_cd, mem_key: memKey })
        .then((response) => {
          if (response.data.returnStatus === 'success') {
            alert(response.data.returnMessage);
            setAvailableCoupons((prev) => prev.map((coupon) => (coupon.cpn_cd === cpn_cd ? { ...coupon, status: 'off' } : coupon)));
          } else {
            alert(response.data.returnMessage);
          }
        })
        .catch((error) => {
          alert('쿠폰 등록 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
        });
    }
  };

  //등록가능한 쿠폰 전체 등록
  const registAllCoupon = async () => {
    if (await window.confirm('쿠폰을 전체 등록하시겠습니까?')) {
      const allCoupon = availableCoupons.filter((item) => item.status !== 'off');
      if (allCoupon.length === 0) {
        alert('등록할 쿠폰이 없습니다.');
        return;
      } else {
        const params = {
          mem_key: memKey,
          registerCoupons: allCoupon,
        };
        api
          .registerAllCoupon(params)
          .then((response) => {
            if (response.data.returnStatus === 'success') {
              alert(response.data.returnMessage);
              setAvailableCoupons((prev) =>
                prev.map((coupon) => {
                  return { ...coupon, status: 'off' };
                }),
              );
            } else {
              alert(response.data.returnMessage);
            }
          })
          .catch((error) => {
            console.log(error);
            alert('쿠폰 등록 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
          });
      }
    }
  };

  //오프라인 쿠폰 등록
  const registerOfflineCoupon = async () => {
    if (!offlineCouponCode) {
      alert('쿠폰 코드를 입력해주세요.');
      return false;
    }

    if (await window.confirm('쿠폰을 등록하시겠습니까?')) {
      api
        .registerOfflineCoupon({ cpn_cd: offlineCouponCode, mem_key: memKey, cpn_stat: '01' })
        .then((response) => {
          if (response.data.returnStatus === 'success') {
            alert(response.data.returnMessage);
            getUserCoupons();
          } else {
            alert(response.data.returnMessage);
          }
        })
        .catch((error) => {
          console.log(error);
          alert('쿠폰 등록 중 에러가 발생했습니다.\n관리자에게 문의바랍니다.');
        });
    }
  };

  //쿠폰 선택창 열기
  const openPaymentCouponModal = () => {
    setIsPaymentCouponModal(true);
  };

  //쿠폰 선택창 닫기
  const closePaymentCoupon = () => {
    setIsPaymentCouponModal(false);
  };

  //사용할 쿠폰 선택 완료
  const handleSelectedCoupon = (data) => {
    closePaymentCoupon();
    setPaymentAmount(0);
    setSelectedCoupon(data);
  };

  //쿠폰 결제 완료
  const handleCouponPayment = async () => {
    await setPaymentMembershipInfo(40);
    alert('결제가 완료되었습니다.');
    setIsPaymentCouponModal(false);
    setIsReceiptModalVisible(false);
    payMembershipInfo.current = {
      ...payMembershipInfo.current,
      pay_no: payMembershipInfo.current.uuid,
      pay_meth: 40,
      pay_amt: 0,
      cpn_cd: selectedCoupon.cpn_cd,
    };

    const startDate = formatDate(new Date())
    const endDate = addDaysToDate(selectedCoupon.d_cnt);
    
    payMembershipInfo.current.gdsList[0].start_dt = startDate;
    payMembershipInfo.current.gdsList[0].end_dt = endDate;
    payMembershipInfo.current.plan_start_dt = startDate;
    payMembershipInfo.current.plan_end_dt = endDate;
    
    updateMembershipAfterPayment(payMembershipInfo.current.uuid)
    api.usedCompleteCoupon({ mem_key: memKey, cpn_cd: selectedCoupon.cpn_cd, cpn_stat: '02' });
  };

  //결제 후 상태 업데이트
  const updateMembershipAfterPayment = (moid) => {
    payMembershipInfo.current.payment_type = 'plan';
    api.insertPaymentMstForPaySeq(payMembershipInfo.current);
    api.sendPaymentCompleteAlert({ mem_key: memKey, mem_nick: memNick, pay_no: moid, pay_type: 'MEMBERSHIP', plan_nm: payMembershipInfo.current.plan_nm });
    api.updateMembershipAfterPayment(payMembershipInfo.current).then((response) => {
      if (response.status === 200) {
        fetchPlanInformation();
        getUserCoupons();
      } else {
        alert('결제상태 업데이트 중 에러가 발생했습니다.');
        return;
      }
    });
  }

  //다운그레이드 - 빌링키 삭제
  const handleDowngrade = async () => {
    const requestData = { param: "" };
    await api.sessionCheck(requestData);

    if(!memberPlanInfo.billing_key){
      alert('현재 해지 요청 상태입니다.\n기존 멤버십 이용기간이 지나면\n비기너 플랜으로 변경됩니다.');
      return false;
    }
    if(await window.confirm('자동결제를 취소하시겠습니까?\n기존 멤버십은 ' + lib.dayFormatKR(memberPlanInfo.plan_end_dt) + '까지\n사용 가능합니다.\n이후 비기너 플랜으로 변경됩니다.\n※결제취소는 카드사에 따라\n2-3영업일이 소요됩니다.')){
      isDowngrade.current = true;
      handleDeleteBillKey();
    }
  }

  useEffect(() => {
    fetchPlanInformation();
    getUserCoupons();
  }, []);

  const closeAutomaticPaymentModal = () => {
    setIsAutomaticPayment(false);
  };


  return (
    <>
      <article id="plan">

        {/* 사용정보 */}
        <section className="l-section topArea">
          <div className="l-section__header">
            <h1 className="tit">사용 정보</h1>
          </div>
          <ul className="box">
            <li>
              <h2>
                만료일 <span>*</span>
              </h2>
              <div className="flexColStart">
                <strong>
                  {lib.dayFormatDot(memberPlanInfo.plan_end_dt) || ''}
                </strong>
              </div>
            </li>

            <li>
              <h2>
                멤버십 이용 기간 <span>*</span>
              </h2>
              <div className="flexColStart">
                <strong>{memberPlanInfo.plan_start_dt ? lib.dayFormatDot(memberPlanInfo.plan_start_dt) + ' ~ ' + lib.dayFormatDot(memberPlanInfo.plan_end_dt) : ''}</strong>
              </div>
            </li>

            <li>
              <h2>
                멤버십 명 <span>*</span>
              </h2>
              <div className="flexColStart">
                <strong>{memberPlanInfo.plan_nm ?? '비기너'} 플랜</strong>
              </div>
            </li>
          </ul>
        </section>

        {/* 플랜 정보 */}
        <section className="l-section planArea">
          <ul className="contentBox">
            {membershipPlanList.map((membership) => (
              <li className="box flexColBetween" id={`${memberPlanInfo.plan_cd === membership.plan_cd ? 'active' : ''}`}>
                <div className="boxCont">
                  <div className="top">
                    {/* 요청!! '/년' '/월' 텍스트 <span>으로 감싸져야 합니다 */}
                    <h1>
                      ₩ {lib.formatNumber(membership.month_amt)}
                      <span>{' / 월'}</span> &nbsp;
                    </h1>
                    <div className="flexRowStart planType">
                      <p>
                        {membership.plan_nm} 플랜 
                      </p>
                      <div
                        className={`tab flexColCenter ${membership.plan_cd === 'PLAN001' ? 'beginner' : ''} ${membership.plan_cd === 'PLAN002' ? 'basic' : ''} ${membership.plan_cd === 'PLAN003' ? 'pro' : ''}`}
                      >
                        {membership.plan_nm}
                      </div>
                    </div>
                  </div>

                  {membership.plan_cd === 'PLAN001' ? (
                    <div className="bottom">
                      <ul>
                        <li>
                          <b>마켓플레이스</b>
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          무료 등록 가능(판매불가)
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <b>프롬프트 빌더</b>
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          무료 월 3개 (갱신형)
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          신규 무료 빌더 업데이트
                        </li>
                      </ul>
                    </div>
                  ) : membership.plan_cd === 'PLAN002' ? (
                    <div className="bottom">
                      <ul>
                        <li>
                          <b>마켓플레이스</b>
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          등록 및 판매 가능(사업자 필요)
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <b>프롬프트빌더</b>
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          무료전체
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          유료 빌더 (프로 빌더 제외)
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          유료 라이브러리 (프로 라이브러리 제외)
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          신규 유료 빌더 업데이트
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="bottom">
                      <ul>
                        <li>
                          <b>마켓플레이스</b>
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          등록 및 판매 가능(사업자 필요)
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <b>프롬프트빌더</b>
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          무료전체
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          유료 빌더 전체
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          유료 라이브러리 전체
                        </li>
                        <li>
                          <span className="check">
                            <CheckOutlined />
                          </span>
                          신규 유료 빌더 업데이트
                        </li>
                      </ul>
                      {/* <ul>
                        <li><b>제공데이터</b></li>
                        <li>
                          <span className="check"><CheckOutlined /></span>10GB
                        </li>
                      </ul> */}
                    </div>
                  )}
                </div>
                {membership.plan_cd === 'PLAN001' ? (
                  membership.plan_cd === memberPlanInfo.plan_cd ? (
                    <button className="btn flexColCenter">이용중</button>
                  ) : null
                ) : 
                  membership.plan_cd === 'PLAN002' ? (
                    memberPlanInfo.plan_cd === 'PLAN001' ? (
                      <button id="pinkBtn3" onClick={() => calculationAmount(membership)}>
                        <span>업그레이드</span>
                      </button>
                    ) : membership.plan_cd === memberPlanInfo.plan_cd ? (
                      <button id="greyBtn6" onClick={handleDowngrade}>해지</button>
                    ) : null
                  ) : (
                    membership.plan_cd === 'PLAN003' ? (
                      memberPlanInfo.plan_cd === 'PLAN003' ? (
                        <button id="greyBtn6" onClick={handleDowngrade}>해지</button>
                      ) : (
                        <button id="pinkBtn3" onClick={() => calculationAmount(membership)}>
                          <span>업그레이드</span>
                        </button>
                      )
                    ) : null
                  )
                }
              </li>
            ))}
          </ul>
        </section>

        {/* 쿠폰 정보 */}
        <section className="l-section">
          <div className="l-section__header" style={{ borderBottom: '2px solid #000' }}>
            <div>
              <h1 className="tit">내 쿠폰 정보</h1>
              <AvailableCouponButton
                status={isAvailableCoupons.current}
                count={isCountAvailableCoupons.current}
                disabled={!isAvailableCoupons.current}
                openAvailableCouponModal={openAvailableCouponModal}
              />
            </div>
            <div>
              <label>이벤트 쿠폰이 있으신가요?</label>
              <input
                type="text"
                placeholder="쿠폰코드를 입력하세요"
                onFocus={(e) => (e.target.placeholder = '')}
                onBlur={(e) => (e.target.placeholder = '쿠폰코드를 입력하세요')}
                className="default"
                value={offlineCouponCode}
                onChange={(e) => setOfflineCouponCode(e.target.value)}
                maxLength={50}
              />
              <Button id="darkGrey" onClick={registerOfflineCoupon}>
                쿠폰등록
              </Button>
            </div>
          </div>
          <div className="l-section__content">
            {/* 그리드 영역 */}
            <Table ref={tableRef} columnLayout={columnLayout} customGridProps={gridProps}></Table>
          </div>
        </section>
      </article>
      <PhoneAuthModal visible={isPhoneAuthModalVisible} onClose={() => setIsPhoneAuthModalVisible(false)} onAuthenticate={handlePhoneAuthentication} initialPhoneNumber={phoneNumber} />
      <ReceiptModal
        visible={isReceiptModalVisible}
        amount={receiptAmount}
        onClose={closeReceiptModal}
        isCoupon={memberPlanInfo.plan_cd === 'PLAN001' ? true : false}
        openPaymentCouponModal={openPaymentCouponModal}
        paymentAmount={paymentAmount}
        setPaymentAmount={setPaymentAmount}
        handleCouponPayment={handleCouponPayment}
        setIsAutomaticPayment={() => setIsAutomaticPayment(true)}
      />
      <AvailableCoupons visible={isAvailableCouponModal} availableCoupons={availableCoupons} onClose={closeAvailableCouponModal} registerCoupon={registerCoupon} registAllCoupon={registAllCoupon} />
      <PaymentCoupon visible={isPaymentCouponModal} onClose={closePaymentCoupon} userOwnCoupons={userOwnCoupons} planCode={paymentMembership?.plan_cd} handleSelectedCoupon={handleSelectedCoupon} />
      <RegisterForAutomaticPayment 
        visible={isAutomaticPayment} 
        onClose={closeAutomaticPaymentModal} 
        handleRegisterAutoPayment={handleRegisterAutoPayment}
      />
    </>
  );
};
export default PlanInfo;
