import React, { useEffect, useState } from 'react';

import CreatePrompt from './CreatePrompt';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { CREATE_PROMPT, PROMPT_MANAGEMENT } from '@routes/pathName';
import PromptManagement from '@pages/prompt/PromptManagement';

const PromptHome = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/')[2];
  const navigate = useNavigate();
  const [tab, setTab] = useState('create');

  const onChangeTab = async(tabKey) => {
    switch (tabKey) {
      case 'create':
        navigate(CREATE_PROMPT); // 해당 경로로 이동
        setTab('create');
        break;
      case 'management':
        if(localStorage.getItem('prptUsed') === 'Y'){
          if(await window.confirm('현재 작업중인 빌더가 있을 수 있습니다.\n이동하시겠습니까?')){
            localStorage.removeItem('prptUsed');
            navigate(PROMPT_MANAGEMENT); // 해당 경로로 이동
            setTab('management');
          }
        }else{
          navigate(PROMPT_MANAGEMENT); // 해당 경로로 이동
          setTab('management');
        }
        break;
      default:
        navigate(CREATE_PROMPT); // 해당 경로로 이동
        setTab('create');
    }
  };

  useEffect(() => {
    if (path === '*') {
      onChangeTab('create');
    } else {
      setTab(path);
    }
  }, [pathname]);

  return (
    <>
      <article className="flexColCenter" id="prompt">
        <div className="rsWrapper flexColCenter">
          <div className="flexRowCenter title">
            {/* <div className="flexRowCenter overflowBox">
              <div onClick={() => onChangeTab('create')} className="flexColCenter"
                   id={tab === 'create' ? 'active' : 'nothing'}>
                <div className="round"></div>
                <h1>프롬프트 생성</h1>
              </div>
              <div onClick={() => onChangeTab('management')} className="flexColCenter"
                   id={tab === 'management' ? 'active' : 'nothing'}>
                <div className="round"></div>
                <h1>프롬프트 관리</h1>
              </div>
            </div> */}
          </div>

            <Routes>
              <Route path="create" element={<CreatePrompt/>}/>
              {/* <Route path="management" element={<PromptManagement/>}/> */}
            </Routes>
          </div>
      </article>
    </>
);
};

export default PromptHome;
