import { Button, Layout, Row, Col, message } from 'antd';
import React, { useRef, useEffect } from 'react';
import Table from '@components/Table';
import * as api from '@api/index';

const ChargeManagementHome = () => {

  const { Content, Header } = Layout;

  const chargeTableRef = useRef();

  //그룹 코드 그리드 설정
  const chargeGridProps = {
    showRowCheckColumn: false,
    showStateColumn: false,
    showRowNumColumn : false,
  };

  //그룹 코드 그리드 컬럼
  const chargeColumnLayout = [
    { dataField: 'charge_cd', visible: false },
    { dataField: 'bdg_nm', headerText: '셀러', width : "30%", editable : false },
    { dataField: 'charge_rate', headerText: '수수료율', width : "20%", dataType : "numeric", formatString: "#,##0.##", style : "text-align : right"
      , editRenderer : {
        type : "InputEditRenderer",
        onlyNumeric : true, // 0~9만 입력가능
        allowPoint : true, // 소수점( . ) 도 허용할지 여부
        allowNegative : true, // 마이너스 부호(-) 허용 여부
        textAlign : "right", // 오른쪽 정렬로 입력되도록 설정
        maxlength : 6, // 글자수 10으로 제한 (천단위 구분자 삽입(autoThousandSeparator=true)로 한 경우 구분자 포함해서 10자로 제한)
        autoThousandSeparator : true // 천단위 구분자 삽입 여부
    }},
    { dataField: 'remark', headerText: '비고', width : "50%", style : "text-align : left", editRenderer : {maxlength : 500} },
  ];

  const fetchSellers = async () => {
    const response = await api.fetchChargeList({});
    chargeTableRef.current.setGridData(response.data);
  }

  const handleSave = async () => {
    const gridData = chargeTableRef.current.getGridData();
    if(!chargeTableRef.current.validateChangedGridData(['charge_rate'],'필수 필드는 반드시 값을 직접 입력해야 합니다.',)){
      alert("필수 필드를 모두 입력해주세요.");
      return false;
    }

    // 수수료율 검증
    const invalidRate = gridData.some(data => data.charge_rate > 100);
    if (invalidRate) {
      message.error('수수료율은 100을 초과할 수 없습니다.');
      return; // 검증 실패 시 저장 작업 중단
    }

    if(await window.confirm("저장하시겠습니까?")){
      const params = {
        saveCharges : gridData
      }
      const response = await api.saveCharges(params);
      if(response.data.returnStatus === "success"){
        alert("저장되었습니다.", "success");
        fetchSellers();
      }else{
        alert("에러가 발생했습니다.");
      }
    }
  }

  useEffect(() => {
    fetchSellers();
  }, [])

  return (
    <>
      <Layout>
        <Content className='l-content'>
          <Col style={{marginLeft: 'auto'}}>
            <Button className='btn-pink' onClick={handleSave}>저장</Button>
          </Col>
          <Table ref={chargeTableRef} columnLayout={chargeColumnLayout} customGridProps={chargeGridProps}/>
        </Content>
      </Layout>
    </>
  )
}

export default ChargeManagementHome