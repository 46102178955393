import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import { Descriptions, message, Row, Col, Image, Flex, Rate, Dropdown, Button, Card, Avatar, Form, Input, Upload, Select } from 'antd';
import { GlobalOutlined, TwitterOutlined, YoutubeOutlined, PlusOutlined } from '@ant-design/icons';
import Loading from '@components/Loading';
import Divider from '@components/samples/DividerList';
import ListCardForKeyValue from '@components/ListCardForKeyValue';
import ColumnStatus from '@components/ColumnStatus';
import * as api from '@api/index';
import { secondsFormat, isAdmin, isCanceledUser, makeDataFormat, makeSelectBoxList } from '@utils/lib';
import { ADMIN_USER_DETAIL, ADMIN_USER_EDIT } from '@routes/pathName';

const INIT_STATE_USER_DETAIL_SEARCH = {
  mem_email: '',
  mem_key: '',
};

const INIT_STATE_EDIT_INFO = {
  mem_email: '',
  mem_nick: '',
  mem_tel: '',
  web: '',
  twitter_link: '',
  youtube_link: '',
  about_me: '',
  loc_cd_1: '',
  loc_cd_2: '',
  group: '',
  mem_div: '',
  plan_nm: '',
  mem_stat: '',
  fir_dt: '',
  cnl_dt: '',
  login_dt: '',
  cnl_rsn_cd: 'CNL_RSN001',
  cnl_rsn: '',
  desc: '',
};

const Profile = () => {
  const [form] = Form.useForm();
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = useMemo(() => ({ ...INIT_STATE_USER_DETAIL_SEARCH, ...qs.parse(search?.substring(1) || '') }), [search]);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetailData, setUserDetailData] = useState(null);
  const [memDivFormat, setMemDivFormat] = useState({});
  const [memStatFormat, setMemStatFormat] = useState({});
  const [cnlRsnCdFormat, setCnlRsnCdFormat] = useState({});
  const [loc1Format, setLoc1Format] = useState({});
  const [loc2Format, setLoc2Format] = useState({});
  const [loc1List, setLoc1List] = useState([]);
  const [loc2List, setLoc2List] = useState([]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // 공통코드 맴버상태 조회
  const makeMemStatFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'MEM_STAT' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setMemStatFormat(madeDataFormat);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 공통코드 맴버구분 조회
  const makeMemDivFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'MEM_DIV' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setMemDivFormat(madeDataFormat);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 공통코드 탈퇴사유코드 조회
  const makeCnlRsnCdFormat = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'CNL_RSN' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_nm', 'cd');
      setCnlRsnCdFormat(madeDataFormat);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 공통코드 위치1 조회
  const makeLoc1Format = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'LOC01' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_desc', 'cd');
      setLoc1Format(madeDataFormat);
      const madeSelectBoxList = makeSelectBoxList(response?.data, 'cd_desc', 'cd');
      setLoc1List(madeSelectBoxList);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 공통코드 위치2 조회
  const makeLoc2Format = async () => {
    try {
      const response = await api.fetchCommonDetailCode({ grp_cd: 'LOC02' });
      const madeDataFormat = makeDataFormat(response?.data, 'cd_desc', 'cd');
      setLoc2Format(madeDataFormat);
      const madeSelectBoxList = makeSelectBoxList(response?.data, 'cd_desc', 'cd');
      setLoc2List(madeSelectBoxList);
    } catch (error) {
      message.warning(error.message);
    }
  };

  // 유저 상세정보 가져오기
  const selectUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await api.selectUser(params);
      setUserDetailData(data);
      form.setFieldsValue({
        ...INIT_STATE_EDIT_INFO,
        mem_email: data.mem_email,
        mem_nick: data.mem_nick,
        mem_tel: data.mem_tel,
        web: data.web,
        twitter_link: data.twitter_link,
        youtube_link: data.youtube_link,
        about_me: data.about_me,
        loc_cd_1: data.loc_cd_1,
        loc_cd_2: data.loc_cd_2,
        group: data.group,
        mem_div: data.mem_div,
        plan_nm: data.plan_nm,
        mem_stat: data.mem_stat,
        fir_dt: data.fir_dt,
        cnl_dt: data.cnl_dt,
        login_dt: data.login_dt,
        cnl_rsn_cd: data.cnl_rsn_cd,
        cnl_rsn: data.cnl_rsn,
        desc: data.desc,
      });
    } catch (error) {
      message.warning(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    // 공통코드 조회
    makeMemStatFormat();
    makeMemDivFormat();
    makeCnlRsnCdFormat();
    makeLoc1Format();
    makeLoc2Format();

    selectUser();
  }, [selectUser, params]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Form
        form={form}
        name="editUser"
        layout="vertical"
        // style={{ maxWidth: 500, margin: '0 auto 20px' }}
        initialValues={INIT_STATE_EDIT_INFO}
        // onFinish={handleUpdateUser}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        scrollToFirstError
      >
        <Divider className="profileTitle" text="기본정보" />
        <Row style={{ flexFlow: 'unset' }}>
          <Col flex="150px">회원 정보</Col>
          <Col flex="auto" style={{ paddingRight: '10px' }}>
            <Descriptions size="small" layout="vertical" column={6} colon={false} style={{ fontWeight: 400 }}>
              <Descriptions.Item span={3} label="닉네임" style={{ paddingRight: '30px' }}>
                <Form.Item name="mem_nick" style={{ margin: '0', width: '100%' }}>
                  <Input />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item span={3} label="이메일">
                <Form.Item name="mem_email" style={{ margin: '0', width: '100%' }}>
                  <Input disabled />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={
                  <Row style={{ position: 'relative', width: '100%' }}>
                    <Col style={{ position: 'absolute' }}>연락처</Col>
                    <Col style={{ position: 'absolute', right: '0', color: '#1C8FD6' }}>* 연락처를 변경할 경우 새로 인증이 필요합니다.</Col>
                  </Row>
                }
                style={{ paddingRight: '30px' }}
                labelStyle={{ paddingTop: '30px', width: '100%' }}
              >
                <Input placeholder='"-"를 제외한 숫자만 입력해 주세요.' disabled />
                <Button style={{ minWidth: '100px', marginLeft: '10px' }}>인증요청</Button>
              </Descriptions.Item>
              <Descriptions.Item span={3} label="위치" labelStyle={{ paddingTop: '30px' }}>
                <Form.Item name="loc_cd_1" style={{ margin: '0 20px 0 0', width: '100%' }}>
                  <Select options={loc1List} placeholder="도, 시" />
                </Form.Item>
                <Form.Item name="loc_cd_2" style={{ margin: '0', width: '100%' }}>
                  <Select options={loc2List} placeholder="구" />
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item
                span={3}
                label={
                  <Row style={{ position: 'relative', width: '100%' }}>
                    <Col style={{ position: 'absolute' }}>비밀번호 입력</Col>
                    <Col style={{ position: 'absolute', right: '0', color: '#1C8FD6' }}>* 8~12자리 영문, 숫자, 특수기호 포함</Col>
                  </Row>
                }
                style={{ paddingRight: '30px' }}
                labelStyle={{ paddingTop: '30px', width: '100%' }}
              >
                <Input disabled />
                <Button style={{ minWidth: '100px', marginLeft: '10px' }}>변경</Button>
              </Descriptions.Item>
              <Descriptions.Item span={3} label="소속 조직" labelStyle={{ paddingTop: '30px' }}>
                <Form.Item name="group" style={{ margin: '0', width: '100%' }}>
                  <Input placeholder="소속" />
                </Form.Item>
              </Descriptions.Item>
              {/* <Descriptions.Item span={3} label="계정삭제" labelStyle={{ paddingTop: '30px' }}>
                <Button style={{ minWidth: '100px' }}>탈퇴 요청</Button>
              </Descriptions.Item> */}
            </Descriptions>
          </Col>
        </Row>
        <Divider style={{ borderColor: '#A6A6A6', margin: '24px 0' }} />
        <Row style={{ flexFlow: 'unset' }}>
          <Col flex="150px">내 소개</Col>
          <Col flex="auto" style={{ paddingRight: '10px' }}>
            <Col style={{ display: 'flex' }}>
              <Descriptions size="small" layout="vertical" column={3} colon={false} style={{ fontWeight: 400, maxWidth: '300px', marginRight: '30px' }}>
                <Descriptions.Item
                  span={3}
                  label={
                    <Row style={{ position: 'relative', width: '100%' }}>
                      <Col style={{ position: 'absolute' }}>내 프로필 이미지</Col>
                      <Col style={{ position: 'absolute', right: '0', color: '#1C8FD6' }}>* 최소 140px X 140px</Col>
                    </Row>
                  }
                  labelStyle={{ width: '100%', marginBottom: '25px' }}
                >
                  <Form.Item name="mem_img_path" valuePropName="fileList" getValueFromEvent={normFile} style={{ margin: '0', width: '100%', textAlign: 'center' }}>
                    <Upload action="" maxCount={1} listType="picture-card">
                      <button type="button">
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>이미지를 등록하거나 드래그앤 드롭해 주세요.</div>
                      </button>
                    </Upload>
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions size="small" layout="vertical" column={6} colon={false} style={{ fontWeight: 400 }}>
                <Descriptions.Item span={3} label="웹사이트" style={{ paddingRight: '30px' }}>
                  <Form.Item name="web" style={{ margin: '0', width: '100%' }}>
                    <Input placeholder="https://" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Yutube">
                  <Form.Item name="youtube_link" style={{ margin: '0', width: '100%' }}>
                    <Input placeholder="https://" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="Instagram" style={{ paddingRight: '30px' }} labelStyle={{ paddingTop: '30px' }}>
                  <Form.Item name="insta_link" style={{ margin: '0', width: '100%' }}>
                    <Input placeholder="https://" />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item span={3} label="X" labelStyle={{ paddingTop: '30px' }}>
                  <Form.Item name="x_link" style={{ margin: '0', width: '100%' }}>
                    <Input placeholder="https://" />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Descriptions size="small" layout="vertical" column={6} colon={false} style={{ fontWeight: 400 }}>
              <Descriptions.Item span={6} label="소개 이미지" labelStyle={{ paddingTop: '30px' }} contentStyle={{ border: '1px solid #E1E1E1', borderRadius: '0.8rem', padding: '30px' }}>
                <Form.Item name="mem_img_path" valuePropName="fileList" getValueFromEvent={normFile} style={{ margin: '0', width: '100%', textAlign: 'center' }}>
                  <Upload action="" maxCount={1} listType="picture">
                    <button type="button">
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>이미지를 등록하거나 드래그앤 드롭해 주세요.</div>
                    </button>
                  </Upload>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item span={6} label="소개 및 설명" labelStyle={{ paddingTop: '30px' }} contentStyle={{ display: 'block' }}>
                <Form.Item name="about" style={{ margin: '0', width: '100%' }}>
                  <Input />
                </Form.Item>
                <Form.Item name="about" style={{ margin: '30px 0 0', width: '100%' }}>
                  <Input.TextArea />
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Divider style={{ borderColor: '#A6A6A6', margin: '24px 0' }} />
        <Row style={{ flexFlow: 'unset' }}>
          <Col flex="150px">
            <Col>전문 AI 분야</Col>
            <Button>추가</Button>
          </Col>
          <Col flex="auto" style={{ paddingRight: '10px' }}>
            <Row gutter={16}>
              <Col span={8} style={{ marginBottom: '20px' }}>
                <Card styles={{ body: { padding: '16px' } }}>
                  <Row>
                    <Col style={{ lineHeight: '34px' }}>전문 AI 분야</Col>
                    <ColumnStatus text="인증완료" style={{ marginLeft: '16px' }} />
                  </Row>
                  <Row style={{ marginTop: '8px' }}>
                    <Col>
                      <Image width={20} src={''} />
                    </Col>
                    <Col style={{ lineHeight: '24px' }}>Midjourney</Col>
                  </Row>
                </Card>
              </Col>
              <Col span={8} style={{ marginBottom: '20px' }}>
                <Card styles={{ body: { padding: '16px' } }}>
                  <Row>
                    <Col style={{ lineHeight: '34px' }}>전문 AI 분야</Col>
                    <ColumnStatus type="danger" text="인증실패" style={{ marginLeft: '16px' }} />
                  </Row>
                  <Row style={{ marginTop: '8px' }}>
                    <Col>
                      <Image width={20} src={''} />
                    </Col>
                    <Col style={{ lineHeight: '24px' }}>Dall-E</Col>
                  </Row>
                </Card>
              </Col>
              <Col span={8} style={{ marginBottom: '20px' }}>
                <Card styles={{ body: { padding: '16px' } }}>
                  <Row>
                    <Col style={{ lineHeight: '34px' }}>전문 AI 분야</Col>
                    <ColumnStatus type="warning" text="검토중" style={{ marginLeft: '16px' }} />
                  </Row>
                  <Row style={{ marginTop: '8px' }}>
                    <Col>
                      <Image width={20} src={''} />
                    </Col>
                    <Col style={{ lineHeight: '24px' }}>SUNO</Col>
                  </Row>
                </Card>
              </Col>
              <Col span={8} style={{ marginBottom: '20px' }}>
                <Card styles={{ body: { padding: '16px' } }}>
                  <Row>
                    <Col style={{ lineHeight: '34px' }}>전문 AI 분야</Col>
                    <ColumnStatus type="warning" text="검토중" style={{ marginLeft: '16px' }} />
                  </Row>
                  <Row style={{ marginTop: '8px' }}>
                    <Col>
                      <Image width={20} src={''} />
                    </Col>
                    <Col style={{ lineHeight: '24px' }}>Stable Diffusion</Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider style={{ borderColor: '#A6A6A6', margin: '24px 0' }} />
        <Row style={{ display: 'block', textAlign: 'center' }}>
          <Col style={{ display: 'inline-flex', margin: '0 8px' }}>
            <Button style={{ minWidth: '100px' }}>취소</Button>
          </Col>
          <Col style={{ display: 'inline-flex', margin: '0 8px' }}>
            <Button style={{ minWidth: '100px' }}>저장</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Profile;
