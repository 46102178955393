import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Col, Divider, Flex, Form, Input, message, Row, Select, Space, Upload } from 'antd';
import Editor from '../../components/Editor';
import Loading from '@components/Loading';
import * as api from '@api/index';
import { DeleteOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { INQUIRY_DETAIL, INQUIRY_LIST } from '@routes/pathName';
import { IMAGES } from '@utils/image';
import SignUpAgreeTerms from '@components/SignUpAgreeTerms';

const EditInquiry = () => {
  const auth = useSelector((s) => s.auth, shallowEqual); // 로그인 유저정보

  const { state } = useLocation();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [inqrList, setInqrList] = useState([]);
  const [fileGrpSeq, setFileGrpSeq] = useState(null);
  const inqrSeq = state?.inqr_seq || null;
  const [existFileList, setExistFileList] = useState([]);

  const [form] = Form.useForm();
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState('');
  const [fileList, setFileList] = useState([]);
  const [delFileCaches, setDelFileCaches] = useState([]);

  const [isTermChecked, setIsTermChecked] = useState(false);
  const [isTermModal, setIsTermModal] = useState(false);

  const [inquiryState, setInquiryState] = useState({
    query: {
      mem_key: state?.mem_key || auth?.mem_key,
      inqr_seq: inqrSeq,
    },
    item: {},
  });

  // 동의 문구
  const editorPlaceholder =
    '문의 시, 주민번호, 계좌번호와 같은 개인정보 입력은 지양하여 주시기 바랍니다.' +
    '\n산업안전보건법을 준수하여 고객응대근로자를 보호하고 있습니다.' +
    '\n성희롱, 욕설 등의 폭언을 하지 말아 주세요. 폭언 시, 상담이 제한되고 법령에 따라 조치될 수 있습니다.';

  // 파일 첨부 컨트롤
  const handleFileChange = ({ fileList: newFileList }) => {
    const filteredNewFiles = newFileList.filter((file) => file.status !== 'done');

    if (filteredNewFiles.some((file) => file.size > 10 * 1024 * 1024)) {
      message.error('각 파일의 크기는 10MB를 초과할 수 없습니다.');
      return;
    }
    setFileList(newFileList);
  };

  // 파일 삭제 컨트롤
  const handleFileRemove = (e, file) => {
    e.stopPropagation();
    e.preventDefault();

    setFileList((prevFileList) => prevFileList.filter((item) => item.uid !== file.uid));

    if (file.url) {
      setDelFileCaches((prev) => [...prev, file.url]);
    }
  };

  const handleSubmit = async (saveData) => {
    try {
      const { content, files: editorFiles } = await editorRef.current.getProcessedContent();
      if (content === '<p><br></p>') return message.error('내용을 입력해주세요.');

      const formData = new FormData();

      // 삭제 파일
      let updatedDelFileCaches = [...delFileCaches];
      // content에서 삭제된 이미지 처리
      const checkFile = existFileList.filter((file) => file.attr1 === 'c');
      checkFile.forEach((file) => {
        // file의 file_phy_path와 img의 src 값이 일치하지 않으면 updatedDelFileCaches에 추가
        const { file_phy_path } = file;
        const imgSrcChkRegex = new RegExp(`<img[^>]+src="${file_phy_path}"[^>]*>`, 'g');
        if (!content.match(imgSrcChkRegex)) {
          updatedDelFileCaches.push(file.file_dwn_path);
        }
      });
      updatedDelFileCaches.forEach((delFile) => {
        formData.append('delFiles', delFile);
      });

      // 첨부 파일
      fileList
        ?.filter((file) => file.status !== 'done')
        .forEach((file, index) => {
          formData.append(`files`, file.originFileObj || file);
        });

      // 에디터 파일
      editorFiles.forEach((file) => {
        formData.append('editorFiles', file);
      });

      const saveFromData = {
        mem_key: auth?.mem_key,
        inqr_seq: inqrSeq,
        inqr_div: saveData.inqr_div,
        inqr_title: saveData.inqr_title,
        contents: content,
        file_grp_seq: fileGrpSeq,
        agree_yn: isTermChecked ? 'Y' : 'N',
      };
      formData.append('saveData', new Blob([JSON.stringify(saveFromData)], { type: 'application/json' }));

      const { data } = await api.saveInquiry(formData);
      if (data.returnStatus == 'success') {
        message.success(data.returnMessage);
        navigate(INQUIRY_DETAIL, { state: { inqr_seq: data.inqr_seq, mem_key: auth?.mem_key, flag: 'U' } });
      } else message.error(data.returnMessage);
    } catch (e) {
      console.log(e);
      message.error('저장에 실패했습니다.');
    }
  };

  // 문의 조회
  const getMemInquiry = useCallback(async (query) => {
    try {
      setIsLoading(true);
      const { data } = await api.getMemInquiry(query);

      // form에 이전 문의 내용 세팅
      setInquiryState((prev) => ({ ...prev, item: data.item }));
      form.setFieldsValue(data.item);

      // file
      const allFiles = data.fileList || [];
      const contentImgs = allFiles.filter((file) => file.attr1 === 'c'); // content 내 이미지파일
      const attachments = allFiles.filter((file) => file.attr1 === 'a'); // 첨부파일
      setExistFileList(allFiles);
      setFileGrpSeq(data.item.file_grp_seq);

      // SunEditor Content Images 처리
      let updatedContents = data.item.contents;
      contentImgs.forEach((img) => {
        updatedContents = updatedContents
          .replace(new RegExp(`src="[^"]*${img.file_org_nm}"`, 'g'), `src="${img.file_dwn_path}"`)
          .replace(new RegExp(`<img[^>]*src="[^"]*${img.file_dwn_path}"[^>]*>`, 'g'), (match) => match.replace(/alt="[^"]*"/, `alt="${img.file_seq}"`));
      });
      setEditorContent(updatedContents);

      // 첨부파일 처리
      if (attachments.length > 0) {
        const formattedAttachments = attachments.map((file) => ({
          uid: file.file_seq || file.uid || `${file.file_nm}-${file.file_seq}`,
          name: file.file_org_nm,
          status: 'done',
          url: file.file_dwn_path,
          originFileObj: file,
        }));
        setFileList(formattedAttachments);
      }
    } catch (e) {
      console.log('실패');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getCommonCode = async () => {
    try {
      // 상담분류 조회
      const { data } = await api.fetchEqualCommonGroupCode({ grp_cd: 'INQR' });
      setInqrList(data);
    } catch (error) {
      console.error('공통코드 조회 error', error);
    }
  };

  const handleTermModalCancel = () => {
    setIsTermModal(false);
  };

  useEffect(() => {
    getCommonCode();
    // 수정일 경우
    if (state) {
      getMemInquiry(inquiryState.query);
    }
  }, []);

  useEffect(() => {
    if (inquiryState.item?.agree_yn === 'Y') {
      setIsTermChecked(true);
    }
  }, [inquiryState.item]);

  return (
    <>
      <Loading isLoading={isLoading} />
      <Divider variant="dashed" dashed>
        <img src={IMAGES.TITLE_ICON} alt="아이콘" />
        1:1 문의하기
      </Divider>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <div className="l-form">
          <h3 className="l-form__title">
            <img src={IMAGES.LIST_ICON1} alt="아이콘" />
            문의유형
          </h3>
          <ul className="l-form__list">
            <li>
              <Form.Item name="inqr_div" label="상담유형" rules={[{ required: true, message: '상담 유형을 선택해주세요.' }]}>
                <Select options={inqrList} fieldNames={{ label: 'cd_nm', value: 'cd' }} allowClear placeholder="상담유형선택" />
              </Form.Item>
            </li>
          </ul>
        </div>
        <Divider />
        <div className="l-form">
          <h3 className="l-form__title">
            <img src={IMAGES.LIST_ICON2} alt="아이콘" />
            문의내용
          </h3>
          <ul className="l-form__list">
            <li>
              <Form.Item name="inqr_title" label="제목" rules={[{ required: true, message: '제목을 입력해주세요.' }]}>
                <Input placeholder="제목을 입력해주세요." />
              </Form.Item>
            </li>
            <li>
              <Form.Item label="내용" required>
                <Editor ref={editorRef} content={editorContent} setContent={setEditorContent} placeholder={editorPlaceholder} attach_yn={'Y'} />
              </Form.Item>
            </li>
            <li>
              <Form.Item label="파일첨부">
                <Upload.Dragger
                  className="plusBannerBox"
                  multiple
                  maxCount={2}
                  fileList={fileList}
                  onChange={handleFileChange}
                  beforeUpload={() => false} // 실제 업로드 방지
                  showUploadList={false} // 기본 리스트 표시 비활성화
                >
                  {fileList.length === 0 ? (
                    <div style={{ textAlign: 'center', color: '#c49bcc' }}>
                      <img src={IMAGES.UPLOAD_ICON} alt="업로드아이콘" />
                      <p>
                        파일을 등록하거나 드래그 & 드롭 해 주세요. <br />
                        *첨부 가능한 최대 파일 크기 : 10MB
                      </p>
                    </div>
                  ) : (
                    <div style={{ marginTop: '16px' }}>
                      {fileList.map((file) => (
                        <div
                          key={file.uid}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <span>{file.name}</span>
                          <DeleteOutlined onClick={(e) => handleFileRemove(e, file)} style={{ color: 'red', cursor: 'pointer' }} />
                        </div>
                      ))}
                    </div>
                  )}
                </Upload.Dragger>
              </Form.Item>
            </li>
          </ul>
        </div>
        <Divider />
        <div className="privacy">
          <Checkbox checked={isTermChecked} onChange={() => setIsTermChecked(!isTermChecked)}>
            <h3>
              (필수)개인정보 수집 및 이용 동의
              <a>
                (<span onClick={() => setIsTermModal(true)}>보기</span>)
              </a>
            </h3>
          </Checkbox>
        </div>
        <Form.Item className="btnArea">
          <Flex gap="middle" align="center" justify="center">
            <Button type="primary" htmlType="submit" disabled={isTermChecked ? false : true}>
              문의하기
            </Button>
            <Button
              onClick={() => {
                navigate(INQUIRY_LIST);
              }}
            >
              취소
            </Button>
          </Flex>
        </Form.Item>
      </Form>

      <SignUpAgreeTerms visible={isTermModal} onClose={handleTermModalCancel} agreeCode={'AGREE003'} />
    </>
  );
};

export default EditInquiry;
